import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "../Services/Apis/authApi";
import authSliceReducer from "../Services/Slices/authSlice";
import modalSlice from "../Services/Slices/modalSlice";
import roleSlice from "../Services/Slices/roleSlice";
import toastSlice from "../Services/Slices/toastSlice";
import { employeeApi } from "../Services/Apis/employeeApi";
import { ridersApi } from "../Services/Apis/ridersApi";
import { cityApi } from "../Services/Apis/cityApi";
import { orderApi } from "../Services/Apis/orderApi";
import { areaApi } from "../Services/Apis/areaApi";
import { razorpayApi } from "../Services/Apis/razorpayApi";
import { areaMasterApi } from "../Services/Apis/areaMasterApi";
import { customerManagementApi } from "../Services/Apis/customerManagementApi";
import { configurationApi } from "../Services/Apis/configurationApi";
import { riderManagementApi } from "../Services/Apis/riderManagementApi";
import { franchiseApi } from "../Services/Apis/franchiseApi";

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[employeeApi.reducerPath]: employeeApi.reducer,
		[ridersApi.reducerPath]: ridersApi.reducer,
		[cityApi.reducerPath]: cityApi.reducer,
		[areaApi.reducerPath]: areaApi.reducer,
		[areaMasterApi.reducerPath]: areaMasterApi.reducer,
		[orderApi.reducerPath]: orderApi.reducer,
		[razorpayApi.reducerPath]: razorpayApi.reducer,
		[customerManagementApi.reducerPath]: customerManagementApi.reducer,
		[configurationApi.reducerPath]: configurationApi.reducer,
		[riderManagementApi.reducerPath]: riderManagementApi.reducer,
		[franchiseApi.reducerPath]: franchiseApi.reducer,


		auth: authSliceReducer,
		modal: modalSlice,
		toast: toastSlice,
		role: roleSlice,
	},

	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: {
				ignoredPaths: [
					"modal.modal.data.handleGetAppointments",
					"modal.modal.data.fetchCustomers",
					"modal.modal.data.fetchAddress",
				],
			},
		})
			.concat(authApi.middleware)
			.concat(employeeApi.middleware)
			.concat(ridersApi.middleware)
			.concat(cityApi.middleware)
			.concat(areaApi.middleware)
			.concat(razorpayApi.middleware)
			.concat(orderApi.middleware)
			.concat(areaMasterApi.middleware)
			.concat(customerManagementApi.middleware)
			.concat(configurationApi.middleware)
			.concat(riderManagementApi.middleware)
			.concat(franchiseApi.middleware);
	},
});

setupListeners(store.dispatch);
export * from "../Services/Slices/authSlice";
export * from "../Services/Slices/roleSlice";
export * from "../Services/Apis/authApi";
export * from "../Services/Slices/modalSlice";
export * from "../Services/Apis/employeeApi";
export * from "../Services/Apis/ridersApi";
export * from "../Services/Apis/cityApi";
export * from "../Services/Apis/orderApi";
export * from "../Services/Apis/areaApi";
export * from "../Services/Apis/razorpayApi";
export * from "../Services/Apis/areaMasterApi";
export * from "../Services/Apis/customerManagementApi";
export * from "../Services/Apis/configurationApi";
export * from "../Services/Apis/riderManagementApi";
export * from "../Services/Apis/franchiseApi";

