import { Form, Formik } from "formik";
import React from "react";
import FormField from "../../shared/components/FormField.component";
import Button from "../../shared/components/Button.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import peopleIcon from "../../assets/icons/people-icon.svg";
import * as Yup from "yup";
import Mobile from "../../shared/components/Mobile";
import { useAddAreaMutation, useUpdateCustomerMutation } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Employee Name is required"),
	email: Yup.string().email("Invalid email address").required("Email is required"),
	phoneNumber: Yup.string()
		.required("Mobile Number is required")
		.matches(/^\d{10}$/, "Please enter 10 digits Mobile Number"),
});

export default function CustomerEditModal({ data }) {
	const { closeModal } = useSelectModal();
	const [updateUser, { status }] = useUpdateCustomerMutation()
	const [addAddress, { data: addressList }] = useAddAreaMutation();
	const { setToast } = useToaster();

	const handleFormSubmit = async (values) => {
		const { data: userData, error } = await updateUser({
			customerId: data._id,
			body: {
				name: values.name,
				email: values.email,
				phoneNumber: "+91" + values.phoneNumber,
			}
		})
		if (userData) {
			setToast({ type: "success", msg: "Customer details updated successfully" })
			data?.fetchCustomers();
			closeModal()
		};
		if (error) {
			setToast({ type: "error", msg: error?.data?.message })
			closeModal()
		};
	}

	return (
		<div className="w-[30rem] relative py-6  overflow-y-hidden modal-height px-5">
			<div className="pl-5">
				<div className="flex justify-start items-center">
					<div className="h-12 w-12 rounded-full flex justify-center items-center modal-logo-size">
						<img src={peopleIcon} alt="" className=" " />
					</div>
				</div>
				<div className="text-lg pt-4 font-inter-bold">Edit Customer</div>
				<div className="tracking-tight text-sm  pb-5 text-[#475467] font-inter-regular">
					Edit Customer Details by Changing the details below
				</div>
			</div>
			<div className="pb-4">
				<Formik
					initialValues={{
						name: data.name,
						email: data.email,
						phoneNumber: data.phoneNumber.slice(3),
					}}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
				>
					{({ isSubmitting, values, setFieldValue, errors, touched }) => (
						<Form
							className="space-y-1 overflow-y-auto px-5 modal-form-height"
							action="#"
							method="POST"
						>
							<FormField
								field={{
									label: "Customer Name",
									name: "name",
									type: "text",
									placeholder: "Tyler Lautner",
									customClassName: "font-inter-medium",
									onKeyPress: (event) => {
										if (/[^a-zA-z ]/.test(event.key)) {
											return event.preventDefault();
										}
									},
								}}
							/>
							{/* <FormField
								field={{
									label: "Mobile Number",
									name: "mobile",
									type: "text",
									placeholder: "91- 78654 67543 ",
									customClassName: "font-inter-medium",
									onKeyPress: (event) => {
										if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
											return event.preventDefault();
										}
									},
								}}
							/> */}
							<Mobile name={"phoneNumber"} touched={touched} errors={errors} placeholder={"7865467543"} />
							<FormField
								field={{
									label: "Email Id",
									name: "email",
									type: "text",
									placeholder: "tyler@email.come",
									customClassName: "font-inter-medium",
								}}
							/>
							<div className="flex justify-between pt-4">
								<Button
									className="w-[47.5%] text-black bg-[#F1F1F1] cursor-pointer bottom-btn-ht"
									onClick={closeModal}
								>
									Cancel
								</Button>
								<Button
									className="w-[47.5%] text-white cursor-pointer bottom-btn-ht"
									type="submit"
									isSubmitting={status === "pending"}
								>
									Save
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}
