import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Map from "./Map_city";

import FormField from "../../shared/components/FormField.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import ListBoxComponent from "../../shared/components/ListBox.component";
import {
	useAddCityMutation,
	useLazyGetAllCityManagersQuery,
	useLazyGetCitesQuery,
	useUpdateCityMutation,
} from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";
import SelectMenuMultiselect from "../../shared/components/Select_Menu_Multiselect.component";
import mapIcon from "../../assets/icons/map-icon.svg";
import editIcon from "../../assets/icons/edit-icon-blue-bg.svg";
import SelectMenu from "../../shared/components/SelectMenu.component";
import { CitySchema } from "../../utils/Services/ValidationSchema";

export default function AddNewCity({ data: selectedCityData }) {
	const { closeModal } = useSelectModal();
	const [cityData, setCityData] = useState(null);
	const [cityManagerList, setCityManagerList] = useState([]);
	const isEdit = selectedCityData;
	const [getManagers, { data: allCityManagers }] = useLazyGetAllCityManagersQuery();
	const [addCity, {status : addStatus}] = useAddCityMutation();
	const [updateCity, {status: updateStatus}] = useUpdateCityMutation();
	const { setToast } = useToaster();
	const [getCites, { data: cities }] = useLazyGetCitesQuery();

	let allSelectedCityManagers = [];
	// getManagers()
	useEffect(() => {
		getManagers();
		getCites();
	}, []);

	useEffect(() => {
		if (allCityManagers) setCityManagerList(allCityManagers);
	}, [allCityManagers]);

	useEffect(() => {
		if (selectedCityData)
			setCityData({
				name: selectedCityData?.name,
			});
	}, [selectedCityData]);

	// const handleSelectedCityManagers = (selectedManagers) => {
	// 	allSelectedCityManagers = selectedManagers;
	// };

	const handleSubmit = async (values) => {
		const submissionData = {
			...cityData,
			cityManagers: values.cityManagers?.map((manager) => manager._id),
		};

		if (values.isCloneDataFromAnotherCity) {
			submissionData.fromCityId = values.fromCity?._id;
		}

		if (!isEdit) {
			let { data, error } = await addCity(submissionData);

			if (data) {
				setToast({ type: "success", msg: "City added successfully" });
				closeModal();
			}
			if (error) {
				setToast({ type: "error", msg: error.data.message });
			}
		} else {
			let { data, error } = await updateCity({
				data: submissionData,
				cityId: selectedCityData._id,
			});

			if (data) {
				setToast({ type: "success", msg: "City updated successfully" });
				closeModal();
			}
			if (error) {
				setToast({ type: "error", msg: error.data.message });
			}
		}
	};

	return (
		<div className="w-[30rem] relative pb-6 pt-[2.5vw] 2xl:h-[40vw] h-[44vw] overflow-y-hidden modal-height">
			<div className="pl-5">
				<div className="flex justify-start items-center">
					<div className="h-12 w-12 rounded-full flex justify-center items-center modal-logo-size absolute top-[0.5vw]">
						<img src={isEdit ? editIcon : mapIcon} alt="" className="" />
					</div>
				</div>

				<div className="text-lg pt-6 font-inter-bold">{isEdit ? "Edit" : "Add New"} City</div>

				<div className="tracking-tight text-sm pb-5 text-[#475467] font-inter-regular">
					{isEdit
						? "Edit an existing area by changing it’s details"
						: "Add a new city by entering the details below"}
				</div>
			</div>
			<div className="pr-3 pb-5">
				<Formik
					initialValues={{
						cityManagers: isEdit ? selectedCityData?.employees : [],
						place: isEdit ? selectedCityData?.name : "",
						name: isEdit ? selectedCityData?.coOrdinates?.placeSearchName : "",
					}}
					validationSchema={CitySchema}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
				>
					{({ isSubmitting, values, setFieldValue, touched, errors }) => (
						<Form className="space-y-6 h-[30.5vw] overflow-y-scroll px-5 modal-form-height" action="#" method="POST">
							<Map place={"kondi"} setCityData={setCityData} selectedCityData={selectedCityData} />
							<div>
								<label className="text-sm font-medium flex gap-3 items-center">
									<Field
										type="checkbox"
										name="isCloneDataFromAnotherCity"
										className="rm-font-overpass border-[#ACACAC] bg-inherit checked:bg-[#ACACAC] hover:text-[#ACACAC] focus:ring-transparent focus:ring-offset-0 focus:checked:bg-[#ACACAC] "
									/>
									Copy all pre-defined services?
								</label>
								<div className="text-xs mt-2 text-[#828282]">
									Enabling this option will copy all pre-defined services from an existing city, and
									add them in this city. Select a city from where you want to copy the pre-defined
									services.{" "}
								</div>
							</div>
							<div>
								<SelectMenu
									name="fromCity"
									options={cities}
									onChange={(option) => setFieldValue("fromCity", option)}
									value={values.fromCity}
									placeholder="Select a City"
									className="w-full"
									label="Select City"
									getOptionValue={(option) => option._id}
									customeClassName= "font-inter-medium"
								/>
								{touched["fromCity"] && errors["fromCity"] ? (
									<div className="text-red-600 text-sm">{errors["fromCity"]}</div>
								) : (
									<div className="invisible h-5"></div>
								)}
							</div>
							<div>
								<SelectMenu
									isMulti={true}
									name="cityManagers"
									options={cityManagerList}
									onChange={(option) => setFieldValue("cityManagers", option)}
									value={values.cityManagers}
									placeholder="Select a City Manager"
									label="Assign a City Manager"
									getOptionValue={(option) => option._id}
									customeClassName= "font-inter-medium"
								/>
								{touched["cityManagers"] && errors["cityManagers"] ? (
									<div className="text-red-600 text-sm">{errors["cityManagers"]}</div>
								) : (
									<div className="invisible"></div>
								)}
							</div>
							<div className="flex justify-between ">
								<Button
									className="w-[47.5%] text-black bg-[#F1F1F1] cursor-pointer bottom-btn-ht"
									onClick={closeModal}
								>
									Cancel
								</Button>
								<Button
									className="w-[47.5%] text-white cursor-pointer bottom-btn-ht"
									type="submit"
									isSubmitting={addStatus==="pending" || updateStatus==="pending"}
								>
									Submit
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}
