import React, { useEffect, useState } from 'react'
import Vector from '../../assets/icons/Vector.svg'
import { useLazyGetServiceReportsDetailsQuery } from '../../utils/Services/Apis/serviceApi';
import useToaster from '../../utils/hooks/useToaster';

function ServiceReports() {
    const { setToast } = useToaster();
    const [reportsData, setReportsData] = useState([]);
    const [getReports] = useLazyGetServiceReportsDetailsQuery();

    useEffect(() => {
        handleGetAllReportsInfo();
    }, []);

    const handleGetAllReportsInfo = async () => {
        getReports().then(({ data }) => {
            setReportsData(data);
        }).catch((error) => {
            setToast({ type: "error", msg: error.data.message });
        })
    }

    return (
        <div className='bg-[#F5F5F5] h-full overflow-hidden p-8'>
            <div className="flex gap-3 mb-8">
                <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                    Service Management &#62; Reports
                </h3>
            </div>
            <div className='bg-white p-4 rounded-[20px] brand-details-section h-[75vh]'>
                <div className=''>
                    <div className='pt-2 pb-4'>
                        <h1 className='2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom pl-3'>Top 5 Selling Services</h1>
                    </div>
                    <div className='flex px-3 mt-2 gap-x-4'>
                        {
                            reportsData.map((report) => {
                                return (
                                    <div className='p-3 border rounded-xl shadow w-[20vw] '>
                                        <div className='flex space-x-2 items-center'>
                                            <img src={report.serviceImg} className='h-7 w-7 rounded-full' />
                                            <h1 className='text-base font-medium font-plus-jakarta text-[#666666]'>{report.serviceName}</h1>
                                        </div>
                                        <hr className='my-2 border-[#E5E5E5]' />
                                        <div className='py-3'>
                                            <h1 className='md:text-2xl text-2xl font-plus-jakarta font-bold text-[#1A1A1A]'>{report.count}</h1>
                                        </div>
                                        <div className='py-3'>
                                            <h1 className='text-xs font-plus-jakarta font-normal text-[#000000]'>Avail by {report.availBy} Customers</h1>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceReports
