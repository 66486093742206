import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useState } from "react";

import { BsEyeSlash, BsEye } from "react-icons/bs";

export default function FormField({
	field,
	error,
	nonFormikError = false,
	disabled = false,
	hideError = false,
	showPrefix = false,
	prefixLabel = "",
	fieldValue = 0,
}) {
	const [showPassword, setShowPassword] = useState(false);
	const { setFieldTouched, touched, errors } = useFormikContext();
	return (
		<div>
			<label
				htmlFor={field.name}
				className={`block text-sm font-medium leading-6 text-gray-900 ${field.customClassName}`}
			>
				{field.label}
			</label>
			<div className="mt-1 relative">
				{showPrefix ? (
					<div
						className={`flex justify-center border-gray-300 bg-[#FFF] items-center rounded-md border h-[2.3rem]`}
					>
						<label
							className={`flex justify-center pl-2 text-sm ${
								fieldValue ? (fieldValue >= 0 ? "text-[#000]" : "text-[#757575]") : "text-[#757575]"
							}`}
						>
							{prefixLabel}
						</label>

						<Field
							disabled={disabled}
							onKeyPress={field.onKeyPress}
							readOnly={field.readOnly}
							placeholder={field.placeholder}
							id={field.name}
							name={field.name}
							type={field.type !== "password" ? field.type : showPassword ? "text" : "password"}
							onFocus={() => setFieldTouched(field.name, false)}
							className={`${
								field.readOnly && "bg-gray-200"
							} block w-full appearance-none rounded-md border border-none px-3 2xl:py-2 py-1 placeholder-gray-400 shadow-sm focus:border-none focus:outline-none text-sm h-[2.15rem] focus:ring-offset-0 focus:ring-0`}
						/>
					</div>
				) : (
					<Field
						disabled={disabled}
						onKeyPress={field.onKeyPress}
						readOnly={field.readOnly}
						placeholder={field.placeholder}
						id={field.name}
						name={field.name}
						type={field.type !== "password" ? field.type : showPassword ? "text" : "password"}
						onFocus={() => setFieldTouched(field.name, false)}
						className={`${
							field.readOnly && "bg-gray-200"
						} block w-full appearance-none rounded-md border border-gray-300 px-3 2xl:py-2 py-1 placeholder-gray-400 shadow-sm focus:border-none focus:outline-none text-sm h-[2.3rem] focus:ring-offset-gray-300 focus:ring-gray-300`}
					/>
				)}


				{field.type === "password" ? (
					!showPassword ? (
						<BsEyeSlash
							className="formIcon-right max-w-[calc(7px + 0.694vw)] absolute  right-7 top-1/2  block max-h-[calc(6px+0.625vw)] -translate-y-1/2 text-sm  font-medium transition-all"
							onClick={() => setShowPassword(!showPassword)}
						/>
					) : (
						<BsEye
							className="formIcon-right max-w-[calc(7px + 0.694vw)] absolute  right-7 top-1/2  block max-h-[calc(6px+0.625vw)] -translate-y-1/2 text-sm  font-medium transition-all"
							onClick={() => setShowPassword(!showPassword)}
						/>
					)
				) : (
					<img
						src={field.icon}
						className="formIcon-right max-w-[calc(7px + 0.694vw)] absolute  right-5 top-1/2  block max-h-[calc(6px+0.625vw)] -translate-y-1/2 text-sm  font-medium transition-all"
						alt=""
						onClick={field.iconClick}
					></img>
				)}
			</div>

			{/* <ErrorMessage name={field.name} /> */}
			{touched[field.name] && errors[field.name] && !hideError ? (
				<div className="text-red-600 text-sm">{errors[field.name]}</div>
			) : (
				!nonFormikError && <div className="invisible h-5"></div>
			)}
		</div>
	);
}
