import React, { useState, useEffect } from 'react'
import TableComponent from '../../../shared/components/Table.component';
import { useLazyGetRiderLeadQuery, useLazyGetTotalTimeLoggedQuery } from '../../../utils/Services/Apis/riderManagementApi';
import { useParams } from 'react-router-dom';
import moment from 'moment'
import LeadsTabs from '../leads-module/Leads_Tab';
import DatePicker from "react-datepicker"
import { Calendar } from 'iconsax-react'

// function roundIfNeeded(number) {
//   if (number % 1 !== 0) {
//     return number?.toFixed(1);
//   } else {
//     return number;
//   }
// }
function roundIfNeeded(decimalHours) {
  // Separate the decimal part and the fractional part
  let hours = Math.floor(decimalHours);
  let minutes = Math.round((decimalHours - hours) * 60);

  // Format the result as a string
  let timeString = hours + ":" + (minutes < 10 ? "0" : "") + minutes;

  return timeString;
}


function TimeLogged({ selectedIndex }) {
    const [selectIndex, setSelectIndex] = useState(0);
    // const [selectedStatus, setSelectedStatus] = useState('today');
    const { riderId } = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [getTimeLogs, {data: timeLogs , status: timeLogsStatus}] = useLazyGetTotalTimeLoggedQuery()


    useEffect(()=>{
        if(selectIndex !== 3) {
           getTimeLogs({riderId, params:{dateRange: categories[selectIndex].status}})
        }
        if(selectIndex === 3 && startDate && endDate) {
            getTimeLogs({riderId, params:{
                dateRange: categories[selectIndex].status,
                startDate,
                endDate
            }})
        }
    },[startDate, endDate, selectIndex])

    let categories = [
        {
            title: `Today`,
            status: "today",

        },
        {
            title: `Current Week`,
            status: "currentWeek",
        },
        {
            title: `Current Month`,
            status: "currentMonth",
        },
        {
            title: `Custom Range`,
            status: "custom",
        },
    ]

    const columns = [

        {
            header: "Date",
            cell: ({ row, getValue }) => {
                const { original } = row;
                const isCustomLessThanMonth = original?.checkInDate?.split("-")?.length === 3
                return (
                    <div>{selectIndex === 0 && "Today, "}{(selectIndex === 3 && !isCustomLessThanMonth) ? moment(original?.checkInDate).format('MMMM') : moment(original?.checkInDate).format('dddd, DD/MM/YY')}</div>
                )
            }
        },
        {
            header: "Time Logged in Hours",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className='pl-2'>
                        <p>{roundIfNeeded(original?.totalTimeLogged)} Hours</p>
                    </span>
                )
            }
        },
    ]
    const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
        <div
            className={`flex gap-2 items-center border-[#000]/10 border rounded-[10px] text-sm h-[3.1rem] 2xl:w-[10rem] w-[10rem] py-2 pl-4 bg-white ${!value && "text-[#000]"}`}
            onClick={onClick}
            ref={ref}
        >
            <Calendar className="h-5 w-5" />
            {value || placeholder}
        </div>
    ));
    return (
        <div className=''>
            {selectedIndex == 4 && (
                <>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center space-x-6'>
                            <div className=''>
                                <LeadsTabs
                                    categories={categories}
                                    setSelectedIndex={setSelectIndex}
                                    selectedIndex={selectIndex}
                                    className='w-fit'
                                />
                            </div>
                            <div className={`flex items-center 2xl:space-x-4 space-x-4 ${selectIndex !== 3 && "hidden"}`}>
                                <div className="">
                                    <h1 className="text-base text-[#000] font-normal">Date Range :</h1>
                                </div>
                                <div className="calendar-icon-size all-rider-date-input">
                                    <DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        maxDate={moment().toDate()}
                                        customInput={<CustomInput />}
                                    />
                                </div>
                                <div className="">
                                    <h1 className="font-plus-jakarta text-sm text-center text-[#000] px-1">To</h1>
                                </div>
                                <div className="calendar-icon-size all-rider-date-input">
                                    <DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        minDate={moment(startDate).toDate()}
                                        maxDate={moment().toDate()}
                                        customInput={<CustomInput />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center gap-x-8'>
                            <h1 className='text-[#000] font-inter-bold text-lg'>Total Logged Hours : {roundIfNeeded(timeLogs?.total)}</h1>
                        </div>
                    </div>
                    <div className="bg-white p-5 mb-3 mt-3 pb-2 h-[40vh] overflow-hidden brand-details-section bg-white-height">
                        <div className="w-full mb-10">
                            <TableComponent
                                data={timeLogs?.timeLogged}
                                columns={columns}
                                customwidths={["w-[70%]", "w-[30%]"]}
                                isLoading={timeLogsStatus === "pending"}
                                customeClass="h-[17rem] pr-3"
                            />
                        </div>
                    </div>
                </>
            )
            }
        </div>
    )
}

export default TimeLogged

