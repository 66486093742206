import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Autocomplete } from "@react-google-maps/api";
import { useFormikContext } from "formik";

function MapDisplayAreas({ setCityData, selectedCityData }) {
	const { setFieldValue, errors, touched } = useFormikContext();
	const containerStyle = {
		width: "100%",
		height: "302px",
	};

	const [center, setPlaceCenter] = useState({
		lat: 18.529395,
		lng: 73.844788,
	});

	const featureStyleOptions = {
		strokeColor: "#810FCB",
		strokeOpacity: 1.0,
		strokeWeight: 3.0,
		fillOpacity: 0.5,
	};

	const placesServiceRef = useRef(null);
	const [map, setMap] = useState(null);

	useEffect(() => {
		if (map) {
			placesServiceRef.current = new window.google.maps.places.PlacesService(map);
		}
	}, [map]);

	useEffect(() => {
		if (!selectedCityData?.coOrdinates || !map) return;
		setPlaceCenter({
			lat: +selectedCityData?.coOrdinates.latitude,
			lng: +selectedCityData?.coOrdinates.longitude,
		});
		let featureLayer = map.getFeatureLayer("LOCALITY");
		featureLayer.style = (options) => {
			if (options?.feature?.placeId == selectedCityData.coOrdinates.place_id) {
				return featureStyleOptions;
			}
		};
	}, [selectedCityData, map]);

	const handlePlaceChanged = () => {
		if (placesServiceRef.current && autocompleteRef.current) {
			const place = autocompleteRef.current.getPlace();
			if (place && place.geometry && place.geometry.location) {
				const { lat, lng } = place.geometry.location;
				setPlaceCenter({ lat: lat(), lng: lng() });
				setCityData({
					coOrdinates: {
						latitude: lat(),
						longitude: lng(),
						place_id: place?.place_id,
						reference: place?.place_id,
						placeSearchName: place.formatted_address,
					},
					name: place?.name,
				});
				setFieldValue("name", place?.name);
				let featureLayer = map.getFeatureLayer("LOCALITY");
				featureLayer.style = (options) => {
					if (options?.feature?.placeId == place.place_id) {
						return featureStyleOptions;
					}
				};
			}
		}
	};

	const onLoad = (map) => {
		setMap(map);
	};

	const onUnmount = () => {
		setMap(null);
	};

	const autocompleteRef = useRef(null);

	return (
		<>
			<div>
				<label className="block font-medium text-sm mb-1 font-inter-medium">City Name</label>
				<Autocomplete
					onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
					onPlaceChanged={handlePlaceChanged}
					options={{ types: ["locality"] }}
				>
					<input
						defaultValue={selectedCityData?.coOrdinates?.placeSearchName}
						name="name"
						type="text"
						placeholder="Search for the City"
						className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm h-[2.3rem]"
					/>
				</Autocomplete>
				{touched["name"] && errors["name"] ? (
					<div className="text-red-600 text-sm">{errors["name"]}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>
			<div className="rounded-[10px] overflow-hidden map-at-zoom">
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={center}
					zoom={10}
					onLoad={onLoad}
					onUnmount={onUnmount}
					options={{ mapId: process.env.REACT_APP_MAP_ID }}
					version="beta"
				></GoogleMap>
			</div>
		</>
	);
}

export default MapDisplayAreas;
