import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const carApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAllowance: builder.query({
				query: () => ({
					url: endpoints.employeeMaster.getAllAllowance,
					method: "GET",
				}),
			}),
		};
	},
});

export const {
	useLazyGetAllowanceQuery
} = carApi;
export { carApi };
