import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import InfoIcon from "../../assets/icons/information.png";

import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import SelectMenuMultiselect from "../../shared/components/Select_Menu_Multiselect.component";
import { useNavigate } from "react-router-dom";
import Loader from '../../shared/components/Loader';
import { useDeleteCityMutation, useLazyGetCitesPaginationQuery } from "../../utils/Services/Apis/cityApi";
import useSelectModal from "../../utils/hooks/useSelectModal";
import AirlineIcon from "../../assets/icons/airline_stops.svg";
import { InfoCircle, Information } from "iconsax-react";
import { findEmployees } from "../../utils/helper/Find_Employees";
import useToaster from "../../utils/hooks/useToaster";

export default function CityManagement() {
	const [pageSize, setPageSize] = useState(10);
	const [pageNo, setPageNo] = useState(1);
	const [getCitesPagination, { data: cities, status: citiesListStatus }] = useLazyGetCitesPaginationQuery();

	const navigate = useNavigate();
	const { openModal } = useSelectModal();

	useEffect(() => {
		getCitesPagination({pageNo, pageSize});
	}, [pageNo]);

	// const [DisplayCityMap, set] = useState(React.lazy(() => import("./Display_City_Map")));

	const columns = [
		{
			header: "City Name",
			accessorKey: "name",
		},
		{
			header: "No.of Areas",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-14 items-center">
						<span>
							{original.areas?.length?.toString().length === 1
								? "0" + original.areas?.length
								: original.areas?.length}
						</span>
						<div className="relative group z-10">
							<Information
								color="#0d6efd"
								onClick={() => handleInfo(original)}
								size="16"
								className=" cursor-pointer info-circle-size"
							></Information>
							<div
								className={`absolute hidden ${
									original.areas?.length && "group-hover:block"
								}  left-4 top-0`}
							>
								<div className="flex flex-col bg-black rounded-lg text-white p-2 z-50 text-sm">
									{original.areas?.map((area) => {
										return <span className="whitespace-nowrap">{area.name}</span>;
									})}
								</div>
							</div>
						</div>
					</span>
				);
			},
		},
		{
			header: "City Manager",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const employees = original.employees
					?.filter((employee) => employee.roles.includes("citymanager"))
					?.map((employee, index, array) => (
						<span key={employee._id} className="whitespace-nowrap ">
							{employee.name}
							{array.length - 1 > index && ","}
						</span>
					));
				return (
					<div className="flex items-center w-full justify-between pr-10">
						<span className="flex gap-1 overflow-hidden max-w-[75%]">{employees}</span>
						<div className="relative group z-10">
							<Information
								color="#0d6efd"
								onClick={() => handleInfo(original)}
								size="16"
								className={`cursor-pointer ${!original.employees?.length && "hidden"} info-circle-size`}
							></Information>
							<div
								className={`absolute hidden ${
									original.employees?.length && "group-hover:block"
								}  left-4 top-0`}
							>
								<div className="flex flex-col bg-black rounded-lg text-white p-2 z-50 text-sm">
									{employees}
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			header: "Map View",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span
						className="relative"
						onClick={() =>
							openModal("DisplayCityMap", { city: original.coOrdinates, hideCloseArrow: false })
						}
					>
						<span className="flex items-center gap-2 border rounded-3xl max-w-max px-6 py-1.5">
							<span>View in map</span>
							<img src={AirlineIcon} alt="" className="h-5 w-5" />
						</span>
					</span>
				);
			},
		},
		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-2 pl-3">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							className="h-5 w-5 cursor-pointer"
							onClick={() =>
								navigate(`/area-management/${original._id}`, { state: { cityData: original } })
							}
						/>
						<img
							src={EditIcon}
							alt=""
							onClick={() => handleEdit(original)}
							className="h-5 w-5 cursor-pointer"
						/>
						<img
							src={DeleteIcon}
							alt=""
							onClick={() => handleDelete(original)}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

	const handleEdit = (cityData) => {
		openModal("AddNewCity", cityData);
	};

	const handleInfo = () => {};
	const handleDelete = (cityData) => {
		openModal("DeleteCityModal", { ...cityData, hideCloseArrow: true });
	};

	const handlePageChange = (type) => {
		let tempCurrentPage = pageNo;
		if(type === 'prev') {
			tempCurrentPage -= 1;
		} else {
			tempCurrentPage += 1;
		}
		setPageNo(tempCurrentPage);
	}

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Area and City Management</h3>
					<div className="flex justify-between items-center mt-3">
						<p className="2xl:text-[20px] text-[18px] font-futura-medium tracking-tight text-gray-900 subheading-at-zoom">
							City Management
						</p>
						<Button
							className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
							onClick={() => openModal("AddNewCity")}
						>
							Add New City
						</Button>
					</div>
				</div>
			</div>
			<div className="bg-white border rounded-xl px-5 mx-8 my-3 h-[70.4vh] overflow-hidden brand-details-section relative">
				<div className="w-full">
					{/* {
						citiesListStatus !== "fulfilled" ?
						<Loader isLoading={true}/> : 
						<TableComponent
							data={cities?.result}
							columns={columns}
							customwidths={["w-[15%]", "w-[15%]", "w-[40%]", "w-[15%]", "w-[15%]"]}
						/>
					} */}
					<TableComponent
						data={cities?.result}
						columns={columns}
						customwidths={["w-[15%]", "w-[15%]", "w-[40%]", "w-[15%]", "w-[15%]"]}
						isLoading = {citiesListStatus === "pending"}
						customeClass='pr-3 max-h-[56vh]'
					/>
				</div>
				{
					cities?.totalPages > 1 && 
					<div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
						<div className=''>
							<Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`} 
								disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
						</div>
						<div className=''>
							<Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == cities?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`}
							disabled={pageNo == cities?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
						</div>
						<div className=''>
							<p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {cities?.totalPages}</p>
						</div>
					</div>
				}
			</div>
		</div>
	);
}
