import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "iconsax-react";
import moment from "moment";
import { useFormikContext } from "formik";

const CustomInput = React.forwardRef(({ value, onClick , placeholder }, ref) => (
  <div
    className={`flex gap-3 border rounded-md text-sm h-[2.3rem] p-2 bg-white pr-4 ${!value && "text-gray-500"}`}
    onClick={onClick}
    ref={ref}
  >
    <Calendar className="h-5 w-5" />
    {value || placeholder}
  </div>
));


const Calender = ({ name="date", placeholderText="Select Date", maxDate, disabled=false }) => {
  const {setFieldValue,values} =useFormikContext()
  const now = moment().toDate();
  return (
    <>
      <DatePicker
        minDate={now} 
        maxDate={maxDate}
        showIcon
        selected={values[name]}
        onChange={(date) => setFieldValue(name,date)}
        // showTimeSelect
        dateFormat="d MMMM, yyyy"
        customInput={<CustomInput />}
        placeholderText={placeholderText}
        disabled={disabled}
      />
    </>
  );
};
export default Calender;