import React, { memo, useEffect } from 'react'
import onJobIcon from "../../assets/icons/rider-status/on-job.svg"
import onlineIcon from "../../assets/icons/rider-status/online.svg"
import offlineIcon from "../../assets/icons/rider-status/offline.svg"
import outOfRadiusIcon from "../../assets/icons/rider-status/out-of-radius.svg"
import radiusIcon from "../../assets/icons/rider-status/Line 269.svg"
import { useLazyGetOrderCountByRiderOfMapQuery } from '../../utils/Services/Apis/riderManagementApi'

function MapInfoCard({ riderId }) {

    const [getOrderCountByRiderOfMap, { data: getRiderOfMapList }] = useLazyGetOrderCountByRiderOfMapQuery();

    useEffect(() => {
        if (riderId)
            getOrderCountByRiderOfMap(riderId);
    }, [riderId]);

    return (
        <div className="z-50 w-[18rem]">
            {getRiderOfMapList?.riders?.map((rider) => (
                <div className="bg-white rounded-lg shadow-lg p-1">
                    <div className="flex items-center justify-between mb-2 w-full">
                        <div className="w-[70%]">
                            <p className="text-sm text-[#000] font-inter-bold">{rider?.name}</p>
                        </div>
                        <div className='w-[30%]'>
                            <div className={`w-8 h-8 rounded-full flex justify-center items-center ${rider?.riderStatus === "onJob" ? 'border-[#0D6EFD] border-2' : rider?.riderStatus === "online" ? 'border-[#28C501] border-2' : ''}`}>
                                <img src={rider?.riderStatus === "onJob" ? onJobIcon
                                    : rider?.riderStatus === "online" ? onlineIcon
                                        : rider?.riderStatus === "offline" ? offlineIcon
                                            : rider?.riderStatus === "outOfRadius" ? outOfRadiusIcon
                                                : rider?.riderStatus === "Radius" ? radiusIcon
                                                    : ""
                                }
                                    className={`${rider?.riderStatus === "outOfRadius" ? 'h-8 w-8' : rider?.riderStatus === "offline" ? 'h-8 w-8' : 'h-6 w-6'}`} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center justify-between w-full">
                            <span className="text-sm font-inter-bold text-[#000] w-[70%]">Today's Details</span>
                            <div className="flex w-[30%]">
                                <span className="text-sm">
                                    {rider?.rating === 0 ? (<span className='text-[#000]'>★★★★★</span>) :
                                        rider?.rating === 1 ? (<span className='text-[#FFC24C]'>★<span className='text-[#000]'>★★★★</span></span>) :
                                            rider?.rating === 2 ? (<span className='text-[#FFC24C]'>★★<span className='text-[#000]'>★★★</span></span>) :
                                                rider?.rating === 3 ? (<span className='text-[#FFC24C]'>★★★<span className='text-[#000]'>★★</span></span>) :
                                                    rider?.rating === 4 ? (<span className='text-[#FFC24C]'>★★★★<span className='text-[#000]'>★</span></span>) :
                                                        (<span className='text-[#FFC24C]'>★★★★★</span>)}
                                </span>
                            </div>
                        </div>
                        <hr className="my-1 border border-[#757575]/0.5" />
                        <div className="flex items-center justify-between w-full">
                            <span className="text-xs font-inter-medium text-[#000] w-[70%]">Services :</span>
                            <span className="text-xs font-inter-bold text-[#000] w-[30%]">{rider?.todayServices}</span>
                        </div>
                        <div className="flex items-center justify-between mt-1.5 w-full">
                            <span className="text-xs font-inter-medium text-[#000] w-[70%]">Completed :</span>
                            <span className="text-xs font-inter-bold text-[#000] w-[30%]">{rider?.completedServices}</span>
                        </div>
                        <div className="flex items-center justify-between mt-1.5 w-full">
                            <span className="text-xs font-inter-medium text-[#000] w-[70%]">Pending :</span>
                            <span className="text-xs font-inter-bold text-[#000] w-[30%]">{rider?.upcomingServices}</span>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center justify-between w-full">
                            <span className="text-sm font-inter-bold text-[#000] w-[70%]">Monthly Details:</span>
                            <div className="flex w-[30%]">
                                <span className="text-sm">
                                    {rider?.rating === 0 ? (<span className='text-[#000]'>★★★★★</span>) :
                                        rider?.rating === 1 ? (<span className='text-[#FFC24C]'>★<span className='text-[#000]'>★★★★</span></span>) :
                                            rider?.rating === 2 ? (<span className='text-[#FFC24C]'>★★<span className='text-[#000]'>★★★</span></span>) :
                                                rider?.rating === 3 ? (<span className='text-[#FFC24C]'>★★★<span className='text-[#000]'>★★</span></span>) :
                                                    rider?.rating === 4 ? (<span className='text-[#FFC24C]'>★★★★<span className='text-[#000]'>★</span></span>) :
                                                        (<span className='text-[#FFC24C]'>★★★★★</span>)}
                                </span>
                            </div>
                        </div>
                        <hr className="my-1 border border-[#757575]/0.5" />
                        <div className="flex items-center justify-between w-full">
                            <span className="text-xs font-inter-medium text-[#000] w-[70%]">On Time :</span>
                            <span className="text-xs font-inter-bold text-[#000] w-[30%]">{rider?.onTime}</span>
                        </div>
                        <div className="flex items-center justify-between mt-1.5 w-full">
                            <span className="text-xs font-inter-medium text-[#000] w-[70%]">Delayed :</span>
                            <span className="text-xs font-inter-bold text-[#000] w-[30%]">{rider?.delayed}</span>
                        </div>
                        <div className="flex items-center justify-between mt-1.5 w-full">
                            <span className="text-xs font-inter-medium text-[#000] w-[70%]">Before Time :</span>
                            <span className="text-xs font-inter-bold text-[#000] w-[30%]">{rider?.beforeTime}</span>
                        </div>

                    </div>
                </div>
            ))}
        </div>
    )
}

export default memo(MapInfoCard);