import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../shared/components/Button.component";
import FormField from "../../../../shared/components/FormField.component";
import { Form, Formik, useFormikContext } from "formik";
import useToaster from "../../../../utils/hooks/useToaster";
import { useLocation, useNavigate } from "react-router-dom";
import useSelectModal from "../../../../utils/hooks/useSelectModal";
import BackButton from "../../../../shared/components/BackButton.component";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import steperIcon from "../../../../assets/icons/steper.svg";
import checkedIcon from "../../../../assets/icons/checkedIcon.svg";

function CreateMenuMaster() {

	const [currentStep, setCurrentStep] = useState(0);
	const location = useLocation();
	const { state } = location;
	const { setToast } = useToaster();
	const navigate = useNavigate();
	const { openModal } = useSelectModal();

	const step1Schema = Yup.object()
		.shape({
			name: Yup.string()
				.required("Menu Master Title is required")
				.max(30, "Menu Master Title should not exceed 30 characters")
				.matches(/[a-zA-Z]/, "Menu Master Title must contain letters"),
			order: Yup.string().required("Sort Order is required"),
			url: Yup.string()
				.required("URL is required")
				.matches(/^\d{10}$/, "Please enter URL"),
			component: Yup.string()
				.required("Component Name is required")
				.max(30, "Component Name should not exceed 30 characters")
				.matches(/[A-Za-z]/, "Component Name must contain letters"),
		})

	const stepValidationSchemas = [
		step1Schema,
	];

	const steps = [
		{ title: "Basic Information" },
	];



	const handleOnValidate = async (values, setFieldValue) => {

		const bodyObj = {
			name: values.name,
			order: values.order,
			url: values.url,
			component: values.component,
		};

		// const { data, error } = await addFranchise(bodyObj);
		// if (data) {
		// 	openModal("FranchiseCreatedModal", { name: data?.franchise?.name, hideCloseArrow: true });
		// 	navigate("/franchise-management")
		// 	setCurrentStep(Math.min(currentStep + 1, steps.length - 1));
		// }
		// if (error) setToast({ type: "error", msg: error?.data?.message });
	}

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 10,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage:
					"linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage:
					"linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
			borderRadius: 1,
		},
	}));

	const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
		zIndex: 1,
		color: "#fff",
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	}));

	function ColorlibStepIcon(props) {
		const { active, completed, className, icon } = props;
		const icons = <img src={steperIcon} alt="" className="opacity-25 " />;
		const activeIcons = <img src={steperIcon} alt="" />;
		const completedIcons = <img src={checkedIcon} alt="" />;
		const displayIcon = completed ? completedIcons : active ? activeIcons : icons;
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
				{displayIcon}
			</ColorlibStepIconRoot>
		);
	}

	return (
		<div className="bg-[#F5F5F5] h-full overflow-hidden">
			<div className="p-8">
				<div className="flex items-center gap-3 mb-8">
					<BackButton />
					<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
						Create Menu Master
					</h3>
				</div>
				<div className="bg-white rounded-[20px] brand-details-section 2xl:pt-4 pb-6 h-[78vh] stepper-sm">
					<Formik
						initialValues={{
							name: "",
							order: "",
							url: "",
							component: "",
						}}
						validationSchema={() => {
							switch (currentStep) {
								case 0:
									return step1Schema;
								default:
									return Yup.object().shape({});
							}
						}}
						onSubmit={(values) => { }}
					>
						{({
							isSubmitting,
							values,
							setFieldValue,
							errors,
							setErrors,
							setTouched,
							setFieldTouched,
						}) => (
							<Form className="space-y-3" action="#" method="POST">
								<Stack sx={{ width: "100%", paddingTop: "30px" }} spacing={4}>
									<Stepper
										alternativeLabel
										activeStep={currentStep}
										connector={<ColorlibConnector />}
									>
									</Stepper>
								</Stack>
								<div className="">
									<BasicInformation />
								</div>
								<div className="w-[17.5vw] flex justify-center mx-auto py-4">
									<Button
										// onClick={() => nextStep(values, setFieldValue, setErrors, setTouched, setFieldTouched)}
										className="w-full border text-[#FFF] bg-black bottom-btn-ht font-inter-bold "
									// isSubmitting={status === "pending"}
									>
										Save
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}
export default CreateMenuMaster;

const BasicInformation = () => {

	const { setFieldValue, values, errors, touched } = useFormikContext();
	return (
		<div className="flex justify-center ">
			<div className="p-6 w-[45vw] max-h-[56vh] flex items-center flex-col rounded-xl border bg-[#F9FAFB]">
				<div className="mb-4">
					<h1 className="text-lg font-inter-bold">Basic Information</h1>
				</div>
				<div className="w-full space-y-2">
					<FormField
						field={{
							label: "Menu Master Title*",
							name: "name",
							type: "text",
							placeholder: "Enter Title",
							customClassName: "font-inter-medium",
						}}
					/>
					<FormField
						field={{
							label: "Sort Order*",
							name: "order",
							type: "text",
							placeholder: "Enter Sort Order",
							customClassName: "font-inter-medium",
						}}
					/>
					<FormField
						field={{
							label: "URL*",
							name: "url",
							type: "text",
							placeholder: "Enter URL",
							customClassName: "font-inter-medium",
						}}
					/>
					<FormField
						field={{
							label: "Component Name*",
							name: "component",
							type: "text",
							placeholder: "Enter Component Name",
							customClassName: "font-inter-medium",
						}}
					/>
				</div>
			</div>
		</div>
	);
};


const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
	<div
		className={`flex gap-3 border rounded-md text-sm h-[2.3rem] p-2 bg-white pr-4 ${!value && "text-gray-500"
			}`}
		onClick={onClick}
		ref={ref}
	>
	</div>
));

