import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const fileUploadApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			uploadFile: builder.mutation({
				query: (body) => ({
					url: endpoints.upload.image,
					body: body,
					method: "POST",
				}),
			}),
		};
	},
});

export const { useUploadFileMutation } = fileUploadApi;
export { fileUploadApi };
