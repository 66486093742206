import React, { useState } from "react";
import DeleteIcon from "../../assets/icons/Delete.png";
import EditIcon from "../../assets/icons/Edit.png";
import InfoCircle from "../../assets/icons/infocircle.svg";

import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Toggle from "../../shared/components/toggle.component";
import { Location, SearchNormal1 } from "iconsax-react";
import { useEffect } from "react";
import {
	useGetAllServicesMutation,
	useUpdateServiceMutation,
} from "../../utils/Services/Apis/serviceApi";
import { useNavigate, useParams } from "react-router-dom";
import useToaster from "../../utils/hooks/useToaster";
import { useGetAreaListMutation, useLazyGetCitesQuery } from "../../utils/Store";
import { useLazyGetAllCarTypesQuery } from "../../utils/Services/Apis/carMasterData";
import SelectMenuIcon from "../../shared/components/SelectMenuIcon.component";

export default function AllServices() {
	const { openModal } = useSelectModal();
	const [getAllServices, { data: allServices, status: allServicesList }] = useGetAllServicesMutation();
	const [updateService] = useUpdateServiceMutation();
	const [selectedCity, setSelectedCity] = useState([]);
	const [selectedArea, setSelectedArea] = useState([]);
	const [getCites, { data: cities }] = useLazyGetCitesQuery();
	const [getAreas, { data: areaDetails }] = useGetAreaListMutation();
  const [allSelectedCityAreas, setAllSelectedCityAreas ] = useState([]);

	useEffect(() => {
			if (selectedCity?.length) {
					handleGetCityAreas()
			} else {
					setAllSelectedCityAreas([]);
					setSelectedArea(null);
			}
	}, [selectedCity]);

	useEffect(()=>{
		if(allSelectedCityAreas?.length && selectedArea?.length){
			const remainingAreas = allSelectedCityAreas.filter(area1=> selectedArea.find(area=> area._id === area1._id)) || []
			setSelectedArea(remainingAreas)
		}
	},[allSelectedCityAreas])

	const handleGetCityAreas = async() => {
			let { data } = await getAreas({
					cityId: selectedCity.map((city, index) => city._id)
			});
			setAllSelectedCityAreas(data);
	}

	const handleCityChange = (option) => {
		setSelectedCity(option);
	};
	
	const handleAreaChange = (option) => {
			setSelectedArea(option);
	};

	const [services, setServices] = useState();
	const [getCarTypes, { data: AllCarTypes }] = useLazyGetAllCarTypesQuery();

	useEffect(() => {
		getCarTypes();
	}, []);

	useEffect(() => {
		if (allServices) setServices(allServices);
	}, [allServices]);

	const navigate = useNavigate();
	const { setToast } = useToaster();

	const [prevBodyObj, setPrevBodyObj] = useState(null);
	
	useEffect(() => {
		let bodyObj = {
				cityId: [],
				areaId: []
		}
		if (selectedCity?.length) {
				bodyObj.cityId = selectedCity.map((city) => city._id)
		}
		if (selectedArea?.length) {
				bodyObj.areaId = selectedArea.map((area) => area._id)
		}
		if (JSON.stringify(bodyObj) !== JSON.stringify(prevBodyObj)) {
      setPrevBodyObj(bodyObj);
      getAllServices(bodyObj);
    }
	}, [selectedArea, allSelectedCityAreas, prevBodyObj]);

	useEffect(() => {
		getCites();
	}, []);

	const handleDelete = (rowData) => {
		openModal("DeleteServiceModal", { ...rowData, hideCloseArrow: true, type: "Service" });
	};

	const handleActionToggle = (serviceData) => {
		updateService({
			data: {
				isActive: !serviceData.isActive,
			},
			serviceId: serviceData._id,
		})
			.then((data) => {
				!serviceData.isActive
					? setToast({ type: "success", msg: "Service is Enabled" })
					: setToast({ type: "success", msg: "Service is Disabled" });
			})
			.catch((err) => {
				setToast({ type: "error", msg: err.data.message });
			});
	};

	const columns = [
		{
			header: "Services",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center w-full">
						<div className="bg-[#F1F1F1] rounded-2xl p-1 h-7 w-7 items-center shrink-0">
							<img
								src={original?.imageUrl}
								alt=""
								onClick={() => navigate(`/service-management/${original?._id}`)}
								className="h-5 w-5"
							/>
						</div>
						<span className="truncate">{original?.serviceName}</span>
					</span>
				);
			},
		},

		{
			header: "Service Time",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center">
						{original?.serviceTime[0]?.hours !== 0 &&
							`${original?.serviceTime[0]?.hours} ${original?.serviceTime[0]?.hours === 1 ? 'hour' : 'hr'
							}`}{" "}
						{original?.serviceTime[0]?.minutes !== 0 &&
							`${original?.serviceTime[0]?.minutes} min`}
					</span>
				);
			},
		},
		{
			header: "Service Type",
			accessorKey: "serviceType",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center">
						{original?.serviceType === "onetimeservice" ? "One time" : "Subscription"}
					</span>
				);
			},
		},
		{
			header: "Final Price",
			cell: ({ row, getValue }) => {
				const { original } = row;
						return	(<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar">
						{AllCarTypes?.map((carType) => (
							<span className="whitespace-nowrap">
								{carType.name} ₹{" "}
								{original.carType?.find((item) => item.type === carType.value)?.afterGSTPrice
									? original.carType?.find((item) => item.type === carType.value)?.afterGSTPrice
									: 0}
							</span>
						))}
					</span>)				
			},
		},

		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center 2xl:ml-10">
						<img
							src={InfoCircle}
							alt=""
							onClick={() => navigate(`/service-management/${original?._id}`)}
							className="h-5 w-5 cursor-pointer"
						/>
						<Toggle
							defaultChecked={original.isActive}
							handleOnToggle={() => handleActionToggle(original)}
						/>
						<img
							src={EditIcon}
							alt=""
							onClick={() => openModal("ServiceModal", original)}
							className="h-5 w-5 cursor-pointer"
						/>

						<img
							src={DeleteIcon}
							alt=""
							onClick={() => handleDelete(original)}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
							Service Management &#62; All Services
						</h3>
						<Button
							onClick={() => openModal("ServiceModal")}
							className="2xl:text-base text-sm text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6"
						>
							Add New Service
						</Button>
					</div>
				</div>

				<div className="bg-white p-5 mx-8 my-3 pb-2 h-[75vh] overflow-hidden brand-details-section bg-white-height">
					<div className="flex items-center justify-between">
						<div className="relative h-12">
							<input
								type="text"
								className="w-[18.35vw] outline-focus h-full border-none rounded-xl py-1.5 px-12 text-base font-normal bg-[#F1F1F1] placeholder-black"
								placeholder="Search Service"
								onChange={(e) => {
									const searchedEmployees = allServices.filter((employee) =>
										employee.serviceName.toLowerCase().includes(e.target.value?.toLowerCase())
									);
									setServices(searchedEmployees);

									if (e.target.value.length === 0) searchedEmployees(allServices);
								}}
							/>

							<div className="absolute left-5 top-[50%] translate-y-[-50%] mr-3">
								<SearchNormal1 color="gray" size={20} className="search-icon " />
							</div>
						</div>
						<div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
							<div className="">
									<h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
							</div>
							<div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
									<SelectMenuIcon
											placeholder='All Cities'
											options={cities}
											multiSelectClass="search-customer-height multi-time-slot"
											isMulti={true}
											onChange={handleCityChange}
											value={selectedCity}
									/>
									<Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
							</div>
					</div>
					<div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
							<div className="">
									<h1 className="text-base text-[#333333] font-normal">Filter by Area:</h1>
							</div>
							<div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
									<SelectMenuIcon
											placeholder='All Areas'
											options={allSelectedCityAreas}
											multiSelectClass="search-customer-height multi-time-slot"
											isMulti={true}
											onChange={handleAreaChange}
											value={selectedArea}
									/>
									<Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
							</div>
					</div>
					</div>

					<div>
						<TableComponent
							data={services}
							columns={columns}
							customwidths={["w-[25%]", "w-[15%]", "w-[15%]", "w-[30%]", "w-[15%]"]}
							isActionColumnTable={true}
							isLoading={allServicesList === "pending"}
							customeClassAction = "action-col-alignment"
							customeClass='pr-3 max-h-[56vh]'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
