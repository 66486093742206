import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const areaMasterApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAllRadiusTypes: builder.query({
				query: () => ({
					url: endpoints.areaMaster.getAllRadiusType,
					method: "GET",
				}),
				providesTags: ["RadiusType"],
			}),
        	deleteRadiusType: builder.mutation({
				query: (radiusTypeId) => ({
					url: endpoints.areaMaster.deleteRadiusType(radiusTypeId),
					method: "DELETE",
				}),
				invalidatesTags: ["RadiusType"]
			}),
			updateRadiusType: builder.mutation({
				query: ({radiusTypeId, data}) => ({
					url: endpoints.areaMaster.updateRadiusType(radiusTypeId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["RadiusType"]
			}),
			addRadiusType: builder.mutation({
				query: (body) => ({
					url: endpoints.areaMaster.addRadiusType,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["RadiusType"]
			}),

			// Time Slot API's
			getAllTimeSlots: builder.query({
				query: () => ({
					url: endpoints.areaMaster.getAllTimeSlots,
					method: "GET",
				}),
				providesTags: ["timeslot"],
			}),
			getTimeSlotPagination: builder.query({
				query: ({pageNo, pageSize}) => {
					return {
						url: endpoints.timeSlot.getTimeSlotPagination(pageNo, pageSize),
						method: "GET",
					};
				},
				providesTags: ["timeslot"],
			}),
			deleteTimeSlot: builder.mutation({
				query: (slotId) => ({
					url: endpoints.areaMaster.deleteTimeSlot(slotId),
					method: "DELETE",
				}),
				invalidatesTags: ["timeslot"]
			}),
			updateTimeSlot: builder.mutation({
				query: ({slotId, data}) => ({
					url: endpoints.areaMaster.updateTimeSlot(slotId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["timeslot"]
			}),
			addTimeSlot: builder.mutation({
				query: (body) => ({
					url: endpoints.areaMaster.addTimeSlot,
					body: body,
					method: "POST",
				}),
				// invalidatesTags: ["timeslot"]
			}),
		};
	},
});

export const {
	// Radius type Master
	useLazyGetAllRadiusTypesQuery,
	useDeleteRadiusTypeMutation,
	useUpdateRadiusTypeMutation,
	useAddRadiusTypeMutation,
	
	// Time slot Master
	useLazyGetAllTimeSlotsQuery,
	useDeleteTimeSlotMutation,
	useUpdateTimeSlotMutation,
	useAddTimeSlotMutation,
	useLazyGetTimeSlotPaginationQuery,
} = areaMasterApi;
export { areaMasterApi };
