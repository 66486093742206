import React from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { useSignupMutation } from "../../utils/Store";

const SignUp = (props) => {
	const [signup, resp] = useSignupMutation();

	const handleSignup = (values) => {
		signup(values);
	};

	return (
		<div className="flex h-4/5 flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img
					className="mx-auto h-12 w-auto"
					src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
					alt="Your Company"
				/>
				<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
					Register
				</h2>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<Formik
						initialValues={{
							firstName: "",
							lastName: "",
							mobile: "",
							email: "",
							password: "",
							"confirm-password": "",
						}}
						validate={(values) => {
							const errors = {};
							if (!values.email) {
								errors.email = "Email Required";
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = "Invalid email address";
							} else if (!values.password) {
								errors.password = "Password Required";
							} else if (values.password?.length < 8) {
								errors.password = "Password should contain minimum 8 characters.";
							}
							return errors;
						}}
						onSubmit={(values) => {
							handleSignup(values);
						}}
					>
						{({ isSubmitting }) => (
							<Form className="space-y-6" action="#" method="POST">
								<div>
									<label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
										First Name
									</label>
									<div className="mt-1">
										<Field
											id="firstName"
											name="firstName"
											type="text"
											required
											className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
										/>
									</div>
								</div>
								<div>
									<label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
										Last Name
									</label>
									<div className="mt-1">
										<Field
											id="lastName"
											name="lastName"
											type="text"
											required
											className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
										/>
									</div>
								</div>
								<div>
									<label htmlFor="email" className="block text-sm font-medium text-gray-700">
										Email address
									</label>
									<div className="mt-1">
										<Field
											id="email"
											name="email"
											type="email"
											autoComplete="email"
											required
											className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
										/>
										{/* <ErrorMessage component="div" name="email" /> */}
									</div>
								</div>
								<div>
									<label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
										Mobile Number
									</label>
									<div className="mt-1">
										<Field
											id="mobile"
											name="mobile"
											type="number"
											required
											className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
										/>
									</div>
								</div>
								<div>
									<label htmlFor="password" className="block text-sm font-medium text-gray-700">
										Password
									</label>
									<div className="mt-1">
										<Field
											id="password"
											name="password"
											type="password"
											autoComplete="current-password"
											required
											className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
										/>
									</div>
								</div>
								<div>
									<label htmlFor="password" className="block text-sm font-medium text-gray-700">
										Confirm Password
									</label>
									<div className="mt-1">
										<Field
											id="confirm-password"
											name="confirm-password"
											type="password"
											required
											className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="flex items-center justify-between">
									<div className="text-sm">
										<a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
											Forgot your password?
										</a>
									</div>
								</div>

								<button
									type="submit"
									className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									Sign in
								</button>
							</Form>
						)}
					</Formik>{" "}
				</div>
			</div>
		</div>
	);
};

export default SignUp;
