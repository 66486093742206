import { useState } from "react";
import Select, { components } from "react-select";

const customStyles = {
	control: (provided) => ({
		...provided,
		borderColor: " 1px solid rgba(0, 0, 0, 0.12)", // set border color to black
		backgroundColor: "#FFF",
		boxShadow: "none", // remove box shadow
		display: "flex",
		borderRadius: "0.375rem",
		fontSize: "14px",
		"&:hover": {
			// borderColor: "#e5e7eb", // ensure border remains black even on hover
		},
	}),
	menuList: (provided) => ({
		...provided,
		padding: 0,
		border: "none",
		maxHeight: '200px'
	}),
	option: (provided, state) => ({
		...provided,
		borderBottom: "none", // Removes the divider
		color: state.isSelected ? "white" : "black",
		backgroundColor: state.isSelected ? "blue" : "transparent",
		// "&:hover": {
		// 	backgroundColor: "#fff",
		// },
	}),
	// option: (provided, state) => ({
	// 	...provided,
	// 	borderBottom: "none", // Removes the divider
	// 	color: state.isSelected ? "white" : "black",
	// 	backgroundColor: state.isSelected ? "blue" : "transparent",
	// 	"&:hover": {
	// 		backgroundColor: "lightgray",
	// 	},
	// }),
};

const InputOption = ({
	getStyles,
	Icon,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	inputType,
	showCheckboxOnRight,
	showCheckbox,
	...rest
}) => {
	const [isActive, setIsActive] = useState(false);
	const onMouseDown = () => setIsActive(true);
	const onMouseUp = () => setIsActive(false);
	const onMouseLeave = () => setIsActive(false);

	// styles
	let bg = "transparent";
	if (isFocused) bg = "#eee";
	// if (isActive) bg = "#B2D4FF";

	const style = {
		appearance: 'none',
		alignItems: "center",
		backgroundColor: bg,
		color: "inherit",
		display: "flex ",
		justifyContent: showCheckboxOnRight ? "space-between" : "",
		flexDirection: (showCheckboxOnRight && showCheckbox) ? "row-reverse" : "row",
		fontSize: "14px",
	};

	// prop assignment
	const props = {
		...innerProps,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
		style,
	};

	return (
		<components.Option
			{...rest}
			isDisabled={isDisabled}
			isFocused={isFocused}
			isSelected={isSelected}
			getStyles={getStyles}
			innerProps={props}
		>
			{ showCheckbox && <input type={inputType} checked={isSelected} readOnly style={{marginRight : 10}}/> }
			{ children ? children?.length < 22 ? children :  children + '...' : ""}
		</components.Option>
	);
};

export default function SelectMenu({
	options,
	value,
	onChange,
	onBlur,
	name,
	isMulti = false,
	icon,
	placeholder = "Select",
	label,
	optionLabel = "name",
	// getOptionValue = (option) => (option._id ? option._id : option[optionLabel]),
	isClearable,
	disabled,
	showCheckboxOnRight = true,
	inputType = "checkbox",
	showCheckbox=true,
	customeClassName,
	hideSelectedOptions = true,
	isLoading= false,
	multiSelectClass,
	menuPlacement,
	classNameForAllRiders
}) {

	const WrappedInputOption = (props) => {
		return <InputOption {...props} showCheckboxOnRight={showCheckboxOnRight} inputType={inputType} showCheckbox={showCheckbox} />
	}
	return (
		<div>
			<label htmlFor={name} className={`block font-medium text-sm mb-1 ${customeClassName}`}>
				{label}
			</label>

			<div className={`select-class-custom-class flex ${classNameForAllRiders}`}>
				{icon && <div className="pt-2.5 z-10 relative">{icon}</div>}
				<Select
					getOptionValue={(option) => (option._id ? option._id : option[optionLabel])}
					getOptionLabel={(option) => option[optionLabel]}
					options={options}
					isMulti={isMulti}
					name={name}
					value={value}
					onChange={onChange}
					// onBlur={onBlur}
					placeholder={placeholder}
					className={`basic-multi-select w-full ${multiSelectClass} ${isMulti && 'multi-time-slot'}`}
					classNamePrefix="select"
					styles={customStyles} // Apply the custom styles here
					components={{
						Option: WrappedInputOption,
					}}
					isClearable={isClearable}
					isDisabled={disabled}
					hideSelectedOptions={hideSelectedOptions}
					isLoading={isLoading}
					menuPlacement={menuPlacement}
				/>
			</div>
		</div>
	);
}
