import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const onDemandApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getWidgetCount: builder.query({
				query: () => ({
					url: endpoints.onDemandJob.widgetCount,
					method: "GET",
				}),
				providesTags: ["OrderCountOd"],
			}),
			getOrders: builder.mutation({
				query: ({ status, body }) => ({
					url: endpoints.onDemandJob.getOrders(status),
					method: "POST",
					body: body,
				}),
				providesTags: ["GetOrdersOd"],
			}),

			// Reasons Master Data
			getReasons: builder.query({
				query: () => ({
					url: endpoints.onDemandJob.reasonsMaster,
					method: "GET",
				}),
			}),

			// update appointment
			updateAppointment: builder.mutation({
				query: ({ orderId, status, data }) => ({
					url: endpoints.onDemandJob.updateAppointment(orderId, status),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["GetOrdersOd", "OrderCountOd"],
			}),

			verifyOtp: builder.mutation({
				query: ({ orderId, otp }) => ({
					url: endpoints.onDemandJob.verifyOtp(orderId, otp),
					method: "POST",
				}),
			}),

			updateAppointmentAfterOtpVerification: builder.mutation({
				query: ({ orderId, body }) => ({
					url: endpoints.onDemandJob.updateAppointmentAfterOtpVerification(orderId),
					method: "PUT",
					body: body,
				}),
				invalidatesTags: ["GetOrdersOd", "OrderCountOd"],
			}),

			widgetsCountByRider: builder.mutation({
				query: (riderId) => ({
					url: endpoints.onDemandJob.widgetsCountByRider,
					body: {riderId},
					method: "POST",
				}),
			}),

			getTotalAmount: builder.mutation({
			query: (body) => ({
				url: endpoints.onDemandJob.getTotalAmount,
				method: "POST",
				body: body,
			}),
	    }),      
		};
	},
});

export const {
	useLazyGetWidgetCountQuery,
	useGetOrdersMutation,
	useLazyGetReasonsQuery,
	useUpdateAppointmentMutation,
	useVerifyOtpMutation,
	useUpdateAppointmentAfterOtpVerificationMutation,
	useGetTotalAmountMutation,
	useWidgetsCountByRiderMutation,
} = onDemandApi;
export { onDemandApi };
