import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./utils/Store";
import { ChakraProvider } from "@chakra-ui/react";
import { LoadScript } from "@react-google-maps/api";
import { libraries } from "./utils/Store/constants";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter>
				<LoadScript
					id="google-map-script"
					googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
					libraries={libraries}
				>
					<App />
				</LoadScript>
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
