import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import UploadImgIcon from "../../assets/icons/Upload-image.png";
import Button from "./Button.component";
import useToaster from "../../utils/hooks/useToaster";
import { useRef } from "react";

function ImageLoadFormField({
	bottomText,
	imageUrl,
	instructions,
	name = "photo",
	isUploadImgBtnDisabled=false,
	multiple = false,
	changeImgButtonName = "Change Image",
	placeholderImg = UploadImgIcon,
	imgFormat = "image/jpg, image/jpeg, image/png",
	fileSize, // in KB
	acceptableHeight, // in pixel
	acceptableWidth,
	customClass,
}) {
	const checkImageDimensions = (fileData) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = fileData;
			img.onload = () => {
				if (
					acceptableHeight &&
					acceptableWidth &&
					img.width !== acceptableWidth &&
					img.height !== acceptableHeight
				) {
					reject(`The image is NOT ${acceptableWidth}x${acceptableHeight} pixels.`);
				} else {
					resolve();
				}
			};
			img.onerror = () => {
				reject("Error loading the image.");
			};
		});
	};

	// const [isFirstTimeEdit, setIsFirstTimeEdit] = useState(isEdit);
	// const [noOfPhotos, setNoOfPhotos] = useState(0);
	const [fileInputValue, setFileInputValue] = useState(""); // Initialize the state here
	// useEffect(() => {
	//  setIsFirstTimeEdit(false);
	// }, []);
	const { setFieldValue, values } = useFormikContext();
	const [previewImage, setPreviewImage] = useState(null);
	const nameRef =useRef(null)
	const { setToast } = useToaster();
	useEffect(() => {
		if (values[name]) {
			if (values[name] instanceof FileList) {
				const reader = new FileReader();
				reader.readAsDataURL(values[name][0]);
				reader.onloadend = () => {
					setPreviewImage([reader.result]);
				};
			} else {
				setPreviewImage(values[name]);
			}
		}
	}, []);
	const handleButtonClick = async () => {
		setFieldValue(name, "");
		setPreviewImage(null);
		await setFileInputValue(""); // Reset the file input value
		document.getElementById(name).click();
	};
	const handleFileChange = (event, form) => {
		const files = event.target.files;
		// setNoOfPhotos(files.length);
		let newPreviewImages = [];

		const processFile = (file, index, totalFiles) => {
			if (imgFormat && !imgFormat.includes(file.type)) {
				setToast({
					type: "error",
					msg: `File format is not allowed`,
				});
				setFieldValue(name, "");
				setPreviewImage(null);
				return;
			}
			if (fileSize && file.size > fileSize * 1024) {
				setToast({
					type: "error",
					msg: `File size exceeds the ${fileSize} KB limit.`,
				});
				setFieldValue(name, "");
				setPreviewImage(null);
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onloadend = async (e) => {
				try {
					acceptableHeight && acceptableWidth && (await checkImageDimensions(e.target.result));

					// If dimensions are correct, process the image further
					newPreviewImages.push(reader.result);
					if (newPreviewImages.length === totalFiles) {
						setPreviewImage(newPreviewImages);
						form.setFieldValue(name, files);
						nameRef.current = name
					}
				} catch (error) {
					setToast({
						type: "error",
						msg: error,
					});
					setPreviewImage(null);
				}
			};
		};
		for (let i = 0; i < files.length; i++) {
			processFile(files[i], i, files.length);
		}
	};
	useEffect(() => {
		if (imageUrl) {
			setPreviewImage([imageUrl]);
			nameRef.current = name
		}
	}, [imageUrl]);

	useEffect(()=>{
	 !imageUrl &&	nameRef.current === name && setPreviewImage(null)
	},[name])

	return (
		<div className="space-y-3">
			<div className={`h-40 w-full border rounded-lg flex justify-center items-center ${customClass}`}>
				{(previewImage && nameRef.current === name) ? (
					<div className="relative">
						<img
							src={previewImage[0]}
							alt="Uploaded"
							className="max-w-full ml-auto mr-auto max-h-36"
						/>
					</div>
				) : (
					<Field name={name} type="file">
						{({ field, form }) => (
							<div>
								<div className="flex flex-col items-center gap-4">
									<img src={placeholderImg} alt="Upload" className="mx-auto h-10 w-10" />
									<div className="text-gray-400 font-sm">{bottomText}</div>
								</div>
								<input
									type="file"
									id={name}
									style={{ display: "none" }}
									onChange={(event) => {
										handleFileChange(event, form);
										setFileInputValue(event.target.value);
									}}
									accept={imgFormat}
									multiple={multiple}
								/>
							</div>
						)}
					</Field>
				)}
			</div>
			<div className="text-[#757575] font-normal text-xs ">{!previewImage && instructions}</div>
			<Button
				className={`w-full  bg-white text-black border-black cursor-pointer h-[2.3rem] ${!isUploadImgBtnDisabled ? "cursor-pointer" : "cursor-not-allowed "}`}
				onClick={()=> !isUploadImgBtnDisabled && handleButtonClick()}
			>
				{(previewImage && nameRef.current === name) ? changeImgButtonName : "Browse"}
			</Button>
		</div>
	);
}
export default React.memo(ImageLoadFormField);
