import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../utils/hooks/useAuth";

const PublicRoute = ({ component: Component, ...rest }) => {
	const { isLogin } = useAuth();
	if (!isLogin()) return <Outlet />;
	return <Navigate to={"/"} replace />;
};

export default PublicRoute;
