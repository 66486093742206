import React, { useEffect, useState } from "react";
import Button from "../../../shared/components/Button.component";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import { useLazyGetAreasQuery, useLazyGetCitesQuery, useLazyGetFranchiseDetailQuery, useRenewFranchiseMutation, } from "../../../utils/Store";
import { Formik, Form, Field, useFormikContext } from "formik";
import useToaster from "../../../utils/hooks/useToaster";
import FormField from "../../../shared/components/FormField.component";
import SelectMenu from "../../../shared/components/SelectMenu.component";
import { ServiceSchema } from "../../../utils/Services/ValidationSchema";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import HomeRefresh1 from "../../../assets/icons/home-refresh-1.png";
import { AddCircle, CloseCircle } from "iconsax-react";
import Checkbox from "../../../shared/components/Checkbox";
import FileSelectButton from "../../../shared/components/FileSelectButton";
import useGetImageUploadData from "../../../utils/hooks/useGetImageUploadData";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import { Calendar } from "iconsax-react";


const validationSchema = Yup.object()
    .shape({
        commissionModelName: Yup.string().required("Please select Commission Model"),
        mouDocumentsUrls: Yup.array().min(1, "Please upload MOU document"),
        revenue: Yup.array().when('commissionModelName', {
            is: 'revenue',
            then:()=> Yup.array().of(
                Yup.object().shape({
                    amount: Yup.string().required("Amount is required"),
                    percentage: Yup.string().required("Percentage is required"),
                    gstPercentage: Yup.string().when('includeGst', {
                        is: false,
                        then:()=> Yup.string().required("GST is required"),
                        otherwise:()=> Yup.string()
                    }),
                })
            ),
            otherwise:()=> Yup.array(), // No validation when commissionModelName is not 'revenue'
        }),
        range: Yup.array().when('commissionModelName', {
            is: 'perUser',
            then:()=> Yup.array().of(
                Yup.object().shape({
                    gstPercentage: Yup.string().when('includeGst', {
                        is: false,
                        then:()=> Yup.string().required("GST is required"),
                        otherwise:()=> Yup.string()
                    }),
                    amount: Yup.string().required("Amount is required"),
                    from: Yup.string().required("From is required"),
                    to: Yup.string().required("To is required")
                    .test('is-greater', 'To value must be greater than From value', function(toValue) {
                        const fromValue = this.parent.from;
                        return parseFloat(toValue) > parseFloat(fromValue);
                    }),
                })
            ),
            otherwise:()=> Yup.array(), // No validation when commissionModelName is not 'revenue'
        }),
    })
    .test("", "", function (value) {
        if (value.commissionModelName === "perUser" && !value.noOfServiceProvider) {
            return this.createError({
                path: "noOfServiceProvider",
                message: "Please enter No of service Providers",
            });
        }
        return true; // Validation passed
    });

function RenewalFranchiseModal({ data }) {
    const [getFranchiseDetails] = useLazyGetFranchiseDetailQuery();
    const [commission,  setCommission] =useState([])
    const [isFirstRender, setIsFirstRender] =useState(true)
    
    useEffect(() => {
        const fetchData = async () => {
            const {data: d , error} = await getFranchiseDetails(data.id);
            if (d && d?.length > 0) {
                setCommission(d[0]?.commission)
            }
            if(error) setToast({ type: "error", msg: error?.data?.message })
        };

        fetchData();
    }, []);

    const [renewFranchise, {status}] =useRenewFranchiseMutation()
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { openModal, closeModal } = useSelectModal();
    const [reinitialize, setReInitialize] = useState();
    const { setToast } = useToaster();
    const navigate = useNavigate();
    const [isRevenue, setIsRevenue] = useState(false);

    const checkFranchiseDates = (values) => {
    if(values.commissionModelName !== "revenue") return;
    
    const franchiseStartDate = new Date(values.franchiseStartDate);
    const franchiseEndDate = new Date(values.franchiseEndDate);

    // Extract only the date component from franchise start and end date
    const franchiseStartDateOnlyDate = new Date(franchiseStartDate.getFullYear(), franchiseStartDate.getMonth(), franchiseStartDate.getDate());
    const franchiseEndDateOnlyDate = new Date(franchiseEndDate.getFullYear(), franchiseEndDate.getMonth(), franchiseEndDate.getDate());

    const datesInRange = [];

    // Generate all dates between franchise start and end date (inclusive)
    for (let date = new Date(franchiseStartDateOnlyDate); date <= franchiseEndDateOnlyDate; date.setDate(date.getDate() + 1)) {
        datesInRange.push(new Date(date));
    }

    // Check for missing dates in revenue entries
    const missingDates = datesInRange.filter(date => {
        return !values.revenue.some(revenue => {
            const revenueStartDate = new Date(revenue.startDate);
            const revenueEndDate = new Date(revenue.endDate);

            // Extract only the date component from revenue start and end date
            const revenueStartDateOnlyDate = new Date(revenueStartDate.getFullYear(), revenueStartDate.getMonth(), revenueStartDate.getDate());
            const revenueEndDateOnlyDate = new Date(revenueEndDate.getFullYear(), revenueEndDate.getMonth(), revenueEndDate.getDate());

            return date >= revenueStartDateOnlyDate && date <= revenueEndDateOnlyDate;
        });
    });

    if (missingDates.length > 0) {
        // Display error message for missing dates
        setToast({ type: "error", msg: "Missing dates between franchise start and end dates in the Commission model" });
        return true;
    }

    return false;
}

    const checkDatesWithinFranchiseRange = (values) => {
    if(values.commissionModelName !== "revenue") return;

    const franchiseStartDate = new Date(values.franchiseStartDate);
    const franchiseEndDate = new Date(values.franchiseEndDate);

    const invalidDates = values.revenue.filter(revenue => {
        const revenueStartDate = new Date(revenue.startDate);
        const revenueEndDate = new Date(revenue.endDate);

        // Extract only the date component from revenue start and end date
        const revenueStartDateOnlyDate = new Date(revenueStartDate.getFullYear(), revenueStartDate.getMonth(), revenueStartDate.getDate());
        const revenueEndDateOnlyDate = new Date(revenueEndDate.getFullYear(), revenueEndDate.getMonth(), revenueEndDate.getDate());
        const franchiseStartDateOnlyDate = new Date(franchiseStartDate.getFullYear(), franchiseStartDate.getMonth(), franchiseStartDate.getDate());
        const franchiseEndDateOnlyDate = new Date(franchiseEndDate.getFullYear(), franchiseEndDate.getMonth(), franchiseEndDate.getDate());

        return (
            revenueStartDateOnlyDate < franchiseStartDateOnlyDate ||
            revenueEndDateOnlyDate > franchiseEndDateOnlyDate
        );
    });

    if (invalidDates.length > 0) {
        // Display error message for dates outside franchise range
        setToast({ type: "error", msg: "Some dates in Commission Model are outside of the franchise start and end dates" });
        return true;
    }

    return false;
};

    const checkRange = (values) => {
        if (values.commissionModelName !== "perUser") return;
        const ranges = values.range;
        ranges.forEach(range => {
            range.from = parseInt(range.from);
            range.to = parseInt(range.to);
        });
        let coveredValues = new Set();
        for (let i = 0; i < ranges.length; i++) {
            const range = ranges[i];
            // Check if max range 'to' value is less than 50
            if (range.to > values.noOfServiceProvider) {
                setToast({ type: "error", msg: "'To' value should not be greater than No. of service provider" });
                return true;
            }
            // Check for overlap
            for (let j = i + 1; j < ranges.length; j++) {
                const nextRange = ranges[j];
                if ((range.from <= nextRange.to && range.to >= nextRange.from) ||
                    (nextRange.from <= range.to && nextRange.to >= range.from)) {
                    setToast({ type: "error", msg: "Range Selection should not overlap" });
                    return true;
                }
            }
            // Add all values within the range to coveredValues set
            for (let val = range.from; val <= range.to; val++) {
                coveredValues.add(val);
            }
        }
        // Check if all values from 0 to 50 are covered
        for (let i = 1; i <= values.noOfServiceProvider; i++) {
            if (!coveredValues.has(i)) {
                setToast({ type: "error", msg: `Ranges should cover all values from 1 to ${values.noOfServiceProvider}` });
                return true;
            }
        }
        return false;
    }

    function validateFranchiseStartDate(franchiseStartDate) {
        const previousEnd = new Date(data?.franchiseData?.endDate);
        const franchiseStart = new Date(franchiseStartDate);
        const previousEndOnlyDate = new Date(previousEnd.getFullYear(), previousEnd.getMonth(), previousEnd.getDate());
        const franchiseStartOnlyDate = new Date(franchiseStart.getFullYear(), franchiseStart.getMonth(), franchiseStart.getDate());
        if (franchiseStartOnlyDate <= previousEndOnlyDate) {
            setToast({ type: "error", msg: "The franchise start date must be greater than the franchise's old end date" })
            return true
        }

        return null; // No error
    }


    const handleFormSubmit = async (values) => {
        if(validateFranchiseStartDate(values.franchiseStartDate)) return
        if(checkRange(values)) return
        if(checkFranchiseDates(values)) return
        if(checkDatesWithinFranchiseRange(values)) return

        const bodyObj = {
				mouDocumentToAdd:
					values.mouDocumentsUrls?.length && values.mouDocumentsUrls,
				commissionModelName: values.commissionModelName,
				commissionData: [],
                contractStartDate: moment(values.franchiseStartDate).format("YYYY-MM-DD"),
                contractEndDate: moment(values.franchiseEndDate).format("YYYY-MM-DD"),
                creditLimit: values.creditLimit,
                paymentDueDate: values.paymentDueDate,
			};
			if (values.commissionModelName === "perUser") {
				bodyObj.commissionData = values.range?.map((e) => {
					return {
						...e,
						startDate: moment(values.franchiseStartDate).format("YYYY-MM-DD"),
						endDate: moment(values.franchiseEndDate).format("YYYY-MM-DD"),
						noOfServiceProvider: +values.noOfServiceProvider,
						amount: +e.amount,
                        rangeFrom: e.from,
                        rangeTo: e.to
					};
				});
			}
			if (values.commissionModelName === "revenue") {
				bodyObj.commissionData = values.revenue?.map((e) => {
					const commissionDataObj = {
                        ...e,
						startDate: moment(e.startDate).format("YYYY-MM-DD"),
						endDate: moment(e.endDate).format("YYYY-MM-DD"),
					};
					if (e.amount) commissionDataObj.amount = +e.amount;
					if (e.percentage) commissionDataObj.percentage = +e.percentage;
					return commissionDataObj;
				});
			}

			const { data: d, error } = await renewFranchise({data: bodyObj, id: data.id});
			if (d) {
				openModal("RenewalDetailsModal", { hideCloseArrow: true, id: data.id, mou: values.mouDocumentsUrls})
                data.fetchFranchise()
			}
			if (error) setToast({ type: "error", msg: error?.data?.message });

    };

    const RenewalForm = ({ values, setFieldValue, errors, touched, franchiseData }) => {

    useEffect(()=>{
        if(!isFirstRender || !commission?.length) return
        const commissionFilteredArray = commission?.length && commission?.filter(obj=> obj.name === data?.franchiseData?.commissionModelName)
        if(data?.franchiseData?.commissionModelName === "revenue" && commissionFilteredArray?.length) setFieldValue(`revenue`, commissionFilteredArray?.map(obj=> ({ startDate: new Date(obj.startDate), endDate: new Date(obj.endDate), "amount": obj.Amount, percentage: obj.percentage, includeGst: obj.includeGst, gstPercentage:obj.gstPercentage || ""  })))
        if(data?.franchiseData?.commissionModelName === "perUser" && commissionFilteredArray?.length) {
            setFieldValue("noOfServiceProvider", commissionFilteredArray[0]?.ServiceProvider)
            setFieldValue("startDate", new Date(commissionFilteredArray[0]?.startDate))
            setFieldValue("endDate", new Date(commissionFilteredArray[0]?.endDate))
            setFieldValue(`range`, commissionFilteredArray?.map(obj=> ({ from: obj.rangeFrom, to: obj.rangeTo, amount: obj.Amount, includeGst: obj.includeGst, gstPercentage:obj.gstPercentage || "" })))
        }
        setIsFirstRender(false)
    },[data])


	    const { uploadImages, uploadImgStatus } = useGetImageUploadData();

        const handleUpload = async () => {
            if(!selectedFiles) return
            
            const uploadedData = await uploadImages(selectedFiles)
            if(uploadedData){
                setSelectedFiles([])
                if (values.mouDocumentsUrls?.length) setFieldValue("mouDocumentsUrls", [...values.mouDocumentsUrls, ...uploadedData]);
			    else setFieldValue("mouDocumentsUrls", uploadedData);
            }
        }

        const handleAddRevenueField = (index) => {
            setFieldValue("revenue", [
                ...values.revenue,
                { startDate: new Date(values.franchiseStartDate), endDate: new Date(values.franchiseStartDate), amount: "", percentage: "", includeGst: false, gstPercentage:"" },
            ]);
        };

        const handleRemoveRevenueField = (index) => {
            const newRevenueArray = [...values.revenue];
            newRevenueArray.splice(index, 1);
            setFieldValue("revenue", newRevenueArray);
        };

        useEffect(
            () => {
                values.revenue?.map((e, index) => {
                    if (e.endDate < e.startDate)
                        setFieldValue(`revenue[${index}]endDate`, new Date(e.startDate));
                });
            },
            values.revenue?.map((e) => e.startDate)
        );

        // useEffect(
        //     () => {
        //         setFieldValue(`revenue[${values.revenue?.length - 1}]endDate`, values.franchiseEndDate)
        //         setFieldValue(`revenue[${0}]startDate`, values.franchiseStartDate)
        //     },[values.franchiseEndDate, values.franchiseStartDate]
        // );
        

        useEffect(() => {
            if (values.endDate < values.startDate) setFieldValue("endDate", new Date(values.startDate));
        }, [values.startDate]);

        const revenueForm = (index) => {
            return (
                <div key={index} className="relative">
                    <div className="w-full border rounded-xl bg-[#F5F5F5] p-5 mb-4">
                        <div className="w-full flex space-x-4 items-center">
                            <div className="w-1/2">
                                <div
                                    className="block text-sm font-medium leading-6 text-[#000]"
                                >
                                    Start Date
                                </div>
                                <DatePicker
                                    selected={values.revenue?.length && values[`revenue`][index]?.startDate}
                                    onChange={(date) => setFieldValue(`revenue[${index}]startDate`, date)}
                                    className="rounded-lg border-[#000]/10 2xl:w-full w-full"
                                    dateFormat="dd-MM-yyyy"
                                    minDate={new Date(values.franchiseStartDate)}
                                    maxDate={new Date(values.franchiseEndDate)}
                                // customInput={<CustomInput />}
                                />
                            </div>
                            <div className="w-1/2">
                                <div
                                    className="block text-sm font-medium leading-6 text-[#000]"
                                >
                                    End Date
                                </div>
                                <DatePicker
                                    selected={values.revenue?.length && values[`revenue`][index]?.endDate}
                                    onChange={(date) => setFieldValue(`revenue[${index}]endDate`, date)}
                                    className="rounded-lg border-[#000]/10 2xl:w-full w-full"
                                    dateFormat="dd-MM-yyyy"
                                    minDate={(values.revenue?.length && values[`revenue`][index]?.startDate) ? new Date(values[`revenue`][index]?.startDate): new Date(values.franchiseStartDate)}
                                    maxDate={new Date(values.franchiseEndDate)}
                                // customInput={<CustomInput />}
                                />
                            </div>
                            <div className="pt-6">
                                {
                                    (index === 0) ? (
                                        <AddCircle
                                            className="text-[#FB0C0C] cursor-pointer"
                                            onClick={() => handleAddRevenueField(index)}
                                        />
                                    ) : (
                                        <CloseCircle
                                            className="text-[#FB0C0C] cursor-pointer"
                                            onClick={() => handleRemoveRevenueField(index)}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="w-full flex space-x-4 pt-4">
                            <div className="w-1/2">
                                <FormField
                                    nonFormikError
                                    field={{
                                        // label: "Amount",
                                        name: `revenue[${index}]amount`,
                                        type: "text",
                                        placeholder: "Enter Revenue Amount",
                                        onKeyPress: (event) => {
											if (/[^0-9]/.test(event.key)) {
												return event.preventDefault();
											}
										},
                                    }}
                                />
                                {touched?.revenue?.[`${index}`]?.amount &&
									errors?.revenue &&
									errors?.revenue[index]?.amount ? (
										<div className="error text-red-600 text-sm">{errors.revenue[index].amount}</div>
									) : (
									<div className="invisible h-5"></div>
								)}
                            </div>
                            <div className="w-1/2">
                                <FormField
                                    nonFormikError
                                    field={{
                                        // label: "Percentage",
                                        name: `revenue[${index}]percentage`,
                                        type: "text",
                                        placeholder: "Enter Revenue Percentage",
                                        onKeyPress: (event) => {
											if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
												return event.preventDefault();
											}
										},
                                    }}
                                />
                                {touched?.revenue?.[`${index}`]?.percentage &&
									errors?.revenue &&
									errors?.revenue[index]?.percentage ? (
										<div className="error text-red-600 text-sm">{errors.revenue[index].percentage}</div>
									) : (
									<div className="invisible h-5"></div>
								)}
                            </div>
                        </div>
                        <div className="flex justify-between mt-2 items-baseline ">
                            <div className="flex items-center">
                                <Checkbox name={`revenue[${index}][${FIELD_NAMES.INCLUDE_GST}]`} />
                                <p className="font-inter-semibold text-sm">Include GST</p>
                            </div>
                            <div>
                            <div className={`flex justify-around items-center border rounded-lg overflow-invisible w-[15rem] ${
                                            values?.revenue[index][FIELD_NAMES.INCLUDE_GST] ? "hidden" : ""
                                        }`}>
                                    <div className="whitespace-nowrap px-3 py-1 border-r-[1px] h-full w-[60%] justify-center flex items-center text-sm">
                                        Add GST %
                                    </div>
                                    <Field
                                        name={`revenue[${index}]gstPercentage`}
                                        type="text"
                                        placeholder="GST"
                                        className={`h-full w-[40%] border-none arrow-none text-sm focus:ring-transparent `}
                                        onKeyPress= {(event) => {
                                            if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
                                                return event.preventDefault();
                                            }
                                    }}
                                    ></Field>
                                </div>
                                {touched?.revenue?.[index]?.includeGst &&
                                    errors?.revenue &&
                                    errors?.revenue[index]?.gstPercentage ? (
                                        <div className="error text-red-600 text-sm">{errors.revenue[index]?.gstPercentage}</div>
                                    ) : (
                                    <div className="invisible h-5"></div>
                                )}
                            </div>
                    </div>
                    </div>
                </div>
            )
        }

        const FIELD_NAMES = {
            FROM: 'from',
            TO: 'to',
            AMOUNT: 'amount',
            INCLUDE_GST: 'includeGst'
        };

        const handleAddRangeField = () => {
            setFieldValue("range", [
                ...values.range,
                { rangeFrom: "", rangeTo: "", amount: "", includeGst: false, gstPercentage:"" },
            ]);
        };

        const handleRemoveRangeField = (index) => {
            const newRange = [...values.range];
            newRange.splice(index, 1);
            setFieldValue("range", newRange);
        };

        const perUserForm = (index) => {
            return (
                <div className="" key={index}>
                    <div className="w-full border rounded-xl bg-[#F5F5F5] p-5 mt-4">
                        <p className="font-inter-semibold text-[#000] text-sm">Range Selection</p>
                        <div className="w-full flex space-x-4">
                            <div className="w-1/2">
                                <FormField
                                    nonFormikError
                                    field={{
                                        label: "From",
                                        name: `range[${index}][${FIELD_NAMES.FROM}]`,
                                        type: "text",
                                        placeholder: "Enter Number",
                                        onKeyPress: (event) => {
                                            if (/[^0-9]/.test(event.key)) {
                                                return event.preventDefault();
                                            }
                                        },
                                    }}
                                />
                                {touched?.range?.[`${index}`]?.from &&
                                    errors?.range &&
                                    errors?.range[index]?.from ? (
                                        <div className="error text-red-600 text-sm">{errors.range[index].from}</div>
                                    ) : (
                                    <div className="invisible h-5"></div>
                                )}
                            </div>
                            <div className="w-1/2">
                                <FormField
                                    nonFormikError
                                    field={{
                                        label: "To",
                                        name: `range[${index}][${FIELD_NAMES.TO}]`,
                                        type: "text",
                                        placeholder: "Enter Number",
                                        onKeyPress: (event) => {
                                            if (/[^0-9]/.test(event.key)) {
                                                return event.preventDefault();
                                            }
                                        },
                                    }}
                                />
                                {touched?.range?.[`${index}`]?.to &&
                                    errors?.range &&
                                    errors?.range[index]?.to ? (
                                        <div className="error text-red-600 text-sm">{errors.range[index].to}</div>
                                    ) : (
                                    <div className="invisible h-5"></div>
                                )}
                            </div>
                            <div className="pt-8">
                                {
                                    (index === 0) ? (
                                        <AddCircle
                                            className="text-[#FB0C0C] cursor-pointer"
                                            onClick={() =>  handleAddRangeField()}
                                        />
                                    ) : (
                                        <CloseCircle
                                            className="text-[#FB0C0C] cursor-pointer"
                                            onClick={() => handleRemoveRangeField(index)}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="w-full">
                            <FormField
                                nonFormikError
                                field={{
                                    // label: "No. of service Providers",
                                    name: `range[${index}][${FIELD_NAMES.AMOUNT}]`,
                                    type: "text",
                                    placeholder: "Enter per user amount",
                                    onKeyPress: (event) => {
                                        if (/[^0-9]/.test(event.key)) {
                                            return event.preventDefault();
                                        }
                                    },
                                }}
                            />
                            {touched?.range?.[`${index}`]?.amount &&
                                errors?.range &&
                                errors?.range[index]?.amount ? (
                                    <div className="error text-red-600 text-sm">{errors.range[index].amount}</div>
                                ) : (
                                <div className="invisible h-5"></div>
                            )}
                        </div>
                        <div className="flex justify-between mt-2 items-baseline ">
                            <div className="flex items-center">
                                <Checkbox name={`range[${index}][${FIELD_NAMES.INCLUDE_GST}]`} />
                                <p className="font-inter-semibold text-sm">Include GST</p>
                            </div>
                            <div>
                                <div className={`flex justify-around items-center border rounded-lg overflow-invisible w-[15rem] ${
                                            values?.range[index][FIELD_NAMES.INCLUDE_GST] ? "hidden" : ""
                                        }`}>
                                    <div className="whitespace-nowrap px-3 py-1 border-r-[1px] h-full w-[60%] justify-center flex items-center text-sm">
                                        Add GST %
                                    </div>
                                    <Field
                                        name={`range[${index}]gstPercentage`}
                                        type="text"
                                        placeholder="GST"
                                        className={`h-full w-[40%] border-none arrow-none text-sm focus:ring-transparent `}
                                        onKeyPress= {(event) => {
                                            if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
                                                return event.preventDefault();
                                            }
                                    }}
                                    ></Field>
                                </div>
                                {touched?.range?.[index]?.includeGst &&
                                    errors?.range &&
                                    errors?.range[index]?.gstPercentage ? (
                                        <div className="error text-red-600 text-sm">{errors.range[index]?.gstPercentage}</div>
                                    ) : (
                                    <div className="invisible h-5"></div>
                                )}
                            </div>
                    </div>
                    </div>
                </div>
            )
        }

        const CustomDatePicker = ({ field, form, ...props }) => {
            const currentFieldValue = field.value;

            return (
                <div className="w-full">
                    <DatePicker
                        {...props}
                        selected={currentFieldValue}
                        onChange={(date) => form.setFieldValue(field.name, date)}
                        minDate={new Date()}
                    />
                </div>
            );
        };

        return (
            <div className="">
                <div className="pl-5 pr-3 modal-ht-inner">
                    <div className="flex space-x-4">
                        <div className="w-full">
                            <div className="w-full py-4">
                                <div className="text-sm font-medium mb-2 text-[#000]">Commission Model</div>
                                <div className="flex items-center justify-between w-full gap-x-4 font-normal text-sm">
                                    <div className="py-2 px-4 w-full bg-white rounded-lg border">
                                        <label className="flex items-center gap-2">
                                            <Field
                                                type="radio"
                                                name="commissionModelName"
                                                value="revenue"
                                                className="mr-2 mt-1.5 items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent hover:bg-[#757575] md:mt-0 sm:mt-0"
                                            />
                                            <span className="text-[#757575] font-normal">Revenue</span>
                                        </label>
                                    </div>
                                    <div className="py-2 px-4 w-full bg-white rounded-lg border">
                                        <label className="flex items-center gap-2">
                                            <Field
                                                type="radio"
                                                name="commissionModelName"
                                                value="perUser"
                                                className="mr-2 mt-1.5 items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent hover:bg-[#757575] md:mt-0 sm:mt-0"
                                            />
                                            <span className="text-[#757575]">Per User</span>
                                        </label>
                                    </div>
                                </div>
                                {errors.commissionModelName && touched.commissionModelName ? (
                                    <div className="error text-red-600 text-sm">{errors?.commissionModelName}</div>
                                ) : (
                                    <div className="invisible h-5"></div>
                                )}
                            </div>
                            <div className="border rounded-xl bg-[#F5F5F5] px-5 mb-4 pt-3">
                                <div className="font-medium text-sm mb-1">MOU</div>
                                <div className="w-full flex space-x-4 ">
                                    <div className="w-1/2">
                                        <FileSelectButton selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} accept={"application/pdf"}/>
                                    </div>
                                    <div className="w-1/2">
                                        <Button
                                            className="bg-black text-white cursor-pointer h-[2.4rem] w-full"
                                            onClick={handleUpload}
                                            isSubmitting={uploadImgStatus === "pending"}
                                        >
                                            Upload
                                        </Button>
                                    </div>
                                </div>
                                {errors.mouDocumentsUrls && touched.mouDocumentsUrls ? (
                                    <div className="error text-red-600 text-sm">{errors?.mouDocumentsUrls}</div>
                                ) : (
                                    <div className="invisible h-5"></div>
                                )}
                                <div className="flex gap-2 overflow-x-auto final-price-scrollbar mb-4">
                                    {values.mouDocumentsUrls?.map((doc, index) => (
                                        <div
                                            className="flex justify-between bg-white rounded-md border items-center px-3 py-1.5 relative"
                                            key={index}
                                        >
                                            <div className="flex-1 flex items-center gap-3">
                                                <div className="font-inter-regular text-sm text-gray-500">
                                                    {doc?.name}
                                                </div>
                                                <RxCross2
                                                    alt=""
                                                    className="h-4 w-4 text-gray-400"
                                                    onClick={() => {
                                                        const updatedArray = values.mouDocumentsUrls?.filter(
                                                            (e) => e.url !== doc.url
                                                        );
                                                        setFieldValue("mouDocumentsUrls", updatedArray);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {values.commissionModelName === "revenue" && (
                                <>
                                    {values.revenue?.map((_, index) => (
                                        <div key={index}>{revenueForm(index)}</div>
                                    ))}
                                </>
                            )}
                            {values.commissionModelName === "perUser" && (
                                <div className="w-full border rounded-xl bg-[#F5F5F5] px-5 pt-5 mb-4">
                                    <div className="w-full flex gap-x-4 items-center">
                                        {/* <div className="w-1/2">
                                            <label
                                                htmlFor="startDate"
                                                className="block text-sm font-medium leading-6 text-[#000]"
                                            >
                                                Start Date
                                            </label>
                                            <DatePicker
                                                selected={values.startDate}
                                                onChange={(date) => setFieldValue("startDate", date)}
                                                className="rounded-lg border-[#000]/10 2xl:w-full w-full"
                                                dateFormat="dd-MM-yyyy"
                                                minDate={new Date()}
                                            // customInput={<CustomInput />}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <label
                                                htmlFor="endDate"
                                                className="block text-sm font-medium leading-6 text-[#000]"
                                            >
                                                End Date
                                            </label>
                                            <DatePicker
                                                selected={values.endDate}
                                                onChange={(date) => setFieldValue("endDate", date)}
                                                className="rounded-lg border-[#000]/10 2xl:w-full w-full"
                                                dateFormat="dd-MM-yyyy"
                                                minDate={new Date(values.startDate)}
                                            // customInput={<CustomInput />}
                                            />
                                        </div> */}
                                        {isRevenue && (
                                            <div className="pt-6">
                                                <AddCircle
                                                    className="text-[#FB0C0C]"
                                                />
                                            </div>
                                        )
                                        }
                                    </div>
                                    {values.commissionModelName === "perUser" && (
                                        <div className="w-full pt-4">
                                            <label className="font-inter-medium text-sm">No. of service Providers</label>
                                            <FormField
                                                field={{
                                                    // label: "No. of service Providers",
                                                    name: "noOfServiceProvider",
                                                    type: "text",
                                                    placeholder: "Enter no. of service of service  providers",
                                                    onKeyPress: (event) => {
                                                        if (/[^0-9]/.test(event.key)) {
                                                            return event.preventDefault();
                                                        }
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            {values.commissionModelName === "perUser" && (
                                <>
                                    {values.range?.map((_, index) => (
                                        <div key={index}>{perUserForm(index)}</div>
                                    ))}
                                </>
                            )}

                        </div>
                    </div>
                </div>
                <div className="absolute right-0 left-0 bottom-0 flex space-x-4 my-3 pt-1 pl-5 pr-6">
                    <Button
                        className="bg-[#F5F5F5] rounded-lg text-[#111827] cursor-pointer bottom-btn-ht w-full"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="bg-[#FB0C0C] rounded-lg text-white cursor-pointer bottom-btn-ht w-full"
                        type="submit"
                        isSubmitting={status === "pending" }
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    };


    return (
        <div className="w-[52rem] rounded-xl modal-height-service max-h-[80vh] overflow-hidden min-h-[45vh] pb-20">
            <div className="pr-2">
                <div className="pl-5 pb-4">
                    <div className="flex items-center justify-between pt-5">
                        <div className="h-12 w-12 bg-[#FF621F] rounded-full flex justify-center items-center modal-logo-size">
                            <img src={HomeRefresh1} className="h-7 w-7" alt="" />
                        </div>
                    </div>
                    <div className="mb-1 mt-6">
                        <h1 className="text-xl font-inter-bold">Renewal</h1>
                    </div>
                    <div className="">
                        <h1 className="text-base font-normal text-[#475467]">
                            Renew/Modify franchise details by changing details below
                        </h1>
                    </div>
                </div>
                <div className="max-h-[50vh] overflow-y-auto">
                    <Formik
                        enableReinitialize={reinitialize}
                        initialValues={{
                            commissionModelName: data?.franchiseData?.commissionModelName,
                            range: [{ rangeFrom: "", rangeTo: "", amount: "", includeGst: false, gstPercentage:"" }],
							revenue: [
								{ startDate: new Date(), endDate: new Date(), amount: "", percentage: "", includeGst: false, gstPercentage:"" },
							],
                            startDate: new Date(),
                            endDate: new Date(),
                            mouDocumentsUrls: [],
                            noOfServiceProvider: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleFormSubmit(values);
                        }}
                    >
                        {({ isSubmitting, values, errors, touched, setFieldValue }) => (
                            <Form className="">
                                <div className="gap-5 w-full pl-5 pr-3">{<OtherData values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} franchiseData={data.franchiseData}/>}</div>
                                <div className="gap-5 w-full">{<RenewalForm values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} franchiseData={data.franchiseData}/>}</div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default RenewalFranchiseModal


const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <div
        className={`flex gap-3 border rounded-md text-sm h-[2.3rem] p-2 bg-white pr-4 ${
            !value && "text-gray-500"
        }`}
        onClick={onClick}
        ref={ref}
    >
        <Calendar className="h-5 w-5" />
        {value || placeholder}
    </div>
));

const OtherData = ({values, setFieldValue, touched, errors, franchiseData}) => {
        useEffect(()=>{
            if(!franchiseData) return
            setFieldValue("creditLimit", franchiseData.creditLimit)
            setFieldValue("paymentDueDate", franchiseData.paymentDueDate)
            setFieldValue("franchiseStartDate", moment(franchiseData.startDate).toDate())
            setFieldValue("franchiseEndDate", moment(franchiseData.endDate).toDate())
        },[franchiseData])

        useEffect(() => {
            if (values.franchiseEndDate < values.franchiseStartDate) setFieldValue("franchiseEndDate", new Date(values.franchiseStartDate));
        }, [values.franchiseStartDate]);

        return (
            <div className="w-full border rounded-xl bg-[#F5F5F5] p-5 mb-4">
                <div className="flex gap-4">
                    <div className="w-full">
                        <div className="mb-2 block font-inter-medium text-sm">Start Date</div>
                        <div className="flex justify-between gap-5">
                            <div className="w-full">
                                <DatePicker
                                    className="rounded-lg border-[#000]/10 2xl:w-full w-full"
                                    selected={values.franchiseStartDate}
                                    onChange={(date) => setFieldValue("franchiseStartDate", moment(date).toDate())}
                                    dateFormat="dd-MM-yyyy"
                                    minDate={moment(franchiseData.endDate).add(1, 'days').toDate() < new Date() ? new Date() : moment(franchiseData.endDate).add(1, 'days').toDate()}
                                    customInput={<CustomInput />}
                                />
                                {/* <div className="invisible h-5"></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="mb-2 block font-inter-medium text-sm">End Date</div>
                        <div className="flex justify-between gap-5">
                            <div className="w-full ">
                                <DatePicker
                                    className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
                                    selected={values.franchiseEndDate}
                                    onChange={(date) => setFieldValue("franchiseEndDate", moment(date).toDate())}
                                    dateFormat="dd-MM-yyyy"
                                    minDate={moment(values.franchiseStartDate).toDate()}
                                    customInput={<CustomInput />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-4">
						<FormField
							field={{
								label: "Credit Limit",
								name: "creditLimit",
								type: "text",
								placeholder: "Enter Credit Limit",
								customClassName: "font-inter-medium",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key)) {
										return event.preventDefault();
									}
								},
							}}
						/>
					</div>
					<div className="w-full">
						<FormField
							field={{
								label: "Payment Due Date",
								name: "paymentDueDate",
								type: "text",
								placeholder: "Enter Payment Due Date",
								customClassName: "font-inter-medium",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
										return event.preventDefault();
									}
								},
							}}
						/>
					</div>
            </div>
        );
    };