import React, { useEffect, useMemo, useState } from "react";
import Button from "../../shared/components/Button.component";
import NewSubscription from "../../assets/icons/New_Subscription.svg";
import EditSubscription from "../../assets/icons/Edit.png";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { AddCircle, ExportCurve, Setting3 } from "iconsax-react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import Edit from "../../assets/icons/edit-icon-blue-bg.svg";
import {
	useLazyGetAreasQuery,
	useLazyGetCitesQuery,
	useLazyGetSupervisorsFromCityQuery,
} from "../../utils/Store";

import SelectMenuMultiselect from "../../shared/components/Select_Menu_Multiselect.component";
import { Formik, Form, useFormik, useFormikContext, Field } from "formik";
import useToaster from "../../utils/hooks/useToaster";
import FormField from "../../shared/components/FormField.component";
import {
	useCreateServiceMutation,
	useUpdateServiceMutation,
} from "../../utils/Services/Apis/serviceApi";
import ImageLoadFormField from "../../shared/components/Image_Load_Form_Field.component";
import useUploadImage from "../../utils/hooks/useUploadImage";
import closeIcon from "../../assets/icons/close.svg";
import SelectMenu from "../../shared/components/SelectMenu.component";
import { ServiceSchema, assetSchema } from "../../utils/Services/ValidationSchema";
import placeholderImg from "../../assets/icons/service_request_management/Group.svg";
import { useNavigate } from "react-router-dom";
import { getUniqueArray } from "../../utils/helper/uniqueArray";
import { useLazyGetAllCarTypesQuery } from "../../utils/Services/Apis/carMasterData";
import zIndex from "@mui/material/styles/zIndex";

function ServiceModal({ data }) {
	const isEdit = !!data;
	// const defaultOptionForCity = useMemo(() => ({ _id: "", name: "Select city" }), []);
	// const defaultOptionForArea = useMemo(() => ({ _id: "", name: "Select Area" }), []);
	// const defaultOptionForServiceType = useMemo(() => ({ _id: "", name: "Select Service Type" }), []);
	// const defaultOptionForAsset = useMemo(() => ({ _id: "", name: "Select Asset" }), []);
	// const defaultOptionForQty = useMemo(() => ({ _id: "", name: "Qty" }), []);
	// const defaultOptionForUnits = useMemo(() => ({ _id: "", name: "Units" }), []);

	const [cityList, setCityList] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [selectedAssets, setSelectedAssets] = useState(isEdit ? data.assets : []);
	const [getCarTypes, { data: AllCarTypes }] = useLazyGetAllCarTypesQuery();

	const [serviceType, setServiceType] = useState([
		{ name: "One Time Service", value: "onetimeservice" },
		{ name: "Subscription", value: "subscription" },
	]);
	const [serviceTimeOption, setserviceTimeOption] = useState([
		{ name: "Hours", value: "hours" },
		{ name: "Min", value: "min" },
	]);
	const [quantityOptions, setQuantityOptions] = useState([
		{ name: "1", value: 1 },
		{ name: "2", value: 2 },
		{ name: "3", value: 3 },
		{ name: "4", value: 4 },
		{ name: "5", value: 5 },
		{ name: "6", value: 6 },
		{ name: "7", value: 7 },
		{ name: "8", value: 8 },
		{ name: "9", value: 9 },
		{ name: "10", value: 10 },
	]);
	const [assestOptions, setAssestOptions] = useState([
		{ _id: 1, name: "Car Washing Machine", value: "carwashingmachine" },
		{ _id: 2, name: "Tool Kit", value: "toolkit" },
		{ _id: 3, name: "Tyre Polish Kit", value: "tyrepolishkit" },
	]);
	const [unitOptions, setUnitOptions] = useState([
		{ name: "Item", value: "item" },
		{ name: "Litre", value: "litre" },
		{ name: "ML", value: "ml" },
	]);

	const discountTypeOptions = [
		{ name: "Flat Discount", value: "flat" },
		{ name: "Discount Percent", value: "percent" },
	];

	const { closeModal } = useSelectModal();
	const [reinitialize, setReInitialize] = useState(isEdit);
	const [getAllCities, { data: cities }] = useLazyGetCitesQuery();
	const [getAllCityAreas, { data: areas }] = useLazyGetAreasQuery();

	const [addService, { status: status1 }] = useCreateServiceMutation();
	const [updateService, { status: status2 }] = useUpdateServiceMutation();
	const [getCitySupervisors] = useLazyGetSupervisorsFromCityQuery();
	const [cityId, setCityId] = useState();
	const [nonFormikErrors, setNonFormikErrors] = useState({});

	const { setToast } = useToaster();
	const { uploadImages } = useUploadImage();
	const navigate = useNavigate();

	useEffect(() => {
		handleFetchCityList();
	}, []);

	useEffect(() => {
		if (cityId) handleFetchAreas(cityId);
	}, [cityId]);

	useEffect(() => {
		getCarTypes();
	}, []);

	const handleFetchCityList = () => {
		getAllCities()
			.then(({ data }) => {
				setCityList(data);
			})
			.catch((error) => {
				setToast({ type: "error", msg: error.data.message });
			});
	};

	const handleFetchAreas = async (cityId) => {
		const { data } = await getAllCityAreas(cityId);
		if (data) {
			let tempAreas = data;
			setAreaList(tempAreas);
			setReInitialize(false);
		}
	};

	const handleFormSubmit = async (formData) => {
		const carType = formData.carType.map((carType, index) => {
			return {
				type: carType.type,
				price: +carType.price,
				discountPrice: +carType.discountPrice,
				discountType: carType.discountType?.value,
				isChecked: carType.isChecked,
			};
		});

		let apiData = {
			cityId: formData.cityId._id,
			areaId: formData.areaId?.map((area) => area?._id),
			serviceName: formData.serviceName,
			serviceTime: formData.serviceTime,
			serviceType: formData.serviceType?.value,
			assets: selectedAssets,
			includeGST: formData.includeGST,
			gst: formData.gst,
			serviceDescription: formData.serviceDescription,
			carType: carType,
			// imageUrl: imageUrlArray[0],
		};

		if (isEdit) {
			if (data?.cityId !== formData?.cityId?._id) {
				apiData.areaUpdated = true;
			} else if (formData?.areaId.length !== 1) {
				apiData.areaUpdated = true;
			} else if (formData?.areaId[0]._id !== data?.areaId) {
				apiData.areaUpdated = true;
			}
			if (formData.photo instanceof FileList) {
				let imageUrlArray = await uploadImages(formData.photo);
				if (!imageUrlArray) return;
				apiData.imageUrl = imageUrlArray[0];
				const { data: d, error } = await updateService({ data: apiData, serviceId: data._id });
				if (d) {
					setToast({ type: "success", msg: "Service Updated successfully" });
					closeModal();
				} else setToast({ type: "error", msg: error.data.message });
			} else {
				const { data: d, error } = await updateService({
					data: { ...apiData, imageUrl: data?.imageUrl },
					serviceId: data._id,
				});
				if (d) {
					setToast({ type: "success", msg: "Service Updated successfully" });
					navigate("/service-management");
					closeModal();
				} else setToast({ type: "error", msg: error.data.message });
			}
			return;
		}

		let imageUrlArray = await uploadImages(formData.photo);
		if (!imageUrlArray) return;
		apiData.imageUrl = imageUrlArray[0];

		addService(apiData)
			.then((resp) => {
				setToast({ type: "success", msg: "Service created successfully" });
				closeModal();
			})
			.catch((error) => {
				setToast({ type: "error", msg: error.data.message });
			});
	};

	const ServiceForm = () => {
		const { values, setFieldValue, errors, touched, isSubmitting } = useFormikContext();
		useEffect(() => {
			if (!isEdit) return;
			// set initial values for edit form
			setFieldValue("assets", data?.assets);
			setFieldValue("includeGST", data?.includeGST);
			setFieldValue("gst", data?.gst);
			setFieldValue("serviceDescription", data?.serviceDescription);
		}, []);

		useEffect(() => {
			if (!isEdit || !AllCarTypes) return;
			AllCarTypes?.map((allCarType, index) => {
				data?.carType?.map((carType) => {
					if (allCarType.value === carType.type) {
						setFieldValue(
							`carType[${index}].discountType`,
							discountTypeOptions?.find((item) => {
								return item.value === carType.discountType;
							})
						);
						setFieldValue(`carType[${index}].isChecked`, carType.isChecked);
						setFieldValue(`carType[${index}].discountPrice`, carType.discountPrice);
						setFieldValue(`carType[${index}].price`, carType.price);
					}
				});
			});
		}, [AllCarTypes]);

		const handleFetchAreas = async (cityId) => {
			const { data } = await getCitySupervisors(cityId);
			if (data) {
				let tempAreas = data;
				setAreaList(tempAreas);
			}
		};
		useEffect(() => {
			if (values?.cityId?._id) setCityId(values?.cityId?._id);
		}, [values?.cityId?._id]);

		useEffect(() => {
			if (selectedAssets.length) return;
			setFieldValue("assets", []);
		}, [selectedAssets]);

		// useEffect(() => {
		// 	if (!values.includeGST) return;
		// 	setFieldValue("gst", "");
		// }, [values.includeGST]);

		const handleSelectedCities = (selectedCities) => {
			setFieldValue("cityId", selectedCities);
		};

		const handleSelectedArea = (selectedAreas) => {
			setFieldValue("areaId", selectedAreas);
		};

		const calculateFinalPrice = (discountType, mrp, discount, name) => {
			let discountedPrice = mrp;
			if (discountType === "flat") {
				discountedPrice = mrp - discount;
			} else {
				discountedPrice = mrp * (1 - discount / 100);
			}
			if (values.gst && !values.includeGST) {
				discountedPrice = discountedPrice * (values.gst / 100 + 1);
			}
			return discountedPrice.toFixed(2).replace(/\.00$/, "");
		};

		const handleAddAsset = async (assetData) => {
			setNonFormikErrors(() => {});
			if (!values?.assetName?.value || !values?.quantity?.value || !values?.unit?.value) {
				!values?.assetName?.value &&
					setNonFormikErrors((nonFormikErrors) => ({
						...nonFormikErrors,
						assetName: "Asset Name is required.",
					}));
				!values?.unit?.value &&
					setNonFormikErrors((nonFormikErrors) => ({
						...nonFormikErrors,
						unit: "Unit is required.",
					}));
				!values?.quantity?.value &&
					setNonFormikErrors((nonFormikErrors) => ({
						...nonFormikErrors,
						quantity: "Quantity is required.",
					}));
			} else {
				setSelectedAssets([assetData, ...selectedAssets]);
				setFieldValue("assetName", "");
				setFieldValue("quantity", "");
				setFieldValue("unit", "");
				setNonFormikErrors({});
				setFieldValue("assets", [assetData, ...selectedAssets]);
			}
		};

		const handleIncreaseHours = () => {
			setFieldValue("serviceTime[0].hours", values.serviceTime[0].hours + 1);
		};
		const handleDecreaseHours = () => {
			setFieldValue("serviceTime[0].hours", values.serviceTime[0].hours - 1);
		};

		const handleIncreaseMins = () => {
			setFieldValue("serviceTime[0].minutes", values.serviceTime[0].minutes + 1);
		};

		const handleDecreaseMins = () => {
			setFieldValue("serviceTime[0].minutes", values.serviceTime[0].minutes - 1);
		};

		const uniqueOptions = getUniqueArray(assestOptions, selectedAssets);

		useEffect(() => {
			if (!values?.assetName) {
				setFieldValue("quantity", "");
				setFieldValue("unit", "");
			}
		}, [values?.assetName]);

		return (
			<div className="overflow-y-auto max-h-[62vh] pt-4 px-5 modal-ht-inner">
				<div className="flex w-full space-x-4 ">
					<div className="w-1/2">
						{/* <SelectMenu
							label="Select City"
							name="cityId"
							options={cityList}
							defaultOption={defaultOptionForCity}
						/> */}
						<SelectMenu
							name="cityId"
							options={cities}
							onChange={(option) => {
								setFieldValue("cityId", option);
								setFieldValue("areaId", "");
							}}
							value={values.cityId}
							placeholder="Select City"
							label="City"
							getOptionValue={(option) => option._id}
							showCheckboxOnRight={false}
						/>
						{touched?.cityId && errors?.cityId ? (
							<div className="text-sm text-red-600 error">{errors?.cityId}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<div className="w-1/2">
						<SelectMenu
							isMulti={true}
							label="Area"
							name="areaId"
							options={areaList}
							onChange={(option) => setFieldValue("areaId", option)}
							value={values.areaId}
							placeholder="Select Area"
							showCheckboxOnRight={false}
							hideSelectedOptions={false}
						/>
						{touched?.areaId && errors?.areaId ? (
							<div className="text-sm text-red-600 error">{errors?.areaId}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>
				<div className="flex space-x-4">
					<div className="w-1/2 space-y-3">
						<div className="">
							<FormField
								field={{
									label: "Service Name",
									name: "serviceName",
									type: "text",
									placeholder: "Enter Service Name",
								}}
							/>
						</div>
						<div>
							<SelectMenu
								label="Service Type"
								name="serviceType"
								options={serviceType}
								onChange={(option) => setFieldValue("serviceType", option)}
								value={values.serviceType}
								placeholder="Select Service Type"
								showCheckboxOnRight={false}
							/>
							{touched?.serviceType && errors?.serviceType ? (
								<div className="text-sm text-red-600 error">{errors?.serviceType}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
					</div>
					<div className="w-1/2 ">
						<div className="">
							<div className="">
								<div>
									<p className="text-sm font-medium text-[##6B7280] mb-2">Service Time</p>
								</div>
								<div>
									{/* <SelectMenu name="serviceTime" options={serviceTimeOption} /> */}
									<div className="grid grid-cols-2 gap-5">
										<div className="flex justify-between items-center border rounded-md overflow-invisible h-[2.3rem]">
											<span className="px-4 h-full bg-[#F5F5F5CC]/80 pt-1.5">Hrs</span>
											<p className="text-sm font-medium text-[##6B7280] py-2 pl-5">
												{values.serviceTime[0].hours}
											</p>
											<div className="flex pl-5 pr-2 space-x-1">
												<AiOutlineMinusCircle
													color="#000000"
													onClick={() => handleDecreaseHours()}
												/>
												<AiOutlinePlusCircle
													color="#000000"
													onClick={() => handleIncreaseHours()}
												/>
											</div>
										</div>
										<div className="flex items-center justify-between border rounded-md overflow-invisible">
											<span className="px-4 h-full bg-[#F5F5F5CC]/80 pt-1.5">Min</span>
											<p className="text-sm font-medium text-[##6B7280] py-2 pl-5">
												{values.serviceTime[0].minutes}
											</p>
											<div className="flex pl-5 pr-2 space-x-1">
												<AiOutlineMinusCircle
													color="#000000"
													onClick={() => handleDecreaseMins()}
												/>
												<AiOutlinePlusCircle color="#000000" onClick={() => handleIncreaseMins()} />
											</div>
										</div>
									</div>
									{errors?.serviceTime && touched?.serviceTime && errors?.serviceTime[0]?.hours ? (
										<div className="text-sm text-red-600 error">
											{errors?.serviceTime[0]?.hours}
										</div>
									) : (
										<div className="invisible h-5"></div>
									)}
									{errors?.serviceTime &&
									touched?.serviceTime &&
									errors?.serviceTime[0]?.minutes ? (
										<div className="text-sm text-red-600 error">
											{errors?.serviceTime[0]?.minutes}
										</div>
									) : (
										<div className="invisible h-5"></div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="border rounded-xl mt-3 bg-[#F5F5F5] gap-x-5 p-2 my-3 ">
					<div className="flex w-full">
						<div className="w-1/2">
							<SelectMenu
								isClearable={true}
								label="Select Asset"
								name="assetName"
								options={uniqueOptions}
								onChange={(option) => setFieldValue("assetName", option)}
								value={values.assetName}
								placeholder="Select Asset"
								showCheckboxOnRight={false}
							/>
							{nonFormikErrors?.assetName ? (
								<div className="text-sm text-red-600">{nonFormikErrors?.assetName}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<div className="w-1/2">
							<div className="flex justify-evenly">
								<div className="w-1/4">
									<div className="">
										<SelectMenu
											label="Quantity"
											name="quantity"
											options={quantityOptions}
											onChange={(option) => setFieldValue("quantity", option)}
											value={values.quantity}
											placeholder="Qty"
											showCheckbox={false}
										/>
										{nonFormikErrors?.quantity ? (
											<div className="text-sm text-red-600">{nonFormikErrors?.quantity}</div>
										) : (
											<div className="invisible h-5"></div>
										)}
									</div>
								</div>
								<div className="w-1/4">
									<div className="">
										<SelectMenu
											label="Unit"
											name="unit"
											options={unitOptions}
											onChange={(option) => setFieldValue("unit", option)}
											value={values.unit}
											placeholder="Units"
										/>
										{nonFormikErrors?.unit ? (
											<div className="text-sm text-red-600">{nonFormikErrors?.unit}</div>
										) : (
											<div className="invisible h-5"></div>
										)}
									</div>
								</div>

								<Button
									className="bg-black mt-6 text-white cursor-pointer h-[2.4rem]"
									onClick={() => {
										handleAddAsset({
											assetName: values?.assetName?.name,
											quantity: values?.quantity?.value,
											unit: values?.unit?.value,
											_id: values?.assetName?._id,
										});
									}}
								>
									Add Assets
								</Button>
							</div>
						</div>
					</div>

					<div className="flex w-full gap-3 pb-2 mt-3 overflow-y-auto">
						{selectedAssets?.map((asset, i) => {
							return (
								<div
									key={i}
									className="flex gap-2 border rounded-lg px-3 py-0.5 w-max items-center bg-white whitespace-nowrap"
								>
									<div>
										{asset.assetName} {asset.quantity} {asset.unit}
									</div>
									<div className="flex items-center justify-center w-2 h-2 ">
										<img
											src={closeIcon}
											alt=""
											className="w-2 h-2 cursor-pointer"
											onClick={() => {
												setSelectedAssets(selectedAssets.filter((e, index) => index !== i));
											}}
										></img>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				{touched.assets && errors?.assets ? (
					<div className="text-sm text-red-600 error">{errors.assets}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
				<div className="flex justify-between h-10 mb-3">
					<div className="space-x-2">
						<Field
							type="checkbox"
							name="includeGST"
							className="rm-font-overpass mr-[0.83vw] border-[#ACACAC] checked:hover:bg-[#ACACAC] checked:focus:bg-[#ACACAC] checked:bg-[#ACACAC] active:bg-[#ACACAC] focus:ring-transparent focus:ring-offset-0  sm:mx-3"
							// className="rm-font-overpass mr-[0.83vw] border-[#c42f52] bg-inherit checked:bg-[#c42f52] hover:text-[#c42f52] focus:ring-transparent focus:ring-offset-0 focus:checked:bg-[#c42f52] sm:mx-3"
						/>
						<label htmlFor="checkbox">Include GST in the prices</label>
					</div>
					<div className="flex flex-col">
						<div className="flex justify-around items-center border rounded-lg overflow-invisible w-[15rem]">
							<div className="whitespace-nowrap px-3 py-1 border-r-[1px] h-full w-[60%] justify-center flex items-center">
								Add GST %
							</div>
							<Field
								nonFormikError
								name="gst"
								type="number"
								placeholder="GST"
								className={`h-full w-[40%] border-none arrow-none ${
									values?.includeGST ? "cursor-not-allowed" : ""
								}`}
								disabled={values?.includeGST}
							></Field>
						</div>
						{errors?.gst && touched.includeGST ? (
							<div className="text-sm text-red-600 text-end">{errors?.gst}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>
				<div className="border rounded-xl w-full bg-[#F5F5F5] mt-8">
					<div className="flex px-3 space-x-2">
						<div className="w-1/4">
							<h1 className="text-sm font-medium text-[#111827] my-3">Car Type</h1>
							{AllCarTypes?.map((carType, index, array) => (
								<>
									<div className="space-x-2 border rounded-md px-2 py-1.5 mb-1 bg-[#FFF] h-[2.3rem] flex items-center">
										<Field
											type="checkbox"
											name={`carType[${index}].isChecked`}
											className="rm-font-overpass mr-[0.83vw] bg-inherit border-[#ACACAC] checked:hover:bg-[#ACACAC] checked:focus:bg-[#ACACAC] checked:bg-[#ACACAC] focus:ring-transparent focus:ring-offset-0 sm:mx-3"
										/>
										<label htmlFor="checkbox" className="text-sm">
											{carType.label}
										</label>
									</div>
									<div className="invisible h-4 mb-1">hidden</div>
								</>
							))}
						</div>
						<div className="w-1/4">
							<h1 className="text-sm font-medium text-[#111827] my-3">Price</h1>
							{AllCarTypes?.map((carType, index) => (
								<div>
									<FormField
										nonFormikError
										field={{
											name: `carType[${index}].price`,
											type: "text",
											placeholder: "0 /-",
											onKeyPress: (event) => {
												if (/[^0-9 ]/.test(event.key)) {
													return event.preventDefault();
												}
											},
										}}
										showPrefix={true}
										prefixLabel=" ₹ "
										fieldValue={values?.carType[index].price}
									/>
									{touched.carType?.[index]?.price &&
									errors?.carType &&
									errors?.carType[index]?.price ? (
										<div className="text-sm text-red-600 error">{errors.carType[index].price}</div>
									) : (
										<div className="invisible h-5"></div>
									)}
								</div>
							))}
						</div>
						<div className="w-1/4">
							<h1 className="text-sm font-medium text-[#111827] my-3">Discount Type</h1>
							{AllCarTypes?.map((carType, index) => (
								<>
									<div className="">
										<SelectMenu
											name={`carType[${index}].discountType`}
											options={discountTypeOptions}
											onChange={(option) => setFieldValue(`carType[${index}].discountType`, option)}
											value={values.carType[index].discountType}
											placeholder="Select"
										/>
									</div>
									<div className="h-5"></div>
								</>
							))}
						</div>
						<div className="w-1/4">
							<h1 className="text-sm font-medium text-[#111827] my-3">Discount Amount</h1>
							<div className="">
								{AllCarTypes?.map((carType, index) => (
									<div>
										<FormField
											nonFormikError
											field={{
												name: `carType[${index}].discountPrice`,
												type: "text",
												placeholder:
													values.carType[index]?.discountType?.value === "flat" ? "0 /-" : "0",
												onKeyPress: (event) => {
													if (/[^0-9 ]/.test(event.key)) {
														return event.preventDefault();
													}
												},
											}}
											showPrefix={true}
											prefixLabel={
												values.carType[index]?.discountType?.value == "flat" ? " ₹ " : " % "
											}
											fieldValue={values?.carType[index]?.discountPrice}
										/>
										{touched.carType?.[index]?.discountPrice &&
										errors?.carType &&
										errors?.carType[index]?.discountPrice ? (
											<div className="text-sm text-red-600 error">
												{errors.carType[index].discountPrice}
											</div>
										) : (
											<div className="invisible h-5"></div>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				{touched.carType && errors?.carType && typeof errors?.carType === "string" ? (
					<div className="text-sm text-red-600 error">{errors.carType}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
				<hr className="mb-4 border" />
				<div className="flex gap-10 overflow-x-auto flex-start final-price-scrollbar">
					{values.carType?.map((carType, index) => (
						<div className="">
							<h1 className="text-sm font-medium text-[#111827] mb-3 whitespace-nowrap">
								Final{" "}
								<span
									className={`text-sm font-medium ${
										carType.type === "suv" ? "uppercase" : "capitalize"
									} `}
								>
									{carType.type}
								</span>{" "}
								Price
							</h1>
							<div>
								₹{" "}
								{calculateFinalPrice(
									carType?.discountType?.value,
									carType?.price,
									carType?.discountPrice,
									"sedan"
								)}
							</div>
						</div>
					))}
					{/* <div className="w-[20%]">
						<h1 className="text-sm font-medium text-[#111827] mb-3">Final Sedan Price</h1>
					</div>
					<div className="w-[20%]">
						<h1 className="text-sm font-medium text-[#111827] mb-3">Final SUV Price</h1>
					</div>
					<div className="w-[20%]">
						<h1 className="text-sm font-medium text-[#111827] mb-3">Final HatchBack Price</h1>
					</div> */}
				</div>
				{/* <div className="flex flex-start space-x-28">
					{values.carType?.map((carType, index) => (
						<div className="w-[10%]">
							<h1 className="text-sm font-medium text-[#475467] mb-3">
								₹{" "}
								{calculateFinalPrice(
									carType?.discountType?.value,
									carType?.price,
									carType?.discountPrice,
									"sedan"
								)}
							</h1>
						</div>
					))}
				</div> */}
				<hr className="mt-1 mb-4 border" />
				<div className="grid grid-cols-2 space-x-4">
					<div className="">
						<div>
							<div className="mb-1 text-sm font-medium">Service Icon</div>
							<ImageLoadFormField
								bottomText={"png format of 24x24 px"}
								imageUrl={data?.imageUrl}
								instructions={
									"Note: Please upload a Service icon with white background and a ratio of 2:1"
								}
								isEdit={isEdit && true}
								changeImgButtonName="Change Icon"
								placeholderImg={placeholderImg}
								imgFormat="image/png"
								// acceptableWidth={24}
								// acceptableHeight={24}
							/>
							<div className="text-sm text-red-600 error">
								{errors.photo && touched.photo ? <div>{errors.photo}</div> : null}
							</div>
						</div>
					</div>
					<div className="">
						<h1 className="text-sm font-medium text-[#111827] mb-1">Service Description</h1>
						<Field
							placeholder="Description here"
							name="serviceDescription"
							as="textarea"
							className={`block w-full h-40 appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
						/>
					</div>
				</div>

				<div className="grid grid-cols-2 pt-4 my-3 space-x-4">
					<Button
						className="bg-[#F5F5F5] rounded-lg text-[#111827] cursor-pointer bottom-btn-ht"
						onClick={closeModal}
					>
						Cancel
					</Button>
					{!data ? (
						<Button
							className="bg-[#FB0C0C] rounded-lg text-white cursor-pointer bottom-btn-ht"
							type="submit"
							isSubmitting={status1 === "pending" || status2 === "pending"}
						>
							Add New Service
						</Button>
					) : (
						<Button
							className="bg-[#FB0C0C] rounded-lg text-white cursor-pointer bottom-btn-ht"
							type="submit"
							isSubmitting={status1 === "pending" || status2 === "pending"}
						>
							Save
						</Button>
					)}
				</div>
			</div>
		);
	};

	if (!AllCarTypes) return null;

	return (
		<div className="w-[52rem] rounded-xl modal-height-service h-[80vh] overflow-hidden">
			<div>
				<div className="pb-4 pl-5">
					<div className="flex items-center justify-between pt-5">
						<div
							className={`h-12 w-12 ${
								!data ? "bg-[#FFC107]" : "bg-[#0D6EFD]"
							} rounded-full flex justify-center items-center modal-logo-size`}
						>
							{!data ? (
								<Setting3 color="white" className="w-6 h-6 service-modal-icon-ht" />
							) : (
								<img src={Edit} className="w-12 h-12" alt="" />
							)}
						</div>
					</div>
					<div className="mt-6 mb-1">
						{!data ? (
							<h1 className="text-xl font-inter-bold">Add New Service</h1>
						) : (
							<h1 className="text-xl font-inter-bold">Edit {data?.serviceName}</h1>
						)}
					</div>
					<div>
						{!data ? (
							<h1 className="text-base font-normal text-[#475467]">
								Create New Service by Adding the details below
							</h1>
						) : (
							<h1 className="text-base font-normal text-[#475467]">
								Edit service details by changing the details below
							</h1>
						)}
					</div>
				</div>
				{console.log("area", areas, data, [
					areas?.[areas?.findIndex((e) => e._id === data?.areaId)],
				])}
				<div className="pb-5 pr-3">
					<Formik
						enableReinitialize={reinitialize}
						initialValues={{
							cityId:
								isEdit && cities ? cities[cities?.findIndex((e) => e._id === data?.cityId)] : "",
							areaId:
								isEdit && areas ? [areas[areas?.findIndex((e) => e._id === data?.areaId)]] : "",

							serviceName: isEdit ? data.serviceName : "",

							carType: AllCarTypes.map((cartype, index) => ({
								price: "",
								discountType: discountTypeOptions[0],
								discountPrice: "",
								isChecked: false,
								type: cartype.value,
							})),

							serviceDescription: "",
							assets: [],
							serviceTime: [
								{
									hours: isEdit ? data.serviceTime[0].hours : 0,
									minutes: isEdit ? data.serviceTime[0].minutes : 0,
								},
							],
							includeGST: false,
							photo: isEdit ? "image" : null,
							serviceType: isEdit
								? serviceType[serviceType?.findIndex((e) => e.value === data?.serviceType)]
								: "",
						}}
						validationSchema={ServiceSchema}
						onSubmit={(values) => {
							handleFormSubmit(values);
						}}
					>
						{({ isSubmitting, values, errors, touched }) => (
							<Form className="flex gap-5">
								<div className="w-full gap-5">{<ServiceForm />}</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default ServiceModal;
