import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import checkIcon from "../../../assets/icons/check.svg"
import Button from '../../../shared/components/Button.component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "iconsax-react";
import moment from "moment";
import useSelectModal from '../../../utils/hooks/useSelectModal';
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import { useAddShiftsForRidersMutation, useLazyGetConfigurationQuery, useLazyGetRidersShiftsQuery, useLazyGetShiftsByRidersCityQuery, useUpdateShiftsForRidersMutation } from '../../../utils/Store';
import { useParams } from 'react-router';
import useToaster from "../../../utils/hooks/useToaster";


const ShiftTiming = ({ riderName }) => {
	const [activeDay, setActiveDay] = useState('Monday');
	const [selectedShifts, setSelectedShifts] = useState({}); // Getting object of days
	const [shiftsFormVisible, setShiftsFormVisible] = useState(false);
	const [savedShifts, setSavedShifts] = useState({});
	// const [selectedDayData, setSelectedDayData] = useState(null);
	const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [isEditMode, setIsEditMode] = useState(false);
	const [uniqueid, setUniqueid] = useState()
	const [selectedOptions, setSelectedOptions] = useState({ name: "Today", value: "today" });
	const { openModal } = useSelectModal();
	const [getShiftsByRidersCity, { data: shiftsByRidersCityData }] = useLazyGetShiftsByRidersCityQuery();
	const [addShiftsForRiders, { data: addShiftsForRidersData }] = useAddShiftsForRidersMutation();
	const [getRidersShift, { data: getRidersShiftData }] = useLazyGetRidersShiftsQuery();
	const [getConfigurations, { data: configurationData }] = useLazyGetConfigurationQuery();
	const defaultTimeModule = configurationData?.find((element) => element.module === "defaultTime");
	const [updateShiftsForRiders, { data: updateShiftsForRidersData }] = useUpdateShiftsForRidersMutation();
	const { setToast } = useToaster();

	const { riderId } = useParams();

	useEffect(() => {
		getShiftsByRidersCity(riderId);
		getRidersShift(riderId);
	}, [riderId]);

	useEffect(() => {
		getConfigurations();
	}, []);

	const [selectedDayDataArray, setSelectedDayDataArray] = useState([]);

	useEffect(() => {
		if (getRidersShiftData && getRidersShiftData.length > 0) {
			const initialDataArray = getRidersShiftData.map(({ data }) => {
				// Assuming each 'data' array has at least one element
				const initialTimeslots = data[0].timeslots;
				return { day: "", timeslots: initialTimeslots };
			});

			setSelectedDayDataArray(initialDataArray);
		}
	}, [getRidersShiftData]);


	const namesByShiftCategory = {};

	shiftsByRidersCityData?.forEach(item => {
		const shiftCategories = item.shiftcategories;

		if (shiftCategories && shiftCategories.length > 0) {
			const shiftCategoryName = shiftCategories[0].name;
			const itemName = item.name;
			const timeslot = item.timeslot;

			if (!namesByShiftCategory[shiftCategoryName]) {
				namesByShiftCategory[shiftCategoryName] = [];
			}

			namesByShiftCategory[shiftCategoryName].push({ name: itemName, timeslot });
		}
	});

	console.log(namesByShiftCategory)

	const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
		<div
			className={`flex gap-2 items-center border-[#000]/10 border rounded-[10px] text-sm h-[3.1rem] 2xl:w-[10rem] w-[10rem] py-2 pl-4 bg-[#f4f4f4] ${!value && "text-[#000]"}`}
			onClick={onClick}
			ref={ref}
		>
			<Calendar className="h-5 w-5" />
			{value || placeholder}
		</div>
	));

	const [selectedShiftData, setSelectedShiftData] = useState()
	const [availableDays, setAvailableDays] = useState([]);
	useEffect(() => {
		const days = [];
		const currentDate = moment(startDate);
		const lastDate = moment(endDate);
		while (currentDate.isSameOrBefore(lastDate, 'day')) {
			days.push(currentDate.format('dddd'));
			currentDate.add(1, 'day');
		}
		setAvailableDays(days);
		const firstAvailableDay = daysOfWeek.find(day => days.includes(day))
		if (firstAvailableDay) setActiveDay(firstAvailableDay)
	}, [startDate, endDate]);


	useEffect(() => {
		if (!selectedShiftData)
			setEndDate(new Date(startDate))
	}, [startDate]);


	// const generateInitialValues = () => {
	// 	const initialValues = {};
	// 	daysOfWeek.forEach(day => {
	// 		Object.keys(namesByShiftCategory).forEach(shift => {
	// 			namesByShiftCategory[shift].forEach((_, index) => {
	// 				initialValues[`${day.toLowerCase()}${shift}${index}`] = false;
	// 			});
	// 		});
	// 	});
	// 	return initialValues;
	// };

	const availableShifts =
		selectedShiftData?.data.map(dayItem =>
			dayItem.timeslots.map(timeslot => (dayItem.day + timeslot.shift + timeslot.name))
		)
			?.flat();

	const generateInitialValues = () => {
		const initialValues = {};
		daysOfWeek.forEach(day => {
			Object.keys(namesByShiftCategory).forEach(shift => {
				namesByShiftCategory[shift].forEach((_, index) => {
					const availableShift = availableShifts?.find(
						available => available === day + shift + _.name
					);
					initialValues[`${day.toLowerCase()}${shift}${index}`] = !!availableShift;
				});
			});
		});
		return initialValues;
	};

	const updateSelectedShifts = (day, values) => {
		const selectedShiftsForDay = Object.keys(values).filter(
			key => key.startsWith(day.toLowerCase()) && values[key]
		);

		setSelectedShifts(prev => ({
			...prev,
			[day]: selectedShiftsForDay.length > 0,
		}));
	};

	const handleSubmit = (values) => {
		let formattedShifts = {
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
		};

		daysOfWeek.forEach(day => {
			let dayShifts = {};
			if (!availableDays?.includes(day)) return
			Object.keys(namesByShiftCategory).forEach(shift => {
				let times = namesByShiftCategory[shift].filter((_, index) => {
					return values[`${day.toLowerCase()}${shift}${index}`];
				});

				if (times.length > 0) {
					dayShifts[shift] = times;
				}
			});
			if (Object.keys(dayShifts).length > 0) {
				formattedShifts[day] = dayShifts;
			}
		});
		if (Object.keys(formattedShifts).length === 9) return setToast({ type: "error", msg: "There should be at least one off day" });
		if (Object.keys(formattedShifts).length === 2) return setToast({ type: "error", msg: "Please select shift time" });

		setSavedShifts(formattedShifts);
		handleAddEdit(formattedShifts);
	};


	let transformDataForPost = (savedShifts) => {
		const transformedData = {
			data: [],
			startDate: savedShifts?.startDate,
			endDate: savedShifts?.endDate,
		};

		for (const day in savedShifts) {
			if (Object.hasOwnProperty.call(savedShifts, day)) {
				if (day === 'startDate' || day === 'endDate') {
					continue;
				}

				const shiftsByDay = savedShifts[day];
				const shifts = [];

				for (const shiftCategory in shiftsByDay) {
					if (Object.hasOwnProperty.call(shiftsByDay, shiftCategory)) {
						const timeslots = shiftsByDay[shiftCategory];

						if (Array.isArray(timeslots)) {
							timeslots.forEach((timeslot) => {
								shifts.push({
									shift: shiftCategory,
									timeslot: timeslot.timeslot,
									name: timeslot.name,
								});
							});
						}
					}
				}

				transformedData.data.push({ shifts, day });
			}
		}

		return transformedData;
	};

	const handleAddEdit = async (savedShifts) => {
		let requestBody = transformDataForPost(savedShifts);
		if (isEditMode) {
			const { data, error } = await updateShiftsForRiders({
				uniqueId: uniqueid,
				body: requestBody,
			});

			setIsEditMode(false)

			if (data) {
				openModal("AddShiftModal", {
					riderName,
					refetchRidersShift: () => getRidersShift(riderId),
					setShiftsFormVisible: () => setShiftsFormVisible(false),
					isEditMode
				});
				setSelectedShiftData(null)
				setSelectedShifts({})
				setStartDate(new Date())
				setEndDate(new Date())
			} else {
				setToast({ type: "error", msg: error?.data?.message });
				setShiftsFormVisible(false)
			}
		} else {
			const { data, error } = await addShiftsForRiders({
				riderId: riderId,
				body: requestBody,
			});

			if (data) {
				openModal("AddShiftModal", {
					riderName,
					refetchRidersShift: () => getRidersShift(riderId),
					setShiftsFormVisible: () => setShiftsFormVisible(false),
				});
			} else {
				setToast({ type: "error", msg: error?.data?.message });
			}
		}
	};


	return (
		<div className="container mx-auto">
			<div className='flex justify-between pb-3'>
				<Button className={`bg-[#000] text-[#FFF] w-[13rem] h-[3rem] font-inter-bold rounded-[10px] ${shiftsFormVisible && "cursor-not-allowed"}`}
					onClick={() => setShiftsFormVisible(true)}
					disabled={shiftsFormVisible}
				>Create New Shift</Button>
				<div className='bg-white self-center rounded-lg p-3 border flex'>*Default working timing is from
					<span className="flex gap-1 ml-1">
						{defaultTimeModule ?
							(
								<>
									{" "}
									<span>{defaultTimeModule?.value?.split(" - ")[0]}</span>
									<span>To</span>
									<span>{defaultTimeModule?.value?.split(" - ")[1]}.</span>
								</>
							) : (
								<span>9AM To 7PM.</span>
							)
						}
					</span>
				</div>
			</div>

			{shiftsFormVisible && (
				Object.keys(namesByShiftCategory).length ? (
					<Formik
						initialValues={generateInitialValues()}
						onSubmit={handleSubmit}
						enableReinitialize={true}
					>
						{({ values, setFieldValue }) => (
							<Form className="bg-white py-5 px-10 shadow-lg rounded-[20px] brand-details-section">
								<div className="flex items-center justify-between">
									<div className='flex items-center 2xl:space-x-4 space-x-4 pb-4'>
										<h1 className="text-base text-[#333333] font-normal">Date Range :</h1>
										<div className="calendar-icon-size all-rider-date-input">
											<DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												dateFormat="dd-MM-yyyy"
												disabled={selectedShiftData}
												// minDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
												minDate={new Date()}
												customInput={<CustomInput />}
											/>
										</div>
										<h1 className="font-plus-jakarta text-sm text-center px-1">To</h1>
										<div className="calendar-icon-size all-rider-date-input">
											<DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
												selected={endDate}
												onChange={(date) => setEndDate(date)}
												dateFormat="dd-MM-yyyy"
												disabled={selectedShiftData}
												// minDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
												// minDate={moment(startDate).toDate()}
												minDate={new Date(startDate)}
												customInput={<CustomInput />}
											/>
										</div>
									</div>
								</div>
								<div className="mb-4 pl-5">
									<div className="flex justify-between border-b w-3/5">
										{daysOfWeek.map(day => (
											<div key={day} className="text-center">
												<button
													disabled={!availableDays?.includes(day)}
													type="button"
													onClick={() => setActiveDay(day)}
													className={`text-base flex gap-2 font-inter-medium py-2 ${activeDay === day ? 'border-b-2 border-[#000] text-[#000]' : 'text-gray-600'}`}
												>
													{day}
													{Object.entries(values)
														.filter(([key, value]) => value === true)
														.map(([key]) => key)?.some(e => e.includes(day.toLowerCase())) && availableDays?.includes(day) && <img src={checkIcon} alt='' className='h-5' />
													}
												</button>
											</div>
										))}
									</div>
								</div>

								<div className="flex flex-wrap bg-white pt-3 px-4 shadow border border-solid border-[#E5E5E5] rounded-[20px] mt-2 mx-4 brand-details-section">
									{Object.entries(namesByShiftCategory)?.map(([shift, items]) => (
										<div key={shift} className="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
											<div className="text-base font-inter-medium text-[#000] mb-2">{shift}</div>
											{items?.map(({ name, timeslot }, index) => {
												const fieldName = `${activeDay.toLowerCase()}${shift}${index}`;
												return (
													<div key={index} className="flex justify-between items-center mb-2">
														<span className="text-gray-700">{name}</span>
														<Field
															type="checkbox"
															name={fieldName}
															className="form-checkbox h-4 w-4 checkbox-custom mr-20"
															id={fieldName}
															onChange={(e) => {
																setFieldValue(fieldName, e.target.checked);
																updateSelectedShifts(activeDay, {
																	...values,
																	[fieldName]: e.target.checked,
																});
															}}
														/>
													</div>
												);
											})}
										</div>
									))}
								</div>

								<div className="flex justify-end gap-3 px-4 pt-4">
									<Button
										className="w-[11rem] text-[#000] bg-[#F1F1F1] cursor-pointer bottom-btn-ht border border-solid border-[#D0D0D0] font-inter-medium text-base"
										type="button"
										onClick={() => {
											setShiftsFormVisible(false)
											setSelectedShiftData(null)
											setSelectedShifts({})
											setStartDate(new Date())
											setEndDate(new Date())
										}}
									>
										Cancel
									</Button>
									<Button
										className="w-[11rem] text-[#FFF] cursor-pointer bottom-btn-ht font-inter-medium text-base"
										type="submit"
									>
										Save
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				) : (
					<div className='text-center mb-4 mt-[5rem]'>
						<p className='font-inter-medium text-base'>No data found</p>
					</div>
				)
			)}

			{!shiftsFormVisible && (
				getRidersShiftData?.length ? (
					<div className='h-[31vh] overflow-y-auto px-3'>
						{
							getRidersShiftData?.map((ridersShiftData, index) => {
								return (
									<div className="bg-white py-5 px-10 shadow-lg rounded-[20px] mb-3 brand-details-section">
										<div className="flex items-center justify-between">
											<div className='flex items-center 2xl:space-x-4 space-x-4 pb-4'>
												<h1 className="text-base text-[#333333] font-normal">Date Range :</h1>
												<div className="calendar-icon-size all-rider-date-input">
													<DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
														selected={new Date(ridersShiftData?.startDate)}
														// onChange={(date) => setStartDate(date)}
														dateFormat="dd-MM-yyyy"
														// disabled={selectedOptions.value !== "custom" ? true : null}
														// minDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
														// minDate={new Date()}
														disabled
														customInput={<CustomInput />}
													/>
												</div>
												<h1 className="font-plus-jakarta text-sm text-center px-1">To</h1>
												<div className="calendar-icon-size all-rider-date-input">
													<DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
														selected={new Date(ridersShiftData?.endDate)}
														// onChange={(date) => setEndDate(date)}
														dateFormat="dd-MM-yyyy"
														// disabled={selectedOptions.value !== "custom" ? true : null}
														// minDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
														// minDate={moment(startDate).toDate()}
														// minDate={new Date()}
														disabled
														customInput={<CustomInput />}
													/>
												</div>
											</div>
											<div className='flex gap-3'>
												<img
													src={EditIcon}
													alt="edit"
													onClick={() => {
														if (new Date(ridersShiftData?.endDate) >= new Date()) {
															setShiftsFormVisible(true);
															setSelectedShiftData(ridersShiftData);
															setStartDate(new Date(ridersShiftData?.startDate));
															setEndDate(new Date(ridersShiftData?.endDate));
															setIsEditMode(true);
															setUniqueid(ridersShiftData?.uniqueId);
														}
													}}
													className={`h-5 w-5 ${new Date(ridersShiftData?.endDate) < new Date() ? 'cursor-not-allowed' : 'cursor-pointer'}`}
												/>

												<img
													src={DeleteIcon}
													alt="delete"
													onClick={() => {
														const shiftStartDate = moment(ridersShiftData?.startDate).format('DD-MM-YYYY');
														const shiftEndtDate = moment(ridersShiftData?.endDate).format('DD-MM-YYYY');
														return openModal("DeleteShiftModal", { ridersUniqueId: ridersShiftData?.uniqueId, refetchRidersShift: () => getRidersShift(riderId), startDate: shiftStartDate, endDate: shiftEndtDate })
													}
													}
													className="h-5 w-5 cursor-pointer"
												/>
											</div>
										</div>
										<div className="mb-4 pl-5">
											<div className="flex justify-between border-b w-3/5">
												{daysOfWeek.map(day => (
													<div key={day} className="text-center">
														<button
															type="button"
															onClick={() => {
																if (ridersShiftData?.data?.some(dataDay => dataDay.day === day)) {
																	const dayData = ridersShiftData?.data?.find(dataDay => dataDay.day === day);
																	const newArray = [...selectedDayDataArray];
																	newArray[index] = dayData;
																	setSelectedDayDataArray(newArray);
																}
															}}
															className={`text-base flex gap-2 font-inter-medium py-2 ${ridersShiftData?.data?.some(dataDay => dataDay.day === day) ? 'text-[#000]' : 'text-gray-500 cursor-default'}`}
														>
															{day} {ridersShiftData?.data?.some(dataDay => dataDay.day === day) && <img src={checkIcon} alt='' className='h-5' />}
														</button>
													</div>
												))}
											</div>
										</div>
										<div className="flex flex-wrap bg-white pt-3 px-4 shadow border border-solid border-[#E5E5E5] rounded-[20px] mt-2 mx-4 brand-details-section">
											{selectedDayDataArray[index] &&
												(() => {
													const formattedData = {};

													selectedDayDataArray[index].timeslots?.forEach(({ shift, timeslot, name }) => {
														if (!formattedData[shift]) {
															formattedData[shift] = [];
														}

														formattedData[shift].push({
															name,
															timeslot,
														});
													});

													return Object.entries(formattedData).map(([shift, data]) => (
														<div key={shift} className="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
															<div className="text-base font-inter-medium text-[#000] mb-2">{shift}</div>
															{data.map(({ name, timeslot }, subIndex) => (
																<div key={subIndex} className="flex justify-between items-center mb-2">
																	<span className="text-gray-700">{name}</span>
																</div>
															))}
														</div>
													));
												})()}
										</div>
									</div>
								)
							})
						}
					</div>
				) : (
					<div className='text-center mb-4 mt-[5rem]'>
						<p className='font-inter-medium text-base'>No data found</p>
					</div>
				)

			)}
		</div>
	);
};

export default ShiftTiming;