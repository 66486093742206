

import { nanoid } from "@reduxjs/toolkit";
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import UpDownArrow from "../../../src/assets/icons/updown-arrow.svg"
import { BsArrowDown, BsArrowUp } from "react-icons/bs";


export default function SubTableComponent({ data = [], columns, getRowCanExpand, customwidths, customeClass, isLoading, customeClassAction, ...props }) {
    const [sorting, setSorting] = useState([]);

	const table = useReactTable({
		data,
        columns: columns.map(column => ({
            ...column,
            id: nanoid(), // Assign a unique id to each column
        })),
		getRowCanExpand,
		state: {
			sorting: sorting,
		},
		onSortingChange: props.isSort && setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
	});
	if (!data) return;

	return (
		<table className="w-full border-separate border-spacing-y-2 block bg-[#00000014]">
			{data.length > 0 && (
				<tbody className={`w-[94%] ml-auto overflow-y-auto block table-body-height ${customeClass}`}>
					{table.getRowModel().rows.map((row) => {
						return (
							<>
								<tr key={row.id} className="flex role-table">
									{row.getVisibleCells().map((cell, index) => {
										return (

											<td
												key={nanoid()}
												className={`border-0 ${customwidths && customwidths[index] ? customwidths[index] : ""
													} ${(cell?.row?.original?.isActive || cell?.row?.original?.isActive == undefined) ? "" : "bg-[#F2F2F2] opacity-70"} pr-2 py-4 2xl:text-base text-sm font-inter-medium text-[#333333] pl-4 items-center flex`}
											>
												{isLoading ? <span className="skeleton block"></span> : flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									})}
								</tr>
							</>
						)
					})}
				</tbody>
			)}
			{data.length <= 0 && (
				<tbody className="overflow-y-auto max-h-[65vh] block">
					<div className="flex justify-center pt-16">
						{isLoading ? <span className="skeleton block"></span> : <label>No Data Found</label>}
					</div>
				</tbody>
			)}
		</table>
	);
}
