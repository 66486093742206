import React from 'react'
import CheckIcon from "../../../assets/icons/check.svg";
import Button from '../../../shared/components/Button.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';

function ApprovedFranchiseModal({ data }) {

    const { closeModal } = useSelectModal();


    return (
        <div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center justify-center">
                <div class=" bg-[#C3FFD1] h-[4rem] w-[4rem] rounded-full flex justify-center items-center ">
                    <img className='h-[2rem] w-[2rem]' src={CheckIcon} />
                </div>
            </div>
            <div className="">
                <h1 className='flex font-inter-medium justify-center pt-4 text-lg text-center'>{data.isFromApproveFranchise ? 'Approved' : 'Rejected'}</h1>
            </div>
            <div className="pb-6 pt-0.5 text-center">
                <span className="text-[#87898E] text-center text-lg"> {data.isFromApproveFranchise ? `${data?.franchiseName}" has been successfully approved` : `${data?.franchiseName}" has been rejected`}</span>
            </div>
            <div className="flex justify-center ">
                <Button
                    className="w-[47.5%] text-[#FFF] cursor-pointer rounded-lg"
                    onClick={() => {closeModal(); data?.fetchFranchise()}}
                >
                Close
            </Button>
        </div>
        </div>
    )
}

export default ApprovedFranchiseModal
