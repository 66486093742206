import React from 'react'
import TableComponent from '../../../shared/components/Table.component'
import { useLazyOrdersQuery } from '../../../utils/Store';
import { useEffect } from 'react';
import Button from '../../../shared/components/Button.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import moment from 'moment/moment'
function CustomerManagementOrders({customerId}) {
   const [getOrders, {data: ordersData, status: ordersDataStatus}] = useLazyOrdersQuery()
   const { openModal } = useSelectModal();

   useEffect(() => {
      getOrders(customerId)
   }, [])
    
    const columns = [
        {
          header: 'Service Name',
          cell: ({ row }) => <div className="text-[16] font-medium">{row.original.serviceName}</div>,
        },
        {
          header: 'Date and Time',
          cell: ({ row }) => <div className="text-[16] font-medium">{`${moment(row?.original?.created).format('l')} ${moment(row?.original?.created).format('LT')}`}</div>,
        },
        {
          header: 'Service Status',
          cell: ({ row }) => <div className="text-[16] font-medium">{row.original.status}</div>,
        },
        {
          header: 'Amount Paid',
          cell: ({ row }) => <div className="text-[16] font-medium">₹ {row.original.amountPaid}</div>,
        },
        {
          header: 'Due Status',
          cell: ({ row }) => {
            if(row.original.dueStatus === "cleared") return <div className="text-[16] font-medium text-[#31BA2E]">Cleared</div>
            if(row.original.dueStatus === "no due") return <div className="text-[16] font-medium">-</div>
            return <button className="text-white px-3 py-1.5 bg-[#FB0C0C] rounded-md" onClick={()=> openModal("clearDueAmountModal", {dueAmount:row.original.dueAmount, customerId:customerId, orderId:row.original?.orderId})}>Clear Due</button>
          },
        },
        {
          header: 'Payment Mode',
          cell: ({ row }) => <div className="text-[16] font-medium">{row.original.paymentType}</div>,
        },
      ];
    return (
        <div className='bg-white border rounded-xl px-5 my-3 h-[45.4vh] overflow-hidden brand-details-section relative'>
            <TableComponent
              data={ordersData}
              columns={columns}
              customwidths={["w-[25%]", "w-[15%]", "w-[15%]", "w-[15%]", "w-[15%]", "w-[15%]"]}
              customeClass='max-h-[37vh] pr-3'
              isLoading={ordersDataStatus === 'pending'}
            />
        </div>
    )
}

export default CustomerManagementOrders