import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {Bars3Icon, XMarkIcon,} from "@heroicons/react/24/outline";
import { GoPerson } from "react-icons/go";
import { AiOutlineFileText } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { TbLogout } from "react-icons/tb";
import {
	Airdrop,
	UserTag,
	Setting5,
	Map,
	SmartCar,
	Setting2,
	Data,
} from "iconsax-react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { MdOutlineAccountTree } from "react-icons/md";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import useSelectModal from "../../utils/hooks/useSelectModal";
import useAuth from "../../utils/hooks/useAuth";
import { Appointment } from "@icon-park/react";
import FranchiseIcon from '../../assets/icons/franchise-icon.svg'
import useRole from "../../utils/hooks/useRole";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}
export default function Sidebar({ open }) {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { openModal } = useSelectModal();
	const { logout } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const { destroyRole } = useRole();

	const userRole = localStorage.getItem('role');

	const handleLogout = () => {
		logout();
		destroyRole();
	};

	const openAddFranchiseModal = () => {
		openModal('CreateNewFranchiseModal');
	};

	const navigation = [
		{
			name: "Rider Management",
			href: "/rider-management",
			icon: GoPerson,
			children: [
				{ name: "Dashboard", href: "/rider-management" },
				{ name: "All Riders", href: "/rider-management/all-riders" },
				{ name: "Shift Master", href: "/rider-management/shift-master" },
			],
		},
		{ name: "Employee Management", href: "/employee-management", icon: AiOutlineFileText },
		{ name: "Appointment Management", href: "/appointment-management", icon: Appointment },
		{
			name: "Franchise Management",
			href: "/franchise-management",
			icon: () => <img src={FranchiseIcon} />,
			children: [
				{ name: "Search Franchise", href: "/franchise-management" },
				{ name: "Add Franchise", click: openAddFranchiseModal }
			]
		},
		{
			name: "Service Management",
			href: "/service-management",
			icon: Setting5,
			children: [
				{ name: "All Services", href: "/service-management" },
				{ name: "Reports", href: "/service-management/reports" },
			],
		},
		{ name: "Area and City Management", href: "/area-city-management", icon: Map },
		{ name: "Car Management", href: "/car-management", icon: SmartCar },
		{ name: "On Demand Job Management", href: "/ondemand-management", icon: MdOutlineAccountTree },
		{
			name: "Master Data",
			href: "/area-master",
			icon: Data,
			children: [
				{ name: "Area Master", href: "/area-master" },
				// { name: "Menu Master", href: "/area-master/menu-master" },
				// { name: "Submenu Master", href: "/area-master/submenu-master" }
			],
		},
		{ name: "Customer Management", href: "/customer-management", icon: Airdrop },
		{ name: "Configuration", href: "/configuration", icon: UserTag },
		// {name: "Role Management", href: "/role-management", icon: UserTag}
	];


	const settingOptions = [
		{ name: "Settings", href: "/settings", icon: Setting2, margin: "10 0px" },
		{ name: "Help", href: "/help", icon: BsQuestionCircle },
		{ name: "Logout Account", href: "/signin", icon: TbLogout, click: handleLogout },
	];

	return (
		<>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-900/80" />
					</Transition.Child>
					<div className="fixed inset-0 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
										<button
											type="button"
											className="-m-2.5 p-2.5"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								{/* Sidebar component, swap this element with another sidebar if you like */}
								<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2 ">
									<div className="flex shrink-0 items-center pt-7">
										{/* <img className="h-8 w-auto" src={blazeLogo} alt="" /> */}
										<span className={`ml-3 text-3xl font-bold `}>FixiGo!</span>
									</div>
									<nav className="flex flex-1 flex-col">
										<ul role="list" className="flex flex-1 flex-col gap-y-7">
											<li>
												<ul role="list" className="-mx-2 space-y-1">
													{navigation.map((item, index) => (
														<NavLink to={item.href} key={index}>
															{({ isActive }) => (
																<li key={index}>
																	<span
																		className={classNames(
																			isActive
																				? "bg-[#DDE9FF] text-black-700"
																				: "text-gray-700 hover:text-black-600 hover:bg-gray-50",
																			"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium align-center"
																		)}
																	>
																		<item.icon
																			className={classNames(
																				isActive
																					? "text-black-700"
																					: "text-gray-400 group-hover:text-black-700 font-medium",
																				"shrink-0 w-5 h-5"
																			)}
																			aria-hidden="true"
																		/>
																		{item.name}
																	</span>
																</li>
															)}
														</NavLink>
													))}
												</ul>
											</li>
										</ul>
									</nav>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
			{/* Static sidebar for desktop */}
			<div className="hidden lg:fixed lg:inset-y-0 lg:w-[15.8vw] lg:flex  lg:flex-col mt-20">
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div
					className={`flex grow flex-col gap-y-5 bg-white px-4  pt-7 ${open ? "w-70" : "w-24"
						} duration-300 relative`}
				>
					<nav className="flex flex-1 flex-col">
						<ul role="list" className="flex flex-1 flex-col gap-y-3 pr-2.5">
							<li className="border-b ">
								<ul role="list" className="space-y-1 -mx-2 overflow-y-auto sidebar-height">
									{navigation.map((item, index) => {
										const isFranchiseOwner = userRole === 'franchiseOwner';
										const isFranchiseManagement = item.name === 'Franchise Management';
										const isDisabled = isFranchiseOwner && !isFranchiseManagement;

										return (
											<NavLink to={item.href} key={index}
												onClick={() => { sessionStorage.removeItem("currentPage") }}
												className={`nav-link ${userRole === 'franchiseOwner' ? 'cursor-default' : ''}  ${isDisabled ? 'disabled opacity-[0.6] cursor-default pointer-events-none' : ''}`}>
												{({ isActive }) => (
													<li>
														{!item.children ? (
															<span
																className={classNames(
																	isActive
																		? "bg-[#E6E6E6] text-[#000000] cursor-default"
																		: `text-[#484848] ${isDisabled ? 'text-gray-400' : 'hover:text-gray-400 hover:bg-[#E6E6E6]'}`,
																	"flex gap-x-3 rounded-md mb-3 p-2 text-base leading-6 font-medium items-center"
																)}
															>
																<item.icon
																	className={classNames(
																		isActive
																			? "text-black-700 "
																			: `group-hover:text-gray-400 font-medium ${isDisabled ? 'text-gray-400' : 'text-[#000000]'}`,
																		"h-5 w-5 shrink-0"
																	)}
																	aria-hidden="true"
																/>
																<span
																	className={`flex-1 futura-pt font-medium ${!open && "hidden"}`}
																>
																	{item.name}
																</span>
															</span>
														) : (
															<>
																<div
																	className={classNames(
																		isActive
																			? "bg-[#E6E6E6] cursor-default text-[#000000]"
																			: `hover:bg-[#E6E6E6] hover:text-gray-400 text-[#484848] mb-3 ${isDisabled ? 'text-gray-400' : ''}`,
																		"flex items-center futura-pt w-full text-left rounded-md p-2 gap-x-3 text-base leading-6 font-medium font-zoom"
																	)}
																>
																	<item.icon
																		className="h-5 w-5 shrink-0 text-[#000000]"
																		aria-hidden="true"
																	/>
																	<span className="futura-pt font-medium">{item.name}</span>
																	<ChevronRightIcon
																		className={classNames(
																			isActive
																				? "-rotate-90 text-gray-500"
																				: "text-gray-400 rotate-90",
																			"ml-auto h-5 w-5 shrink-0"
																		)}
																		aria-hidden="true"
																	/>
																</div>
																<div className={`${!isActive && "hidden"} ml-9 mb-3 flex`}>
																	<div className="border-l-2 border-[#E7E7E7]/80 mb-3"></div>
																	<div className="pt-2 flex-1 mx-2">
																		{item.children.map((subItem) => {
																			const isFranchiseOwner = userRole === 'franchiseOwner';
																			const isFranchiseManagementChild = subItem.name === 'Add Franchise';
																			const isDisabledChild = isFranchiseOwner && (
																				isFranchiseManagementChild || (subItem.children && subItem.children.some(child => child.name === 'Add Franchise'))
																			);

																			return (
																				<li key={subItem.name}>
																					<a
																						href={subItem.href}
																						className={classNames(
																							location.pathname === subItem.href
																								? "bg-active"
																								: "hover:bg-gray-50",
																							"block rounded-md futura-pt py-1 pr-2 pl-4 ml-1 my-0.5 text-sm leading-6",
																							isDisabledChild ? 'disabled opacity-[0.6] cursor-default pointer-events-none' : ''
																						)}
																						onClick={(e) => {
																							e.preventDefault();
																							if (!isDisabledChild) {
																								navigate(subItem.href);
																								subItem.click();
																							}
																						}}
																					>
																						{subItem.name}
																					</a>
																				</li>
																			);
																		})}

																	</div>
																</div>
															</>
														)}
													</li>
												)}
											</NavLink>
										);
									})}

								</ul>
							</li>
							<li>
								<ul role="list" className="space-y-1 -mx-2">
									{settingOptions.map((item, index) => (
										<NavLink to={item.href} key={index}>
											{({ isActive }) => (
												<>
													<li>
														<span
															className={classNames(
																isActive
																	? "bg-[#F5F5F5] text-[#484848] "
																	: `${item.name === "Logout Account"
																		? "text-[#CB0303] hover:bg-red-200"
																		: "text-[#000] hover:text-gray-400 hover:bg-[#E6E6E6]"
																	}`,
																"flex gap-x-3 rounded-md mb-2 p-2 text-base leading-6 font-medium items-center"
															)}
															onClick={item.click}
														>
															<item.icon
																className={classNames(
																	isActive
																		? "text-[#000] "
																		: `${item.name === "Logout Account"
																			? "text-[#CB0303]"
																			: "text-[#000] group-hover:text-gray-400 font-medium"
																		}`,
																	"h-5 w-5 shrink-0"
																)}
																aria-hidden="true"
															/>
															<span className={`flex-1 font-medium futura-pt ${!open && "hidden"}`}>
																{item.name}
															</span>
														</span>
													</li>
												</>
											)}
										</NavLink>
									))}
								</ul>
							</li>
						</ul>
					</nav>
				</div>
			</div>
			<div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
				<button
					type="button"
					className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
					onClick={() => setSidebarOpen(true)}
				>
					<span className="sr-only">Open sidebar</span>
					<Bars3Icon className="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
			<Outlet />
		</>
	);
}
