
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import { useNavigate } from "react-router";
import useToaster from "../../utils/hooks/useToaster";
import {
    useDeleteEmployeeMutation,
    useLazyGetAllEmployeesQuery,
    useLazyGetAreasQuery,
} from "../../utils/Store";
import { InfoCircle, SearchNormal1, Location } from "iconsax-react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import SelectMenu from "../../shared/components/SelectMenu.component";
import Loader from "../../shared/components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineCalendarToday } from 'react-icons/md'
import VectorCalendar from '../../assets/icons/Vector.svg'
import SelectMenuIcon from "../../shared/components/SelectMenuIcon.component";
import { useLazyGetCitesQuery } from "../../utils/Store";
import { useGetAreaListMutation } from "../../utils/Services/Apis/customerManagementApi";
import moment from "moment";
import Avatar from 'react-avatar';
import { useGetCustomerWidgetCountMutation, useGetCustomerListMutation } from "../../utils/Services/Apis/customerManagementApi";
export default function CustomerManagement() {
    const navigate = useNavigate();
    // const [getAllCustomers, { data: customerList, status: customerListStatus }] = useGetCustomerListMutation();
    const [allCustomerData, setAllCustomerData] = useState([]);
    const { setToast } = useToaster();
    const { openModal } = useSelectModal();
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [getCites, { data: cities }] = useLazyGetCitesQuery();
    const [getWidgetCount, { data: widgetsData, status: widgetsDataStatus }] = useGetCustomerWidgetCountMutation();
    const [getAreas, { data: areaDetails }] = useGetAreaListMutation();
    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState({ name: "Current Month", value: "currentMonth" });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [allSelectedCityAreas, setAllSelectedCityAreas ] = useState([]);
    const dateRangeOptions = [
        { name: "Today", value: "today" },
        { name: "Current Month", value: "currentMonth" },
        { name: "Last Month", value: "lastMonth" },
        { name: "Last 90 days", value: "last90Days" },
        { name: "Custom Range", value: "custom" },
    ];
    const columns = [
        {
            header: "Customer Name",
            accessorKey: "name",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center w-full">
                        <Avatar
                            name={original.name}
                            size="40"
                            round={true}
                            color="#F1F1F1"
                            fgColor="#000"
                            textSizeRatio="1.5"
                        />
                        <span className="truncate">{original.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Mobile",
            accessorKey: "phoneNumber",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p>{original.phoneNumber}</p>
                )
            }
        },
        {
            header: "Service Availed",
            accessorKey: "service",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p>{original.totalServicesAvailed}</p>
                )
            }
        },
        {
            header: "Email",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium">
                        {original.email}
                    </span>
                );
            },
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 pl-3">
                        <InfoCircle
                            color="#0d6efd"
                            variant="Bold"
                            onClick={() => navigate(`/customer-management/${original?._id}`)}
                            className="h-5 w-5 cursor-pointer"
                        />
                        <img
                            src={EditIcon}
                            alt="edit"
                            onClick={() => openModal("CustomerEditModal", { ...original, fetchCustomers: getWidgetsCount })}
                            className="h-5 w-5 cursor-pointer"
                        />
                        <img
                            src={DeleteIcon}
                            alt="delete"
                            onClick={() => openModal("CustomerDeleteModal", { ...original, hideCloseArrow: true, fetchCustomers: getWidgetsCount })}
                            className="h-5 w-5 cursor-pointer"
                        />
                    </span>
                );
            },
        },
    ];
    useEffect(() => {
        getCites();
    }, []);

    useEffect(() => {
        handleSetDate();
    }, [selectedOptions]);

    useEffect(() => {
        const id = setTimeout(() => {
            getWidgetsCount()
        }, 350)
        return () => {
            clearTimeout(id)
        }
    }, [startDate, endDate, selectedCity, selectedArea, inputValue, pageNo]);

    useEffect(() => {
        if (widgetsData?.customersList) setAllCustomerData(widgetsData.customersList);
    }, [widgetsData?.customersList]);

    const handleDelete = async (employeeData) => {
        openModal("DeleteEmployeeModal", { ...employeeData, hideCloseArrow: true });
    };

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handlePageChange = (type) => {
        let tempCurrentPage = pageNo;
        if (type === 'prev') {
            tempCurrentPage -= 1;
        } else {
            tempCurrentPage += 1;
        }
        setPageNo(tempCurrentPage);
    };

    const dateDisabled = (date) => {
        return startDate ? moment(date).isBetween(startDate, endDate, null, '[]') : true || moment(date).isAfter(endDate);
    };

    const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
        <div
            className={`flex gap-1 border rounded-md text-sm h-[2.3rem] p-2 bg-white ${!value && "text-[#000]"}`}
            onClick={onClick}
            ref={ref}
        >
            <MdOutlineCalendarToday className="h-5 w-5" />
            {value || placeholder}
        </div>
    ));

    const getWidgetsCount = () => {
        let bodyObj = {
            dateRange: selectedOptions?.value,
            cityId: [],
            areaId: []
        }
        if (selectedCity?.length) {
            bodyObj.cityId = selectedCity.map((city) => city._id)
        }
        if (selectedArea?.length) {
            bodyObj.areaId = selectedArea.map((area) => area._id)
        }
        if (inputValue) {
            bodyObj.search = inputValue
        }
        if (selectedOptions?.value == 'custom') {
            bodyObj.from = moment(startDate).format('YYYY-MM-DD');
            bodyObj.to = moment(endDate).format('YYYY-MM-DD');
        }
        getWidgetCount({ body: bodyObj, params: { page: pageNo, pageSize: pageSize } });
    };

    const handleSetDate = () => {
        switch (selectedOptions.value) {
            case 'today':
                setStartDate(moment().toDate())
                break;
            case 'currentMonth':
                setStartDate(moment().startOf('month').toDate())
                setEndDate(moment().toDate())
                break;
            case 'lastMonth':
                setStartDate(moment().subtract(1, 'months').startOf('month').toDate())
                setEndDate(moment().subtract(1, 'months').endOf('month').toDate())
                break;
            case 'last90Days':
                setStartDate(moment().subtract(90, 'days').startOf('day').toDate())
                setEndDate(moment().toDate())
                break;
            case 'custom':
                setStartDate(moment().toDate())
                setEndDate(moment().toDate())
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (selectedCity?.length) {
            handleGetCityAreas()
        } else {
            setAllSelectedCityAreas([]);
            setSelectedArea(null);
        }
    }, [selectedCity]);

    const handleGetCityAreas = async() => {
        let { data } = await getAreas({
            cityId: selectedCity.map((city, index) => city._id)
        });
        setAllSelectedCityAreas(data);
    }

    const handleCityChange = (option) => {
        setSelectedCity(option);
        setSelectedArea(null);
    };
    const handleAreaChange = (option) => {
        setSelectedArea(option);
    };


    const handleChange = (e) => {
        const value = e.target.value;
        const sanitizedValue = value.replace(/[^\w\s]/gi, '');
        setInputValue(sanitizedValue);
    };

    return (
        <div className="bg-[#F5F5F5] h-full">
            <div className="">
                <div className="px-8 pt-8">
                    <div className="flex justify-between">
                        <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Customer Management</h3>
                        <Button
                            className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
                            onClick={() => navigate("/add-new-service")}
                        >
                            Add New Appointment
                        </Button>
                    </div>
                </div>
                <div className="flex items-center justify-between bg-[#EDEDED] p-4 mx-8 my-6 rounded-xl shadow border border-[#A8A8A8] card-height">
                    <div className="h-full card-width">
                        {
                            widgetsDataStatus === 'pending' ? <span className="skeleton block h-full p-5 skeleton-height-widgets"></span> : (
                                <div className="flex items-center bg-[#fff] h-full 2xl:p-5 p-3 rounded-xl">
                                    <div className="w-[50%] p-2">
                                        <h1 className="font-inter-bold text-base text-[#000]">Total Active Customers</h1>
                                    </div>
                                    <div className="w-[50%] text-center p-2">
                                        <h1 className="font-inter-bold 2xl:text-4xl text-3xl text-[#000]">{widgetsData?.totalActiveCount}</h1>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="h-full card-width">
                        {
                            widgetsDataStatus === 'pending' ? <span className="skeleton block h-full p-5 skeleton-height-widgets"></span> : (
                                <div className="flex items-center bg-[#fff] h-full 2xl:p-5 p-3 rounded-xl">
                                    <div className="w-[50%] p-2">
                                        <h1 className="font-inter-bold text-base text-[#000]">Total New Customers</h1>
                                    </div>
                                    <div className="w-[50%] text-center p-2">
                                        <h1 className="font-inter-bold 2xl:text-4xl text-3xl text-[#000]">{widgetsData?.newCustomerCount}</h1>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="h-full card-width">
                        {
                            widgetsDataStatus === 'pending' ? <span className="skeleton block h-full p-5 skeleton-height-widgets"></span> : (
                                <div className="flex items-center bg-[#fff] h-full 2xl:p-5 p-3 rounded-xl">
                                    <div className="w-[50%] p-2.5">
                                        <h1 className="font-inter-bold text-base text-[#000]">Total Vehicles</h1>
                                    </div>
                                    <div className="w-[50%] text-center p-2.5">
                                        <h1 className="font-inter-bold 2xl:text-4xl text-3xl text-[#000]">{widgetsData?.totalVehiclesCount}</h1>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="h-full rounded-xl card-width">
                        <div className="mb-4 ">
                            <SelectMenu
                                options={dateRangeOptions}
                                multiSelectClass="radius-unit-ht"
                                onChange={handleSelectChange}
                                showCheckbox={false}
                                value={selectedOptions}
                            />
                        </div>
                        <div className="w-full flex items-center">
                            <div className="calendar-icon-size w-[90%]">
                                <DatePicker className="rounded-lg border-none outline-none date-picker-text-height"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    disabled={selectedOptions.value !== "custom" ? true : null}
                                    maxDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
                                    customInput={<CustomInput />}
                                />
                            </div>
                            {selectedOptions.value !== "today" &&
                                <>
                                    <div className="">
                                        <h1 className="font-plus-jakarta 2xl:px-3 xl:px-1 px-3 text-sm text-center">To</h1>
                                    </div>
                                    <div className="calendar-icon-size w-[90%]">
                                        <DatePicker className="rounded-lg border-none outline-none date-picker-text-height"
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            disabled={selectedOptions.value !== "custom" ? true : null}
                                            customInput={<CustomInput />}
                                            maxDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
                                            minDate={moment(startDate).toDate()}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="bg-white p-5 mx-8 pb-4 h-[60vh] overflow-hidden brand-details-section bg-white-height  customer-table-height  relative">
                    <div className="flex items-center justify-between">
                        <div className="relative 2xl:h-[3.2rem] h-[2.75rem] search-customer-height">
                            <input
                                type="text"
                                className="2xl:w-[32rem] xl:w-[24rem] w-[32rem] outline-focus h-full border-[#E9E9E9] rounded-xl py-1.5 pl-12 pr-3 text-base font-regular bg-[#F1F1F1] placeholder-black focus:outline-none focus:border-[#E9E9E9] focus:ring-transparent"
                                placeholder="Search Customer Name / Mobile Number"
                                value={inputValue}
                                onChange={handleChange}
                            />
                            <div className="absolute left-4 top-[50%] translate-y-[-50%]">
                                <SearchNormal1 color="gray" size={20} className="search-icon" />
                            </div>
                        </div>
                        <div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
                            <div className="">
                                <h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
                            </div>
                            <div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
                                <SelectMenuIcon
                                    placeholder='All Cities'
                                    options={cities}
                                    multiSelectClass="search-customer-height multi-time-slot"
                                    isMulti={true}
                                    onChange={handleCityChange}
                                    value={selectedCity}
                                />
                                <Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
                            </div>
                        </div>
                        <div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
                            <div className="">
                                <h1 className="text-base text-[#333333] font-normal">Filter by Area:</h1>
                            </div>
                            <div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
                                <SelectMenuIcon
                                    placeholder='All Areas'
                                    options={allSelectedCityAreas}
                                    multiSelectClass="search-customer-height multi-time-slot"
                                    isMulti={true}
                                    onChange={handleAreaChange}
                                    value={selectedArea}
                                />
                                <Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full mb-10">
                        <TableComponent
                            data={allCustomerData}
                            columns={columns}
                            customwidths={["w-[22.5%]", "w-[17.5%]", "w-[22.5%]", "w-[27.5%]", "w-[10%]"]}
                            isLoading={widgetsDataStatus === "pending"}
                            customeClass="customer-management-table-ht pr-3"
                        />
                    </div>
                    {widgetsData?.totalPages > 1 &&
                        <div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
                            <div className=''>
                                <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`}
                                    disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
                            </div>
                            <div className=''>
                                <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == widgetsData?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`}
                                    disabled={pageNo == widgetsData?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
                            </div>
                            <div className=''>
                                <p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {widgetsData?.totalPages}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
