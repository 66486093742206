import React, { useEffect } from 'react'
import HomeRefresh1 from "../../../assets/icons/home-refresh-1.png";
import { CloseCircle } from 'iconsax-react';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import { useLazyGetFranchiseDetailQuery } from '../../../utils/Store';
import moment from 'moment';
import { RxCross2 } from "react-icons/rx";

function RenewalDetailsModal({data}) {
    const [getFranchiseDetails, { data: franchiseDetailsData }] = useLazyGetFranchiseDetailQuery();

    useEffect(()=>{
        if(data.id) getFranchiseDetails(data.id)
    },[data])
    
    const { openModal } = useSelectModal();
    if(!franchiseDetailsData?.length) return
    const franchiseData = franchiseDetailsData[0]
    return (
        <div className='w-[52rem] rounded-xl modal-height-service max-h-[80vh] overflow-hidden min-h-[45vh]'>
            <div className="px-10">
                <div className="flex items-center justify-between pt-5">
                    <div className="h-12 w-12 bg-[#FF621F] rounded-full flex justify-center items-center modal-logo-size">
                        <img src={HomeRefresh1} className="h-7 w-7" alt="" />
                    </div>
                    <div className=''>
                        <CloseCircle
                            onClick={() => openModal("RenewedFranchiseModal", { hideCloseArrow: true, name: franchiseData.name, renewalDate: franchiseData.endDate})}
                        />
                    </div>
                </div>
                <div className="mb-1 mt-6">
                    <h1 className="text-xl font-inter-bold">Renewal Details</h1>
                </div>
                <div className="mb-8">
                    <h1 className="text-base font-normal text-[#475467]">
                        Renewed franchise details for
                    </h1>
                </div>
                <div className="w-full">
                    <div className="flex mb-8">
                        <div className="text-lg font-normal text-[#474747]">
                            Start Date : {moment(franchiseData.startDate).format("DD MMMM, YYYY")}
                        </div>
                        <div className="text-lg font-inter-medium">
                            {/* {employeeData.name} */}
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <h1 className="text-lg font-normal text-[#474747]">
                            End Date : {moment(franchiseData.endDate).format("DD MMMM, YYYY")}
                        </h1>
                        <h1 className="text-lg font-inter-medium">
                            {/* {employeeData.cityName} */}
                        </h1>
                    </div>
                    <div className="flex mb-4 justify-start items-baseline  gap-3">
                        <h1 className="text-lg font-normal text-[#474747]">
                            MOU : 
                        </h1>
                        <div className="flex gap-2 overflow-x-auto final-price-scrollbar mb-4">
                            {data.mou?.map((doc, index) => (
                                <div
                                    className="flex justify-between bg-white rounded-md border items-center px-3 py-2 relative"
                                    key={index}
                                >
                                    <div className="flex-1 flex items-center gap-3">
                                        <div className="font-inter-regular text-sm text-gray-500">
                                            {doc?.name}
                                        </div>
                                        <RxCross2
                                            alt=""
                                            className="h-4 w-4 text-gray-400"
                                        />
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <h1 className="text-lg font-normal text-[#474747]">
                            Commission Model : {franchiseData.commissionModelName === "revenue" ? "Revenue" : "Per User"}
                        </h1>
                        <h1 className="text-lg font-inter-medium">
                            {/* {employeeData.email} */}
                        </h1>
                    </div>
                    <div className="flex mb-8">
                        <h1 className="text-lg font-normal text-[#474747]">
                            Credit Limit : {franchiseData.creditLimit}
                        </h1>
                        <h1 className="text-lg font-inter-medium">
                            {/* {employeeData.email} */}
                        </h1>
                    </div>
                    <div className="flex mb-8">
                        <h1 className="text-lg font-normal text-[#474747]">
                            Payment Due Date : {franchiseData.paymentDueDate} / Every Month
                        </h1>
                        <h1 className="text-lg font-inter-medium">
                            {/* {employeeData.email} */}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenewalDetailsModal
