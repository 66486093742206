import React from 'react'
import {
    Calendar1,
    Car,
    Clock,
    GasStation,
    Keyboard,
    Lifebuoy,
    Location,
    User,
    Message,
    CallCalling,
    Calendar,
} from "iconsax-react";
import DownloadIcon from '../../../assets/images/cars/download_cars.png';
import Button from '../../../shared/components/Button.component';
import PaymentMode from "../../../assets/icons/payment-mode.svg";
import moment from 'moment';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import { data, formatTime, secondsToTime, timeDifference, timeToSeconds } from "../../../utils/helper/progressBarCalculations";

function VehicleServiceCard({ type, status, isVehicleServiceModal, selectedValue, vehicleDetailsData, serviceData }) {
    const { openModal } = useSelectModal();

    let addonServices = [];

    function formatNumber(num) {
		if (num < 10) {
		  return '0' + num;
		}
		return '' + num;
	}

    const DisplayStatus = () => {
        if(serviceData?.dueStatus === "cleared") return <div className="text-[16] font-medium text-[#31BA2E] mr-4 border rounded-md px-6 py-1.5">Cleared</div>
        if(serviceData?.dueStatus === "no due") return <></>
        return <div className='mr-4'>
                <Button
                    className="2xl:text-base text-sm text-white cursor-pointer 2xl:px-4 px-4 py-2 w-[8vw] font-inter-regular rounded-xl"
                    customeHeight="h-10"
                    // type="submit"
                    onClick={() => {
                        openModal("clearDueAmountModal", {dueAmount:serviceData?.dueAmount, orderId:serviceData?.orderId, customerId:serviceData?.customerId })
                    }}
                >
                    Clear Due
                </Button>
            </div>
    }

    if(status === 'completed'){
        addonServices = vehicleDetailsData?.service?.filter((service) => service.addOnServiceVerification)
    }

    return (
        <div>
            <div
                className={`w-full h-2/4 pb-1 mb-3 bg-white 
                ${isVehicleServiceModal ? "border-l-0" : selectedValue?.name === "All Vehicles" ? "border-l-0 rounded-none mb-0" : "border-l-8 shadow rounded-xl"}
                ${status === "requested" ? "border-[#FF621F]" : status === "confirmed" ? "border-[#00BE68]" : status === "completed" ? "border-[#315EFF]" : status === "canceled" ? "border-[#FB0C0C]" : ""}`}
            >

                <div className="bg-white rounded-t-lg drop-shadow-md px-5 py-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center py-1.5 px-3 mt-2 bg-[#F1F1F1] rounded-lg w-fit space-x-2">
                            <Car className="w-5 h-5 2xl:w-7 2xl:h-7" />
                            <h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
                                {isVehicleServiceModal ? serviceData?.serviceName : vehicleDetailsData?.service[0]?.serviceName}
                            </h1>
                        </div>
                        <div className="flex justify-evenly items-center">
                            <div className="flex items-center">
                                <Clock size={25} className="mx-3 w-5 h-5 2xl:w-7 2xl:h-7" />
                                <h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">{vehicleDetailsData?.timeslotDetails?.split("-")[0]}</h1>
                            </div>
                            <div className="flex items-center ml-6">
                                <Calendar size={25} className="mx-4 w-5 h-5 2xl:w-7 2xl:h-7" />
                                <h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">
                                    {moment(vehicleDetailsData?.serviceDate).format("DD MMMM YYYY")}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between pt-5 ">
                        <div className="">
                            <Button className="bg-[#D9D9D9] rounded-lg text-base 2xl:text-xl font-inter-medium text-[#000000] cursor-pointer border border-[#A8A8A8]">
                                One Time Service
                            </Button>
                        </div>
                        <div className='flex items-center'>
                            {
                                isVehicleServiceModal && DisplayStatus()                                
                            }
                            {
                                (status === 'completed' || isVehicleServiceModal) &&
                                <div>
                                    <Button className="bg-black text-white cursor-not-allowed">
                                        Download Invoice
                                        <img className='ml-3 h-6 w-6' src={DownloadIcon} />
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='px-5 py-4'>
                    <div className='flex'>
                        <div className='w-[30%]'>
                            <div className='flex'>
                                <p className='font-inter-medium text-black text-base'>Rider Name</p>
                                <div className='flex ml-8'>
                                    <User size={20} color="#666666" className="icon-size-14px" />
                                    <p className='font-inter-regular text-[#474747] text-base ml-3'>{vehicleDetailsData?.riderName}</p>
                                </div>
                            </div>
                        </div>
                        <div className='w-[70%] flex'>
                            <p className='font-inter-medium text-black text-base'>Description</p>
                            <div className='ml-8'>
                                <p className='font-inter-regular text-[#474747] text-base'>{vehicleDetailsData?.serviceDescription}</p>
                                {/* <ul className='font-inter-regular text-[#474747] text-base pl-6 list-disc'>
                                    <li>Breaks and Tyres Cleaning</li>
                                    <li>Detailed Polishing</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>

                    <div className='w-full h-[1px] bg-[#DEDEDE] my-4'></div>

                    {((status === "completed") || (isVehicleServiceModal)) && addonServices.length > 0 && (
                        <>
                            <div className="">
                                <div className="">
                                    <p className="text-black font-inter-medium text-sm 2xl:text-base">Add On Services</p>
                                </div>
                                <div className="items-center overflow-x-auto final-price-scrollbar">

									<div className="flex space-x-3 w-max">
										{addonServices.map((addOnService, i) => {
											const serviceDurationInSeconds = timeToSeconds(addOnService?.serviceTime[0]);
											const timeDiff = addOnService?.startTime ? timeDifference(addOnService?.startTime, addOnService?.endTime) : { hours: 0, minutes: 0, seconds: 0 };
											const extraTimeInSeconds = addOnService?.startTime ? timeToSeconds(timeDiff) - serviceDurationInSeconds : 0;
											const extraTime = secondsToTime(extraTimeInSeconds);
											return (
												<div className="flex items-center justify-center space-x-2 border rounded-lg py-1 px-3 my-2" key={i}>
													<img src={addOnService?.imageUrl} className="h-5 w-5" />
													<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">{addOnService?.serviceName}</p>
													<Clock size={18} color="gray" className="icon-size-14px" />
													<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
														{addOnService?.serviceTime[0]?.hours}:
														{formatNumber(addOnService?.serviceTime[0]?.minutes)} Hours
														<span className="text-[#CB0303] font-inter-bold text-sm 2xl:text-base">
															{extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}
														</span>
													</p>
													<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
														<span className="text-sm 2xl:text-base">₹</span>
														{addOnService?.addOnServiceAmount}
													</p>
												</div>
											)
										})}
									</div>

								</div>
                            </div>
                            <div className='w-full h-[1px] bg-[#DEDEDE] my-4'></div>
                        </>
                    )}

                    <div className="flex justify-between pr-10">
                        <div className="flex items-center space-x-14">
                            <div className="">
                                <p className="text-black font-inter-medium text-sm 2xl:text-base">Total Service Time</p>
                            </div>
                            <div className="flex items-center justify-center space-x-3 ml-2 border rounded-lg p-1 px-3">
                                <Clock size={18} color="gray" className="icon-size-14px" />
                                <p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
                                    {vehicleDetailsData?.service[0]?.serviceTime[0].hours}:
									{formatNumber(vehicleDetailsData?.service[0]?.serviceTime[0].minutes)}  Hours
                                </p>
                            </div>
                        </div>
                        {(isVehicleServiceModal) &&
                            (
                                <div className="flex items-center 2xl:space-x-6 space-x-2">
                                    <div className="flex mr-2">
                                        <img src={PaymentMode} color="white" className="h-9 w-9" />

                                        <h1 className="font-inter-medium pl-2 flex items-center justify-center text-base 2xl:text-base lg:text-sm">
                                            Payment Mode :
                                        </h1>
                                    </div>
                                    <div className="">
                                        <p className="font-inter-medium text-gray-700 text-md border lg:text-sm 2xl:text-base rounded-lg p-1 px-5">
                                            {/* {orderDetails?.paymentType == "cash" ? "Cash" : "Online"} */}
                                            Cash
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                        {(isVehicleServiceModal) &&
                            (
                                <div className="flex items-center space-x-14">
                                    <div className="">
                                        <p className="text-black font-inter-medium text-sm 2xl:text-base">Due Amount</p>
                                    </div>
                                    <div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
                                        <p className="text-gray text-sm 2xl:text-base">₹</p>
                                        <p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
                                            {/* {vehicleDetailsData?.payableAmount} */}
                                            {serviceData?.dueAmount}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                        <div className="flex items-center space-x-14">
                            <div className="">
                                <p className="text-black font-inter-medium text-sm 2xl:text-base">Total Price</p>
                            </div>
                            <div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
                                <p className="text-gray text-sm 2xl:text-base">₹</p>
                                <p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
                                    {vehicleDetailsData?.payableAmount}
                                </p>
                            </div>
                        </div>

                    </div>

                    {(!isVehicleServiceModal) &&
                        (
                            <>
                                <div className='w-full h-[1px] bg-[#DEDEDE] my-4'></div>
                                <div className="flex items-center 2xl:space-x-6 space-x-2">
                                    <div className="flex mr-2">
                                        <img src={PaymentMode} color="white" className="h-9 w-9" />

                                        <h1 className="font-inter-medium pl-2 flex items-center justify-center text-base 2xl:text-base lg:text-sm">
                                            Payment Mode :
                                        </h1>
                                    </div>
                                    <div className="">
                                        <p className="font-inter-medium text-gray-700 text-md border lg:text-sm 2xl:text-base rounded-lg p-1 px-5">
                                            {vehicleDetailsData?.paymentType == "cash" ? "Cash" : "Online"}
                                            {/* {vehicleDetailsData?.paymentType} */}
                                        </p>
                                    </div>
                                </div>
                            </>
                        )
                    }

                    {(status === "canceled") && (
                        <div>
                            <div className='w-full h-[1px] bg-[#DEDEDE] my-4'></div>
                            <div className="">
                                <div className=" flex space-x-7 pr-5 items-center">

                                    <p className="text-gray-900 font-semibold text-base">Cancelled by </p>
                                    <div className="">
                                        <Button className="bg-[#FFFFFF] flex space-x-2 font-normal w-32 cursor-pointer">
                                            <User size={20} color="#666666" className="icon-size-14px" />
                                            <span className="text-[#474747]">{vehicleDetailsData?.canceledBy?.name}</span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className=" flex space-x-1 pr-5 items-center w-1/2">
                                        <p className="text-gray-900 font-semibold text-base">Reason :</p>
                                        <div className="">
                                            <p className="font-inter-medium text-[#474747]">{vehicleDetailsData?.canceledBy?.reason}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center px-11 w-1/2">
                                        <p className="font-inter-medium text-[#474747] pt-6 "><span className="text-gray-900 font-semibold text-base pr-1">Note :</span>{vehicleDetailsData?.canceledBy?.note}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

export default VehicleServiceCard