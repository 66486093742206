import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const customerManagementApi = apiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            getCustomerList: builder.mutation({
                query: ({ pageNo, pageSize }) => {
                    return {
                        url: endpoints.customerManagement.getCustomerList(pageNo, pageSize),
                        method: "POST",
                    }
                },
                providesTags: ["CustomerManagement"],
            }),
            getCustomerWidgetCount: builder.mutation({
                query: ({ body, params }) => ({
                    url: endpoints.customerManagement.widgetsCount,
                    params: params,
                    body: body,
                    method: "POST",
                }),
            }),
            getAreaList: builder.mutation({
                query: (body) => ({
                    url: endpoints.customerManagement.getAreaList,
                    body: body,
                    method: "POST"
                })
            }),
            addAddress: builder.mutation({
                query: ({ vehicleId, body }) => ({
                    url: endpoints.customerManagement.addAddress(vehicleId),
                    body: body,
                    method: "POST",
                }),
            }),
            deleteCustomer: builder.mutation({
                query: (customerId) => ({
                    url: endpoints.customerManagement.deleteCustomer(customerId),
                    method: "DELETE",
                }),
                invalidatesTags: ["CustomerManagement"],
            }),
            updateCustomer: builder.mutation({
                query: ({ customerId, body }) => ({
                    url: endpoints.customerManagement.updateCustomer(customerId),
                    method: "PUT",
                    body: body,
                }),
                invalidatesTags: ["CustomerManagement", "customer"],
            }),
            getCustomerWidgets: builder.query({
                query: (customerId) => ({
                    url: endpoints.customerManagement.getCustomerWidgets(customerId),
                    method: "GET",
                }),
                providesTags: ["CustomerWidgets"],
            }),
            getSingleCustomer: builder.query({
                query: (customerId) => ({
                    url: endpoints.customerManagement.getSingleCustomer(customerId),
                    method: "GET",
                }),
                providesTags: ["customer"],
            }),
            getCustomerVehicles: builder.query({
                query: ({ customerId }) => {
                    return {
                        url: endpoints.customerManagement.getCustomerVehicles(customerId),
                        method: "GET",
                    };
                },
            }),
            getCustomerAddress: builder.query({
                query: ({ customerId }) => {
                    return {
                        url: endpoints.customerManagement.getCustomerAddress(customerId),
                        method: "GET",
                    };
                },
            }),
            deleteAddress: builder.mutation({
                query: (addressId) => {
                    return {
                        url: endpoints.customerManagement.deleteAddress(addressId),
                        method: "DELETE",
                    }
                },
                invalidatesTags: ["CustomerManagement"],
            }),
            updateVehicleAddress: builder.mutation({
                query: ({ addressId, body }) => ({
                    url: endpoints.customerManagement.updateVehicleAddress(addressId),
                    method: "PUT",
                    body: body,
                }),
            }),
            getVehicleAddress: builder.query({
                query: (vehicleId) => {
                    return {
                        url: endpoints.customerManagement.getVehicleAddress(vehicleId),
                        method: "GET",
                    };
                },
            }),
            getVehicleDetailsByStatus: builder.mutation({
                query: ({ status, body }) => {
                    return {
                        url: endpoints.customerManagement.getVehicleDetailsByStatus(status),
                        body: body,
                        method: "POST"
                    }
                }
            }),
            updateVehicleDetails: builder.mutation({
                query: ({ vehicleId, body }) => ({
                    url: endpoints.customerManagement.updateVehicleDetails(vehicleId),
                    method: "PUT",
                    body: body,
                }),
            }),
            getVehicleDetails: builder.query({
				query: (vehicleId) => {
					return {
						url: endpoints.customerManagement.getVehicleDetails(vehicleId),
						method: "GET",
					};
				},
			}),
            getVehicleServiceCount: builder.mutation({
                query: (body) => ({
                    url: endpoints.customerManagement.getVehicleServiceCount,
                    body: body,
                    method: "POST"
                })
            }),
            updateVehicle: builder.mutation({
                query: ({ vehicleId, body }) => ({
                    url: endpoints.customerManagement.updateVehicle(vehicleId),
                    method: "PUT",
                    body: body,
                }),
            }),
            getNotifications: builder.query({
                query: ({customerId}) => {
                    return {
                        url: endpoints.customerManagement.notifications(customerId),
                        method: "GET",
                    };
                },
                transformResponse: response => {
                    return response.reverse();
              },
            }),
            orders: builder.query({
                query: ( customerId ) => {
                    return {
                        url: endpoints.customerManagement.orders(customerId),
                        method: "GET",
                    };
                },
            }),
            paymentLink: builder.mutation({
                query: (body) => ({
                    url: endpoints.customerManagement.paymentLink,
                    body: body,
                    method: "POST"
                })
            }),
            getDues: builder.query({
                query: ( customerId ) => {
                    return {
                        url: endpoints.customerManagement.getDues(customerId),
                        method: "GET",
                    };
                },
            }),
        };
    },
});

export const {
    useGetCustomerListMutation,
    useGetCustomerWidgetCountMutation,
    useGetAreaListMutation,
    useDeleteCustomerMutation,
    useUpdateCustomerMutation,
    useLazyGetCustomerVehiclesQuery,
    useLazyGetCustomerWidgetsQuery,
    useLazyGetSingleCustomerQuery,
    useAddAddressMutation,
    useLazyGetCustomerAddressQuery,
    useDeleteAddressMutation,
    useUpdateVehicleAddressMutation,
    useGetVehicleDetailsByStatusMutation,
    // useAddVehicleAddressMutation,
    useLazyGetVehicleAddressQuery,
    useUpdateVehicleDetailsMutation,
    useLazyGetVehicleDetailsQuery,
    useGetVehicleServiceCountMutation,
    useUpdateVehicleMutation,
    useLazyGetNotificationsQuery,
    useLazyOrdersQuery,
    usePaymentLinkMutation,
    useLazyGetDuesQuery
} = customerManagementApi;

export { customerManagementApi };