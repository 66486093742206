import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const franchiseApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAllMasterFranchise: builder.query({
				query: () => ({
					url: endpoints.franchiseManagement.getAllMasterFranchise,
					method: "GET",
				}),
				providesTags: ["ShiftCategory"],
			}),
			getAllFranchise: builder.mutation({
				query: ({ body, params }) => ({
					url: endpoints.franchiseManagement.getAllFranchise,
					params: params,
					body: body,
					method: "POST",
				}),
				providesTags: ["FranchiseManagement"],
			}),
			deleteFranchise: builder.mutation({
				query: (id) => ({
					url: endpoints.franchiseManagement.deleteFranchise(id),
					method: "DELETE",
				}),
				invalidatesTags: ["FranchiseManagement"],
			}),
			updateFranchise: builder.mutation({
				query: ({ id, data }) => ({
					url: endpoints.franchiseManagement.updateFranchise(id),
					method: "PUT",
					body: data,
				}),
				invalidatesTags: ["FranchiseManagement"],
			}),
			getFranchiseDetail: builder.query({
				query: (id) => ({
					url: endpoints.franchiseManagement.getFranchiseDetail(id),
					method: "GET",
				}),
				providesTags: ["FranchiseManagement"]
			}),
			getFranchiseByStatus: builder.query({
				query: ({ status }) => ({
					url: endpoints.franchiseManagement.getFranchiseByStatus(status),
					method: "GET"
				}),
				providesTags: ["FranchiseManagement"],
			}),
			addFranchise: builder.mutation({
				query: (body) => ({
					url: endpoints.franchiseManagement.addFranchise,
					method: "POST",
					body: body,
				}),
				providesTags: ["ShiftCategory"],
			}),
			renewFranchise: builder.mutation({
				query: ({ id, data }) => ({
					url: endpoints.franchiseManagement.renewFranchise(id),
					method: "PUT",
					body: data,
				}),
				invalidatesTags: ["FranchiseManagement"],
			}),
			getChildFranchiseById: builder.query({
				query: (parentId) => ({
					url: endpoints.franchiseManagement.getChildFranchiseById(parentId),
					method: "GET",
				}),
				providesTags: ["FranchiseManagement"],
			}),
			updateFranchiseStatus: builder.mutation({
				query: ({ id, body }) => ({
					url: endpoints.franchiseManagement.updateFranchiseStatus(id),
					method: "PUT",
					body: body,
				}),
				invalidatesTags: ["FranchiseManagement"],
			}),
		};
	},
});

export const {
	useLazyGetAllMasterFranchiseQuery,
	useGetAllFranchiseMutation,
	useDeleteFranchiseMutation,
	useUpdateFranchiseMutation,
	useLazyGetFranchiseDetailQuery,
	useLazyGetFranchiseByStatusQuery,
	useAddFranchiseMutation,
	useRenewFranchiseMutation,
	useLazyGetChildFranchiseByIdQuery,
	useUpdateFranchiseStatusMutation,
} = franchiseApi;
export { franchiseApi };
