import React, { useState } from "react";
import Tabs from "../../shared/components/Tabs.component";

import ShiftCategory from "./Shift_Category";
import TimeslotCreation from "./Time_Slot_Creation";

export default function ShiftMaster() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    let categories = [{
        title: `Shift Category`,
        status: "shift-category",
        component: <ShiftCategory/>,
    },
    {
        title: `Time Slot Creation`,
        status: "time-slot-creation",
        component: <TimeslotCreation/>
    }]
    return <>
        <div className="relative bg-gray-100 h-full">
            <div className="w-full sm:px-0">
                <div className="px-8 pb-5 pt-8">
                    <div className="flex">
                        <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                           Shift Management Master
                        </h3>
                    </div>
                </div>

                <div className="flex justify-between pl-8 mb-4 pr-3">
                    <Tabs
                        categories={categories}
                        className="2xl:w-[19vw] lg:w-[18vw] tab-width-zoom"
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                    />
                </div>

                <div className="max-h-[60vh]">
					{categories[selectedIndex].component}
				</div>

            </div>
        </div>
    </>;
}
