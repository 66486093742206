import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const carApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAllFuelTypes: builder.query({
				query: () => ({
					url: endpoints.carMaster.getAllFuelType,
					method: "GET",
				}),
			}),
			getAllTransmissionTypes: builder.query({
				query: () => ({
					url: endpoints.carMaster.getAllTransmissionType,
					method: "GET",
				}),
			}),
			getAllCarTypes: builder.query({
				query: () => ({
					url: endpoints.carMaster.getAllCarType,
					method: "GET",
				}),
			}),
		};
	},
});

export const {
    useLazyGetAllTransmissionTypesQuery,
    useLazyGetAllFuelTypesQuery,
	useLazyGetAllCarTypesQuery
} = carApi;
export { carApi };
