import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import onJobIcon from "../../assets/icons/rider-status/on-job.svg"
import onlineIcon from "../../assets/icons/rider-status/online.svg"
import offlineIcon from "../../assets/icons/rider-status/offline.svg"
import outOfRadiusIcon from "../../assets/icons/rider-status/out-of-radius.svg"
import radiusIcon from "../../assets/icons/rider-status/Line 269.svg"
import mapZoomIcon from "../../assets/icons/bx_collapse.svg"
import Tabs from "../../shared/components/Tabs.component";
import { data as areaMasterHelper } from '../../utils/helper/areaMaster';
import MapInfoCard from "./Map_Info_Card";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useGetRiderMapStatusMutation } from "../../utils/Store";
import io from 'socket.io-client';


const containerStyle = {
    width: "100%",
    height: "400px",
};

const MapRiderStatus = ({areaList, selectedCity}) => {
    
    const [center, setCenter] = useState({ lat: 21.556483, lng: 78.666478 });
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const { openModal, isModalOpen } = useSelectModal();

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };
    const handleModalOpen = () => {
        openModal("MapModalAllRiders", {areaList, markers, selectedCity, selectedIndex, setSelectedIndex});
    };

    const statuses = [
        { icon: onJobIcon, text: 'On Job' , status: "onJob" },
        { icon: onlineIcon, text: 'Online', status: "online" },
        { icon: offlineIcon, text: 'Offline', status: "offline" },
        { icon: outOfRadiusIcon, text: 'Out Of Radius', status: "outOfRadius" },
        { icon: radiusIcon, text: 'Radius' }, 
    ];

    const [getRiders] = useGetRiderMapStatusMutation()
    const [markers, setMarkers] = useState([])
    const areaIds= areaList?.map(area=> area._id)    
    const fetchRiders = async() => {
        if(!areaIds?.length) return
        const {data: d ,error} = await getRiders({"riderStatus": categories[selectedIndex].status , "areaId" : areaIds})
        if(d){
            const markers = d.riders?.filter(rider=>rider.location?.coordinates?.length === 2).map(rider => ({
                    riderId: rider._id , lat: rider.location?.coordinates[1], lng: rider.location?.coordinates[0], icon: statuses.find(e => e.status === rider.riderStatus)?.icon, status: rider.riderStatus
                }))
            setMarkers(markers || [])
        } 
        if(error) {}
    }
    useEffect(()=>{
        const socket = io(process.env.REACT_APP_SOCKET_URL)
        const areaId= areaList?.map(area=> area._id) || []
        if(!areaId?.length || !selectedCity) return 
        
        fetchRiders()
        socket.emit('mapriders', {"riderStatus": categories[selectedIndex].status , "areaId" : areaId, "uniqueId" : localStorage.getItem("socketId")});
        socket.on('mapridersdetails', (data) => {
          if(data.uniqueId !== localStorage.getItem("socketId")) return
          const markers = data.riders?.filter(rider=>rider.location?.coordinates?.length === 2 ).map(rider => ({
                    riderId: rider._id , lat: rider.location?.coordinates[1], lng: rider.location?.coordinates[0], icon: statuses.find(e => e.status === rider.riderStatus)?.icon, status: rider.riderStatus
                }))
            setMarkers(markers || [])
        });

        socket.on('riderUpdatedLocation',(data) =>{
            if(selectedCity?._id === data.cityId){
                socket.emit('mapriders', {"riderStatus": categories[selectedIndex].status , "areaId" : areaIds || [], "uniqueId" : localStorage.getItem("socketId")});
            }
        });
        return () => {
            socket.disconnect();
        };
    },[selectedIndex, areaList, selectedCity, isModalOpen])


    const onLoad = (map) => {
        mapRef.current = map;
        const idleListener = map.addListener("idle", () => {
            setIsMapLoaded(true);
        });
        return () => {
            window.google.maps.event.removeListener(idleListener);
        };
    };

    const onUnmount = () => {
        mapRef.current = null;
    };


    let categories = [
        { status: "all", title: 'All' },
        { status: "onJob", title: 'On Job' },
        { status: "online", title: 'Online' },
        { status: "offline", title: 'Offline' },
        { status: "outOfRadius", title: 'Out Of Radius' },
    ];


    const geocoder = new window.google.maps.Geocoder();
    const boundaryStyles = {};
    const circles = useRef([]);
    const circlesRef = useRef([]);
    const clearCircles = () => {
        circles.current.forEach((circle) => circle.setMap(null));
        circles.current = [];
        if (mapRef.current) {
            let featureLayer = mapRef.current.getFeatureLayer("POSTAL_CODE");
            featureLayer.style = (options) => {
            };
        }
    };


    const drawPincodes = (pincodeArray) => {
        pincodeArray.forEach((pincode) => {
            geocoder.geocode({ address: pincode, region: "IN" }, (results, status) => {
                if (status === "OK" && results.length > 0) {
                    const place = results[0].geometry.location;
                    const placeId = results[0].place_id;
                    const boundaryStyle = {
                        strokeColor: "#810FCB",
                        strokeOpacity: 1.0,
                        strokeWeight: 3.0,
                        fillOpacity: 0.5,
                    };

                    boundaryStyles[placeId] = boundaryStyle;

                    if (Object.keys(boundaryStyles).length === pincodeArray.length) {
                        let featureLayer = mapRef.current.getFeatureLayer("POSTAL_CODE");
                        featureLayer.style = (options) => {
                            if (options?.feature?.placeId) {
                                return boundaryStyles[options.feature.placeId];
                            }
                        };
                    }
                } else {
                    console.error(`No results found for the pin code: ${pincode}`);
                }
            });
        });
    }


    const drawCircleForArea = (areaData) => {
        const circle = new window.google.maps.Circle({
            strokeColor: "#810FCB",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            fillColor: "#810FCB",
            fillOpacity: 0,
            map: mapRef.current,
            center: {
                lat: parseFloat(areaData.coOrdinates.latitude),
                lng: parseFloat(areaData.coOrdinates.longitude)
            },
            radius: areaData.radius * (areaMasterHelper[areaData?.radiusTypes[0]?.unit] || 1),
        });
        circles.current.push(circle)
        return circle
    };

    useEffect(() => {
        if (isMapLoaded && areaList) {
            clearCircles();
            const newCircles = areaList?.map((area) => {
                if (area.pincodes) {
                    drawPincodes(area.pincodes)
                }
                if (area.radius) {
                    drawCircleForArea(area);
                }
            });
            circlesRef.current = newCircles;
        }
        return () => clearCircles();
    }, [isMapLoaded, areaList]);

    useEffect(() => {
        if (isMapLoaded && areaList.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();

            const geocodePincode = (pincode) => {
                return new Promise((resolve, reject) => {
                    geocoder.geocode({ address: pincode, region: "IN" }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            resolve(results[0].geometry.location);
                        } else {
                            reject(`No results found for the pin code: ${pincode}`);
                        }
                    });
                });
            };

            const geocodePromises = [];
            areaList.forEach((area) => {
                if (area.pincodes) {
                    area.pincodes.forEach((pincode) => {
                        geocodePromises.push(geocodePincode(pincode));
                    });
                }
            });

            Promise.all(geocodePromises).then((locations) => {
                locations.forEach((location) => {
                    bounds.extend(location);
                });

                areaList.forEach((area) => {
                    if (area.radius) {
                        const circle = drawCircleForArea(area);
                        bounds.union(circle.getBounds());
                    }
                });

                if (!bounds.isEmpty()) {
                    mapRef.current.fitBounds(bounds);
                }
            }).catch((error) => console.error(error));
        }
    }, [isMapLoaded, areaList]);



    return (
        <div className="relative w-[72%] z-10 ml-8 2xl:h-[25vh] lg:h-[32vh] overflow-y-auto pr-3">
            <div className="bg-white rounded-lg shadow-md p-4 max-w-sm absolute top-4 left-4 z-10">
                <ul>
                    {statuses.map((status, index) => (
                        <li key={index} className="flex items-center mb-4 last:mb-0">
                            <img src={status.icon} alt="" className="h-4 w-4 mr-2" /> {/* Adjust size as needed */}
                            <span className="text-sm">{status.text}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="absolute top-4 left-56 z-10">
                <Tabs
                    categories={categories}
                    className="w-[30vw]"
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    customeClassName = "2xl:text-sm 2xl:py-[0.45rem]"
                />
            </div>

            <div className="absolute top-4 right-4 z-10 cursor-pointer" onClick={handleModalOpen}>
                <img src={mapZoomIcon}/>
            </div>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{ mapId: process.env.REACT_APP_MAP_ID, streetViewControl: false, scaleControl: false, mapTypeControl: false, scrollwheel: false, fullscreenControl: false }}
                version="beta"
            >
                {isMapLoaded && markers?.length  && markers?.map((marker, index) => (
                    <Marker
                        key={index}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={() => handleMarkerClick(marker)}
                        icon={{
                            url: marker.icon,
                            scaledSize: new window.google.maps.Size(30, 30),
                            anchor: new window.google.maps.Point(15, 15)
                        }}
                    />
                ))}
                <div className="z-50">
                    {selectedMarker && (
                        <InfoWindow
                            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                            onCloseClick={() => setSelectedMarker(null)}
                            zIndex={10}
                        >
                            <MapInfoCard riderId={selectedMarker?.riderId} />
                        </InfoWindow>
                    )}
                </div>

            </GoogleMap>
        </div>
    );
};

export default MapRiderStatus;