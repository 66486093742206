import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useResetPasswordMutation, useSigninMutation } from "../../utils/Services/Apis/authApi";
import { Field, Form, Formik } from "formik";
import useAuth from "../../utils/hooks/useAuth";
import useToaster from "../../utils/hooks/useToaster";
import FixigoWhiteLogo from "../../assets/images/fixigo-white-logo.png";
import FixigoColorLogo from "../../assets/images/fixigo-logo.png";
import { Eye, EyeSlash } from 'iconsax-react';
import { useParams } from 'react-router-dom';

const NewPassword = () => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const { setToast } = useToaster();
	// const [signIn, resp] = useSigninMutation();
	const [toggleNewPassword, setToggleNewPassword] = useState(false);
	const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
	const [isErrorMessage, setIsErrorMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState('')

	const [resetPassword, { data: resetPasswardData }] = useResetPasswordMutation();

	const { tokenId } = useParams();

	const handleNewPassword = async (values) => {
		let resp;
		if (tokenId) {
			resp = await resetPassword({
				tokenId: tokenId,
				body: values
			});
		}
		if (resp.data) {
			login(resp.data.tokenResponse);
			setToast({ type: "success", msg: "Password has been changed successfully"});
			navigate("/dashboard");
		} else if (resp.error) {
			setIsErrorMessage(true);
			setErrorMessage(resp.error.data.message)
			// setToast({ type: "error", msg: resp.error.data.message });
		}
	};

	return (
		<>
			<div className="flex h-[100vh] justify-center bg-[#FFFFFF]">
				<div className="w-[60%] auth-bg h-[inherit] flex items-center pl-32">
					<div className="mb-24">
						<h1 className="font-inter-bold text-[#FFFFFF] text-[2.500vw]">Welcome to,</h1>
						<div className="w-[20.313vw] h-[8.854vw]">
							<img className="w-full h-full" src={FixigoWhiteLogo} />
						</div>
						<p className="text-[1.250vw] text-[#FFFFFF] font-inter-regular">Services At your Convenience</p>
					</div>
				</div>
				<div className="w-[40%] h-[inherit]">
					<div className="w-[70%] mx-auto relative h-[inherit]">
						<div className="w-[10.729vw] h-[4.688vw] absolute top-[4rem]">
							<img className="w-full h-full" src={FixigoColorLogo} />
						</div>
						{
							<div className="flex flex-col justify-center my-auto h-full">
								<h2 className="text-[2.396vw] font-futura-bold text-black">Create New Password</h2>
								{/* <h2 className="text-[2.396vw] font-futura-bold text-black">Sign in</h2> */}
								<p className="text-[#616161] futura-pt text-[1.250vw] mb-8 mt-4">Enter a new password below to change<br /> your password</p>

								<div>
									<Formik
										initialValues={{ newPassword: "", verifyPassword: "" }}
										// validate={handleValidation}
										onSubmit={(values) => {
											handleNewPassword(values);
										}}
									>
										{({ isSubmitting, errors, touched, values }) => {
											return <Form action="#" method="POST">

												<div className="relative mb-6">
													<div className="absolute top-[50%] -translate-y-[50%] left-6 h-[1.146vw] w-[0.833vw]">
														<svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
															<path d="M8 16C7.46957 16 6.96086 15.7893 6.58579 15.4142C6.21071 15.0391 6 14.5304 6 14C6 12.89 6.89 12 8 12C8.53043 12 9.03914 12.2107 9.41421 12.5858C9.78929 12.9609 10 13.4696 10 14C10 14.5304 9.78929 15.0391 9.41421 15.4142C9.03914 15.7893 8.53043 16 8 16ZM14 19V9H2V19H14ZM14 7C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V19C16 19.5304 15.7893 20.0391 15.4142 20.4142C15.0391 20.7893 14.5304 21 14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 7.89 0.89 7 2 7H3V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C8.65661 0 9.30679 0.129329 9.91342 0.380602C10.52 0.631876 11.0712 1.00017 11.5355 1.46447C11.9998 1.92876 12.3681 2.47995 12.6194 3.08658C12.8707 3.69321 13 4.34339 13 5V7H14ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z" fill="black" />
														</svg>
													</div>
													<Field
														id="newPassword"
														placeholder="New Password"
														name="newPassword"
														type={toggleNewPassword ? "text" : "password"}
														autoComplete="current-password"
														required
														className={`block w-full appearance-none pr-3 pl-14 py-2 placeholder-black shadow-sm sm:text-sm bg-[#F2F2F2] h-[3.125vw] rounded-[0.833vw] focus:shadow-[0] ${isErrorMessage ? 'border-1 border-[#FB0C0C66]' : 'border-0'}`}
													/>
													<div className="absolute top-[50%] -translate-y-[50%] right-4 cursor-pointer" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
														{
															toggleNewPassword ? <Eye size="24" className="w-[1.250vw] h-[1.250vw]" color="#000" /> : <EyeSlash size="24" className="w-[1.250vw] h-[1.250vw]" color="#000" />
														}
													</div>
												</div>

												<div className="relative mb-3">
													<div className="absolute top-[50%] -translate-y-[50%] left-6 h-[1.146vw] w-[0.833vw]">
														<svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
															<path d="M8 16C7.46957 16 6.96086 15.7893 6.58579 15.4142C6.21071 15.0391 6 14.5304 6 14C6 12.89 6.89 12 8 12C8.53043 12 9.03914 12.2107 9.41421 12.5858C9.78929 12.9609 10 13.4696 10 14C10 14.5304 9.78929 15.0391 9.41421 15.4142C9.03914 15.7893 8.53043 16 8 16ZM14 19V9H2V19H14ZM14 7C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V19C16 19.5304 15.7893 20.0391 15.4142 20.4142C15.0391 20.7893 14.5304 21 14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 7.89 0.89 7 2 7H3V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C8.65661 0 9.30679 0.129329 9.91342 0.380602C10.52 0.631876 11.0712 1.00017 11.5355 1.46447C11.9998 1.92876 12.3681 2.47995 12.6194 3.08658C12.8707 3.69321 13 4.34339 13 5V7H14ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z" fill="black" />
														</svg>
													</div>
													<Field
														id="verifyPassword"
														placeholder="Confirm Password"
														name="verifyPassword"
														type={toggleConfirmPassword ? "text" : "password"}
														autoComplete="current-password"
														required
														className={`block w-full appearance-none pr-3 pl-14 py-2 placeholder-black shadow-sm sm:text-sm bg-[#F2F2F2] h-[3.125vw] rounded-[0.833vw] focus:shadow-[0] ${isErrorMessage ? 'border-1 border-[#FB0C0C66]' : 'border-0'}`}
													/>
													<div className="absolute top-[50%] -translate-y-[50%] right-4 cursor-pointer" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
														{
															toggleConfirmPassword ? <Eye size="24" className="w-[1.250vw] h-[1.250vw]" color="#000" /> : <EyeSlash size="24" className="w-[1.250vw] h-[1.250vw]" color="#000" />
														}
													</div>
												</div>
												<div className="h-6">
													<p className={`font-medium text-[#FB0C0C] text-[0.833vw] text-left futura-pt ${isErrorMessage ? 'visible' : 'invisible'}`}>
														{/* The email or password is incorrect, please check and try again. */}
														{errorMessage}
													</p>
												</div>

												{/* <div className="text-start">
														<a className="font-medium text-[#FB0C0C] cursor-pointer text-[0.833vw] text-left futura-pt underline underline-offset-1" onClick={() => setShowForgotPasswordPage(true)}>
															Forgot your password?
														</a>
													</div> */}

												<div className="mt-10">
													<button
														type="submit"
														disabled={!values.newPassword || !values.verifyPassword}
														className="flex w-full h-[3.125vw] text-[1.042vw] justify-center items-center rounded-[10px] border border-transparent bg-[#000] py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none font-inter-bold tracking-wider"
													>
														Change Password
													</button>
												</div>
											</Form>
										}}
									</Formik>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</>
	);
};

export default NewPassword;