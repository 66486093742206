import React, { useEffect, useMemo, useRef, useState } from "react";
// import Stepper from "react-stepper-horizontal";
import Button from "../../shared/components/Button.component";
import FormField from "../../shared/components/FormField.component";
import { Field, Form, Formik, useFormikContext } from "formik";
import ListBoxComponent from "../../shared/components/ListBox.component";
import {
	useAddCustomerMutation,
	useAddOrderMutation,
	useGetCustomerByPhoneNumberMutation,
	useGetRidersMutation,
	useGetServicesForOrderMutation,
	useLazyGetAreasQuery,
	useLazyGetCarModelByBrandIdQuery,
	useLazyGetCitesQuery,
	useLazyGetPaymentOrderStatusQuery,
	useLazyGetRidersForOrderQuery,
	useLazySendPaymentLinkToCustomerQuery,
	useVehicleAddressMutation,
	useVerifyVehicleMutation,
} from "../../utils/Store";
import { useLazyGetBrandsQuery } from "../../utils/Services/Apis/carApi";
import MapSearchLocation from "./MapSearchLocation";
import useToaster from "../../utils/hooks/useToaster";
import DatePickerComponent from "../../shared/components/DatePicker.component";
import dateFormatter from "../../shared/components/dateFormatter.component";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useSelectModal from "../../utils/hooks/useSelectModal";
import BackButton from "../../shared/components/BackButton.component";
import SelectMenu from "../../shared/components/SelectMenu.component";
import * as Yup from "yup";
////////////////////////////////////////
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import steperIcon from "../../assets/icons/steper.svg";
import checkedIcon from "../../assets/icons/checkedIcon.svg";
import { calculatePrice } from "../../utils/helper/priceCalculator";
import ReactFlagsSelect from "react-flags-select";
import Calender from "../../shared/components/Calender";
import PaymentStatusSpinner from "../../shared/components/PaymentStatusSpinner";
import { useGetAvailableRidersMutation, useGetAvailableTimeSlotsMutation } from "../../utils/Services/Apis/timeSlot";
import { useCallback } from "react";
import { isInTheRadius } from "../../utils/helper/checkInTheRadius";
import SearchCustomer from "../../shared/components/SearchCustomer";
///////////////////////////////////////////////////////
function NewServiceRequest() {
	const [getVehicleAddress, {data: vehicleAddress, status: vehicleAddressStatus}] = useVehicleAddressMutation()
	const [currentStep, setCurrentStep] = useState(0); // Start from the first step
	const [getAllBrands, { data: allCarBrands }] = useLazyGetBrandsQuery();
	const [getCarModelByBrandId, { data: carModalsList }] = useLazyGetCarModelByBrandIdQuery();
	// const [isExistedCustomer, setIsExistedCustomer] = useState(false);
	const [addCustomer, { status: addCustomerStatus }] = useAddCustomerMutation();
	const [getCities, { data: cityList }] = useLazyGetCitesQuery();
	const [dataBodyForServices, setDataBodyForServices] = useState("");
	const [addOrder, { data:addOrderData, status }] = useAddOrderMutation();
	const [verifyVehicle, { status: vehicleVerificationStatus }] = useVerifyVehicleMutation();
	const [brandId, setBrandId] = useState("");
	const [customerVehicleList, setCustomerVehicleList] = useState([]);
	const { setToast } = useToaster();
	// const [allRiders, setAllRiders] = useState([]);
	// const [getRiders] = useGetRidersMutation();
	// const [getRiders, { data: allRiders }] = useLazyGetRidersForOrderQuery();
	const navigate = useNavigate();
	const { openModal } = useSelectModal();
	const [payableAmount, setPayableAmount] = useState(0);
	const brandRef = useRef(null);
	const [isSelectedExistingAddress, setIsSelectedExistingAddress] = useState(false);
	const [isSelectedExistingVehicle, setIsSelectedExistingVehicle] = useState(false);
	const [isSubmittedOnlinePaymentMethod, setIsSubmittedOnlinePaymentMethod] = useState(false);
	const [paymentStatus, setPaymentStatus] = useState("");

	
	const dateRef = useRef(null);
	const soltRef = useRef(null);
	const areaRef = useRef(null);
	const [ getAllAvailableRiders, { data: allRiders, isLoading: getRidersLoading } ] = useGetAvailableRidersMutation();
	const [ getAllAvailableTimeSlots, { data: allTimeSlots, status: timeSlotStatus, isLoading: timeSlotLoading }  ] = useGetAvailableTimeSlotsMutation();
  // const [sendPaymentLinkToCustomer , {data:sendPaymentLinkToCustomerData}] = useLazySendPaymentLinkToCustomerQuery()
	const [getOnlinePaymentStatus , {data: onlinePaymentStatusData}] = useLazyGetPaymentOrderStatusQuery()

	const step1Schema = Yup.object().shape({
		customerName: Yup.string().required("Required"),
		customerEmail: Yup.string().email("Invalid email").required("Required"),
		customerCity: Yup.string().required("Required"),
		customerPhoneNumber: Yup.string()
			.required("Phone Number is required")
			.matches(/^\d{10}$/, "Please enter 10 digits Mobile Number"),
	});
	const step2Schema = Yup.object().shape({
		vehicleNumber: Yup.string().required("Required"),
		brandId: Yup.object().required("Brand is required"),
		model: Yup.object().required("Model is required"),
	});
	const step3Schema = Yup.object().shape({
		line1: Yup.string().required("Address Line 1 is required"),
		line2: Yup.string(),
		landMark: Yup.string(),
		area: Yup.object().required("Area is required"),
		city: Yup.object().required("City is required"),
		pincode: Yup.string().required("Pincode is required").min(6, "Please enter 6 digits Pincode"),
		coOrdinates: Yup.object().required("Select location on map"),
		addressType: Yup.string().required("Select Address type"),
	});
	const step4Schema = Yup.object().shape({
		selectService: Yup.object().required("Service is required"),
	});
	const step5Schema = Yup.object().shape({
		date: Yup.date().required("Date is required"),
		solt: Yup.object().required("Slot is required"),
	});
	const step6Schema = Yup.object().shape({
		paymentMode: Yup.string()
			.oneOf(["online", "cash"], "Invalid payment mode")
			.required("Payment Mode is required"),
	});
	const stepValidationSchemas = [
		step1Schema,
		step2Schema,
		step3Schema,
		step4Schema,
		step5Schema,
		step6Schema,
	];
	useEffect(() => {
		if (!brandId) return;
		getCarModelByBrandId(brandId);
	}, [brandId]);

	const vehicleRef = useRef();
	const vehicleRefForBrand = useRef();

	useEffect(() => {
		if(!addOrderData) return
		if(onlinePaymentStatusData && onlinePaymentStatusData?.pending !== true) return
		const intervalId  = setInterval (()=>{
       getOnlinePaymentStatus(addOrderData.paymentOrderId)
		}, 30000)
  
		return (()=>{
			clearInterval(intervalId)
		})
	}, [addOrderData,onlinePaymentStatusData]);

	useEffect(()=>{
		if(!onlinePaymentStatusData) return
		if(onlinePaymentStatusData?.success) {
			setPaymentStatus("success")
			setTimeout(()=>{
				navigate("/appointment-management");
				openModal("DisplayAppointmentSuccess", { orderId: addOrderData._id });
			}, 5000)
		}
		if(onlinePaymentStatusData?.failed) setPaymentStatus("failed")
	},[onlinePaymentStatusData])

	const steps = [
		{ title: "Primary Information" },
		{ title: "Vehicle Details" },
		{ title: "Address Details" },
		{ title: "Services Details" },
		{ title: "Select Slot" },
		{ title: "Payment Method" },
	];
	// Function to move to the next step
	const nextStep = async (values, setFieldValue, setErrors, setTouched) => {
		const currentSchema = stepValidationSchemas[currentStep];
		// Validate only the fields for the current step

		currentSchema
			.validate(values, { abortEarly: false })
			.then(() => {
				// setCurrentStep(currentStep + 1);
				handleOnValidate(values, setFieldValue);
			})
			// .then(()=> setCurrentStep(Math.min(currentStep + 1, steps.length - 1)))
			.catch((err) => {
				// If validation fails
				const errors = {};
				const touched = {};
				err.inner.forEach((error) => {
					errors[error.path] = error.message;
					touched[error.path] = true;
				});
				setErrors(errors);
				setTouched(touched);
				// Mark fields as touched
			});
	};
	const handleOnValidate = async (values, setFieldValue) => {
		if (currentStep === 0 && !values.search) {
			const dataObj = {
				customerName: values.customerName,
				customerEmail: values.customerEmail,
				customerCity: values.customerCity,
				customerPhoneNumber: "+91" + values.customerPhoneNumber,
			};
			if (
				!values.customerName ||
				!values.customerEmail ||
				!values.customerCity ||
				!values.customerPhoneNumber
			) {
				return;
			}
			const { data, error } = await addCustomer(dataObj);
			if (data) {
				setToast({ type: "success", msg: "Added Customer Details Successfully" });
				// setIsExistedCustomer(true);
				setFieldValue("customerId", data._id);
				setCustomerVehicleList([]);
			}
			if (error) return setToast({ type: "error", msg: error.data.message });
		}
		if (currentStep === 0) {
			getAllBrands();
		}
		if (currentStep === 1 && !cityList) {
			getCities();
		}
		if (currentStep === 1) {
			if (!values?.brandId?._id || !values?.model?._id) return;
			const { data, err } = await verifyVehicle({
				vehicleNumber: values.vehicleNumber,
			});
			if (data){
				if(values.vehicle){
					const {data, error} = await	getVehicleAddress({
						vehicleId: values.vehicle._id
					})
						if(data) {
							const defaultAddress = data.find(add=> add.defaultAddress) || ""
							setFieldValue("initialDefaultAddress", defaultAddress)
							setFieldValue("selectAddress", defaultAddress)
						}
			} 
					
				if(!data?.verified)	return setToast({ type: "error", msg: "Please enter valid vehicle number" });
				if(data?.verified) setCurrentStep(Math.min(currentStep + 1, steps.length - 1))
			}
			if (err) return setToast({ type: "error", msg: err.data.message });
		}
		if (currentStep === 2) {
			if (!values.city?._id || !values.area?._id) return;
			  const isInsideRadius = await  isInTheRadius({
        pincodes: values.area?.pincodes,
        areaLatitude: values.area.coOrdinates.latitude, 
        areaLongitude: values.area.coOrdinates.longitude, 
        latitude:values.coOrdinates.latitude, 
        longitude:values.coOrdinates.longitude, 
        radiusType:values.area?.radiusType, 
        areaRadius:values.area.radius
      })
			if(!isInsideRadius) setToast({ type: "error", msg: "Address is not in serviceable radius" });
			if(isInsideRadius) setCurrentStep(Math.min(currentStep + 1, steps.length - 1))
		}
		// if (currentStep === 3) {
		// 	handleGetRiders(values.area?._id);
		// }

		if (currentStep === 5) {
			if (values.paymentMode === "online" && !isSubmittedOnlinePaymentMethod) {
				 setIsSubmittedOnlinePaymentMethod(true);
			}

			const data = {
				paymentType: values.paymentMode,
				customerId: values.customerId,
				carBrandId: values?.brandId?._id,
				vehicleNumber: values.vehicleNumber,
				carModelId: values.model._id,
				// promoCode: "Unknown Type: String",
				payableAmount: payableAmount,
				addressLineOne: values.line1,
				addressLineTwo: values.line2,
				landMark: values.landMark,
				areaId: values.area?._id,
				cityId: values.city?._id,
				pincode: values.pincode,
				defaultAddress: values.defaultAddress,
				addressType: values.addressType,
				serviceType: values.serviceType,
				serviceDate: moment(values.date).toISOString(),
				timeSlot: values.solt?.timeslot,
				riderId: values.rider?._id,
				coOrdinates: {...values.coOrdinates, searchedPlace: values.searchedPlace},
				service: [values.selectService?._id],
			};
		  const {data: d ,error} = await addOrder(data)
			
			if(d) {
				if(values.paymentMode === "online") return setToast({ type: "success", msg: "Payment link is sent to registered mobile number" })

        setToast({ type: "success", msg: "Service request created successfully" })
				navigate("/appointment-management");
				openModal("DisplayAppointmentSuccess", { orderId: d._id });
			}
			if(error) setToast({ type: "error", msg: error.data.message });				
		}
		if(currentStep === 2 || currentStep === 1) return
		setCurrentStep(Math.min(currentStep + 1, steps.length - 1))
	};
	// Function to move to the previous step
	const prevStep = () => {
		if (currentStep !== 0) {
			setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
		} else {
			navigate(-1);
		}
	};
	// const allSlots = [
	// 	{ name: "8.00 AM - 9.00 AM", value: "8-9 AM" },
	// 	{ name: "9.00 AM - 10.00 AM", value: "9-10 AM" },
	// 	{ name: "10.00 AM - 11.00 AM", value: "10-11 AM" },
	// 	{ name: "11.00 AM - 12.00 PM", value: "11-12 AM" },
	// 	{ name: "12.00 PM  - 1.00 PM", value: "12-1 PM" },
	// 	{ name: "1.00 PM - 2.00 PM", value: "1-2 PM" },
	// 	{ name: "2.00 PM - 3.00 PM", value: "2-3 PM" },
	// 	{ name: "3.00 PM - 4.00 PM", value: "3-4 PM" },
	// 	{ name: "4.00 PM - 5.00 PM", value: "4-5 PM" },
	// 	{ name: "5.00 PM - 6.00 PM", value: "5-6 PM" },
	// 	{ name: "6.00 PM - 7.00 PM", value: "6-7 PM" },
	// 	{ name: "7.00 PM - 8.00 PM", value: "7-8 PM" },
	// ];

	const [allSlots, setAllSlots] = useState([]);
	const [getCustomerByPhoneNumber, { status: getCustomerByPhoneNumberStatus }] =
		useGetCustomerByPhoneNumberMutation();
	const [oldCustomer, setOldCustomer] = useState([]);
	const [selected, setSelected] = useState("IN");

	const handleCalculatePrice = (values, setFieldValue) => {
		let selectedCarServicePrice = values?.selectService?.carType.find(
			(car) => car.type === values?.model?.carType
		);
		let payableAmount = calculatePrice(
			selectedCarServicePrice?.afterGSTPrice,
			selectedCarServicePrice?.discountType,
			selectedCarServicePrice?.discountPrice,
			selectedCarServicePrice?.price
		);
		setPayableAmount(payableAmount);
		return payableAmount;
	};
	// const handleGetRiders = async (areaId, setFieldValue) => {
	// 	const { data, error } = await getRiders(areaId);
	// 	// if (data) {
	// 	// 	setAllRiders(data);
	// 	// }
	// 	if (error) {
	// 		setToast({ type: "error", msg: error.data.message });
	// 	}
	// };

	const PrimaryInformation = () => {
		const { values, setFieldValue } = useFormikContext();
		useEffect(() => {
			if (oldCustomer?.length) {
				// setIsExistedCustomer(true);
				setFieldValue("customerPhoneNumber", oldCustomer[0]?.phoneNumber.slice(3));
				setFieldValue("customerName", oldCustomer[0]?.name);
				setFieldValue("customerEmail", oldCustomer[0]?.email);
				setFieldValue("customerCity", oldCustomer[0]?.city);
				setFieldValue("customerId", oldCustomer[0]?._id);
				if (oldCustomer[0]?.vehicle?.length > 0) {
					setCustomerVehicleList(oldCustomer[0]?.vehicle);
				}
			}
		}, [oldCustomer]);
		useEffect(() => {
			if (!values.search && oldCustomer?.length) {
				setFieldValue("customerPhoneNumber", "");
				setFieldValue("customerName", "");
				setFieldValue("customerEmail", "");
				setFieldValue("customerCity", "");
				setFieldValue("customerId", "");
			}
		}, [values.search]);
		return (
			<div className="flex justify-center ">
				<div className="px-6 pb-0 2xl:pt-2 xl:pt-4 pt-2 w-[50vw] h-[28rem] 2xl:h-[31.25rem] xl:h-[29.75rem] flex items-center flex-col rounded-xl border xl:space-y-3 space-y-3 bg-[#F9FAFB]">
					<h1 className="text-lg font-inter-bold">1. Primary Information of the Customer</h1>
					<div className="w-full 2xl:space-y-3 space-y-0">
						<SearchCustomer setOldCustomer={setOldCustomer} />
						{/* <div className="flex gap-3">
							<div className="w-[90vw] flex">
								<ReactFlagsSelect
									selected={selected}
									showOptionLabel={false}
									disabled={true}
									showSelectedLabel={false}
									placeholder="+91"
								/>
								<div className="w-full">
									<FormField
										field={{
											label: "",
											name: "search",
											type: "text",
											placeholder: "Search Customer with Mobile number",
											onKeyPress: (event) => {
												if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
													return event.preventDefault();
												}
											},
										}}
									/>
								</div>
							</div>
							<Button
								className={
									"bg-black text-white mt-1 2xl:py-2 py-1 text-sm 2xl:text-base cursor-pointer h-[2.4rem] w-44"
								}
								isSubmitting={getCustomerByPhoneNumberStatus === "pending"}
								onClick={async () => {
									if (values.search) {
										let { data } = await getCustomerByPhoneNumber(values.search);
										if (data && data.length > 0) {
											setOldCustomer(data);
										} else {
											setToast({ type: "error", msg: "Customer not found" });
										}
									}
								}}
							>
								Search
							</Button>
						</div> */}
						<div>
							<label className="block text-sm font-medium leading-6 text-gray-900">
								Mobile Number
							</label>
							<div className="flex">
								<ReactFlagsSelect
									selected={selected}
									showOptionLabel={false}
									disabled={true}
									showSelectedLabel={false}
									placeholder="+91"
								/>
								<div className="w-full">
									<FormField
										field={{
											// label: "Mobile Number",
											name: "customerPhoneNumber",
											type: "text",
											placeholder: "+91",
											readOnly: values.search,
											onKeyPress: (event) => {
												if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
													return event.preventDefault();
												}
											},
										}}
									/>
								</div>
							</div>
						</div>
						{/* <h3>Mobile Number</h3>
                            <input placeholder="+91"></input> */}
						<FormField
							field={{
								label: "Customer Name",
								name: "customerName",
								type: "text",
								placeholder: "Enter Customer Name",
								readOnly: values.search,
							}}
						/>
						<FormField
							field={{
								label: "Email ID",
								name: "customerEmail",
								type: "email",
								placeholder: "Enter Customer Email",
								readOnly: values.search,
							}}
						/>
						<FormField
							field={{
								label: "City",
								name: "customerCity",
								type: "text",
								placeholder: "Enter City",
								readOnly: values.search,
							}}
						/>
					</div>
				</div>
			</div>
		);
	};
	const VehicleDetails = () => {
		const { values, setFieldValue, errors, touched } = useFormikContext();
		// const [isOldVehicleSelected, setIsOldVehicleSelected] = useState(false);

		useEffect(() => {
			if (!values?.brandId?._id || brandRef.current === values?.brandId?._id) return;
			setBrandId(values?.brandId?._id);
			brandRef.current = values?.brandId?._id;
			setFieldValue("model", "");
		}, [values?.brandId]);

		useEffect(() => {
			if (vehicleRef.current === values?.vehicle?._id || !allCarBrands?.length) {
				return; // Skip the effect logic
			}
			vehicleRef.current = values?.vehicle?._id;
			setFieldValue("model", "");
			if (values?.vehicle) {
				setIsSelectedExistingVehicle(true);
				setFieldValue("vehicleNumber", values?.vehicle?.vehicleNumber);
				const brandIndex = allCarBrands?.findIndex(
					(brand) => brand?._id === values?.vehicle?.carBrandId
				);
				setFieldValue("brandId", brandIndex !== -1 ? allCarBrands[brandIndex] : "");
			}
		}, [values?.vehicle, allCarBrands]);

		useEffect(() => {
			if (
				!carModalsList?.length ||
				!values?.vehicle ||
				vehicleRefForBrand.current === values?.vehicle?._id ||
				values.model
			)
				return;
			const modelIndex = carModalsList?.findIndex(
				(car) => car?._id === values?.vehicle?.carModelId
			);
			if (modelIndex !== -1) vehicleRefForBrand.current = values?.vehicle?._id;
			setFieldValue("model", carModalsList && modelIndex !== -1 ? carModalsList[modelIndex] : "");
		}, [carModalsList, values?.vehicle]);

		useEffect(() => {
			if (isSelectedExistingVehicle && !values?.vehicle) {
				setFieldValue("vehicleNumber", "");
				setFieldValue("brandId", "");
				setFieldValue("model", "");
				setIsSelectedExistingVehicle(false);
				vehicleRef.current = "";
				vehicleRefForBrand.current = "";
			}
		}, [values?.vehicle]);

		return (
			<div className="flex justify-center">
				<div className="px-6 pb-0 pt-6 w-[50vw] h-[28rem] 2xl:h-[28.25rem] xl:h-[27.75rem] flex items-center flex-col rounded-xl border space-y-3 bg-[#F9FAFB]">
					<h1 className="text-lg font-inter-bold">2. Vehicle Details</h1>
					<div className="w-full  space-y-3">
						<SelectMenu
							label="Vehicle"
							name="vehicle"
							options={customerVehicleList}
							onChange={(option) => setFieldValue("vehicle", option)}
							value={values.vehicle}
							placeholder="Select Vehicle"
							getOptionValue={(option) => option._id}
							optionLabel="vehicleNumber"
							isClearable={true}
						/>
						<div className="invisible h-3"></div>
						<FormField
							field={{
								label: "Vehicle Number",
								name: "vehicleNumber",
								type: "text",
								placeholder: "Enter Vehicle Number",
								readOnly: values.vehicle,
							}}
							disabled={values.vehicle}
						/>
						<div>
							<SelectMenu
								label="Vehicle Brand"
								name="brandId"
								options={allCarBrands ? allCarBrands : []}
								onChange={(option) => setFieldValue("brandId", option)}
								value={values.brandId}
								placeholder="Select Vehicle Brand"
								getOptionValue={(option) => option._id}
								// disabled={values.vehicle}
							/>
							{errors.brandId && touched.brandId ? (
								<div className="error text-red-600 text-sm">{errors?.brandId}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<div>
							<SelectMenu
								label="Vehicle Model"
								name="model"
								options={carModalsList ? carModalsList : []}
								onChange={(option) => setFieldValue("model", option)}
								value={values.model}
								placeholder="Select Vehicle Model"
								getOptionValue={(option) => option._id}
								// disabled={values.vehicle}
							/>
							{errors.model && touched.model ? (
								<div className="error text-red-600 text-sm">{errors?.model}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};
	const AddressDetails = () => {
		const { values, setFieldValue, errors, touched } = useFormikContext();
		const [getAreasByCity, { data: areaList }] = useLazyGetAreasQuery();
		const shouldDefaultDisabled = useRef(false)
		useEffect(() => {
			if (values?.city?._id) {
				getAreasByCity(values.city?._id);
			}
		}, [values?.city]);

		 

		useEffect(() => {
			if (isSelectedExistingAddress && !values.selectAddress) {
				setFieldValue("line1", "");
				setFieldValue("line2", "");
				setFieldValue("landMark", "");
				setFieldValue("city", "");
				setFieldValue("pincode", "");
				setFieldValue("defaultAddress", false);
				setFieldValue("addressType", "");
				setFieldValue("area", "");
				setFieldValue("coOrdinates", "");
				setFieldValue("searchedPlace", "");
				setIsSelectedExistingAddress(false);
			}
		}, [values.selectAddress]);

		useEffect(()=>{
			shouldDefaultDisabled.current = values?.initialDefaultAddress?._id === values?.selectAddress?._id
			if(values.selectAddress) setIsSelectedExistingAddress(true)
		},[values.selectAddress])

		useEffect(()=>{
			if(!values?.initialDefaultAddress){
			 shouldDefaultDisabled.current = false
			 setFieldValue("defaultAddress", true);
			}
		},[values?.initialDefaultAddress])

		useEffect(()=>{
			if(values.area) areaRef.current = values.area._id
			if(values?.area?._id !== areaRef.current) {
				setFieldValue("solt", "");
		  	setFieldValue("rider", "");
			}
		},[values.area])

		useEffect(() => {
			if (!values.selectAddress || !oldCustomer || !cityList) return;
			setFieldValue("line1", values.selectAddress?.addressLineOne);
			setFieldValue("line2", values.selectAddress?.addressLineTwo);
			setFieldValue("landMark", values.selectAddress?.landMark);
			const cityIndex = cityList?.findIndex((city) => city._id === values.selectAddress?.cityId);
			setFieldValue("city", cityIndex !== -1 ? cityList[cityIndex] : "");
			setFieldValue("pincode", values.selectAddress?.pincode);
			setFieldValue("defaultAddress", values.selectAddress?.defaultAddress);
			if(values.selectAddress?.addressType !== "home" && values.selectAddress?.addressType !== "office"){
				 setFieldValue("customType", values.selectAddress?.addressType);
			}
			setFieldValue("addressType", values.selectAddress?.addressType);
			setFieldValue("searchedPlace",values.selectAddress?.coOrdinates?.searchedPlace ? values.selectAddress?.coOrdinates?.searchedPlace : "");
		}, [values.selectAddress]);

		useEffect(() => {
			if (areaList && values.selectAddress) {
				const areaIndex = areaList?.findIndex((area) => area._id === values.selectAddress?.areaId);
				setFieldValue("area", areaIndex !== -1 ? areaList[areaIndex] : "");
			}
		}, [areaList]);
		return (
			<div className="flex justify-center">
				<div className="pl-6 pr-3 pb-0 2xl:pt-6 pt-3 w-[50vw] h-[27vw] flex items-center flex-col rounded-xl border 2xl:space-y-3 space-y-1 bg-[#F9FAFB]">
					<h1 className="text-lg font-inter-bold">3. Address Details</h1>
					<p>Please enter the address you want to book service for</p>
					<div className="w-full 2xl:space-y-3 space-y-1 h-[50vh] overflow-y-scroll overflow-x-hidden pr-3">
						{/* <input placeholder='Search Customer with Mobile number' ></input> */}
						<SelectMenu
							label="Select Address"
							name="selectAddress"
							options={vehicleAddress ? vehicleAddress : []}
							onChange={(option) => setFieldValue("selectAddress", option)}
							value={values.selectAddress}
							placeholder="Address"
							getOptionValue={(option) => option._id}
							optionLabel="addressLineOne"
							isClearable={true}
						/>
						<div className="pt-2">
							<label className="text-sm mb-1">Search Location</label>
							<MapSearchLocation
								setFieldValue={setFieldValue}
								selectedAddress={values.selectAddress}
							/>
							{errors.coOrdinates && touched.coOrdinates ? (
								<div className="error text-red-600 text-sm">{errors?.coOrdinates}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<FormField
							field={{
								label: "Address Line 1",
								name: "line1",
								type: "text",
								placeholder: "Enter address 1",
							}}
						/>
						<FormField
							field={{
								label: "Address Line 2",
								name: "line2",
								type: "text",
								placeholder: "Enter address 2",
							}}
						/>
						<FormField
							field={{
								label: "Landmark",
								name: "landMark",
								type: "text",
								placeholder: "Enter landmark",
							}}
						/>

						<div>
							<SelectMenu
								label="City"
								name="city"
								options={cityList}
								onChange={(option) => setFieldValue("city", option)}
								value={values.city}
								placeholder="Select city"
								getOptionValue={(option) => option._id}
							/>
							{errors.city && touched.city ? (
								<div className="error text-red-600 text-sm">{errors?.city}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>

						<div>
							<SelectMenu
								label="Area"
								name="area"
								options={areaList}
								onChange={(option) => setFieldValue("area", option)}
								value={values.area}
								placeholder="Select area"
								getOptionValue={(option) => option._id}
							/>
							{errors.area && touched.area ? (
								<div className="error text-red-600 text-sm">{errors?.area}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>

						<FormField
							field={{
								label: "Pin Code",
								name: "pincode",
								type: "text",
								placeholder: "Enter Code",
								onKeyPress: (event) => {
									if (/[^0-9 ]/.test(event.key) || event.target.value.length > 5) {
										return event.preventDefault();
									}
								},
							}}
						/>
						<div>
							<div className="block text-sm font-medium leading-6 text-gray-900">Address Type</div>
							<div className="flex items-center space-x-4">
								<div className="2xl:p-2 w-[10%] p-1 m-1">
									<div className="flex items-center">
										<Field
											type="radio"
											name="addressType"
											className="mr-2 mt-1.5 appearance-none items-center p-2 border-black border-2 checked:bg-black focus:text-black focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-black"
											value="home"
										/>
										<span className="text-gray-700 2xl:text-base text-sm">Home</span>
									</div>
								</div>
								<div className="2xl:p-2 w-[10%] p-1 m-1">
									<div className="flex items-center">
										<Field
											type="radio"
											name="addressType"
											className="mr-2 mt-1.5 appearance-none items-center p-2 border-black border-2 checked:bg-black focus:text-black focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-black"
											value="office"
										/>
										<span className="text-gray-700 2xl:text-base text-sm">Office</span>
									</div>
								</div>
								<div className="w-full rounded-lg m-1">
									<div className="flex items-center w-full gap-1">
										<Field
											// disabled={true}
											type="radio"
											name="addressType"
											className="mr-2 mt-1.5 appearance-none items-center p-2 border-black border-2 checked:bg-black focus:text-black focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-black"
											value={values.customType}
										/>
										<div className="w-full -mt-1">
											<FormField
												nonFormikError
												field={{
													name: "customType",
													type: "text",
													placeholder: "Other",
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							{errors.addressType && touched.addressType ? (
								<div className="error text-red-600 text-sm whitespace-nowrap">
									{errors?.addressType}
								</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<div className="space-x-2 pb-5 default-address-checkbox">
							<Field
							  disabled={shouldDefaultDisabled.current}
								type="checkbox"
								name="defaultAddress"
								className="rm-font-overpass mr-[0.83vw] border-black border-2 p-1.5 bg-inherit checked:bg-black focus:ring-transparent focus:ring-offset-0 focus:checked:bg-black sm:mx-2"
							/>
							<span className="2xl:text-base text-sm">Save address as default?</span>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const ServiceDetails = () => {
		const { values, setFieldValue, errors, touched } = useFormikContext();
		const [getServicesForOrder, { data: serviceList }] = useGetServicesForOrderMutation();
		useEffect(() => {
			if (!values.city?._id || !values.serviceType || !values.area?._id || !values.model?.carType)
				return;
			getServicesForOrder({
				cityId: values.city._id,
				areaId: values.area._id,
				// serviceType: values.serviceType,
				serviceType: "onetimeservice",
				carType: values.model.carType,
			});
		}, [values.serviceType]);

		useEffect(() => {
			if (!serviceList) return;
			const selectedService = serviceList?.find((service) => service._id === values.selectService?._id)
			setFieldValue(
				"selectService",
				selectedService ? selectedService : ""
			);
		}, [serviceList]);

		return (
			<div className="flex justify-center">
				<div className="px-6 mb-4 pt-6 w-[50vw] pb-2 flex items-center flex-col rounded-xl border space-y-3 bg-[#F9FAFB]">
					<div className="text-lg font-inter-bold">4. Service Details</div>
					<div className="w-full">
						<div className="text-sm font-medium mb-2 font-inter-medium">Choose Service Type</div>
						<div className="flex items-center justify-between w-full gap-10 font-normal text-sm">
							<div className="py-2 px-4 w-1/3 bg-white rounded-lg border">
								<label className="flex items-center gap-2">
									<Field
										type="radio"
										name="serviceType"
										value="service"
										className="mr-2 mt-1.5  items-center checked:bg-[#757575] focus:text-[#757575] focus:ring-transparent hover:bg-[#757575] md:mt-0 sm:mt-0"
									/>
									<span className="text-[#757575] font-normal ">Onetime Service</span>
								</label>
							</div>
							<div className="py-2 px-4 w-1/3 bg-white rounded-lg border">
								<label className="flex items-center gap-2">
									<Field
										type="radio"
										name="serviceType"
										value="subscriptions"
										className="mr-2 mt-1.5 appearance-none items-center checked:bg-[#757575] focus:text-[#757575] focus:ring-transparent md:mt-0 sm:mt-0"
										disabled
									/>
									<span className="text-[#757575]">Subscriptions</span>
								</label>
							</div>
							<div className="py-2 px-4 w-1/3 bg-white rounded-lg border">
								<label className="flex items-center gap-2">
									<Field
										type="radio"
										name="serviceType"
										value="packages"
										className="mr-2 mt-1.5 appearance-none items-center checked:bg-[#757575] focus:text-[#757575] focus:ring-transparent md:mt-0 sm:mt-0"
										disabled
									/>
									<span className="text-[#757575]">Packages</span>
								</label>
							</div>
						</div>
					</div>
					{/* <div className="flex gap-3 items-center w-full">
            <div className="w-[90vw]">
              <FormField
                field={{
                  label: "",
                  name: "searchService",
                  type: "text",
                  placeholder: "Search Service",
                }}
              />
            </div>
            <Button className={"bg-black text-white "}>Search</Button>
          </div> */}
					<div className="w-full pt-5">
						<SelectMenu
							label="Select Services"
							name="selectService"
							options={serviceList ? serviceList : ""}
							onChange={(option) => setFieldValue("selectService", option)}
							value={values.selectService}
							placeholder="Select Service"
							getOptionValue={(option) => option._id}
							optionLabel="serviceName"
							customeClassName="font-inter-medium"
						/>
						{errors.selectService && touched.selectService ? (
							<div className="error text-red-600 text-sm">{errors?.selectService}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>
			</div>
		);
	};

	// const getSlots = (formValues) => {
	// 	let slots = allSlots;
	// 	if (moment().isSame(formValues?.date, "day")) {
	// 		slots = allSlots.filter((slot) => {
	// 			const currentTime = moment();
	// 			const endTimeOfSlot = moment(slot.value.split("-")[1], "h A"); // Get the end time of the slot

	// 			return endTimeOfSlot.isAfter(currentTime);
	// 		});
	// 	}
	// 	return slots;
	// };


	const getAvailableTimeSlots = async (formData) => {
		let { data: slotData, error } = await getAllAvailableTimeSlots({
			coOrdinates: {
				latitude: formData?.coOrdinates?.latitude,
				longitude: formData?.coOrdinates?.longitude,
			},
			areaId: formData?.area?._id,
			serviceDate: moment(formData?.date).format('YYYY-MM-DD'),
			duration: "sixty",
			serviceId: [formData?.selectService?._id]
		})
		if(slotData) {
			setAllSlots(slotData);
		}
		if(error) {
			setToast({ type: "error", msg: error?.data?.message ? error?.data?.message : 'Error while fetching slots' });
		}
	}
	
	const getAvailableRiders = async (formData) => {
		let { data, error } = getAllAvailableRiders({
			coOrdinates: {
				latitude: formData?.coOrdinates?.latitude,
				longitude: formData?.coOrdinates?.longitude,
			},
			areaId: formData?.area?._id,
			serviceDate: moment(formData?.date).format('YYYY-MM-DD'),
			duration: "sixty",
			timeslot: formData?.solt?.timeslot,
			serviceId: [formData?.selectService?._id]
		});
		if (error) {
			setToast({ type: "error", msg: error?.data?.message ? error?.data?.message : 'Error while fetching Riders' });
		}
	}

	const maxDate = moment().add(6, "days").toDate();
	const SelectSlot = () => {
		const { setFieldValue, values, errors, touched } = useFormikContext();
		useEffect(() => {
			if(values?.date === dateRef.current) return
			setFieldValue("solt", "");
			setFieldValue("rider", "");
			dateRef.current = values?.date
			getAvailableTimeSlots(values);
		}, [values.date]);
		
		useEffect(() => {
			if(values?.solt) {
				if(values?.solt === soltRef.current) return
				soltRef.current = values?.solt
				getAvailableRiders(values)
			}
			if(values?.rider)
				setFieldValue("rider", "");
		}, [values?.solt])
		return (
			<div className="flex justify-center">
				<div className="px-6 pt-6 w-[50vw] mb-4 pb-2 flex items-center flex-col rounded-xl border space-y-3 bg-[#F9FAFB]">
					<div className="text-lg font-inter-bold">5. Select Slot</div>
					<div className="flex gap-3 items-center w-full">
						<div className="w-full">
							<div className="mb-2 block font-inter-medium text-sm">Available Slot</div>
							<div className="flex justify-between gap-5">
								<div className="slot-date-picker mt-1">
									<Calender name="date" maxDate={maxDate} />
									{errors.date && touched.date ? (
										<div className="error text-red-600 text-sm">{errors?.date}</div>
									) : (
										<div className="invisible h-5"></div>
									)}
								</div>
								<div className="w-1/2">
									<SelectMenu
										name="solt"
										// options={getSlots(values)}
										options={allSlots}
										onChange={(option) => setFieldValue("solt", option)}
										value={values.solt}
										placeholder="Select Slot"
										icon={""}
										isLoading={timeSlotLoading}
										hideSelectedOptions={false}
									/>
									{errors.solt && touched.solt ? (
										<div className="error text-red-600 text-sm">{errors?.solt}</div>
									) : (
										<div className="invisible h-5"></div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="w-full">
						<SelectMenu
							label="Select Rider"
							name="rider"
							options={allRiders}
							onChange={(option) => setFieldValue("rider", option)}
							value={values.rider}
							placeholder="Select Rider"
							getOptionValue={(option) => option._id}
							customeClassName="font-inter-medium"
							disabled={!(values?.date && values?.solt)}
							isLoading={getRidersLoading}
						/>
						{errors.rider && touched.rider ? (
							<div className="error text-red-600 text-sm">{errors?.rider}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>
			</div>
		);
	};
	const PaymentMethod = ({ values }) => {
		const { setFieldValue } = useFormikContext();
		return (
			<div className="flex justify-center">
				<div className="px-6 pb-2 pt-6 w-[27vw] flex items-center flex-col rounded-xl border bg-[#F9FAFB]">
					<div className="text-lg font-inter-bold">6. Payment Method</div>
					<div className="flex items-center justify-center w-full gap-5 font-normal text-sm mt-6 payment-container">
						<div className="py-2 px-4 w-1/3 bg-white rounded-lg ">
							<label className="flex items-center gap-2">
								<Field
									type="radio"
									name="paymentMode"
									className="mr-2 mt-1.5 appearance-none items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent md:mt-0 sm:mt-0"
									value="online"
								/>
								<span className="text-[#757575]">Online</span>
							</label>
						</div>
						<div className="py-2 px-4 w-1/3 bg-white rounded-lg ">
							<label className="flex items-center gap-2">
								<Field
									type="radio"
									name="paymentMode"
									className="mr-2 mt-1.5 appearance-none items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent md:mt-0 sm:mt-0"
									value="cash"
									disabled={isSubmittedOnlinePaymentMethod}
								/>
								<span className="text-[#757575]">COD</span>
							</label>
						</div>
					</div>

					<div className="w-full text-sm font-medium pb-5">
					      <div className="flex justify-between w-full text-lg font-semibold mt-12 mb-4">
									<div className="">Total Amount</div>
									<div>{handleCalculatePrice(values, setFieldValue)}</div>
								</div>
								<hr className="border border-gray-300 w-full" />
						{values.paymentMode === "cash" ? (
							<>
								<div className="text-[#757D8A] mt-2">Customer has to pay in cash</div>
								<div className="mt-4 text-[#030303] font-semibold">
									Please collect the total amount from the customer in cash
								</div>
							</>
						) : !isSubmittedOnlinePaymentMethod ? (
							<>
								<hr className="border border-gray-300 w-full" />
								<div className="text-[#757D8A] mt-2">
									A payment link will be sent to the customer’s registered mobile number
								</div>
								<div className="mt-4">Please wait until the payment is completed and processed</div>
								
							</>
						) : (
							<div className="mt-3">
								<PaymentStatusSpinner status={paymentStatus} />
								<div className="mt-4 text-[#030303] font-semibold text-center">{paymentStatus=== "success" ? "Payment has been successfully completed!" : paymentStatus=== "failed" ? "Payment has been failed please try again later!" : "Payment is being processed"}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 10,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage:
					"linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage:
					"linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
			borderRadius: 1,
		},
	}));
	const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
		zIndex: 1,
		color: "#fff",
		// width: 30,
		// height: 30,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
		// ...(ownerState.active && {
		//  backgroundImage:
		//    "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
		//  boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
		// }),
		// ...(ownerState.completed && {
		//  backgroundImage:
		//    "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
		// }),
	}));
	function ColorlibStepIcon(props) {
		const { active, completed, className, icon } = props;
		// const icons = {
		//  1: <img src={steperIcon} alt="" />,
		//  2: <img src={steperIcon} alt="" />,
		//  3: <img src={steperIcon} alt="" />,
		//  4: <img src={steperIcon} alt="" />,
		//  5: <img src={steperIcon} alt="" />,
		//  6: <img src={steperIcon} alt="" />,
		// };
		// const activeIcons = {
		//  1: <img src={checkedIcon} alt="" />,
		//  2: <img src={checkedIcon} alt="" />,
		//  3: <img src={checkedIcon} alt="" />,
		//  4: <img src={checkedIcon} alt="" />,
		//  5: <img src={checkedIcon} alt="" />,
		//  6: <img src={checkedIcon} alt="" />,
		// };
		// const displayIcon = completed ? activeIcons[icon] : icons[icon];
		const icons = <img src={steperIcon} alt="" className="opacity-25 " />;
		const activeIcons = <img src={steperIcon} alt="" />;
		const completedIcons = <img src={checkedIcon} alt="" />;
		const displayIcon = completed ? completedIcons : active ? activeIcons : icons;
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
				{displayIcon}
			</ColorlibStepIconRoot>
		);
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="bg-[#F5F5F5] h-full overflow-hidden">
			<div className="p-8">
				<div className="flex gap-3 mb-8">
					<BackButton />
					<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
						Add New Appointment
					</h3>
				</div>
				<div className="bg-white rounded-[20px] brand-details-section 2xl:pt-4 pb-6 h-[43vw] 2xl:h-[41vw] 3xl:h-[38vw] stepper-sm">
					<Formik
						initialValues={{
							customerEmail: "",
							customerPhoneNumber: "",
							customerName: "",
							customerCity: "",
							line1: "",
							line2: "",
							landMark: "",
							area: "",
							city: "",
							pincode: "",
							paymentMode: "cash",
							serviceType: "service",
							addressType: "home",
							selectService: "",
							date: new Date(),
							searchedPlace:""
						}}
						validationSchema={() => {
							switch (currentStep) {
								case 0:
									return step1Schema;
								case 1:
									return step2Schema;
								case 2:
									return step3Schema;
								case 3:
									return step4Schema;
								case 4:
									return step5Schema;
								case 5:
									return step6Schema;
								default:
									return Yup.object().shape({});
							}
						}}
						onSubmit={(values) => {}}
					>
						{({ isSubmitting, values, setFieldValue, errors, setErrors, setTouched }) => (
							<Form className="space-y-3" action="#" method="POST">
								<Stack sx={{ width: "100%", paddingTop: "30px" }} spacing={4}>
									<Stepper
										alternativeLabel
										activeStep={currentStep}
										connector={<ColorlibConnector />}
									>
										{steps.map((label) => (
											<Step key={label.title}>
												<StepLabel StepIconComponent={ColorlibStepIcon}>{label.title}</StepLabel>
											</Step>
										))}
									</Stepper>
								</Stack>
								<div>
									{currentStep === 0 && <PrimaryInformation />}
									{currentStep === 1 && <VehicleDetails />}
									{currentStep === 2 && <AddressDetails vehicleAddress={vehicleAddress} />}
									{currentStep === 3 && <ServiceDetails />}
									{currentStep === 4 && <SelectSlot />}
									{currentStep === 5 && <PaymentMethod values={values} />}
								</div>
								<div className="w-[45vw] mx-auto px-16 flex justify-center gap-5">
									<Button
										onClick={prevStep}
										disabled={ isSubmittedOnlinePaymentMethod}
										className={
											`w-full border text-[#1A1A1A] border-black bg-white bottom-btn-ht font-inter-bold ${isSubmittedOnlinePaymentMethod && "opacity-25" }`
										}
									>
										Previous
									</Button>
									<Button
										onClick={() => nextStep(values, setFieldValue, setErrors, setTouched)}
										disabled={currentStep === steps.length || isSubmittedOnlinePaymentMethod}
										className={`w-full border text-[#FFF] bg-black bottom-btn-ht font-inter-bold ${isSubmittedOnlinePaymentMethod && "opacity-25" }`}
										isSubmitting={status === "pending" || addCustomerStatus === "pending"|| vehicleVerificationStatus === "pending" || vehicleAddressStatus === "pending"}
									>
										{currentStep === 5 ? "Submit" : "Next"}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
export default NewServiceRequest;
