import React, { useEffect, useRef, useState } from 'react'
import { CloseCircle, LocationTick, LogoutCurve, TickCircle, Location, LocationCross } from "iconsax-react";
import RiSpeedUpLine from "../../assets/icons/ri_slow-down-line.svg";
import TemporaryUser from '../../assets/icons/temporary-user.svg';
import SelectMenuIcon from '../../shared/components/SelectMenuIcon.component';
import { useLazyGetRiderNotificationsQuery, useLazyGetRidersByStatusQuery, useLazyGetRidersCountByStatusQuery, useLazyGetTotalRidersQuery, useLazyGetRidersDistanceQuery, useLazyGetRiderDetailsQuery, useLazyGetCitesQuery, useGetAreaListMutation } from "../../utils/Store";
import TableInfiniteScroll from '../../shared/components/TableInfiniteScroll';
import { Tab } from "@headlessui/react";
import moment from 'moment';
import pdfDonloadIcon from '../../assets/icons/download-pdf.svg';
import xlsDownloadIcon from '../../assets/icons/download-xls.svg'
import Avatar from 'react-avatar';
import { useNavigate } from "react-router";
import { generatePDF } from '../../utils/helper/generatePdf';
import useToaster from '../../utils/hooks/useToaster'
import { generateExcel } from '../../utils/helper/generateExcel';
import MapRiderStatus from './Map_Rider_Status';
import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_SOCKET_URL)

function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function RiderManagement() {
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const isLoadingRef = useRef(false);
    const [ridersData, setRidersData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState('onJob');
    const [getNotifications, { data: notifications }] = useLazyGetRiderNotificationsQuery();
    const [getRidersByStatus, {data: ridersStatusData}] = useLazyGetRidersByStatusQuery();
    const [getRidersCountByStatus, { data: ridersCountByStatusData }] = useLazyGetRidersCountByStatusQuery();
    const [ridersCountByStatus, setRidersCountByStatus] = useState();
    const [getTotalRiders, { data: totalRidersCountData }] = useLazyGetTotalRidersQuery();
    const [totalRidersCount, setTotalRidersCount] = useState(0)
    const [getRidersDistance, { data: totalRidersDistance }] = useLazyGetRidersDistanceQuery();
    const navigate = useNavigate();
    const [getRiderDetails] = useLazyGetRiderDetailsQuery()
    const { setToast } = useToaster();
    const [getCites, { data: cities }] = useLazyGetCitesQuery();
    const [getAreas, { data: areaDetails }] = useGetAreaListMutation();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedArea, setSelectedArea] = useState();
    const [allSelectedCityAreas, setAllSelectedCityAreas] = useState([]);

    const ridersStatus = [
        {
            title: "On Job",
            status: 'onJob',
            icon: <TickCircle color="#FFFFFF" className="w-5 h-5 2xl:w-6 2xl:h-6" />,
            riderCount: ridersCountByStatus?.onJob
        },
        {
            title: "Online",
            status: 'online',
            icon: <LocationTick color="#FFFFFF" className="w-5 h-5 2xl:w-7 2xl:h-7" />,
            riderCount: ridersCountByStatus?.online
        },
        {
            title: "Offline",
            status: 'offline',
            icon: <CloseCircle color="#FFFFFF" className="w-5 h-5 2xl:w-6 2xl:h-6" />,
            riderCount: ridersCountByStatus?.offline,
        },
        {
            title: "Out Of Radius",
            status: 'outOfRadius',
            icon: <LocationCross color="#FFFFFF" className="w-5 h-5 2xl:w-6 2xl:h-6" />,
            riderCount: ridersCountByStatus?.outOfRadius,
        },
        {
            title: "On Leave",
            status: 'onLeave',
            icon: <LogoutCurve color="#FFFFFF" className="w-5 h-5 2xl:w-6 2xl:h-6" />,
            riderCount: 0,
        },
        {
            title: "Absent",
            status: 'absent',
            icon: <img src={TemporaryUser} color="#FFFFFF" className="w-5 h-5 2xl:w-6 2xl:h-6" alt='' />,
            riderCount: 0,
        },
    ]


    const fetchInitialData = async () => {
        isLoadingRef.current = true;
        const { data, error } = await getRidersByStatus({
            riderStatus:  selectedStatus,
            params: { page: 1 },
        });
        if (data) {
            setRidersData(data.riders);
            setCurrentPage(2);
            setHasMoreRecords(data.riders.length > 0);
            isLoadingRef.current = false;
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
            isLoadingRef.current = false;
        }
    };
    const fetchMoreData = async () => {
        const { data, error } = await getRidersByStatus({
            riderStatus: selectedStatus,
            params: { page: currentPage },
        });
        if (data) {
            setRidersData((prev) => [...prev, ...data.riders]);
            setCurrentPage((prev) => prev + 1);
            setHasMoreRecords(data.riders.length > 0);
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
        }
    };


    useEffect(()=>{
        fetchInitialData()
    },[ridersCountByStatus])

    const handleGetCityAreas = async () => {
        try {
            const { data } = await getAreas({
                cityId: [selectedCity?._id]
            });
            setAllSelectedCityAreas(data);
        } catch (error) {
            console.error("Error fetching areas:", error);
            setAllSelectedCityAreas([]); // Reset areas to an empty array on error
        }
    };

    const handleCityChange = (option) => {
        setSelectedCity(option);
        setSelectedArea(null);
    };
    const handleAreaChange = (option) => {
        setSelectedArea(option);

    };

    useEffect(() => {
        fetchInitialData();
    }, [selectedStatus]);

    useEffect(() => {
        getRidersCountByStatus();
        getNotifications();
        getCites();
        getTotalRiders();
        getRidersDistance();
        socket.on('totalRidersCount', (data) => {
          setTotalRidersCount(data)
        });
        socket.on('riderUpdatedLocation', (data) => {
        });
        socket.on('ridercount', (data) => {
          setRidersCountByStatus(data)
        });
        return () => {
          socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if(ridersCountByStatusData) setRidersCountByStatus(ridersCountByStatusData)
    }, [ridersCountByStatusData])

    useEffect(() => {
        if(totalRidersCountData) setTotalRidersCount(totalRidersCountData)
    }, [totalRidersCountData]);

    useEffect(() => {
        if (cities && cities.length > 0) {
            setSelectedCity(cities[0]);
        }
    }, [cities]);

    useEffect(() => {
        const id = setTimeout(() => {
            let areaIds = []
            if (selectedArea?.length) {
                areaIds = selectedArea?.map(area => area?._id)
            }
            if (selectedCity && !selectedArea?.length) {
                areaIds = areaDetails?.map(area => area?._id)
            }
        }, 350)
        return () => {
            clearTimeout(id)
        }
    }, [selectedArea, selectedCity, areaDetails])

    useEffect(() => {
        if (selectedCity) {
            handleGetCityAreas()
        } else {
            setAllSelectedCityAreas([]);
            setSelectedArea(null);
        }
    }, [selectedCity]);

    const handleGeneratePdf = async (riderData) => {
        const { data, error } = await getRiderDetails(riderData?._id)
        if (data) {
            setToast({ type: "success", msg: "Report is being downloaded" });
            generatePDF(data, riderData?.name)
        }
        if (error) setToast({ type: "error", msg: error?.data?.message });
    }
    const handleGenerateExcel = async (riderData) => {
        const { data, error } = await getRiderDetails(riderData?._id)
        if (data) {
            setToast({ type: "success", msg: "Report is being downloaded" });
            generateExcel(data, riderData?.name)
        }
        if (error) setToast({ type: "error", msg: error?.data?.message });
    }

    const totalDistance = totalRidersDistance?.totalDistance || 0;

    const displayDistance = () => {
        let distanceToShow = totalDistance;
        let unit = totalDistance >= 1000 ? 'KM' : 'M';

        if (totalDistance >= 1000) {
            distanceToShow = parseInt(totalDistance / 1000);
        }
        return `${distanceToShow} ${unit}`;
    };



    const columns = [
        {
            header: "Rider Name",
            accessorKey: "vehicleNumber",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span
                        className="relative overflow-x-auto flex items-center cursor-pointer"
                        onClick={() => navigate(`/appointment-management/${original?._id}`)}
                    >
                        <span className='mr-3'>
                            {
                                original?.profileImageURL ? <span className='block w-8 h-8 rounded-full overflow-hidden'><img className='w-full h-full' src={original?.profileImageURL} alt="" /></span> : <Avatar
                                    name={original.name}
                                    size="35"
                                    round={true}
                                    color="#F1F1F1"
                                    fgColor="#000"
                                    textSizeRatio="1.5"
                                />
                            }
                        </span>
                        <span className='w-[80%] overflow-x-auto whitespace-nowrap final-price-scrollbar'> {original?.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Yesterday's Services",
            accessorKey: "yesterdayservice",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span
                        className="relative overflow-x-auto mx-auto"
                    >
                        {original?.yesterdayServices}
                    </span>
                );
            }
        },
        {
            header: "Today's Services",
            accessorKey: "todayservices",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span
                        className="relative overflow-x-auto mx-auto"
                    >
                        {original?.todayServices}
                    </span>
                );
            }
        },
        {
            header: "View Report",
            accessorKey: "report",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-2 mx-auto">
                        <img
                            src={pdfDonloadIcon}
                            alt=""
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => handleGeneratePdf(original)}
                        />
                        <img
                            src={xlsDownloadIcon}
                            alt=""
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => handleGenerateExcel(original)}
                        />
                    </span>
                );
            },
        },
    ]

    return (
        <div className='bg-[#F5F5F5] h-full relative py-3 '>
            <div className="px-8 pb-4 pt-4 flex items-center">
                <h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                    Dashboard
                </h1>
                <div className="flex ">
                    <div className="pl-8">
                        <h1 className="bg-[#FFF] text-[#000] font-inter-bold p-2.5 rounded-[6px]">Total Riders ({totalRidersCount?.totalRiders})</h1>
                    </div>
                    <div className="pl-8">
                        <h1 className="bg-[#FFF] text-[#000] font-inter-bold p-2.5 rounded-[6px]">Total Distance Covered by All Riders - {displayDistance()}</h1>
                    </div>
                </div>
            </div>
            <div className='flex'>
                <div className="rider-status-width 2xl:w-[25.8%] xl:w-[26.7%] 3xl:w-[22.7%] h-full pr-6">
                    <Tab.Group
                        as={"div"}
                        className="w-[100%] pr-10"
                        onChange={(index) => {
                            setSelectedStatus(ridersStatus[index].status)
                        }}
                    >
                        <Tab.List>
                            {ridersStatus.map((statusCard, index) => (
                                <Tab
                                    key={index}
                                    className={({ selected }) =>
                                        classNames(
                                            'h-[3.5rem] w-full bg-white ml-8 mt-3 2xl:ml-8 2xl:mt-3 2xl:rounded-2xl rounded-xl flex items-center justify-start pl-3 py-2 cursor-pointer',
                                            'focus:outline-none',
                                            selected
                                                ? `border ${statusCard?.status === 'onJob' ? 'border-[#315EFF]' : statusCard?.status === 'online' ? 'border-[#00BE68]' : statusCard?.status === 'offline' ? 'border-[#FB0C0C]' : statusCard?.status === 'outOfRadius' ? 'border-[#37474F]' : statusCard?.status === 'onLeave' ? 'border-[#FF621F]' : statusCard?.status === 'absent' ? 'border-[#FFC24C]' : ''}`
                                                : ''
                                        )
                                    }
                                >
                                    <div className={`h-10 w-10 rounded-full flex items-center justify-center ${statusCard?.status === 'onJob' ? 'bg-[#315EFF]' : statusCard?.status === 'online' ? 'bg-[#00BE68]' : statusCard?.status === 'offline' ? 'bg-[#FB0C0C]' : statusCard?.status === 'outOfRadius' ? 'bg-[#37474F]' : statusCard?.status === 'onLeave' ? 'bg-[#FF621F]' : statusCard?.status === 'absent' ? 'bg-[#FFC24C]' : ''}`}>
                                        {statusCard?.icon}
                                    </div>
                                    <div className="pl-4 flex justify-center items-center">
                                        <h1 className="text-base font-inter-regular text-[#000]">{statusCard?.title}</h1>
                                        <h1 className="text-xl font-semibold font-plus-jakarta text-[#000] px-4">
                                            {statusCard?.riderCount}
                                        </h1>
                                    </div>
                                </Tab>
                            ))}
                        </Tab.List>
                    </Tab.Group>
                </div>
                <div className="rider-table-width 2xl:w-[48%] xl:w-[47%] 3xl:w-[51%] h-[24.75rem] mt-3 bg-[#FFF] 2xl:rounded-[20px] rounded-[14px] overflow-hidden pr-3">
                    <TableInfiniteScroll
                        hasMore={hasMoreRecords}
                        next={fetchMoreData}
                        height="20rem"
                        scrollThreshold={0.8}
                        dataLength={ridersData?.length}
                        data={ridersData}
                        columns={columns}
                        customwidths={["w-[33%]", "w-[27.5%]", "w-[22%]", "w-[17.5%]"]}
                        customeHeight="20rem"
                        customeClass="pl-3"
                    />
                </div>
            </div>
            <div className='flex justify-start pl-8 pb-4 pt-8 z-[40] relative'>
                <div className="flex items-center space-x-1">
                    <div className="">
                        <h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
                    </div>
                    <div className="w-[13rem] cm-air-width relative flex items-center cm-air-ht">
                        <SelectMenuIcon
                            placeholder='All Cities'
                            options={cities}
                            multiSelectClass="search-customer-height multi-time-slot bg-color-white width-size "
                            isMulti={false}
                            onChange={handleCityChange}
                            value={selectedCity}
                        />
                        <Location className="h-5 w-5 absolute 2xl:left-[0.75vw] xl:left-[2.75vw] left-[2.5vw] top-[50%] -translate-y-[50%] location-icon-size-ht" />
                    </div>
                </div>
                <div className="flex items-center 2xl:ml-6 ml-[4.5rem] space-x-1 ">
                    <div className="">
                        <h1 className="text-base text-[#333333] font-normal">Filter by Area:</h1>
                    </div>
                    <div className="w-[13rem] relative flex items-center cm-air-ht">
                        <SelectMenuIcon
                            placeholder='All Areas'
                            options={allSelectedCityAreas}
                            multiSelectClass="search-customer-height multi-time-slot bg-color-white width-size"
                            isMulti={true}
                            onChange={handleAreaChange}
                            value={selectedArea}
                        />
                        <Location className="h-5 w-5 absolute 2xl:left-[0.75vw] xl:left-[2.75vw] left-[2.5vw] top-[50%] -translate-y-[50%] location-icon-size-ht" />
                    </div>
                </div>
            </div>
            <div className='h-[20vh] w-full'>
                <MapRiderStatus areaList={selectedArea?.length ? selectedArea :allSelectedCityAreas} selectedCity={selectedCity}/>
            </div>
            <div className="w-[25%] absolute inset-y-0 top-[5.5rem] right-0 h-full flex">
                <div className="w-0.5 bg-gray-300 h-[750px] mt-6"></div>

                <div className='overflow-y-auto px-4 h-[80vh] mt-3'>
                    {notifications?.map((e) => (
                        <div className="h-auto bg-white mx-auto mb-4 rounded-2xl flex items-center justify-start pl-3 py-2">
                            <div className="bg-[#FFC24C] h-10 w-10 rounded-full flex items-center justify-center self-start">
                                <img src={RiSpeedUpLine} color="#FFFFFF" className="w-5 h-5 2xl:w-6 2xl:h-6" />
                            </div>
                            <div className="pl-2 w-[80%]">
                                <div className="text-base font-inter-regular text-[#000]">{e.notificationBody}</div>
                                <ul>
                                    <li className="text-[#898989] bullet font-jakarta">{moment(e.created).fromNow()}</li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RiderManagement
