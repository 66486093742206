import React from "react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import { useNavigate } from "react-router-dom";
import useToaster from "../../utils/hooks/useToaster";
import DeleteIcon from "../../assets/icons/modal-delete-icon.svg";
import { useDeleteCustomerMutation, useGetCustomerListMutation, useDeleteAddressMutation } from "../../utils/Services/Apis/customerManagementApi";

function CustomerDeleteModal({ data }) {
	const { closeModal, openModal } = useSelectModal();
	const { setToast } = useToaster();
	const [deleteCustomer,{deleteCustomerStatus}] = useDeleteCustomerMutation();
	const [deleteAddress,{deleteAddressStatus}] = useDeleteAddressMutation();

	const handleCustomerDelete = async () => {
		deleteCustomer(data?._id)
			.then(({ data: d }) => {
				setToast({ type: "success", msg: d.message });
				data?.fetchCustomers();
			})
			.catch((error) => {
				setToast({ type: "error", msg: error.data.message });
			});
		closeModal();
	};

	const handleAddressDelete = async () => {
		deleteAddress(data?._id)
			.then(({ data: d }) => {
				setToast({ type: "success", msg: d.message });
				data?.fetchAddress();
			})
			.catch((error) => {
				setToast({ type: "error", msg: error.data.message });
			});
		closeModal();
	};

	return (
		<div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
			<div className="flex items-center justify-center">
				<div className=" bg-[#E5E5E5] h-2/12 w-2/12 rounded-full flex justify-center items-center ">
					<img src={DeleteIcon} alt=""/>
				</div>
			</div>
			{ data?.name &&
				<div className="flex font-inter-medium justify-center pt-4 text-lg text-center">
					Are you sure you want to delete this<br /> Customer Profile “{data?.name}” ?{" "}
				</div>
			}
			{ data?.areaId &&
				<div className="flex font-inter-medium justify-center pt-4 text-lg text-center">
					Are you sure you want to delete this<br /> Vehicle Address “{data?.addressLineOne}” ?{" "}
				</div>
			}
			<div className="pb-6 pt-0.5 text-center">
				<span className="text-[#87898E] text-center text-sm">This action can’t be undone</span>
			</div>
			<div className="flex justify-between ">
				<Button
					className="w-[47.5%] bg-[#F1F1F1] text-[#23262F] cursor-pointer"
					onClick={closeModal}
				>
					Cancel
				</Button>
				{ data?.name &&
					<Button
						className="w-[47.5%] text-white cursor-pointer"
						type="submit"
						onClick={handleCustomerDelete}
						isSubmitting={deleteCustomerStatus==="pending"}
					>
						Delete
					</Button>
				}
				{ data?.areaId &&
					<Button
						className="w-[47.5%] text-white cursor-pointer"
						type="submit"
						onClick={handleAddressDelete}
						isSubmitting={deleteAddressStatus==="pending"}
					>
						Delete
					</Button>
				}
			</div>
		</div>
	);
}

export default CustomerDeleteModal;

