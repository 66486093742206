import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import UploadImgIcon from "../../assets/icons/Upload-image.png";
import Button from "./Button.component";
import useToaster from "../../utils/hooks/useToaster";
import { useRef } from "react";
import { BiLoaderAlt } from "react-icons/bi";

function ImageUploadFieldWithoutPreview({
	bottomText,
	instructions,
	name = "photo",
	isUploadImgBtnDisabled = false,
	multiple = false,
	placeholderImg = UploadImgIcon,
	imgFormat = "image/jpg, image/jpeg, image/png",
	fileSize, // in KB
	acceptableHeight, // in pixel
	acceptableWidth,
	customClass,
	uploadImgStatus,
	nodeClickRef,
}) {
	const checkImageDimensions = (fileData) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = fileData;
			img.onload = () => {
				if (
					acceptableHeight &&
					acceptableWidth &&
					img.width !== acceptableWidth &&
					img.height !== acceptableHeight
				) {
					reject(`The image is NOT ${acceptableWidth}x${acceptableHeight} pixels.`);
				} else {
					resolve();
				}
			};
			img.onerror = () => {
				reject("Error loading the image.");
			};
		});
	};

	const [fileInputValue, setFileInputValue] = useState(""); // Initialize the state here
	const { setFieldValue, values } = useFormikContext();
	const nameRef = useRef(null);
	const { setToast } = useToaster();
	useEffect(() => {
		if (values[name]) {
			if (values[name] instanceof FileList) {
				const reader = new FileReader();
				reader.readAsDataURL(values[name][0]);
				reader.onloadend = () => {};
			} else {
			}
		}
	}, []);
	const handleButtonClick = async () => {
		setFieldValue(name, "");
		document.getElementById(name).click();
	};
	const handleFileChange = (event, form) => {
		const files = event.target.files;
		let newPreviewImages = [];

		const processFile = (file, index, totalFiles) => {
			if (imgFormat && !imgFormat.includes(file.type)) {
				setToast({
					type: "error",
					msg: `File format is not allowed`,
				});
				setFieldValue(name, "");
				return;
			}
			if (fileSize && file.size > fileSize * 1024) {
				setToast({
					type: "error",
					msg: `File size exceeds the ${fileSize} KB limit.`,
				});
				setFieldValue(name, "");
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onloadend = async (e) => {
				try {
					acceptableHeight && acceptableWidth && (await checkImageDimensions(e.target.result));

					newPreviewImages.push(reader.result);
					if (newPreviewImages.length === totalFiles) {
						form.setFieldValue(name, files);
						nameRef.current = name;
					}
				} catch (error) {
					setToast({
						type: "error",
						msg: error,
					});
				}
			};
		};
		for (let i = 0; i < files.length; i++) {
			processFile(files[i], i, files.length);
		}
	};

	return (
		<div className="space-y-3">
			<div
				className={`h-40 w-full border bg-white rounded-lg flex justify-center items-center ${customClass}`}
			>
				<Field name={name} type="file">
					{({ field, form }) => (
						<div>
							<div className="flex flex-col items-center gap-2">
								<img src={placeholderImg} alt="Upload" className="mx-auto h-10 w-10" />
								<div className="text-gray-400 text-sm">{bottomText}</div>
							</div>
							<input
								type="file"
								id={name}
								style={{ display: "none" }}
								onChange={(event) => {
									handleFileChange(event, form);
									setFileInputValue(event.target.value);
								}}
								accept={imgFormat}
								multiple={multiple}
							/>
						</div>
					)}
				</Field>
			</div>
			<div className="text-[#757575] font-normal text-xs ">{instructions}</div>
			<Button
				className={`w-full  bg-white text-black border-black cursor-pointer h-[2.3rem] ${
					!isUploadImgBtnDisabled ? "cursor-pointer" : "cursor-not-allowed "
				}`}
				onClick={() => {
					if (nodeClickRef) nodeClickRef.current = name;
					!isUploadImgBtnDisabled && handleButtonClick();
				}}
			>
				{uploadImgStatus === "pending" && nodeClickRef?.current === name ? (
					<BiLoaderAlt className="animate-spin text-xl"></BiLoaderAlt>
				) : (
					"Upload"
				)}
			</Button>
		</div>
	);
}
export default React.memo(ImageUploadFieldWithoutPreview);
