import React, { useState } from 'react'
import ReactFlagsSelect from 'react-flags-select';
import FormField from './FormField.component';

export default function Mobile({name, errors, touched, placeholder}) {
  const [selected, setSelected] = useState("IN");
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Mobile Number
      </label>
      <div className="flex">
        <ReactFlagsSelect
          selected={selected}
          showOptionLabel={false}
          disabled={true}
          showSelectedLabel={false}
          placeholder="+91"
        />
        <div className="w-full">
          <FormField
            hideError={true}
            nonFormikError
            field={{
              // label: "Mobile Number",
              name: name,
              type: "text",
              placeholder: placeholder,
              // readOnly: values.search,
              onKeyPress: (event) => {
                if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
                  return event.preventDefault();
                }
              },
            }}
          />
        </div>
      </div>
      {errors[name] && touched[name] ? (
        <div className="error text-red-600 text-sm -mt-[5px]">{errors[name]}</div>
      ) : (
        <div className="invisible h-5 -mt-[5px]"></div>
      )}
    </div>
  )
}
