import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assets/icons/Delete.png";
import EditIcon from "../../assets/icons/Edit.png";
import InfoIcon from "../../assets/icons/information.png";
import DownloadCars from "../../assets/images/cars/download_cars.png";
import UploadCars from "../../assets/images/cars/upload_cars.png";

import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useNavigate } from "react-router-dom";
import {
	useDeleteBrandsMutation,
	useLazyGetBrandsQuery,
	useUpdateCarBrandMutation,
} from "../../utils/Services/Apis/carApi";
import { CgSoftwareDownload, CgSoftwareUpload } from "react-icons/cg";
import { InfoCircle } from "iconsax-react";

export default function CarManagement() {
	const { openModal } = useSelectModal();
	const navigate = useNavigate();
	let [getBrands, {data: allBrands, status: allBrandsStatus}] = useLazyGetBrandsQuery();
	const [deleteBrand] = useDeleteBrandsMutation();
	const [updateBrand] = useUpdateCarBrandMutation();
	const [brandData, setBrandData] = useState([]);

	useEffect(() => {
		if (allBrands) setBrandData(allBrands);
	}, [allBrands]);

	const handleEdit = async (brandData) => {
		openModal("AddBrandModal", brandData);
	};

	const handleInfo = (rowData) => {
		navigate(`/carlist-page/${rowData._id}`, {
			state: { brandData: rowData },
		});
	};
	const handleDelete = async (rowData) => {
		openModal("DeleteBrandModal", { ...rowData, hideCloseArrow: true });
	};

	const columns = [
		{
			header: "Brand Logo",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 w-7 h-7">
						<img
							src={original.logo}
							alt=""
							onClick={() => handleInfo(original, row)}
							className="h-full w-full object-cover"
						/>
					</span>
				);
			},
		},
		{
			header: "Brand Name",
			accessorKey: "name",
		},
		{
			header: "No. of Cars",
			accessorKey: "carModelCount",
		},

		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3.5 pl-3">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							onClick={() => handleInfo(original)}
							className="h-5 w-5 cursor-pointer"
						/>
						<img
							src={EditIcon}
							alt=""
							onClick={() => handleEdit(original)}
							className="h-5 w-5 cursor-pointer"
						/>
						<img
							src={DeleteIcon}
							alt=""
							onClick={() => handleDelete(original)}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

	useEffect(() => {
		getBrandsData();
	}, []);

	const getBrandsData = async () => {
		getBrands();
	};

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pt-8 pb-4">
					<div className="flex justify-between">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Brand Management</h3>
						<div className="flex gap-3">
							<Button
								className="bg-[#000] text-white cursor-not-allowed 2xl:px-8 px-6 2xl:text-base text-sm"
							>
								Download Sample Excel
								<img src={DownloadCars} color="white" className="pl-2.5 excel-btn-size" />
							</Button>
							<Button
								className="bg-[#000] text-white cursor-not-allowed 2xl:px-8 px-6 2xl:text-base text-sm"
							>
								Upload Excel
								<img src={UploadCars} color="white" className="pl-2.5 excel-btn-size" />
							</Button>
							<Button
								onClick={() => openModal("AddBrandModal")}
								className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
							>
								Add New Brand
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white border rounded-xl px-5 mx-8 my-3 h-[75vh] overflow-hidden brand-details-section">
				<TableComponent
					data={brandData}
					columns={columns}
					customwidths={["w-[30%]", "w-[25%]", "w-[30%]", "w-[15%]"]}
					customeClass="max-h-[66vh] pr-3"
					isLoading={allBrandsStatus === "pending"}
				/>
			</div>
		</div>
	);
}
