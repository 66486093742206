import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import CrossIcon from "../../../assets/icons/reject-icon.png"
import TableComponent from "../../../shared/components/Table.component";
import { SearchNormal1, Location, InfoCircle,} from "iconsax-react";
import "react-datepicker/dist/react-datepicker.css";
import SelectMenuIcon from "../../../shared/components/SelectMenuIcon.component";
import { useLazyGetCitesQuery } from "../../../utils/Store";
import { useNavigate } from "react-router-dom";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import AcceptIcon from "../../../assets/icons/accept.png"

function PendingFranchise() {

    const [getCites, { data: cities }] = useLazyGetCitesQuery();
    const [selectedCity, setSelectedCity] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();
    const { openModal } = useSelectModal();

    const allFranchiseData = [
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 3 Car Washing Center",
            email: 'franchiseThreecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 4",
            email: "franchiseFour@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 1 Car Washing Center",
            email: 'franchiseOnecarwashingcenter@gmail.com',
            city: "Vishakapatnam",
            area: "Vizagfverbfgvbgvvfcbvfb",
            mobileNumber: "+91 12345 67890",
        },
        {
            name: "Franchise 2",
            email: "franchiseTwo@gmail.com",
            city: "Vishakapatnamtyhjyukhjctgjhvkjnhmv",
            area: "Vizag",
            mobileNumber: "+91 12345 67890",
        },
    ];

    const columns = [
        {
            header: "Franchise Name",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center w-full">
                        <span className="truncate">{original?.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Email",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="">{original?.rating}
                        <span className=''>{original?.email}</span>
                    </p>
                )
            }
        },
        {
            header: "City",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="truncate">{original?.city}</p>
                )
            }
        },
        {
            header: "Area",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
                        {original?.area}
                    </span>
                );
            },
        },
        {
            header: "Mobile Number",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium text-base">
                        {original?.mobileNumber}
                    </span>
                );
            },
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center justify-center">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center gap-x-2 bg-[#59D956] rounded-[20px] px-2 py-1 ">
                                <h1 className="font-inter-medium text-sm text-[#FFF]"
                                onClick={() => openModal("ApproveFranchiseModal")}
                                >Approve</h1>
                                <img src={AcceptIcon} className=""/>
                            </div>
                        </div>
                        <img
                            src={CrossIcon}
                            alt="edit"
                            onClick={() => openModal("RejectFranchiseModal")}
                            className="h-5 w-5 cursor-pointer"
                        />
                        <InfoCircle
                            color="#0d6efd"
                            variant="Bold"
                            // onClick={() => openModal("")}
                            className="h-5 w-5 cursor-pointer"
                        />
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        getCites();
    }, []);

    const handleCityChange = (option) => {
        setSelectedCity(option);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        const sanitizedValue = value.replace(/[^\w\s]/gi, '');
        setInputValue(sanitizedValue);
    };

    return (
        <div className='bg-[#F5F5F5] h-full'>
            <div className="space-y-3">
                <div className="p-8">
                    <div className="flex justify-between">
                        <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Franchise Management &#62; Pending Franchise</h3>
                    </div>
                </div>
                <div className="bg-white p-5 mx-8 pb-4 h-[75vh] overflow-hidden brand-details-section bg-white-height franchise-table-height relative">
                    <div className="flex items-center justify-between">
                        <div className="relative 2xl:h-[3.2rem] h-[2.75rem] search-customer-height">
                            <input
                                type="text"
                                className="2xl:w-[22rem] xl:w-[16rem] w-[22rem] outline-focus h-full border-[#E9E9E9] rounded-xl py-1.5 pl-12 pr-3 text-base font-regular bg-[#F1F1F1] placeholder-black focus:outline-none focus:border-[#E9E9E9] focus:ring-transparent"
                                placeholder="Search Franchise"
                                value={inputValue}
                                onChange={handleChange}
                            />
                            <div className="absolute left-4 top-[50%] translate-y-[-50%]">
                                <SearchNormal1 color="gray" size={20} className="search-icon" />
                            </div>
                        </div>
                        <div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
                            <div className="">
                                <h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
                            </div>
                            <div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
                                <SelectMenuIcon
                                    placeholder='All Cities'
                                    options={cities}
                                    multiSelectClass="search-customer-height multi-time-slot"
                                    onChange={handleCityChange}
                                    value={selectedCity}
                                />
                                <Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full my-4">
                        <TableComponent
                            data={allFranchiseData}
                            columns={columns}
                            customwidths={["w-[15%]", "w-[25%]", "w-[15%]", "w-[15%]", "w-[15%]", "w-[15%]"]}
                            // isLoading={widgetsDataStatus === "pending"}
                            customeClass="franchise-management-table-ht pr-3"
                        />
                    </div>
                </div>
                {/* {widgetsData?.totalPages > 1 &&
                    <div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
                        <div className=''>
                            <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`}
                                disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
                        </div>
                        <div className=''>
                            <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == widgetsData?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`}
                                disabled={pageNo == widgetsData?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
                        </div>
                        <div className=''>
                            <p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {widgetsData?.totalPages}</p>
                        </div>
                    </div>
                } */}
            </div>
        </div>
    )
}

export default PendingFranchise
