import React from 'react'
import DeleteIcon from '../../../assets/icons/modal-delete-icon.svg'
import useSelectModal from '../../../utils/hooks/useSelectModal'
import Button from '../../../shared/components/Button.component'
import { useDeleteRadiusTypeMutation, useDeleteTimeSlotMutation } from '../../../utils/Services/Apis/areaMasterApi'
import useToaster from '../../../utils/hooks/useToaster'
import { useNavigate } from 'react-router-dom'

const DeleteMasterAreaModal = ({ data }) => {
	const { status, ...otherProps } = data;
	const { closeModal } = useSelectModal();
	const { setToast } = useToaster();
	const navigate = useNavigate();
	const [deleteRadiusType] = useDeleteRadiusTypeMutation();
	const [deleteTimeSlot] = useDeleteTimeSlotMutation();

	const handleDelete = () => {
		if(status === 'radius-type') {
			handleDeleteRadiusType();
		}
		if(status === 'time-slot') {
			handleDeleteTimeSlot();
		}
	}

	const handleDeleteRadiusType = async () => {
		const {data, error} = await deleteRadiusType(otherProps?.data?._id)
		if(data) {
			setToast({ type: "success", msg: `${otherProps?.data?.type} deleted successfully` });
		}
		if(error) {
			setToast({ type: "error", msg: error?.data?.message });
		};
		closeModal();
	};
	
	const handleDeleteTimeSlot = async () => {
		const {data, error} = await deleteTimeSlot(otherProps?.data?._id)
			if(data) {
				setToast({ type: "success", msg: `${otherProps?.data?.name} deleted successfully` });
			}
			if(error) {
				setToast({ type: "error", msg: error?.data?.message });
			};
		closeModal();
	};

	return (
		<div className="w-[30rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
			<div className="flex items-center justify-center">
				<div className=" bg-[#E5E5E5] h-2/12 w-2/12 rounded-full flex justify-center items-center ">
					<img src={DeleteIcon} />
				</div>
			</div>

			<div className="pb-8 text-center">
				<div className="flex font-inter-medium justify-center pt-4 text-2xl text-center text-[#23262F]">
					Are you sure you want to delete this {status === "time-slot" ? `Time Slot "${otherProps.data.name}"` : `Radius Type "${otherProps.data.type}"`}
				</div>
				<div className="text-[#87898E] text center w-full text-base">This action can't be undone.</div>
			</div>
			<div className="flex justify-between ">
				<Button className="w-[47.5%] bg-[#F1F1F1] text-[#000000] border-[#D0D0D0] font-inter-medium cursor-pointer" onClick={closeModal}>
					Cancel
				</Button>
				<Button className="w-[47.5%] text-white font-inter-medium cursor-pointer" type="submit" onClick={handleDelete}>
					Delete
				</Button>
			</div>
		</div>
	)
}

export default DeleteMasterAreaModal
