import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the CSS for styling
import backArrow from "../../assets/icons/back-arrow.png";
import { useState } from "react";

function CustomNav({ onSlide, direction }) {
	return (
		<button
			type="button"
			className={`image-gallery-custom-${direction} text-black border`}
			onClick={onSlide}
		>
			{direction === "left" ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<mask id="mask0_2652_15725" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
						<rect x="24" width="24" height="24" transform="rotate(90 24 0)" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_2652_15725)">
						<path
							d="M20 11L7.825 11L13.425 5.4L12 4L4 12L12 20L13.425 18.6L7.825 13L20 13L20 11Z"
							fill="#1C1B1F"
						/>
					</g>
				</svg>
			) : (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<mask id="mask0_7162_9020" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
						<rect y="24" width="24" height="24" transform="rotate(-90 0 24)" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_7162_9020)">
						<path
							d="M4 13L16.175 13L10.575 18.6L12 20L20 12L12 4L10.575 5.4L16.175 11L4 11L4 13Z"
							fill="#1C1B1F"
						/>
					</g>
				</svg>
			)}
		</button>
	);
}

export default function PreviewImages({ data }) {
	const images = data.map((image) => ({ original: image.imgUrl }));

	const [currentIndex, setCurrentIndex] = useState(0);

	const handleSlide = (currentIndex) => {
		setCurrentIndex(currentIndex);
	};

	return (
		<div className="pt-20 pb-10 px-60 ">
			<div className="flex justify-center mb-10 text-base font-inter-medium">{data[currentIndex].serviceName}</div>
			<div className="w-[45rem] rounded-2x preview-img-modal-width">
				<ImageGallery
					onSlide={handleSlide}
					items={images}
					showPlayButton={false}
					showIndex={false}
					renderLeftNav={(onClick, isDisabled) => {
						return <CustomNav direction="left" onSlide={() => onClick()} disabled={isDisabled} />;
					}}
					renderRightNav={(onClick, isDisabled) => {
						return <CustomNav direction="right" onSlide={() => onClick()} disabled={isDisabled} />;
					}}
					showFullscreenButton={false}
					// infinite={false}
				/>
			</div>
			<div className="mt-20 flex font-inter-bold justify-center 2xl:text-2xl text-base">
				{currentIndex + 1} of {images.length}
			</div>
		</div>
	);
}
