import React, { useEffect } from "react";
import Button from "../../shared/components/Button.component";
import EditIcon from "../../assets/icons/edit-icon.svg";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { useState } from "react";
import useToaster from "../../utils/hooks/useToaster";
import { useLazyGetConfigurationQuery, useUpdateConfigurationMutation } from "../../utils/Store";

function ConfigurationTable() {
	const { setToast } = useToaster();
	const [selectedTime1, setSelectedTime1] = useState(moment("8 AM", "h A"));
	const [selectedTime2, setSelectedTime2] = useState(moment(selectedTime1).add(1, "hour"));
	const [editIndex, setEditIndex] = useState(null);
	const [riderManagement, setRiderManagement] = useState("");
	const [serviceCost, setServiceCost] = useState("");
	const [beforeTime, setBeforetime] = useState("");
	const [googleDistance, setGoogleDistance] = useState("");
	const [googleMinute, setGoogleMinute] = useState("");

	const [getConfigurations, { data }] = useLazyGetConfigurationQuery();
	const [updateConfiguration, { status }] = useUpdateConfigurationMutation();

	const riderManagementModule = data?.find((element) => element.module === "riderManagement");
	const serviceCostModule = data?.find((element) => element.module === "serviceCost");
	const beforeTimeModule = data?.find((element) => element.module === "beforeTime");
	const defaultTimeModule = data?.find((element) => element.module === "defaultTime");
	const googleDistanceModule = data?.find((element) => element.module === "mapDistance");
	const googleMinuteModule = data?.find((element) => element.module === "mapMinutes");
	const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

	useEffect(() => {
		getConfigurations();
	}, []);

	useEffect(() => {
		if (data) {
			setRiderManagement(riderManagementModule?.value);
			setServiceCost(serviceCostModule?.value);
			setBeforetime(beforeTimeModule?.value);
			if (!defaultTimeModule) return;
			setSelectedTime1(moment(defaultTimeModule?.value?.split(" - ")[0], "h A"));
			setSelectedTime2(moment(defaultTimeModule?.value?.split(" - ")[1], "h A"));
		}
	}, [data]);

	const buttonClassNames =
		"2xl:text-base text-sm text-white cursor-pointer h-8 2xl:px-7 px-4 w-[calc(50px+2.698vw)]";
	const inputClassNames =
		"rounded-lg mr-3 border text-center border-[#CACACA] focus-visible:outline-none w-[3.2rem] py-0.5 2xl:text-base text-sm";

	useEffect(() => {
		// Check if selectedTime2 is less than or equal to 1 hour from selectedTime1
		if (selectedTime2.isSameOrBefore(moment(selectedTime1).add(1, "hour"))) {
			setSelectedTime2(moment(selectedTime1).add(1, "hour"));
		}
	}, [selectedTime1]);

	const updateConfigValues = async (module, value) => {
		if (+value < 1) return setToast({ type: "error", msg: "Value Should be More than Zero" });
		if (module === "defaultTime" && selectedTime2.isSameOrBefore(moment(selectedTime1)))
			return setToast({ type: "error", msg: "Please Select Appropriate Time" });
		const { data, error } = await updateConfiguration({
			module: module,
			value: value,
		});
		if (data) {
			setEditIndex(null);
			setToast({ type: "success", msg: data.message });
		}
		if (error) {
			setEditIndex(null);
			setToast({ type: "error", msg: error?.data?.message });
		}
	};

	const addressData = [
		{
			module: "Rider Management",
			description: (
				<div>
					When the rider location is not logged <br /> and treat that rider as offline
				</div>
			),
			value: (
				<div>
					{editIndex === 0 ? (
						<input
							className={inputClassNames}
							onChange={(e) => setRiderManagement(e.target.value)}
							value={riderManagement}
							onKeyDown={(event) => {
								if (!allowedKeys.includes(event.key) && /[^0-9]/.test(event.key)) {
									return event.preventDefault();
								}
							}}
						></input>
					) : (
						<span className="w-12 mr-2 text-center">{riderManagementModule?.value}</span>
					)}
					<span>Min</span>
				</div>
			),
			action:
				editIndex === 0 ? (
					<Button
						className={buttonClassNames}
						onClick={() => updateConfigValues("riderManagement", riderManagement)}
						isSubmitting={status === "pending"}
					>
						Save
					</Button>
				) : (
					<img
						src={EditIcon}
						alt=""
						onClick={() => setEditIndex(0)}
						className="h-5 w-5 cursor-pointer"
					/>
				),
		},
		{
			module: "Service Cost",
			description: (
				<div>
					Cost for per wash to be used in <br /> calculation of profit
				</div>
			),
			value: (
				<div>
					<div>
						{editIndex === 1 ? (
							<input
								className={inputClassNames}
								onChange={(e) => setServiceCost(e.target.value)}
								value={serviceCost}
								onKeyDown={(event) => {
									if (!allowedKeys.includes(event.key) && /[^0-9]/.test(event.key)) {
										return event.preventDefault();
									}
								}}
							></input>
						) : (
							<span className="w-12 mr-2 text-center">{serviceCostModule?.value}</span>
						)}
					</div>
				</div>
			),
			action:
				editIndex === 1 ? (
					<Button
						className={buttonClassNames}
						onClick={() => updateConfigValues("serviceCost", serviceCost)}
						isSubmitting={status === "pending"}
					>
						Save
					</Button>
				) : (
					<img
						src={EditIcon}
						alt=""
						onClick={() => setEditIndex(1)}
						className="h-5 w-5 cursor-pointer"
					/>
				),
		},
		{
			module: "Before Time",
			description: (
				<div>
					Buffer time for calculating whether the service <br /> was done before that. Before time
					calculation
				</div>
			),
			value: (
				<div>
					{editIndex === 2 ? (
						<input
							className={inputClassNames}
							onChange={(e) => setBeforetime(e.target.value)}
							value={beforeTime}
							onKeyDown={(event) => {
								if (!allowedKeys.includes(event.key) && /[^0-9]/.test(event.key)) {
									return event.preventDefault();
								}
							}}
						></input>
					) : (
						<span className="w-12 mr-2 text-center">{beforeTimeModule?.value}</span>
					)}
					<span>Min</span>
				</div>
			),
			action:
				editIndex === 2 ? (
					<Button
						className={buttonClassNames}
						onClick={() => updateConfigValues("beforeTime", beforeTime)}
						isSubmitting={status === "pending"}
					>
						Save
					</Button>
				) : (
					<img
						src={EditIcon}
						alt=""
						onClick={() => setEditIndex(2)}
						className="h-5 w-5 cursor-pointer"
					/>
				),
		},
		{
			module: "Default Time",
			description: (
				<div>
					If the Shift timing is not assigned <br /> default time will be considered
				</div>
			),
			value: (
				<div className="flex configuration gap-2 items-center config-time-picker">
					{editIndex === 3 ? (
						<>
							<Timepicker
								selectedTime={selectedTime1}
								setSelectedTime={setSelectedTime1}
								onChange={setSelectedTime2}
							></Timepicker>
							<span>To</span>
							<Timepicker
								selectedTime={selectedTime2}
								setSelectedTime={setSelectedTime2}
								onChange={setSelectedTime2}
								disabledTime={selectedTime1}
								disabled={!selectedTime2}
							></Timepicker>
						</>
					) : (
						<span className="flex gap-3">
							{defaultTimeModule && (
								<>
									{" "}
									<span>{defaultTimeModule?.value?.split(" - ")[0]}</span>
									<span>To</span>
									<span>{defaultTimeModule?.value?.split(" - ")[1]}</span>
								</>
							)}
						</span>
					)}
				</div>
			),
			action:
				editIndex === 3 ? (
					<Button
						className={buttonClassNames}
						isSubmitting={status === "pending"}
						onClick={() =>
							updateConfigValues(
								"defaultTime",
								moment(selectedTime1).format("h A") + " - " + moment(selectedTime2).format("h A")
							)
						}
					>
						Save
					</Button>
				) : (
					<img
						src={EditIcon}
						alt=""
						onClick={() => setEditIndex(3)}
						className="h-5 w-5 cursor-pointer"
					/>
				),
		},
		{
			module: "Google Distance",
			description: (
				<div>
					Distance thresholds in meters to trigger  <br /> Google API calls based on rider movement
				</div>
			),
			value: (
				<div>
					{editIndex === 4 ? (
						<input
							className={inputClassNames}
							onChange={(e) => setGoogleDistance(e.target.value)}
							value={googleDistance}
							onKeyDown={(event) => {
								if (!allowedKeys.includes(event.key) && /[^0-9]/.test(event.key)) {
									return event.preventDefault();
								}
							}}
						></input>
					) : (
						<span className="w-12 mr-2 text-center">{googleDistanceModule?.value}</span>
					)}
					<span>Meter</span>
				</div>
			),
			action:
				editIndex === 4 ? (
					<Button
						className={buttonClassNames}
						onClick={() => updateConfigValues("mapDistance", googleDistance)}
						isSubmitting={status === "pending"}
					>
						Save
					</Button>
				) : (
					<img
						src={EditIcon}
						alt=""
						onClick={() => setEditIndex(4)}
						className="h-5 w-5 cursor-pointer"
					/>
				),
		},
		{
			module: "Google Time",
			description: (
				<div>
					Time intervals in minutes to trigger  <br /> Google API calls based on rider movement
				</div>
			),
			value: (
				<div>
					{editIndex === 5 ? (
						<input
							className={inputClassNames}
							onChange={(e) => setGoogleMinute(e.target.value)}
							value={googleMinute}
							onKeyDown={(event) => {
								if (!allowedKeys.includes(event.key) && /[^0-9]/.test(event.key)) {
									return event.preventDefault();
								}
							}}
						></input>
					) : (
						<span className="w-12 mr-2 text-center">{googleMinuteModule?.value}</span>
					)}
					<span>Min</span>
				</div>
			),
			action:
				editIndex === 5 ? (
					<Button
						className={buttonClassNames}
						onClick={() => updateConfigValues("mapMinutes", googleMinute)}
						isSubmitting={status === "pending"}
					>
						Save
					</Button>
				) : (
					<img
						src={EditIcon}
						alt=""
						onClick={() => setEditIndex(5)}
						className="h-5 w-5 cursor-pointer"
					/>
				),
		},
	];

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<div className="flex items-center space-x-4">
							<h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
								Rider management
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white border rounded-xl px-5 mx-8 my-3 max-h-[70.4vh] h-auto overflow-hidden brand-details-section relative">
				<div className="w-full">
					<Table data={addressData} />
				</div>
			</div>
		</div>
	);
}

export default ConfigurationTable;

const Timepicker = ({ selectedTime, setSelectedTime, disabledTime, placeholder, onChange }) => {
	const format = "h A";

	const handleTimeChange = (value) => {
		setSelectedTime(value);
	};

	return (
		<div>
			<TimePicker
				prefixCls="configuration rc-time-picker"
				showSecond={false}
				showMinute={false}
				value={selectedTime}
				onChange={handleTimeChange}
				className="text-lg font-medium open-time-picker"
				allowEmpty={false}
				format={format}
				use12Hours
				placeholder={placeholder}
				disabledHours={() => {
					if (!disabledTime) return [];
					const disabledHours = [];
					let minToTime = disabledTime.clone().add(1, "hours");
					for (let i = 0; i < minToTime.hours(); i++) {
						disabledHours.push(i);
					}
					return disabledHours;
				}}
			/>
		</div>
	);
};

const Table = ({ data }) => {
	return (
		<div className="pb-4">
			<div className="table-row table-header mt-4 mb-3 w-full 2xl:text-lg text-base text-left font-inter-bold">
				<div className="w-[12%] pl-4">Sr. No.</div>
				<div className="w-[19%]">Module</div>
				<div className="w-[38%]">Description</div>
				<div className="w-[21.5%]">Value</div>
				<div className="w-[8.5%]">Action</div>
			</div>
			{data.map((item, index) => (
				<div
					className="table-row border rounded-lg my-2 2xl:text-base text-sm font-inter-medium text-[#333333]"
					key={index}
				>
					<div className="w-[12%] pl-4">{index + 1}.</div>
					<div className="w-[19%]">{item.module}</div>
					<div className="w-[38%]">{item.description}</div>
					<div className="w-[21.5%] flex pr-4">
					  <div className="w-[90%] overflow-x-auto whitespace-nowrap final-price-scrollbar">{item.value}</div>
					</div>
					<div className="w-[8.5%]">{item.action}</div>
				</div>
			))}
		</div>
	);
};
