import React, { useEffect, useMemo, useRef, useState } from "react";
import AddNewVariant from "./Add_New_Variant";
import Button from "../../shared/components/Button.component";
import AddNewCarForm from "./Add_New_Car_Form";
import {
	useAddCarModelMutation,
	useUpdateCarModelMutation,
} from "../../utils/Services/Apis/carApi";
import { Form, Formik, useFormikContext } from "formik";
import FormField from "../../shared/components/FormField.component";
import ListBox from "../../shared/components/ListBox.component";
import ImageLoadFormField from "../../shared/components/Image_Load_Form_Field.component";
import { useUploadFileMutation } from "../../utils/Services/Apis/fileUpload";
import useToaster from "../../utils/hooks/useToaster";
import { useLocation, useNavigate, useParams } from "react-router";
import TransmissionTypeIcon from "../../assets/icons/car-management/transmission-type.svg";
import FuelTypeIcon from "../../assets/icons/car-management/fuel-type.svg";
import DeleteIcon from "../../assets/icons/Delete.png";
import crossIcon from "../../assets/icons/cross.png";
import { AddVariantSchema, CarSchema } from "../../utils/Services/ValidationSchema";
import BackButton from "../../shared/components/BackButton.component";
import useUploadImage from "../../utils/hooks/useUploadImage";
import SelectMenu from "../../shared/components/SelectMenu.component";
import {
	useLazyGetAllCarTypesQuery,
	useLazyGetAllFuelTypesQuery,
	useLazyGetAllTransmissionTypesQuery,
} from "../../utils/Services/Apis/carMasterData";

export default function AddCarPage() {
	const { id } = useParams();
	const isEditing = !!id;
	const [isNewVariant, setIsNewVariant] = useState(false);
	const [carVariant, setCarVariant] = useState([]);

	const { setToast } = useToaster();
	const navigate = useNavigate();

	const {
		state: { brandData, carData },
	} = useLocation();

	const [addCarModel, { status: status1 }] = useAddCarModelMutation();
	const [uploadImage] = useUploadFileMutation();
	const [updateCarModel, { status: status2 }] = useUpdateCarModelMutation();
	const [getAllTransmissionTypes, { data: listData }] = useLazyGetAllTransmissionTypesQuery();
	const [getAllFuelTypes, { data: FuelTypeListData }] = useLazyGetAllFuelTypesQuery();
	const [getAllCarTypes, { data: carTypesData }] = useLazyGetAllCarTypesQuery();
	const { uploadImages } = useUploadImage();
	const [nonFormikErrors, setNonFormikErrors] = useState({});

	const findTheIndex = (array, item, property) => {
		return array.findIndex((e) => e[property] === item[property]);
	};

	useEffect(() => {
		getCarMasterData();
	}, []);

	const getCarMasterData = async () => {
		await getAllTransmissionTypes();
		await getAllFuelTypes();
		await getAllCarTypes();
	};

	const GetFormValues = () => {
		useEffect(() => {
			setFieldValue("variants", carVariant);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [carVariant]);

		//to set initial values of edit variant

		const { values, setFieldValue, errors, setFieldError } = useFormikContext();
		useEffect(() => {
			if (!isEditing) return;
			if (isNewVariant) {
				setFieldValue("transmissionType", listData[0]);
				setFieldValue("fuelType", FuelTypeListData[0]);
				setFieldValue("variantName", "");
				return;
			}
			if (listData)
				setFieldValue(
					"transmissionType",
					listData[listData.findIndex((list) => list.value == values.variant.transmissionType)]
				);
			if (FuelTypeListData)
				setFieldValue(
					"fuelType",
					FuelTypeListData[
						FuelTypeListData.findIndex((list) => list.value == values.variant.fuelType)
					]
				);
			setFieldValue("variantName", values.variant.variantName);

			setFieldValue("_id", values.variant._id);
		}, [values.variant]);

		const updatedVariant = {
			variantName: values.variantName,
			fuelType: values.fuelType?.value,
			transmissionType: values.transmissionType?.value,
			_id: !isNewVariant ? values._id : "",
		};

		const addedVariant = {
			variantName: values.variantName,
			fuelType: values.fuelType?.value,
			transmissionType: values.transmissionType?.value,
		};

		return (
			<>
				{!isEditing || isNewVariant ? (
					<Button
						className="w-[45%] text-white cursor-pointer bottom-btn-ht"
						type="submit"
						onClick={async () => {
							try {
								await AddVariantSchema.validate(values, { abortEarly: false });
								setCarVariant([addedVariant, ...carVariant]);
								setNonFormikErrors({});
								// setFieldValue("variants", [addedVariant, ...carVariant]);
								setFieldValue("variantName", "");
								setFieldValue("transmissionType", null);
								setFieldValue("fuelType", null);
								setFieldError("variants", "");
							} catch (errors) {
								const formErrors = {};
								errors.inner.forEach((error) => {
									formErrors[error.path] = error.message;
								});
								setNonFormikErrors(formErrors);
							}
						}}
					>
						Add
					</Button>
				) : (
					<Button
						className="w-[45%] text-white cursor-pointer bottom-btn-ht"
						onClick={async () => {
							try {
								await AddVariantSchema.validate(values, { abortEarly: false });
								const updatedVariantList = carVariant.map((variant) => {
									if (values.variant._id !== variant._id) return variant;
									return updatedVariant;
								});
								setCarVariant(updatedVariantList);
								setFieldValue("variant", updatedVariant);
							} catch (errors) {
								const formErrors = {};
								errors.inner.forEach((error) => {
									formErrors[error.path] = error.message;
								});
								setNonFormikErrors(formErrors);
							}
						}}
					>
						Save
					</Button>
				)}
			</>
		);
	};

	useEffect(() => {
		if (id) setCarVariant(carData.variants);
	}, [id]);

	const updateCarData = (data) => {
		updateCarModel(data)
			.unwrap()
			.then(({ data }) => {
				setToast({ type: "success", msg: "Car updated successfully" });
				navigate(-1);
			})
			.catch((err) => {
				setToast({ type: "error", msg: err.data.message });
			});
	};

	const handleAddCarModel = async (values) => {
		let imageUrlArray = await uploadImages(values.photo);
		if (imageUrlArray) {
			addCarModel({
				name: values.carName,
				variants: carVariant,
				carType: values.carType.value,
				carBrandId: brandData["_id"],
				imageUrl: imageUrlArray[0],
			})
				.unwrap()
				.then(({ data }) => {
					setToast({ type: "success", msg: "Car added successfully" });
					navigate(-1);
				})
				.catch((err) => {
					setToast({ type: "error", msg: err.data.message });
				});
		}
		// uploadImage(formData)
	};

	const handleEditCarModel = async (values) => {
		if (typeof values?.photo !== "string") {
			let imageUrlArray = await uploadImages(values.photo);
			if (!imageUrlArray) return;
			updateCarData({
				name: values.carName,
				variants: carVariant,
				carType: values.carType.value,
				carBrandId: brandData["_id"],
				imageUrl: imageUrlArray[0],
				uniqueId: carData?.uniqueId,
			});
			// uploadImage(formData)
		} else {
			let tempCarData = {
				name: values.carName,
				variants: carVariant,
				carType: values.carType.value,
				carBrandId: brandData["_id"],
				imageUrl: carData.imageUrl,
				uniqueId: carData?.uniqueId,
			};
			updateCarData(tempCarData);
		}
	};

	const handleDeleteVariant = (index) => {
		setCarVariant([...carVariant].toSpliced(index, 1));
	};

	const getSelectedCarType = (selectedCarType) => {
		if (carTypesData)
			return carTypesData[carTypesData.findIndex((list) => list.value == selectedCarType)];
	};

	return (
		<div className="bg-[#F5F5F5] h-full pt-6">
			<div className="px-8 py-4">
				<div className="flex gap-3 items-center">
					<BackButton />
					<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
						{brandData?.name}
					</h3>
				</div>
				<Formik
					enableReinitialize
					initialValues={{
						carName: isEditing ? carData.name : "",
						photo: isEditing ? carData.imageUrl : null,
						carType: isEditing ? getSelectedCarType(carData.carType) : "",
						transmissionType: isEditing ? carData.variants[0] : null,
						variant: isEditing && carData.variants[0],
						fuelType: isEditing ? carData.variants[0] : null,
						variantName: isEditing ? carData.variants[0].variantName : "",
						variants: isEditing ? carData.variants : [],
					}}
					validationSchema={CarSchema}
					onSubmit={(values) => {
						if (isEditing) {
							handleEditCarModel(values);
						} else {
							handleAddCarModel(values);
						}
					}}
				>
					{({ isSubmitting, errors, touched, setFieldValue, values }) => (
						<div className="flex 2xl:gap-5 gap-3 pt-9 pb-4 pl-8">
							<section className="w-[60%] border shadow p-5 rounded-xl bg-[#FFFFFF] h-[75vh]">
								<Form className="" action="#" method="POST">
									<div className="flex flex-row">
										<div className="basis-11/12 2xl:space-y-6 space-y-3 pt-10 margin-top-varient">
											<div>
												<div className="text-[22px] font-inter-bold subheading-at-zoom">
													{isEditing ? "Edit Car" : "Add New Car"}
												</div>
												{/* <div className="tracking-tight text-sm font-medium">
													{isEditing ? " Add a new car by entering the details below" : ""}
												</div> */}
											</div>
											<FormField
												field={{
													label: "Enter Car Model",
													name: "carName",
													type: "text",
													placeholder: "Enter car model",
													onKeyPress: (event) => {
														if (/[^a-zA-Z0-9 .-]/.test(event.key)) {
															return event.preventDefault();
														}
													},
												}}
												error={errors.carName}
											/>
											{/* <div>
												<ListBox
													label="Car Type"
													name="carType"
													options={carTypesData}
													property="carType"
												/>
											</div> */}
											<div>
												<SelectMenu
													label="Car Type"
													name="carType"
													options={carTypesData}
													onChange={(option) => setFieldValue("carType", option)}
													value={values.carType}
													placeholder="Select car type"
													optionLabel="label"
												/>
												{touched["carType"] && errors["carType"] ? (
													<div className="text-red-600 text-sm">{errors["carType"]}</div>
												) : (
													<div className="invisible h-5"></div>
												)}
											</div>

											<div>
												<div className="text-sm mb-1">Car Photo</div>
												<ImageLoadFormField
													bottomText={"png format of 300x200 px"}
													imageUrl={carData?.imageUrl}
													instructions={
														"Note: Please upload a car image with white background and a ratio of 2:1"
													}
													isEdit={isEditing}
													imgFormat="image/png"
													// acceptableWidth={300}
													// acceptableHeight={200}
												/>
												{/* <div>
													{errors.photo && touched.photo ? <div>{errors.photo}</div> : null}
												</div> */}
												<div className="text-red-600 text-sm">
													{errors.photo && touched.photo ? <div>{errors.photo}</div> : null}
												</div>
											</div>
										</div>
										<div className="2xl:px-10 px-5 pt-8">
											<hr className="h-full w-0.5 bg-[#969696] opacity-30" />
										</div>
										<div
											className={`${
												isEditing ? "pt-8" : "pt-10"
											} basis-11/12 space-y-6 margin-top-varient`}
										>
											<div className="flex justify-between items-center">
												<div className="text-[22px] font-inter-bold subheading-at-zoom">
													{isEditing ? "Edit" : "Add"} Variant
												</div>
												{isEditing && (
													// setIsNewVariant(true)
													<Button
														className="bg-black text-white cursor-pointer bottom-btn-ht"
														onClick={() => setIsNewVariant(!isNewVariant)}
													>
														{isNewVariant ? "Edit Variant" : "New Variant"}
													</Button>
												)}
											</div>
											{!isEditing || isNewVariant ? (
												<div>
													<FormField
														nonFormikError
														field={{
															label: "Variant Name",
															name: "variantName",
															type: "text",
															placeholder: "Variant 1",
															onKeyPress: (event) => {
																if (/[^a-zA-Z0-9 .-]/.test(event.key)) {
																	return event.preventDefault();
																}
															},
														}}
													/>
													{nonFormikErrors["variantName"] ? (
														<div className="text-red-600 text-sm">
															{nonFormikErrors["variantName"]}
														</div>
													) : (
														<div className=" h-5"></div>
													)}
												</div>
											) : (
												<>
													{/* <ListBox
														label="Select Variant"
														name="variant"
														options={carVariant}
														property="variantName"
														isEditing={isEditing}
													/> */}
													<SelectMenu
														label="Variant Type"
														name="variant"
														options={carVariant}
														onChange={(option) => setFieldValue("variant", option)}
														value={values.variant}
														placeholder="Select"
														optionLabel="variantName"
													/>
													<div className="pt-3">
														<FormField
															field={{
																label: "Variant Name",
																name: "variantName",
																type: "text",
																placeholder: "Variant 1",
																onKeyPress: (event) => {
																	if (/[^a-zA-Z0-9 .-]/.test(event.key)) {
																		return event.preventDefault();
																	}
																},
															}}
														/>
													</div>
												</>
											)}
											{/* <div>
												<ListBox
													label="Fuel Type"
													name="fuelType"
													options={FuelTypeListData}
													property="fuelType"
												/>
											</div> */}
											<div>
												<SelectMenu
													label="Fuel Type"
													name="fuelType"
													options={FuelTypeListData}
													onChange={(option) => setFieldValue("fuelType", option)}
													value={values.fuelType}
													placeholder="Select"
													optionLabel="label"
												/>
												{nonFormikErrors["fuelType"] ? (
													<div className="text-red-600 text-sm">{nonFormikErrors["fuelType"]}</div>
												) : (
													<div className="invisible h-5"></div>
												)}
											</div>
											{/* <div>
												<ListBox
													label="Transmission Type"
													name="transmissionType"
													options={listData}
													property="transmissionType"
												/>
											</div> */}
											<div>
												<SelectMenu
													label="Transmission Type"
													name="transmissionType"
													options={listData}
													onChange={(option) => setFieldValue("transmissionType", option)}
													value={values.transmissionType}
													placeholder="Select"
													optionLabel="label"
												/>
												{nonFormikErrors["transmissionType"] ? (
													<div className="text-red-600 text-sm">
														{nonFormikErrors["transmissionType"]}
													</div>
												) : (
													<div className="invisible h-5"></div>
												)}
											</div>
											<div className="flex justify-between">
												<Button
													className="w-[45%] text-[#000000] bg-[#F1F1F1] cursor-pointer bottom-btn-ht font-inter-bold"
													onClick={() => {
														setFieldValue("variantName", "");
														setFieldValue("transmissionType", listData[0]);
														setFieldValue("fuelType", FuelTypeListData[0]);
													}}
												>
													Cancel
												</Button>
												<GetFormValues />
											</div>
										</div>
									</div>
									<div className="flex justify-center mt-10 margin-top-zoom">
										<Button
											className="w-[35%] text-white cursor-pointer bottom-btn-ht"
											type="submit"
											isSubmitting={status1 === "pending" || status2 === "pending"}
										>
											{isEditing ? "Save" : "Add"} Car
										</Button>
									</div>
								</Form>
							</section>
							<div className="">
								<hr className="h-full w-0.5 bg-[#969696] opacity-30" />
							</div>
							<section className="flex-1 border shadow 2xl:pt-12 pt-10 2xl:pb-5 pb-2 2xl:pl-5 pl-2 2xl:pr-3 pr-1 rounded-xl bg-[#FFFFFF] h-[75vh] overflow-hidden margin-t-var">
								<div className="text-lg font-inter-bold">
									{isEditing ? "Edited" : "Added"} Variants
								</div>
								<div className="flex flex-col gap-3 mt-4 h-[35vw] overflow-y-scroll 2xl:pr-2 pr-1">
									{errors.variants && touched.variants ? (
										<div className="text-red-600 text-sm">{errors.variants}</div>
									) : null}

									{carVariant?.map((variant, index) => {
										return (
											<div className="border border-gray-200 rounded-xl px-2" key={index}>
												<header className="flex justify-between py-2 2xl:px-4 px-2 rounded-t-xl">
													<div className="font-inter-medium text-base">{variant.variantName}</div>
													<img
														src={crossIcon}
														alt=""
														className="h-5 w-5 self-center cursor-pointer"
														onClick={() => handleDeleteVariant(index)}
													/>
												</header>
												<div>
													<hr />
												</div>
												<div className="flex 2xl:flex-row flex-col py-2 2xl:px-4 px-2 gap-4">
													<div className="flex 2xl:w-2/5 w-full items-center space-x-2">
														<div className="2xl:text-base text-sm sm:w-[55%] 2xl:w-[60%] 3xl:w-[55%] font-inter-medium">
															Fuel Type :
														</div>
														<div className="flex gap-2 items-center">
															{/* <img src={FuelTypeIcon} alt="" className="h-4 w-4" /> */}
															<div className="2xl:text-base text-sm font-inter-regular capitalize">
																{" "}
																{variant.fuelType}
															</div>
														</div>
													</div>
													<div className="flex 2xl:w-3/5 w-full items-center space-x-2 mb-2 2xl:mb-0">
														<div className="text-sm 2xl:text-base sm:w-[55%] 2xl:w-[50%] 3xl:w-[65%] font-inter-medium">
															Transmission Type:{" "}
														</div>
														<div className="flex gap-2 items-center">
															{/* <img src={TransmissionTypeIcon} alt="" className="h-4 w-4" /> */}
															<div className="2xl:text-base text-sm font-inter-regular capitalize">
																{variant.transmissionType}
															</div>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</section>
						</div>
					)}
				</Formik>
			</div>
		</div>
	);
}
