const BASE_URL = process.env.REACT_APP_BASE_URL;

const endpoints = {
	auth: {
		signin: "auth/signin",
		signup: "auth/signup",
		forgotPassword: "auth/forgot",
		resetPassword: (token) => `auth/reset/${token}`
	},
	employee: {
		profile: "users/me",
		addEmployee: "addEmployee",
		getEmployeeDetails: (employeeId) => `employee/${employeeId}`,
		deleteEmployee: (employeeId) => `employee/${employeeId}`,
		updateEmployee: (employeeId) => `employee/${employeeId}`,
		getAllEmployees: "all-employees",
	},
	rider: {
		getRiders: "riders",
	},
	city: {
		addCity: "city",
		getCities: `cities`,
		getCitiesPagination: (pageNo, pageSize) =>
			`cities/pagination?page=${pageNo}&pageSize=${pageSize}`,
		deleteCity: (cityId) => `city/${cityId}`,
		getCityManagers: "citymanagers",
		updateCity: (cityId) => `city/${cityId}`,
		getCityManagersByCity: (cityId) => `city/citymanagers/${cityId}`,
		getCityManagersByCities: "citymanagers/cities",
	},
	area: {
		addArea: "area",
		getAreas: (cityId) => `area/${cityId}`,
		getAreaPagination: (cityId, pageNo, pageSize) =>
			`area/pagination/${cityId}?page=${pageNo}&pageSize=${pageSize}`,
		getCitySupervisor: (cityId) => `area/supervisors/${cityId}`,
		deleteArea: (areaId) => `area/${areaId}`,
		upateArea: (areaId) => `area/${areaId}`,
		getAreaDetails: (areaId) => `area/s/${areaId}`,
		getActiveRadiusType: "radiusType/active",
	},
	car: {
		getCarBrands: "car/brand",
		addCarBrand: "car/brand",
		deleteCarBrand: (carBrandId) => `car/${carBrandId}`,
		updateCarBrand: (carBrandId) => `car/${carBrandId}`,
		getCarModels: (carBrandId) => `car/models/${carBrandId}`,
		addCarModel: "car/brand/model",
		deleteCarModel: (modelUniqueId) => `car/model/variant/${modelUniqueId}`,
		updateCarModel: `/car/brand/model`,
	},
	upload: {
		image: `upload/image`,
		file: `upload/file`,
	},
	services: {
		addService: "service",
		getAllServices: "services/all",
		deleteSingleService: (serviceId) => `single-service/${serviceId}`,
		updateService: (serviceId) => `single-service/${serviceId}`,
		getServiceInfo: (serviceId) => `single-service/${serviceId}`,
		getAllPackages: "package",
		getAllSubscriptions: "subscription",
		// reports
		reports: "services/top",
	},
	order: {
		getCustomerByPhoneNumber: "customer/phoneNumber",
		getCarModelByBrandId: (brandId) => `order/carmodel/${brandId}`,
		addCustomer: "customer",
		getServicesForOrder: "order/services",
		addOrder: "order",
		getOrderDetails: (brandId) => `order/${brandId}`,
		getOrderCount: `orders/count`,
		getOrderByStatus: (status) => `orders/all/${status}`,
		getRidersForOrder: (orderId) => `orders/riders/${orderId}`,
		assignOrderToRider: `order/assign/rider`,
		rescheduleOrCancel: (orderId) => `order/${orderId}`,
		getSingleOrder: (orderId) => `order/${orderId}`,
		verifyVehicle: "verify-vehicle",
		vehicleAddress: "vehicleAddress",
	},
	packages: {
		getAllPackages: "package",
		addPackage: "package",
	},
	employeeMaster: {
		getAllAllowance: "allowance",
	},
	carMaster: {
		getAllFuelType: "fuelType",
		getAllCarType: "carType",
		getAllTransmissionType: "transmissionType",
	},
	onDemandJob: {
		widgetCount: "orders/onDemandCount",
		getOrders: (status) => `orders/on-demand/${status}`,
		reasonsMaster: "reasons",
		updateAppointment: (orderId, status) => `orders/${orderId}/${status}`,
		verifyOtp: (orderId, otp) => `order/paymentVerification/${orderId}/${otp}`,
		updateAppointmentAfterOtpVerification: (orderId) => `on-demand/order/${orderId}`,
		getTotalAmount: "order/on-demand-service/totalamount",
		widgetsCountByRider: "/riders/onDemandCount",
	},
	timeSlot: {
		getAvailableTimeSlots: "available/timeslots",
		getTimeSlotPagination: (pageNo, pageSize) =>
			`timeslots/master?page=${pageNo}&pageSize=${pageSize}`,
		getRiderInTimeSlot: "available/riders",
		reschedulingTimeSlot: "reschedule/timeslots",
	},

	razorpay: {
		sendPaymentLinkToCustomer: (orderId) => `payment/razorpay/${orderId}`,
		getPaymentOrderStatus: (paymentOrderId) => `payment/status/${paymentOrderId}`,
	},
	areaMaster: {
		// Area master Endpoints
		getAllRadiusType: "radiusType",
		deleteRadiusType: (radiusTypeId) => `radiusType/${radiusTypeId}`,
		updateRadiusType: (radiusTypeId) => `radiusType/${radiusTypeId}`,
		addRadiusType: "radiusType",

		// Time Slot master Endpoints
		getAllTimeSlots: "timeslots",
		deleteTimeSlot: (slotId) => `timeslots/master/${slotId}`,
		updateTimeSlot: (slotId) => `timeslots/master/${slotId}`,
		addTimeSlot: "timeslots/master",
	},
	customerManagement: {
		widgetsCount: "widgets",
		getCustomerList: (pageNo, pageSize) => `get-customers?page=${pageNo}&pageSize=${pageSize}`,
		getAreaList: "cities/areas",
		deleteCustomer: (customerId) => `customer/${customerId}`,
		updateCustomer: (customerId) => `customer/${customerId}`,
		getCustomerWidgets: (customerId) => `customer-widget/${customerId}`,
		getSingleCustomer: (customerId) => `customer/${customerId}`,
		getCustomerVehicles: (customerId) => `customer-vehicles/${customerId}`,
		getCustomerAddress: (customerId) => `customerAddress/${customerId}`,
		deleteAddress: (addressId) => `address/${addressId}`,
		updateVehicleAddress: (addressId) => `update-address/${addressId}`,
		addAddress: (vehicleId) => `address/${vehicleId}`,
		getVehicleDetailsByStatus: (status) => `vehicle/services/${status}`,
		// addVehicleAddress: (vehicleId) => `address/${vehicleId}`,
		getVehicleAddress: (vehicleId) => `vehicleAddress/${vehicleId}`,
		updateVehicleDetails: (vehicleId) => `vehicleDetails/${vehicleId}`,
		getVehicleDetails: (vehicleId) => `vehicle/details/${vehicleId}`,
		getVehicleServiceCount: "vehicle/service/count",
		updateVehicle: (vehicleId) => `update-vehicle/${vehicleId}`,
		notifications: (customerId) => `get-notifications/${customerId}`,
		orders: (customerId) => `customer/orders/dues/${customerId}`,
		paymentLink: "/customer/paymentlink",
		getDues: (customerId) => `customer/dues/${customerId}`,
	},
	configuration: {
		getConfiguration: "configurations",
		updateConfiguration: "configurations",
	},
	riderManagement: {
		getRidersCountByStatus: "/riders/count",
		getTotalRiders: "riders/totalRiders",
		getRidersByStatus: (riderStatus) => `/riders/status/${riderStatus}`,
		getRiderNotifications: "get-rider-dashboard-notifications",
		getRidersDistance: "/riders/distance",
		getOrderByRiderAndStatus: (riderId, status) => `/riders/orders/${riderId}/${status}`,
		getOrdersCountByRider: (riderId) => `/riders/orders/${riderId}`,
		getRiderDetails: (riderId) => `riders/s/${riderId}`,
		getPDF: (riderId) => `riders/s/orders/${riderId}`,
		getAllRiders: `riders/all`,
		getRidersCompletedOrders: (riderId, status) => `riders/completed/orders/${riderId}/${status}`,
		getOrderOfRiderByStatus: (riderId, status) => `/riders/orders/s/${riderId}/${status}`,
		getRiderEmployeeDetails: (employeeId) => `/riders/${employeeId}`,
		getCashCollectionCount: (riderId) => `/riders/cashCollection/${riderId}`,
		getTotalCashReceived: (riderId) => `/riders/totalCashCollection/${riderId}`,
		getTotalCashDeposited: (riderId) => `/riders/totalCashDeposited/${riderId}`,
		getAvailableAmount: (riderId) => `/riders/availableAmount/${riderId}`,
		getSalaryAllowanceOfRider: (riderId) => `/riders/sallaryAllowance/${riderId}`,
		getRiderLead: (riderId) => `/riders/lead/${riderId}`,
		getRiderMapStatus: "riders/map/status",
		getTotalProfit: (riderId) => `/riders/totalProfit/${riderId}`,
		getTotalServices: (riderId) => `/riders/totalServices/${riderId}`,
		getAllCashCollection: (riderId) => `riders/allCashCollection/${riderId}`,
		getRidersOrderCount: (riderId) => `/riders/order/count/${riderId}`,
		getOrderCountByRiderOfMap: (riderId) => `/riders/map/details/${riderId}`,
		getTotalTimeLogged: (riderId) => `/riders/timeLogged/${riderId}`,
		getShiftsByRidersCity: (riderId) => `/shifts/timeslots/${riderId}`,
		addShiftsForRiders: (riderId) => `/shifts/riders/${riderId}`,
		getRidersShifts: (riderId) => `/shifts/riders/${riderId}`,
		deleteRidersShift: (uniqueId) => `/shifts/riders/${uniqueId}`,
		updateShiftsForRiders: (uniqueId) => `/shifts/riders/${uniqueId}`,
	},
	shiftCategory: {
		getAllShifts: "shifts",
		addShift: "shifts",
		deleteShiftCategory: (shiftId) => `/shifts/${shiftId}`,
		updateShift: (id) => `/shifts/${id}`,
		getShiftsForTimeslots: "/shifts/timslots",

		getTimeSlotCreationPagination: (pageNo, pageSize) =>
			`/shift/timeslots/master?page=${pageNo}&pageSize=${pageSize}`,
		updateTimeSlotCreation: (id) => `/shift/timeslots/master/${id}`,
		deleteTimeSlotCreation: (id) => `/shift/timeslots/master/${id}`,
		addTimeSlotCreation: "/shift/timeslots/master",
	},

	franchiseManagement: {
		getAllMasterFranchise: `/franchise/masterFranchise`,
		getAllFranchise: `/franchise/all`,
		deleteFranchise: (id) => `/franchise/${id}`,
		updateFranchise: (id) => `/franchise/${id}`,
		getFranchiseDetail: (id) => `/franchise/${id}`,
		getFranchiseByStatus: (status) => `/franchise/status/${status}`,
		addFranchise: "franchise",
		renewFranchise: (id) => `/franchise/renewal/${id}`,
		getChildFranchiseById: (parentId) => `/franchise/masterFranchiseChild/${parentId}`,
		updateFranchiseStatus:(id)=> `/franchise/status/${id}`,
	}
};

export { endpoints, BASE_URL };
