import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const subscriptionApi = apiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            getAllSubscriptions: builder.query({
                query: () => ({
                    url: endpoints.services.getAllSubscriptions,
                    method: "GET",
                }),
                providesTags: ["GetAllSubscriptions"],
            }),
        }
    }
})



export const {
    useLazyGetAllSubscriptionQuery,   
} = subscriptionApi;
export { subscriptionApi };