import { useDispatch, useSelector } from "react-redux";
import { setModal, setIsModalOpen } from "../Store";
import AddBrandModal from "../../pages/car-management/Add_Brand_Modal";
import DeleteBrandModal from "../../pages/car-management/Delete_Brand_Modal";
import DeleteCarModal from "../../pages/car-management/Delete_Car_Modal";
import PackageModal from "../../pages/service-management/Package_Modal";
import SubscriptionModal from "../../pages/service-management/Subscription_Modal";
import ServiceModal from "../../pages/service-management/Service_Modal";
import AddNewArea from "../../pages/area-and-city-management/Add_New_Area_Modal";
import AddNewCity from "../../pages/area-and-city-management/Add_New_City_Modal";
import AddNewService from "../../pages/service-request-mangement/Add_New_Service";
import PreviewDocImage from "../../pages/employee-management/Preview_Doc_Image_Modal";
import MapDisplayAreas from "../../pages/area-and-city-management/Map_Display_Areas";
import DisplayCityMap from "../../pages/area-and-city-management/Display_City_Map";
import AppointmentSuccessModal from "../../pages/service-request-mangement/Appointment_Success_Modal";
import DeleteCityModal from "../../pages/area-and-city-management/Delete_City_Modal";
import DeleteAreaModal from "../../pages/area-and-city-management/Delete_Area_Modal";
import DeleteEmployeeModal from "../../pages/employee-management/Delete_Employee_Modal";
import DeleteServiceModal from "../../pages/service-management/Delete_Service_Modal";
import AssignRiderModal from "../../pages/service-request-mangement/Assign_Rider_Modal";
import RescheduleModal from "../../pages/service-request-mangement/Reschedule_Modal";
import UpdateAppointmentModal from "../../pages/on-demand-management/Update_Appointment_Modal";
import PaymentModal from "../../pages/on-demand-management/Payment_Modal";
import CancelModal from "../../pages/on-demand-management/Cancel_Modal";
import CancelAppointmentModal from "../../pages/service-request-mangement/Cancel_Appointment_Modal";
import PreviewImages from "../../shared/components/PreviewImages";
import EndServiceSuccessModal from "../../pages/on-demand-management/EndServiceSuccessModal";
import EditMasterAreaModal from "../../pages/master-data/area/Edit_Master_Area_Modal";
import DeleteMasterAreaModal from "../../pages/master-data/area/Delete_Master_Area_Modal";
import CustomerServiceModal from "../../pages/customer-management/Customer_Service_Modal";
import CustomerEditModal from "../../pages/customer-management/Customer_Edit_Modal";
import CustomerDeleteModal from "../../pages/customer-management/Customer_Delete_Modal";
import ClearDueAmountModal from "../../pages/customer-management/Clear_Due_Amount_Modal";
import PaymentLinkSuccessModal from "../../pages/customer-management/Payment_Link_Success_Modal";
import EditVehicleModal from "../../pages/customer-management/vehicle-module/Edit_Vehicle_Modal";
import AddEditAddress from "../../pages/customer-management/Add_Edit_Address";
import DeleteRiderModal from "../../pages/rider-management/Delete_Rider_Modal";
import EditMasterCategoryModal from "../../pages/rider-management/Edit_Master_Category_Modal";
import DeleteMasterCategoryModal from "../../pages/rider-management/Delete_Master_Category_Modal";
import MapModalAllRiders from "../../pages/rider-management/Map_Modal";
import AddShiftModal from "../../pages/rider-management/shift-module/Add_Shift_Modal";
import DeleteShiftModal from "../../pages/rider-management/shift-module/Delete_Shift_Modal";
import DeleteFranchiseModal from "../../pages/franchise-management/all-franchise/Delete_Franchise_Modal";
import EditFranchiseModal from "../../pages/franchise-management/all-franchise/Edit_Franchise_Modal";
import RenewalFranchiseModal from "../../pages/franchise-management/all-franchise/Renewal_Franchise_Modal";
import ApproveFranchiseModal from "../../pages/franchise-management/pending-franchise/Approve_Franchise_Modal";
import ApprovedFranchiseModal from "../../pages/franchise-management/pending-franchise/Approved_Franchise_Modal";
import RejectFranchiseModal from "../../pages/franchise-management/pending-franchise/Reject_Franchise_Modal";
import FranchiseInfoModal from "../../pages/franchise-management/all-franchise/Franchise_Info_Modal";
import RenewedFranchiseModal from "../../pages/franchise-management/all-franchise/Renewed_Franchise_Modal";
import CreateNewFranchiseModal from "../../pages/franchise-management/all-franchise/Create_New_Franchise_Modal";
import RenewalDetailsModal from "../../pages/franchise-management/all-franchise/Renewal_Details_Modal";
import FranchiseCreatedModal from "../../pages/franchise-management/all-franchise/Franchise_Created_Modal";
import EditMenuMasterModal from "../../pages/master-data/area/menu-master/Edit_Menu_Master_Modal";
import DeleteMenuMasterModal from "../../pages/master-data/area/menu-master/Delete_Menu_Master_Modal";
import EditSubMenuMasterModal from "../../pages/master-data/area/submenu-master/Edit_SubMenu_Master_Modal";
import DeleteSubMenuMasterModal from "../../pages/master-data/area/submenu-master/Delete_SubMenu_Master_Modal";
import RevenueModal from "../../pages/franchise-management/all-franchise/Revenue_Modal";
import PerUserModal from "../../pages/franchise-management/all-franchise/Per_User_Modal";
import DeleteRoleModal from "../../pages/role-management/Delete_Role_Modal";


export default function useSelectModal() {
	const { modal, isModalOpen } = useSelector((state) => state.modal);
	const renderModal =
		modal.name === "AddBrandModal" ? (
			<AddBrandModal data={modal?.data} />
		) : modal.name === "AddNewArea" ? (
			<AddNewArea data={modal?.data} />
		) : modal.name === "AddNewCity" ? (
			<AddNewCity data={modal?.data} />
		) : modal.name === "PackageModal" ? (
			<PackageModal data={modal?.data} />
		) : modal.name === "SubscriptionModal" ? (
			<SubscriptionModal data={modal?.data} />
		) : modal.name === "ServiceModal" ? (
			<ServiceModal data={modal?.data} />
		) : modal.name === "DeleteBrandModal" ? (
			<DeleteBrandModal data={modal?.data} />
		) : modal.name === "DeleteCarModal" ? (
			<DeleteCarModal data={modal?.data} />
		) : modal.name === "AddNewService" ? (
			<AddNewService />
		) : modal.name === "PreviewDocImage" ? (
			<PreviewDocImage data={modal?.data} />
		) : modal.name === "MapDisplayAreas" ? (
			<MapDisplayAreas data={modal?.data} />
		) : modal.name === "DisplayCityMap" ? (
			<DisplayCityMap data={modal?.data} />
		) : modal.name === "DisplayAppointmentSuccess" ? (
			<AppointmentSuccessModal data={modal?.data} />
		) : modal.name === "DeleteCityModal" ? (
			<DeleteCityModal data={modal?.data} />
		) : modal.name === "DeleteAreaModal" ? (
			<DeleteAreaModal data={modal?.data} />
		) : modal.name === "DeleteEmployeeModal" ? (
			<DeleteEmployeeModal data={modal?.data} />
		) : modal.name === "DeleteServiceModal" ? (
			<DeleteServiceModal data={modal?.data} />
		) : modal.name === "AssignRiderModal" ? (
			<AssignRiderModal data={modal?.data} />
		) : modal.name === "RescheduleModal" ? (
			<RescheduleModal data={modal?.data} />
		) : modal.name === "UpdateAppointmentModal" ? (
			<UpdateAppointmentModal data={modal?.data} />
		) : modal.name === "PaymentModal" ? (
			<PaymentModal data={modal?.data} />
		) : modal.name === "CancelModal" ? (
			<CancelModal data={modal?.data} />
		) : modal.name === "CancelAppointmentModal" ? (
			<CancelAppointmentModal data={modal?.data} />
		) : modal.name === "PreviewImages" ? (
			<PreviewImages data={modal?.data} />
		) : modal.name === "EndServiceSuccessModal" ? (
			<EndServiceSuccessModal data={modal?.data} />
		) : modal.name === "EditMasterAreaModal" ? (
			<EditMasterAreaModal data={modal?.data} />
		) : modal.name === "DeleteMasterAreaModal" ? (
			<DeleteMasterAreaModal data={modal?.data} />
		) : modal.name === "CustomerServiceModal" ? (
			<CustomerServiceModal data={modal?.data} />
		) : modal.name === "CustomerEditModal" ? (
			<CustomerEditModal data={modal?.data} />
		) : modal.name === "CustomerDeleteModal" ? (
			<CustomerDeleteModal data={modal?.data} />
		) : modal.name === "clearDueAmountModal" ? (
			<ClearDueAmountModal data={modal?.data} />
		) : modal.name === "paymentLinkSuccessModal" ? (
			<PaymentLinkSuccessModal />
		) : modal.name === "EditVehicleModal" ? (
			<EditVehicleModal data={modal?.data} />
		) : modal.name === "AddEditAddress" ? (
			<AddEditAddress data={modal?.data} />
		) : modal.name === "DeleteRiderModal" ? (
			<DeleteRiderModal data={modal?.data} />
		) : modal.name === "EditMasterCategoryModal" ? (
			<EditMasterCategoryModal data={modal?.data} />
		) : modal.name === "DeleteMasterCategoryModal" ? (
			<DeleteMasterCategoryModal data={modal?.data} />
		) : modal.name === "MapModalAllRiders" ? (
			<MapModalAllRiders data={modal?.data} />
		) : modal.name === "AddShiftModal" ? (
			<AddShiftModal data={modal?.data} />
		) : modal.name === "DeleteShiftModal" ? (
			<DeleteShiftModal data={modal?.data} />
		) : modal.name === "DeleteFranchiseModal" ? (
			<DeleteFranchiseModal data={modal?.data} />
		) : modal.name === "EditFranchiseModal" ? (
			<EditFranchiseModal data={modal?.data} />
		) : modal.name === "RenewalFranchiseModal" ? (
			<RenewalFranchiseModal data={modal?.data} />
		) : modal.name === "ApproveFranchiseModal" ? (
			<ApproveFranchiseModal data={modal?.data} />
		) : modal.name === "ApprovedFranchiseModal" ? (
			<ApprovedFranchiseModal data={modal?.data} />
		) : modal.name === "RejectFranchiseModal" ? (
			<RejectFranchiseModal data={modal?.data} />
		) : modal.name === "FranchiseInfoModal" ? (
			<FranchiseInfoModal data={modal?.data} />
		) : modal.name === "RenewedFranchiseModal" ? (
			<RenewedFranchiseModal data={modal?.data} />
		) : modal.name === "CreateNewFranchiseModal" ? (
			<CreateNewFranchiseModal data={modal?.data} />
		) : modal.name === "RenewalDetailsModal" ? (
			<RenewalDetailsModal data={modal?.data} />
		) : modal.name === "FranchiseCreatedModal" ? (
			<FranchiseCreatedModal data={modal?.data} />
		) : modal.name === "EditMenuMasterModal" ? (
			<EditMenuMasterModal data={modal?.data} />
		) : modal.name === "DeleteMenuMasterModal" ? (
			<DeleteMenuMasterModal data={modal?.data} />
		) : modal.name === "EditSubMenuMasterModal" ? (
			<EditSubMenuMasterModal data={modal?.data} />
		) : modal.name === "DeleteSubMenuMasterModal" ? (
			<DeleteSubMenuMasterModal data={modal?.data} />
		) : modal.name === "RevenueModel" ? (
			<RevenueModal data={modal?.data} />
		) : modal.name === "PerUserModal" ? (
			<PerUserModal data={modal?.data} />
		) : modal.name === "DeleteRoleModal" ? (
			<DeleteRoleModal data={modal?.data} />
		) : (
			""
		);
	const dispatch = useDispatch();
	const openModal = (modalName, data = null) => dispatch(setModal({ name: modalName, data }));

	const closeModal = () => {
		dispatch(setModal(""));
		dispatch(setIsModalOpen(false));
	};

	return { dispatch, setModal, setIsModalOpen, renderModal, isModalOpen, openModal, closeModal };
}
