import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import InfoIcon from "../../assets/icons/information.png";

import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import ListBoxComponent from "../../shared/components/ListBox.component";
import PeopleIcon from "../../assets/icons/mdi_user-multiple-add-outline.png";
import { useNavigate } from "react-router";
import useToaster from "../../utils/hooks/useToaster";
import {
	useDeleteEmployeeMutation,
	useLazyGetAllEmployeesQuery,
	useLazyGetAreasQuery,
	useLazyGetCitesQuery,
} from "../../utils/Store";
import { InfoCircle, SearchNormal1, Location } from "iconsax-react";

import useSelectModal from "../../utils/hooks/useSelectModal";
import SelectMenu from "../../shared/components/SelectMenu.component";

export default function EmployeeManagementPage() {
	const navigate = useNavigate();
	const [getAllEmployees, { data: allEmployees, status: employeeListStatus }] = useLazyGetAllEmployeesQuery();
	const [allEmployeeData, setAllEmployeeData] = useState([]);
	const { setToast } = useToaster();
	const { openModal } = useSelectModal();
	const [selectedCity, setSelectedCity] = useState();
	const [selectedArea, setSelectedArea] = useState();
	const [getCites, { data: cities }] = useLazyGetCitesQuery();
	const [getAreas, { data: areaDetails }] = useLazyGetAreasQuery();

	const columns = [
		{
			header: "Employee Name",
			accessorKey: "name",
			// cell: ({ row, getValue }) => {
			// 	const { original } = row;
			// 	return (
			// 		<span className="truncate font-inter-medium">
			// 			{original.name}
			// 		</span>
			// 	);
			// },
		},
		{
			header: "Role",
			accessorKey: "roles",
		},
		{
			header: "Mobile",
			accessorKey: "phoneNumber",
		},
		{
			header: "Email",
			// accessorKey: "email",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium">
						{original.email}
					</span>
				);
			},
		},
		{
			header: "City",
			accessorKey: "cityName",
		},

		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							onClick={() => navigate(`/employee-management/${original?._id}`)}
							className={`h-5 w-5 cursor-pointer ${original?.roles[0] !== 'superadmin' ? 'visible' : 'invisible'}`}
						/>
						<img
							src={EditIcon}
							alt="edit"
							onClick={() => navigate(`/employee-management/edit/${original?._id}`)}
							className={`h-5 w-5 cursor-pointer ${original?.roles[0] !== 'superadmin' ? 'visible' : 'invisible'}`}
						/>
						<img
							src={DeleteIcon}
							alt="delete"
							onClick={() => handleDelete(original)}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

	useEffect(() => {
		getAllEmployees();
	}, []);

	useEffect(() => {
		if (allEmployees) setAllEmployeeData(allEmployees);
	}, [allEmployees]);

	const handleDelete = async (employeeData) => {
		openModal("DeleteEmployeeModal", { ...employeeData, hideCloseArrow: true });
	};

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Employee Management</h3>
						<Button
							className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
							onClick={() => navigate("/employee-management/add")}
						>
							Add New Employee
						</Button>
					</div>
				</div>
				<div className="bg-white p-5 mx-8 my-3 pb-2 h-[75vh] overflow-hidden brand-details-section bg-white-height">
					<div className="flex items-center justify-between">
						<div className="relative h-12 input-height-zoom">
							<input
								type="text"
								className="w-[18.35vw] outline-focus h-full border-none rounded-xl py-1.5 px-12 text-base font-normal bg-[#F1F1F1] placeholder-black"
								placeholder="Search Employee"
								onChange={(e) => {
									const searchedEmployees = allEmployees.filter((employee) =>
										employee.name.toLowerCase().includes(e.target.value?.toLowerCase())
									);
									setAllEmployeeData(searchedEmployees);
									if (e.target.value.length === 0) searchedEmployees(allEmployees);
								}}
							/>
							<div className="absolute left-4 top-[50%] translate-y-[-50%]">
								<SearchNormal1 color="gray" size={20} className="search-icon " />
							</div>
						</div>
						<div className=" items-center space-x-4 hidden">
							<h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
							<div className="">
								<SelectMenu
									placeholder="All Cities"
									options={cities}
									onChange={(option) => setSelectedCity(option)}
									value={selectedCity}
									icon={<Location className="h-5 w-5" />}
								/>
							</div>
							{/* <h1 className='text-base text-[#333333] font-normal'>Filter by Area:</h1>
 							<div className="">
 								<SelectMenu
									placeholder='All Areas' options={areaDetails}
 									onChange={(option) => setSelectedArea(option)}
 									value={selectedArea}
 									icon={<Location className="h-5 w-5" />}
								/>
							</div> */}
						</div>
					</div>

					<div className="w-full mb-10">
						<TableComponent
							data={allEmployeeData}
							columns={columns}
							customeClass='pr-3 max-h-[56vh]'
							customwidths={["w-[20%]", "w-[17.5%]", "w-[17.5%]", "w-[22.5%]", "w-[10%]", "w-[12.5%]"]}
							isLoading={employeeListStatus === "pending"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
