// export const calculatePrice = (discountType, totalPrice, discount, gst, includeGST) => {
//     let tempTotalPrice = totalPrice;
//     if (includeGST && gst > 0) {
//         tempTotalPrice = Number(totalPrice) + Number(+totalPrice * +values.gst * 0.01);
//     }
//     if (discountType === "percent") {
//         const calculatedFinalPrice = tempTotalPrice - discount * tempTotalPrice * 0.01;
//         return calculatedFinalPrice;
//     } else {
//         const calculatedFinalPrice = tempTotalPrice - discount;
//         return calculatedFinalPrice;
//     }
// };

export const calculatePrice = (afterGSTPrice, discountType, discountPrice, price) => {
	if(afterGSTPrice) return +afterGSTPrice.toFixed(2).replace(/\.00$/, "");
	// if(afterGSTPrice) return Math.ceil(+afterGSTPrice) ;

	// if (discountType === "percent") {
	// 	finalPrice = finalPrice - (finalPrice * discountPrice) / 100;
	// } else {
	// 	finalPrice = finalPrice - discountPrice;
	// }

	// if (Number.isInteger(finalPrice)) {
	// 	return finalPrice;
	// } else {
	// 	return +finalPrice.toFixed(2).replace(/\.00$/, "");
	// }
};
