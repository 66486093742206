import React, { useEffect } from 'react'
import { FiArrowLeft } from "react-icons/fi";
import Button from "../../shared/components/Button.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Add } from 'iconsax-react';
import TableComponent from '../../shared/components/Table.component';
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import useSelectModal from '../../utils/hooks/useSelectModal';
import { useAddAddressMutation, useLazyGetCustomerAddressQuery, useLazyGetVehicleAddressQuery, useUpdateVehicleAddressMutation } from '../../utils/Store';
import { useState } from 'react';
import useToaster from '../../utils/hooks/useToaster';
import { useRef } from 'react';

function CustomerAddress() {
    const [getCustomerAddress, { data: customerAddressList }] = useLazyGetCustomerAddressQuery();
    const [getVehicleAddress , {data: vehicleAddress, status:vehicleAddressStatus }] = useLazyGetVehicleAddressQuery()
    const [updateAddress, {status: updateStatus}] =useUpdateVehicleAddressMutation()
    const { setToast } = useToaster();

    const { state } = useLocation();
    const { openModal } = useSelectModal();
    const navigate = useNavigate();
    const { customerId } = useParams();
    const [addressData, setAddressData] = useState();
    const clickedAddressId = useRef()

    useEffect(() => {
        getAddress()
    }, [customerId, state?.vehicleId]);

    const getAddress = async() => {
        if (customerId && state?.vehicleId === "All Vehicles") {
            const {data, error} = await getCustomerAddress({ customerId })
            if(data) setAddressData(data)
        } else if(state?.vehicleId){
           const {data, error} = await getVehicleAddress(state.vehicleId)
           if(data) setAddressData(data)
        }
    }

    // useEffect(() => {
    //     if (customerAddressList) setAddressData(customerAddressList)
    // }, [customerAddressList]);

    const handleEditAddress = (addressData) => {
        openModal("AddEditAddress", { vehicleId: state.vehicleId, getAddress, vehicleAddress: addressData })
    }

    const updateDefault = async (addressData) => {
        if(addressData?.default) return 
        clickedAddressId.current = addressData?._id
        const { data: updatedData, error } = await updateAddress({
			addressId: addressData?._id,
			body: {...addressData, defaultAddress: true},
		})
        if(updatedData) {
			getAddress()
            setToast({ type: "success", msg: updatedData?.message });
		}
		if (error) {
			setToast({ type: "error", msg: error?.data?.message });
		}
    }

    const columns = [
        {
            header: "Sr.No",
            accessorKey: 'sr_no',
            cell: ({ row, getValue, index }) => {
                const { original } = row;
                return (
                    <span className="flex gap-14 items-center">
                        {row.index + 1}
                    </span>
                );
            },

        },
        {
            header: "Vehicle Number",
            accessorKey: 'vehicle_number',
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-14 items-center">{original.vehicleNumber}
                    </span>
                );
            },
        },
        {
            header: "Address",
            accessorKey: 'address',
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <div className="flex items-center w-full justify-between pr-10">
                        <span className="truncate">{original.addressLineOne}</span>
                    </div>
                );
            },
        },
        {
            header: "Landmark",
            accessorKey: 'landmark',
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <div className="flex items-center w-full justify-between pr-10">
                        <span className="truncate">{original.landmark}</span>
                    </div>
                );
            },
        },
        {
            header: "Area Code",
            accessorKey: 'area_code',
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span>
                        {original.areaCode}
                    </span>
                );
            },
        },
        {
            header: "City",
            accessorKey: 'city',
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span>{original.city}

                    </span>
                );
            },
        },
        {
            header: "State",
            accessorKey: 'state',
            cell: ({ row, getValue }) => {
                const { original } = row;
                const state = original.cityCoOrdinates?.placeSearchName?.split(",")[1]
                return (
                    <span>
                        {state}
                    </span>
                );
            },
        },
        {
            header: "",
            accessorKey: "address_new",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className='flex'>
                        <Button className={`text-sm text-white h-[2.4rem] rounded-[10px] w-[8vw] ${original?.default ? 'bg-[#FB0C0C] cursor-default' : 'bg-[#C3C3C3]'}`} onClick={()=>updateDefault(original)} isSubmitting={updateStatus === "pending" && original?._id === clickedAddressId.current}>
                            {original?.default ? "Default Address" : "Set as Address "}
                        </Button>
                    </span>
                );
            },
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-2">
                        <img
                            src={EditIcon}
                            alt=""
                            onClick={()=>handleEditAddress(original)}
                            className="h-5 w-5 cursor-pointer"
                        />
                        <img
                            src={DeleteIcon}
                            alt=""
                            onClick={() => openModal("CustomerDeleteModal", { ...original, hideCloseArrow: true, fetchAddress: getAddress })}
                            className="h-5 w-5 cursor-pointer"
                        />
                    </span>
                );
            },
        },
    ];

    return (
        <div className="bg-[#F5F5F5] h-full">
            <div className="space-y-3">
                <div className="px-8 pb-4 pt-8">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-4">
                            <FiArrowLeft
                                size={22}
                                onClick={() => navigate(-1)}
                                className="cursor-pointer back-arrow"
                            />
                            <h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">My Saved Address</h1>
                        </div>
                        <div className={` items-center ${state?.vehicleId === "All Vehicles" ? "hidden" : "flex"} `}>
                            <Button
                                onClick={() => openModal("AddEditAddress", { vehicleId: state.vehicleId, getAddress })}
                                className="2xl:text-base text-sm text-white cursor-pointer reb-btn-shadow 2xl:px-6 px-4"
                            >
                                <Add className='mr-1 customer-address-icon-ht text-white' color='#ffffff' />
                                Add New Address

                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white border rounded-xl px-5 mx-8 my-3 max-h-[70.4vh] h-auto overflow-hidden brand-details-section relative">
                <div className="w-full">
                    <TableComponent
                        data={addressData}
                        columns={columns}
                        customwidths={["w-[5%]", "w-[12.8%]", "w-[13.8%]", "w-[11.8%]", "w-[11.8%]", "w-[11.8%]", "w-[11.8%]", "w-[11.8%]", "w-[8.8%]"]}
                        isLoading={vehicleAddressStatus === "pending"}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomerAddress
