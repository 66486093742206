import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import DeleteIcon from "../../assets/icons/Delete.png";
import EditIcon from "../../assets/icons/Edit.png";
import CallIcon from "../../assets/icons/call-icon.png";
import WalletIcon from "../../assets/icons/wallet-icon.png";
import InfoIcon from "../../assets/icons/information.svg"
import { Message } from "iconsax-react";
import SelectMenu from '../../shared/components/SelectMenu.component';
import Button from '../../shared/components/Button.component';
import { IoLocationOutline } from "react-icons/io5";
import Tabs from '../../shared/components/Tabs.component';
import CustomerManagementVehicles from './vehicle-module/Customer_Management_Vehicles';
import CustomerManagementSubscriptions from './subscriptions-module/Customer_Management_Subscriptions';
import CustomerManagementOrders from './order-module/Customer_Management_Orders';
import CustomerManagementNotification from './notification-module/Customer_Management_Notification';
import useSelectModal from '../../utils/hooks/useSelectModal';
import { useLazyGetCustomerVehiclesQuery, useLazyGetCustomerWidgetsQuery, useLazyGetSingleCustomerQuery, useLazyGetVehicleDetailsQuery } from '../../utils/Store';
import Avatar from 'react-avatar';

function CustomerInfo() {
    const { openModal } = useSelectModal();
    const [getWidgetsData, { data: widgetsData }] = useLazyGetCustomerWidgetsQuery()
    const [getCustomer, { data: customerData }] = useLazyGetSingleCustomerQuery()
    const [getCustomerVehicles, { data: customerVehicleList, status: customerVehicleStatus }] = useLazyGetCustomerVehiclesQuery();
    const [getVehicleDetails, { data: vehicleDetails }] = useLazyGetVehicleDetailsQuery()
    const navigate = useNavigate();
    const { customerId } = useParams();
    useEffect(() => {
        if (customerId) {
            getCustomerVehicles({ customerId });
        }
    }, []);

    const selectVehicle = [
        ...(customerVehicleList?.map((vehicle) => ({
            ...vehicle,
            value: vehicle?._id,
            name: vehicle?.brandName,
        })) || []),
        { value: 'All Vehicles', name: 'All Vehicles' },
    ];

    const [selectedValue, setSelectedValue] = useState({ value: 'All Vehicles', name: 'All Vehicles' })
    const [vehicleData, setVehicleData] = useState(customerVehicleList);

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption);

        if (selectedOption.name === 'All Vehicles') {
            setVehicleData(customerVehicleList);
        } else {
            const selectedVehicle = customerVehicleList.find(
                (vehicle) => vehicle._id === selectedOption?._id
            );
            setVehicleData(selectedVehicle);
        }
    };

    useEffect(() => {
        if (customerVehicleList) {
            setSelectedValue({ value: 'All Vehicles', name: 'All Vehicles' });
            setVehicleData(customerVehicleList);
        }
    }, [customerVehicleList]);

    useEffect(() => {
        if (selectedValue?.value === 'All Vehicles' || !selectedValue?.value) return
        fetchVehicleDetails()

    }, [vehicleData])

    const fetchVehicleDetails = async () => {
        getVehicleDetails(selectedValue?.value)
    }

    useEffect(() => {
        getCustomer(customerId)
    }, [])

    useEffect(() => {
        getWidgetsData(customerId)
    }, [])

    useEffect(() => {
        setVehicleData(customerVehicleList);
    }, [])

    const [selectedIndex, setSelectedIndex] = useState(0)
    let categories = [
        {
            title: `Vehicles`,
            status: "vehicles",
            component: <CustomerManagementVehicles
                vehicleDataInfo={vehicleData}
                selectedValue={selectedValue}
                singleVehicleDetails={vehicleDetails}
                fetchVehicleDetails={fetchVehicleDetails}
            />

        },
        {
            title: `Subscription `,
            status: "subscription",
            component: <CustomerManagementSubscriptions />,
        },
        {
            title: `Orders`,
            status: "orders",
            component: <CustomerManagementOrders customerId={customerId} />,
        },
        {
            title: `Notification`,
            status: "notification",
            component: <CustomerManagementNotification />,
        },
        {
            title: `Service Reminder`,
            status: "serviceReminder",
            // component: ,
        },
        {
            title: `Escalations`,
            status: "escalations",
            // component: ,
        },
    ];

    const getDeleteModalConfig = (customerData, fetchCustomers, navigate) => ({
        ...customerData,
        hideCloseArrow: true,
        fetchCustomers: fetchCustomers,
        fetchCustomers: async () => {
            await fetchCustomers();
            navigate(-1);
        },
    });

    return (
        <div className="h-full pl-8 2xl:pr-24 pb-4 pr-16 pt-8 w-full bg-[#F5F5F5]">
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="flex items-center space-x-4">
                        <FiArrowLeft
                            className="cursor-pointer back-arrow"
                            size={22}
                            onClick={() => navigate(-1)}
                        />
                        <h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                            {customerData?.name}
                        </h1>
                    </div>
                </div>
                <div className="flex justify-between mt-5">
                    <div className="py-5 px-6 bg-[#FFFFFF] rounded-xl w-[65.0%] 2xl:w-[67%]">
                        <div className='flex justify-between items-center'>
                            <div className="flex items-center">
                                {/* <div className='w-16 h-16 rounded-full bg-[#F5F5F5]'>
                                    <img className='w-full h-full' src="" alt="" />
                                </div> */}
                                <Avatar
                                    name={customerData?.name}
                                    size="60"
                                    round={true}
                                    color="#F1F1F1"
                                    fgColor="#000"
                                    textSizeRatio="2"
                                />
                                <h1 className="text-base font-inter-bold ml-4">{customerData?.name}</h1>
                            </div>

                            <div className='flex'>
                                <img className='cursor-pointer' src={EditIcon} onClick={() => openModal("CustomerEditModal", { ...customerData, fetchCustomers: () => { } })}
                                    alt="" />
                                <img className='ml-4 cursor-pointer' src={DeleteIcon} onClick={() => openModal("CustomerDeleteModal", getDeleteModalConfig(customerData, getCustomer, navigate))}
                                    alt="" />
                            </div>
                        </div>

                        <div className='flex 2xl:mt-8 mt-4'>
                            <div className='flex items-center mr-6'>
                                <img className='w-4 h-4' src={CallIcon} alt="" />
                                <p className='text-[#474747] text-base ml-2'>{customerData?.phoneNumber}</p>
                            </div>

                            <div className='flex items-center'>
                                <Message className='text-[#474747] w-5 h-5' />
                                <p className='text-[#474747] text-base ml-2'>{customerData?.email}</p>
                            </div>
                        </div>

                        <div className='2xl:mt-6 mt-3 flex items-center justify-between'>
                            <div className='flex items-center customer-info-select-vehicle'>
                                <p className='text-base mr-6'>Select Vehicle:</p>
                                <SelectMenu
                                    options={selectVehicle}
                                    value={selectedValue}
                                    onChange={handleSelectChange}
                                    showCheckbox={false}
                                    multiSelectClass="w-[17vw]"
                                    className="vehicle-dropdown-height"

                                />
                            </div>

                            <div className='flex'>
                                <Button
                                    className="2xl:text-base text-sm text-white cursor-pointer 2xl:px-4 px-4 py-2 w-[9vw]"

                                    customeHeight="h-10"
                                    type="submit"
                                    onClick={() => navigate(`/customer-management/${customerId}/address`, { state: { vehicleId: selectedValue?.value } })}
                                >
                                    <IoLocationOutline
                                        className='w-5 h-5 mr-3' />

                                    Addresses
                                </Button>

                                <Button
                                    className="2xl:text-base cursor-not-allowed text-sm text-white 2xl:px-4 px-4 ml-8 w-[9vw]"
                                    customeHeight="h-10"
                                    type="submit"

                                >
                                    <img className='w-5 h-5 mr-3' src={WalletIcon} alt="" />
                                    Wallet
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className='w-[16%] 2xl:w-[15%] flex flex-col justify-between'>
                        <div className='py-4 space-y-1 2xl:px-5 px-4 bg-[#FFFFFF] rounded-xl w-full h-[45%]'>
                            <div className='flex justify-between'>
                                <p className='text-base'>No. of Vehicle</p>
                                <img
                                    className='h-6 w-6 cursor-pointer'
                                    src={InfoIcon} alt=""
                                    onClick={() => navigate(`/customer-management/${customerId}/customer-vehicles`)}
                                />
                            </div>
                            <p className='2xl:text-2xl text-xl font-inter-medium'>{widgetsData?.noOfVehicles}</p>
                        </div>

                        <div className='py-4 space-y-1 2xl:px-5 px-4 bg-[#FFFFFF] rounded-xl w-full h-[45%]'>
                            <div className='flex justify-between'>
                                <p className='text-base'>Due Amount</p>
                                <img
                                    className='h-6 w-6 cursor-pointer'
                                    src={InfoIcon} alt=""
                                    onClick={() => navigate(`/customer-management/${customerId}/due-amount`)}
                                />
                            </div>
                            <p className='2xl:text-2xl text-xl font-inter-medium'>{widgetsData?.dueAmount}</p>
                        </div>
                    </div>

                    <div className='w-[16%] 2xl:w-[15%] flex flex-col justify-between'>
                        <div className='py-4 space-y-1 2xl:px-5 px-4 bg-[#FFFFFF] rounded-xl w-full h-[45%]'>
                            <p className='text-base'>Total Service</p>
                            <p className='2xl:text-2xl text-xl font-inter-medium'>{widgetsData?.serviceCount}</p>
                        </div>
                        <div className='py-4 space-y-1 2xl:px-5 px-4 bg-[#FFFFFF] rounded-xl w-full h-[45%]'>
                            <p className='text-base'>Total Business</p>
                            <p className='2xl:text-2xl text-xl font-inter-medium'>{widgetsData?.totalBusiness}</p>
                        </div>
                    </div>
                </div>

                <div className='2xl:mt-5 mt-3'>
                    <Tabs
                        categories={categories}
                        className="w-full"
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex} />
                </div>

                <div className="max-h-[60vh] 2xl:mt-5 mt-3">
                    {categories[selectedIndex].component}
                </div>
            </div>
        </div>
    )
}

export default CustomerInfo