import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../shared/components/Button.component";
import FormField from "../../shared/components/FormField.component";
import ImageLoadFormField from "../../shared/components/Image_Load_Form_Field.component";
import {
	useAddEmployeeMutation,
	useGetAllFranchiseMutation,
	useLazyGetAllCityManagersQuery,
	useLazyGetAreasQuery,
	useLazyGetCitesQuery,
	useLazyGetCityManagersCityWiseQuery,
	useLazyGetEmployeeDetailsQuery,
	useLazyGetSupervisorsFromCityQuery,
	useUpdateEmployeeMutation,
} from "../../utils/Store";
import { FiArrowLeft } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import useToaster from "../../utils/hooks/useToaster";
import useUploadImage from "../../utils/hooks/useUploadImage";
import closeIcon from "../../assets/icons/close.svg";
import useSelectModal from "../../utils/hooks/useSelectModal";
import SelectMenu from "../../shared/components/SelectMenu.component";
import ReactFlagsSelect from "react-flags-select";
import { useLazyGetAllowanceQuery } from "../../utils/Services/Apis/employeeMasterData";
import { getUniqueArray } from "../../utils/helper/uniqueArray";
import Calender from "../../shared/components/Calender";

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Employee Name is required"),
	email: Yup.string().email("Invalid email address").required("Email is required"),

	phoneNumber: Yup.string()
		.required("Mobile Number is required")
		.matches(/^\d{10}$/, "Please enter 10 digits Mobile Number"),
	city: Yup.object().required("City is required"),

	role: Yup.object().required("Role is required"),
	address: Yup.string().required("Address is required"),
	joiningDate: Yup.string().required("Joining Date is required"),
	kycDocuments: Yup.mixed().required("Document is required"),
	bankdoc: Yup.mixed().required("Document photo is required"),
	accountNumber: Yup.string().required("Account Number is required"),
	ifscCode: Yup.string()
		.matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code")
		.required("IFSC code is required"),
	fixedSalary: Yup.number().required("Fixed Salary field is required"),
	incentive: Yup.number(),
	documentType: Yup.object().required("Upload document is required"),
	kycDocArray: Yup.array().min(1, "Add at least one kyc document"),
	area: Yup.array().when("role", {
		is: (role) => role?.value === "rider",
		then: () => Yup.array().min(1, "Area is required"),
		otherwise: () => Yup.array().notRequired(),
	}),
});

export default function AddNewEmployee() {
	const navigate = useNavigate();
	const { employeeId } = useParams();
	const { state } = useLocation();
	const [addNewEmployee, { status: status1 }] = useAddEmployeeMutation();
	const [updateEmployee, { status: status2 }] = useUpdateEmployeeMutation();
	const [getEmployeeDetails, { data: employeeDetails }] = useLazyGetEmployeeDetailsQuery();
	const [getAllowance, { data: allowanceList }] = useLazyGetAllowanceQuery();
	const [getCities, { data: existingCitiesList }] = useLazyGetCitesQuery();
	const { setToast } = useToaster();
	const { uploadImages } = useUploadImage();
	const { openModal } = useSelectModal();
	const [reinitialize, setReInitialize] = useState(!!employeeId);
	const [kycDocArray, setKycDocArray] = useState([]);
	const [allOtherAllowances, setAllOtherAllowances] = useState([]);
	const [selected, setSelected] = useState("IN");

	const [cities, setCities] = useState([]);

	const [addDocumentValidation, setAddDocumentValidation] = useState({});
	const [AllowanceValidation, setAddAllowanceValidation] = useState({});

	const cityRef = useRef();
	const roleRef = useRef();

	const roles = [
		{ _id: 2, name: "Rider", value: "rider" },
		{ _id: 3, name: "Supervisor", value: "supervisor" },
		{ _id: 4, name: "City Manager", value: "citymanager" },
		{ _id: 5, name: "Call Center", value: "callcenter" },
		{ _id: 6, name: "HR", value: "hr" },
		{ _id: 7, name: "Franchise Owner", value: "franchiseowner" },
		{ _id: 8, name: "Warehouse Manager", value: "warehousemanager" },
		{ _id: 9, name: "Account Manager", value: "accountmanager" },
	];

	const kycDocList = [
		{ _id: 1, name: "PAN Card", value: "pan" },
		{ _id: 2, name: "Aadhaar Card", value: "aadhaar" },
	];

	const bankVerificationDocument = [
		{ _id: 2, name: "Balance Statement", value: "balancestatement" },
		{ _id: 3, name: "Cancel Cheque", value: "cancelcheque" },
	];

	const [cityAreaList, setCityAreaList] = useState([]);

	const [supervisorCityManagerList, setSupervisorCityManagerList] = useState([]);

	useEffect(() => {
		handleGetCities();
		getAllowance();
	}, []);

	const handleGetCities = async () => {
		let { data, error } = await getCities();
		if (data) {
			setCities(data);
		}
	};

	useEffect(() => {
		if (employeeId) {
			handleGetEmployeeDetail();
		}
	}, [employeeId]);

	const addEmployee = async (values) => {
		const expected = {
			name: values.name,
			phoneNumber: "+91" + values.phoneNumber,
			roles: values.role.value,
			email: values.email,
			address: values.address,
			cityId: values.city._id,
			assignee: values?.supervisorCityManager?._id,
			joiningDate: values.joiningDate,
			kycDocuments: kycDocArray,
			fixedSalary: +values.fixedSalary,
			incentive: +values.incentive,
			salaryAllowance: allOtherAllowances,
			accountNumber: +values.accountNumber,
			ifscCode: values.ifscCode,
			bankDocuments: [
				{
					documentType: values.documentType.value,
					imageUrl: employeeDetails?.bankDocuments[0]?.imageUrl,
				},
			],
		};

		if (values.franchise) {
			expected.franchiseId = values.franchise._id;
		}

		if (values?.area) {
			expected.areaId = values?.area?.map((area) => area._id);
		} else if (values.role.value === "rider") expected.areaId = [];

		if (employeeId) {
			if (values.bankdoc instanceof FileList) {
				let imageUrlArray = await uploadImages(values.bankdoc);
				if (imageUrlArray) {
					expected.bankDocuments = [
						{
							documentType: values.documentType.value,
							imageUrl: imageUrlArray[0],
						},
					];
					const { data, error } = await updateEmployee({ employeeId: employeeId, body: expected });
					if (data) {
						if (state?.isFromRidersPage) {
							setToast({ type: "success", msg: "Rider details updated successfully" });
							navigate("/rider-management/all-riders");
							return;
						}
						if (state?.isFromRiderInfoPage) {
							setToast({ type: "success", msg: "Rider details updated successfully" });
							navigate(`/rider-management/all-riders/${employeeId}`);
							return;
						}

						setToast({ type: "success", msg: data.message });
						navigate("/employee-management");
					} else {
						setToast({ type: "error", msg: error.data.message });
					}
				}
			} else {
				expected.bankDocuments = [
					{
						documentType: values.documentType.value,
						imageUrl: employeeDetails?.bankDocuments[0]?.imageUrl,
					},
				];
				const { data, error } = await updateEmployee({ employeeId: employeeId, body: expected });
				if (data) {
					if (state?.isFromRidersPage) {
						setToast({ type: "success", msg: "Rider details updated successfully" });
						navigate("/rider-management/all-riders");
						return;
					}
					if (state?.isFromRiderInfoPage) {
						setToast({ type: "success", msg: "Rider details updated successfully" });
						navigate(`/rider-management/all-riders/${employeeId}`);
						return;
					}
					setToast({ type: "success", msg: data.message });
					navigate("/employee-management");
				} else {
					setToast({ type: "error", msg: error.data.message });
				}
			}
			return;
		}

		let imageUrlArray = await uploadImages(values.bankdoc);
		if (imageUrlArray) {
			expected.bankDocuments = [
				{
					documentType: values.documentType.value,
					imageUrl: imageUrlArray[0],
				},
			];
			const { data, error } = await addNewEmployee(expected);
			if (data) {
				setToast({ type: "success", msg: "Employee Added Successfully" });
				navigate("/employee-management");
			} else {
				setToast({ type: "error", msg: error.data.message });
			}
		}
	};

	const handleGetEmployeeDetail = () => {
		getEmployeeDetails(employeeId);
	};

	useEffect(() => {
		if (employeeDetails) {
			setAllOtherAllowances(employeeDetails.salaryAllowance);
			setKycDocArray(employeeDetails.kycDocuments);
		}
	}, [employeeDetails]);

	if (employeeId && !employeeDetails) {
		return null;
	}
	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="gap-3">
				<div className="px-5 pt-8 pb-4">
					<div className="flex items-center space-x-2">
						<FiArrowLeft
							size={22}
							onClick={() => navigate("/employee-management")}
							className="cursor-pointer back-arrow"
						/>
						<label className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
							{employeeId ? "Edit" : "Add New"} Employee
						</label>
					</div>
				</div>
			</div>
			<div className="">
				<Formik
					enableReinitialize={reinitialize}
					initialValues={{
						name: employeeId ? employeeDetails?.name : "",
						bankdoc: employeeId ? employeeDetails?.bankDocuments[0]?.imageUrl : null,
						city: employeeId
							? cities[cities.findIndex((e) => e._id === employeeDetails?.cityId)]
							: "",
						area: employeeId ? employeeDetails?.areas : [],
						email: employeeId ? employeeDetails?.email : "",
						phoneNumber: employeeId ? employeeDetails?.phoneNumber?.slice(3) : "",
						role: employeeId
							? roles[roles.findIndex((e) => e.value === employeeDetails?.roles[0])]
							: "",
						supervisor: "",
						kycDocuments: {},
						accountNumber: employeeId ? employeeDetails?.accountNumber : "",
						ifscCode: employeeId ? employeeDetails?.ifscCode : "",
						address: employeeId ? employeeDetails?.address : "",
						joiningDate: employeeId ? new Date(employeeDetails?.joiningDate) : "",
						kyc: "",
						documentType: employeeId
							? bankVerificationDocument[
									bankVerificationDocument.findIndex(
										(e) => e.value === employeeDetails?.bankDocuments[0].documentType
									)
							  ]
							: "",
						otherAllowance: employeeId ? employeeDetails?.city : "",
						supervisorCityManager: employeeId
							? supervisorCityManagerList[
									supervisorCityManagerList.findIndex((e) => e._id === employeeDetails?.assignee)
							  ]
							: "",
						fixedSalary: employeeId ? employeeDetails?.fixedSalary : "",
						incentive: employeeId ? +employeeDetails?.incentive : "",
						kycDocArray: employeeId ? employeeDetails?.bankDocuments : [],
					}}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						addEmployee(values);
					}}
				>
					{({ isSubmitting, values, errors, touched, setFieldValue, setFieldError }) => (
						<Form className="flex 2xl:gap-5 gap-2.5">
							<div className="w-[75%] overflow-y-scroll h-[80vh] pl-8">
								<div>
									<MemorizedEmployeeDetails
										values={values}
										setFieldValue={setFieldValue}
										errors={errors}
										touched={touched}
										employeeDetails={employeeDetails}
										supervisorCityManagerList={supervisorCityManagerList}
										roles={roles}
										cities={cities}
										selected={selected}
										cityAreaList={cityAreaList}
										cityRef={cityRef}
										roleRef={roleRef}
										setSupervisorCityManagerList={setSupervisorCityManagerList}
										setCityAreaList={setCityAreaList}
										setReInitialize={setReInitialize}
									/>
								</div>
								<div>
									<MemorizedKycDocument
										setFieldError={setFieldError}
										addDocumentValidation={addDocumentValidation}
										setAddDocumentValidation={setAddDocumentValidation}
										uploadImages={uploadImages}
										kycDocArray={kycDocArray}
										setKycDocArray={setKycDocArray}
										kycDocList={kycDocList}
										openModal={openModal}
										values={values}
										setFieldValue={setFieldValue}
										errors={errors}
										touched={touched}
									/>
								</div>
								<div>
									<MemorizedSalaryDetails
										allOtherAllowances={allOtherAllowances}
										setAddAllowanceValidation={setAddAllowanceValidation}
										setAllOtherAllowances={setAllOtherAllowances}
										allowanceList={allowanceList}
										AllowanceValidation={AllowanceValidation}
										values={values}
										setFieldValue={setFieldValue}
									/>
								</div>
								<div>{/* <ImageSelector /> */}</div>
								<div className="flex justify-center gap-5 pb-5 mt-5">
									<Button
										className="w-[30%] bg-[#ECEBED] text-black border cursor-pointer bottom-btn-ht font-inter-medium rounded-[10px]"
										onClick={() => navigate(-1)}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										className="bg-[#FB0C0C] w-[30%] whitespace-nowrap text-white cursor-pointer bottom-btn-ht font-inter-medium rounded-[10px]"
										isSubmitting={status1 === "pending" || status2 === "pending"}
									>
										{employeeId ? "Save Changes" : "Add New Employee"}
									</Button>
								</div>
							</div>
							<div className="w-[18vw] py-5">
								<MemorizedBankDetails
									values={values}
									setFieldValue={setFieldValue}
									errors={errors}
									touched={touched}
									bankVerificationDocument={bankVerificationDocument}
									employeeDetails={employeeDetails}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}

const SalaryDetails = ({
	allOtherAllowances,
	setAddAllowanceValidation,
	setAllOtherAllowances,
	allowanceList,
	AllowanceValidation,
	values,
	setFieldValue,
}) => {
	const handleAddAllowance = () => {
		setAddAllowanceValidation(() => {});
		if (!values?.otherAllowance?.value || !values?.amount) {
			!values?.otherAllowance?.value &&
				setAddAllowanceValidation((AllowanceValidation) => ({
					...AllowanceValidation,
					type: "Please Select Allowance",
				}));
			!values?.amount &&
				setAddAllowanceValidation((AllowanceValidation) => ({
					...AllowanceValidation,
					amount: "Please add amount",
				}));
		} else {
			setAllOtherAllowances([
				{
					type: values?.otherAllowance?.name,
					amount: values?.amount,
					_id: values?.otherAllowance?._id,
				},
				...allOtherAllowances,
			]);
			setFieldValue("otherAllowance", "");
			setFieldValue("amount", "");
			setAddAllowanceValidation({});
		}
	};

	const uniqueAllowanceOptions = getUniqueArray(allowanceList, allOtherAllowances);
	const totalAllowance = allOtherAllowances?.reduce((a, e) => a + +e?.amount, 0);
	return (
		<div className="p-5">
			<div className="mb-3 text-lg font-inter-bold">Salary details</div>
			<div className="grid grid-cols-3 p-5 bg-white border border-gray-200 gap-x-5 gap-y-5 rounded-2xl">
				<div>
					<FormField
						field={{
							label: "Fixed Salary",
							name: "fixedSalary",
							type: "text",
							placeholder: "Enter fixed salary",
							onKeyPress: (event) => {
								if (/[^0-9 ]/.test(event.key)) {
									return event.preventDefault();
								}
							},
						}}
					/>
					<FormField
						field={{
							label: "Incentives",
							name: "incentive",
							type: "text",
							placeholder: "Enter incentives, if any",
							onKeyPress: (event) => {
								if (/[^0-9 ]/.test(event.key)) {
									return event.preventDefault();
								}
							},
						}}
					/>
				</div>
				<div className="grid grid-cols-2 col-span-2 gap-x-5">
					<div className="mt-1">
						<SelectMenu
							label="Other Allowance"
							name="otherAllowance"
							options={uniqueAllowanceOptions}
							onChange={(option) => setFieldValue("otherAllowance", option)}
							value={values.otherAllowance}
							placeholder="Select Allowances, if any"
							showCheckboxOnRight={false}
						/>
						{AllowanceValidation?.type ? (
							<div className="text-sm text-red-600">{AllowanceValidation?.type}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
						<div className="flex flex-wrap gap-2">
							{allOtherAllowances?.map((allowance, index) => (
								<div
									className="flex gap-2 border text-sm rounded-lg px-3 py-1 w-max items-center h-[1.8vw] overflow-x-auto final-price-scrollbar"
									key={index}
								>
									<div className="text-sm">{allowance?.type}</div>
									<div className="text-sm whitespace-nowrap ">₹ {allowance.amount}</div>
									<div className="flex items-center justify-center w-6">
										<img
											src={closeIcon}
											alt=""
											className="w-2 h-2 cursor-pointer "
											onClick={() => {
												setAllOtherAllowances(allOtherAllowances.filter((e, i) => i !== index));
											}}
										></img>
									</div>
								</div>
							))}
							{totalAllowance ? (
								<div className="w-full mt-1 text-sm">Total Allowance - ₹ {totalAllowance}</div>
							) : (
								<></>
							)}
						</div>
					</div>
					<div className="flex gap-4 ">
						<div className="col-span-1 ">
							<FormField
								nonFormikError
								field={{
									label: "Amount",
									name: "amount",
									type: "text",
									placeholder: "₹ 0",
									onKeyPress: (event) => {
										if (/[^0-9 ]/.test(event.key)) {
											return event.preventDefault();
										}
									},
								}}
							/>
							{AllowanceValidation?.amount ? (
								<div className="text-sm text-red-600">{AllowanceValidation?.amount}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<Button
							className="w-full bg-black whitespace-nowrap mt-7 text-white cursor-pointer h-[2.4rem]"
							onClick={() => {
								handleAddAllowance();
							}}
						>
							Add Allowance
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const MemorizedSalaryDetails = React.memo(SalaryDetails);

const KycDocument = ({
	setFieldError,
	addDocumentValidation,
	setAddDocumentValidation,
	uploadImages,
	kycDocArray,
	setKycDocArray,
	kycDocList,
	openModal,
	values,
	setFieldValue,
	errors,
	touched,
}) => {
	const handleAddDocument = async () => {
		setAddDocumentValidation(() => {});
		if (!values?.kyc?.value || !values?.documentNumber || !values[values.kyc?.value]) {
			!values?.kyc &&
				setAddDocumentValidation((addDocumentValidation) => ({
					...addDocumentValidation,
					kyc: "Please Select Document",
				}));
			!values?.documentNumber &&
				setAddDocumentValidation((addDocumentValidation) => ({
					...addDocumentValidation,
					documentNumber: "Add Document Number",
				}));

			!values[values.kyc?.value] &&
				setAddDocumentValidation((addDocumentValidation) => ({
					...addDocumentValidation,
					docValidationPhoto: "Please Select Photo",
				}));
		} else {
			let imageUrlArray = await uploadImages(values[values.kyc?.value]);
			const updatedArray = [
				...kycDocArray,
				{
					name: values.kyc?.value,
					documentNumber: values.documentNumber,
					imageUrls: imageUrlArray,
					label: values.kyc?.name,
					_id: values.kyc?._id,
				},
			];
			if (imageUrlArray) {
				setKycDocArray(updatedArray);
				setFieldValue("documentNumber", "");
				setFieldValue("kyc", "");
				setAddDocumentValidation({});
				setFieldValue("kycDocArray", updatedArray);
				setFieldValue(values.kyc?.value, "");
			}
		}
	};

	useEffect(() => {
		if (kycDocArray?.length) setFieldError("kycDocArray", "");
	}, [kycDocArray]);

	const uniqueKycOptions = getUniqueArray(kycDocList, kycDocArray);
	return (
		<div className="px-5">
			<div className="mb-3 text-lg font-inter-bold">KYC details</div>
			<div className="grid grid-cols-3 p-5 bg-white border border-gray-200 gap-x-5 rounded-2xl">
				<div className="space-y-3">
					<div>
						<SelectMenu
							label="Document"
							name="kyc"
							options={uniqueKycOptions}
							onChange={(option) => setFieldValue("kyc", option)}
							value={values.kyc}
							placeholder="Select Document"
							showCheckboxOnRight={false}
						/>
						{addDocumentValidation?.kyc ? (
							<div className="text-sm text-red-600">{addDocumentValidation?.kyc}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<div>
						<FormField
							disabled={!values.kyc}
							nonFormikError
							field={{
								label: "Document Number",
								name: "documentNumber",
								type: "text",
								placeholder: "Enter Document Number",
								readOnly: !values.kyc,
								onKeyPress: (event) => {
									if (values.kyc.value === "pan") {
										if (/[^a-zA-Z0-9]/.test(event.key) || event.target.value?.length > 9) {
											return event.preventDefault();
										}
										if (/[a-z]/.test(event.key)) {
											const uppercaseLetter = event.key.toUpperCase();
											event.target.value = (event.target.value || "") + uppercaseLetter;
											event.preventDefault();
										}
									}
									if (values.kyc.value === "aadhaar") {
										if (/[^0-9]/.test(event.key) || event.target.value?.length > 11) {
											return event.preventDefault();
										}
									}
								},
							}}
						/>
						{addDocumentValidation?.documentNumber ? (
							<div className="text-sm text-red-600">{addDocumentValidation?.documentNumber}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<div className="text-xs font-normal text-[#757575]">
						Note: Please upload your document not more than 1MB multiple photos/documents can be
						attached.
					</div>
				</div>
				<div>
					<div className="mb-1 text-sm">Upload the Document</div>
					<div>
						<ImageLoadFormField
							bottomText={"png / jpg format"}
							name={values?.kyc?.value}
							multiple={true}
							imgFormat="image/png, image/jpg"
							fileSize={1024}
							isUploadImgBtnDisabled={!values?.kyc?.value}
						/>
						{addDocumentValidation?.docValidationPhoto ? (
							<div className="text-sm text-red-600">
								{addDocumentValidation?.docValidationPhoto}
							</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>

				<div>
					<Button
						className="w-full bg-black mt-7 text-white cursor-pointer h-[2.4rem]"
						onClick={() => handleAddDocument()}
					>
						Add Document
					</Button>
				</div>
				<hr className="col-span-3 mt-5 mb-5 border border-gray-100" />
				{errors?.kycDocArray && touched?.kycDocArray && (
					<div className="text-sm text-red-600">{errors?.kycDocArray}</div>
				)}
				<div className="flex col-span-3 gap-10">
					{kycDocArray?.map((doc, index) => (
						<div
							className="flex w-[calc(175px+4.549vw)] justify-between bg-white rounded-xl border items-center px-4 py-4 relative"
							key={index}
						>
							<div className="flex-1 pr-4">
								<div className="text-sm font-inter-regular">
									{doc?.label ? doc?.label : doc?.name}
								</div>
								<hr className="my-3 text-gray-100" />
								<div className="text-sm font-inter-regular">{doc?.documentNumber}</div>
							</div>
							<div
								className="rounded-lg flex border h-[calc(40px+1.042vw)] w-[calc(40px+1.042vw)] relative cursor-pointer"
								onClick={() => openModal("PreviewDocImage", doc.imageUrls)}
							>
								<img
									src={doc.imageUrls && doc.imageUrls[0]}
									alt=""
									className="h-[50px] w-[50px] rounded overflow-hidden m-auto"
								></img>
								<div className="absolute text-white bg-[#02AC5B] rounded-full -bottom-1 -right-1 h-5 w-5 text-center text-sm">
									{doc.imageUrls?.length}
								</div>
							</div>
							<div className="absolute flex items-start justify-end w-6 cursor-pointer right-2 top-2">
								<img
									src={closeIcon}
									alt=""
									className="w-2 h-2"
									onClick={() => {
										const updatedArray = kycDocArray.filter((e) => e.name !== doc.name);
										setKycDocArray(updatedArray);
										setFieldValue("kycDocArray", updatedArray);
									}}
								></img>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const MemorizedKycDocument = React.memo(KycDocument);

const BankDetails = ({
	values,
	setFieldValue,
	errors,
	touched,
	bankVerificationDocument,
	employeeDetails,
}) => {
	return (
		<div className="space-y-3">
			<div className="mb-3 text-lg font-semibold">Bank details</div>
			<FormField
				field={{
					label: "Account Number",
					name: "accountNumber",
					type: "text",
					placeholder: "Enter bank account number",
					onKeyPress: (event) => {
						if (/[^0-9]/.test(event.key)) {
							return event.preventDefault();
						}
					},
				}}
			/>
			<FormField
				field={{
					label: "IFSC code",
					name: "ifscCode",
					type: "text",
					placeholder: "Enter ifsc code",
					onKeyPress: (event) => {
						if (/[^a-zA-Z0-9 ]/.test(event.key) || event.target.value.length > 10) {
							return event.preventDefault();
						}
					},
				}}
			/>
			<div>
				<SelectMenu
					label="Upload Document"
					name="documentType"
					options={bankVerificationDocument}
					onChange={(option) => setFieldValue("documentType", option)}
					value={values.documentType}
					placeholder="Upload Document to verify bank account"
					showCheckboxOnRight={false}
				/>
				{errors.documentType && touched.documentType ? (
					<div className="text-sm text-red-600 error">{errors?.documentType}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>
			<div>
				<ImageLoadFormField
					bottomText={"Upload Document"}
					name="bankdoc"
					imageUrl={employeeDetails?.bankDocuments[0]?.imageUrl}
					instructions="Note: Please upload your document not more than 1MB."
					imgFormat="image/jpeg, application/pdf"
					fileSize={1024}
					customClass="bg-white"
				/>
				{errors.bankdoc && touched.bankdoc ? (
					<div className="text-sm text-red-600 error">{errors?.bankdoc}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>
		</div>
	);
};

const MemorizedBankDetails = React.memo(BankDetails);

const EmployeeDetails = ({
	values,
	setFieldValue,
	errors,
	touched,
	employeeDetails,
	supervisorCityManagerList,
	roles,
	cities,
	selected,
	cityAreaList,
	cityRef,
	roleRef,
	setSupervisorCityManagerList,
	setCityAreaList,
	setReInitialize,
}) => {
	const [getCityManagers] = useLazyGetAllCityManagersQuery();
	const [getCitySupervisors] = useLazyGetSupervisorsFromCityQuery();
	const [getCityManagersByCity] = useLazyGetCityManagersCityWiseQuery();
	const [getAreasByCity] = useLazyGetAreasQuery();
	const [getAllFranhcise, { data: franchiseData, status: franchiseDataStatus }] =
		useGetAllFranchiseMutation();

	const handleSetSupervisorList = async () => {
		///to prevent extra call when some state changed
		if (cityRef.current === values?.city._id && roleRef.current === values?.role?.value) {
			return; // Skip the effect logic
		}
		cityRef.current = values?.city._id;
		roleRef.current = values?.role?.value;

		if (values?.role?.value === "rider") {
			const { data, error } = await getCitySupervisors(values?.city?._id);
			if (data) {
				setSupervisorCityManagerList(data);
			}
			if (error) {
				console.log(error);
			}

			const { data: d, error: err } = await getAreasByCity(values?.city?._id);
			if (d) {
				setCityAreaList(d);
			}
			if (err) {
				console.log(err);
			}
			setReInitialize(false);
		}
		if (values?.role?.value === "supervisor") {
			const { data, error } = await getCityManagersByCity(values?.city?._id);
			if (data) {
				setSupervisorCityManagerList(data);
			}
			if (error) {
				console.log(error);
			}
			setReInitialize(false);
		}
		if (
			(employeeDetails && values?.city?._id !== employeeDetails?.cityId) ||
			values?.role?.value !== employeeDetails?.roles[0]
		) {
			setFieldValue("supervisorCityManager", "");
			setFieldValue("area", []);
		}
	};

	useEffect(() => {
		if (values?.city?._id) {
			handleSetSupervisorList();
		}
	}, [values?.city?._id, values?.role?.value]);

	useEffect(() => {
		if (values?.city?._id) {
			if (values?.area?.length) {
				getAllFranhcise({
					body: {
						cityId: [values?.city?._id],
						areaId: values?.area?.map((area) => area._id),
						franchiseLevel: "all",
						status: "all",
					},
					params: { page: 1, pageSize: 1000 },
				});
				return;
			}
			if (values.city)
				getAllFranhcise({
					body: { cityId: [values?.city?._id], franchiseLevel: "all", status: "all" },
					params: { page: 1, pageSize: 1000 },
				});
		}
	}, [values?.city?._id, values?.area]);

	useEffect(() => {
		if (
			!!franchiseData?.franchise &&
			!franchiseData?.franchise?.find((franchise) => franchise._id === values.franchise?._id)
		)
			setFieldValue("franchise", "");
	}, [values?.city?._id, values?.area, franchiseData]);

	useEffect(() => {
		if (!franchiseData || !employeeDetails) return;
		const franchise = franchiseData?.franchise?.find((e) => e._id === employeeDetails?.franchiseId);
		setFieldValue("franchise", franchise);
	}, [franchiseData]);

	return (
		<div className="px-5 pb-5">
			<div className="mb-3 text-lg font-inter-bold">Employee details</div>
			<div className="grid grid-cols-3 p-5 bg-white border border-gray-200 gap-x-5 gap-y-5 rounded-2xl">
				<FormField
					field={{
						label: "Employee Name",
						name: "name",
						type: "text",
						placeholder: "Enter Name",
					}}
				/>
				<FormField
					field={{
						label: "Email ID",
						name: "email",
						type: "text",
						placeholder: "Enter Email",
					}}
				/>

				<div>
					<label className="block text-sm font-medium leading-6 text-gray-900">Mobile Number</label>
					<div className="flex">
						<ReactFlagsSelect
							selected={selected}
							showOptionLabel={false}
							disabled={true}
							showSelectedLabel={false}
							placeholder="+91"
						/>
						<div className="w-full">
							<FormField
								hideError={true}
								nonFormikError
								field={{
									name: "phoneNumber",
									type: "text",
									placeholder: "+91",
									readOnly: values.search,
									onKeyPress: (event) => {
										if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
											return event.preventDefault();
										}
									},
								}}
							/>
						</div>
					</div>
					{errors.phoneNumber && touched.phoneNumber ? (
						<div className="text-sm text-red-600 error">{errors?.phoneNumber}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				<div>
					<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
						Residential Address
					</label>
					<div>
						<Field
							placeholder="Enter Address"
							name="address"
							as="textarea"
							className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
						/>
						{errors?.address && touched?.address ? (
							<div className="text-sm text-red-600 error">{errors?.address}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>
				<div>
					<SelectMenu
						label="City"
						name="city"
						options={cities}
						onChange={(option) => setFieldValue("city", option)}
						value={values.city}
						placeholder="Select City"
						getOptionValue={(option) => option._id}
						showCheckboxOnRight={false}
					/>
					{errors?.city && touched?.city ? (
						<div className="text-sm text-red-600 error">{errors?.city}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				<div>
					<SelectMenu
						label="Role"
						name="role"
						options={roles}
						onChange={(option) => setFieldValue("role", option)}
						value={values.role}
						placeholder="Select Role"
						showCheckboxOnRight={false}
						inputType="radio"
					/>
					{errors?.role && touched?.role ? (
						<div className="text-sm text-red-600 error">{errors?.role}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				<div>
					<SelectMenu
						label={
							values?.role?.value !== "rider" && values?.role?.value !== "supervisor"
								? "Assign Supervisors / City Manager"
								: values?.role?.value === "rider"
								? "Assign Supervisors"
								: "Assign City Manager"
						}
						name="supervisorCityManager"
						options={supervisorCityManagerList}
						onChange={(option) => setFieldValue("supervisorCityManager", option)}
						value={values.supervisorCityManager}
						placeholder="Select Supervisors / City Managers"
						getOptionValue={(option) => option._id}
						showCheckboxOnRight={false}
					/>
				</div>

				<div className="w-full">
					<label
						htmlFor="joiningDate"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Joining Date
					</label>
					<Calender name="joiningDate" />
					{errors.joiningDate && touched.joiningDate ? (
						<div className="text-sm text-red-600 error">{errors?.joiningDate}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				{values?.role.value === "rider" && (
					<div>
						<SelectMenu
							isMulti="true"
							label="Area"
							name="area"
							options={cityAreaList}
							onChange={(option) => setFieldValue("area", option)}
							value={values.area}
							placeholder="Select area"
							showCheckboxOnRight={false}
							multiSelectClass="overflow-125"
						/>
						{errors?.area && touched?.area && (
							<div className="text-sm text-red-600">{errors?.area}</div>
						)}
					</div>
				)}
				<SelectMenu
					disabled={!!employeeDetails?.franchiseId}
					label="Add Franchise"
					name="franchise"
					options={franchiseData?.franchise}
					onChange={(option) => setFieldValue("franchise", option)}
					value={values.franchise}
					placeholder="Select Franchise"
					showCheckboxOnRight={false}
					classNameForAllRiders={`${!!employeeDetails?.franchiseId && "cursor-not-allowed"}`}
					isLoading={franchiseDataStatus === "pending"}
				/>
			</div>
		</div>
	);
};

const MemorizedEmployeeDetails = React.memo(EmployeeDetails);
