import React, { useEffect, useState } from "react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import { useAssignOrderToRiderMutation, useLazyGetRidersForOrderQuery } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";
import SelectMenu from "../../shared/components/SelectMenu.component";
import { Form, Formik } from "formik";
import * as Yup from "yup";

export const schema = Yup.object().shape({
	rider: Yup.string().required("Please select Rider"),
});

export default function AssignRiderModal({ data }) {	
	const { closeModal } = useSelectModal();
	const [riderList, setRiderList] = useState([]);
	const [getRiders, { status: getRiderStatus }] = useLazyGetRidersForOrderQuery();
	const [assignRider, { status }] = useAssignOrderToRiderMutation();
	const { setToast } = useToaster();
	const [selectedRider, setSelectedRider] = useState(null);

	useEffect(() => {
		if (data) handleGetRiders();
	}, []);

	useEffect(() => {
		if (riderList.length > 0) {
			handleAssignSelectedRider();
		}
	}, [riderList]);

	const handleGetRiders = () => {
		getRiders(data._id)
			.then(({ data }) => {
				setRiderList(data);
			})
			.catch((error) => {
				setToast({ type: "error", msg: error.data.message });
			});
	};

	const handleAssignRider = async () => {
		if (selectedRider) {
			assignRider({
				orderId: data._id,
				riderId: selectedRider?._id,
				serviceId: data?.service?.map((ser) => ser?._id)
			})
				.unwrap()
				.then(() => {
					setToast({
						type: "success",
						msg: `Order is assigned to ${selectedRider?.name ? selectedRider?.name : "new rider"}`,
					});
					closeModal();
					data?.refetch && data?.refetch()
				})
				.catch((error) => {
					setToast({ type: "error", msg: error.data.message });
					closeModal();
				});
		}
	};

	const handleAssignSelectedRider = () => {
		let tempSelectedRider = riderList.find((rider) => rider?.employeeId === data?.riderId);
		setSelectedRider(tempSelectedRider);
	};

	return (
		<Formik
			validationSchema={schema}
			initialValues={{
				rider: "",
			}}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form action="#" method="POST">
					<div className="w-[calc(147px+16.367vw)] p-6 px-8 bg-[#FFFFFF] rounded-xl">
						<div className="flex font-inter-medium justify-center pt-4 text-2xl text-[#000] text-center">
							Assign a Rider
						</div>

						<div className="pb-20 pt-5">
							<SelectMenu
								name="rider"
								placeholder="Select Rider"
								options={riderList}
								onChange={(option) => setSelectedRider(option)}
								value={selectedRider}
								hideSelectedOptions={false}
								isLoading={getRiderStatus !== "fulfilled"}
							/>
							{errors?.rider && touched.rider ? (
								<div className="text-red-600 text-sm">{errors?.rider}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<div className="flex justify-between ">
							<Button
								className="w-[47.5%] bg-[#F1F1F1] border-[#D0D0D0] text-[#000] cursor-pointer font-inter-medium text-lg"
								onClick={closeModal}
							>
								Cancel
							</Button>
							<Button
								className="w-[47.5%] text-[#FFF] bg-[#000] cursor-pointer font-inter-medium text-lg"
								type="submit"
								onClick={handleAssignRider}
								isSubmitting={status === "pending"}
							>
								Assign Rider
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
}
