import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../shared/components/Button.component";
import useSelectModal from "../../../../utils/hooks/useSelectModal";
import Edit from "../../../../assets/icons/edit-icon-blue-bg.svg";
import { Formik, Form } from "formik";
import useToaster from "../../../../utils/hooks/useToaster";
import FormField from "../../../../shared/components/FormField.component";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import * as Yup from "yup";


const validationSchema = Yup.object()
    .shape({
        name: Yup.string()
            .required("Menu Master Title is required")
            .max(30, "Menu Master Title should not exceed 30 characters")
            .matches(/[a-zA-Z]/, "Menu Master Title must contain letters"),
        order: Yup.string().required("Sort Order is required"),
        url: Yup.string()
            .required("URL is required")
            .matches(/^\d{10}$/, "Please enter URL"),
        component: Yup.string()
            .required("Component Name is required")
            .max(30, "Component Name should not exceed 30 characters")
            .matches(/[A-Za-z]/, "Component Name must contain letters"),
    });

function EditMenuMasterModal({ data }) {
    const { closeModal } = useSelectModal();
    const { _id } = useParams();
    const { setToast } = useToaster();
    const navigate = useNavigate();



    const handleFormSubmit = async (formData) => {
        let apiData = {
            name: formData.name,
            order: formData.order,
            url: formData?.url,
            component: formData?.component,
        }
        // const { data: d, error } = await updateFranchise({
        //     id: data._id,
        //     data: apiData
        // })
        // if (d) {
        //     setToast({ type: "success", msg: "Changes Saved Successfully" })
        //     closeModal();
        //     data.fetchFranchise();
        // }
        // if (error) setToast({ type: "error", msg: error?.data?.message });

        return;
    }

    const MenuMasterForm = ({ values, setFieldValue, errors, touched }) => {

        const CustomDatePicker = ({ field, form, ...props }) => {
            const currentFieldValue = field.value;

            return (
                <div className="w-full">
                    <DatePicker
                        {...props}
                        selected={currentFieldValue}
                        onChange={(date) => form.setFieldValue(field.name, date)}
                        minDate={new Date()}
                    />
                </div>
            );
        };

        return (
            <div className="overflow-y-auto max-h-[60vh] pt-4 px-5 modal-ht-inner">
                <div className="flex space-x-4">
                    <div className="w-full space-y-2">
                        <FormField
                            field={{
                                label: "Menu Master Title*",
                                name: "name",
                                type: "text",
                                placeholder: "Enter Title",
                                customClassName: "font-inter-medium",
                            }}
                        />
                        <FormField
                            field={{
                                label: "Sort Order*",
                                name: "order",
                                type: "text",
                                placeholder: "Enter Sort Order",
                                customClassName: "font-inter-medium",
                            }}
                        />
                        <FormField
                            field={{
                                label: "URL*",
                                name: "url",
                                type: "text",
                                placeholder: "Enter URL",
                                customClassName: "font-inter-medium",
                            }}
                        />
                        <FormField
                            field={{
                                label: "Component Name*",
                                name: "component",
                                type: "text",
                                placeholder: "Enter Component Name",
                                customClassName: "font-inter-medium",
                            }}
                        />
                    </div>
                </div>
                <div className="flex space-x-4 my-3 pt-4">
                    <Button
                        className="bg-[#F5F5F5] rounded-lg text-[#111827] cursor-pointer bottom-btn-ht w-full"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="bg-[#FB0C0C] rounded-lg text-white cursor-pointer bottom-btn-ht w-full"
                        type="submit"
                    // isSubmitting={updateFranchiseStatus === "pending"}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="w-[52rem] rounded-xl modal-height-service h-[70vh] overflow-hidden">
            <div>
                <div className="pl-5 pb-4">
                    <div className="flex items-center justify-between pt-5">
                        <div className="h-12 w-12 bg-[#0D6EFD] rounded-full flex justify-center items-center modal-logo-size">
                            <img src={Edit} className="h-12 w-12" alt="" />
                        </div>
                    </div>
                    <div className="mb-1 mt-6">
                        <h1 className="text-xl font-inter-bold">Edit Menu Master</h1>
                    </div>
                    <div className="">
                        <h1 className="text-base font-normal text-[#475467]">
                            Edit menu master details by changing the details below
                        </h1>
                    </div>
                </div>
                <div className="pr-3 pb-5">
                    <Formik
                        initialValues={{
                            name: data?.name,
                            order: data?.order,
                            url: data?.url,
                            component: data?.component,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleFormSubmit(values);
                        }}
                    >
                        {({ isSubmitting, values, errors, touched, setFieldValue }) => {
                            return (
                                <Form className="flex gap-5">
                                    <div className="gap-5 w-full">
                                        {<MenuMasterForm values={values} errors={errors} setFieldValue={setFieldValue} touched={touched} />}
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default EditMenuMasterModal
