import React, { useEffect, Suspense, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import AirlineIcon from "../../assets/icons/airline_stops.svg";
import ArrowIcon from "../../assets/icons/arrow_drop_down.svg";

import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useDeleteAreaMutation, useLazyGetAllTimeSlotsQuery, useLazyGetAreasPaginationQuery } from "../../utils/Store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InfoIcon from "../../assets/icons/information.png";
import useToaster from "../../utils/hooks/useToaster";
import moment from "moment";
import Loader from "../../shared/components/Loader";
import { InfoCircle } from "iconsax-react";
import BackButton from "../../shared/components/BackButton.component";
import ListBoxComponent from "../../shared/components/ListBox.component";
import convertToCardinalFormat from "../../utils/helper/convertToCardinalFormat";

export default function AreaRadiusManagement() {
	const {
		state: { cityData },
	} = useLocation();
	const [pageSize, setPageSize] = useState(10);
	const [pageNo, setPageNo] = useState(1);

	const allSlots = [
		{ name: "8.00 AM - 9.00 AM", value: "8 - 9 AM" },
		{ name: "9.00 AM - 10.00 AM", value: "9 - 10 AM" },
		{ name: "10.00 AM - 11.00 AM", value: "10 - 11 AM" },
		{ name: "11.00 AM - 12.00 PM", value: "11 - 12 PM" },
		{ name: "12.00 PM  - 1.00 PM", value: "12 - 1 PM" },
		{ name: "1.00 PM - 2.00 PM", value: "1 - 2 PM" },
		{ name: "2.00 PM - 3.00 PM", value: "2 - 3 PM" },
		{ name: "3.00 PM - 4.00 PM", value: "3 - 4 PM" },
		{ name: "4.00 PM - 5.00 PM", value: "4 - 5 PM" },
		{ name: "5.00 PM - 6.00 PM", value: "5 - 6 PM" },
		{ name: "6.00 PM - 7.00 PM", value: "6 - 7 PM" },
		{ name: "7.00 PM - 8.00 PM", value: "7 - 8 PM" },
		// { name: "8.00 PM - 9.00 PM", value: "8 - 9 PM" },
		// { name: "9.00 PM - 10.00 PM", value: "9 - 10 PM" },
		// { name: "10.00 PM - 11.00 PM", value: "10 - 11 PM" },
	];

	const tableData = [
		{
			city_name: "Kormangala",
			no_of_areas: 5,
			manager: "Guy Hawkins",
			payment_type: "cash",
		},
		{
			city_name: "Pune",
			no_of_areas: 5,
			manager: "Guy Hawkins",
			payment_type: "cash",
		},
		{
			city_name: "Pune",
			no_of_areas: 5,
			manager: "Guy Hawkins",
			payment_type: "cash",
		},
		{
			city_name: "Pune",
			no_of_areas: 5,
			manager: "Guy Hawkins",
			payment_type: "cash",
		},
		{
			city_name: "Pune",
			no_of_areas: 5,
			manager: "Guy Hawkins",
			payment_type: "cash",
		},
		{
			city_name: "Pune",
			no_of_areas: 5,
			manager: "Guy Hawkins",
			payment_type: "cash",
		},
	];

	const [getCityAreasPagination, { data: cityAreasList, status: areaListStatus }] = useLazyGetAreasPaginationQuery();
	const [getAllTimeSlotList, { data: timeSlotList }] = useLazyGetAllTimeSlotsQuery();

	const { cityId } = useParams();
	const navigate = useNavigate();
	const { openModal } = useSelectModal();

	const { setToast } = useToaster();

	useEffect(() => {
		getAllCityAreas();
	}, [pageNo]);

	useEffect(()=>{
		getAllTimeSlotList()
	},[])

	const getAllCityAreas = async () => {
		if (cityId) {
			getCityAreasPagination({cityId, pageNo, pageSize});
		}
	};

	const handleEdit = (data) => {
		openModal("AddNewArea", { cityData: cityData, isEdit: true, areaData: data, timeSlotList: timeSlotList });
	};

	const handleDeleteArea = (rowData) => {
		openModal("DeleteAreaModal", { ...rowData, hideCloseArrow: true });
	};

	const openMap = (data) => {
		openModal("MapDisplayAreas", { pincodeArray: data.pincodes, areaData: data });
	};

	const columns = [
		{
			header: "Area Name",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return <div className="text-[16] font-medium">{original.name}</div>;
			},
			accessorKey: "name",
		},
		{
			header: "Map View",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="relative group" onClick={() => openMap(original)}>
						<span className="flex items-center gap-2 border rounded-3xl max-w-max px-6 py-2">
							<span>View in map</span>
							<img src={AirlineIcon} alt="" className="h-5 w-5" />
						</span>
					</span>
				);
			},
		},
		{
			header: "Area Centre",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const { lat, lng } = convertToCardinalFormat(
					original.coOrdinates?.latitude,
					original.coOrdinates?.longitude
				);
				return (
					<span className="flex flex-col gap-2">
						<span> {lat}</span>
						<span> {lng}</span>
					</span>
				);
			},
		},
		{
			header: "Area Supervisor",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<div className="flex flex-col">
						{original.employees
							.filter((employee) => employee.roles.includes("supervisor"))
							.map((employee, index, array) => (
								<span>
									{employee.name} {array.length - 1 > index && ","}
								</span>
							))}
					</div>
				);
			},
		},
		{
			header: "Time Slot",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<div className="flex flex-col gap-1 h-9 overflow-y-auto pr-1">
						{original?.timeSlot?.map((timeSlot) => (
							<span className="flex gap-3 bg-white border rounded-lg max-w-max px-3 py-1">
								<span className="whitespace-nowrap w-36 text-gray-500">{timeSlotList?.find(slot=> slot.timeslot === timeSlot)?.name}</span>
								{/* {allSlots
									.filter((slot) => slot.value === timeSlot)
									.map((slot) => (
										<span className="whitespace-nowrap w-36 text-gray-500">{slot.name}</span>
									))} */}
								<img src={ArrowIcon} alt="" />
							</span>
						))}
					</div>
				);
			},
		},
		{
			header: "Payment Mode",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 bg-white border rounded-lg max-w-max px-3 py-1 w-full capitalize pl-">
						{original.paymentType === "both" ? "Online, Cash" : original.paymentType}
						<img src={ArrowIcon} alt="" />
					</span>
				);
			},
		},
		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-2 pl-3">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							className="h-5 w-5 cursor-pointer"
							onClick={() => {
								navigate(`/area-details-management/${original._id}`, { state: cityData });
							}}
						/>
						<img
							src={EditIcon}
							alt=""
							onClick={() => handleEdit(original)}
							className="h-5 w-5 cursor-pointer"
						/>
						<img
							src={DeleteIcon}
							alt=""
							onClick={() => handleDeleteArea(original)}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

	const handlePageChange = (type) => {
		let tempCurrentPage = pageNo;
		if(type === 'prev') {
			tempCurrentPage -= 1;
		} else {
			tempCurrentPage += 1;
		}
		setPageNo(tempCurrentPage);
	}

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
						Area and City Management
					</h1>
					<div className="flex justify-between mt-3">
						<div className="flex gap-3 items-center">
							<BackButton />
							<h3 className="2xl:text-[20px] text-[18px] font-futura-medium tracking-tight text-gray-900 subheading-at-zoom">
								{cityData.name}
							</h3>
						</div>
						<Button
							className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
							onClick={() => openModal("AddNewArea", { cityData: cityData, isEdit: false })}
						>
							Add New Area
						</Button>
					</div>
				</div>
			</div>
			<div className="bg-white border rounded-xl px-5 mx-8 my-3 h-[70.4vh] overflow-hidden brand-details-section relative">
				<div className="w-full">
					{
						areaListStatus !== "fulfilled" ?
						<Loader isLoading={true}/> : 
						<TableComponent
							data={cityAreasList?.result}
							columns={columns}
							customwidths={[
								"w-[12%]",
								"w-[15%]",
								"w-[15%]",
								"w-[17.5%]",
								"w-[20%]",
								"w-[12%]",
								"w-[8.5%]",
							]}
							customeClass='pr-3 max-h-[56vh]'
						/>
					}
				</div>
				{
					cityAreasList?.totalPages > 1 && 
					<div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
						<div className=''>
							<Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`} 
								disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
						</div>
						<div className=''>
							<Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == cityAreasList?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`}
							disabled={pageNo == cityAreasList?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
						</div>
						<div className=''>
							<p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {cityAreasList?.totalPages}</p>
						</div>
					</div>
				}
			</div>
		</div>
	);
}
