import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const ridersApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getRiders: builder.mutation({
				query: (body) => ({
					url: endpoints.rider.getRiders,
					body: body,
					method: "POST",
				}),
			}),
		};
	},
});

export const { useGetRidersMutation } = ridersApi;
export { ridersApi };
