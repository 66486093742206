import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useToaster from "../../utils/hooks/useToaster";

function Toaster() {
	let { type, toastMsg } = useSelector((state) => state.toast);
	toastMsg = toastMsg?.toString().includes('!') ? toastMsg : toastMsg + "!";
	const { setToast } = useToaster();
	useEffect(() => {
		const Id = setTimeout(() => {
			if (type === "success") {
				toast.dismiss();
				toast.success(toastMsg, {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
					theme: "colored",
				});
			}
			if (type === "error") {
				toast.dismiss();
				toast.error(toastMsg, {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
					theme: "colored",
				});
			}
			setToast({ msg: "", type: "" });
		}, 100);

		return () => {
			clearTimeout(Id);
		};
	}, [type, toastMsg]);

	return (
		<ToastContainer
			position="bottom-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			pauseOnHover
			theme="colored"
			// limit={1}
		/>
	);
}

export default React.memo(Toaster);
