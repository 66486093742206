import React from "react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import useToaster from "../../utils/hooks/useToaster";
import DeleteIcon from "../../assets/icons/modal-delete-icon.svg";
import { useDeleteSingleServiceMutation } from "../../utils/Services/Apis/serviceApi";
import { useNavigate } from "react-router";

function DeleteServiceModal({ data }) {
	const { closeModal, openModal } = useSelectModal();
	const { setToast } = useToaster();
	const [deleteService,{status}] = useDeleteSingleServiceMutation();
	const navigate = useNavigate();

	const handleDelete = async () => {
		deleteService(data?._id)
			.then((resp) => {
				setToast({ type: "success", msg: `${data.type} deleted successfully` });
				data.isNavigateBack && navigate(-1);
			})
			.catch((err) => {
				setToast({ type: "error", msg: err.data.message });
			});
		closeModal();
	};

	return (
		<div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
			<div className="flex items-center justify-center">
				<div className=" bg-[#E5E5E5] h-2/12 w-2/12 rounded-full flex justify-center items-center ">
					<img src={DeleteIcon} />
				</div>
			</div>
			<div className="flex font-inter-medium justify-center pt-4 text-xl text-center">
				Do you want to delete this {data.type}?
			</div>
			<div className="pb-5 text-center">
				<span className="text-[#87898E] text-center">This action can’t be undone</span>
			</div>
			<div className="flex justify-between ">
				<Button className="w-[47.5%] bg-[#F1F1F1] text-[#23262F] cursor-pointer" onClick={closeModal}>
					Cancel
				</Button>
				<Button className="w-[47.5%] text-white cursor-pointer" type="submit" onClick={handleDelete} isSubmitting={status==="pending"}>
					Delete
				</Button>
			</div>
		</div>
	);
}

export default DeleteServiceModal;
