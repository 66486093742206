
function isPointInsideCircle(pointLat, pointLon, circleCenterLat, circleCenterLon, circleRadius) {
    const distance = haversineDistance(pointLat, pointLon, circleCenterLat, circleCenterLon);
    return distance <= circleRadius;
  }
  function haversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3;  // Earth's radius in meters
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;  // Distance in meters
  }
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  const getPostalCode = async (lat, lng) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Replace with your API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        // Extracting the postal code from the response
        const addressComponents = data.results[0].address_components;
        const postalCodeObj = addressComponents.find(component => component.types.includes('postal_code'));
        return postalCodeObj ? postalCodeObj.long_name : 'Postal code not found';
      } else {
        throw new Error('Geocoding failed');
      }
    } catch (error) {
      console.error('Error fetching postal code:', error);
      return null;
    }
  };
  export const isInTheRadius = async ({pincodes, areaLatitude, areaLongitude, latitude, longitude, radiusType, areaRadius}) =>{
    let isInTheRadius = false;
    if (pincodes.length) {
      const postalCode = await getPostalCode(latitude, longitude)
      const isSamePincode = pincodes.find((pin) => pin == postalCode)
      if (isSamePincode) isInTheRadius = true
    } else {
      const radius = radiusType?.unit === "Kilometer" ? areaRadius * 1000 : areaRadius
      isInTheRadius = isPointInsideCircle(latitude, longitude, areaLatitude, areaLongitude, radius)
    }
    return isInTheRadius
  }
