import moment from 'moment';
import React, { useMemo } from 'react';
import { Clock } from "iconsax-react";
import ProgressBar from "@ramonak/react-progress-bar";

const formatTime = (hours = 0, minutes = 0, seconds = 0) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

const timeToSeconds = (time) => {
    return (time.hours * 3600) + (time.minutes * 60) + (time.seconds || 0);
}

const secondsToTime = (seconds) => {
    return {
        hours: Math.floor(seconds / 3600),
        minutes: Math.floor((seconds % 3600) / 60),
        seconds: Math.round(seconds % 60) 
    };
}

export const timeDifference = (startTime, endTime) => {
    const start = moment.utc(startTime);
    const end =endTime ? moment.utc(endTime) : moment.utc().add(5, 'hours').add(30, 'minutes'); 
    const difference = end.diff(start, 'milliseconds');
    return secondsToTime(difference / 1000);
}

function AddOnServices({ service }) {
    const serviceDurationInSeconds = useMemo(() => timeToSeconds(service?.serviceTime[0]), [service?.serviceTime]);
    const timeDiff = useMemo(() =>  service?.startTime ? timeDifference(service?.startTime, service?.endTime) : { hours: 0, minutes: 0, seconds: 0 }, [service?.startTime, service?.endTime]);

    const extraTimeInSeconds =  service?.startTime ? timeToSeconds(timeDiff) - serviceDurationInSeconds : 0;
    const extraTime = secondsToTime(extraTimeInSeconds);

    const completion = Math.round((timeToSeconds(timeDiff) / serviceDurationInSeconds) * 100);

    return (
        <div className="flex">
            <div className="w-[42%]">
                <div className="border rounded-lg py-1 px-3 my-2 overflow-x-auto max-w-fit final-price-scrollbar">
                    <div className="flex items-center justify-center space-x-2 w-max">
                        <img src={service.imageUrl} className="h-5 w-5" alt=""/>
                        <p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">{service.serviceName}</p>
                        <Clock size={18} color="gray" className="icon-size-14px" />
                        <p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
                            {formatTime(service?.serviceTime[0].hours, service?.serviceTime[0].minutes, service?.serviceTime[0].seconds)}
                            <span className="text-sm 2xl:text-base font-inter-regular text-[#CB0303]">
                                {extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}
                            </span>
                        </p>
                        <p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
                            <span className="text-sm 2xl:text-base">₹</span>
                            {service?.addOnServiceAmount}
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-[58%] flex items-center pl-10">
                <div className="w-[100%]">
                    <div className="flex justify-between">
                        <p className="text-sm 2xl:text-base font-inter-bold text-[#000]">
                            {formatTime(timeDiff.hours, timeDiff.minutes, timeDiff.seconds)} 
                            <span className="text-[#000] font-inter-bold text-sm 2xl:text-base">
                                {`${service?.endTime ? "(Completed)" : service?.startTime ? "(In-progress)": ""}`}
                            </span>
                        </p>
                        <p className="text-sm 2xl:text-base font-inter-bold">
                            {formatTime(service?.serviceTime[0].hours, service?.serviceTime[0].minutes, service?.serviceTime[0].seconds)}
                            <span className="text-[#CB0303] font-inter-bold text-sm 2xl:text-base">
                                {extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}
                            </span>
                        </p>
                    </div>
                    <ProgressBar
                        completed={(extraTimeInSeconds > 0 || service?.endTime ) ? 100 : completion}
                        labelAlignment="center"
                        className="progress-bar-height"
                        labelClassName="progress-bar-label"
                        bgColor="#24BB61"
                        width="100%"
                    />
                </div>
            </div>
        </div>
    );
}

export default React.memo(AddOnServices);
