import React from "react";
import { useLocation, useParams } from "react-router-dom";

export default function Main({ children }) {
	const {pathname} = useLocation();
	const { riderId } = useParams();
	// console.log(riderId, 'Rider Id....')

	// console.log('location', location)
	return <div className={`lg:w-[84.2vw] ml-auto min-h-[calc(100vh-90px)] min-height ${pathname === `/rider-management/all-riders/${riderId}` ? 'overflow-y-scroll overflow-x-hidden no-scrollbar' : 'overflow-hidden'}`}>{children}</div>;
}

