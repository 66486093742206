import { createSlice } from "@reduxjs/toolkit";
const ROLE = 'role';
let initialState = null;

if (localStorage.getItem(ROLE)) initialState = localStorage.getItem(ROLE);

export const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        setRole: (state, action) => {
            localStorage.setItem(ROLE, action.payload);
            return (state = action.payload);
        },
        distroyRole: (state, action) => {
            localStorage.removeItem(ROLE);
			return (state = null);
        }
    }
});

export const {setRole, distroyRole} = roleSlice.actions;
export default roleSlice.reducer;