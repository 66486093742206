import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const razorpayApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			sendPaymentLinkToCustomer: builder.query({
				query: (orderId) => {
					return {
						url: endpoints.razorpay.sendPaymentLinkToCustomer(orderId),
						method: "GET",
					};
				},
			}),

			getPaymentOrderStatus: builder.query({
				query: (paymentOrderId) => {
					return {
						url: endpoints.razorpay.getPaymentOrderStatus(paymentOrderId),
						method: "GET",
					};
				},
			}),
		};
	},
});

export const {
 useLazyGetPaymentOrderStatusQuery,
 useLazySendPaymentLinkToCustomerQuery
} = razorpayApi;
export { razorpayApi };
