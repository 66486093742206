import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../shared/components/Button.component";
import FormField from "../../../shared/components/FormField.component";
import { Field, Form, Formik, useFormikContext } from "formik";
import useToaster from "../../../utils/hooks/useToaster";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import BackButton from "../../../shared/components/BackButton.component";
import SelectMenu from "../../../shared/components/SelectMenu.component";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import steperIcon from "../../../assets/icons/steper.svg";
import checkedIcon from "../../../assets/icons/checkedIcon.svg";
import ReactFlagsSelect from "react-flags-select";
import DatePicker from "react-datepicker";
import { AddCircle, Calendar, CloseCircle, InfoCircle } from "iconsax-react";
import {
	useAddFranchiseMutation,
	useGetAreaListMutation,
	useGetCityManagersByCitiesMutation,
	useLazyGetAllMasterFranchiseQuery,
	useLazyGetCitesQuery,
} from "../../../utils/Store";
import useGetImageUploadData from "../../../utils/hooks/useGetImageUploadData";
import pdfIcon from "../../../assets/icons/pdf-svgrepo-com.svg";
import ImageUploadFieldWithoutPreview from "../../../shared/components/Image_Upload_Field_Without_Preview";
import Checkbox from "../../../shared/components/Checkbox";

function CreateNewFranchise() {
	const [currentStep, setCurrentStep] = useState(0);
	const location = useLocation();
	const { state } = location;
	const [addFranchise, {status}] = useAddFranchiseMutation();
	const { setToast } = useToaster();
	const navigate = useNavigate();
	const { openModal } = useSelectModal();
	const [getAllMasterFranchise, { data: masterFranchiseList }] =
		useLazyGetAllMasterFranchiseQuery();
	const [getCities, { data: cityList }] = useLazyGetCitesQuery();

	useEffect(() => {
		getAllMasterFranchise();
		getCities();
	}, []);

	const step1Schema = Yup.object()
		.shape({
			name: Yup.string()
				.required("Franchise Name is required")
				.max(70, "Franchise Name should not exceed 70 characters")
				.matches(/[a-zA-Z]/, "Franchise Name must contain letters"),
			address: Yup.string().required("Address is required"),
			phoneNumber: Yup.string()
				.required("Mobile Number is required")
				.matches(/^\d{10}$/, "Please enter 10 digits Mobile Number"),
			email: Yup.string().email("Invalid email").required("Email is required").max(35, "Email should be less than 35 characters"),
		})
		.test("", "", function (value) {
			if (state === "franchise" && !value.masterFranchise) {
				return this.createError({
					path: "masterFranchise",
					message: "Master Franchise is required",
				});
			}
			return true; // Validation passed
		});

	const step2Schema = Yup.object().shape({
		gstNumber: Yup.string()
			.required("GST Number is required")
			.matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Invalid GST Number"),
		city: Yup.array().required("City is required"),
	});

	const step3Schema = Yup.object().shape({
		area: Yup.array().required("Area is required"),
		cityManager: Yup.object().required("City Manager is required"),
		startDate: Yup.date().required("Start Date is required"),
		endDate: Yup.date().required("End Date is required"),
	});

	const step4Schema = Yup.object().shape({
		mouDocumentsUrls: Yup.array().min(1, "Please upload MOU document"),
		kycDocumentsUrls: Yup.array().min(1, "Please upload KYC document"),
		// emergencyPhoneNumber: Yup.string()
		// .matches(/^\d{10}$/, "Please enter 10 digits Contact Number")
		// .nullable(),
	})
	.test("is-less-than", "is in limit", function (value) {
			if (value.emergencyPhoneNumber && value.emergencyPhoneNumber.length !== 10) {
				return this.createError({
					path: "emergencyPhoneNumber",
					message: "Please enter 10 digits Contact Number",
				});
			}
			return true; // Validation passed
		});;

	const step5Schema = Yup.object()
		.shape({
			creditLimit: Yup.string().required("Credit Limit is required"),
			paymentDueDate: Yup.string().required("Payment Due Date is required"),
			gracePeriod: Yup.string().required("Grace Period is required"),
		})
		.test("is-less-than", "is in limit", function (value) {
			if (+value.paymentDueDate > 31) {
				return this.createError({
					path: "paymentDueDate",
					message: "Payment Due Date should not be greater than 31",
				});
			}
			if (+value.paymentDueDate < 1) {
				return this.createError({
					path: "paymentDueDate",
					message: "Payment Due Date must be at least 1",
				});
			}
			if (+value.gracePeriod > 15) {
				return this.createError({
					path: "gracePeriod",
					message: "Grace Period should not be greater than 15",
				});
			}
			return true; // Validation passed
		});

	const step6Schema = Yup.object()
		.shape({
			commissionModelName: Yup.string().required("Please select Commission Model"),
			revenueArray: Yup.array().when('commissionModelName', {
				is: 'revenue',
				then:()=> Yup.array().of(
						Yup.object().shape({
							amount: Yup.string().required("Amount is required"),
							percentage: Yup.string().required("Percentage is required"),
							gstPercentage: Yup.string().when('includeGst', {
								is: false,
								then:()=> Yup.string().required("GST is required"),
							}),
						})
				),
				otherwise:()=> Yup.array(), // No validation when commissionModelName is not 'revenue'
			}),
			range: Yup.array().when('commissionModelName', {
				is: 'perUser',
				then:()=> Yup.array().of(
						Yup.object().shape({
							gstPercentage: Yup.string().when('includeGst', {
								is: false,
								then:()=> Yup.string().required("GST is required"),
							}),
							amount: Yup.string().required("Amount is required"),
							rangeFrom: Yup.string().required("From is required"),
							rangeTo: Yup.string().required("To is required")
								.test('is-greater', 'To value must be greater than From value', function(toValue) {
										const fromValue = this.parent.rangeFrom;
										return parseFloat(toValue) > parseFloat(fromValue);
								}),
						})
				),
				otherwise:()=> Yup.array(), // No validation when commissionModelName is not 'revenue'
			}),
			
		})
		.test("", "", function (value) {
			if (value.commissionModelName === "perUser" && !value.noOfServiceProvider) {
				return this.createError({
					path: "noOfServiceProvider",
					message: "Please enter No of service Providers",
				});
			}
			return true; // Validation passed
		});

	const stepValidationSchemas = [
		step1Schema,
		step2Schema,
		step3Schema,
		step4Schema,
		step5Schema,
		step6Schema,
	];

	const steps = [
		{ title: "Primary Information" },
		{ title: "Secondary Information" },
		{ title: "Franchise Information" },
		{ title: "Documents" },
		{ title: "Payment Information" },
		{ title: "Commission Information" },
	];

	const nextStep = async (values, setFieldValue, setErrors, setTouched) => {
		const currentSchema = stepValidationSchemas[currentStep];
		currentSchema
			.validate(values, { abortEarly: false })
			.then(() => {
				handleOnValidate(values, setFieldValue);
			})
			.catch((err) => {
				const errors = {};
				const touched = {};
				err.inner.forEach((error) => {
					errors[error.path] = error.message;
					touched[error.path] = true;
				});
				setErrors(errors);
				setTouched(touched);
			});
	};

	const prevStep = () => {
		if (currentStep !== 0) {
			setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
		} else {
			navigate(-1);
		}
	};

	const checkFranchiseDates = (values) => {
    if(values.commissionModelName !== "revenue") return;
    
    const franchiseStartDate = new Date(values.franchiseStartDate);
    const franchiseEndDate = new Date(values.franchiseEndDate);

    // Extract only the date component from franchise start and end date
    const franchiseStartDateOnlyDate = new Date(franchiseStartDate.getFullYear(), franchiseStartDate.getMonth(), franchiseStartDate.getDate());
    const franchiseEndDateOnlyDate = new Date(franchiseEndDate.getFullYear(), franchiseEndDate.getMonth(), franchiseEndDate.getDate());

    const datesInRange = [];

    // Generate all dates between franchise start and end date (inclusive)
    for (let date = new Date(franchiseStartDateOnlyDate); date <= franchiseEndDateOnlyDate; date.setDate(date.getDate() + 1)) {
        datesInRange.push(new Date(date));
    }

    // Check for missing dates in revenue entries
    const missingDates = datesInRange.filter(date => {
        return !values.revenueArray.some(revenue => {
            const revenueStartDate = new Date(revenue.startDate);
            const revenueEndDate = new Date(revenue.endDate);

            // Extract only the date component from revenue start and end date
            const revenueStartDateOnlyDate = new Date(revenueStartDate.getFullYear(), revenueStartDate.getMonth(), revenueStartDate.getDate());
            const revenueEndDateOnlyDate = new Date(revenueEndDate.getFullYear(), revenueEndDate.getMonth(), revenueEndDate.getDate());

            return date >= revenueStartDateOnlyDate && date <= revenueEndDateOnlyDate;
        });
    });

    if (missingDates.length > 0) {
        // Display error message for missing dates
        setToast({ type: "error", msg: "Missing dates between franchise start and end dates in the Commission model" });
        return true;
    }

    return false;
}

    const checkDatesWithinFranchiseRange = (values) => {
    if(values.commissionModelName !== "revenue") return;

    const franchiseStartDate = new Date(values.franchiseStartDate);
    const franchiseEndDate = new Date(values.franchiseEndDate);

    const invalidDates = values.revenueArray.filter(revenue => {
        const revenueStartDate = new Date(revenue.startDate);
        const revenueEndDate = new Date(revenue.endDate);

        // Extract only the date component from revenue start and end date
        const revenueStartDateOnlyDate = new Date(revenueStartDate.getFullYear(), revenueStartDate.getMonth(), revenueStartDate.getDate());
        const revenueEndDateOnlyDate = new Date(revenueEndDate.getFullYear(), revenueEndDate.getMonth(), revenueEndDate.getDate());
        const franchiseStartDateOnlyDate = new Date(franchiseStartDate.getFullYear(), franchiseStartDate.getMonth(), franchiseStartDate.getDate());
        const franchiseEndDateOnlyDate = new Date(franchiseEndDate.getFullYear(), franchiseEndDate.getMonth(), franchiseEndDate.getDate());

        return (
            revenueStartDateOnlyDate < franchiseStartDateOnlyDate ||
            revenueEndDateOnlyDate > franchiseEndDateOnlyDate
        );
    });

    if (invalidDates.length > 0) {
        // Display error message for dates outside franchise range
        setToast({ type: "error", msg: "Some dates in Commission Model are outside of the franchise start and end dates" });
        return true;
    }

    return false;
};



    const checkRange = (values) => {
        if (values.commissionModelName !== "perUser") return;
        const ranges = values.range;
        ranges.forEach(range => {
            range.rangeFrom = parseInt(range.rangeFrom);
            range.rangeTo = parseInt(range.rangeTo);
        });
        let coveredValues = new Set();
        for (let i = 0; i < ranges.length; i++) {
            const range = ranges[i];
            // Check if max range 'rangeTo' value is less than 50
            if (range.rangeTo > values.noOfServiceProvider) {
                setToast({ type: "error", msg: "'To' value should not be greater than No. of service provider" });
                return true;
            }
            // Check for overlap
            for (let j = i + 1; j < ranges.length; j++) {
                const nextRange = ranges[j];
                if ((range.rangeFrom <= nextRange.rangeTo && range.rangeTo >= nextRange.rangeFrom) ||
                    (nextRange.rangeFrom <= range.rangeTo && nextRange.rangeTo >= range.rangeFrom)) {
                    setToast({ type: "error", msg: "Range Selection should not overlap" });
                    return true;
                }
            }
            // Add all values within the range to coveredValues set
            for (let val = range.rangeFrom; val <= range.rangeTo; val++) {
                coveredValues.add(val);
            }
        }
        // Check if all values rangeFrom 0 to 50 are covered
        for (let i = 1; i <= values.noOfServiceProvider; i++) {
            if (!coveredValues.has(i)) {
                setToast({ type: "error", msg: `Ranges should cover all values from 1 to ${values.noOfServiceProvider}` });
                return true;
            }
        }
        return false;
    }

	const handleOnValidate = async (values, setFieldValue) => {
		
		if (currentStep === 5) {
        if(checkRange(values)) return
        if(checkFranchiseDates(values)) return
        if(checkDatesWithinFranchiseRange(values)) return
				
			const bodyObj = {
				name: values.name,
				address: values.address,
				phoneNumber: "+91" + values.phoneNumber,
				email: values.email,
				franchiseLevel: "parent",
				gst: values.gstNumber,
				cityId: values.city?.length && values.city?.map((city) => city._id),
				areaId: values.area?.length && values.area?.map((area) => area._id),
				manager: values.cityManager?.name,
				startDate: moment(values.franchiseStartDate).format("YYYY-MM-DD"),
				endDate: moment(values.franchiseEndDate).format("YYYY-MM-DD"),
				kycDocuments:
					values.kycDocumentsUrls?.length && values.kycDocumentsUrls,
				mouDocuments:
					values.mouDocumentsUrls?.length && values.mouDocumentsUrls,
				creditLimit: +values.creditLimit,
				paymentDueDate: +values.paymentDueDate,
				gracePeriod: +values.gracePeriod,
				commissionModelName: values.commissionModelName,
				commissionData: [],
			};
			if (values.masterFranchise) {
				bodyObj.franchiseLevel = "child";
				bodyObj.parentId = values.masterFranchise._id;
			}
			if (values.emergencyPhoneNumber) {
				bodyObj.emergencyPhoneNumber= "+91" + values.emergencyPhoneNumber
			}
			if (values.commissionModelName === "perUser") {
				bodyObj.commissionData = values.range?.map((e) => {
					const obj ={
						...e,
						startDate: moment(values.startDate).format("YYYY-MM-DD"),
						endDate: moment(values.endDate).format("YYYY-MM-DD"),
						noOfServiceProvider: +values.noOfServiceProvider,
						amount: +e.amount,
					}
					if(e.includeGst) obj.gstPercentage = 0
					return obj;
				});
			}
			if (values.commissionModelName === "revenue") {
				bodyObj.commissionData = values.revenueArray?.map((e) => {
					const commissionDataObj = {
						...e,
						startDate: moment(e.startDate).format("YYYY-MM-DD"),
						endDate: moment(e.endDate).format("YYYY-MM-DD"),
					};
					if (e.amount) commissionDataObj.amount = +e.amount;
					if (e.percentage) commissionDataObj.percentage = +e.percentage;
					if(e.includeGst) commissionDataObj.gstPercentage = 0
					return commissionDataObj;
				});
			}

			const { data, error } = await addFranchise(bodyObj);
			if (data) {
				openModal("FranchiseCreatedModal", { name: data?.franchise?.name, hideCloseArrow: true });
				navigate("/franchise-management")
				setCurrentStep(Math.min(currentStep + 1, steps.length - 1));
			}
			if (error) setToast({ type: "error", msg: error?.data?.message });
		}

		if(currentStep !==5) setCurrentStep(Math.min(currentStep + 1, steps.length - 1));
	};

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 10,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage:
					"linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage:
					"linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
			borderRadius: 1,
		},
	}));

	const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
		zIndex: 1,
		color: "#fff",
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	}));

	function ColorlibStepIcon(props) {
		const { active, completed, className, icon } = props;
		const icons = <img src={steperIcon} alt="" className="opacity-25 " />;
		const activeIcons = <img src={steperIcon} alt="" />;
		const completedIcons = <img src={checkedIcon} alt="" />;
		const displayIcon = completed ? completedIcons : active ? activeIcons : icons;
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
				{displayIcon}
			</ColorlibStepIconRoot>
		);
	}

	return (
		<div className="bg-[#F5F5F5] h-full overflow-hidden">
			<div className="p-8">
				<div className="flex items-center gap-3 mb-8">
					<BackButton />
					<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
						Add New Franchise
					</h3>
				</div>
				<div className="bg-white rounded-[20px] brand-details-section 2xl:pt-4 pb-6 h-[78vh] stepper-sm">
					<Formik
						initialValues={{
							email: "",
							phoneNumber: "",
							address: "",
							area: "",
							city: "",
							franchiseStartDate: new Date(),
							franchiseEndDate: new Date(),
							startDate: new Date(),
							endDate: new Date(),
							emergencyPhoneNumber: "",
							manager: "",
							kycDocumentsUrls: [],
							mouDocumentsUrls: [],
							kycDocuments: "",
							mouDocuments: "",
							range: [{ rangeFrom: "", rangeTo: "", amount: "", includeGst: false, gstPercentage:"" }],
							revenueArray: [
								{ startDate: new Date(), endDate: new Date(), amount: "", percentage: "", includeGst: false, gstPercentage:"" },
							],
						}}
						validationSchema={() => {
							switch (currentStep) {
								case 0:
									return step1Schema;
								case 1:
									return step2Schema;
								case 2:
									return step3Schema;
								case 3:
									return step4Schema;
								case 4:
									return step5Schema;
								case 5:
									return step6Schema;
								default:
									return Yup.object().shape({});
							}
						}}
						onSubmit={(values) => {}}
					>
						{({
							isSubmitting,
							values,
							setFieldValue,
							errors,
							setErrors,
							setTouched,
							setFieldTouched,
						}) => (
							<Form className="space-y-3" action="#" method="POST">
								<Stack sx={{ width: "100%", paddingTop: "30px" }} spacing={4}>
									<Stepper
										alternativeLabel
										activeStep={currentStep}
										connector={<ColorlibConnector />}
									>
										{steps.map((label) => (
											<Step key={label.title}>
												<StepLabel StepIconComponent={ColorlibStepIcon}>{label.title}</StepLabel>
											</Step>
										))}
									</Stepper>
								</Stack>
								<div>
									{currentStep === 0 && (
										<PrimaryInformation masterFranchiseList={masterFranchiseList} state={state} />
									)}
									{currentStep === 1 && <SecondaryInformation cityList={cityList} />}
									{currentStep === 2 && <FranchiseInformation />}
									{currentStep === 3 && <Documents />}
									{currentStep === 4 && <PaymentInformation />}
									{currentStep === 5 && <CommissionInformation />}
								</div>
								<div className="w-[45vw] mx-auto px-16 flex justify-center gap-5">
									<Button
										onClick={prevStep}
										className="w-full border text-[#1A1A1A] border-black bg-white bottom-btn-ht font-inter-bold "
									>
										Previous
									</Button>
									<Button
										onClick={() =>
											nextStep(values, setFieldValue, setErrors, setTouched, setFieldTouched)
										}
										className="w-full border text-[#FFF] bg-black bottom-btn-ht font-inter-bold "
										isSubmitting={status === "pending"}
									>
										{currentStep === 5 ? "Submit" : "Next"}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
export default CreateNewFranchise;

const PrimaryInformation = ({ masterFranchiseList, state }) => {
	const [selected, setSelected] = useState("IN");
	const { setFieldValue, values, errors, touched } = useFormikContext();
	return (
		<div className="flex justify-center ">
			<div className="px-6 pb-0 2xl:pt-2 xl:pt-4 pt-2 w-[50vw] max-h-[56vh] flex items-center flex-col rounded-xl border xl:space-y-3 space-y-3 bg-[#F9FAFB]">
				<h1 className="text-lg font-inter-bold">1. Primary Information of the Franchise</h1>
				<div className="w-full 3xl:space-y-3 2xl:space-y-1 space-y-0">
					<div className={`${state === "masterFranchise" && "hidden"}`}>
						<SelectMenu
							label="Master Franchise"
							name="masterFranchise"
							placeholder="Select Master Franchise"
							isClearable={true}
							options={masterFranchiseList}
							onChange={(option) => setFieldValue("masterFranchise", option)}
							value={values.masterFranchise}
							customeClassName="font-inter-medium"
						/>
						{errors.masterFranchise && touched.masterFranchise ? (
							<div className="error text-red-600 text-sm">{errors?.masterFranchise}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<FormField
						field={{
							label: "Franchise Name",
							name: "name",
							type: "text",
							placeholder: "Enter Franchise Name",
							customClassName: "font-inter-medium",
						}}
					/>
					<FormField
						field={{
							label: "Address",
							name: "address",
							type: "text",
							placeholder: "Enter Address",
							customClassName: "font-inter-medium",
						}}
					/>
					<div className="">
						<label className="block text-sm font-inter-medium leading-6 text-gray-900">
							Mobile Number
						</label>
						<div className="flex">
							<ReactFlagsSelect
								selected={selected}
								showOptionLabel={false}
								disabled={true}
								showSelectedLabel={false}
								placeholder="+91"
							/>
							<div className="w-full">
								<FormField
									field={{
										name: "phoneNumber",
										type: "text",
										placeholder: "+91",
										onKeyPress: (event) => {
											if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
												return event.preventDefault();
											}
										},
									}}
								/>
							</div>
						</div>
					</div>
					<FormField
						field={{
							label: "Email ID",
							name: "email",
							type: "email",
							placeholder: "Enter Customer Email",
							customClassName: "font-inter-medium",
						}}
					/>
				</div>
			</div>
		</div>
	);
};

const SecondaryInformation = ({ cityList }) => {
	const { setFieldValue, values, errors, touched } = useFormikContext();

	return (
		<div className="flex justify-center">
			<div className="px-6 pb-0 pt-6 w-[50vw] h-[28rem] 2xl:h-[28.25rem] xl:h-[27.75rem] flex items-center flex-col rounded-xl border space-y-3 bg-[#F9FAFB]">
				<h1 className="text-lg font-inter-bold">2. Secondary Information of the Franchise</h1>
				<div className="w-full 2xl:space-y-3 space-y-0">
					<FormField
						field={{
							label: "GST",
							name: "gstNumber",
							type: "text",
							placeholder: "Enter GST Number",
							customClassName: "font-inter-medium",
						}}
					/>
					<div className="">
						<SelectMenu
							label="City"
							name="city"
							options={cityList}
							onChange={(option) => setFieldValue("city", option)}
							value={values.city}
							placeholder="Select City"
							getOptionValue={(option) => option._id}
							isMulti={true}
							customeClassName="font-inter-medium"
						/>
						{errors.city && touched.city ? (
							<div className="error text-red-600 text-sm">{errors?.city}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const FranchiseInformation = () => {
	const { setFieldValue, values, errors, touched } = useFormikContext();
	// const [franchiseStartDate, setStartDate] = useState(new Date());
	// const [franchiseEndDate, setEndDate] = useState(new Date());
	const [getCitiesManagers, { data: cityManagerList }] = useGetCityManagersByCitiesMutation();
	const [getAreasByCity, { data: areaList, status: areaStatus }] = useGetAreaListMutation();

	useEffect(() => {
		if (!values.city?.length) return;
		// setFieldValue("area", null);
		getAreasByCity({
			cityId: values.city?.map((city, index) => city._id),
		});
	}, [values.city]);

	useEffect(() => {
			if (areaList?.length && values.area?.length) {
					const areas = areaList?.filter(area => values.area.find((area1) => area._id === area1?._id))
					setFieldValue("area", areas)
			}
	}, [areaList]);

	useEffect(() => {
		getCitiesManagers({ cityId: values.city?.length && values.city?.map((city) => city._id) });
	}, []);

	useEffect(() => {
		if (values.franchiseEndDate < values.franchiseStartDate)
			setFieldValue("franchiseEndDate", new Date(values.franchiseStartDate));
	}, [values.franchiseStartDate]);
	return (
		<div className="flex justify-center">
			<div className="pl-6 pr-3 pb-0 2xl:pt-6 pt-3 w-[50vw] h-[27vw] flex items-center flex-col rounded-xl border 2xl:space-y-3 space-y-1 bg-[#F9FAFB]">
				<h1 className="text-lg font-inter-bold">3. Franchise Information</h1>
				<div className="w-full 2xl:space-y-3 space-y-1 h-[50vh] overflow-x-hidden pr-3">
					<div className="">
						<SelectMenu
							label="Serviceable Area"
							name="area"
							options={areaStatus === "pending" ? [] : areaList}
							onChange={(option) => setFieldValue("area", option)}
							value={values.area}
							placeholder="Select Area"
							getOptionValue={(option) => option._id}
							isLoading={areaStatus === "pending"}
							isMulti={true}
							customeClassName="font-inter-medium"
						/>
						{errors.area && touched.area ? (
							<div className="error text-red-600 text-sm">{errors?.area}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<div>
						<SelectMenu
							label="Manager"
							name="cityManager"
							placeholder="Select City Manager"
							isClearable={true}
							options={cityManagerList}
							onChange={(option) => setFieldValue("cityManager", option)}
							value={values.cityManager}
							getOptionValue={(option) => option._id}
							customeClassName="font-inter-medium"
						/>
						{errors.cityManager && touched.cityManager ? (
							<div className="error text-red-600 text-sm">{errors?.cityManager}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
					</div>

					<div className="w-full">
						<div className="mb-2 block font-inter-medium text-sm">Start Date</div>
						<div className="flex justify-between gap-5">
							<div className="w-full">
								<DatePicker
									className="rounded-lg border-[#000]/10 2xl:w-full w-full"
									selected={values.franchiseStartDate}
									onChange={(date) => setFieldValue("franchiseStartDate", new Date(date))}
									dateFormat="dd-MM-yyyy"
									minDate={new Date()}
									customInput={<CustomInput />}
								/>
								<div className="invisible h-5"></div>
							</div>
						</div>
					</div>
					<div className="w-full">
						<div className="mb-2 block font-inter-medium text-sm">End Date</div>
						<div className="flex justify-between gap-5">
							<div className="w-full ">
								<DatePicker
									className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
									selected={values.franchiseEndDate}
									onChange={(date) => setFieldValue("franchiseEndDate", new Date(date))}
									dateFormat="dd-MM-yyyy"
									minDate={new Date(values.franchiseStartDate)}
									customInput={<CustomInput />}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Documents = () => {
	const [selected, setSelected] = useState("IN");
	const { setFieldValue, values, errors, touched } = useFormikContext();
	const { uploadImages, uploadImgStatus } = useGetImageUploadData();
	const nodeClickRef = useRef();
	const getKycUrls = async () => {
		let imageUrlArray = await uploadImages(values.kycDocuments);
		if (imageUrlArray) {
			if (values.kycDocumentsUrls?.length)
				setFieldValue("kycDocumentsUrls", [...values.kycDocumentsUrls, ...imageUrlArray]);
			else setFieldValue("kycDocumentsUrls", imageUrlArray);
		}
	};

	const getMouUrls = async () => {
		let imageUrlArray = await uploadImages(values.mouDocuments);
		if (imageUrlArray) {
			if (values.mouDocumentsUrls?.length)
				setFieldValue("mouDocumentsUrls", [...values.mouDocumentsUrls, ...imageUrlArray]);
			else setFieldValue("mouDocumentsUrls", imageUrlArray);
		}
	};

	useEffect(() => {
		if (values.kycDocuments) getKycUrls();
		return () => setFieldValue("kycDocuments", "");
	}, [values.kycDocuments]);

	useEffect(() => {
		if (values.mouDocuments) getMouUrls();
		return () => setFieldValue("mouDocuments", "");
	}, [values.mouDocuments]);

	return (
		<div className="flex justify-center">
			<div className="px-6 mb-4 pt-6 w-[50vw] max-h-[54vh] pb-2 flex items-center flex-col rounded-xl border space-y-3 bg-[#F9FAFB] document-card-ht">
				<div className="text-lg font-inter-bold">4. Documents</div>
				<div className="w-full 2xl:space-y-3 space-y-0 overflow-y-auto pr-2">
					<div className="">
						<div className="mb-1 text-sm font-inter-medium">Upload KYC</div>
						<div className="">
							<ImageUploadFieldWithoutPreview
								bottomText={"PNG/JPG/PDF format"}
								name="kycDocuments"
								multiple={true}
								imgFormat="image/png,image/jpg,application/pdf"
								fileSize={5120}
								customClass="document-ht"
								uploadImgStatus={uploadImgStatus}
								nodeClickRef={nodeClickRef}
							/>
							{errors.kycDocumentsUrls && touched.kycDocumentsUrls ? (
								<div className="error text-red-600 text-sm">{errors?.kycDocumentsUrls}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<div className="flex gap-2 overflow-x-auto final-price-scrollbar mb-4">
							{values.kycDocumentsUrls?.map((doc, index) => (
								<div
									className="flex w-[calc(175px+4.549vw)] justify-between bg-white rounded-xl border items-center px-4 py-4 relative shrink-0"
									key={index}
								>
									<div className="flex-1 pr-4">
										<div className="font-inter-regular text-sm truncate w-[calc(75px+4.549vw)]">
											{doc?.name}
										</div>
										<hr className="text-gray-100 my-3" />
										<div className="font-inter-regular text-sm">{doc.size}</div>
									</div>
									<div
										className={`${
											doc.type !== "application/pdf" && " rounded-lg border "
										} h-[calc(40px+1.042vw)] w-[calc(40px+1.042vw)] relative flex`}
										// onClick={() => openModal("PreviewDocImage", doc.imageUrls)}
									>
										<img
											src={doc.type === "application/pdf" ? pdfIcon : doc.url}
											alt=""
											className="h-[50px] w-[50px] rounded overflow-hidden m-auto"
										></img>
									</div>
									<div className="w-6 flex justify-end items-start cursor-pointer absolute right-2 top-2">
										<CloseCircle
											alt=""
											className="h-5 w-5"
											onClick={() => {
												const updatedArray = values.kycDocumentsUrls?.filter(
													(e) => e.url !== doc.url
												);
												setFieldValue("kycDocumentsUrls", updatedArray);
											}}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="">
						<div className="mb-1 text-sm font-inter-medium">Upload MOU</div>
						<div className="">
							<ImageUploadFieldWithoutPreview
								bottomText={"PDF format"}
								name="mouDocuments"
								multiple={true}
								imgFormat="application/pdf"
								fileSize={15360}
								customClass="document-ht"
								uploadImgStatus={uploadImgStatus}
								nodeClickRef={nodeClickRef}
							/>
							{errors.mouDocumentsUrls && touched.mouDocumentsUrls ? (
								<div className="error text-red-600 text-sm">{errors?.mouDocumentsUrls}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>
						<div className="flex gap-2 overflow-x-auto final-price-scrollbar mb-4">
							{values.mouDocumentsUrls?.map((doc, index) => (
								<div
									className="flex w-[calc(175px+4.549vw)] justify-between bg-white rounded-xl border items-center px-4 py-4 relative shrink-0"
									key={index}
								>
									<div className="flex-1 pr-4">
										<div className="font-inter-regular text-sm truncate w-[calc(75px+4.549vw)]">
											{doc?.name}
										</div>
										<hr className="text-gray-100 my-3" />
										<div className="font-inter-regular text-sm">{doc.size}</div>
									</div>
									<div
										className={`${
											doc.type !== "application/pdf" && " rounded-lg border "
										} h-[calc(40px+1.042vw)] w-[calc(40px+1.042vw)] relative flex`}
										// onClick={() => openModal("PreviewDocImage", doc.imageUrls)}
									>
										<img
											src={doc.type === "application/pdf" ? pdfIcon : doc.url}
											alt=""
											className="h-[50px] w-[50px] rounded overflow-hidden m-auto"
										></img>
									</div>
									<div className="w-6 flex justify-end items-start cursor-pointer absolute right-2 top-2">
										<CloseCircle
											alt=""
											className="h-5 w-5"
											onClick={() => {
												const updatedArray = values.mouDocumentsUrls?.filter(
													(e) => e.url !== doc.url
												);
												setFieldValue("mouDocumentsUrls", updatedArray);
											}}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
					<label className="block text-sm font-inter-medium leading-6 text-gray-900">
						Emergency Contact
					</label>
					<div className="flex">
						<ReactFlagsSelect
							selected={selected}
							showOptionLabel={false}
							disabled={true}
							showSelectedLabel={false}
							placeholder="+91"
						/>
						<div className="w-full">
							<FormField
								field={{
									name: "emergencyPhoneNumber",
									type: "text",
									placeholder: "+91",
									onKeyPress: (event) => {
										if (/[^0-9]/.test(event.key) || event.target.value.length > 9) {
											return event.preventDefault();
										}
									},
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const PaymentInformation = () => {
	return (
		<div className="flex justify-center">
			<div className="px-6 pt-6 w-[50vw] max-h-[48vh] mb-4 pb-2 flex items-center flex-col rounded-xl border space-y-3 bg-[#F9FAFB]">
				<div className="text-lg font-inter-bold">5. Payment Information</div>
				<div className="w-full 2xl:space-y-3 space-y-1 h-[50vh] overflow-x-hidden pr-3">
					<div className="w-full">
						<FormField
							field={{
								label: "Credit Limit",
								name: "creditLimit",
								type: "text",
								placeholder: "Enter Credit Limit",
								customClassName: "font-inter-medium",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key)) {
										return event.preventDefault();
									}
								},
							}}
						/>
					</div>
					<div className="w-full">
						<FormField
							field={{
								label: "Payment Due Date",
								name: "paymentDueDate",
								type: "text",
								placeholder: "Enter Payment Due Date",
								customClassName: "font-inter-medium",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
										return event.preventDefault();
									}
								},
							}}
						/>
					</div>
					<div className="w-full">
						<FormField
							field={{
								label: "Grace Period",
								name: "gracePeriod",
								type: "text",
								placeholder: "Enter Grace Period Days",
								customClassName: "font-inter-medium",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
										return event.preventDefault();
									}
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const CommissionInformation = () => {
	const { setFieldValue, values, errors, touched } = useFormikContext();
	const { openModal } = useSelectModal();
	useEffect(() => {
		if (values.endDate < values.startDate) setFieldValue("endDate", new Date(values.startDate));
	}, [values.startDate]);

	const FIELD_NAMES = {
		FROM: "rangeFrom",
		TO: "rangeTo",
		AMOUNT: "amount",
		INCLUDE_GST: "includeGst",
	};

	const handleAddRangeField = () => {
		setFieldValue("range", [
			...values.range,
			{ rangeFrom: "", rangeTo: "", amount: "", includeGst: false, gstPercentage:"" },
		]);
	};

	const handleRemoveRangeField = (index) => {
		const newRange = [...values.range];
		newRange.splice(index, 1);
		setFieldValue("range", newRange);
	};

	const handleAddRevenueField = () => {
		setFieldValue("revenueArray", [
			...values.revenueArray,
			{ startDate: new Date(), endDate: new Date(), amount: "", percentage: "", includeGst: false, gstPercentage:"" },
		]);
	};

	const handleRemoveRevenueField = (index) => {
		const newRevenueArray = [...values.revenueArray];
		newRevenueArray.splice(index, 1);
		setFieldValue("revenueArray", newRevenueArray);
	};


	const rangeSelectionArrayJsx = (index) => (
		<div key={index} className="px-2 pb-[0.5rem]">
			<div className="my-4 flex items-center justify-between">
				<p className="font-inter-bold text-[#000] text-sm">Record #{index + 1}</p>
				<div className="bg-[#000]/20 w-[87%] h-[1px]"></div>
			</div>
			<div className="w-full border rounded-xl bg-[#FFF] p-5 mt-4">
				<p className="font-inter-bold text-[#000] text-sm pb-2">Range Selection</p>
				<div className="w-full flex gap-x-4">
					<div className="w-1/2">
						<div className="font-inter-medium text-sm mb-2">From</div>
						<FormField
							nonFormikError
							field={{
								// label: "No. of service Providers",
								name: `range[${index}][${FIELD_NAMES.FROM}]`,
								type: "text",
								placeholder: "Enter number",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key)) {
										return event.preventDefault();
									}
								},
							}}
						/>
						{touched[`range[${index}].rangeFrom`] &&
							errors?.range &&
							errors?.range[index]?.rangeFrom ? (
								<div className="error text-red-600 text-sm">{errors.range[index].rangeFrom}</div>
							) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<div className="w-1/2">
						<div className="font-inter-medium text-sm mb-2">To</div>
						<FormField
							nonFormikError
							field={{
								// label: "No. of service Providers",
								name: `range[${index}][${FIELD_NAMES.TO}]`,
								type: "text",
								placeholder: "Enter number",
								onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key)) {
										return event.preventDefault();
									}
								},
							}}
						/>
						{touched[`range[${index}].rangeTo`] &&
							errors?.range &&
							errors?.range[index]?.rangeTo ? (
								<div className="error text-red-600 text-sm">{errors.range[index].rangeTo}</div>
							) : (
							<div className="invisible h-5"></div>
						)}
					</div>
					<div className="pt-8">
						{index === 0 ? (
							<AddCircle
								className="text-[#FB0C0C] cursor-pointer"
								onClick={() => handleAddRangeField()}
							/>
						) : (
							<CloseCircle
								className="text-[#FB0C0C] cursor-pointer"
								onClick={() => handleRemoveRangeField(index)}
							/>
						)}
					</div>
				</div>
				<div className="w-full rounded-lg pr-10">
					<div className="font-inter-medium text-sm mb-2">Amount</div>
					<FormField
					  nonFormikError
						field={{
							// label: "No. of service Providers",
							name: `range[${index}][${FIELD_NAMES.AMOUNT}]`,
							type: "text",
							placeholder: "Enter per user amount",
							onKeyPress: (event) => {
									if (/[^0-9]/.test(event.key)) {
										return event.preventDefault();
									}
								},
						}}
					/>
					{touched[`range[${index}].amount`] &&
						errors?.range &&
						errors?.range[index]?.amount ? (
							<div className="error text-red-600 text-sm">{errors.range[index].amount}</div>
						) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				<div className="flex justify-between pr-10 items-baseline ">
					<div className="flex items-center">
						<Checkbox name={`range[${index}][${FIELD_NAMES.INCLUDE_GST}]`} />
						<p className="font-inter-semibold text-sm">Include GST</p>
					</div>
					<div>
						<div className={`flex justify-around items-center border rounded-lg overflow-invisible w-[15rem] ${
										values?.range[index][FIELD_NAMES.INCLUDE_GST] ? "hidden" : ""
									}`}>
								<div className="whitespace-nowrap px-3 py-1 border-r-[1px] h-full w-[60%] justify-center flex items-center text-sm">
									Add GST %
								</div>
								<Field
									name={`range[${index}]gstPercentage`}
									type="text"
									placeholder="GST"
									className={`h-full w-[40%] border-none arrow-none text-sm focus:ring-transparent `}
									onKeyPress= {(event) => {
										if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
											return event.preventDefault();
										}
								}}
								></Field>
							</div>
							{touched[`range[${index}].gstPercentage`] &&
									errors?.range &&
									errors?.range[index]?.gstPercentage ? (
										<div className="error text-red-600 text-sm">{errors.range[index].gstPercentage}</div>
									) : (
									<div className="invisible h-5"></div>
								)}
					</div>
					
				</div>
			</div>
		</div>
	);

	useEffect(
		() => {
			values.revenueArray?.map((e, index) => {
				if (e.endDate < e.startDate)
					setFieldValue(`revenueArray[${index}]endDate`, new Date(e.startDate));
			});
		},
		values.revenueArray?.map((e) => e.startDate)
	);

	const handleDatepickerClick = (index) => {
    const element = document.getElementById("commissionInformation");
    if (element && index === (values.revenueArray?.length -1) && element.scrollHeight - element.scrollTop === element.clientHeight) {
			setTimeout(() => {
					element.scrollIntoView({ behavior: "smooth" });
					element.scrollBy(0, 100);
    }, 100);
    }
  };


	const revenueArrayJsx = (index) => (
		<div key={index} className="relative">
			<div className="my-4 flex items-center justify-between">
				<p className="font-inter-bold text-[#000] text-sm">Record #{index + 1}</p>
				<div className="bg-[#000]/20 w-[87%] h-[1px]"></div>
			</div>
			<div className="w-full border rounded-xl bg-[#FFF] p-5 mt-3">
				<div className="flex items-center justify-center w-full gap-x-4 font-normal text-sm payment-container">
					<div className="w-1/2 rounded-lg " onClick={()=>handleDatepickerClick(index)}>
						<div className="gap-2">
							<span className="font-inter-medium text-sm mb-1 block">Start Date</span>
							<DatePicker
								selected={values.revenueArray?.length && values[`revenueArray`][index]?.startDate}
								onChange={(date) => setFieldValue(`revenueArray[${index}]startDate`, date)}
								className="rounded-lg border-[#000]/10 2xl:w-full w-full"
								dateFormat="dd-MM-yyyy"
								minDate={new Date(values.franchiseStartDate)}
								maxDate={new Date(values.franchiseEndDate)}
								customInput={<CustomInput />}
							/>
						</div>
					</div>
					<div className="w-1/2 rounded-lg " onClick={()=>handleDatepickerClick(index)}>
						<div className="gap-2">
							<span className="font-inter-medium text-sm mb-1 block">End Date</span>
							<DatePicker
								selected={values.revenueArray?.length && values[`revenueArray`][index]?.endDate}
								onChange={(date) => setFieldValue(`revenueArray[${index}]endDate`, date)}
								className="rounded-lg border-[#000]/10 2xl:w-full w-full"
								dateFormat="dd-MM-yyyy"
								minDate={
									(values.revenueArray?.length && values[`revenueArray`][index]?.startDate) ? new Date(values[`revenueArray`][index]?.startDate ) : new Date(values.franchiseStartDate)
								}
								maxDate={new Date(values.franchiseEndDate)}
								customInput={<CustomInput />}
							/>
						</div>
					</div>
					<div className="pt-6">
						{index === 0 ? (
							<AddCircle
								className="text-[#FB0C0C] cursor-pointer"
								onClick={() => handleAddRevenueField()}
							/>
						) : (
							<CloseCircle
								className="text-[#FB0C0C] cursor-pointer"
								onClick={() => handleRemoveRevenueField(index)}
							/>
						)}
					</div>
				</div>
				<div className="mt-3">
					<div className="flex items-center justify-center w-full gap-x-4 font-normal text-sm payment-container">
						<div className="w-1/2 rounded-lg ">
							<div className="w-full rounded-lg">
								<div className="font-inter-medium text-sm mb-2">Amount</div>
								<FormField
									nonFormikError
									field={{
										// label: "No. of service Providers",
										name: `revenueArray[${index}][${FIELD_NAMES.AMOUNT}]`,
										type: "text",
										placeholder: "Enter revenue amount",
										onKeyPress: (event) => {
											if (/[^0-9]/.test(event.key)) {
												return event.preventDefault();
											}
										},
									}}
								/>
								{touched[`revenueArray[${index}].amount`] &&
									errors?.revenueArray &&
									errors?.revenueArray[index]?.amount ? (
										<div className="error text-red-600 text-sm">{errors.revenueArray[index].amount}</div>
									) : (
									<div className="invisible h-5"></div>
								)}
							</div>
						</div>
						<div className="w-1/2 rounded-lg ">
							<div className="w-full rounded-lg">
								<div className="font-inter-medium text-sm mb-2">Percentage</div>
								<FormField
									nonFormikError
									field={{
										// label: "No. of service Providers",
										name: `revenueArray[${index}]percentage`,
										type: "text",
										placeholder: "Enter revenue percentage",
										onKeyPress: (event) => {
											if (/[^0-9]/.test(event.key)  || event.target.value.length > 1) {
												return event.preventDefault();
											}
										},
									}}
								/>
								{touched[`revenueArray[${index}].percentage`] &&
									errors?.revenueArray &&
									errors?.revenueArray[index]?.percentage ? (
										<div className="error text-red-600 text-sm">{errors.revenueArray[index].percentage}</div>
									) : (
									<div className="invisible h-5"></div>
								)}
							</div>
						</div>
					  
						<div className="w-[1.75rem]"></div>
					</div>
					<div className="flex justify-between pr-10 items-baseline mt-1">
						<div className="flex items-center">
							<Checkbox name={`revenueArray[${index}][${FIELD_NAMES.INCLUDE_GST}]`} />
							<p className="font-inter-semibold text-sm">Include GST</p>
						</div>
						<div>
							<div className={`flex justify-around items-center border rounded-lg overflow-invisible w-[15rem] ${
											values?.revenueArray[index][FIELD_NAMES.INCLUDE_GST] ? "hidden" : ""
										}`}>
									<div className="whitespace-nowrap px-3 py-1 border-r-[1px] h-full w-[60%] justify-center flex items-center text-sm">
										Add GST %
									</div>
									<Field
										name={`revenueArray[${index}]gstPercentage`}
										type="text"
										placeholder="GST"
										className={`h-full w-[40%] border-none arrow-none text-sm focus:ring-transparent `}
										onKeyPress= {(event) => {
											if (/[^0-9]/.test(event.key) || event.target.value.length > 1) {
												return event.preventDefault();
											}
									}}
									></Field>
								</div>
								{touched[`revenueArray[${index}].gstPercentage`] &&
										errors?.revenueArray &&
										errors?.revenueArray[index]?.gstPercentage ? (
											<div className="error text-red-600 text-sm">{errors.revenueArray[index].gstPercentage}</div>
										) : (
										<div className="invisible h-5"></div>
									)}
						</div>
						
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className="flex justify-center">
			<div className="pl-6 pr-2 pb-2 pt-6 w-[50vw] rounded-xl border bg-[#F9FAFB] h-[56vh]">
				<div className="text-lg font-inter-bold text-center">6. Commission Information</div>
				<div className="pr-3 overflow-y-auto h-[48vh] w-[48vw]" id={"commissionInformation"}>
					<div className="px-2">
						<div className="mt-3">
							<p className="font-inter-medium text-sm mb-1">Commission Model</p>
							<div className="flex items-center justify-between w-full gap-x-4 font-normal text-sm payment-container pl-5 pr-16">
								<div className="py-2 px-4 w-1/2 bg-white rounded-lg border">
									<label className="relative flex items-center gap-2">
										<Field
											type="radio"
											name="commissionModelName"
											className="mr-2 mt-1.5 appearance-none items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent md:mt-0 sm:mt-0"
											value="revenue"
										/>
										<span className="text-[#757575]">Revenue</span>
										<div className="absolute group z-10 right-4">
											<InfoCircle
												color="#0d6efd"
												variant="Bold"
												className={`h-5 w-5 cursor-pointer right-4`}
												onClick={() => openModal('RevenueModel')}
											></InfoCircle>
											{/* <div className={`absolute hidden group-hover:block -right-0 -top-11 w-60`}>
												<div className="bg-black rounded-2xl px-6 text-white py-2 text-sm">
													This is a revenue model
												</div>
											</div> */}
										</div>
									</label>
								</div>
								<div className="py-2 px-4 w-1/2 bg-white rounded-lg border">
									<label className="relative flex items-center gap-2">
										<Field
											type="radio"
											name="commissionModelName"
											className="mr-2 mt-1.5 appearance-none items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent md:mt-0 sm:mt-0"
											value="perUser"
										/>
										<span className="text-[#757575]">Per User</span>
										<div className="absolute group z-10 right-4">
											<InfoCircle
												color="#0d6efd"
												variant="Bold"
												onClick={() => openModal('PerUserModal')}
												className={`h-5 w-5 cursor-pointer right-4`}
											></InfoCircle>
											{/* <div className={`absolute hidden group-hover:block -right-0 -top-11 w-60`}>
												<div className="bg-black rounded-2xl text-white py-2 px-6 text-sm">
													This is a perUser model
												</div>
											</div> */}
										</div>
									</label>
								</div>
							</div>
							{errors.commissionModelName && touched.commissionModelName ? (
								<div className="error text-red-600 text-sm">{errors?.commissionModelName}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div>

						{values.commissionModelName === "revenue" && (
							<>{values.revenueArray?.map((_, index) => revenueArrayJsx(index))}</>
						)}

						{values.commissionModelName === "perUser" && (
							<div className="w-full border rounded-xl bg-[#FFF] p-5 mt-3">
								<div className="flex items-center justify-center w-full gap-x-4 font-normal text-sm payment-container pr-11">
									<div className="w-1/2 rounded-lg ">
										<div className="gap-2">
											<span className="font-inter-medium text-sm mb-1 block">Start Date</span>
											<DatePicker
												selected={values.franchiseStartDate}
												onChange={(date) => setFieldValue("startDate", date)}
												className="rounded-lg border-[#000]/10 2xl:w-full w-full"
												dateFormat="dd-MM-yyyy"
												minDate={new Date()}
												customInput={<CustomInput />}
												disabled={true}
											/>
										</div>
									</div>
									<div className="w-1/2 rounded-lg ">
										<div className="gap-2">
											<span className="font-inter-medium text-sm mb-1 block">End Date</span>
											<DatePicker
												selected={values.franchiseEndDate}
												onChange={(date) => setFieldValue("endDate", date)}
												className="rounded-lg border-[#000]/10 2xl:w-full w-full"
												dateFormat="dd-MM-yyyy"
												minDate={new Date(values.startDate)}
												customInput={<CustomInput />}
												disabled={true}
											/>
										</div>
									</div>
								</div>
								<div className="w-full mt-3 pr-11">
									<label className="font-inter-medium text-sm mb-2">No. of service Providers</label>
									<FormField
										field={{
											// label: "No. of service Providers",
											name: "noOfServiceProvider",
											type: "text",
											placeholder: "Enter no. of service of service  providers",
											onKeyPress: (event) => {
												if (/[^0-9]/.test(event.key)) {
													return event.preventDefault();
												}
											},
										}}
									/>
								</div>
							</div>
						)}
					</div>
					{values.commissionModelName === "perUser" && (
						<>
							{values?.range?.map((range, index) => {
								return rangeSelectionArrayJsx(index);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
	<div
		className={`flex gap-3 border rounded-md text-sm h-[2.3rem] p-2 bg-white pr-4 ${
			!value && "text-gray-500"
		}`}
		onClick={onClick}
		ref={ref}
	>
		<Calendar className="h-5 w-5" />
		{value || placeholder}
	</div>
));
