import React, { useEffect, useMemo, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import Avatar from 'react-avatar';
import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import { useNavigate } from "react-router";
import useToaster from "../../utils/hooks/useToaster";
import {
	useGetAllRidersMutation,
	useGetAreaListMutation,
	useLazyGetCitesQuery,
	useLazyGetPDFQuery,
} from "../../utils/Store";
import { InfoCircle, SearchNormal1, Location, Calendar } from "iconsax-react";
import SelectMenuIcon from "../../shared/components/SelectMenuIcon.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import SelectMenu from "../../shared/components/SelectMenu.component";
import DatePicker from "react-datepicker";
import moment from "moment";
import pdfDonloadIcon from '../../assets/icons/download-pdf.svg';
import xlsDownloadIcon from '../../assets/icons/download-xls.svg';
import { generateAllRidersPDF } from "../../utils/helper/generateAllRiderPDF";
import { generateAllRidersExcel } from "../../utils/helper/generateAllRiderExcel";

export default function AllRiders() {
	const navigate = useNavigate();
	const { setToast } = useToaster();
	const { openModal } = useSelectModal();
	const [selectedCity, setSelectedCity] = useState();
	const [selectedArea, setSelectedArea] = useState();
	const [getCites, { data: cities }] = useLazyGetCitesQuery();
	const [getAreas, { data: areaDetails }] = useGetAreaListMutation();
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [allSelectedCityAreas, setAllSelectedCityAreas] = useState([]);
	const [getAllRiders, { data: allRiders, status: riderListStatus }] = useGetAllRidersMutation();
	const [selectedOptions, setSelectedOptions] = useState({ name: "Today", value: "today" });
	const [pageSize, setPageSize] = useState({ name: "10", value: 10 });
	const [pageNo, setPageNo] = useState(1);
	const [inputValue, setInputValue] = useState('');
	const [allRidersData, setAllRidersData] = useState([]);
	const [getRiderDetails] = useLazyGetPDFQuery()

	const arr = useMemo(() => [10, 20, 30, 40], []);
	const pageOptions = arr.map((value, index) => ({
		value: value,
		name: value + "" 
	}));

	useEffect(()=>{
		if(pageNo < 1) setPageNo(1) 
		if(pageNo > allRiders?.totalPages) setPageNo(allRiders?.totalPages || 1)
	},[pageNo, pageSize, allRiders])


	const handleGeneratePdf = async (riderData) => {
		const { data, error } = await getRiderDetails({riderId: riderData?._id , params:{"startDate": moment(startDate).format('YYYY-MM-DD'), "endDate": moment(endDate).format('YYYY-MM-DD')}})
		if (data) {
			setToast({ type: "success", msg: "Report is being downloaded" });
			generateAllRidersPDF(data, riderData?.name)
		}
		if (error) setToast({ type: "error", msg: error?.data?.message });
	}
	const handleGenerateExcel = async (riderData) => {
		const { data, error } = await getRiderDetails({riderId: riderData?._id , params:{"startDate": moment(startDate).format('YYYY-MM-DD'), "endDate": moment(endDate).format('YYYY-MM-DD')}})
		if (data) {
			setToast({ type: "success", msg: "Report is being downloaded" });
			generateAllRidersExcel(data, riderData?.name)
		}
		if (error) setToast({ type: "error", msg: error?.data?.message });
	}

	const columns = [
		{
			header: (
				<div className="flex items-center">
					<span className="cursor-pointer">Rider Name</span>
				</div>
			),
			accessorKey: "riderName",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center w-full">
						{ original?.profileImageURL ? <span className='block w-[40px] h-[40px] rounded-full overflow-hidden'><img className='w-full h-full' src={original?.profileImageURL} alt="" /></span> :<Avatar
							name={original.riderName}
							size="40"
							round={true}
							color="#F1F1F1"
							fgColor="#000"
							textSizeRatio="1.5"
						/>}
						<span className="truncate">{original.riderName}</span>
					</span>
				);
			},
		},
		{
			header: (
				<div className="flex items-center">
					<span className="cursor-pointer">Assigned Area</span>
				</div>
			),
			accessorKey: "areas",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const areaOptions = original.areas.map(area => ({
					label: area.name,
					name: area.name
				}))
				return (
					<div className="w-full">
						<SelectMenu
							showCheckbox={false}
							options={areaOptions}
							value={areaOptions[0]}
						/>
					</div>
				);
			},
			sortType: (rowA, rowB, id, desc) => {
				let areaNameA = rowA.values[id][0]?.name || '';
				let areaNameB = rowB.values[id][0]?.name || '';
				return areaNameA.localeCompare(areaNameB);
			}
		},
		{
			header: "No.of Completed Services",
			disableSortBy: true,
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<div className="flex items-center justify-center w-full">
						<p className="truncate border-[#E5E5E5] border border-solid w-[5rem] h-[2.25rem] rounded-[10px] flex items-center justify-center cursor-pointer"
						onClick={() => navigate(`/ondemand-management/${original?._id}`, {state: {"startDate" : moment(startDate).format('YYYY-MM-DD'), "endDate": moment(endDate).format('YYYY-MM-DD')}})}>{original.completedServices}</p>
					</div>
				);
			},
		},
		{
			header: "No.of Upcoming Services",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex items-center justify-center w-full">
						<span className="truncate border-[#E5E5E5] border border-solid w-[5rem] h-[2.25rem] rounded-[10px] flex items-center justify-center cursor-pointer"
						onClick={() => navigate(`/appointment-management/${original?._id}`)}>{original.upcomingServices}</span>
					</span>
				);
			},
		},
		{
			header: "View Report",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-2 ml-8">
						<img
							src={pdfDonloadIcon}
							alt=""
							className="h-5 w-5 cursor-pointer"
							onClick={() => handleGeneratePdf(original)}
						/>
						<img
							src={xlsDownloadIcon}
							alt=""
							className="h-5 w-5 cursor-pointer"
							onClick={() => handleGenerateExcel(original)}
						/>
					</span>
				);
			},
		},

		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 pl-3">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							onClick={() => navigate(`/rider-management/all-riders/${original?._id}`)}
							className="h-5 w-5 cursor-pointer"
						/>
						<img
							src={EditIcon}
							alt="edit"
							onClick={() => navigate(`/employee-management/edit/${original?._id}`, {state : { isFromRidersPage: true }})}
							className="h-5 w-5 cursor-pointer"
						/>
						<img
							src={DeleteIcon}
							alt="delete"
							onClick={() => handleDelete(original,  getfetchRiders )}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

	useEffect(() => {
		getCites();
	}, []);

	useEffect(() => {
        handleSetDate();
    }, [selectedOptions]);

	useEffect(() => {
		if (allRiders)
			setAllRidersData(allRiders);
	}, [allRiders]);

	useEffect(() => {
		const id = setTimeout(() => {
			let areaIds = []
			if (selectedArea?.length) {
				areaIds = selectedArea.map(area => area._id)
			}
			if (selectedCity?.length && !selectedArea?.length) {
				areaIds = areaDetails.map(area => area._id)
			}
			if (inputValue) {
				getAllRiders({
					body:
					{
						startDate: moment(startDate).format('YYYY-MM-DD'),
						endDate: moment(endDate).format('YYYY-MM-DD'),
						areaId: areaIds,
						page: pageNo,
						pageSize: pageSize?.value
					}
					, params: { search: inputValue }
				});
				return
			}
			getAllRiders({
				body:
				{
					startDate: moment(startDate).format('YYYY-MM-DD'),
					endDate: moment(endDate).format('YYYY-MM-DD'),
					areaId: areaIds,
					page: pageNo,
					pageSize: pageSize?.value
				}
			});
		}, 350)
		return () => {
			clearTimeout(id)
		}
	}, [startDate, endDate, selectedArea, selectedCity, inputValue, pageNo, areaDetails, pageSize])

	useEffect(() => {
		if (selectedCity?.length) {
			handleGetCityAreas()
		} else {
			setAllSelectedCityAreas([]);
			setSelectedArea(null);
		}
	}, [selectedCity]);

	const handleDelete = async (riderData, refetchRiders) => {
		openModal("DeleteRiderModal", { riderData, refetchRiders , hideCloseArrow: true });
	};

	const handleGetCityAreas = async () => {
		let { data } = await getAreas({
			cityId: selectedCity.map((city, index) => city._id)
		});
		setAllSelectedCityAreas(data);
	}

	const handleCityChange = (option) => {
		setSelectedCity(option);
		setSelectedArea(null);
	};
	const handleAreaChange = (option) => {
		setSelectedArea(option);

	};

	const getfetchRiders = () => {
	
		let bodyObj = {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD'),
			areaId: [],
			page: pageNo,
			pageSize: pageSize?.value
		}
		if (selectedArea?.length) {
			bodyObj.areaId = selectedArea?.map((area) => area._id)
		}
		if (selectedArea?.length) {
			bodyObj.areaId = selectedArea.map(area => area._id)
		}
		if (selectedCity?.length && !selectedArea?.length) {
			bodyObj.areaId = areaDetails.map(area => area._id)
		}
		if (inputValue) {
			getAllRiders({ body: bodyObj, params: { search: inputValue } });
			return
		}
		getAllRiders({ body: bodyObj });
	}

	const handleSetDate = () => {
		switch (selectedOptions.value) {
			case 'today':
				setStartDate(moment().toDate())
				break;
			case 'custom':
				setStartDate(moment().toDate())
				setEndDate(moment().toDate())
				break;
			default:
				break;
		}
	}

	const handlePageChange = (type) => {
		let tempCurrentPage = pageNo;
		if (type === 'prev') {
			tempCurrentPage -= 1;
		} else {
			tempCurrentPage += 1;
		}
		setPageNo(tempCurrentPage);
	}

	const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
		<div
			className={`flex gap-2 items-center border-[#000]/10 border rounded-[10px] text-sm h-[3.1rem] 2xl:w-[10rem] w-[10rem] py-2 pl-4 bg-white ${!value && "text-[#000]"}`}
			onClick={onClick}
			ref={ref}
		>
			<Calendar className="h-5 w-5" />
			{value || placeholder}
		</div>
	));

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex items-center justify-between">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">All Riders</h3>
					</div>
				</div>
				<div className="flex justify-between px-8 ">
					<div className="flex items-center 2xl:space-x-4 space-x-4 pb-4">
						<div className="">
							<h1 className="text-base text-[#333333] font-normal">Filter by Date:</h1>
						</div>
						<div className="calendar-icon-size all-rider-date-input">
							<DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								dateFormat="dd-MM-yyyy"
								minDate={selectedOptions.value === 'custom' ? moment().toDate() : null}
								maxDate={moment().toDate()}
								customInput={<CustomInput />}
							/>
						</div>
						<div className="">
							<h1 className="font-plus-jakarta text-sm text-center px-1">To</h1>
						</div>
						<div className="calendar-icon-size all-rider-date-input">
							<DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
								selected={endDate}
								onChange={(date) => setEndDate(date)}
								dateFormat="dd-MM-yyyy"
								minDate={moment(startDate).toDate()}
								maxDate={moment().toDate()}
								customInput={<CustomInput />}
							/>
						</div>
					</div>
					<div className="flex 2xl:space-x-12 xl:space-x-0 space-x-4 pb-4">
						<div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
							<div className="">
								<h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
							</div>
							<div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
								<SelectMenuIcon
									placeholder='All Cities'
									options={cities}
									multiSelectClass="search-customer-height multi-time-slot bg-color-white"
									isMulti={true}
									onChange={handleCityChange}
									value={selectedCity}
								/>
								<Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
							</div>
						</div>
						<div className="flex items-center 2xl:space-x-4 xl:space-x-0 space-x-4">
							<div className="">
								<h1 className="text-base text-[#333333] font-normal">Filter by Area:</h1>
							</div>
							<div className="2xl:w-[15rem] w-[17rem] relative flex items-center justify-center cm-air-ht">
								<SelectMenuIcon
									placeholder='All Areas'
									options={allSelectedCityAreas}
									multiSelectClass="search-customer-height multi-time-slot bg-color-white"
									isMulti={true}
									onChange={handleAreaChange}
									value={selectedArea}
								/>
								<Location className="h-5 w-5 absolute location-icon-ht 2xl:left-[1.5vw] xl:left-[2.75vw] 3xl:left-[0.85vw] left-[2.5vw] top-[50%] -translate-y-[50%]" />
							</div>
						</div>
					</div>
				</div>
				<div className="bg-white p-5 mx-8 my-3 pb-2 h-[70vh] 2xl:h-[69vh] overflow-hidden brand-details-section bg-white-height relative">
					<div className="flex items-center justify-between">
						<div className="relative h-12 input-height-zoom">
							<input
								type="text"
								className="w-[18.35vw] outline-focus h-full border-none rounded-xl py-1.5 px-12 text-base font-normal bg-[#F1F1F1] placeholder-black"
								placeholder="Search Rider"
								onChange={(e) => {
									// const searchedRiders = allRiders.filter((rider) =>
									// 	rider.name.toLowerCase().includes(e.target.value?.toLowerCase())
									// );
									// setAllRidersData(searchedRiders);
									// if (e.target.value.length === 0) searchedRiders(allRiders);
									setInputValue(e.target.value);
								}}
							/>
							<div className="absolute left-4 top-[50%] translate-y-[-50%]">
								<SearchNormal1 color="gray" size={20} className="search-icon " />
							</div>
						</div>
					</div>
					<div className="w-full mb-10">
						<TableComponent
							data={allRiders?.riders}
							columns={columns}
							customwidths={["w-[20%]", "w-[17.5%]", "w-[17.5%]", "w-[17.5%]", "w-[15%]", "w-[12.5%]"]}
							isLoading={riderListStatus === "pending"}
							isSort="true"
							customeClass="max-h-[48vh] h-[48vh] pr-3"
						/>
					</div>
					{allRiders?.totalPages >= 1  &&
					<div className={`flex items-center gap-x-3 2xl:pt-2 absolute bottom-4 ${pageNo === 1 && allRiders?.riders?.length < 10 && "hidden"}`}>
						<div className=''>
							<Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`}
								disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
						</div>
						<div className=''>
							<Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo >= allRiders?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`}
								disabled={pageNo >= allRiders?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
						</div>
						<div className=''>
							<p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {allRiders?.totalPages}</p>
						</div>
						<div className="ml-10 flex items-center justify-center gap-x-3">
							<p className='text-[#727272] text-base font-inter-regular'>Page Size</p>
							{/* <p className="relative w-[3rem] h-[2.5rem] flex items-center justify-start px-2 text-base cursor-pointer font-inter-medium border-[#E5E5E5] border border-solid rounded-[5px]">{pageSize}
								<img src={ArrowDropDown} className="absolute left-8 h-3 w-3 "/>
								</p> */}
							<SelectMenu
								showCheckbox={false}
								options={pageOptions}
								value={pageSize}
								menuPlacement={"top"}
								onChange={(value) => setPageSize(value)}
								customeClassName='hidden'
								classNameForAllRiders='h-[2.5rem] custome-ht-all-riders'
							/>
						</div>
					</div>
					}
				</div>
			</div>
		</div>
	);
}
