import React, { useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Tabs from "../../../shared/components/Tabs.component";
import FranchiseInfo from "./Franchise_Info";

function FranchiseDetails() {

	const navigate = useNavigate();
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [franchiseName, setFranchiseName] = useState('');

	const handleFranchiseNameChange = (name) => {
		setFranchiseName(name);
	};

	let categories = [
		{
			title: `Primary Information`,
			status: "primaryInformation",
			component: <FranchiseInfo selectedIndex={selectedIndex} onFranchiseNameChange={handleFranchiseNameChange} />,
		},
		{
			title: `Secondary Information`,
			status: "secondaryInformation",
			component: <FranchiseInfo selectedIndex={selectedIndex} onFranchiseNameChange={handleFranchiseNameChange} />,
		},
		{
			title: `Franchise Information`,
			status: "franchiseInformation",
			component: <FranchiseInfo selectedIndex={selectedIndex} onFranchiseNameChange={handleFranchiseNameChange} />,
		},
		{
			title: `Documents`,
			status: "documents",
			component: <FranchiseInfo selectedIndex={selectedIndex} onFranchiseNameChange={handleFranchiseNameChange} />,
		},
		{
			title: `Payment Information`,
			status: "paymentInformation",
			component: <FranchiseInfo selectedIndex={selectedIndex} onFranchiseNameChange={handleFranchiseNameChange} />,
		},
		{
			title: `Commission Information`,
			status: "commissionInformation",
			component: <FranchiseInfo selectedIndex={selectedIndex} onFranchiseNameChange={handleFranchiseNameChange} />,
		},
	];

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<div className="flex items-center space-x-4">
							<FiArrowLeft
								size={22}
								onClick={() => {
									navigate("/franchise-management");
									setTimeout(() => {	
										sessionStorage.removeItem('currentPage');
									}, 500);
								}}
								className="cursor-pointer back-arrow"
							/>
							<h1 className="2xl:text-[22px] text-[20px] font-futura-bold heading-at-zoom">{franchiseName}</h1>
						</div>
					</div>
					<Tabs
						categories={categories}
						className="w-full py-6"
						setSelectedIndex={setSelectedIndex}
						selectedIndex={selectedIndex}
					/>
					<div className="mt-3 overflow-y-hidden overflow-x-hidden brand-details-section max-h-[68vh]">
						{categories[selectedIndex].component}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FranchiseDetails
