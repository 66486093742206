import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import DeleteIcon from "../../assets/icons/Delete.png";
import EditIcon from "../../assets/icons/Edit.png";
import CallIcon from "../../assets/icons/call-icon.png";
import { Message } from "iconsax-react";
import SelectMenu from '../../shared/components/SelectMenu.component';
import Tabs from '../../shared/components/Tabs.component';
import useSelectModal from '../../utils/hooks/useSelectModal';
import { useLazyGetRiderEmployeeDetailsQuery, useLazyGetTotalProfitQuery, useLazyGetTotalServicesQuery } from '../../utils/Store';
import Avatar from 'react-avatar';
import { Location, Setting3, EmptyWallet, Star1 } from "iconsax-react";
import RiderSalaryAllowance from './salary-allowance-module/Rider_Salary_Allowance';
import LeadsTable from './leads-module/Leads_Table';
import RiderServices from './rider-services/Rider_Services';
import CashCollection from './cash-collection-module/Cash_Collection';
import TimeLogged from './time-logged-module/Time_Logged';
import ShiftTiming from './shift-module/Shift_Timing';

function RiderInfo() {
    const { openModal } = useSelectModal();
    const [getRiderDetails, { data: riderDetailsList }] = useLazyGetRiderEmployeeDetailsQuery();
    const [getTotalServices, { data: totalServicesList }] = useLazyGetTotalServicesQuery();
    const [getTotalProfit, { data: totalProfitList }] = useLazyGetTotalProfitQuery();
    const navigate = useNavigate();
    const { riderId: employeeId } = useParams();

    useEffect(() => {
        if (employeeId) getRiderDetails(employeeId);
        getTotalProfit(employeeId);
        getTotalServices(employeeId);
    }, [employeeId]);

    const selectArea = [
        ...(riderDetailsList?.areas?.map((areas) => (
            {
                ...areas,
                value: areas?._id,
                name: areas?.name,
            }
        )) || []),
        { value: 'All Area', name: 'All Area' },
    ];

    const [selectedValue, setSelectedValue] = useState({ value: 'All Area', name: 'All Area' })
    const [riderDetailsData, setRiderDetailsData] = useState(riderDetailsList);

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption);

        if (selectedOption.name === 'All Area') {
            setRiderDetailsData(riderDetailsList);
        } else {
            const selectedVehicle = riderDetailsList.find(
                (vehicle) => vehicle._id === selectedOption?._id
            );
            setRiderDetailsData(selectedVehicle);
        }
    };

    useEffect(() => {
        if (riderDetailsList) {
            setSelectedValue({ value: 'All Area', name: 'All Area' });
            setRiderDetailsData(riderDetailsList);
        }
    }, [riderDetailsList]);

    useEffect(() => {
        setRiderDetailsData(riderDetailsList);
    }, [])

    const [selectedIndex, setSelectedIndex] = useState(0)
    let categories = [
        {
            title: `Services`,
            status: "services",
            component: <RiderServices
                areasId={(riderDetailsList?.areas || []).map(areaId => areaId?._id)}
            />

        },
        {
            title: `Shift Timings`,
            status: "shiftTimings",
            component: <ShiftTiming riderName = {riderDetailsList?.name}/>,
        },
        {
            title: `Cash Collection`,
            status: "cashCollection",
            component: <CashCollection selectedIndex={selectedIndex} />
        },
        {
            title: `Supply / Asset`,
            status: "supplyAsset",
            // component: <CustomerManagementNotification />,
        },
        {
            title: `Time Logged`,
            status: "timeLogged",
            component: <TimeLogged selectedIndex={selectedIndex} />,
        },
        {
            title: `Salary Allowance`,
            status: "salaryAllowance",
            component: <RiderSalaryAllowance selectedIndex={selectedIndex} />,
        },
        {
            title: `Leads`,
            status: "leads",
            component: <LeadsTable selectedIndex={selectedIndex} />,
        },
        {
            title: `Stats`,
            status: "stats",
            // component: ,
        },
        {
            title: `Statement of Accounts`,
            status: "statementOfAccounts",
        },
    ];

    return (
        <div className={`pl-8 2xl:pr-24 pb-4 pr-16 pt-8 w-full bg-[#F5F5F5] ${(selectedIndex === 2 || selectedIndex === 4) ? 'min-h-[inherit]' : 'min-h-full'}`}>
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="flex items-center space-x-4">
                        <FiArrowLeft
                            className="cursor-pointer back-arrow"
                            size={22}
                            onClick={() => navigate(-1)}
                        />
                        <h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                            {riderDetailsList?.name}
                        </h1>
                    </div>
                </div>
                <div className="flex justify-between 2xl:mt-5 mt-2 2xl:h-[34vh] lg:h-[36vh] h-[36vh]">
                    <div className="py-5 px-6 bg-[#FFFFFF] rounded-xl w-[65%] 2xl:w-[67%]">
                        <div className='flex justify-between items-center'>
                            <div className="flex items-center">
                                <span className='mr-3'>
                                    {
                                        riderDetailsList?.profileImageURL ? <span className='block w-12 h-12 rounded-full overflow-hidden'><img className='w-full h-full' src={riderDetailsList?.profileImageURL} alt="" /></span> : <Avatar
                                            name={riderDetailsList?.name}
                                            size="60"
                                            round={true}
                                            color="#F1F1F1"
                                            fgColor="#000"
                                            textSizeRatio="1.5"
                                        />
                                    }
                                </span>
                                <h1 className="2xl:text-base font-inter-bold ml-4">{riderDetailsList?.name}</h1>
                                <div className='flex ml-10 space-x-1'>
                                    <span className="text-black-400 text-sm ml-1">★★★★☆</span>
                                </div>
                                <div className='ml-4 flex items-center'>
                                    {
                                        riderDetailsList?.riderStatus === 'online' ? (
                                            <div className='w-4 h-4 rounded-full bg-[#24BB61] mr-2'></div>
                                        ) : (
                                            <div className='w-4 h-4 rounded-full bg-[#A8A8A8] mr-2'></div>
                                        )
                                    }
                                    {riderDetailsList?.riderStatus}
                                </div>
                            </div>

                            <div className='flex'>
                                <img className='cursor-pointer 2xl:h-[16px] 2xl:w-[16px] h-[12px] w-[12px]'
                                    src={EditIcon}
                                    onClick={() => navigate(`/employee-management/edit/${riderDetailsList?._id}`, { state: { isFromRiderInfoPage: true } })}
                                    alt="" />

                                <img className='ml-4 cursor-pointer'
                                    src={DeleteIcon} onClick={() => openModal("DeleteRiderModal", { riderData: { _id: riderDetailsList?._id, riderName: riderDetailsList?.name }, refetchRiders: () => navigate(-1), hideCloseArrow: true })}
                                    alt="" />
                            </div>
                        </div>

                        <div className='flex 2xl:mt-10 mt-8'>
                            <div className='flex items-center mr-6'>
                                <img className='w-4 h-4' src={CallIcon} alt="" />
                                <p className='text-[#474747] 2xl:text-base text-sm ml-2'>{riderDetailsList?.phoneNumber}</p>
                            </div>

                            <div className='flex items-center mr-6'>
                                <Message className='text-[#474747] w-5 h-5' />
                                <p className='text-[#474747] 2xl:text-base text-sm ml-2'>{riderDetailsList?.email}</p>
                            </div>

                            <div className='flex items-center'>
                                <Location className='text-[#474747] w-5 h-5' />
                                <p className='text-[#474747] 2xl:text-base text-sm ml-2'>{riderDetailsList?.address}</p>
                            </div>
                        </div>

                        <div className='flex 2xl:mt-10 mt-8'>
                            <div className='flex mr-8 max-w-[50%]'>
                                <p className='2xl:text-base text-sm mr-6'>Superviser:</p>
                                <p className='2xl:text-base text-sm whitespace-nowrap max-w-[90%] overflow-x-auto final-price-scrollbar'>
                                    {riderDetailsList?.supervisor?.map((superviser) => {
                                        return superviser?.name
                                    }).join(', ') || "NA"}
                                </p>
                            </div>

                            <div className='flex max-w-[50%]'>
                                <p className='2xl:text-base text-sm mr-6'>City Manager:</p>
                                <p className='2xl:text-base text-sm whitespace-nowrap max-w-[90%] overflow-x-auto final-price-scrollbar'>
                                    {riderDetailsList?.citymanager?.map((citymanager) => {
                                        return citymanager?.name
                                    }).join(', ') || "NA"}
                                </p>
                            </div>
                        </div>

                        <div className='2xl:mt-10 mt-8 flex items-center justify-between'>
                            <div className='flex items-center customer-info-select-vehicle'>
                                <p className='2xl:text-base text-sm mr-6'>Assigned Area :</p>
                                <SelectMenu
                                    options={selectArea}
                                    value={selectedValue}
                                    onChange={handleSelectChange}
                                    showCheckbox={false}
                                    multiSelectClass="w-[17vw]"
                                    className="vehicle-dropdown-height"
                                    classNameForAllRiders="assigned-area-font-size"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='w-[34%] 2xl:w-[32%]'>
                        <div className='py-3 2xl:px-3 px-3 bg-[#FFFFFF] rounded-xl border border-[#EDEDED] shadow-rider-info'>
                            <p className='2xl:text-[14px] text-[10px] mb-2 font-inter-medium'>Total Number of Services</p>
                            <div className='flex justify-between space-x-2'>
                                <div className='py-2 px-2 bg-[#FFFFFF] border rounded-xl w-full h-[45%] shadow-rider-info'>
                                    <div className='flex items-center mb-3'>
                                        <div className='w-7 h-7 rounded-full bg-[#0D6EFD] flex items-center justify-center mr-3'>
                                            <Setting3
                                                color='#FFF'
                                                className='w-4 h-4'
                                            />
                                        </div>
                                        <p className='2xl:text-[14px] text-[10px]'>Previous Month</p>
                                    </div>
                                    <div className='2xl:h-[2px] h-[1px] w-full bg-[#E5E5E5]'></div>
                                    <div className='mt-2'>
                                        <p className='2xl:text-lg text-sm font-inter-medium'>{totalServicesList?.previousMonthCount}</p>
                                    </div>
                                </div>

                                <div className='py-2 px-2 bg-[#FFFFFF] border rounded-xl w-full h-[45%] shadow-rider-info'>
                                    <div className='flex items-center mb-3'>
                                        <div className='w-7 h-7 rounded-full bg-[#0D6EFD] flex items-center justify-center mr-3'>
                                            <Setting3
                                                color='#FFF'
                                                className='w-4 h-4'
                                            />
                                        </div>
                                        <p className='2xl:text-[14px] text-[10px]'>Current Month</p>
                                    </div>
                                    <div className='2xl:h-[2px] h-[1px] w-full bg-[#E5E5E5]'></div>
                                    <div className='mt-2'>
                                        <p className='2xl:text-lg text-sm font-inter-medium'>{totalServicesList?.currentMonthCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='py-3 2xl:px-3 px-3 bg-[#FFFFFF] rounded-xl border border-[#EDEDED] shadow-rider-info mt-2'>
                            <p className='2xl:text-[14px] text-[10px] mb-2 font-inter-medium'>Total Profit</p>
                            <div className='flex justify-between space-x-2'>
                                <div className='py-2 px-2 bg-[#FFFFFF] border rounded-xl w-full h-[45%] shadow-rider-info'>
                                    <div className='flex items-center mb-3'>
                                        <div className='w-7 h-7 rounded-full bg-[#198754] flex items-center justify-center mr-3'>
                                            <EmptyWallet
                                                color="#FFF"
                                                className='w-4 h-4'
                                            />
                                        </div>
                                        <p className='2xl:text-[14px] text-[10px]'>Previous Month</p>
                                    </div>
                                    <div className='2xl:h-[2px] h-[1px] w-full bg-[#E5E5E5]'></div>
                                    <div className='mt-2'>
                                        <p className='2xl:text-lg text-sm font-inter-medium'> ₹ {totalProfitList?.totalPreviousMonthProfit}</p>
                                    </div>
                                </div>

                                <div className='py-2 px-2 bg-[#FFFFFF] border rounded-xl w-full h-[45%] shadow-rider-info'>
                                    <div className='flex items-center mb-3'>
                                        <div className='w-7 h-7 rounded-full bg-[#198754] flex items-center justify-center mr-3'>
                                            <EmptyWallet
                                                color="#FFF"
                                                className='w-4 h-4'
                                            />
                                        </div>
                                        <p className='2xl:text-[14px] text-[10px]'>Current Month</p>
                                    </div>
                                    <div className='2xl:h-[2px] h-[1px] w-full bg-[#E5E5E5]'></div>
                                    <div className='mt-2'>
                                        <p className='2xl:text-lg text-sm font-inter-medium'> ₹ {totalProfitList?.totalCurrentMonthProfit}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='2xl:mt-5 mt-3'>
                    <Tabs
                        categories={categories}
                        className="w-full"
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex} />
                </div>

                <div className="max-h-[60vh] 2xl:mt-3 mt-3">
                    {categories[selectedIndex].component}
                </div>
            </div>
        </div>
    )
}

export default RiderInfo