import React, { useEffect } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import Button from "../../shared/components/Button.component";
import SelectMenu from "../../shared/components/SelectMenu.component";
import {
	useGetTotalAmountMutation,
	useLazyGetReasonsQuery,
	useUpdateAppointmentMutation,
} from "../../utils/Services/Apis/onDemand";
import RadioButton from "../../shared/components/RadioButton";
import useSelectModal from "../../utils/hooks/useSelectModal";
import useToaster from "../../utils/hooks/useToaster";
import * as Yup from "yup";

const schema = Yup.object().shape({
	reason: Yup.object().required("Please select reason!"),
	note: Yup.string().required("Please select note!"),
});

function UpdateAppointmentModal({ data }) {
	const [getReasons, { data: reaonsList, status }] = useLazyGetReasonsQuery();
	const [updateAppointment, { status: updateAppointmentStatus }] = useUpdateAppointmentMutation();
  const { openModal, closeModal } = useSelectModal();
	const { setToast } = useToaster();
  const [getTotalAmount, {data : amountData}]= useGetTotalAmountMutation()

		useEffect(()=>{
		if(!data) return
		getTotalAmount({
			orderId: data._id,
			vehicleType: data.vehicleType
		})

	},[data])

	useEffect(() => {
		getReasons();
	}, []);
	
	const handleUpdateAppointment = async (values) => {
		if (values.type === "cancel") {
			const { data: d, error } = await updateAppointment({
				orderId: data?._id,
				status: "cancel",
				data: {
					reason: values.reason?.reason,
					note: values.note,
				},
			});
			if (d) {
				openModal("CancelModal", data);
				data.handleGetAppointments(data.fetchStatus)
			}
			if (error) {
				setToast({ type: "error", msg: error.data.message });
				closeModal();
			}
		}
		if (values.type === "completed") {
			const { data: d, error } = await updateAppointment({
				orderId: data?._id,
				status: "complete",
				data: {
					reason: values.reason?.reason,
					note: values.note,
				},
			});

			if (d) setToast({ type: "success", msg: d.message });
			if (error) {
				if(error.data.message === "Order is already completed and verified") {
					setToast({ type: "success", msg: error.data.message });
					data.handleGetAppointments(data.fetchStatus)
					closeModal();
				} else setToast({ type: "error", msg: error.data.message });
				return
			} 

			if (amountData?.totalAmount === 0){
				data.handleGetAppointments(data.fetchStatus)
				return closeModal();
			} 

			openModal("PaymentModal",  {...data, customeClassForModal: 'margin-top-modal' , totalAmount :amountData.totalAmount});
		}
	};

	return (
		<Formik
			validationSchema={schema}
			initialValues={{
				type: "cancel",
				reason: "",
				note: ""
			}}
			onSubmit={handleUpdateAppointment}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form action="#" method="POST">
					<UpdateAppointment
						reaonsList={reaonsList}
						updateAppointment={updateAppointment}
						data={data}
						updateAppointmentStatus={updateAppointmentStatus}
					/>
				</Form>
			)}
		</Formik>
	);
}

export default UpdateAppointmentModal;

const UpdateAppointment = ({ reaonsList, updateAppointment, data, updateAppointmentStatus }) => {
	const { values, setFieldValue ,touched, errors } = useFormikContext();

	return (
		<div className="sm:w-[35vw] w-[40vw] w-f p-6 px-16 bg-[#FFFFFF] rounded-xl pb-8">
			<div className=" justify-start pt-4  pb-3">
				<h1 className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Reason to Update Appointment</h1>
			</div>
			<div className="gap-3 items-center py-3">
				<div className="w-full h-full">
					<div className="flex items-center justify-between w-full gap-4 font-normal text-sm">
						<div className="py-2 px-4 w-full bg-[#D9D9D9] bg-opacity-20 rounded-lg border">
							<label className="flex items-center gap-2">
								<RadioButton name="type" value="cancel" />
								<span className="text-[#000000] font-regular 2xl:text-base text-sm">Cancel Appointment</span>
							</label>
						</div>
						<div className="py-2 px-4 w-full bg-[#D9D9D9] bg-opacity-20 rounded-lg border">
							<label className="flex items-center gap-2">
								<RadioButton name="type" value="completed" />
								<span className="text-[#000000] font-regular 2xl:text-base text-sm">Mark as Completed</span>
							</label>
						</div>
					</div>
				</div>
				<div className="px-5">
					<hr className="border bg-[#D9D9D9] w-full mt-5" />
				</div>
				<div className="pt-6 pb-4">
					<SelectMenu
						placeholder="Select Reason"
						customeClassName="font-inter-medium"
						name="reason"
						options={reaonsList}
						optionLabel="label"
						hideSelectedOptions={false}
						onChange={(option) => setFieldValue("reason", option)}
						value={values.reason}
						multiSelectClass="2xl:text-base text-sm"
					/>
					{touched.reason && errors.reason ? (
						<div className="text-red-600 text-sm">{errors.reason}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				<div className="">
					<Field
						placeholder="Write Note"
						name="note"
						as="textarea"
						className="w-full h-40 rounded-[10px] border bg-[#D9D9D9] bg-opacity-20 border-[#D9D9D9] px-3 py-3 placeholder-gray-400 text-base focus:outline-none focus:border-[#D9D9D9] focus:ring-transparent "
					/>
					{touched.note && errors.note ? (
						<div className="text-red-600 text-sm">{errors.note}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
				</div>
				<div className="flex justify-center pt-8 pb-1">
					<Button
						className="w-full text-white cursor-pointer bottom-btn-ht text-base font-inter-bold"
						type="submit"
						isSubmitting={updateAppointmentStatus === "pending"}
					>
						Submit
					</Button>
				</div>
			</div>
		</div>
	);
};
