import { Navigate, Route, Routes, redirect, useNavigate } from "react-router-dom";
import "./App.css";
import Navbar from "./shared/components/Navbar.component";
import NotFound from "./shared/pages/NotFound.page";
import SignIn from "./shared/pages/Signin.page";
import SignUp from "./shared/pages/Signup.page";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Toaster from "./shared/components/Toaster.component";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./shared/components/Sidebar.component";
import { useEffect, useState } from "react";
import useAuth from "./utils/hooks/useAuth";
import Modal from "./shared/components/Modal.component";
import CardLayout from "./shared/components/CardLayout.component";
import useSelectModal from "./utils/hooks/useSelectModal";
import CarManagement from "./pages/car-management/Car_Management";
import Main from "./shared/components/Main.component";
import CarListPage from "./pages/car-management/Car_List_Page";
import AddCarPage from "./pages/car-management/Add_Car_Page";
import CityManagement from "./pages/area-and-city-management/City_Management";
import AreaRadiusManagement from "./pages/area-and-city-management/Area_Radius_Management";
import ServiceRequestMangement from "./pages/service-request-mangement/Service_Request_Mangement";
import EmployeeManagementPage from "./pages/employee-management/Employee_Management_Page";
import AddNewEmployee from "./pages/employee-management/Add_New_Employee";
import EmployeeDetailsPage from "./pages/employee-management/Employee_Details";
import AreaDetailsManagement from "./pages/area-and-city-management/Area_Details_Management";
import AllServices from "./pages/service-management/All_Services_Page";
import ServiceInfo from "./pages/service-management/Service_Info";
import PackagesPage from "./pages/service-management/Packages_Page";
import SubscriptionPage from "./pages/service-management/Subscription_Page";
import ServiceReports from "./pages/service-management/Service_Reports";
import NewServiceRequest from "./pages/service-request-mangement/New_Service_Request";
import OnDemandMangement from "./pages/on-demand-management/On_Demand_Management";
import MasterDataPage from "./pages/master-data/area/Master_Data_Page";
import CustomerManagement from "./pages/customer-management/Customer_Management";
import CustomerInfo from "./pages/customer-management/Customer_Info";
import CustomerVehicleCount from "./pages/customer-management/Customer_Vehicle_Count";
import CustomerDueAmount from "./pages/customer-management/Customer_Due_Amount";
import CustomerAddress from "./pages/customer-management/Customer_Address";
import ConfigurationTable from "./pages/configurations/ConfigurationTable";
import RiderManagement from "./pages/rider-management/Rider_Management";
import AllRiders from "./pages/rider-management/All_Riders";
import RiderInfo from "./pages/rider-management/Rider_Info";
import ShiftMaster from "./pages/rider-management/Shift_Master";
import AllFranchise from "./pages/franchise-management/all-franchise/All_Franchise"
import PendingFranchise from "./pages/franchise-management/pending-franchise/Pending_Franchise"
import CreateNewFranchise from "./pages/franchise-management/all-franchise/Create_New_Franchise";
import FranchiseDetails from "./pages/franchise-management/all-franchise/Franchise_Details";
import { nanoid } from "@reduxjs/toolkit";
import NewPassword from "./shared/pages/NewPassword.page";
import RoleManagement from "./pages/role-management/Role_Management";
import AddNewRole from "./pages/role-management/Add_New_Role";
import MenuMaster from "./pages/master-data/area/menu-master/Menu_Master";
import CreateMenuMaster from "./pages/master-data/area/menu-master/Create_Menu_Master";
import SubMenuMaster from "./pages/master-data/area/submenu-master/Sub_Menu_Master";
import CreateSubMenuMaster from "./pages/master-data/area/submenu-master/Create_SubMenu_Master";

function App() {
	const { isLogin } = useAuth();
	const { renderModal } = useSelectModal();
	const [open, setOpen] = useState(true);
	localStorage.setItem("socketId", nanoid());
	const [userRole, setUserRole] = useState('superadmin');
	// const roleOfUser = localStorage.getItem('role')

	return (
		<>
			<div className="flex flex-col h-screen">
				{isLogin() ? <Navbar /> : <></>}
				<Routes>
					<Route element={<PublicRoute />}>
						<Route path="/signin" element={<SignIn setUserRole={setUserRole} />} />
						<Route path="/signup" element={<SignUp />} />
						<Route path="/reset-password/:tokenId" element={<NewPassword />} />
					</Route>
					<Route element={<PrivateRoute />}>
						<Route element={<Sidebar open={open} setOpen={setOpen} />}>
							{/* {
								roleOfUser !== 'superadmin' && (
									<Route path="/" element={<Navigate to="/franchise-management" replace />} />
								)
							}
							{
								roleOfUser === 'superadmin' &&  (
									<Route path="/" element={<Navigate to="/car-management" replace />} />
								)
							} */}
							<Route path="/" element={<Navigate to="/franchise-management" replace />} />
							<Route path="" element={<CardLayout />} />
							<Route
								path="/car-management"
								disabled
								element={
									<Main>
										<CarManagement />
									</Main>
								}
							/>
							<Route
								path="/carlist-page/:brandId"
								element={
									<Main>
										<CarListPage />
									</Main>
								}
							/>
							<Route
								path="/add-new-car"
								element={
									<Main>
										<AddCarPage />
									</Main>
								}
							/>
							<Route
								path="/edit-car/:id"
								element={
									<Main>
										<AddCarPage />
									</Main>
								}
							/>
							<Route
								path="/area-city-management"
								element={
									<Main>
										<CityManagement />
									</Main>
								}
							/>
							<Route
								path="/area-management/:cityId"
								element={
									<Main>
										<AreaRadiusManagement />
									</Main>
								}
							/>
							<Route
								path="/employee-management"
								element={
									<Main>
										<EmployeeManagementPage />
									</Main>
								}
							/>
							<Route
								path="/employee-management/:employeeId"
								element={
									<Main>
										<EmployeeDetailsPage />
									</Main>
								}
							/>
							<Route
								path="/employee-management/add"
								element={
									<Main>
										<AddNewEmployee />
									</Main>
								}
							/>
							<Route
								path="/employee-management/edit/:employeeId"
								element={
									<Main>
										<AddNewEmployee />
									</Main>
								}
							/>
							<Route
								path="/area-details-management/:areaId"
								element={
									<Main>
										<AreaDetailsManagement />
									</Main>
								}
							/>

							{/* Service Request Routes Start */}
							<Route
								path="/service-management"
								element={
									<Main>
										<AllServices />
									</Main>
								}
							/>
							{/* <Route
								path="/service-management/packages"
								element={
									<Main>
										<PackagesPage/>
									</Main>
								}
							/>
							<Route
								path="/service-management/subscription"
								element={
									<Main>
										<SubscriptionPage/>
									</Main>
								}
							/> */}
							<Route
								path="/add-new-service"
								element={
									<Main>
										<NewServiceRequest />
									</Main>
								}
							/>
							<Route
								path="/service-management/:serviceId"
								element={
									<Main>
										<ServiceInfo />
									</Main>
								}
							/>
							<Route
								path="/service-management/reports"
								element={
									<Main>
										<ServiceReports />
									</Main>
								}
							/>

							{/* Service Request Routes Start */}
							<Route
								path="/appointment-management"
								element={
									<Main>
										<ServiceRequestMangement />
									</Main>
								}
							/>
							<Route
								path="/ondemand-management"
								element={
									<Main>
										<OnDemandMangement />
									</Main>
								}
							/>
							<Route
								path="/area-master"
								element={
									<Main>
										<MasterDataPage />
									</Main>
								}
							/>
							<Route
								path="/area-master/menu-master"
								element={
									<Main>
										<MenuMaster />
									</Main>
								}
							/>
							<Route
								path="/area-master/menu-master/create-menu-master"
								element={
									<Main>
										<CreateMenuMaster />
									</Main>
								}
							/>
							<Route
								path="/area-master/submenu-master"
								element={
									<Main>
										<SubMenuMaster />
									</Main>
								}
							/>
							<Route
								path="/area-master/submenu-master/create-submenu-master"
								element={
									<Main>
										<CreateSubMenuMaster />
									</Main>
								}
							/>
							{/* Service Request Routes END */}

							<Route
								path="/customer-management"
								element={
									<Main>
										<CustomerManagement />
									</Main>
								}
							/>

							<Route
								path="/customer-management/:customerId"
								element={
									<Main>
										<CustomerInfo />
									</Main>
								}
							/>

							<Route
								path="/customer-management/:customerId/customer-vehicles"
								element={
									<Main>
										<CustomerVehicleCount />
									</Main>
								}
							/>

							<Route
								path="/customer-management/:customerId/due-amount"
								element={
									<Main>
										<CustomerDueAmount />
									</Main>
								}
							/>

							<Route
								path="/customer-management/:customerId/address"
								element={
									<Main>
										<CustomerAddress />
									</Main>
								}
							/>

							<Route
								path="/rider-management"
								element={
									<Main>
										<RiderManagement />
									</Main>
								}
							/>

							<Route
								path="/rider-management/all-riders"
								element={
									<Main>
										<AllRiders />
									</Main>
								}
							/>

							<Route
								path="/rider-management/shift-master"
								element={
									<Main>
										<ShiftMaster />
									</Main>
								}
							/>

							<Route
								path="/configuration"
								element={
									<Main>
										<ConfigurationTable />
									</Main>
								}
							/>

							<Route
								path="/rider-management/all-riders/:riderId"
								element={
									<Main>
										<RiderInfo />
									</Main>
								}
							/>
							<Route
								path="/appointment-management/:riderId"
								element={
									<Main>
										<ServiceRequestMangement />
									</Main>
								}
							/>
							<Route
								path="/ondemand-management/:riderId"
								element={
									<Main>
										<OnDemandMangement />
									</Main>
								}
							/>
							<Route
								path="/franchise-management"
								element={
									<Main>
										<AllFranchise />
									</Main>
								}
							/>
							<Route
								path="/franchise-management/pending-franchise"
								element={
									<Main>
										<PendingFranchise />
									</Main>
								}
							/>
							<Route
								path="/franchise-management/new-franchise"
								element={
									<Main>
										<CreateNewFranchise />
									</Main>
								}
							/>
							<Route
								path="/franchise-management/:franchiseId"
								element={
									<Main>
										<FranchiseDetails />
									</Main>
								}
							/>

							<Route
								path="/role-management"
								element={
									<Main>
										<RoleManagement/>
									</Main>
								}
							/>

							<Route
								path="/role-management/add-new-role"
								element={
									<Main>
										<AddNewRole/>
									</Main>
								}
							/>

							<Route path="*" element={<NotFound />} />
						</Route>
					</Route>
				</Routes>
				{renderModal && <Modal>{renderModal}</Modal>}
				{!renderModal && <Toaster />}
			</div>
		</>
	);
}

export default App;
