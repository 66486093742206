import React, { useState, useEffect } from 'react'
import useSelectModal from '../../utils/hooks/useSelectModal'
import editIcon from '../../assets/icons/car-management/editBlueIcon.svg'
import Button from '../../shared/components/Button.component'
import SelectMenu from '../../shared/components/SelectMenu.component'
import { Field, Form, Formik } from 'formik'
import useToaster from '../../utils/hooks/useToaster'
import * as Yup from "yup";
import { useLazyGetAllShiftsQuery, useUpdateShiftMutation, useUpdateTimeSlotCreationMutation } from '../../utils/Services/Apis/shiftMasterApi'
import { useLazyGetCitesQuery } from '../../utils/Store'

const EditMasterCategoryModal = ({ data }) => {
    const [getCites, { data: cities }] = useLazyGetCitesQuery();
    const [getAllShifts, { data: shiftList }] = useLazyGetAllShiftsQuery();
    const { status, ...otherProps } = data;

    const { closeModal } = useSelectModal();
    const [updateTimeSlotCreation, { status: updateTimeSlotCreationStatus }] = useUpdateTimeSlotCreationMutation();
    const [updateShift, {status: updateShiftStatus}] = useUpdateShiftMutation();
    const { setToast } = useToaster();

    const schema = Yup.object()
    .shape({
        selectedCities : status === "time-slot" && Yup.array().min(1, "City is required"), 
        shiftCategory: status === "shift-category" && Yup.string().trim().max(20, 'Shift Category should not exceed 20 characters').required("Shift Category is required"),
    })

    const handleSubmit = async (values) => {
        let d, error;
        if (status === "time-slot") {
            ({ data: d, error } = await updateTimeSlotCreation({
                id: data?.data?._id,
                data: { "isActive": true, "shiftCategoryId": values?.selectedShift?._id, "cityIds": values?.selectedCities.map(city => city?._id) },
            }));
        } else if (status === "shift-category") {
            ({ data: d, error } = await updateShift({
                id: data?.data?._id,
                data: { "isActive": true, "name": values.shiftCategory }
            }))
        }
        if (d) {
            setToast({ type: "success", msg: "Shift Category updated successfully" });
            closeModal();
            data.refetchData();
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
        }

        console.log('data', data?.data?._id);
    };


    useEffect(() => {
        getCites();
        getAllShifts();
    }, []);

    const handleInitialValues = () => {
        if(status === "time-slot") {
            return {
                selectedCities: data?.data?.cities || [],
                selectedShift: data?.data?.shiftcategories?.length ? data?.data?.shiftcategories[0] : "",
                selectedTimeSlot: data?.data?.name
            }
        }else if(status === "shift-category"){
            return {
                shiftCategory: data?.data?.name,
            }
        }
    }

    return (

        <Formik
            validationSchema={schema}
            initialValues={handleInitialValues()}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, touched, errors }) => (
                <Form action="#" method="POST">
                    <div className="w-[32rem] min-h-[24rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
                        <div className="flex items-center justify-between">
                            <div className="h-12 w-12 bg-[#0D6EFD] rounded-full flex justify-center items-center modal-logo-size">
                                <img src={editIcon} />
                            </div>
                        </div>

                        <div className="pb-6">
                            <div className="flex font-inter-bold justify-start pt-4 pb-1 text-[#000000] text-lg">
                                Edit {status === "time-slot" ? "Time slot" : "Shift Category Slot"}
                            </div>
                            <div className="text-[#87898E]">Edit an
                                {status === "time-slot" ? " existing preferred time slot by changing it’s details" : " existing Available by changing it's details"}
                            </div>
                        </div>
                        {
                            status === "time-slot" &&
                            <>
                                <div className="mb-1">
                                    <SelectMenu
                                        label="City"
                                        name="selectedCities"
                                        isMulti={true}
                                        options={cities}
                                        placeholder="Select City"
                                        multiSelectClass="h-[3rem] radius-unit-ht w-full"
                                        onChange={(option) => setFieldValue("selectedCities", option)}
                                        value={values.selectedCities}
                                    />
                                    {errors?.selectedCities && touched?.selectedCities ? (
                                        <div className="text-red-600 text-sm">{errors?.selectedCities}</div>
                                    ) : (
                                        <div className="invisible h-5"></div>
                                    )}
                                </div>

                                <div className="mb-1">
                                    <SelectMenu
                                        label="Shift Category"
                                        name="selectedShift"
                                        options={shiftList}
                                        placeholder="Select Shift Category"
                                        multiSelectClass="h-[3rem] radius-unit-ht w-full"
                                        onChange={(option) => setFieldValue("selectedShift", option)}
                                        value={values.selectedShift}
                                    />
                                    {touched.shiftCategory && errors.shiftCategory ? (
                                        <div className="text-red-600 text-sm">{errors.shiftCategory}</div>
                                    ) : (
                                        <div className="invisible h-5"></div>
                                    )}
                                </div>

                                <div className="">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Preferred time slot
                                    </label>
                                    <Field
                                        name="shiftCategory"
                                        type="text"
                                        className="block w-full rounded-md border border-gray-300 px-3 2xl:py-2 py-1 placeholder-gray-400 focus:border-gray-300 focus:outline-none text-sm h-[3rem]  focus:ring-transparent"
                                        placeholder="Enter Shift Category to add in master data "
                                        onKeyPress={(event) => {
                                            if (/[^a-zA-Z ]/.test(event.key)) {
                                                return event.preventDefault();
                                            }
                                        }}
                                        value={values.selectedTimeSlot}
                                    />
                                </div>
                            </>
                        }
                        {
                            status === "shift-category" &&
                            <>
                                <div className="mb-4">
                                <label className="block text-sm font-inter-medium leading-6 text-gray-900">
                                        Shift Category
                                    </label>
                                    <Field
                                        name="shiftCategory"
                                        type="text"
                                        className="block w-full rounded-md border border-gray-300 px-3 2xl:py-2 py-1 placeholder-gray-400 shadow-sm focus:border-gray-300 focus:outline-none text-sm h-[3rem] focus:ring-transparent "
                                        placeholder="Enter Shift"
                                    />
                                    {touched.shiftCategory && errors.shiftCategory ? (
                                        <div className="text-red-600 text-sm">{errors.shiftCategory}</div>
                                    ) : (
                                        <div className="invisible h-5"></div>
                                    )}
                                </div>
                            </>
                        }
                        <div className="flex justify-between pt-5 pb-4">
                            <Button className="w-[47.5%] h-[3.5rem] bg-[#F1F1F1] text-[#23262F] cursor-pointer rounded-[10px] font-inter-bold text-base border-[#D0D0D0]" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button className="w-[47.5%] h-[3.5rem] bg-[#000000] text-white cursor-pointer rounded-[10px] font-inter-bold text-base border" type="submit" isSubmitting={ updateTimeSlotCreationStatus === "pending" || updateShiftStatus === "pending"}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>

    )
}

export default EditMasterCategoryModal