import React, { useEffect } from 'react'
import TableComponent from '../../../shared/components/Table.component'
import CompleteService from '../../../assets/images/cars/complete-sevice-icon.png'
import moment from 'moment/moment'
import { useLazyGetNotificationsQuery } from '../../../utils/Services/Apis/customerManagementApi'
import { useParams } from 'react-router-dom'

function CustomerManagementNotification() {
    const { customerId } = useParams();
    const [getNotifications, {data: allNotifications, status: notificationStatus} ] = useLazyGetNotificationsQuery();

    useEffect(() => {
      getNotifications({customerId});
    }, []);

    const columns = [
        {
            header: 'Notifications',
            cell: ({row}) => {
                return (
                    <div className='flex items-center w-full'>
                        <div className='2xl:w-12 2xl:h-12 w-10 h-10 mr-2'>
                            <img className='w-full h-full' src={CompleteService}/>
                        </div>
                        <div className='w-[90%]'>
                            <p className='text-[#333333] font-inter-medium 2xl:text-base text-sm'>{row?.original?.notificationTitle}</p>
                            <p className='text-[#333333] font-inter-regular 2xl:text-base text-sm'>{row?.original?.notificationBody}</p>
                        </div>
                    </div>
                )
            }
            
        },
        {
            header: 'Date and Time',
            cell: ({row}) => {
                return (
                    <div className='text-[#333333] font-inter-regular 2xl:text-base text-sm'>
                        {`${moment(row?.original?.created).format('LT')}    ${moment(row?.original?.created).format('l')}`}
                    </div>
                )
            }
        }
    ]

    return (
        <div className='bg-white border rounded-xl px-5 my-3 h-[45.4vh] overflow-hidden brand-details-section relative'>
            <TableComponent
                data={allNotifications}
                columns={columns}
                customwidths={["w-[75%]", "w-[25%]"]}
                customeClass="max-h-[40vh] notification-tbody-ht pr-3"
                isLoading={notificationStatus === 'pending'}
            />
        </div>
    )
}

export default CustomerManagementNotification