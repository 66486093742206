import React, { useEffect } from 'react'
import useSelectModal from '../../utils/hooks/useSelectModal';
import Button from '../../shared/components/Button.component';
import { useRescheduleOrCancelMutation } from '../../utils/Store';
import useToaster from '../../utils/hooks/useToaster';
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import SelectMenu from '../../shared/components/SelectMenu.component';
import { useLazyGetReasonsQuery } from '../../utils/Services/Apis/onDemand';

function CancelAppointmentModal({ data }) {
    const { closeModal } = useSelectModal();
    const [ cancelOrder, { status:cancelOrderStatus } ] = useRescheduleOrCancelMutation();
    const { setToast } = useToaster();
    const [getReasons, { data: reaonsList, status }] = useLazyGetReasonsQuery();

    const schema = Yup.object().shape({
        reason: Yup.object().required("Please select reason!"),
        note: Yup.string().required("Please select note!"),
    });

    useEffect(() => {
        getReasons();
    }, [])
    

    const handleCancelAppointment = (values) => {
        cancelOrder({
			data: {
                status: data?.status,
                note: values.note,
                reason: values.reason?.reason,
            },
			orderId: data?.orderId,
		})
        .unwrap()
        .then(() => {
			setToast({ type: "success", msg: 'Order Cancelled' });
            closeModal()
            data?.refetch && data?.refetch()
		}).catch((error) => {
            setToast({ type: "error", msg: error.data.message });
            closeModal()
		})
    }
    return (
        <Formik
			validationSchema={schema}
			initialValues={{
				type: "cancel",
				reason: "",
				note: ""
			}}
			onSubmit={handleCancelAppointment}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form action="#" method="POST">
					<CancelAppointment
                        reaonsList={reaonsList}
						cancelOrderStatus={cancelOrderStatus}
                        closeModal={closeModal}
                    />
				</Form>
			)}
		</Formik>
    )
}

export default CancelAppointmentModal


const CancelAppointment = ({ reaonsList, cancelOrderStatus, closeModal }) => {
	const { values, setFieldValue ,touched, errors } = useFormikContext();

	return (
		<div className="2xl:w-[35vw] w-[40vw] p-6 px-16 bg-[#FFFFFF] rounded-xl pb-8">
            <div className=" justify-start pt-4  pb-3">
                <h1 className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Reason to Cancel the Appointment</h1>
            </div>
            <div className="gap-3 items-center py-3">
                <div className="pt-6 pb-4">
                    <SelectMenu
                        placeholder="Select Reason"
                        customeClassName="font-inter-medium"
                        name="reason"
                        options={reaonsList}
                        optionLabel="label"
                        hideSelectedOptions={false}
                        onChange={(option) => setFieldValue("reason", option)}
                        value={values.reason}
                        multiSelectClass="2xl:text-base text-sm"
                    />
                    {touched.reason && errors.reason ? (
                        <div className="text-red-600 text-sm">{errors.reason}</div>
                    ) : (
                        <div className="invisible h-5"></div>
                    )}
                </div>
                <div className="">
                    <Field
                        placeholder="Write Note"
                        name="note"
                        as="textarea"
                        className="w-full h-40 max-h-60 rounded-[10px] border bg-[#D9D9D9] bg-opacity-20 border-[#D9D9D9] px-3 py-3 placeholder-gray-400 text-base focus:outline-none focus:border-[#D9D9D9] focus:ring-transparent "
                    />
                    {touched.note && errors.note ? (
                        <div className="text-red-600 text-sm">{errors.note}</div>
                    ) : (
                        <div className="invisible h-5"></div>
                    )}
                </div>
                <div className="flex justify-center pt-8 pb-1 space-x-6">
                    <Button
                        className="w-full text-black cursor-pointer bottom-btn-ht text-base font-inter-bold bg-[#D9D9D9]"
                        type=''
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="w-full text-white cursor-pointer bottom-btn-ht text-base font-inter-bold"
                        type="submit"
                        isSubmitting={cancelOrderStatus === "pending"}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </div>
	);
};
