import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const columnStyles = {
        Name: { cellWidth: 25 },
        Mobile: { cellWidth: 20 },
        Address: { cellWidth: 39 }, // Example width
        Date: { cellWidth: 17 }, // Example width
        Slot: { cellWidth: 22 }, // Example width
        StartTime: { cellWidth: 16 }, // Example width
        EndTime: { cellWidth: 16 }, // Example width
        Services: { cellWidth: 32 }, // Example width
        Status: { cellWidth: 29 }, // Example width 
        Rating: { cellWidth: 17 }, // Example width
        RiderStatus: { cellWidth: 29 }, // Example width
        Amount: { cellWidth: 15 } // Example width
      }

const headStyles = {
    fillColor: [255, 255, 255], // Set header background color to white
    textColor: [0, 0, 0],
    lineWidth: 0.1,
    fontStyle: 'normal', // Width of the border line
    // lineColor: [0, 0, 0] // You might want to set the text color to a contrasting color like black
  }    

 export const generatePDF = (jsonData, riderName= "records") => {
    const doc = new jsPDF({
    orientation: "landscape", // Change to landscape orientation
  });

    // Define the column names for the tables
    const tableColumns = [
      { title: "Name", dataKey: "Name" },
      { title: "Mobile", dataKey: "Mobile" },
      { title: "Address", dataKey: "Address" },
      { title: "Date", dataKey: "Date" },
      { title: "Slot", dataKey: "Slot" },
      { title: "Start Time", dataKey: "StartTime" }, // Adjusted to include Start Time
      { title: "End Time", dataKey: "EndTime" }, // Adjusted to include End Time
      { title: "Services", dataKey: "Services" }, // Adjusted to include Services
      { title: "Status (Appointment and Job Sheet status)", dataKey: "Status" },
      { title: "Rating", dataKey: "Rating" },
      { title: "Rider Status (Before Time, Delay, On Time)", dataKey: "RiderStatus" },
      { title: "Amount", dataKey: "Amount" }
    ];

    // Define the title for Today's data and Yesterday's data
    const sectionTitles = {
      today: "Today",
      yesterday: "Yesterday"
    };

    // Function to generate table rows
    const createRows = (data) => {
      if (data.length === 0) {
    // Create a dummy row with empty strings for each column
    return [{
      Name: '',
      Mobile: '',
      Address: '',
      Date: '',
      Slot: '',
      StartTime: '',
      EndTime: '',
      Services: '',
      Status: '',
      Rating: '',
      RiderStatus: '',
      Amount: ''
    }];
  }
       
     return data?.map(record => ({
      Name: record.Name,
      Mobile: record.Mobile,
      Address: record.Address,
      Date: record.Date,
      Slot: record.Slot,
      StartTime: record.StartTime?.length ? moment(record.StartTime[0]).utc().format("h:mm")  : "N/A", // You need to add Start Time in your JSON data
      EndTime: record.EndTime?.length ? moment(record.EndTime[0]).utc().format("h:mm")  : "N/A", // You need to add End Time in your JSON data
      Services: record.Services?.join(", "),
      Status: record.Status,
      Rating: record.Rating || "N/A",
      RiderStatus: record.RiderStatus?.join(", ") || "N/A",
      Amount: record.Amount
    }));
    } 

    // Generate table for Today
    doc.setFontSize(10);
    doc.text(sectionTitles.today, 14, 15);
    doc.autoTable({
      columns: tableColumns,
      body: createRows(jsonData.Today),
      startY: 20,
      theme: 'grid',
      tableWidth: 'wrap', // Use 'auto', 'wrap', or a number
      styles: {
        fontSize: 7,
        cellPadding: 1,
      },
      // Use this to define specific column widths
      columnStyles : columnStyles,
      headStyles : headStyles
    });

    // Generate table for Yesterday, calculate starting position based on the previous table
    const finalY = doc.previousAutoTable.finalY || 20;
    doc.text(sectionTitles.yesterday, 14, finalY + 15);
    doc.autoTable({
      columns: tableColumns,
      body: createRows(jsonData.Yesterday),
      startY: finalY + 20,
      theme: 'grid',
      tableWidth: 'wrap',
      styles: {
        fontSize: 7,
        cellPadding: 1,
      },
      headStyles : headStyles,
      columnStyles : columnStyles
    });


    // Save the PDF
    doc.save(`${riderName}.pdf`);
  };

