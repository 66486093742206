import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg"
import Toggle from "../../shared/components/toggle.component";
import useSelectModal from '../../utils/hooks/useSelectModal';
import useToaster from "../../utils/hooks/useToaster";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../shared/components/Button.component";
import TableComponent from "../../shared/components/Table.component";
import { useAddShiftMutation, useLazyGetAllShiftsQuery, useUpdateShiftMutation } from "../../utils/Services/Apis/shiftMasterApi";

const schema = Yup.object()
    .shape({
        shiftCategory: Yup.string().trim().max(20, 'Shift Category should not exceed 20 characters').required("Shift Category is required"),
    })

export default function ShiftCategory() {
    const { openModal } = useSelectModal();
    const [getAllShifts, { data: shiftList , status: shiftListStatus}] = useLazyGetAllShiftsQuery();
    const [updateShift]=useUpdateShiftMutation();
    const [addShift,{status:addShiftStatus}]=useAddShiftMutation();
    const { setToast } = useToaster();

    useEffect(() => {
        getAllShifts();
    }, [])

    const handleAddshiftCategory = async (values, { resetForm }) => {
        const { data, error } = await addShift({
            "name": values.shiftCategory,
        })
        if (data) {
            setToast({ type: "success", msg: "Shift Category added successfully" })
            resetForm();
            getAllShifts();
        }
        if (error) {
            resetForm();
            setToast({ type: "error", msg: error?.data?.message });
        } 

    };

    const openEditModal = (shiftCategory) => {
        const status = "shift-category"
        openModal("EditMasterCategoryModal", { status, data: shiftCategory, refetchData: getAllShifts });
    };

    const openDeleteModal = (shiftCategory) => {
        const status = "shift-category"
        openModal("DeleteMasterCategoryModal", {status, data: shiftCategory, refetchData: getAllShifts })
    }

    const handleActionToggle = (shiftData) => {
        updateShift({
            data: {
                isActive: !shiftData.isActive,
            },
            id: shiftData._id,
        })
            .then((data) => {
                !shiftData.isActive
                    ? setToast({ type: "success", msg: "Shift Category is Enabled" })
                    : setToast({ type: "success", msg: "Shift Category Disabled" });
            })
            .catch((err) => {
                setToast({ type: "error", msg: err.data.message });
            });
    }

    const columns = [
        {
            header: "List of Shift Category",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3">
                        <span className='font-inter-medium'>{original?.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3">
                        <Toggle
                            defaultChecked={original?.isActive}
                            handleOnToggle={() => handleActionToggle(original)}
                        />
                        <img
                            src={EditIcon}
                            alt="edit"
                            className={`h-5 w-5 cursor-pointer`}
                            onClick={() => openEditModal(original)}
                        />
                        <img
                            src={DeleteIcon}
                            alt="delete"
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => openDeleteModal(original)}
                        />
                    </span>
                );
            },
        },
    ];

    return (
        <Formik
            validationSchema={schema}
            initialValues={{
                shiftCategory: "",
            }}
            onSubmit={handleAddshiftCategory}
        >
            {({ values, setFieldValue, touched, errors }) => (
                <Form action="#" method="POST">
                    <div>
                        <div className="flex items-end  pl-8">
                            <div className="mr-4">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Add Shift Category
                                </label>
                                <Field
                                    name="shiftCategory"
                                    type="text"
                                    className="block w-[27.5vw] rounded-md border border-gray-300 px-3 2xl:py-2 py-1 placeholder-gray-400 focus:border-gray-300 focus:outline-none text-sm h-[3rem]  focus:ring-transparent "
                                    placeholder="Enter Shift Category to add in master data "
                                    onKeyPress={(event) => {
                                        if (/[^a-zA-Z ]/.test(event.key)) {
                                            return event.preventDefault();
                                        }
                                    }}
                                />
                                {touched.shiftCategory && errors.shiftCategory ? (
                                    <div className="text-red-600 text-sm">{errors.shiftCategory}</div>
                                ) : (
                                    <div className="invisible h-5"></div>
                                )}
                            </div>
                            <div className='pl-6 pt-6 w-[100%]'>
                                <Button
                                    className="2xl:text-base text-sm text-white cursor-pointer reb-btn-shadow 2xl:px-7 px-4 mb-5 w-[calc(142px+3.698vw)] rounded-[10px]"
                                    type="submit"
                                    isSubmitting={addShiftStatus === "pending"}
                                >
                                    Add Shift Category
                                </Button>
                            </div>
                        </div>
                        <div className="bg-white p-5 mx-8 mb-3 mt-8 pb-2 h-[40vh] overflow-hidden brand-details-section radius-type-bg-white relative">
                            <div className="w-full mb-10">
                                <TableComponent
                                    data={shiftList}
                                    columns={columns}
                                    customwidths={["w-[92.5%]", "w-[12.5%]"]}
                                    isActionColumnTable={true}
                                    customeClass="max-h-[48vh] 2xl:max-h-[50vh] radius-type-table"
                                    isLoading={shiftListStatus === "pending"}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}