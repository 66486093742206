import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const carApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			// BRAND api's
			getBrands: builder.query({
				query: () => ({
					url: endpoints.car.getCarBrands,
					method: "GET",
				}),
				providesTags: ["GetBrands"],
			}),
			deleteBrands: builder.mutation({
				query: (brandId) => ({
					url: endpoints.car.deleteCarBrand(brandId),
					method: "DELETE",
				}),
				invalidatesTags: ["GetBrands"],
			}),
			addCarBrand: builder.mutation({
				query: (body) => ({
					url: endpoints.car.addCarBrand,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["GetBrands"],
			}),
			updateCarBrand: builder.mutation({
				query: ({ brandId, data }) => ({
					url: endpoints.car.updateCarBrand(brandId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["GetBrands", "GetCars"],
			}),

			// CAR API's
			getCarModels: builder.query({
				query: (brandId) => ({
					url: endpoints.car.getCarModels(brandId),
					method: "GET",
				}),
				providesTags: ["GetCars"],
			}),
			addCarModel: builder.mutation({
				query: (body) => ({
					url: endpoints.car.addCarModel,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["GetCars"],
			}),
			deleteCarModel: builder.mutation({
				query: (brandId) => ({
					url: endpoints.car.deleteCarModel(brandId),
					method: "DELETE",
				}),
				invalidatesTags: ["GetCars"],
			}),
			updateCarModel: builder.mutation({
				query: (data) => ({
					url: endpoints.car.updateCarModel,
					body: data,
					method: "PUT",
				}),
			}),
			//
		};
	},
});

export const {
	useLazyGetBrandsQuery,
	useLazyGetCarModelsQuery,
	useUpdateCarBrandMutation,
	useDeleteBrandsMutation,
	useAddCarBrandMutation,
	useAddCarModelMutation,
	useDeleteCarModelMutation,
	useUpdateCarModelMutation,
} = carApi;
export { carApi };
