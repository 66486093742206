import moment from 'moment';
export const formatTime = (hours = 0, minutes = 0, seconds = 0) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const timeToSeconds = (time) => {
    return (time.hours * 3600) + (time.minutes * 60) + (time.seconds || 0);
}

export const secondsToTime = (seconds) => {
    return {
        hours: Math.floor(seconds / 3600),
        minutes: Math.floor((seconds % 3600) / 60),
        seconds: Math.round(seconds % 60) 
    };
}

export const timeDifference = (startTime, endTime) => {
    const start = moment.utc(startTime);
    const end =endTime ? moment.utc(endTime) : moment.utc().add(5, 'hours').add(30, 'minutes'); 
    const difference = end.diff(start, 'milliseconds');
    return secondsToTime(difference / 1000);
}

export const data= {
    "_id": "651401435a063b3fdc046dad",
    "paymentType": "COD",
    "payableAmount": 2500,
    "cityId": "6500a910e592ab316eecb84b",
    "areaId": "6500a92ee592ab316eecb867",
    "serviceType": "service",
    "serviceDate": "2023-09-27T01:00:00.000Z",
    "timeSlot": "1 - 2 PM",
    "status": "ongoing",
    "confirmationCall": true,
    "fuelType": "petrol",
    "transmissionType": "manual",
    "riderId": "65115bd02ab29775de7f6850",
    "customerName": "Shubham",
    "customerPhoneNumber": "+919096584618",
    "customerEmail": "shubhamjodave@mailinator.com",
    "addressLineOne": "swargate",
    "addressLineTwo": "",
    "landMark": "",
    "coOrdinates": {
        "latitude": 18.5018322,
        "longitude": 73.8635912
    },
    "pincode": 411013,
    "defaultAddress": false,
    "addressType": "home",
    "vehicleType": "suv",
    "vehicleNumber": "mh13cs9748",
    "vehicleModel": "x7",
    "vehicleModelImage": "https://fixigophoto.blob.core.windows.net/fixigophoto/2023-09-12_fd49bc45-51ce-4379-bbaf-d37c04cc010f.jpg",
    "vehicleBrand": "Marui Suzuki",
    "vehicleName": "Marui Suzuki-x7",
    "riderName": "shubhamj rider",
    "service": [
        {
            "_id": "651401455a063b3fdc046db6",
            "cityId": "6500a910e592ab316eecb84b",
            "areaId": "6500a92ee592ab316eecb867",
            "startTime" :"2023-10-04T01:00:28.747Z",
            "endTime" :"2023-10-04T06:20:28.747Z",
            "serviceName": "Test",
            "serviceTime": [
                {
                    "hours": 5,
                    "minutes": 5
                }
            ],
            "serviceType": "onetimeservice",
            "assets": [
                {
                    "assetName": "Tyre Polish Kit",
                    "quantity": 5,
                    "unit": "ml",
                    "_id": 3
                }
            ],
            "carType": [
                {
                    "type": "sedan",
                    "price": 3000,
                    "discountPrice": 100,
                    "discountType": "flat",
                    "isChecked": true,
                    "afterGSTPrice": 2900
                },
                {
                    "type": "suv",
                    "price": 2500,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": true,
                    "afterGSTPrice": 2500
                },
                {
                    "type": "hatchback",
                    "price": 1000,
                    "discountPrice": 200,
                    "discountType": "flat",
                    "isChecked": true,
                    "afterGSTPrice": 800
                },
                {
                    "type": "upcoming",
                    "price": 2000,
                    "discountPrice": 100,
                    "discountType": "percent",
                    "isChecked": true,
                    "afterGSTPrice": 0
                }
            ],
            "includeGST": true,
            "imageUrl": "https://fixigophoto.blob.core.windows.net/fixigophoto/2023-09-15_6d09acc3-5db6-470c-92de-200f05a62f95.jpg",
            "serviceDescription": "",
            "addOnService": false,
            "addOnServiceVerification": false,
            "isServiceStarted": false,
            "isServiceCompleted": false,
            "isDeleted": false,
            "creator": "64e72a4a0f925bc828c73ee0",
            "created": "2023-09-15T07:12:23.110Z",
            "__v": 0,
            "orderId": "651401435a063b3fdc046dad",
            "serviceMasterDataId": "6504212ac45a9a2e7a07f230"
        },
        {
            "_id": "651402d079e5f6c20a0ce913",
            "cityId": "64f3715c59fce29cb4982dba",
            "areaId": "64f6d2d4227c75f2713c45e6",
            "serviceName": "service name 1",
            "startTime" :"2023-10-04T06:00:28.747Z",
            "endTime" :"2023-10-04T07:00:28.747Z",
            "serviceTime": [
                {
                    "hours": 1,
                    "minutes": 2
                }
            ],
            "serviceType": "onetimeservice",
            "assets": [
                {
                    "assetName": "carwashingmachine",
                    "quantity": 1,
                    "unit": "item"
                }
            ],
            "carType": [
                {
                    "type": "sedan",
                    "price": 1000,
                    "discountPrice": 10,
                    "discountType": "percent",
                    "isChecked": true,
                    "afterGSTPrice": 990
                },
                {
                    "type": "suv",
                    "price": 400,
                    "discountPrice": 20,
                    "discountType": "percent",
                    "isChecked": true,
                    "afterGSTPrice": 352
                },
                {
                    "type": "hatchback",
                    "price": 200,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": true,
                    "afterGSTPrice": 220
                },
                {
                    "type": "upcoming",
                    "price": 77,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": true,
                    "afterGSTPrice": 84
                },
                {
                    "type": "test 1",
                    "price": 0,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": false,
                    "afterGSTPrice": 0
                },
                {
                    "type": "test 2",
                    "price": 0,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": false,
                    "afterGSTPrice": 0
                },
                {
                    "type": "test 3",
                    "price": 0,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": false,
                    "afterGSTPrice": 0
                }
            ],
            "includeGST": false,
            "gst": 10,
            "imageUrl": "https://fixigophoto.blob.core.windows.net/fixigophoto/2023-09-13_cd9bd5ea-ff3f-43d0-ac84-931f38871054.jpg",
            "serviceDescription": "service",
            "isServiceStarted": false,
            "isServiceCompleted": false,
            "beforeServiceImageUrls": [],
            "afterServiceImageUrls": [],
            "paymentStatus": "pending",
            "addOnService": true,
            "addOnServiceVerification": true,
            "isDeleted": false,
            "created": "2023-09-05T10:15:26.403Z",
            "__v": 0,
            "creator": "65115bd02ab29775de7f6850",
            "orderId": "651401435a063b3fdc046dad",
            "serviceMasterDataId": "64f7166f0336f94689635ca9",
            "paymentType": "cash"
        },
        {
            "_id": "651402d179e5f6c20a0ce915",
            "cityId": "64f59d44c07126b75a46edd3",
            "areaId": "64f59db5c07126b75a46edec",
            "startTime" :"2023-10-04T05:00:28.747Z",
            "endTime" :"2023-10-04T06:10:28.747Z",
            "serviceName": "sercice nfsdf",
            "serviceTime": [
                {
                    "hours": 1,
                    "minutes": 0
                }
            ],
            "serviceType": "subscription",
            "assets": [
                {
                    "assetName": "carwashingmachine",
                    "quantity": 1,
                    "unit": "item"
                }
            ],
            "carType": [
                {
                    "type": "sedan",
                    "price": 100,
                    "discountPrice": 30,
                    "discountType": "flat",
                    "isChecked": true,
                    "afterGSTPrice": 70
                },
                {
                    "type": "suv",
                    "price": 0,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": false,
                    "afterGSTPrice": 0
                },
                {
                    "type": "hatchback",
                    "price": 0,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": false,
                    "afterGSTPrice": 0
                },
                {
                    "type": "upcoming",
                    "price": 0,
                    "discountPrice": 0,
                    "discountType": "flat",
                    "isChecked": false,
                    "afterGSTPrice": 0
                }
            ],
            "includeGST": true,
            "gst": 10,
            "imageUrl": "https://fixigophoto.blob.core.windows.net/fixigophoto/2023-09-05_d7863134-5a48-416c-8ba8-303fc1d67b58.jpg",
            "serviceDescription": "serive name",
            "isServiceStarted": false,
            "isServiceCompleted": false,
            "beforeServiceImageUrls": [],
            "afterServiceImageUrls": [],
            "paymentStatus": "pending",
            "addOnService": true,
            "addOnServiceVerification": true,
            "isDeleted": false,
            "created": "2023-09-05T10:09:06.090Z",
            "__v": 0,
            "creator": "65115bd02ab29775de7f6850",
            "orderId": "651401435a063b3fdc046dad",
            "serviceMasterDataId": "64f716f50336f94689635e4c",
            "paymentType": "cash"
        }
    ],
    "areaName": "Swargat",
    "cityName": "Pune",
    "paymentStatus": "pending"
}