import React, { useEffect } from 'react'
import CardService from './CardService'
import { useLazyGetOrderDetailsQuery } from '../../utils/Store';

function AppointmentSuccessModal({data}) {

    const [ getOrderData, {data: orderDetails} ] = useLazyGetOrderDetailsQuery();

    useEffect(() => {
        if(data?.orderId)
            handleGetOrderData(data?.orderId);

    }, [data?.orderId])
    
    const handleGetOrderData = (orderId) => {
        getOrderData(orderId)
    }
    if(!orderDetails) return
    return (
        <div className='py-5 px-5 shadow rounded-none w-[70rem]'>
            <CardService type="success" orderDetails={orderDetails} />
        </div>
    )
}

export default AppointmentSuccessModal