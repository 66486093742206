import React from 'react'
import { FcCancel } from 'react-icons/fc';
import { BsCheck2 } from 'react-icons/bs'
import Button from '../../shared/components/Button.component';
import useSelectModal from '../../utils/hooks/useSelectModal';

function CancelModal({ data }) {
	const { closeModal } = useSelectModal();

    return (
        <div className="2xl:w-[35vw] w-[40vw] p-6 px-16 bg-[#FFFFFF] rounded-xl pb-8">

            {/* For Cancel Modal UI */}

            <div className='flex items-center justify-center'>
                    <div className='2xl:h-20 2xl:w-20 h-24 w-24 bg-[#FFEBEB] rounded-full flex items-center justify-center'>
                        <FcCancel size={50} />
                    </div>
                </div>
                <div className=" pt-4 text-center">
                    <h1 className="text-[#23262F] font-inter-medium text-2xl ">This Appointment has been cancelled successfully!</h1>
                </div>

                {/* For Success Modal UI */}
                
            {/* <div className='flex items-center justify-center'>
                <div className='h-20 w-20 bg-[#C3FFD1] rounded-full flex items-center justify-center'>
                    <BsCheck2 size={50} color='#01B433' />
                </div>
            </div>
            <div className=" pt-4 text-center">
                <h1 className="text-[#23262F] font-inter-medium text-2xl ">Service Charges ₹3000</h1>
            </div>
            <div className=" pt-4 text-center">
                <h1 className="text-[#8E8E8E] font-inter-regular text-2xl ">Amount has been paid online</h1>
            </div> */}

            <div className="flex justify-center pt-8 pb-1">
                <Button
                    className="w-[12.5vw] h-[50px] text-white cursor-pointer bottom-btn-ht text-base font-inter-bold"
                    onClick={closeModal}
                >
                    Close
                </Button>
            </div>
        </div>
    )
}

export default CancelModal
