import React, { useState } from 'react';
import ExternalWashIcon from "../../../assets/images/cars/car-service-external.png";
import { Down, History, Up } from '@icon-park/react';
import TableComponent from '../../../shared/components/Table.component';

function SubscriptionsCard({ }) {

    const [isTableVisible, setIsTableVisible] = useState(false);

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    }

    const columns = [
        {
            header: "Date",
            accessorKey: "name",
        },
        {
            header: "Service",
        },
        {
            header: "Rider Name",
        },
        {
            header: "Service Time",
        }
    ];

    const ServiceCard = () => {
        return (
            <div className='border border-[#CACACA] rounded-[8px] p-2 flex items-center mr-4'>
                <div className='bg-[#F1F1F1] p-2 rounded-full w-[36px] h-[36px] mr-4 cm-add-on-icon'>
                    <img className='w-full h-full' src={ExternalWashIcon} alt="" />
                </div>
                <p className='text-[#474747] 3xl:text-base text-sm'>External Car Wash : <span className='3xl:text-base text-sm'>4(3 available)</span></p>
            </div>
        )
    }

    return (
        <div className=''>
            <div className='pr-3 mb-[2vw]'>
                <div className="w-full h-auto pb-1 mb-3 bg-white border border-[#DDD] rounded-[24px] overflow-hidden">
                    <div className="bg-white rounded-t-lg drop-shadow-md px-5 py-4">
                        <div className="flex items-center justify-between">
                            <div className="py-1.5 px-3 bg-[#F1F1F1] rounded-lg w-fit space-x-2">
                                <h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
                                    Monthly Premium Subscription
                                </h1>
                            </div>
                            <div className='flex'>
                                <div className="py-1.5 px-3 bg-[#F1F1F1] rounded-lg w-fit space-x-2">
                                    <h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
                                        Purchase Date: 10 Aug 2023
                                    </h1>
                                </div>
                                <div className="py-1.5 px-3 bg-[#F1F1F1] rounded-lg w-fit space-x-2 ml-4">
                                    <h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
                                        Expiry Date:  15 Oct 2023
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='px-5 py-4'>
                        <div className='flex '>
                            <ServiceCard />
                            <ServiceCard />
                            <ServiceCard />
                            <ServiceCard />
                        </div>

                        <div className='w-full h-[1px] bg-[#DEDEDE] my-4'></div>

                        <div className="flex items-center space-x-14 ml-auto w-fit mr-9">
                            <div className="">
                                <p className="text-black font-inter-medium text-sm 2xl:text-base">Total Price</p>
                            </div>
                            <div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
                                <p className="text-gray text-sm 2xl:text-base">₹</p>
                                <p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
                                    {/* {orderDetails?.payableAmount} */}
                                    5000
                                </p>
                            </div>
                        </div>

                        <div className='w-full h-[1px] bg-[#DEDEDE] my-4'></div>

                        <div className='flex items-center space-x-4'>
                            <History />
                            <p className='font-inter-medium text-base'>History of Used Services from Subscription</p>
                            {isTableVisible ? (
                                <Up className='cursor-pointer' onClick={toggleTableVisibility} />
                            ) : (
                                <Down className='cursor-pointer' onClick={toggleTableVisibility} />
                            )}
                        </div>
                        {isTableVisible && (
                            <TableComponent
                                columns={columns}
                                customwidths={["w-[20%]", "w-[20%]", "w-[40%]", "w-[20%]"]}
                            // isLoading = {citiesListStatus === "pending"}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionsCard