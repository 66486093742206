import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";
import { Field, useFormikContext } from "formik";

let containerStyle = {
	width: "100%",
	height: window.innerWidth < 1679 ? "200px" : "400px",
};

const MapSearchLocation = ({ setFieldValue, selectedAddress, height }) => {
	const { setFieldTouched, values } = useFormikContext();
	if(height) containerStyle = {width: "100%", height}
	const [center, setCenter] = useState({ lat: values.coOrdinates?.latitude || 21.556483, lng: values.coOrdinates?.longitude || 78.666478 }); 
	const [zoom, setZoom] = useState(5);
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [markerPosition, setMarkerPosition] = useState({ lat: values.coOrdinates?.latitude, lng: values.coOrdinates?.longitude });
	const searchedPlaceRef = useRef(values.searchedPlace)

	useEffect(()=>{
		if(!values.searchedPlace){
			setFieldValue("coOrdinates", "");
			setMarkerPosition();
		}
	},[values.searchedPlace])

	useEffect(() => {
		if (selectedAddress) {
			setFieldValue("coOrdinates",  { latitude: selectedAddress?.coOrdinates?.latitude, longitude: selectedAddress?.coOrdinates?.longitude });
			setFieldValue("searchedPlace", selectedAddress?.coOrdinates?.searchedPlace);
			const markerPosition={lat: selectedAddress?.coOrdinates?.latitude, lng: selectedAddress?.coOrdinates?.longitude}
			setMarkerPosition(markerPosition);
			setCenter(markerPosition);
			searchedPlaceRef.current = selectedAddress?.coOrdinates?.searchedPlace
			setZoom(14);
		}
	}, [selectedAddress]);

	const mapRef = useRef(null);
	const autocompleteRef = useRef(null);

	const onLoad = (map) => {
		mapRef.current = map;
		const idleListener = map.addListener("idle", () => {
			setIsMapLoaded(true);
		});
		return () => {
			window.google.maps.event.removeListener(idleListener);
		};
	};

	const onUnmount = () => {
		mapRef.current = null;
	};

	const handlePlaceChanged = () => {
		if (autocompleteRef.current) {
			const place = autocompleteRef.current.getPlace();
			const location = place.geometry.location;
			const addressToDisplay = place.formatted_address.includes(place.name) ? place.formatted_address : place.name+ ", " + place.formatted_address;
      searchedPlaceRef.current = addressToDisplay
			setFieldValue("searchedPlace", addressToDisplay);
			setCenter({ lat: location.lat(), lng: location.lng() });
			setMarkerPosition({ lat: location.lat(), lng: location.lng() });
			setFieldValue("coOrdinates", { latitude: location.lat(), longitude: location.lng()});
			setZoom(14);
		}
	};

	const handleMarkerDragEnd = (event) => {
		setMarkerPosition({
			lat: event.latLng.lat(),
			lng: event.latLng.lng(),
		});
		setFieldValue("coOrdinates", { latitude: event.latLng.lat(), longitude: event.latLng.lng()});
	};

	 const handleBlur = () => {
    if (searchedPlaceRef.current !== values.searchedPlace) {
      setFieldValue("searchedPlace", "");
      setFieldTouched("searchedPlace", false);
    }
  };

	return (
		<div>
			<Autocomplete
				onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
				onPlaceChanged={handlePlaceChanged}
			>
				<Field
				  onBlur={handleBlur}
				  name="searchedPlace"
					type="text"
					placeholder="Search Location"
					className="block w-full mb-4 mt-1 appearance-none rounded-md border border-gray-300 px-3 2xl:py-3 py-1 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm h-[2.3rem]"
				/>
			</Autocomplete>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={zoom}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{ scrollwheel: false }}
				version="beta"
				className="h-[10vw]"
			>
				{markerPosition && isMapLoaded && center && (
					<Marker
						position={markerPosition}
						draggable={true}
						onDragEnd={(event) => handleMarkerDragEnd(event)}
					/>
				)}
			</GoogleMap>
		</div>
	);
};


export default MapSearchLocation;