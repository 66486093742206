import ExcelJS from 'exceljs';
import moment from 'moment';

  const createExcelRowsFromJson = async (workbook, data, title) => {
    const worksheet = workbook.getWorksheet('Schedule');

    // Add a title row in bold
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { bold: true };
    titleRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add header row in bold
    const headerRow = worksheet.addRow([
      "Name", "Mobile", "Address", "Date", "Slot",
      "Start Time", "End Time", "Services", "Status (Appointment and Job Sheet status)",
      "Rating", "RiderStatus (Before Time, Delay, On Time)", "Amount"
    ]);
    // headerRow.eachCell((cell) => {
    //   cell.font = { bold: true };
    // });

    // Add data rows
    data.forEach(item => {
      worksheet.addRow([
        item.Name,
        item.Mobile,
        item.Address,
        item.Date,
        item.Slot,
        item.StartTime?.length ? moment(item.StartTime[0]).utc().format("h:mm")  : "N/A",
        item.EndTime?.length ? moment(item.EndTime[0]).utc().format("h:mm")  : "N/A",
        item.Services?.join(", "),
        item.Status,
        item.Rating,
        item.RiderStatus?.join(", ") || "N/A",
        item.Amount
      ]);
    });

    // Add an empty row after the section
    worksheet.addRow([]);
  };

  export const generateExcel = async (jsonData, riderName="records") => {
    // Create a new workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('Schedule');

    // Create rows for Today and Yesterday
    await createExcelRowsFromJson(workbook, jsonData.Today, "Today");
    await createExcelRowsFromJson(workbook, jsonData.Yesterday, "Yesterday");

    // Write the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer and create a link to download
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${riderName}.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
  };


