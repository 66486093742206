import React, { useEffect } from 'react'
import TableComponent from '../../../shared/components/Table.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import { useLazyGetChildFranchiseByIdQuery } from '../../../utils/Store';
import moment from 'moment';
import { CloseCircle } from 'iconsax-react';

function FranchiseInfoModal({ data }) {

    const [getAllChildFranchise, { data: getAllChildFranchiseList }] = useLazyGetChildFranchiseByIdQuery();
    const { closeModal } = useSelectModal();

    useEffect(() => {
        getAllChildFranchise(data?.id);
    }, [])

    const subFranchiseColumn = [
        {
            header: "Franchise Name",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="truncate font-inter-medium">{original?.name}
                    </p>
                )
            }
        },
        {
            header: "City",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
                        <p className='whitespace-nowrap'>{original?.city.join(', ')}</p>
                    </p>
                )
            }
        },
        {
            header: "Area",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
                        <p className='whitespace-nowrap'>{original?.area.join(', ')}</p>
                    </p>
                )
            }
        },
        {
            header: "Start Date",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="font-inter-medium">{moment(original?.startDate).format('DD-MM-YYYY')}</p>
                )
            }
        },
        {
            header: "No. of Service Providers",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <p className="font-inter-medium">{original?.ServiceProvider}</p>
                )
            }
        },
        {
            header: "Expiring in Days",
            cell: ({ row, getValue }) => {
                const { original } = row;
                const endDate = moment(original?.endDate);
                const today = moment(new Date());
                const expirationDays = endDate.diff(today, 'days');
                const expired = expirationDays < 0 ? "Expired" : expirationDays;
                return (
                    <span className="truncate font-inter-medium">
                        <span className={`rounded-full px-4 py-0.5 ${expired === "Expired" || expirationDays < 30 ? "bg-[#FB0C0C] text-[#FFF]" : ""}`}>
                            {expired}
                        </span>
                    </span>
                );
            },
        },
    ]

    return (
        <div className="w-[70vw]">
            <div className="flex flex-row rounded-[20px] pl-4 pr-20 text-sm w-[100%]">
                <TableComponent
                    data={getAllChildFranchiseList}
                    columns={subFranchiseColumn}
                    customwidths={["w-[22%]", "w-[18%]", "w-[11%]", "w-[12%]", "w-[22%]", "w-[15%]"]}
                />
            </div>
        </div>
    )
}

export default FranchiseInfoModal