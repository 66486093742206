import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import Button from "../../shared/components/Button.component";
import Delete from "../../assets/icons/delete.svg";
import Edit from "../../assets/icons/edit.svg";
import { TickCircle, Car, InfoCircle } from "iconsax-react";
import { useNavigate, useParams } from "react-router-dom";
import {
	useDeleteSingleServiceMutation,
	useLazyGetServiceDetailsQuery,
} from "../../utils/Services/Apis/serviceApi";
import useSelectModal from "../../utils/hooks/useSelectModal";
import useToaster from "../../utils/hooks/useToaster";
import { calculatePrice } from "../../utils/helper/priceCalculator";

function ServiceInfo() {
	const navigate = useNavigate();
	const { serviceId } = useParams();
	const { openModal } = useSelectModal();
	const { setToast } = useToaster();
	const [getServiceInfo, { data: serviceDetails }] = useLazyGetServiceDetailsQuery();
	const [deleteService] = useDeleteSingleServiceMutation();

	useEffect(() => {
		if (serviceId) {
			handleGetServiceData();
		}
	}, [serviceId]);

	const handleGetServiceData = async () => {
		const { error } = await getServiceInfo(serviceId);
		if (error) setToast({ type: "error", msg: error.data.message });
	};

	const handleDelete = () => {
		openModal("DeleteServiceModal", {
			...serviceDetails,
			hideCloseArrow: true,
			type: "Service",
			isNavigateBack: true,
		});
	};

	if (!serviceDetails) return;
	return (
		<div className="h-full px-8 pb-4 pt-8 w-full bg-[#F5F5F5]">
			<div className="w-full">
				<div className="flex justify-between">
					<div className="flex items-center space-x-4">
						<FiArrowLeft
							className="cursor-pointer back-arrow"
							size={22}
							onClick={() => navigate(-1)}
						/>
						<div className="bg-[#F1F1F1] rounded-2xl p-1 h-7 w-7 items-center">
							<img src={serviceDetails?.imageUrl} className="h-5 w-5" />
						</div>
						<h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
							{serviceDetails?.serviceName}
						</h1>
					</div>
					<div className="flex items-center space-x-4">
						<Button
							className="text-white bg-[#000] cursor-pointer rounded-[10px] btn-radius"
							onClick={() => openModal("ServiceModal", serviceDetails)}
						>
							Edit
							<img src={Edit} color="white" className="pl-2.5 cursor-pointer delete-edit-icon-ht" />
						</Button>
						<Button className="text-white" onClick={() => handleDelete()}>
							Delete
							<img src={Delete} color="white" className="pl-2.5 delete-edit-icon-ht" />
						</Button>
					</div>
				</div>
				<hr className="border w-full mt-8 mb-3" />
				<div className="flex flex-row">
					<div className="mt-5 p-3 bg-[#FFFFFF] card-sm-shadow rounded-xl w-[30%] mr-4">
						<div className="">
							<h1 className="text-base font-inter-bold">Service Details</h1>
						</div>
						<hr className="my-3 border border-gray-100" />

						<div className="flex ">
							<div className="w-full">
								<div className=" py-3 flex flex-start space-x-10">
									<h1 className="text-base font-inter-medium text-[#000000]">Service Type:</h1>
									<h1 className="text-base font-normal text-[#474747]">
										{serviceDetails?.serviceType === "onetimeservice"
											? "One Time Service"
											: "Subscription"}
									</h1>
								</div>
								<div className=" py-3 flex flex-start space-x-6">
									<h1 className="text-base font-inter-medium text-[#000000]">Estimated Time:</h1>
									<h1 className="text-base font-normal text-[#474747]">
										{serviceDetails?.serviceTime[0]?.hours != 0 &&
											serviceDetails?.serviceTime[0]?.hours + " Hours"}{" "}
										{serviceDetails?.serviceTime[0]?.minutes != 0 &&
											serviceDetails?.serviceTime[0]?.minutes + " Min"}
									</h1>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-5 p-3 bg-[#FFFFFF] card-sm-shadow rounded-xl w-[30%] mr-4">
						<div className="">
							<h1 className="text-base font-inter-bold">Assets Details</h1>
						</div>
						<hr className="my-3 border border-gray-100" />
						<div className="flex justify-between gap-2 my-3">
							<div className="w-[60%]">
								<h1 className="text-base font-medium">Assets</h1>
							</div>
							<div className="w-[40%]">
								<h1 className="text-base font-medium">Qty.</h1>
							</div>
						</div>
						<div className="flex ">
							<div className="w-full">
								{serviceDetails?.assets.map((asset, index) => (
									<div key={index} className="flex justify-between">
										<div className="w-[60%]">
											<h1 className="text-base font-inter-medium text-[#000000] py-1">
												{asset.assetName}
											</h1>
										</div>
										<div className="flex space-x-6 py-1 pl-1 w-[40%]">
											<h1 className="text-base font-medium">x{asset.quantity}</h1>
											<h1 className="bg-[#E8E8E8] rounded-md px-3 text-base font-normal text-[#474747]">
												{asset.unit}
											</h1>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="mt-5 p-3 bg-[#FFFFFF] card-sm-shadow rounded-xl w-[40%]">
						<div className="">
							<h1 className="text-base font-inter-bold">City and Area</h1>
						</div>
						<hr className="my-3 border border-gray-100" />
						<div className="flex ">
							<div className="w-full">
								<div className=" py-3 flex flex-start">
									<div className="w-[30%]">
										<h1 className="text-base font-inter-medium text-[#000000]">City:</h1>
									</div>
									<div className="flex space-x-3 w-[70%]">
										<h1 className="text-base font-normal text-[#474747] pl-2">
											{serviceDetails?.cities.map((city) => city.name)}
										</h1>
									</div>
								</div>
								<div className=" py-3 flex flex-start">
									<div className="w-[30%]">
										<h1 className="text-base font-inter-medium text-[#000000]">Areas:</h1>
									</div>
									<div className="flex flex-wrap items-center gap-2 w-[70%]">
										{serviceDetails?.areas?.map((area) => {
											return (
												<h1 className="text-sm font-medium text-[#000000] border rounded-md py-1 px-1.5">
													{area?.name?.split(",")[0]}
												</h1>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row">
					<div className="mt-5 p-3 bg-[#FFFFFF] card-sm-shadow rounded-xl w-[29.75%] mr-4">
						<div className="">
							<h1 className="text-base font-inter-bold">Service Description</h1>
						</div>
						<hr className="my-3 border border-gray-100" />

						<div className="flex ">
							<div className="w-full">
								<div className=" py-2 flex flex-start items-center space-x-2">
									{serviceDetails?.serviceDescription.length > 0 && (
										<>
											<div className="h-0.2 w-0.2 bg-[#757D8A] p-1 rounded-xl"></div>
											<h1 className="text-base font-medium text-[#474747]">
												{serviceDetails?.serviceDescription}
											</h1>
										</>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="mt-5 p-3 bg-[#FFFFFF] card-sm-shadow rounded-xl w-[70.25%]">
						<div className="flex items-center justify-between">
							<h1 className="text-base font-inter-bold text-[#000000]">Car Type</h1>
							<div className="pr-6">
								<h1 className="text-base font-inter-bold text-[#000000">
									GST added {serviceDetails?.gst ? `${serviceDetails?.gst}%` : "0%"}
								</h1>
							</div>
						</div>
						<hr className="my-3 border border-gray-100" />
						<div className="">
							<table className="w-full border-collapse ">
								<thead className="">
									<tr className="grid grid-cols-5 justify-around text-justify mb-4">
										<th className="text-base font-semibold text-[#6B6B6B]">Type</th>
										<th className="2xl:pl-10 text-base font-semibold text-[#6B6B6B]">Price</th>
										<th className="2xl:pl-10 text-base font-semibold text-[#6B6B6B]">
											Discount Type
										</th>
										<th className="2xl:pl-10 text-base font-semibold text-[#6B6B6B]">
											Discount Amount
										</th>
										<th className="2xl:pl-10 ml-10 text-base font-semibold text-[#6B6B6B]">
											Final Price
										</th>
									</tr>
								</thead>
								<tbody>
									<div className="pb-3">
										{serviceDetails?.carType.map((car, index, array) => (
											<div className="">
												<tr
													key={index}
													className="grid grid-cols-5 justify-around text-justify pt-2 td-border-none"
												>
													<td
														className={`text-base font-inter-medium text-[#000000] ${
															car.type === "suv" ? "uppercase" : "capitalize"
														}`}
													>
														{car.type}
													</td>
													<td className="2xl:pl-10 text-base font-inter-medium text-[#000000]">
														₹ {car.price}
													</td>
													<td className="2xl:pl-10 text-base font-inter-medium text-[#000000]">
														{car.discountType === "percent" ? "Discount Percent" : "Flat Discount"}
													</td>
													<td className="2xl:pl-10 text-base font-inter-medium text-[#000000]">
														{car.discountType === "percent"
															? car.discountPrice
															: "₹ " + car.discountPrice}
													</td>
													<td className="2xl:pl-10 ml-10 text-base font-inter-medium text-[#000000]">
														₹ {car.afterGSTPrice ? car.afterGSTPrice : 0}
													</td>
												</tr>
												<hr
													className={`my-2 border border-gray-100 ${
														index === array.length - 1 && "hidden"
													}`}
												/>
											</div>
										))}
									</div>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceInfo;
