import React from "react";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import Button from "../../../shared/components/Button.component";
import CheckIcon from "../../../assets/icons/check.svg";


function FranchiseCreatedModal({ data }) {

	const { closeModal } = useSelectModal();

	return (
		<div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
			<div className="flex items-center justify-center">
				<div className=" bg-[#C3FFD1] h-[4.5rem] w-[4.5rem] rounded-full flex justify-center items-center ">
					<img src={CheckIcon} />
				</div>
			</div>
			<div className="flex font-inter-medium justify-center pt-4 text-xl text-center">
				Franchise Created
			</div>
			<div className="pb-7 pt-2 text-center text-xl">
				<span className="text-[#87898E] text-center">“{data?.name}’’ franchise has been <br/>successfully created</span>
			</div>
			<div className="flex items-center justify-center ">
				<Button
					className="w-[47.5%] text-[#fff] cursor-pointer font-inter-medium text-base"
					onClick={closeModal}
				>
					Close
				</Button>
			</div>
		</div>
	);
}

export default FranchiseCreatedModal;

