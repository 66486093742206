import { useDispatch, useSelector } from "react-redux";
import { setRole, distroyRole } from "../Store";

export default function useRole () {
    const role = useSelector((state) => state.role);

    const dispatch = useDispatch();

    const roleSet = (data) => {
		dispatch(setRole(data));
	};

	const roleDistroy = () => {
		dispatch(distroyRole());
	};

    return { roleSet, roleDistroy };
}