import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Button from "../../shared/components/Button.component";
import DeleteIcon from "../../assets/icons/Delete.png";
import EditIcon from "../../assets/icons/Edit.png";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { original } from "@reduxjs/toolkit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	useDeleteBrandsMutation,
	useDeleteCarModelMutation,
	useLazyGetCarModelsQuery,
} from "../../utils/Services/Apis/carApi";
import { useEffect } from "react";
import BackButton from "../../shared/components/BackButton.component";
import useToaster from "../../utils/hooks/useToaster";

export default function CarListPage() {
	const { closeModal, openModal } = useSelectModal();
	const navigate = useNavigate();
	const { setToast } = useToaster();

	const { brandId } = useParams();
	const {
		state: { brandData },
	} = useLocation();
	const [getCarModels, { data: allCars }] = useLazyGetCarModelsQuery();
	const [deleteBrand] = useDeleteBrandsMutation();
	const [deleteCarModel] = useDeleteCarModelMutation();

	useEffect(() => {
		getCarModels(brandId);
	}, []);

	const handleDeleteBrand = async () => {
		openModal("DeleteBrandModal", { ...brandData, hideCloseArrow: true, navigateBack: true });
	};

	const handleDeleteCarVariant = (carData) => {
		openModal("DeleteCarModal", { ...carData, hideCloseArrow: true, navigateBack: true });
	};

	const handleCarEdit = (carData) => {
		navigate(`/edit-car/${carData.uniqueId}`, {
			state: { carData: carData, brandData: brandData },
		});
	};
	
	const handleEdit = () => {
		openModal("AddBrandModal", {_id:brandData?._id, name:allCars?.carName , logo:allCars?.imageUrl});
	}

	return (
		<div className=" bg-[#F5F5F5] h-full pt-8">
			<div className="space-y-3 px-8 pb-4">
				<div className="flex justify-between">
					<div className="flex gap-3 items-center">
						<BackButton />
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">{allCars?.carName}</h3>
					</div>
					<Button
						className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm w-[12%]"
						onClick={() => navigate(`/add-new-car`, { state: { brandData } })}
					>
						Add New Car
					</Button>
				</div>
			</div>
			<form className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-10 px-8 pt-3 pb-4">
				<section
					aria-labelledby="summary-heading"
					className="px-4 pt-10 pb-7 col-span-4 space-y-4 relative car-list-page bg-[#FFFFFF]"
				>
					<div className="flex justify-center px-8 h-[12.5vw]">
						<img
							src={allCars?.imageUrl}
							alt={""}
							className="h-full w-full rounded-md mt-4 object-center object-contain"
						/>
					</div>
					<h1 id="summary-heading" className="text-3xl font-bold text-[#000000] text-center pt-4 font-futura-medium">
						{allCars?.carName}
					</h1>
					<div className="flex items-center justify-center pb-2">
						<div className="text-base text-center font-futura-medium py-1.5 px-2 bg-[#D9D9D9] rounded-2xl w-60 text-[#000000] ">
							Number Of Cars Available: {allCars?.noOfModels}
						</div>
					</div>

					<div className="absolute right-4 top-0">
						<span className="flex gap-2">
							<div className="bg-[#F5F5F5] rounded-full h-8 w-8 flex items-center justify-center cursor-pointer">
								<img
									src={EditIcon}
									alt=""
									onClick={() => handleEdit(original)}
									className="h-5 w-5 cursor-pointer"
								/>
							</div>
							<div className="bg-[#F5F5F5] rounded-full h-8 w-8 flex items-center justify-center cursor-pointer">
								<img
									src={DeleteIcon}
									alt=""
									onClick={() => handleDeleteBrand()}
									className="h-5 w-5"
								/>
							</div>
						</span>
					</div>
				</section>
				{allCars?.result?.length > 0 && (
					<section aria-labelledby="cart-heading" className="lg:col-span-8">
						<div className="pr-2 bg-[#FFFFFF] shadow rounded-xl px-3 pt-6 car-list-page h-[75vh] overflow-hidden">
						<ul className="max-h-[70vh] overflow-y-auto ">
							{allCars?.result.map((product, index) => (
								<li
									key={index}
									className="flex mx-3 mb-4 rounded-lg p-4 border border-gray-200 bg-[#F5F5F5] "
								>
									<div className="w-[300px] h-[9.5vw] rounded-lg bg-[#FFF] p-4">
										<img
											src={product.imageUrl}
											alt={product.imageUrl}
											className="w-full h-full object-contain"
										/>
									</div>

									<div className=" w-[600px] ml-4 flex flex-col justify-between sm:ml-6">
										<div className="">
											<div className="flex justify-between mb-4">
												<div className="flex space-x-4">
													<p className="text-2xl font-inter-medium leading-8 car-brand-size">{product.name}</p>
													<p className="text-lg font-inter-regular ml-2 leading-8 car-type-size">{product.carType}</p>
												</div>
												<div className="">
													<span className="flex gap-2">
														<div className="bg-[#FFFFFF] rounded-full h-8 w-8 flex items-center justify-center cursor-pointer">
															<img
																src={EditIcon}
																alt=""
																// onClick={() => handleEdit(original)}
																className="h-5 w-5"
																onClick={() => handleCarEdit(product)}
															/>
														</div>
														<div className="bg-[#FFFFFF] rounded-full h-8 w-8 flex items-center justify-center cursor-pointer">
															<img
																src={DeleteIcon}
																alt=""
																onClick={() => handleDeleteCarVariant(product)}
																className="h-5 w-5"
															/>
														</div>
													</span>
												</div>
											</div>
											<div className="bg-[#FFFFFF] rounded-xl border ">
												<div className="grid grid-cols-3 gap-2 py-2 px-4">
													<div className="text-[#000000] font-inter-medium text-sm">Variant Name</div>
													<div className="text-[#000000] font-inter-medium text-sm">Fuel Type</div>
													<div className="text-[#000000] font-inter-medium text-sm">Transmission Type</div>
												</div>
												<hr className="bg-[#A0A0A0] opacity-20 w-full h-0.5" />
												<div className="h-[5vw] overflow-y-scroll car-details-card">
													{product?.variants?.map((variant, index) => {
														return (
															<div className="grid grid-cols-3 gap-2 py-1.5 px-4" key={index}>
																<div className="text-[#000000] text-sm font-inter-regular">
																	{variant?.variantName}
																</div>
																<div className="text-[#000000] font-inter-regular text-sm capitalize">{variant?.fuelType}</div>
																<div className="text-[#000000] font-inter-regular text-sm capitalize">
																	{variant?.transmissionType}
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>
								</li>
							))}
						</ul>
						</div>
					</section>
				)}
			</form>
		</div>
	);
}