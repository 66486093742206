import { useDispatch, useSelector } from "react-redux";
import { createUser, destroyUser, distroyRole } from "../Store";

export default function useAuth() {
	const auth = useSelector((state) => state.auth);

	const dispatch = useDispatch();

	const login = (data) => {
		dispatch(createUser(data));
	};

	const logout = () => {
		dispatch(destroyUser());
		dispatch(distroyRole())
	};

	const isLogin = () => {
		if (auth) return true;
		return false;
	};

	return { login, logout, isLogin };
}
