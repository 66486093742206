import { useDispatch } from "react-redux";
import { setToaster } from "../Services/Slices/toastSlice";

export default function useToaster() {
	const dispatch = useDispatch();
	const setToast = ({ type, msg }) => {
		dispatch(setToaster({ type, msg }));
	};
	return { setToast };
}
