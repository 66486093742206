import AsyncSelect from "react-select/async";
import React, { useState } from "react";
import { useGetCustomerByPhoneNumberMutation } from "../../utils/Store";
import { useFormikContext } from "formik";
import ReactFlagsSelect from "react-flags-select";

function SearchCustomer({ setOldCustomer }) {
  const [getCustomerByPhoneNumber, { status: getCustomerByPhoneNumberStatus }] =
    useGetCustomerByPhoneNumberMutation();
  const { setFieldValue, values } = useFormikContext();

  const loadOptions = async (inputValue, callback) => {
    if (inputValue?.length < 4) return callback([]);
    let { data } = await getCustomerByPhoneNumber(inputValue);
    if (data && data.length > 0) {
      const options = data.map((item) => ({
        value: item,
        label: item.name + "   " + item.phoneNumber.slice(3),
      })); // Ensure the correct format
      callback(options);
    } else {
      callback([]);
    }
  };

  const handleSelectChange = (selectedOption, action) => {
    if (action.action === "clear") {
			setFieldValue("search", "");
      setFieldValue("searchedOption", ""); // Clear the value
      // setOldCustomer([]); // Clear the old customer
    } else {
      setFieldValue("search", [selectedOption.value]);
      setFieldValue("searchedOption", {
        value: selectedOption.value,
        label: selectedOption.value.phoneNumber.slice(3),
      });
      setOldCustomer([selectedOption.value]);
    }
  };

  const customStyles = {
	control: (provided) => ({
		...provided,
		borderColor: " 1px solid rgba(0, 0, 0, 0.12)", // set border color to black
		backgroundColor: "#FFF",
		boxShadow: "none", // remove box shadow
		display: "flex",
		borderRadius: "0.375rem",
		fontSize: "14px",
	}),
	menuList: (provided) => ({
		...provided,
		padding: 0,
		border: "none",
		maxHeight: '200px'
	}),
	option: (provided, state) => ({
		...provided,
		borderBottom: "none", // Removes the divider
		color: state.isSelected ? "white" : "black",
		backgroundColor: state.isSelected ? "blue" : "transparent",
	}),
};

	const [selected, setSelected] = useState("IN");
  
  return (
    <div className={`select-class-custom-class flex w-full mb-4 items-center`}>
      <ReactFlagsSelect
        selected={selected}
        showOptionLabel={false}
        disabled={true}
        showSelectedLabel={false}
        placeholder="+91"
      />
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        onChange={handleSelectChange}
        value={values?.searchedOption}
        isClearable={true}
        className={`custom-mobile-select 2xl:mb-0.5 w-full`}
        styles={customStyles} 
        placeholder="Search Customer with Mobile number"
      />
    </div>
  );
}

export default React.memo(SearchCustomer);
