import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/icons/back-arrow.png";

export default function BackButton() {
	const navigate = useNavigate();
	return (
		<img
			src={backArrow}
			alt=""
			onClick={() => navigate(-1)}
			className="w-6 h-6 cursor-pointer"
		/>
	);
}
