import { Field } from "formik";
import React from "react";

export default function Checkbox({ name }) {
	return (
		<Field
			type="checkbox"
			name={name}
			className="rm-font-overpass mr-[0.83vw] border-gray-600 border-2 p-1.5 bg-inherit checked:bg-gray-600 focus:ring-transparent focus:ring-offset-0 focus:checked:bg-gray-600 sm:mx-2"
		/>
	);
}
