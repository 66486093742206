import React from "react";
import {
    Car,
    Clock,
    GasStation,
    Lifebuoy,
    Location,
    User,
    Message,
    Calendar,
} from "iconsax-react";
import Button from "../../shared/components/Button.component";
import PaymentMode from "../../assets/icons/payment-mode.svg";
import PhoneCall from "../../assets/icons/phone-call.svg";



function CardServiceSkeleton({ type, orderDetails = null, status, dataLength }) {

    return (
        <>
            { dataLength > 0 ? 
                (<div className="w-[95%]">
                    <div
                        className={`w-full h-2/4 pb-5 mb-3 bg-white ${status === 'requested' ? "border-[#FF621F]" : status === 'confirmed' ? "border-[#00BE68]" : status === 'canceled' ? "border-[#CB0303]" : ""} border-l-8 shadow rounded-xl order-modal-${type}`}
                    >
                        <div className="bg-white rounded-t-lg drop-shadow-md px-5 py-4">
                            <div className="flex items-center justify-between">
                                <div className="w-fit">
                                    <div className="flex items-center py-1.5 px-3 mt-2 bg-[#F1F1F1] rounded-lg space-x-2 skeleton">
                                        <Car className="w-5 h-5 2xl:w-7 2xl:h-7 invisible" />
                                        <h1 className="font-inter-medium text-base 2xl:text-lg pl-1 invisible">
                                            Service Name
                                        </h1>
                                    </div>
                                </div>
                                <div className="flex justify-evenly items-center">
                                    <div className="flex items-center skeleton">
                                        <Clock size={25} className="mx-3 w-5 h-5 2xl:w-7 2xl:h-7 invisible" />
                                        <h1 className="text-base 2xl:text-xl font-inter-medium text-[#000] invisible">Time Slot</h1>
                                    </div>
                                    <div className="flex items-center ml-6 skeleton">
                                        <Calendar size={25} className="mx-4 w-5 h-5 2xl:w-7 2xl:h-7 invisible" />
                                        <h1 className="text-base 2xl:text-xl font-inter-medium text-[#000] invisible">
                                            Service Date
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between pt-5 ">
                                {type !== "success" && (
                                    <>
                                        <div className="w-fit">
                                            <div className="skeleton">
                                                <Button className="bg-[#D9D9D9] rounded-lg text-base 2xl:text-xl font-inter-medium text-[#000000] cursor-pointer border border-[#A8A8A8] invisible">
                                                    One Time Service
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="w-fit">
                                            <div className={`relative left-5 ${status === 'requested' ? "bg-[#FF621F]/20" : status === 'confirmed' ? "bg-[#00BE68]/20" : status === 'canceled' ? "bg-[#CB0303]/20" : ""} skeleton`}>
                                                <h1 className={`relative h-10 w-40 text-lg font-medium font-plus-jakarta text-center pt-1.5 ${status === 'requested' ? "text-[#FF621F]" : status === 'confirmed' ? "text-[#00BE68]" : status === 'canceled' ? "text-[#CB0303]" : ""} rounded-l-full invisible`}>
                                                    {
                                                        status === 'requested' ? "Requested" : status === 'confirmed' ? "Confirmed" : status === 'canceled' ? "Cancelled" : ""
                                                    }
                                                    <div className="h-8 w-8 rounded-full absolute -left-5 top-1/2 bg-white -translate-y-1/2"></div>
                                                </h1>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="">
                            <div className="flex justify-between ">
                                <div className="h-[13vw] w-[30%] p-5 pt-6 ">
                                    <div className="w-full h-full width-30 skeleton skeleton-h-full">
                                        <img src="" className="w-full h-full object-contain invisible" />
                                    </div>
                                </div>

                                <div className="w-[25%] pt-6 relative">
                                    <div className="w-[80%] overflow-x-auto final-price-scrollbar skeleton">
                                        <h1 className="2xl:text-[22px] text-lg font-inter-bold vehicle-name-text whitespace-nowrap invisible">Vehicle Name</h1>
                                    </div>
                                    <div className="flex space-x-8 h-[3vw] items-center">
                                        <div className="flex space-x-3 items-center skeleton">
                                            <GasStation size={22} color="#666666" className="icon-size-14px invisible" />
                                            <h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base invisible">
                                                Fuel Type
                                            </h1>
                                        </div>
                                        <div className="flex space-x-3 items-center skeleton">
                                            <Lifebuoy size={22} color="#666666" className="icon-size-14px invisible" />
                                            <h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base invisible">
                                                Transmission Type
                                            </h1>
                                        </div>
                                    </div>

                                    <div className="flex space-x-3 h-[3vw] items-center skeleton">
                                        <img src="" className="w-6 h-6 icon-size-14px invisible" />
                                        <h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base invisible">
                                            Vehicle Number
                                        </h1>
                                    </div>

                                    <div className=" flex space-x-5 h-[3vw] items-center skeleton">
                                        <p className="text-black font-inter-medium text-sm 2xl:text-base invisible">Assigned To</p>
                                        <div className="w-[50%] ">
                                            <div className="border items-center sm:pl-2 bg-[#FFFFFF] flex space-x-2 font-normal border-inherit rounded-md p-1.5 w-full justify-start pl-1">
                                                <User size={18} color="#666666" className="icon-size-14px mx-1 invisible" />
                                                <span className="text-[#474747] text-sm 2xl:text-base truncate invisible">Rider Name</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[50%] 2xl:w-[40%] pb-4 pt-6 width-40 pr-6">
                                    <div className="flex h-[5vw] skeleton">
                                        <div className="w-[35%]">
                                            <p className="text-black font-inter-medium text-sm 2xl:text-base invisible">Address</p>
                                        </div>

                                        <div className="flex justify-center items-start">
                                            <Location size={20} color="#666666" className="icon-size-14px invisible" />

                                            <p className="font-inter-regular text-[#474747] text-sm 2xl:text-base w-56 pl-2 ml-0 line-clamp-3 2xl:line-clamp-4 invisible">
                                                Prajakta, Rural Hospital, shivaji peth, Kolhapur, 411016
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex h-[3vw] items-center skeleton">
                                        <div className="w-[35%]">
                                            <p className="text-black font-inter-medium text-sm 2xl:text-base invisible">Customer Name</p>
                                        </div>

                                        <div className="flex justify-center">
                                            <User size={20} color="#666666" className="icon-size-14px invisible" />
                                            <p className="font-inter-regular text-[#474747] pr-2 pl-2 3xl:text-base text-sm truncate invisible">Customer Name</p>
                                            <img src={PhoneCall} className="w-5 h-5 icon-size-14px invisible" />
                                        </div>
                                    </div>

                                    <div className="flex h-[3vw] items-center skeleton">
                                        <div className="w-[35%]">
                                            <p className="text-black font-inter-medium text-sm 2xl:text-base invisible">Customer Email</p>
                                        </div>

                                        <div className="flex space-x-1 items-center w-[65%] pr-4">
                                            <Message size={20} color="#666666" className="icon-size-14px invisible" />
                                            <p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate invisible">Customer Email</p>
                                        </div>
                                    </div>

                                    <div className="flex h-[3vw] items-center skeleton">
                                        <div className="w-[35%]">
                                            <p className="text-black font-inter-medium text-sm 2xl:text-base invisible">Order ID</p>
                                        </div>

                                        <div className="flex space-x-1 items-center w-[65%] pr-4">
                                            <p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate invisible">
                                                Order Unique ID
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-5 mt-3">
                            <hr className="border bg-gray-300 w-full" />
                        </div>
                        <div className="flex justify-between pt-5 px-16">
                            <div className="w-[20rem]">
                                <div className="flex items-center space-x-14 skeleton">
                                    <div className="">
                                        <p className="text-black font-inter-medium text-base invisible">Total Service Time</p>
                                    </div>
                                    <div className="flex items-center justify-center space-x-3 ml-3 border rounded-lg p-1 px-3">
                                        <Clock size={18} color="gray" className="icon-size-14px invisible" />
                                        <p className="font-inter-medium text-base text-[#000000] invisible">
                                            Hours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[15rem]">
                                <div className="flex items-center space-x-14 skeleton">
                                    <div className="">
                                        <p className="text-black font-inter-medium text-base invisible">Total Price</p>
                                    </div>
                                    <div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
                                        <p className="text-gray text-base invisible">₹</p>
                                        <p className="text-[#000000] font-inter-medium text-base invisible">
                                            Payable Amount
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" px-5">
                            <hr className="border bg-gray-300 w-full mt-5" />
                        </div>
                        <div className="flex items-center py-5 justify-between">
                            <div className="w-[20rem] ml-16">
                                <div className="flex items-center pl-16 space-x-6 skeleton">
                                    <div className="flex mr-2 invisible">
                                        <img src={PaymentMode} color="white" className="h-9 w-9" />
                                        <h1 className="font-semibold pl-2 flex items-center justify-center">
                                            Payment Mode :
                                        </h1>
                                    </div>
                                    <div className="invisible">
                                        <p className="font-inter-medium text-gray-700 text-md border rounded-lg p-1 px-5">
                                            Payment Type
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* {
							orderDetails?.canceledBy?.role === 'Rider' &&
							<Button className="bg-black font-md w-[300px] text-white border lg:text-base 2xl:text-lg border-black-200 rounded-xl py-2 p-2 mr-4 cursor-pointer h-[3.1rem] btn-radius" onClick={handleAssignRider}>
								Assign to Rider
							</Button>
						} */}
                        </div>
                        {/* {type !== "success" && (
						<>
							<div className="px-5">
								<hr className="border bg-gray-300 w-full" />
							</div>
							<div className="">
								<div className="flex items-center justify-evenly pt-5 px-5">
									{
										status !== "canceled" &&
											<>
												{
													status === "requested" && 
													<Button className="bg-black w-[285px] text-white font-inter-regular lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] py-2 p-2 mr-4 cursor-pointer h-[3.1rem] btn-radius" onClick={handleAssignRider}>
														Assign to Rider
													</Button>
												}
												<Button className="bg-black w-[285px] text-white font-inter-regular lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] p-2 mr-4 cursor-pointer h-[3.1rem] btn-radius" onClick={handleRescheduleRequest}>
													Reschedule Request
												</Button>
												<Button className="bg-black w-[285px] text-white font-inter-regular lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] p-2 mr-5 cursor-pointer h-[3.1rem] btn-radius" onClick={handleCancelRequest}>
													Cancel Request
												</Button>												
											</>
									}
								</div>
								{
									status === "canceled" &&
									<div className="pl-16">
										<div className=" flex space-x-7 pt-3 pr-5 items-center">

											<p className="text-gray-900 font-semibold text-base">Cancelled by {orderDetails?.canceledBy?.role}</p>
											<div className="">
												<Button className="bg-[#FFFFFF] flex space-x-2 font-normal w-32 cursor-pointer">
													<User size={20} color="#666666" className="icon-size-14px"/>
													<span className="text-[#474747]">{orderDetails?.canceledBy?.name}</span>
												</Button>
											</div>
										</div>
										<div className="flex justify-between">
											<div className=" flex space-x-1 pr-5 items-center w-1/2">
												<p className="text-gray-900 font-semibold text-base">Reason :</p>
												<div className="">
													<p className="font-inter-medium text-[#474747]">{orderDetails?.reason}</p>
												</div>
											</div>
											<div className="flex items-center justify-center px-11 w-1/2">
												<p className="font-inter-medium text-[#474747] pt-6 "><span className="text-gray-900 font-semibold text-base pr-1">Note :</span>{orderDetails?.note}</p>

											</div>
										</div>
									</div>
								}
							</div>
						</>
					)} */}
                    </div>
                </div>) : (
                    <div className="flex items-center h-96">
                        <span>No Data Found</span>
                    </div>
                )
            }
        </>
    );
}

export default CardServiceSkeleton;
