import React from "react";
import {
	Car,
	Clock,
	GasStation,
	Lifebuoy,
	Location,
	User,
	Message,
	Calendar,
} from "iconsax-react";
import Button from "../../../shared/components/Button.component";
import PaymentMode from "../../../assets/icons/payment-mode.svg";
import KeyboardIcon from "../../../assets/icons/small-keyboard.svg";
import PhoneCall from "../../../assets/icons/phone-call.svg";
import moment from "moment";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import useToaster from "../../../utils/hooks/useToaster";
import { formatTime, secondsToTime, timeDifference, timeToSeconds } from "../../../utils/helper/progressBarCalculations";

function RiderServicesCard({ status, orderDetails }) {
	const { setToast } = useToaster();
	const { openModal } = useSelectModal();

	if (!orderDetails) return;
	const handleAssignRider = () => {
		openModal("AssignRiderModal", { ...orderDetails, hideCloseArrow: false });
	}

	const handleRescheduleRequest = () => {
		openModal("RescheduleModal", { ...orderDetails, hideCloseArrow: false });
	}

	const handleCancelRequest = () => {
		openModal("CancelAppointmentModal", {
			status: 'canceled',
			orderId: orderDetails._id,
		});
	}

	function formatNumber(num) {
		if (num < 10) {
			return '0' + num;
		}
		return '' + num;
	}

	const addonServices = orderDetails?.service?.filter((service) => service.addOnService);


	return (
		<>
			<div>
				<div
					className={`w-full h-2/4 pb-5 mb-3 bg-white ${(orderDetails?.status === 'navigation' || orderDetails?.status === 'assigned') ? "border-[#00BE68]" : orderDetails?.status === 'ongoing' ? "border-[#FF621F]" : orderDetails?.status === 'completed' ? "border-[#315EFF]" : ""} border-l-8 shadow rounded-xl`}
				>
					<div className="bg-white rounded-t-lg drop-shadow-md px-5 py-4">
						<div className="flex items-center justify-between">
							<div className="flex items-center py-1.5 px-3 mt-2 bg-[#F1F1F1] rounded-lg w-fit space-x-2">
								<Car className="w-5 h-5 2xl:w-7 2xl:h-7" />
								<h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
									{orderDetails?.service[0]?.serviceName}
								</h1>
							</div>
							<div className="flex justify-evenly items-center">
								<div className="flex items-center">
									<Clock size={25} className="mx-3 w-5 h-5 2xl:w-7 2xl:h-7" />
									<h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">{orderDetails?.timeSlot?.split(" - ")[0]}</h1>
								</div>
								<div className="flex items-center ml-6">
									<Calendar size={25} className="mx-4 w-5 h-5 2xl:w-7 2xl:h-7" />
									<h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">
										{moment(orderDetails?.serviceDate).format("DD MMMM YYYY")}
									</h1>
								</div>
							</div>
						</div>

						<div className="flex items-center justify-between pt-5 ">
							<div className="">
								<Button className="bg-[#D9D9D9] rounded-lg text-base 2xl:text-xl font-inter-medium text-[#000000] cursor-pointer border border-[#A8A8A8]">
									One Time Service
								</Button>
							</div>
							<div className="flex items-center">
								<div className={`relative left-5 ${orderDetails?.status === 'completed' ? "bg-[#315EFF33] text-[#315EFF]" : orderDetails?.status === 'ongoing' ? "bg-[#FF621F33] text-[#FF621F]" : orderDetails?.status === 'assigned' ? "bg-[#00BE6833] text-[#00BE68]" : ""} `}>
									<h1 className={`relative h-10 w-40 text-lg font-medium font-plus-jakarta text-center pt-1.5 rounded-l-full`}>
										{
										 	orderDetails?.status === 'assigned' ? `Upcoming` : orderDetails?.status === 'ongoing' ? `${orderDetails?.status}` : orderDetails?.status === 'completed' ? `${orderDetails?.status}` : ""
										}
										<div className="h-8 w-8 rounded-full absolute -left-5 top-1/2 bg-white -translate-y-1/2"></div>
									</h1>
								</div>

								{
									!orderDetails?.rescheduled ? '' : (
										<div className={`relative left-5 bg-[#FB0C0C33] text-[#FB0C0C] ml-16`}>
											<h1 className={`relative h-10 w-40 text-lg font-medium font-plus-jakarta text-center pt-1.5 rounded-l-full`}>
												Rescheduled
												<div className="h-8 w-8 rounded-full absolute -left-5 top-1/2 bg-white -translate-y-1/2"></div>
											</h1>
										</div>
									)
								}
							</div>
						</div>
					</div>
					<div className="">
						<div className="flex justify-between ">
							<div className="w-[20%] 2xl:w-[30%] p-5 pt-6 h-[12vw] width-30">
								<img src={orderDetails?.vehicleModelImage} className="w-full h-full object-contain" />
							</div>

							<div className="w-[30%] pt-6 relative">
								<div className="w-[80%] overflow-x-auto final-price-scrollbar">
									<h1 className="2xl:text-[22px] text-lg font-inter-bold vehicle-name-text whitespace-nowrap">{orderDetails?.vehicleBrand} {orderDetails?.vehicleModel}</h1>
								</div>
								<div className="flex space-x-8 h-[3vw] items-center">
									<div className="flex space-x-3 items-center">
										<GasStation size={22} color="#666666" className="icon-size-14px" />
										<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
											{orderDetails?.fuelType?.charAt(0).toUpperCase() +
												orderDetails?.fuelType?.slice(1)}
										</h1>
									</div>
									<div className="flex space-x-3 items-center">
										<Lifebuoy size={22} color="#666666" className="icon-size-14px" />
										<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
											{orderDetails?.transmissionType.charAt(0).toUpperCase() +
												orderDetails?.transmissionType.slice(1)}
										</h1>
									</div>
								</div>

								<div className="flex space-x-3 h-[3vw] items-center">
									<img src={KeyboardIcon} className="w-6 h-6 icon-size-14px" />
									<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
										{orderDetails?.vehicleNumber?.replace(/([A-Z]{2})(\d{2})([A-Z]{2})(\d{4})/, "$1 $2 $3 $4")}
									</h1>
								</div>

								<div className=" flex space-x-5 h-[3vw] items-center">
									<p className="text-black font-inter-medium text-sm 2xl:text-base ">Assigned Area</p>
									<div className="w-[50%]">
										<div className="border items-center sm:pl-2 bg-[#FFFFFF] flex space-x-2 font-normal border-inherit rounded-md p-1.5 w-full justify-start pl-1">
											<span className="text-[#474747] text-sm 2xl:text-base truncate">{orderDetails?.addressLineOne ? orderDetails?.addressLineOne : 'None'}</span>
										</div>
									</div>
								</div>

								<div className="absolute 2xl:right-7 lg:right-[0.75rem] top-3 w-px bg-gray-300 h-[12vw]"></div>
							</div>
							<div className="w-[50%] 2xl:w-[40%] pb-4 pt-6 width-40">
								<div className="flex h-[3vw]">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Address</p>
									</div>

									<div className="flex justify-center items-start">
										<Location size={20} color="#666666" className="icon-size-14px" />

										<p className="font-inter-regular text-[#474747] text-sm 2xl:text-base w-[20rem] pl-2 ml-0 line-clamp-3 2xl:line-clamp-4">
											{orderDetails?.addressLineOne && <span>{orderDetails?.addressLineOne}</span>}
											{orderDetails?.addressLineTwo && <span>, {orderDetails?.addressLineTwo}</span>}
											{orderDetails?.landMark && <span>, {orderDetails?.landMark}</span>}
											{orderDetails?.areaName && <span>, {orderDetails?.areaName}</span>}
											{orderDetails?.cityName && <span>, {orderDetails?.cityName}</span>}
											{orderDetails?.pincode && <span>, {orderDetails?.pincode}</span>}
										</p>
									</div>
								</div>

								<div className="flex h-[2.5vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Customer Name</p>
									</div>

									<div className="flex justify-center">
										<User size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] pr-2 pl-2 3xl:text-base text-sm truncate">{orderDetails?.customerName}</p>
										<img src={PhoneCall} className="w-5 h-5 icon-size-14px" />
									</div>
								</div>

								<div className="flex h-[2.5vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Customer Email</p>
									</div>

									<div className="flex space-x-1 items-center w-[65%] pr-4">
										<Message size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate">{orderDetails?.customerEmail}</p>
									</div>
								</div>

								<div className="flex h-[2.5vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Order ID</p>
									</div>

									<div className="flex space-x-1 items-center w-[65%] pr-4">
										<p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate">
											{orderDetails?.orderUniqueId ? orderDetails?.orderUniqueId : '-'}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{addonServices?.length > 0 && (
						<>
							<div className="px-5 mt-3">
								<hr className="border bg-gray-300 w-full" />
							</div>
							<div className="pt-5 2xl:px-16 lg:px-9">
								<div className="flex items-center">
									<div className="">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Add On Services</p>
									</div>
									<div>
										<div className="flex items-center py-1 justify-between">
											<div className="flex items-center pl-[14rem] space-x-6">
												<div className="flex mr-2">
													<img src={PaymentMode} color="white" className="h-9 w-9" />

													<h1 className="font-inter-medium pl-2 flex items-center justify-center text-base 2xl:text-base lg:text-sm">
														Payment Mode :
													</h1>
												</div>
												<div>
													<p className="font-inter-medium text-gray-700 text-md border rounded-lg p-1 px-5">{orderDetails?.paymentMode}</p>
												</div>
											</div>

										</div>
									</div>
								</div>
								<div className="items-center overflow-x-auto final-price-scrollbar">

									<div className="flex space-x-3 w-max">
										{addonServices.map((addOnService, i) => {
											const serviceDurationInSeconds = timeToSeconds(addOnService?.serviceTime[0]);
											const timeDiff = addOnService?.startTime ? timeDifference(addOnService?.startTime, addOnService?.endTime) : { hours: 0, minutes: 0, seconds: 0 };
											const extraTimeInSeconds = addOnService?.startTime ? timeToSeconds(timeDiff) - serviceDurationInSeconds : 0;
											const extraTime = secondsToTime(extraTimeInSeconds);
											return (
												<div className="flex items-center justify-center space-x-2 border rounded-lg py-1 px-3 my-2" key={i}>
													<img src={addOnService?.imageUrl} className="h-5 w-5" />
													<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">{addOnService?.serviceName}</p>
													<Clock size={18} color="gray" className="icon-size-14px" />
													<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
														{addOnService?.serviceTime[0]?.hours}:
														{formatNumber(addOnService?.serviceTime[0]?.minutes)} Hours
														<span className="text-[#CB0303] font-inter-bold text-sm 2xl:text-base">
															{extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}
														</span>
													</p>
													<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
														<span className="text-sm 2xl:text-base">₹</span>
														{addOnService?.addOnServiceAmount}
													</p>
												</div>
											)
										})}
									</div>

								</div>
							</div>
						</>
					)}

					<div className="px-5 mt-3">
						<hr className="border bg-gray-300 w-full" />
					</div>

					<div className="flex justify-between pt-5 px-16">
						<div className="flex items-center space-x-14">
							<div className="">
								<p className="text-black font-inter-medium text-base">Total Service Time</p>
							</div>
							<div className="flex items-center justify-center space-x-3 ml-3 border rounded-lg p-1 px-3">
								<Clock size={18} color="gray" className="icon-size-14px" />
								<p className="font-inter-medium text-base text-[#000000]">
									{orderDetails?.service[0]?.serviceTime[0].hours}:
									{formatNumber(orderDetails?.service[0]?.serviceTime[0].minutes)} Hours
								</p>
							</div>
						</div>
						<div className="flex items-center py-1 justify-between">
							<div className="flex items-center pl-16 space-x-6">
								<div className="flex mr-2">
									<img src={PaymentMode} color="white" className="h-9 w-9" />

									<h1 className="font-inter-medium pl-2 flex items-center justify-center text-base 2xl:text-base lg:text-sm">
										Payment Mode :
									</h1>
								</div>
								<div>
									<p className="font-inter-medium text-gray-700 text-md border rounded-lg p-1 px-5">
										{
											addonServices?.length ? orderDetails?.paymentMode : orderDetails?.paymentType
										}
									</p>
								</div>
							</div>

						</div>
						<div className="flex items-center space-x-14">
							<div className="">
								<p className="text-black font-inter-medium text-base">Total Price</p>
							</div>
							<div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
								<p className="text-gray text-base">₹</p>
								<p className="text-[#000000] font-inter-medium text-base">
									{orderDetails.payableAmount}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RiderServicesCard;
