import React from 'react'
import SubscriptionsCard from './Subscriptions_Card'

function CustomerManagementSubscriptions() {
  return (
    <div>
        <p className='font-inter-bold text-[22px] total-text'>Total Subscriptions (02)</p>
        <div className='mt-5 overflow-scroll h-[21.75vw]'>
            <SubscriptionsCard />
            <SubscriptionsCard />
            <SubscriptionsCard />
        </div>
    </div>
  )
}

export default CustomerManagementSubscriptions