import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const packageApi = apiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            getAllPackages: builder.query({
                query: () => ({
                    url: endpoints.packages.getAllPackages,
                    method: "GET",
                }),
                providesTags: ["GetAllPackages"],
            }),
            createPackage: builder.mutation({
				query: (body) => ({
					url: endpoints.packages.addPackage,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["GetAllPackages"],
			}),
        }
    }
})



export const {
   useLazyGetAllPackagesQuery,
   useCreatePackageMutation
} = packageApi;
export { packageApi };