import React from 'react';
import VehiclesCard from './vehicle-module/Vehicles_Card';
import VehicleServiceCard from './vehicle-module/Vehicle_Service_Card';
import { useLazyGetSingleOrderQuery } from '../../utils/Store';
import { useEffect } from 'react';

function CustomerServiceModal({data}) {
  const isVehicleServiceModal = true;
  const [getSingleOrder, { data: singleOrderData }] = useLazyGetSingleOrderQuery();

  useEffect(() => {
      if(data){
        getSingleOrder(data.orderId)
      }
  }, [])

  return (
    <div className='shadow rounded-none 2xl:w-[89rem] w-[78rem] border-l-8 border-[#315EFF] overflow-hidden'>
        <VehiclesCard
          isVehicleServiceModal = {isVehicleServiceModal}  
          vehicleData = {singleOrderData}
        />
        <VehicleServiceCard
          isVehicleServiceModal = {isVehicleServiceModal}
          vehicleDetailsData = {singleOrderData}
          serviceData = {data}
        />
    </div>
  )
}

export default CustomerServiceModal