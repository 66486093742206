import React from 'react'
import Car from '../../assets/icons/card/car.svg'
import Information from '../../assets/icons/information.svg'
import Edit from '../../assets/icons/Edit.png'
import Delete from '../../assets/icons/Delete.png'
import CarService from '../../assets/icons/card/car-service.svg'
import ExternalCleaning from '../../assets/icons/card/external-cleaning.svg'
import InternalCleaning from '../../assets/icons/card/internal-cleaning.svg'
import PaymentMode from '../../assets/icons/card/payment-mode.svg'
import useSelectModal from '../../utils/hooks/useSelectModal'

const CardLayout = () => {

  const{openModal}=useSelectModal();
  return (
    <div className=''>

      <div className='pt-0.5'>

        <div className='flex flex-wrap'>
          <div className='bg-white border shadow w-[443px] m-2 rounded-2xl mb-6'>

            <div className=' bg-white border-b px-4 2xl:px-6 pt-4 pb-3 rounded-t-2xl'>
              <div className='flex justify-between 2xl:space-x-24'>
                <div className=''>
                  <h1 className='font-bold 2xl:text-lg'>Winter Care Package</h1>
                </div>
                <div className='flex space-x-1'>
                  <div className='h-7 w-7 bg-[#F5F5F5] rounded-full flex items-center justify-center cursor-pointer'>
                    <img src={Information} className='w-5 h-5' />
                  </div>
                  <div className='h-7 w-7 bg-[#F5F5F5] rounded-full flex items-center justify-center cursor-pointer'>
                    <img src={Edit} className='w-5 h-5' onClick={()=>openModal("PackageModal")} />
                  </div>
                  <div className='h-7 w-7 bg-[#F5F5F5] rounded-full flex items-center justify-center cursor-pointer'>
                    <img src={Delete} className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className='w-3/4 flex space-x-2 mt-2'>
                <div>
                  <h1 className='font-medium text-sm 2xl:text-base'>City Name:</h1>
                </div>
                <div>
                  <h1 className='font-medium text-sm 2xl:text-base'>Bangalore</h1>
                </div>
              </div>
            </div>

            <div className='px-4 2xl:px-6 py-4'>
              <div className='flex justify-between 2xl:space-x-28 mb-2'>
                <div className='flex items-center '>
                  <div className='bg-[#F5F5F5] h-8 w-8 flex items-center justify-center rounded-2xl'>
                    <img src={CarService} />
                  </div>
                  <h1 className='font-normal pl-1 items-center text-sm 2xl:text-base'>External Cleaning :</h1>
                </div>
                <div className='flex items-center justify-center'>
                  <h1 className='font-normal items-center text-sm 2xl:text-base'>₹ 1000</h1>
                </div>
              </div>

              <div className='flex justify-between 2xl:space-x-28 mb-2'>
                <div className='flex items-center '>
                  <div className='bg-[#F5F5F5] h-8 w-8 flex items-center justify-center rounded-2xl'>
                    <img src={CarService} />
                  </div>
                  <h1 className='font-normal pl-1 items-center text-sm 2xl:text-base'>External Cleaning :</h1>
                </div>
                <div className='flex items-center justify-center'>
                  <h1 className='font-normal items-center text-sm 2xl:text-base'>₹ 1000</h1>
                </div>
              </div>

              <div className='flex justify-between 2xl:space-x-28'>
                <div className='flex items-center '>
                  <div className='bg-[#F5F5F5] h-8 w-8 flex items-center justify-center rounded-2xl'>
                    <img src={CarService} />
                  </div>
                  <h1 className='font-normal pl-1 items-center text-sm 2xl:text-base'>External Cleaning :</h1>
                </div>
                <div className='flex items-center justify-center'>
                  <h1 className='font-normal items-center text-sm 2xl:text-base'>₹ 1000</h1>
                </div>
              </div>
            </div>

            <hr />

            <div className='px-4 2xl:px-6 py-4'>
              <div className='flex justify-between 2xl:space-x-28 mb-3'>
                <h1 className='font-normal text-sm 2xl:text-base'>Total Price :</h1>
                <h1 className='font-normal text-sm 2xl:text-base'>₹ 3000</h1>
              </div>
              <div className='flex justify-between 2xl:space-x-28'>
                <h1 className='font-normal text-sm 2xl:text-base'>Discount :</h1>
                <h1 className='font-normal text-sm 2xl:text-base'>₹ 250</h1>
              </div>
            </div>

            <hr />

            <div className='px-4 2xl:px-6 py-4'>
              <div className='flex justify-between 2xl:space-x-28'>
                <h1 className='font-normal text-sm 2xl:text-base'>Final Package Price :</h1>
                <h1 className='font-normal text-sm 2xl:text-base'>₹ 2750</h1>
              </div>
            </div>

            <hr />

            <div className='px-4 2xl:px-6 py-4 flex justify-between w-full'>
              <div className='flex items-center w-1/2'>
                <div className='bg-[#198754] rounded-full w-6 h-6 2xl:w-7 2xl:h-7 flex items-center justify-center'>
                  <img src={PaymentMode} />
                </div>
                <h1 className='font-normal pl-1 flex items-center justify-center text-sm 2xl:text-base'>Payment Mode :</h1>
              </div>
              <div className='w-1/2 text-end'>
                <select className='border-[#E5E5E5] rounded-xl 2xl:w-full ml-auto text-sm 2xl:text-base'>
                  <option value="Cash" className='text-[#475467]'>Cash</option>
                  <option value="Online" className='text-[#475467]'>Online</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLayout;

