import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../utils/hooks/useAuth";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isLogin } = useAuth();
	if (isLogin()) return <Outlet />; // or loading indicator, etc...
	return <Navigate to={"/signin"} replace />;
};

export default PrivateRoute;
