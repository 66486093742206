import React from "react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import { BiTrash } from "react-icons/bi";
import DeleteIcon from "../../assets/icons/modal-delete-icon.svg";

import {
	useDeleteBrandsMutation,
	useDeleteCarModelMutation,
} from "../../utils/Services/Apis/carApi";
import useToaster from "../../utils/hooks/useToaster";
import { useNavigate } from "react-router-dom";

function DeleteCarModal({ data }) {
	const { closeModal, openModal } = useSelectModal();
	const [deleteCarModel,{status}] = useDeleteCarModelMutation();
	const { setToast } = useToaster();
	const handleDelete = () => {
		deleteCarModel(data.uniqueId)
			.unwrap()
			.then(({ data }) => {
				setToast({ type: "success", msg: "Car Model deleted successfully" });
			})
			.catch((err) => {
				setToast({ type: "error", msg: err.data.message });
			});
		closeModal();
	};

	return (
		<div className="w-[30rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
			<div className="flex items-center justify-center">
				<div className=" bg-[#E5E5E5] h-2/12 w-2/12 rounded-full flex justify-center items-center ">
					<img src={DeleteIcon} />
				</div>
			</div>

			<div className="pb-8 text-center">
				<div className="flex font-inter-medium justify-center pt-4 text-lg text-center">
					Do you want to delete this
					<br /> “{data?.name}” Car?
				</div>
				<div className="text-[#87898E] text center w-full">This action can't be undone.</div>
			</div>
			<div className="flex justify-between ">
				<Button className="w-[47.5%] bg-[#F1F1F1] text-[#23262F] cursor-pointer" onClick={closeModal}>
					Cancel
				</Button>
				<Button className="w-[47.5%] text-white cursor-pointer" type="submit" onClick={handleDelete} isSubmitting={status==="pending"}>
					Delete
				</Button>
			</div>
		</div>
	);
}

export default DeleteCarModal;
