import React from 'react'
import { Tab } from "@headlessui/react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function RiderManagementTabs({ categories, className, setSelectedIndex, selectedIndex }) {
    return (
		<Tab.Group
			as={"div"}
			className={className}
			selectedIndex={selectedIndex}
			onChange={setSelectedIndex}
		>
			<Tab.List className="flex items-center justify-between space-x-1 rounded-[6px] bg-[#F5F5F5] border border-[#A8A8A8]">
				{categories.map((category, index) => (
					<Tab
						key={index}
						className={({ selected }) =>
							classNames(
								"tab-titles w-[13.5rem] 2xl:rounded-[6px] rounded-[6px] 2xl:py-[0.625rem] py-[0.45rem] whitespace-nowrap text-base 2xl:text-base lg:text-sm font-inter-medium leading-5",
								"focus:outline-none",
								selected
									? "bg-black  text-white"
									: "text-black hover:bg-white/[0.12]"
							)
						}
					>
						{category.title}
					</Tab>
				))}
			</Tab.List>
		</Tab.Group>
	);
}

export default RiderManagementTabs