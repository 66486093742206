import React, { useState, useRef, useEffect, useMemo } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { useFormikContext } from "formik";
import useToaster from "../../utils/hooks/useToaster";
import {data as areaMasterHelper} from '../../utils/helper/areaMaster';

const containerStyle = {
	width: "100%",
	height: "302px",
};

function MapArea({ pincodeArray = [], city, isEdit, setPincodeArray }) {
	const { values, setFieldValue, setFieldError } = useFormikContext();
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const { setToast } = useToaster();

	const marker = useMemo(
		() => ({
			lat: +values.latitude,
			lng: +values.longitude,
		}),
		[values?.latitude, values.longitude]
	);

	const [center, setCenter] = useState({
		lat: city.coOrdinates.latitude,
		lng: city.coOrdinates.longitude,
	});
	const mapRef = useRef(null);
	const boundaryStyles = {};
	const [selectedPoint, setSelectedPoint] = useState(null);
	const lastCircleRef = useRef(null);
	const searchedAreaRef = useRef(null)
	const isFirstRenderRef = useRef(true)

	const onLoad = (map) => {
		mapRef.current = map;
		const idleListener = map.addListener("idle", () => {
			setIsMapLoaded(true);
		});
		return () => {
			window.google.maps.event.removeListener(idleListener);
		};
	};

	const onUnmount = () => {
		mapRef.current = null;
	};

	useEffect(() => {
		if (!pincodeArray.length) {
			if (mapRef.current) {
				let featureLayer = mapRef.current.getFeatureLayer("POSTAL_CODE");
				featureLayer.style = (options) => {
					return {}; // Resetting to default styles or you can define a default style
				};
			}
			return;
		}
		const geocoder = new window.google.maps.Geocoder();
		pincodeArray.forEach((pincode) => {
			geocoder.geocode({ address: pincode, region: "IN" }, (results, status) => {
				if (status === "OK" && results.length > 0) {
					const place = results[0].geometry.location;
					const placeId = results[0].place_id;
					const boundaryStyle = {
						strokeColor: "#810FCB",
						strokeOpacity: 1.0,
						strokeWeight: 3.0,
						fillOpacity: 0.5,
					};
          // console.log(results[0].address_components.find((address)=> address.types.includes("locality")).long_name)
					if(!isEdit){
						if (!results[0].formatted_address.includes(city.name)) {
							// Place is outside the bounds
							setToast({ type: "error", msg: `Please add a pincode within the ${city.name} city` });
							setPincodeArray(pincodeArray.filter(pin => pin !== pincode))
							return;
						}
					}

					boundaryStyles[placeId] = boundaryStyle;

					// Assuming all geocoding requests have been completed, you can apply the styles here
					if (Object.keys(boundaryStyles).length === pincodeArray.length) {
						let featureLayer = mapRef.current.getFeatureLayer("POSTAL_CODE");
						featureLayer.style = (options) => {
							if (options?.feature?.placeId) {
								return boundaryStyles[options.feature.placeId];
							}
						};
					}

					if (isEdit) return;
					if (pincodeArray.length !== 1) return;
					setCenter({
						lat: place.lat(),
						lng: place.lng(),
					});
					setFieldValue("latitude", place.lat());
					setFieldValue("longitude", place.lng());
				} else {
					setToast({ type: "error", msg: `No results found for the pin code: ${pincode}` });
					setPincodeArray(pincodeArray.filter(pin => pin !== pincode))
				}
			});
		});
	}, [pincodeArray]);

	useEffect(() => {
		if ((!values.radius || !values.search) && lastCircleRef.current) {
			lastCircleRef.current.setMap(null);
			lastCircleRef.current = null;
			return;
		}
		if (!values?.search || !values.radius) return;
		setPincodeArray([]);

		const geocoder = new window.google.maps.Geocoder();

		if(isEdit && isFirstRenderRef.current) searchedAreaRef.current = values?.search

		geocoder.geocode({ address: values?.search }, (results, status) => {
			if (status === "OK" && results.length > 0) {
				const place = values.location;

				// Remove the previous circle from the map if it exists.
				if (lastCircleRef.current) {
					lastCircleRef.current.setMap(null);
				}

				// Create and add circle to the map
				const circle = new window.google.maps.Circle({
					center: searchedAreaRef.current !== values?.search ? { lat: place.lat(), lng: place.lng() } : { lat: +values.latitude, lng: +values.longitude },
					radius: values.radius * areaMasterHelper[values.radiusType?.unit], // Convert km to meters
					strokeColor: "#810FCB",
					strokeOpacity: 1.0,
					strokeWeight: 3.0,
					// fillColor: "#810FCB",
					// fillOpacity: 0.5,
				});
				circle.setMap(mapRef.current);

				// Store this circle as the last circle added.
				lastCircleRef.current = circle;
				if (searchedAreaRef.current !== values?.search) {
					setCenter({
						lat: place.lat(),
						lng: place.lng(),
					});
					setFieldValue("latitude", place.lat());
					setFieldValue("longitude", place.lng());
				}
				searchedAreaRef.current = values?.search
				isFirstRenderRef.current = false

			} else {
				// setToast({ type: "error", msg: `No results found for the area: ${values?.search}` });
			}
		});
	}, [values?.search, values.radius, values.radiusType, isEdit, values.latitude, values.longitude]);

	const computeDeltas = () => {
		if (!mapRef.current) return;
		const bounds = mapRef.current.getBounds();
		const north = bounds.getNorthEast().lat();
		const south = bounds.getSouthWest().lat();
		const east = bounds.getNorthEast().lng();
		const west = bounds.getSouthWest().lng();

		const latitudeDelta = north - south;
		const longitudeDelta = east - west;

		setFieldValue("latitudeDelta", latitudeDelta);
		setFieldValue("longitudeDelta", longitudeDelta);
	};

	return (
		<div className="rounded-[10px] overflow-hidden map-at-zoom">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={10}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{ mapId: process.env.REACT_APP_MAP_ID }}
				version="beta"
				onBoundsChanged={computeDeltas}
				onClick={(event) => {
					setSelectedPoint({
						lat: event.latLng.lat(),
						lng: event.latLng.lng(),
					});
				}}
			>
				{center && isMapLoaded && <Marker position={marker} />}

				{selectedPoint && (
					<InfoWindow
						position={selectedPoint}
						onCloseClick={() => {
							setSelectedPoint(null);
						}}
					>
						<div>
							Latitude: {selectedPoint.lat} <br />
							Longitude: {selectedPoint.lng}
						</div>
					</InfoWindow>
				)}
			</GoogleMap>
		</div>
	);
}

export default MapArea;
