import React, { useEffect, useState } from "react";
import Button from "../../../shared/components/Button.component";
import SelectMenu from "../../../shared/components/SelectMenu.component";
import TableComponent from "../../../shared/components/Table.component";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import Toggle from "../../../shared/components/toggle.component";
import useSelectModal from '../../../utils/hooks/useSelectModal';
import { useAddRadiusTypeMutation, useLazyGetAllRadiusTypesQuery, useUpdateRadiusTypeMutation } from "../../../utils/Services/Apis/areaMasterApi";
import useToaster from "../../../utils/hooks/useToaster";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

const schema = Yup.object()
	.shape({
		radiusType: Yup.string().trim().max(10, 'Radius Type should not exceed 10 characters').required("Radius Type is required"),
		radiusUnit: Yup.object().required("Radius Unit is required"),
	})

export default function RadiusType() {
    const { openModal } = useSelectModal();
    const [radiusType, setRadiusType] = useState([]);
    const [ getAllRadiusList, {data: radiusList, status: radiusListStatus} ] = useLazyGetAllRadiusTypesQuery();
    const [ updateRadiusType ] = useUpdateRadiusTypeMutation();
    const [ addRadiusType, {status: addRadiusStatus} ] = useAddRadiusTypeMutation();
    const { setToast } = useToaster();


    const radiusUnit = [
        { name: "Kilometer", value: "Kilometer" },
        { name: "Meter", value: "Meter" },
    ];
    const columns = [
        {
            header: "List of Radius Types",
            accessorKey: "type",
        },
        {
            header: "Unit",
            accessorKey: "unit",
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 ml-auto mr-16 pl-3">
                        <Toggle 
                            defaultChecked={original.isActive}
							handleOnToggle={() => handleActionToggle(original)}
                        />
                        <img
                            src={EditIcon}
                            alt="edit"
                            className={`h-5 w-5 cursor-pointer`}
                            onClick={() => openEditModal(original)}
                        />
                        <img
                            src={DeleteIcon}
                            alt="delete"
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => openDeleteModal(original)}
                        />
                    </span>
                );
            },
        },
    ];

    const [newRadiusType, setNewRadiusType] = useState({
        radiusType: "",
        unit: "",
    });

    useEffect(() => {
        getAllRadiusList();
    }, [])
    

    const handleAddRadiusType = async (values, { resetForm }) => {
        const {data, error} = await addRadiusType({
            "type": values.radiusType,
            "unit": values.radiusUnit.value
        })
        if(data) {
            setToast({ type: "success", msg: "Radius Type added successfully" })
            resetForm()
        }
        if(error) setToast({ type: "error", msg: error?.data?.message });
        
    };

    const openEditModal = (radiusType) => {
        const status = "radius-type"
        openModal("EditMasterAreaModal", {status, data: radiusType});
    };

    const openDeleteModal = (radiusType) => {
        const status = "radius-type"
        openModal("DeleteMasterAreaModal", {status, data: radiusType})
    }

    const handleActionToggle = (radiusData) => {
        updateRadiusType({
			data: {
				isActive: !radiusData.isActive,
			},
			radiusTypeId: radiusData._id,
		})
			.then((data) => {
				!radiusData.isActive
					? setToast({ type: "success", msg: "Radius Type is Enabled" })
					: setToast({ type: "success", msg: "Radius Type Disabled" });
			})
			.catch((err) => {
				setToast({ type: "error", msg: err.data.message });
			});
    }

    return (
        <Formik
			validationSchema={schema}
			initialValues={{
				radiusType: "",
                radiusUnit:""
			}}
            onSubmit={handleAddRadiusType}
		>
			{({ values, setFieldValue, touched, errors }) => (
				<Form action="#" method="POST">
					<div>
            <div className="flex items-end  pl-8">
                <div className="mr-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        Add Radius Type
                    </label>
                    <Field
                        name="radiusType"
                        type="text"
                        className="block w-[27.5vw] rounded-md border border-gray-300 px-3 2xl:py-2 py-1 placeholder-gray-400 focus:border-gray-300 focus:outline-none text-sm h-[3rem]  focus:ring-transparent "
                        placeholder="Enter Radius Type to add in master data "
                        onKeyPress= {(event) => {
                            if (/[^a-zA-Z ]/.test(event.key)) {
							return event.preventDefault();
							}
						}}
                        // value={newRadiusType.radiusType}
                        // onChange={(e) => setNewRadiusType({ ...newRadiusType, radiusType: e.target.value })}
                    />
                    {touched.radiusType && errors.radiusType ? (
                            <div className="text-red-600 text-sm">{errors.radiusType}</div>
                        ) : (
                            <div className="invisible h-5"></div>
					)}
                </div>
                <div className="mr-4">
                    <SelectMenu
                        label="Radius Unit"
                        name="radiusUnit"
                        options={radiusUnit}
                        placeholder="Select Radius Unit"
                        multiSelectClass="h-[3rem] radius-unit-ht w-[14vw]"
                        onChange={(option) => setFieldValue("radiusUnit", option)}
						value={values.radiusUnit}
                    />
                    {touched.radiusUnit && errors.radiusUnit ? (
                            <div className="text-red-600 text-sm">{errors.radiusUnit}</div>
                        ) : (
                            <div className="invisible h-5"></div>
					)}
                </div>
                <Button
                    className="2xl:text-base text-sm text-white cursor-pointer reb-btn-shadow 2xl:px-7 px-4 mb-5 w-[calc(142px+3.698vw)]"
                    type="submit"
                    isSubmitting={addRadiusStatus === "pending"}
                >
                    Add Radius Type
                </Button>
            </div>
            <div className="bg-white p-5 mx-8 mb-3 mt-8 pb-2 h-[58vh] overflow-hidden brand-details-section radius-type-bg-white relative">
                <div className="w-full mb-10">
                    <TableComponent
                        data={radiusList}
                        columns={columns}
                        customwidths={["w-[33.33%]", "w-[33.33%]", "w-[33.33%]"]}
                        isActionColumnTable={true}
                        customeClass="max-h-[48vh] 2xl:max-h-[50vh] radius-type-table pr-3"
                        isLoading = {radiusListStatus === "pending"}
                        customeClassAction = "action-col-alignment"
                    />
                </div>

                {/* <div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
                    <div className=''>
                        <Button className='w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px]'>Previous</Button>
                    </div>
                    <div className=''>
                        <Button className='w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px]'>Next</Button>
                    </div>
                    <div className=''>
                        <p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>1</span> of 9</p>
                    </div>
                </div> */}
            </div>
            </div>
			</Form>
			)}
	    </Formik>
    )
}