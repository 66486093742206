import React, { useEffect } from 'react'
import CheckIcon from "../../../assets/icons/check.svg";
import Button from '../../../shared/components/Button.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import { useUpdateFranchiseStatusMutation } from '../../../utils/Store';
import { useParams } from 'react-router-dom';
import useToaster from '../../../utils/hooks/useToaster';

function ApproveFranchiseModal({ data }) {

    const { openModal, closeModal } = useSelectModal();
    const [updateFranchiseStatus] = useUpdateFranchiseStatusMutation();
    const { setToast } = useToaster();

    const handleOnClick = async () => {
        openModal("ApprovedFranchiseModal", { isFromApproveFranchise: true, franchiseName: data?.franchiseName, fetchFranchise:data?.fetchFranchise  })

        const franchiseData = {
            status: data?.franchiseStatus === 'pending' ? 'approved' : data?.franchiseStatus,
            comment: ""
        }

        const { data: d, error } = await updateFranchiseStatus({
            id: data?.franchiseId,
            body: franchiseData,
        });
        if (d) {
            setToast({ type: "success", msg: "Franchise approved successfully" })
            
        }
        if (error) setToast({ type: "error", msg: error?.data?.message });

        return;
    }


    return (
        <div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center justify-center">
                <div class=" bg-[#C3FFD1] h-[4rem] w-[4rem] rounded-full flex justify-center items-center ">
                    <img className='h-[2rem] w-[2rem]' src={CheckIcon} />
                </div>
            </div>
            <div className="">
                <h1 className='flex font-inter-medium justify-center pt-4 text-lg text-center'>Approve</h1>
            </div>
            <div className="pb-6 pt-0.5 text-center">
                <span className="text-[#87898E] text-center text-lg">Are you sure you want to approve "{data?.franchiseName}" </span>
            </div>
            <div className="flex justify-between ">
                <Button
                    className="w-[47.5%] bg-[#F1F1F1] text-[#23262F] cursor-pointer rounded-lg"
                    onClick={closeModal}
                >
                    Close
                </Button>
                <Button
                    className="w-[47.5%] text-white cursor-pointer rounded-lg"
                    type="submit"
                    onClick={handleOnClick}
                    // isSubmitting={status === "pending"}
                >
                    Approve
                </Button>
            </div>
        </div>
    )
}

export default ApproveFranchiseModal