import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import RiderManagementTabs from '../Rider_Management_Tabs'
import RiderServicesCard from './Rider_Services_Card';
import { useLazyGetOrderOfRiderByStatusQuery, useLazyGetRidersOrderCountQuery } from '../../../utils/Store';
import useToaster from "../../../utils/hooks/useToaster";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRef } from "react";

function RiderServices({ areasId }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [ordersData, setOrdersData] = useState([]);
    const { setToast } = useToaster();
    const [getOrderOfRiderByStatus, { data: orderOfRiderByStatus }] = useLazyGetOrderOfRiderByStatusQuery();
    const [getRidersOrderCount, { data: ridersOrderCount }] = useLazyGetRidersOrderCountQuery()
    const { riderId } = useParams();

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const isLoadingRef = useRef(false);

    useEffect(() => {
        if (riderId) {
            getRidersOrderCount({ riderId });
        }
    }, [])

    useEffect(() => {
        fetchInitialData();
    }, [selectedIndex, riderId]);

    const fetchInitialData = async () => {
        isLoadingRef.current = true
        let data, error;
        if(riderId){
            ({ data, error } = await getOrderOfRiderByStatus({
                status: riderServiceCategories[selectedIndex].status,
                riderId: riderId,
                params: { page: 1 },
            }));
        }
        if (data) {
            setOrdersData(data.orders);
            setCurrentPage(2);
            setHasMoreRecords(data.orders.length > 0);
            isLoadingRef.current = false
        }
        if(error) {
            setToast({ type: "error", msg: error.data.message });
            isLoadingRef.current = false
        }
    };

    const fetchMoreData = async () => {
        let data, error;
        if(riderId){
            ({ data, error } = await getOrderOfRiderByStatus({
                status: riderServiceCategories[selectedIndex].status,
                riderId: riderId,
                params: { page: currentPage },
            }));
        }
        if (data) {
            setOrdersData(prev => [...prev, ...data.orders]);
            setCurrentPage(prev => prev + 1);
            setHasMoreRecords(data.orders.length > 0);
        }
        if (error) {
            setToast({ type: "error", msg: error.data.message });
        }

    };

    // useEffect(() => {
    //     if (orderOfRiderByStatus) {
    //         setOrdersData(orderOfRiderByStatus.orders);
    //     }
    // }, [orderOfRiderByStatus]);

    const renderCards = (status) => {
        if (ordersData?.length) {
            return ordersData.map((order, index) => (
                <RiderServicesCard
                    status={status}
                    orderDetails={order}
                    key={order.id || index}
                />
            ));
        } else {
            return (
                <div className="h-96 w-full justify-center flex items-center">
                    <p>No Data Found</p>
                </div>
            )
        }
    };

    let riderServiceCategories = [
        {
            title: `All Services (${(ridersOrderCount?.all) || "00"})`,
            status: "all",
            component: renderCards("all")
        },
        {
            title: `Upcoming Services (${(ridersOrderCount?.upcoming) || "00"})`,
            status: "upcoming",
            component: renderCards("upcoming")
        },
        {
            title: `In Progress Services (${(ridersOrderCount?.ongoing) || "00"})`,
            status: "ongoing",
            component: renderCards("ongoing")
        },
        {
            title: `Completed Services (${(ridersOrderCount?.completed) || "00"})`,
            status: "completed",
            component: renderCards("completed")
        },
    ]
    
    let infiniteHightScroll = {
        height : () => {
            if(window.innerWidth < 1679){
                return '42vh'
            }else {
                return '30vh'
            }
        }
    }

    return (
        <div>
            <RiderManagementTabs
                categories={riderServiceCategories}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                className='w-fit'
            />
            {
                <div className='mt-3'>
                    <InfiniteScroll
                        hasMore={hasMoreRecords}
                        next={fetchMoreData}
                        height={infiniteHightScroll}
                        scrollThreshold={0.8}
                        dataLength={ordersData.length}
                        className='2xl:max-h-[30vh] lg:max-h-[40vh]'
                    >
                        {riderServiceCategories[selectedIndex]?.component}
                    </InfiniteScroll>
                </div>
            }
        </div>
    )
}

export default RiderServices