import React from 'react'
import HomeRefresh1 from "../../../assets/icons/home-refresh-1.png";
import Button from '../../../shared/components/Button.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import moment from 'moment';

function RenewedFranchiseModal({data}) {

    const { closeModal } = useSelectModal();

    return (
        <div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center justify-center">
                <div class=" bg-[#FF621F] h-[4rem] w-[4rem] rounded-full flex justify-center items-center ">
                    <img className='h-[2rem] w-[2rem]' src={HomeRefresh1} />
                </div>
            </div>
            <div className="">
                <h1 className='flex font-inter-medium justify-center pt-4 text-lg text-center'>Renewed</h1>
            </div>
            <div className="py-1 text-center">
                <span className="text-[#87898E] text-center text-lg"> "{data.name}" franchise has been <br/> successfully renewed</span>
            </div>
            <div className=''>
                <p className='flex font-inter-medium justify-center pb-6 text-lg text-center'>Renewal Date - {moment(new Date()).format("DD MMMM, YYYY")}</p>
            </div>
            <div className="flex justify-center ">
                <Button
                    className="w-[47.5%] text-[#FFF] cursor-pointer rounded-lg"
                    onClick={closeModal}
                >
                    Close
                </Button>
            </div>
        </div>
    )
}

export default RenewedFranchiseModal
