import React from 'react';
import RadioButton from '../../shared/components/RadioButton';
import Button from '../../shared/components/Button.component';
import { Field, Form, Formik, useFormikContext } from "formik";
import { Data, RepeateMusic } from 'iconsax-react';
import useToaster from "../../utils/hooks/useToaster";
import * as Yup from "yup";
import useSelectModal from '../../utils/hooks/useSelectModal';
import FormField from '../../shared/components/FormField.component';
import { usePaymentLinkMutation } from '../../utils/Store';

const schema = Yup.object()
	.shape({
		dueAmount: Yup.number().min(1, 'Due amount should be greater than zero').notRequired(),
		partialAmount: Yup.number(),
		type:Yup.string()
	})
	.test("partialAmount amount should not be greater than total Due amount", function (value) {
		if (value.dueAmount < value.partialAmount && value.type ==="partial") {
			return this.createError({
				path: "partialAmount",
				message: "Partial amount should not be greater than Due amount",
			});
		}
		return true; // Validation passed
	})
	.test("partialAmount amount required", function (value) {
		if (value.type === "partial" && !!!value.partialAmount) {
			return this.createError({
				path: "partialAmount",
				message: "Partial Amount amount should be greater than zero",
			});
		}
		return true; // Validation passed
	});

function ClearDueAmountModal({data}) {
	const { openModal, closeModal } = useSelectModal();
	const [sendPaymentLink,{status}] = usePaymentLinkMutation()
	const { setToast } = useToaster();

	const handleUpdateAppointment = async(values) => {
		let amount = data?.dueAmount
		if(values.type === "partial") amount= values.partialAmount

		const {data: d, error} =await sendPaymentLink(
			{orderId:data?.orderId , customerId:data?.customerId , amount:amount}
		)
		if(d) openModal("paymentLinkSuccessModal")
		if(error) {
			setToast({ type: "error", msg: error.data.message })
			closeModal();
		};
	}
	
	return (
		<Formik
			validationSchema={schema}
			initialValues={{
				dueAmount: data?.dueAmount,
				partialAmount: "",
				type: "partial"
			}}
		onSubmit={handleUpdateAppointment}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form action="#" method="POST">
					<ClearDueAmount dueAmount={data?.dueAmount} status={status}/>
				</Form>
			)}
		</Formik>
	)
}

export default ClearDueAmountModal;

const ClearDueAmount = ({dueAmount, status}) => {
	const { values, setFieldValue, touched, errors } = useFormikContext();
	

	return (
		<div className="2xl:w-[35vw] w-[40vw] p-6 px-16 bg-[#FFFFFF] rounded-xl pb-8">
			<div className=" justify-start pt-4  pb-4">
				<h1 className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Select method to Settle the Due Amount</h1>
			</div>
			<div className="gap-3 items-center py-3">
				<div className="w-full h-full">
					<div className="flex items-center justify-between w-full gap-4 font-normal text-sm">
						<div className="py-2 px-4 w-full bg-[#D9D9D9] bg-opacity-20 rounded-lg border">
							<label className="flex items-center gap-2">
								<RadioButton name="type" value="partial"/>
								<span className="text-[#000000] font-regular 2xl:text-base text-sm">Pay Partial Amount</span>
							</label>
						</div>
						<div className="py-2 px-4 w-full bg-[#D9D9D9] bg-opacity-20 rounded-lg border">
							<label className="flex items-center gap-2">
								<RadioButton name="type" value="full"/>
								<span className="text-[#000000] font-regular 2xl:text-base text-sm">Pay Full Amount</span>
							</label>
						</div>
					</div>
				</div>
				{/* <div className="">
							<Field
								placeholder="Write Note"
								name="note"
								as="textarea"
								className="w-full h-40 rounded-[10px] border bg-[#D9D9D9] bg-opacity-20 border-[#D9D9D9] px-3 py-3 placeholder-gray-400 text-base focus:outline-none focus:border-[#D9D9D9] focus:ring-transparent "
							/>
							{touched.note && errors.note ? (
								<div className="text-red-600 text-sm">{errors.note}</div>
							) : (
								<div className="invisible h-5"></div>
							)}
						</div> */}
				<div className="flex justify-between pt-8  pb-3">
					<p className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Total Due Amount</p>
					<p className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">₹ {dueAmount}</p>
				</div>
				{ values.type === 'partial' &&
					(
						<div className="pt-8 pb-3">
							<p className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Add Partial Paybale Amount</p>
							<p className='text-[#717171] font-inter-regular 2xl:text-[14px] text-[10px] pt-4'>Enter partial payble amount</p>

							<div className='h-[3rem] mt-2'>
								<FormField
									field={{
										name: "partialAmount",
										type: "text",
										placeholder: "Enter Amount",
										customClassName: "font-inter-medium",
										onKeyPress: (event) => {
												if (/[^0-9]/.test(event.key)) {
													return event.preventDefault();
												}
										},
									}}
								/>
							</div>

							<div className="flex justify-between pt-8  pb-3">
								<p className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Total Settled Amount</p>
								<p className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">{values.partialAmount && "₹"} {values.partialAmount}</p>
							</div>
						</div>
					)
				}
				<div className="flex justify-center pt-8 pb-1">
					<Button
						className="w-full text-white cursor-pointer bottom-btn-ht text-base font-inter-medium"
						type="submit"
						isSubmitting={status === "pending"}
					>
						Send Payment Link to Customer
					</Button>
				</div>
			</div>
		</div>
	)
}