import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const ridersApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAvailableRiders: builder.mutation({
				query: (body) => ({
					url: endpoints.timeSlot.getRiderInTimeSlot,
					body: body,
					method: "POST",
				}),
			}),
			getTimeSlotPagination: builder.query({
				query: ({pageNo, pageSize}) => {
					return {
						url: endpoints.timeSlot.getTimeSlotPagination(pageNo, pageSize),
						method: "GET",
					};
				},
				providesTags: ["Time Slot"],
			}),
			getAvailableTimeSlots: builder.mutation({
				query: (body) => ({
					url: endpoints.timeSlot.getAvailableTimeSlots,
					body: body,
					method: "POST",
				}),
			}),
			reschedulingTimeSlot: builder.mutation({
				query: (body) => ({
					url: endpoints.timeSlot.reschedulingTimeSlot,
					body: body,
					method: "POST",
				}),
			}),
		};
	},
});

export const { 
    useGetAvailableRidersMutation,
    useGetAvailableTimeSlotsMutation,
	useReschedulingTimeSlotMutation,
	useLazyGetTimeSlotPaginationQuery,
} = ridersApi;
export { ridersApi };
