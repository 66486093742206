import { useState } from "react";
import Select, { components } from "react-select";

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: " 1px solid rgba(233, 233, 233, 1)", // set border color to gray
        backgroundColor: "#F1F1F1",
        boxShadow: "none", // remove box shadow
        display: "flex",
        borderRadius: "0.625rem",
        height: "3.1rem",
        width: "12.5vw",
        paddingLeft: "1.5vw",
        fontSize: "14px",
        "&:hover": {
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize:"font-inter-medium",
        color:"#000",
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        border: "none",
        maxHeight: '200px'
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: "none", // Removes the divider
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "blue" : "transparent",
    }),
};

const InputOption = ({
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    inputType,
    showCheckboxOnRight,
    showCheckbox,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    let bg = "transparent";
    if (isFocused) bg = "#eee";


    const style = {
        appearance: 'none',
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
        justifyContent: showCheckboxOnRight ? "space-between" : "",
        flexDirection: (showCheckboxOnRight && showCheckbox) ? "row-reverse" : "row",
        fontSize: "14px",
    };

    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style,
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            {showCheckbox && <input type={inputType} checked={isSelected} readOnly style={{ marginRight: 10 }} />}
            {children ? children?.length < 22 ? children : children + '...' : ""}
        </components.Option>
    );
};

export default function SelectMenuIcon({
    options,
    value,
    onChange,
    name,
    isMulti = false,
    placeholder = "Select",
    optionLabel = "name",
    isClearable,
    disabled,
    showCheckboxOnRight = true,
    inputType = "checkbox",
    showCheckbox = true,
    customeClassName,
    hideSelectedOptions = true,
    isLoading = false,
    multiSelectClass
}) {

    const WrappedInputOption = (props) => {
        return <InputOption {...props} showCheckboxOnRight={showCheckboxOnRight} inputType={inputType} showCheckbox={showCheckbox} />
    }
    return (
        <div>
            <div className="select-class-custom-class flex">
                <Select
                    getOptionValue={(option) => (option._id ? option._id : option[optionLabel])}
                    getOptionLabel={(option) => option[optionLabel]}
                    options={options}
                    isMulti={isMulti}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={`${multiSelectClass}`}
                    classNamePrefix="select"
                    styles={customStyles} // Apply the custom styles here
                    components={{
                        Option: WrappedInputOption,
                    }}
                    isClearable={isClearable}
                    isDisabled={disabled}
                    hideSelectedOptions={hideSelectedOptions}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}