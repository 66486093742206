import React, { useState } from 'react'
import Button from '../../../shared/components/Button.component'
import useSelectModal from '../../../utils/hooks/useSelectModal'
import { useNavigate } from 'react-router-dom';

function CreateNewFranchiseModal() {

    const { closeModal } = useSelectModal();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('masterFranchise');

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

    const handleNextButtonClick = () => {
        navigate("/franchise-management/new-franchise", {state : selectedOption});
        closeModal();
    };

    return (
        <div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center">
                <h1 className='flex font-inter-bold items-center pt-1 text-lg'>Create New Franchise</h1>
            </div>
            <div className="">
                <h1 className='flex font-inter-normal items-center pt-4 text-base'>Choose Type of Franchise you want to create</h1>
            </div>
            <div className="space-y-4 pt-4">
                <label className="flex items-center border border-[#D9D9D9] p-2 rounded-lg">
                    <input
                        checked={selectedOption === 'masterFranchise'}
                        onChange={handleRadioChange}
                        type="radio"
                        name="renewal"
                        value="masterFranchise"
                        className="mr-2 mt-1.5 items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent hover:text-[#757575] md:mt-0 sm:mt-0"
                    />
                    <span className="text-[#757575] font-normal">Master Franchise</span>
                </label>
                <label className="flex items-center border border-[#D9D9D9] p-2 rounded-lg">
                    <input
                        checked={selectedOption === 'franchise'}
                        onChange={handleRadioChange}
                        type="radio"
                        name="renewal"
                        value="franchise"
                        className="mr-2 mt-1.5 items-center checked:bg-[#FB0C0C] focus:text-[#FB0C0C] focus:ring-transparent hover:text-[#757575] md:mt-0 sm:mt-0"
                    />
                    <span className="text-[#757575] font-normal">Franchise</span>
                </label>
            </div>
            <div className="flex justify-center pt-6">
                <Button
                    className="w-[47.5%] text-[#FFF] cursor-pointer rounded-lg"
                    onClick={handleNextButtonClick}
                >
                    Next
                </Button>
            </div>
        </div>
    )
}

export default CreateNewFranchiseModal
