import React from "react";
import useSelectModal from "../../utils/hooks/useSelectModal";

export default function ImageThumbnail({
	imgUrls = []
}) {
	const { openModal } = useSelectModal();
	// if (!imgUrls?.length) return;
	return (
		<span className="cursor-pointer" onClick={() => imgUrls[0] ? openModal("PreviewImages", imgUrls) : ''}>
			{imgUrls?.length > 2 ? (
				<span className="flex cursor-pointer">
					<div className="rounded-xl border 2xl:h-12 2xl:w-12 h-10 w-10 lg:w-8 lg:h-8 -rotate-12 mt-2 overflow-hidden">
						<img src={imgUrls[0]?.imgUrl} alt="" className="h-12 w-12"></img>
					</div>
					<div className="rounded-xl border 2xl:h-12 2xl:w-12 h-10 w-10 lg:w-8 lg:h-8 -mx-3 z-10 relative overflow-hidden">
						<img
							src={imgUrls[1]?.imgUrl}
							alt=""
							className="h-12 w-12 backdrop-brightness-50 bg-white/30"
						></img>
						<span className="absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 text-white font-bold text drop-shadow-md">
							{imgUrls?.length > 3 ? "+" + (imgUrls?.length - 3) : ""}
						</span>
					</div>
					<div className="rounded-xl border 2xl:h-12 2xl:w-12 h-10 w-10 lg:w-8 lg:h-8 rotate-12 mt-2 overflow-hidden">
						<img src={imgUrls[2]?.imgUrl} alt="" className="h-12 w-12 "></img>
					</div>
				</span>
			) : (
				imgUrls[0] ?
				<div className="rounded-xl border h-12 w-12 -mx-3 z-10 relative overflow-hidden">
					<img src={imgUrls[0]?.imgUrl} alt="" className="h-12 w-12 opacity-50"></img>
					<span className="absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 text-white font-bold text">
						{imgUrls?.length > 1 ? "+" + (imgUrls?.length - 1) : ""}
					</span>
				</div> : <span className="text-base font-inter-bold text-[#898C81]">nil</span>
			)}
		</span>
	);
}
