
import React, { useEffect, useState } from 'react'
import TableComponent from '../../../shared/components/Table.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import Toggle from '../../../shared/components/toggle.component';
import EditIcon from '../../../assets/icons/Edit.png'
import DeleteIcon from '../../../assets/icons/Delete.png'
import Button from '../../../shared/components/Button.component';
import TimePicker from '../../../shared/components/TimePicker.component';
import moment from 'moment';
import { ArrowDown2 } from 'iconsax-react';
import { useAddTimeSlotMutation, useLazyGetAllTimeSlotsQuery, useUpdateTimeSlotMutation, useLazyGetTimeSlotPaginationQuery } from '../../../utils/Services/Apis/areaMasterApi';
import useToaster from '../../../utils/hooks/useToaster';
import {useParams } from "react-router-dom";
import { result } from 'lodash';


const Timeslot = () => {

    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const { setToast } = useToaster();

    const { openModal } = useSelectModal();

    const [selectedTime1, setSelectedTime1] = useState(moment('12:00 AM', 'h:mm A'));
    const [selectedTime2, setSelectedTime2] = useState(moment(selectedTime1).add(15, 'minutes'));
    const [isTimeDiffIsGreaterThan4Hrs, setIsTimeDiffIsGreaterThan4Hrs] = useState(false);


    useEffect(() => {
        setSelectedTime2(moment(selectedTime1).add(15, 'minutes'));
    }, [selectedTime1]);

    useEffect(()=>{
        calculateTimeDifference(selectedTime2, selectedTime1);
    },[selectedTime2])
    // const [getAllTimeSlotList, { data: timeSlotList }] = useLazyGetAllTimeSlotsQuery();
    const [getTimeSlotPagination, {data: timeSlotPaginationList, status: timeSlotPaginationStatus}] = useLazyGetTimeSlotPaginationQuery();
    const { slotId } = useParams();
    const [updateTimeSlot] = useUpdateTimeSlotMutation();
    const [addTimeSlot, { status: addTimeSlotStatus }] = useAddTimeSlotMutation();

    useEffect(() => {
		getAllTimeSlots();
	}, [pageNo]);

	const getAllTimeSlots = async () => {
        
        getTimeSlotPagination({pageNo, pageSize});
        
	};


    const columns = [
        {
            header: "List of Assigned Time Slots",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center w-full">
                        <span className='font-inter-medium'>{original.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 2xl:ml-10">
                        <Toggle
                            defaultChecked={original.isActive}
                            handleOnToggle={() => handleActionToggle(original)}
                        />

                        <img
                            src={DeleteIcon}
                            alt=""
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => openDeleteModal(original)}
                        />
                    </span>
                );
            },
        },
    ]

    function calculateTimeDifference(time1, time2) {
    const date1 = moment(time1);
    const date2 = moment(time2).add(1, "minute");
    const differenceMs = date2 - date1;
    const differenceHours = Math.abs(differenceMs / 36e5); // 1 hour = 36e5 milliseconds
    if (differenceHours < 4) {
        setIsTimeDiffIsGreaterThan4Hrs(false)
    } else {
        setIsTimeDiffIsGreaterThan4Hrs(true)
    }
    }

    const openEditModal = (timeSlot) => {
        const status = "time-slot";
        openModal("EditMasterAreaModal", { status, data: timeSlot });
    };


    const openDeleteModal = (timeSlot) => {
        const status = "time-slot";
        openModal("DeleteMasterAreaModal", { status, data: timeSlot })
    }


    const handleActionToggle = (timeSlotData) => {
        updateTimeSlot({
            data: {
                isActive: !timeSlotData.isActive,
            },
            slotId: timeSlotData?._id,
        })
            .then((data) => {
                !timeSlotData.isActive
                    ? setToast({ type: "success", msg: "Time Slot is Enabled" })
                    : setToast({ type: "success", msg: "Time Slot Disabled" });
            })
            .catch((err) => {
                setToast({ type: "error", msg: err.data.message });
            });
    }

    const handlePageChange = (type) => {
        let tempCurrentPage = pageNo;
        if (type === 'prev') {
            tempCurrentPage -= 1;
        } else {
            tempCurrentPage += 1;
        }
        setPageNo(tempCurrentPage);
    }
    
    const handleAddTimeSlot = async() => {
        if(isTimeDiffIsGreaterThan4Hrs) return setToast({ type: "error", msg: "Please Select Appropriate Time" });  
        const {data: timeSlotData, error} =  await addTimeSlot({ from: moment(selectedTime1).format("LT").toLocaleLowerCase(), to: moment(selectedTime2).format("LT").toLocaleLowerCase() })
        if(timeSlotData)  {
            setToast({ type: "success", msg: "Time Slot is Added successfully" })
            getTimeSlotPagination({pageNo:1 , pageSize})
            setSelectedTime1(moment('12:00 AM', 'h:mm A'))
        }   
        if(error) setToast({ type: "error", msg: error.data.message });                               
    }
    

    return (
        <div className="bg-[#F5F5F5] h-full">
            <div className="space-y-3">
                <div className='w-[50%] flex items-center justify-center mx-6 pb-3'>
                    <div className='px-2 w-[100%] pt-1'>
                        <h1 className='font-inter-regular text-[#111827] text-sm'>From</h1>
                        <div className="relative">
                            <TimePicker
                                selectedTime={selectedTime1}
                                setSelectedTime={setSelectedTime1}
                                onChange={setSelectedTime2}
                            />
                            {/* <ArrowDown2 className='absolute right-2 top-[50%] -translate-y-1/2 dropdown-icon-ht' /> */}
                        </div>
                    </div>
                    <div className='px-2 w-[100%] pt-1'>
                        <h1 className='font-inter-regular text-[#111827] text-sm'>To</h1>
                        <div className="relative">
                            <TimePicker
                                selectedTime={selectedTime2}
                                setSelectedTime={setSelectedTime2}
                                disabledTime={selectedTime1}
                                disabled={!selectedTime2}
                                onChange={setSelectedTime2}
                                placeholder="Select To Time"
                            />
                            {/* <ArrowDown2 className='absolute right-2 top-[50%] -translate-y-1/2 dropdown-icon-ht' /> */}
                        </div>
                        {/* <span>{isTimeDiffIsGreaterThan4Hrs ? "true" : "false"}</span> */}
                    </div>
                    <div className='pl-6 pt-6 w-[100%]'>
                        <Button
                            className="w-[100%] h-[2.9rem] text-[#FEFEFE] text-base cursor-pointer font-inter-regular rounded-[10px]"
                            isSubmitting={addTimeSlotStatus === "pending"}
                            onClick={handleAddTimeSlot}
                        >
                            Add Time Slot
                        </Button>
                    </div>
                </div>
                <div className="bg-white p-5 mx-8 my-3 pb-2 h-[60vh] overflow-hidden brand-details-section bg-white-height relative">
                    <div className='w-full'>
                        <TableComponent
                            data={timeSlotPaginationList?.timeslots}
                            columns={columns}
                            customwidths={["w-[85%]", "w-[15%]"]}
                            isActionColumnTable={true}
                            customeClass='max-h-[43vh] table-height-sm pr-3'
                            isLoading = {timeSlotPaginationStatus === "pending"}
                        />
                    </div>
                    {timeSlotPaginationList?.totalPages > 1 &&
                        <div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
                            <div className=''>
                                <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`} disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
                            </div>
                            <div className=''>
                                <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == timeSlotPaginationList?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`} disabled={pageNo == timeSlotPaginationList?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
                            </div>
                            <div className=''>
                                <p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {timeSlotPaginationList?.totalPages}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Timeslot
