
export default function FileSelectButton({ selectedFiles, setSelectedFiles, accept, classNames }) {

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files).map(file => file));
    };

    return (
        <div className="relative inline-block w-full">
            <div
                id="file-upload"
                className={`w-full pr-5 pl-3 py-1 text-gray-700 bg-white border h-[2.4rem] rounded shadow appearance-none hover:cursor-pointer truncate ${classNames}`}
                onClick={() => document.getElementById('fileInput').click()}
            >
                {selectedFiles.length > 0 ? selectedFiles?.map((file => file.name)).join(', ') : 'Select files...'}
                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M10 12l-6-6h12l-6 6z" />
                    </svg>
                </div>
            </div>
            <input
                accept={accept}
                type="file"
                id="fileInput"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    );
}