
import React, { useState, useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Field, useFormikContext } from "formik";
import useToaster from "../../utils/hooks/useToaster";
function GMapAutocomplete({ cityName = "", name, fullCityName }) {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const { setToast } = useToaster();
  const [autocomplete, setAutocomplete] = useState(null);
  const [bounds, setBounds] = useState(null);
  const autocompleteRef = useRef();
  const searchedPlaceRef = useRef(values[name]);
  const onLoad = (ref) => {
    setAutocomplete(ref);
  };
  const onPlaceChanged =async () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      const location = place.geometry.location;
      setFieldValue("location", location)
      // Convert the bounds state into a LatLngBounds object
      const latLngBounds = new window.google.maps.LatLngBounds(
        { lat: bounds.south, lng: bounds.west },
        { lat: bounds.north, lng: bounds.east }
      );

      const match = place.adr_address.match(/<span class="locality">([^<]+)<\/span>/)
      const locality = match ? match[1] : null;
    if (!locality || !locality?.includes(cityName) || !latLngBounds.contains(location)) {
      setToast({
        type: "error",
        msg: `Please select a location within the ${cityName} city`
      });
      setFieldValue(name, ""); // updated from "search" to the prop "name"
      setFieldTouched(name, false);
      return;
    }
			const addressToDisplay = place.formatted_address.includes(place.name) ? place.formatted_address : place.name+ ", " + place.formatted_address;      
      setFieldValue(name, addressToDisplay); // updated from "search" to the prop "name"
      searchedPlaceRef.current = addressToDisplay
    }
  };

  const handleBlur = () => {
    if (searchedPlaceRef.current !== values[name]) {
      setFieldValue(name, "");
      setFieldTouched(name, false);
    }
  };

  useEffect(() => {
    if (!cityName) return;
    // Get bounds only once
    getCityBounds(cityName)
      .then((bounds) => {
				setBounds(bounds)
			})
      .catch((error) => console.error(error));
  }, [cityName]);
  
  async function getCityBounds(cityName) {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${fullCityName}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.status !== "OK") {
      throw new Error("Failed to get city bounds");
    }
    const viewport = data.results[0].geometry.viewport;
    return {
      south: viewport.southwest.lat,
      west: viewport.southwest.lng,
      north: viewport.northeast.lat,
      east: viewport.northeast.lng,
    };
  }

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        Search Area
      </label>
      <Autocomplete
        bounds={bounds}
        ref={autocompleteRef}
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        // options={{ types: ["sublocality"] }}
      >
        <Field
          name={name}
          onBlur={handleBlur}
          type="text"
          placeholder="Search location"
          className={`mt-1 block w-full appearance-none rounded-md border h-10 border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
        />
      </Autocomplete>
    </div>
  );
}
export default GMapAutocomplete;
