import React from 'react';
import HyndaiVenue from "../../../assets/images/cars/hyundai-venue-img.png";
import { GasStation, Lifebuoy } from 'iconsax-react';
import KeyboardIcon from "../../../assets/icons/small-keyboard.svg";
import { IoLocationOutline } from 'react-icons/io5';
import EditIcon from "../../../assets/icons/Edit.png";
import useSelectModal from '../../../utils/hooks/useSelectModal';


function VehiclesCard({ isVehicleCountCard, isVehicleServiceModal, vehicleData, selectedValue, fetchVehicleDetails }) {
    const { openModal } = useSelectModal();
    const handleDefaultAddress = (addressList) => {
        let defaultAddress = addressList && addressList.find((addrs) => addrs.defaultAddress);
        return defaultAddress ? (<p className='font-inter-regular text-base text-[#474747] vehicle-card-text'>
            {defaultAddress?.addressLineOne && <span>{defaultAddress?.addressLineOne}</span>}
            {defaultAddress?.addressLineTwo && <span>, {defaultAddress?.addressLineTwo}</span>}
            {defaultAddress?.landMark && <span>, {defaultAddress?.landMark}</span>}
            {defaultAddress?.areaName && <span>, {defaultAddress?.areaName}</span>}
            {defaultAddress?.cityName && <span>, {defaultAddress?.cityName}</span>}
            {defaultAddress?.pincode && <span>, {defaultAddress?.pincode}</span>}
        </p>) : '-'
    }

    const handleEdit = (vehicleData) => {
        openModal("EditVehicleModal",{...vehicleData, fetchVehicleDetails:fetchVehicleDetails});
    };
    return (
        <div>
            <div className={`border-[#DDD] bg-white overflow-hidden ${isVehicleServiceModal ? 'border-0 border-b rounded-none' : selectedValue?.name === "All Vehicles" ? 'border-0 border-b rounded-none' : 'border rounded-[14px]'} ${isVehicleCountCard ? 'mb-4' : 'mb-0'}`}>
                <div className={`py-3 px-8 flex items-center ${isVehicleCountCard ? 'justify-between border-b' : 'car-card-shadow'}`}>
                    <div className='flex items-center'>
                        <h1 className='font-inter-bold 2xl:text-[22px] text-[20px] vehicle-card-heading'>{vehicleData?.vehicleBrand} {vehicleData?.vehicleModel}</h1>
                        <p className='font-inter-regular 2xl:text-[20px] text-[18px] mx-8 vehicle-card-heading'>{vehicleData?.vehicleType}</p>
                        {
                            (!isVehicleCountCard && !isVehicleServiceModal) && (
                                <p className='font-inter-regular 2xl:text-[20px] text-[18px] vehicle-card-heading'>Total Services Booked <span>({vehicleData?.orderCount})</span></p>
                            )
                        }
                    </div>
                    {
                        (isVehicleCountCard) && (
                            <div>
                                <p className='font-inter-medium 2xl:text-[20px] text-[18px] vehicle-card-heading'>No. of Services Availed : {vehicleData?.orderCount}</p>
                            </div>
                        )
                    }
                </div>
                <div className='py-2 px-8 flex items-center'>
                    <div className='w-[45%] flex justify-between items-center'>
                        <div className='w-[8.2rem] h-[5.2rem] '>
                            <img className='w-full h-full object-contain' src={vehicleData?.vehicleModelImage} alt="Model image" />
                        </div>
                        <div className='flex items-center'>
                            <GasStation size={22} color="#000" className="icon-size-14px" />
                            <p className='text-base text-[#474747] font-inter-medium ml-3 vehicle-card-text'>{vehicleData?.fuelType}</p>
                        </div>
                        <div className='flex items-center'>
                            <Lifebuoy size={22} color="#000" className="icon-size-14px" />
                            <p className='text-base text-[#474747] font-inter-medium ml-3 vehicle-card-text'>{vehicleData?.transmissionType}</p>
                        </div>
                        <div className='flex items-center'>
                            <img src={KeyboardIcon} className="w-6 h-6 icon-size-14px" />
                            <p className='text-base text-[#474747] font-inter-medium ml-3 vehicle-card-text'>{vehicleData?.vehicleNumber}</p>
                        </div>
                        {
                            !isVehicleServiceModal && (
                                <div className='h-[3vw] my-auto w-[1px] bg-[#DEDEDE]'></div>
                            )
                        }

                    </div>
                    <div className='w-[55%] flex items-center ml-4'>
                        <p className='2xl:text-base text-base text-[#000] font-inter-medium vehicle-card-text'>Address</p>
                        <IoLocationOutline className='w-5 h-5 mr-3 ml-10 icon-size-14px' />
                        {
                            isVehicleCountCard &&
                            <p className='font-inter-regular text-base text-[#474747] vehicle-card-text'>{handleDefaultAddress(vehicleData?.address)}</p>
                        }
                        {
                            !isVehicleCountCard &&
                            <p className='font-inter-regular text-base text-[#474747] vehicle-card-text w-[95%]'>
                                {vehicleData?.addressLineOne && <span>{vehicleData?.addressLineOne}</span>}
                                {vehicleData?.addressLineTwo && <span>, {vehicleData?.addressLineTwo}</span>}
                                {vehicleData?.landMark && <span>, {vehicleData?.landMark}</span>}
                                {vehicleData?.areaName && <span>, {vehicleData?.areaName}</span>}
                                {vehicleData?.cityName && <span>, {vehicleData?.cityName}</span>}
                                {vehicleData?.pincode && <span>, {vehicleData?.pincode}</span>}
                            </p>
                        }
                        {
                            !isVehicleCountCard && !isVehicleServiceModal && (
                                <img className='cursor-pointer ml-auto icon-size-14px' src={EditIcon} alt="" onClick={() => { handleEdit(vehicleData) }} />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehiclesCard