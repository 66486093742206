import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const cityApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getCites: builder.query({
				query: () => {
					return {
						url: endpoints.city.getCities,
						method: "GET",
					};
				},
				providesTags: ["City"],
			}),
			getCitesPagination: builder.query({
				query: ({ pageNo, pageSize }) => {
					return {
						url: endpoints.city.getCitiesPagination(pageNo, pageSize),
						method: "GET",
					};
				},
				providesTags: ["City"],
			}),
			addCity: builder.mutation({
				query: (body) => ({
					url: endpoints.city.addCity,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["City"],
			}),
			deleteCity: builder.mutation({
				query: (cityId) => ({
					url: endpoints.city.deleteCity(cityId),
					method: "DELETE",
				}),
				invalidatesTags: ["City"],
			}),
			getAllCityManagers: builder.query({
				query: () => {
					return {
						url: endpoints.city.getCityManagers,
						method: "GET",
					};
				},
			}),
			updateCity: builder.mutation({
				query: ({ cityId, data }) => ({
					url: endpoints.city.updateCity(cityId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["City"],
			}),
			getCityManagersCityWise: builder.query({
				query: (cityId) => {
					return {
						url: endpoints.city.getCityManagersByCity(cityId),
						method: "GET",
					};
				},
			}),
			getCityManagersByCities: builder.mutation({
				query: (body) => ({
					url: endpoints.city.getCityManagersByCities,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["City"],
			}),
		};
	},
});

export const {
	useAddCityMutation,
	useGetCitesQuery,
	useLazyGetCitesQuery,
	useDeleteCityMutation,
	useLazyGetAllCityManagersQuery,
	useUpdateCityMutation,
	useLazyGetCityManagersCityWiseQuery,
	useLazyGetCitesPaginationQuery,
	useGetCityManagersByCitiesMutation,
} = cityApi;
export { cityApi };
