import React, { useState, useEffect } from 'react';
import VehiclesCard from './Vehicles_Card';
import VehicleServiceCard from './Vehicle_Service_Card';
import CustomerManagementTabs from '../Customer_Management_Tabs';
import { useGetVehicleDetailsByStatusMutation, useGetVehicleServiceCountMutation } from '../../../utils/Store';
import { useParams } from 'react-router-dom';

function CustomerManagementVehicles({ vehicleDataInfo, selectedValue, singleVehicleDetails, fetchVehicleDetails }) {
    const [serviceList, setServiceList] = useState("requested");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [getVehicleDetailsByStatus, { data: vehicleDetailsByStatusData }] = useGetVehicleDetailsByStatusMutation(); 
    const [getVehicleServiceCount, { data: vehicleServiceCount }] = useGetVehicleServiceCountMutation();
    const {customerId} = useParams();

    useEffect(() => {
        if(selectedValue?.value !== 'All Vehicles'){
            getVehicleServiceCount({vehicleId: selectedValue?.value, customerId:customerId});
        }else {
            getVehicleServiceCount({customerId:customerId});
        }
    }, [selectedValue]);

    const handleVehicleServiceCount = (count) => {
		let countStr = "00";
		if (count == 0) return countStr;
		if (count > 0 && count < 10) {
			countStr = count > 0 && count < 10 ? "0" + count.toString() : count.toString();
		} else {
			countStr = count;
		}
		return countStr;
	};   

    let vehicleServiceCategories = [
        {
            title: `Requested Services (${handleVehicleServiceCount(vehicleServiceCount?.requested ? vehicleServiceCount?.requested : "00")})`,
            status: "requested",
        },
        {
            title: `Confirmed Services (${handleVehicleServiceCount(vehicleServiceCount?.confirmed ? vehicleServiceCount?.confirmed : "00")})`,
            status: "confirmed",
        },
        {
            title: `Completed Services (${handleVehicleServiceCount(vehicleServiceCount?.completed ? vehicleServiceCount?.completed : "00")})`,
            status: "completed",
        },
        {
            title: `Cancelled Services (${handleVehicleServiceCount(vehicleServiceCount?.canceled ? vehicleServiceCount?.canceled : "00")})`,
            status: "canceled",
        },
    ]

    useEffect(() => {
		if(vehicleDataInfo){
            handleGetAppointments();
        }
	}, [selectedIndex, selectedValue]);

	const handleGetAppointments = async () => {
        const status =vehicleServiceCategories[selectedIndex].status
        let apiData;
        if(selectedValue?.value !== 'All Vehicles'){
            apiData = { vehicleId: selectedValue?.value, customerId: customerId };
        }else{
            apiData = {customerId: customerId }
        }

		let { data, error } = await getVehicleDetailsByStatus({status, body: apiData});
	};
    
    let address={}
    const defaultAddress = singleVehicleDetails?.vehicleDetails?.vehicleaddresses?.find((address)=>address.defaultAddress)
    if(defaultAddress) address ={...defaultAddress}
    return (
        <div>
            {selectedValue?.name !== "All Vehicles" && (
                <VehiclesCard 
                        vehicleData={{...vehicleDataInfo, vehicleBrand: vehicleDataInfo?.brandName, vehicleModelImage: vehicleDataInfo?.imageUrl, vehicleType: vehicleDataInfo?.carType, ...address, vehicleId:singleVehicleDetails?.vehicleDetails?._id, ...singleVehicleDetails?.vehicleDetails, orderCount:singleVehicleDetails?.serviceCount } }
                        selectedValue={selectedValue}
                        fetchVehicleDetails={fetchVehicleDetails}
                    />
            )}
            <div className='2xl:mt-5 mt-3'>
                <CustomerManagementTabs
                    categories={vehicleServiceCategories}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    className='w-fit'
                />
            </div>
            {selectedValue?.name === "All Vehicles" && (
                <div className="2xl:mt-5 mt-3 h-[18.5vw] overflow-y-auto pr-3">
                    {vehicleDetailsByStatusData?.map((vehicleData) => (
                        <div key={vehicleData._id} className={`border-l-8 overflow-hidden rounded-xl mb-4 ${vehicleServiceCategories[selectedIndex].status === 'requested' ? "border-[#FF621F]" : vehicleServiceCategories[selectedIndex].status === 'confirmed' ? "border-[#00BE68]" : vehicleServiceCategories[selectedIndex].status === 'completed' ? "border-[#315EFF]" : vehicleServiceCategories[selectedIndex].status === 'canceled' ? "border-[#FB0C0C]" : ""}`}>
                            <VehiclesCard 
                                vehicleData={{...vehicleData, orderCount:vehicleData?.serviceCount}}
                                selectedValue={selectedValue}
                                fetchVehicleDetails={handleGetAppointments}
                            />
                                
                            <div className='mt-0'>
                                {/* {vehicleServiceCategories[selectedIndex].component} */}
                                <VehicleServiceCard 
                                    status={vehicleServiceCategories[selectedIndex].status}
                                    selectedValue={selectedValue} 
                                    vehicleDetailsData={vehicleData} 
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {selectedValue?.name !== "All Vehicles" && (
                <div className='2xl:mt-5 mt-3 h-[10vw] overflow-y-scroll pr-4'>
                    {/* {vehicleServiceCategories[selectedIndex].component} */}
                    {
                        vehicleDetailsByStatusData?.map((vehicleData) => (
                            <VehicleServiceCard 
                                    status={vehicleServiceCategories[selectedIndex].status}
                                    selectedValue={selectedValue} 
                                    vehicleDetailsData={vehicleData} 
                                />
                        ))
                    }
                </div>
            )}
        </div>
    );
}

export default CustomerManagementVehicles