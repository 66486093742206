import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const serviceApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAllServices: builder.mutation({
				query: (body) => ({
					url: endpoints.services.getAllServices,
					method: "POST",
					body: body,
				}),
				providesTags: ["GetAllServices"],
			}),
			getServiceDetails: builder.query({
				query: (serviceId) => {
					return {
						url: endpoints.services.getServiceInfo(serviceId),
						method: "GET",
					};
				},
				providesTags: ["serviceDetails"],
			}),
			deleteSingleService: builder.mutation({
				query: (serviceId) => ({
					url: endpoints.services.deleteSingleService(serviceId),
					method: "DELETE",
				}),
				invalidatesTags: ["GetAllServices"],
			}),
			updateService: builder.mutation({
				query: ({ serviceId, data }) => ({
					url: endpoints.services.updateService(serviceId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["GetAllServices", "serviceDetails"],
			}),
			createService: builder.mutation({
				query: (body) => ({
					url: endpoints.services.addService,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["GetAllServices"],
			}),

			// Service Management > Reports
			getServiceReportsDetails: builder.query({
				query: () => {
					return {
						url: endpoints.services.reports,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const {
	useGetAllServicesMutation,
	useDeleteSingleServiceMutation,
	useUpdateServiceMutation,
	useLazyGetServiceDetailsQuery,
	useCreateServiceMutation,
	useLazyGetServiceReportsDetailsQuery,
} = serviceApi;
export { serviceApi };
