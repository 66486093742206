import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import TableComponent from "../../../shared/components/Table.component";
import Button from "../../../shared/components/Button.component";
import { SearchNormal1, Location, InfoCircle, Information } from "iconsax-react";
import "react-datepicker/dist/react-datepicker.css";
import SelectMenuIcon from "../../../shared/components/SelectMenuIcon.component";
import {
	useGetAllFranchiseMutation,
	useLazyGetChildFranchiseByIdQuery,
	useLazyGetCitesQuery,
	useUpdateFranchiseMutation,
} from "../../../utils/Store";
import { useNavigate, useParams } from "react-router-dom";
import Toggle from "../../../shared/components/toggle.component";
import HomeRefresh from "../../../assets/icons/renewable-house.png";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import useToaster from "../../../utils/hooks/useToaster";
import moment from "moment";
import AcceptIcon from "../../../assets/icons/accept.png";
import CrossIcon from "../../../assets/icons/reject-icon.png";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

function AllFranchise() {
	const [getCites, { data: cities }] = useLazyGetCitesQuery();
	const [selectedCity, setSelectedCity] = useState("");
	const [inputValue, setInputValue] = useState("");
	const navigate = useNavigate();
	const { openModal } = useSelectModal();
	const [infoModalPosition, setInfoModalPosition] = useState({ top: 0, left: 0 });
	const [getAllFranhcise, { data: getAllListFranchise, status: franchiseDataStatus }] =
		useGetAllFranchiseMutation();
	const [pageSize, setPageSize] = useState(10);
	const [pageNo, setPageNo] = useState(1);
	const { setToast } = useToaster();
	const [updateFranchise] = useUpdateFranchiseMutation();
	const userRole = localStorage.getItem("role");
	const [sortingOrder, setSortingOrder] = useState(true);

	const cityOptions = cities ? [{ name: "All Cities" }, ...cities] : [{ name: "All Cities" }];

	useEffect(() => {
		setSelectedCity([cityOptions[0]]);
	}, []);

	const status = [
		{
			id: 1,
			name: "All Franchise",
			value: "all",
		},
		{
			id: 2,
			name: "Pending Franchise",
			value: "pending",
		},
		{
			id: 3,
			name: "Rejected Franchise",
			value: "rejected",
		},
	];

	const franchiseLevel = [
		{
			id: 1,
			name: "All Franchise",
			value: "all",
		},
		{
			id: 2,
			name: "Master Franchise",
			value: "parent",
		},
	];

	const [selectedStatus, setSelectedStatus] = useState(status[0]);
	const [selectedFranchiseLevel, setSelectedFranchiseLevel] = useState(franchiseLevel[0]);

	useEffect(() => {
		const fetchCityData = async () => {
			await getCites();
		};
		fetchCityData();
	}, []);

	useEffect(() => {
		const id = setTimeout(() => {
			getAllFranchiseData();
		}, 350);
		return () => {
			clearTimeout(id);
		};
	}, [selectedCity, inputValue, pageNo, selectedFranchiseLevel, selectedStatus, sortingOrder]);

	useEffect(() => {
		setPageNo(1);
	}, [selectedFranchiseLevel, selectedStatus]);

	const getAllFranchiseData = () => {
		let bodyObj = {
			cityId: [],
			expireInDays: sortingOrder,
		};
		if (selectedCity) {
			bodyObj.cityId = selectedCity?._id ? [selectedCity?._id] : [];
		}
		if (inputValue) {
			bodyObj.search = inputValue;
		}
		if (selectedStatus) {
			bodyObj.status = selectedStatus.value;
		}
		if (selectedFranchiseLevel) {
			bodyObj.franchiseLevel = selectedFranchiseLevel.value;
		}

		getAllFranhcise({ body: bodyObj, params: { page: pageNo, pageSize: pageSize } });
	};

	const handleCityChange = (option) => {
		setSelectedCity(option);
	};

	const handleChange = (e) => {
		const value = e.target.value;
		const sanitizedValue = value.replace(/[^\w\s]/gi, "");
		setInputValue(sanitizedValue);
	};

    let handleInfo = (original, event) => {
        openModal("FranchiseInfoModal", { id: original._id, postionOfCross: true });
    };

	const handleActionToggle = (franchiseData) => {
		updateFranchise({
			data: {
				isActive: !franchiseData.isActive,
			},
			id: franchiseData._id,
		})
			.unwrap()
			.then((data) => {
				!franchiseData.isActive
					? setToast({ type: "success", msg: "Franchise is Enabled" })
					: setToast({ type: "success", msg: "Franchise is Disabled" });
				getAllFranchiseData();
			})
			.catch((err) => {
				setToast({ type: "error", msg: err.data.message });
			});
	};

	const handlePageChange = (type) => {
		let tempCurrentPage = pageNo;
		if (type === "prev") {
			tempCurrentPage -= 1;
		} else {
			tempCurrentPage += 1;
		}
		setPageNo(tempCurrentPage);
		sessionStorage.setItem("currentPage", tempCurrentPage);
	};

	useEffect(() => {
		const storedPage = sessionStorage.getItem("currentPage");
		if (storedPage) {
			setPageNo(parseInt(storedPage));
		}
	}, []);

	const column1 = [
		{
			header: "Franchise Name",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-x-2 items-center w-full">
						<span className="truncate font-inter-medium">{original?.name}</span>
						{original?.hasChildren && (
							<span className="flex gap-x-1 items-center">
								<div className="relative group">
									<Information
										color="#0d6efd"
										onClick={() => handleInfo(original)}
										size="16"
										className="info-circle-size cursor-pointer"
									/>
								</div>
							</span>
						)}
					</span>
				);
			},
		},
		{
			header: "Rating",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="">
						{original?.Rating}
						<span className="text-[#FFC24C]">★</span>
					</span>
				);
			},
		},
		{
			header: "City",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const cities = original?.city?.map((city) => city?.cityName);
				return (
					<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
						<span className="whitespace-nowrap">{cities?.join(", ")}</span>
					</span>
				);
			},
		},
		{
			header: "Area",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const areas = original?.area?.map((area) => area?.areaName);
				return (
					<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
						<span className="whitespace-nowrap">{areas?.join(", ")}</span>
					</span>
				);
			},
		},
		{
			header: "Start Date",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium">
						{moment(original?.startDate).format("DD-MM-YYYY")}
					</span>
				);
			},
		},
		{
			header: "Service Providers",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return <span className="truncate font-inter-medium">{original?.ServiceProvider}</span>;
			},
		},
		{
			header: (
				<div className="flex gap-1" onClick={() => setSortingOrder((pre) => !pre)}>
					<span className="cursor-pointer">Expiring in Days</span>
					{sortingOrder ? (
						<BsArrowDown className="h-4 self-center cursor-pointer" />
					) : (
						<BsArrowUp className="h-4 self-center cursor-pointer" />
					)}
				</div>
			),
			accessorKey: "expiry",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const endDate = moment(original?.endDate);
				const today = moment(new Date());
				const expirationDays = endDate.diff(today, "days");
				const expired = expirationDays < 0 ? "Expired" : expirationDays;
				return (
					<span className="truncate font-inter-medium">
						<span
							className={`rounded-full px-4 py-0.5 ${
								expired === "Expired" || expirationDays < 30 ? "bg-[#FB0C0C] text-[#FFF]" : ""
							}`}
						>
							{expired}
						</span>
					</span>
				);
			},
		},
		{
			header: "Amount",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return <span className="truncate font-inter-medium">₹ {original?.amount}</span>;
			},
		},
		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;

				return (
					<span className="flex gap-2">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							onClick={() => navigate(`/franchise-management/${original?._id}`)}
							className="h-5 w-5 cursor-pointer shrink-0"
						/>
						{userRole !== "franchiseOwner" && (
							<Toggle
								defaultChecked={original?.isActive}
								handleOnToggle={() => handleActionToggle(original)}
							/>
						)}
						{userRole !== "franchiseOwner" && (
							<img
								src={EditIcon}
								alt="edit"
								onClick={() => {
									if (cities && original?.city?.length) {
										const citiesSelected = original.city?.map((city) =>
											cities.find((cities) => cities._id === city.cityId)
										);
										openModal("EditFranchiseModal", {
											...original,
											getCityObject: citiesSelected,
											getAreaObject: original.area,
											fetchFranchise: getAllFranchiseData,
										});
									}
								}}
								className="h-5 w-5 cursor-pointer"
							/>
						)}
						{userRole !== "franchiseOwner" && (
							<img
								src={DeleteIcon}
								alt="delete"
								onClick={() =>
									openModal("DeleteFranchiseModal", {
										...original,
										hideCloseArrow: true,
										fetchFranchise: getAllFranchiseData,
									})
								}
								className="h-5 w-5 cursor-pointer"
							/>
						)}
						{userRole !== "franchiseOwner" && (
							<img
								src={HomeRefresh}
								alt=""
								className="h-5 w-5 cursor-pointer object-cover"
								onClick={() =>
									openModal("RenewalFranchiseModal", {
										id: original._id,
										fetchFranchise: getAllFranchiseData,
										franchiseData: original,
									})
								}
							/>
						)}
					</span>
				);
			},
		},
	];

	const column2 = [
		{
			header: "Franchise Name",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center w-full">
						<span className="truncate">{original?.name}</span>
					</span>
				);
			},
		},
		{
			header: "Email",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium text-base">
						<span className="">{original?.email}</span>
					</span>
				);
			},
		},
		{
			header: "City",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const cities = original?.cityName?.join(", ");
				return (
					<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
						<span className="whitespace-nowrap">{cities}</span>
					</span>
				);
			},
		},
		{
			header: "Area",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const areas = original?.areaName?.join(", ");
				return (
					<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
						<span className="whitespace-nowrap">{areas}</span>
					</span>
				);
			},
		},
		{
			header: "Mobile Number",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium text-base">{original?.phoneNumber}</span>
				);
			},
		},
		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center justify-center">
						<div className="flex items-center justify-center">
							<div className="flex items-center justify-center gap-x-2 bg-[#59D956] rounded-[20px] px-2 py-1 ">
								<h1
									className="font-inter-medium text-sm text-[#FFF]"
									onClick={() =>
										openModal("ApproveFranchiseModal", {
											franchiseName: original?.name,
											franchiseId: original?._id,
											franchiseStatus: original?.status,
											fetchFranchise: getAllFranchiseData,
										})
									}
								>
									Approve
								</h1>
								<img src={AcceptIcon} className="" />
							</div>
						</div>
						<img
							src={CrossIcon}
							alt="edit"
							onClick={() =>
								openModal("RejectFranchiseModal", {
									franchiseName: original?.name,
									franchiseId: original?._id,
									franchiseStatus: original?.status,
									fetchFranchise: getAllFranchiseData,
								})
							}
							className="h-5 w-5 cursor-pointer"
						/>
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							onClick={() => navigate(`/franchise-management/${original?._id}`)}
							className="h-5 w-5 cursor-pointer shrink-0"
						/>
					</span>
				);
			},
		},
	];

	const column3 = [
		{
			header: "Franchise Name",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center w-full">
						<span className="truncate">{original?.name}</span>
					</span>
				);
			},
		},
		{
			header: "Email",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium text-base">
						<span className="">{original?.email}</span>
					</span>
				);
			},
		},
		{
			header: "City",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const cities = original?.cityName?.join(", ");
				return (
					<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
						<span className="whitespace-nowrap">{cities}</span>
					</span>
				);
			},
		},
		{
			header: "Area",
			cell: ({ row, getValue }) => {
				const { original } = row;
				const areas = original?.areaName?.join(", ");
				return (
					<span className="flex gap-3 w-[100%] overflow-x-auto final-price-scrollbar font-inter-medium">
						<span className="whitespace-nowrap">{areas}</span>
					</span>
				);
			},
		},
		{
			header: "Mobile Number",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium text-base">{original?.phoneNumber}</span>
				);
			},
		},
		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3 items-center justify-center">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							onClick={() => navigate(`/franchise-management/${original?._id}`)}
							className="h-5 w-5 cursor-pointer shrink-0"
						/>
					</span>
				);
			},
		},
	];

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
							Franchise Management
						</h3>
						{userRole !== "franchiseOwner" && (
							<Button
								className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
								onClick={() => openModal("CreateNewFranchiseModal")}
							>
								Create New Franchise
							</Button>
						)}
					</div>
				</div>
				<div className="bg-white p-5 mx-8 pb-4 h-[75vh] overflow-hidden brand-details-section bg-white-height franchise-table-height relative">
					<div className="flex items-center justify-between">
						<div className="relative 2xl:h-[3.2rem] h-[2.75rem] search-customer-height">
							{/* 2xl:w-[22rem] xl:w-[16rem]  */}
							<input
								type="text"
								className="w-[15rem] outline-focus h-full border-[#E9E9E9] rounded-xl py-1.5 pl-12 pr-3 text-base font-regular bg-[#F1F1F1] placeholder-black focus:outline-none focus:border-[#E9E9E9] focus:ring-transparent"
								placeholder="Search Franchise"
								value={inputValue}
								onChange={handleChange}
							/>
							<div className="absolute left-4 top-[50%] translate-y-[-50%]">
								<SearchNormal1 color="gray" size={20} className="search-icon" />
							</div>
						</div>
						<div className="flex items-center space-x-4">
							<div className="">
								<h1 className="text-base text-[#333333] font-normal">Filter by Status:</h1>
							</div>
							<div className="relative flex items-center justify-center cm-air-ht">
								<SelectMenuIcon
									placeholder="All"
									options={status}
									multiSelectClass="search-customer-height multi-time-slot franchise-select-control"
									onChange={(e) => setSelectedStatus(e)}
									value={selectedStatus}
								/>
							</div>
						</div>
						<div className="flex items-center space-x-4">
							<div className="">
								<h1 className="text-base text-[#333333] font-normal">Filter by Franchise:</h1>
							</div>
							<div className="relative flex items-center justify-center cm-air-ht">
								<SelectMenuIcon
									placeholder="All Franchise"
									options={franchiseLevel}
									multiSelectClass="search-customer-height multi-time-slot franchise-select-control"
									onChange={(e) => setSelectedFranchiseLevel(e)}
									value={selectedFranchiseLevel}
								/>
							</div>
						</div>
						<div className="flex items-center space-x-4">
							<div className="">
								<h1 className="text-base text-[#333333] font-normal">Filter by City:</h1>
							</div>
							<div className="relative flex items-center justify-center cm-air-ht">
								<SelectMenuIcon
									placeholder="All Cities"
									options={cityOptions}
									multiSelectClass="search-customer-height multi-time-slot"
									onChange={handleCityChange}
									value={selectedCity}
								/>
								<Location className="h-5 w-5 absolute location-icon-ht top-[50%] -translate-y-[50%] left-[1vw]" />
							</div>
						</div>
					</div>
					<div className="w-full my-4">
						<TableComponent
							data={getAllListFranchise?.franchise}
							columns={
								selectedStatus.value === "pending"
									? column2
									: selectedStatus.value === "rejected"
									? column3
									: column1
							}
							customwidths={
								selectedStatus.value === "pending" || selectedStatus.value === "rejected"
									? ["w-[15%]", "w-[25%]", "w-[15%]", "w-[15%]", "w-[15%]", "w-[15%]"]
									: [
											"w-[15%]",
											"w-[5%]",
											"w-[11%]",
											"w-[12.5%]",
											"w-[10%]",
											"w-[13%]",
											"w-[12%]",
											"w-[7%]",
											"w-[13.5%]",
									  ]
							}
							isLoading={franchiseDataStatus === "pending"}
							customeClass="franchise-management-table-ht"
						/>
					</div>
					{getAllListFranchise?.totalPages > 0 && (
						<div className="flex items-center gap-x-3 2xl:pt-2 absolute bottom-4">
							<div className="">
								<Button
									disabled={pageNo === 1}
									onClick={() => handlePageChange("prev")}
									className="w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] cursor-pointer"
								>
									Previous
								</Button>
							</div>
							<div className="">
								<Button
									disabled={pageNo === getAllListFranchise?.totalPages}
									onClick={() => handlePageChange("next")}
									className="w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px]"
								>
									Next
								</Button>
							</div>
							<div className="">
								<p className="text-[#727272] text-base font-inter-regular">
									Page <span className="text-[#FF0000] text-base font-inter-regular">{pageNo}</span>{" "}
									of {getAllListFranchise?.totalPages}
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default AllFranchise;
