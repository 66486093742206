import React from "react";
import { useState, useEffect } from "react";

export default function PaymentStatusSpinner({ status }) {
	return (
		<div className="flex justify-center ">
			{status === "failed" ? (
				<svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="39.5" cy="39.5" r="39.5" fill="#FFEBEB"/>
					<path d="M79 39.5C79 61.3152 61.3152 79 39.5 79C17.6848 79 0 61.3152 0 39.5C0 17.6848 17.6848 0 39.5 0C61.3152 0 79 17.6848 79 39.5ZM6.8865 39.5C6.8865 57.5119 21.4881 72.1135 39.5 72.1135C57.5119 72.1135 72.1135 57.5119 72.1135 39.5C72.1135 21.4881 57.5119 6.8865 39.5 6.8865C21.4881 6.8865 6.8865 21.4881 6.8865 39.5Z" fill="#D9FDD3"/>
					<path d="M79 39.5C79 61.3152 61.3152 79 39.5 79C17.6848 79 0 61.3152 0 39.5C0 17.6848 17.6848 0 39.5 0C61.3152 0 79 17.6848 79 39.5ZM6.8865 39.5C6.8865 57.5119 21.4881 72.1135 39.5 72.1135C57.5119 72.1135 72.1135 57.5119 72.1135 39.5C72.1135 21.4881 57.5119 6.8865 39.5 6.8865C21.4881 6.8865 6.8865 21.4881 6.8865 39.5Z" fill="#FB0C0C"/>
					<g clip-path="url(#clip0_7701_21674)">
					<rect x="19" y="20" width="40" height="40" rx="20" fill="white"/>
					<rect x="-1" width="80" height="80" rx="40" fill="#FFEBEB"/>
					<g clip-path="url(#clip1_7701_21674)">
					<path d="M49 45L54 43.75L55.25 48.75" stroke="#FB0C0C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M54 43.75C52.9369 46.9259 50.9326 49.7032 48.2533 51.7127C45.5739 53.7222 42.3466 54.8687 39 55C35.9203 55.0006 32.915 54.0531 30.3925 52.2864C27.8699 50.5197 25.9522 48.0194 24.9 45.125M29 35L24 36.25L22.75 31.25" stroke="#FB0C0C" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M24 36.25C25.1189 33.1088 27.1382 30.3668 29.8058 28.3661C32.4734 26.3654 35.6712 25.1946 39 25C42.0947 25.0087 45.111 25.9744 47.6353 27.7647C50.1597 29.555 52.0684 32.0823 53.1 35M39 31.25V41.25" stroke="#FB0C0C" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M39 48.75C39.6904 48.75 40.25 48.1904 40.25 47.5C40.25 46.8096 39.6904 46.25 39 46.25C38.3096 46.25 37.75 46.8096 37.75 47.5C37.75 48.1904 38.3096 48.75 39 48.75Z" stroke="#FB0C0C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					</g>
					<defs>
					<clipPath id="clip0_7701_21674">
					<rect x="19" y="20" width="40" height="40" rx="20" fill="white"/>
					</clipPath>
					<clipPath id="clip1_7701_21674">
					<rect width="35" height="35" fill="white" transform="translate(21.5 22.5)"/>
					</clipPath>
					</defs>
				</svg>
			) : status === "success" ? (
				<svg
					width="79"
					height="79"
					viewBox="0 0 79 79"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="39.5" cy="39.5" r="39.5" fill="#C8FFBF" />
					<path
						d="M79 39.5C79 61.3152 61.3152 79 39.5 79C17.6848 79 0 61.3152 0 39.5C0 17.6848 17.6848 0 39.5 0C61.3152 0 79 17.6848 79 39.5ZM6.8865 39.5C6.8865 57.5119 21.4881 72.1135 39.5 72.1135C57.5119 72.1135 72.1135 57.5119 72.1135 39.5C72.1135 21.4881 57.5119 6.8865 39.5 6.8865C21.4881 6.8865 6.8865 21.4881 6.8865 39.5Z"
						fill="#D9FDD3"
					/>
					<path
						d="M79 39.5C79 61.3152 61.3152 79 39.5 79C17.6848 79 0 61.3152 0 39.5C0 17.6848 17.6848 0 39.5 0C61.3152 0 79 17.6848 79 39.5ZM6.8865 39.5C6.8865 57.5119 21.4881 72.1135 39.5 72.1135C57.5119 72.1135 72.1135 57.5119 72.1135 39.5C72.1135 21.4881 57.5119 6.8865 39.5 6.8865C21.4881 6.8865 6.8865 21.4881 6.8865 39.5Z"
						fill="#2CB815"
					/>
					<g clip-path="url(#clip0_7102_21582)">
						<rect x="19" y="20" width="40" height="40" rx="20" fill="#2CB815" />
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M47.4947 32.3168L35.5613 43.8334L32.3947 40.4501C31.8113 39.9001 30.8947 39.8668 30.228 40.3334C29.578 40.8168 29.3947 41.6668 29.7947 42.3501L33.5447 48.4501C33.9113 49.0168 34.5447 49.3668 35.2613 49.3668C35.9447 49.3668 36.5947 49.0168 36.9613 48.4501C37.5613 47.6668 49.0113 34.0168 49.0113 34.0168C50.5113 32.4834 48.6947 31.1334 47.4947 32.3001V32.3168Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_7102_21582">
							<rect x="19" y="20" width="40" height="40" rx="20" fill="white" />
						</clipPath>
					</defs>
				</svg>
			) : (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="78"
					height="79"
					viewBox="0 0 78 79"
					fill="none"
					className="animate-spin"
				>
					<path
						d="M74.5279 48.0521C76.3752 48.5032 77.5217 50.373 76.9115 52.1741C74.4125 59.5509 69.7778 66.0512 63.5602 70.8266C56.4292 76.3035 47.6431 79.1836 38.6536 78.9909C29.6642 78.7983 21.0094 75.5444 14.1197 69.767C7.2299 63.9895 2.51742 56.0344 0.760979 47.2161C-0.995464 38.3978 0.309249 29.2441 4.45952 21.2678C8.60978 13.2914 15.3572 6.96966 23.5868 3.34728C31.8163 -0.275104 41.0355 -0.98135 49.7208 1.34525C57.2936 3.37382 64.0653 7.60216 69.2001 13.4585C70.4538 14.8884 70.1113 17.0547 68.5777 18.1792C67.0441 19.3037 64.902 18.9575 63.6222 17.5509C59.4338 12.9477 53.9967 9.61998 47.9389 7.99721C40.7678 6.07624 33.1559 6.65936 26.3611 9.65021C19.5663 12.6411 13.9952 17.8606 10.5685 24.4464C7.14183 31.0322 6.06458 38.59 7.51481 45.8709C8.96503 53.1518 12.8559 59.72 18.5445 64.4902C24.2331 69.2603 31.379 71.9469 38.8012 72.106C46.2234 72.2651 53.4778 69.8871 59.3655 65.3651C64.3393 61.545 68.0863 56.388 70.1913 50.5312C70.8345 48.7417 72.6805 47.6011 74.5279 48.0521Z"
						fill="#2CB815"
					/>
				</svg>
			)}
		</div>
	);
}
