import React, { useState } from 'react'
// import CheckIcon from "../../../assets/icons/check.svg";
import CrossIcon from "../../../assets/icons/cross-vector.png"
import Button from '../../../shared/components/Button.component';
import useSelectModal from '../../../utils/hooks/useSelectModal';
import { MdOutlineInsertComment } from "react-icons/md";
import useToaster from '../../../utils/hooks/useToaster';
import { useUpdateFranchiseStatusMutation } from '../../../utils/Store';

function RejectFranchiseModal({ data }) {

    const { openModal, closeModal } = useSelectModal();
    const [updateFranchiseStatus] = useUpdateFranchiseStatusMutation();
    const { setToast } = useToaster();
    const [comment, setComment] = useState("");

    const handleOnClick = async () => {
        openModal("ApprovedFranchiseModal", { isFromApproveFranchise: true, franchiseName: data?.franchiseName })

        const franchiseData = {
            status: data?.franchiseStatus === 'pending' ? 'rejected' : data?.franchiseStatus,
            comment: comment,
        }

        const { data: d, error } = await updateFranchiseStatus({
            id: data?.franchiseId,
            body: franchiseData,
        });
        if (d) {
            setToast({ type: "success", msg: "Franchise rejected successfully" })
        }
        if (error) setToast({ type: "error", msg: error?.data?.message });

        return;
    }

    return (
        <div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center justify-center">
                <div class=" bg-[#FFEBEB] h-[4rem] w-[4rem] rounded-full flex justify-center items-center ">
                    <img className='h-[1.25rem] w-[1.25rem]' src={CrossIcon} />
                </div>
            </div>
            <div className="">
                <h1 className='flex font-inter-medium justify-center pt-4 text-lg text-center'>Reject</h1>
            </div>
            <div className="pb-6 pt-0.5 text-center">
                <span className="text-[#87898E] text-center text-lg">Are you sure you want to reject "{data?.franchiseName}" </span>
            </div>
            <div className=''>
                <div className='flex items-center gap-x-1 pt-1'>
                    <MdOutlineInsertComment className='h-[1.5rem] w-[1.5rem]' />
                    <h1 className='font-inter-medium text-base text-[#000]'>Add Comment</h1>
                </div>
                <div className='pt-2 pb-6'>
                    <textarea rows="4" type='text'
                        placeholder='Enter your Comment'
                        className='max-h-[10rem] w-full rounded-[6px] border-[#D1D5DB] textarea-shadow'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                </div>
            </div>
            <div className="flex justify-between ">
                <Button
                    className="w-[47.5%] bg-[#F1F1F1] text-[#23262F] cursor-pointer rounded-lg"
                    onClick={closeModal}
                >
                    Close
                </Button>
                <Button
                    className="w-[47.5%] text-white cursor-pointer rounded-lg"
                    type="submit"
                    onClick={handleOnClick}
                // isSubmitting={status === "pending"}
                >
                    Reject
                </Button>
            </div>
        </div>
    )
}

export default RejectFranchiseModal
