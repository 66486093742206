import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

import FormField from "../../shared/components/FormField.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import MapArea from "./Map_Area";
import editIcon from "../../assets/icons/edit-icon-blue-bg.svg";
import mapIcon from "../../assets/icons/map-icon.svg";

import {
	useAddAreaMutation,
	useLazyGetActiveRadiusTypeQuery,
	useLazyGetAllTimeSlotsQuery,
	useLazyGetSupervisorsFromCityQuery,
	useUpdateAreaMutation,
} from "../../utils/Store";
import GMapAutocomplete from "./MapSearchBox";
import closeIcon from "../../assets/icons/close-icon.png";
import SelectMenu from "../../shared/components/SelectMenu.component";
import useToaster from "../../utils/hooks/useToaster";
import { AreaSchema } from "../../utils/Services/ValidationSchema";

export default function AddNewArea({ data }) {
	const { closeModal } = useSelectModal();
	const paymentMode = [
		{ name: "Cash", value: "cash" },
		{ name: "Online", value: "online" },
		{ name: "Cash, Online", value: "both" },
	];

	const [getCitySupervisors, { data: supervisorList }] = useLazyGetSupervisorsFromCityQuery();

	const [pincodeArray, setPincodeArray] = useState(
		data?.areaData?.pincodes ? data.areaData.pincodes : []
	);

	const [addNewArea,{status:addAreaStatus}] = useAddAreaMutation();
	const [updateArea,{status}] = useUpdateAreaMutation();
	const [radiusTypeList, setRadiusTypeList] = useState([])
	const [getActiveRadius, {status: activeRadiusloading}] = useLazyGetActiveRadiusTypeQuery()
	const { setToast } = useToaster();
	const [timeSlotList, setTimeSlotList] = useState([])
	const [ getActiveTimeSlots, { data: activeTimeSlotList, status: timeSlotStatus }] = useLazyGetAllTimeSlotsQuery();

	useEffect(() => {
		handleGetActiveRadiusType();
		handleGetActiveTimeSlots();
	}, [])
	
	useEffect(() => {
		if (data?.cityData?._id) getCitySupervisors(data?.cityData?._id);
	}, [data?.cityData?._id]);

	const handleGetActiveRadiusType = async() => {
		const { data, error } = await getActiveRadius();
		if (data) {
			setRadiusTypeList(data);
		}
		if (error) {
			setToast({ type: "error", msg: error?.data?.message });
		}
	}
	
	const handleGetActiveTimeSlots = async() => {
		const { data, error } = await getActiveTimeSlots();
		if (data) {
			setTimeSlotList(data);
		}
		if (error) {
			setToast({ type: "error", msg: error?.data?.message });
		}
	}

	const PincodeComponent = () => {
		const { values, setFieldValue } = useFormikContext();
		useEffect(() => {
			if (pincodeArray.length) {
				setFieldValue("radius", "");
				setFieldValue("radiusType", "");
				setFieldValue("search", "");
			}
			setFieldValue("pincodeArrayLength", pincodeArray?.length);
		}, [pincodeArray]);

		return (
			<div className="mt-4 border rounded-lg p-2 mb-4">
				<div className="flex justify-between gap-4 items-center">
					<div className="flex-1">
						<FormField
							field={{
								label: "Area Pincode",
								name: "pincode",
								type: "text",
								placeholder: "Enter area pincode",
								customClassName: "font-inter-medium",
								onKeyPress: (event) => {
									if (/[^0-9 ]/.test(event.key) || event.target.value.length > 5) {
										return event.preventDefault();
									}
								},
							}}
						/>
					</div>
					<Button
						onClick={() => {
							if (values.pincode?.length !== 6 || pincodeArray.includes(values.pincode))
								return null;
							setPincodeArray([...pincodeArray, values.pincode]);
							setFieldValue("pincode", "");
						}}
						className="whitespace-nowrap mt-1.5 text-white cursor-pointer h-[2.3rem]"
					>
						Add Pincode
					</Button>
				</div>
				<div className={`flex flex-wrap gap-2 ${pincodeArray?.length && "mb-2"}`}>
					{pincodeArray.map((pincode, index) => (
						<div className="flex gap-2 border rounded-lg px-3 py-1 w-max items-center">
							<div className="text-sm">{pincode}</div>

							<div className="w-6 flex justify-center h-full items-center">
								<img
									src={closeIcon}
									alt=""
									className="h-2 w-2 cursor-pointer text-[#000]"
									onClick={() => {
										const pincodes = pincodeArray.filter((e, ind) => ind !== index);
										setPincodeArray(pincodes);
										setFieldValue("pincodeArrayLength", pincodes.length);
									}}
								></img>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	const handleFormSubmit = async (values) => {
		const submissionValues = {
			radius: values.radius,
			radiusType: values.radiusType?._id,
			name: values.name,
			cityId: data?.cityData?._id,
			paymentType: values.paymentType?.value,
			timeSlot: values.timeSlot?.map((e) => e?.timeslot),
			coOrdinates: {
				latitude: +values.latitude,
				longitude: +values.longitude,
				searchedAreaName: values.search,
				longitudeDelta: values.longitudeDelta,
				latitudeDelta: values.latitudeDelta,
			},
			pincodes: pincodeArray,
		};

		if (values.employees) {
			submissionValues.employees = values.employees?.map((employee) => employee?._id);
			submissionValues.employees.filter((emp) => emp != null);
		}

		if (data?.isEdit) {
			const { data: area, error } = await updateArea({
				data: submissionValues,
				areaId: data.areaData._id,
			});
			if (area) {
				closeModal();
				setToast({ type: "success", msg: "Area Updated successfully" });
			}
			if (error) setToast({ type: "error", msg: error.data.message });
			return;
		}

		const { data: area, error } = await addNewArea(submissionValues);
		if (area) {
			closeModal();
			setToast({ type: "success", msg: "Area Added successfully" });
		}
		if (error) setToast({ type: "error", msg: error.data.message });
	};

	if (!supervisorList) return;
	return (
		<div className="w-[30rem] relative py-6 2xl:h-[40vw] h-[44vw] overflow-y-hidden modal-height">
			<div className="pl-5">
				<div className="flex justify-start items-center">
					<div className="h-12 w-12 rounded-full flex justify-center items-center modal-logo-size">
						<img src={data?.isEdit ? editIcon : mapIcon} alt="" className=" " />
					</div>
				</div>

				<div className="text-lg pt-4 font-inter-bold">{data.isEdit ? "Edit Area" : "Add New Area"}</div>

				<div className="tracking-tight text-sm  pb-5 text-[#475467] font-inter-regular">
					{!data.isEdit
						? "Add a new area by entering the details below"
						: "Edit an existing area by changing it’s	details"}
				</div>
			</div>
			<div className="pr-3 pb-5">
				<Formik
					initialValues={{
						name: data.isEdit ? data.areaData.name : "",
						search: data.isEdit ? data.areaData?.coOrdinates?.searchedAreaName : "",
						radius: data.isEdit ? data.areaData.radius : "",
						latitude: data.isEdit ? data.areaData?.coOrdinates?.latitude : "",
						longitude: data.isEdit ? data.areaData?.coOrdinates?.longitude : "",
						pincodeArrayLength: pincodeArray.length,
						employees: data.isEdit
							? data.areaData?.employees.map(
								(id) => supervisorList[supervisorList.findIndex((e) => e._id === id._id)]
							)
							: "",
						timeSlot: data?.fromAreaDetails ? data?.areaData?.timeSlot : data.isEdit
							? data?.areaData?.timeSlot?.map((time) => data?.timeSlotList?.find(slot=> slot.timeslot === time))
							: [],
						radiusType: data.isEdit ? data.areaData?.radiusType : "",
						paymentType: data.isEdit
							? paymentMode[paymentMode.findIndex((e) => e.value === data?.areaData?.paymentType?.toLowerCase())]
							: paymentMode[0],
					}}
					validationSchema={AreaSchema}
					onSubmit={(values) => {
						handleFormSubmit(values);
					}}
				>
					{({ isSubmitting, values, setFieldValue, errors, touched }) => (
						<Form className="space-y-6 h-[30.5vw] overflow-y-scroll px-5 modal-form-height" action="#" method="POST">
							<div className="">
								<FormField
									field={{
										label: "Area Name",
										name: "name",
										type: "text",
										placeholder: "Enter area name",
										customClassName: "font-inter-medium"
									}}
								/>
								<div>
									<GMapAutocomplete cityName={data?.cityData?.name} name="search" fullCityName={data?.cityData?.coOrdinates?.placeSearchName}/>
									{touched.search && errors.search ? (
										<div className="text-red-600 text-sm">{errors.search}</div>
									) : (
										<div className="invisible h-5"></div>
									)}
								</div>
								<div>
									<div className="text-sm font-inter-medium leading-6 text-gray-900">Area Centre</div>
									<div className="grid grid-cols-2 gap-4">
										<FormField
											field={{
												name: "latitude",
												type: "text",
												placeholder: "N: 0° 0' 0.000''",
												onKeyPress: (event) => {
													if (/[^0-9.]/.test(event.key)) {
														return event.preventDefault();
													}
												},
											}}
											showPrefix={values?.latitude ? true : false}
											prefixLabel="N: "
										/>
										<FormField
											field={{
												name: "longitude",
												type: "text",
												placeholder: "E: 0° 0' 0.000''",
												onKeyPress: (event) => {
													if (/[^0-9.]/.test(event.key)) {
														return event.preventDefault();
													}
												},
											}}
											showPrefix={values?.latitude ? true : false}
											prefixLabel="E: "
										/>
									</div>
								</div>
                				<PincodeComponent />

								<div className="text-sm font-inter-medium leading-6 text-gray-900">Area Radius</div>
								<div className="flex justify-between">
									<div className="w-[40%]">
										<FormField
											disabled={!values.search}
											field={{
												name: "radius",
												type: "text",
												placeholder: "Set the area radius",
												customClassName: "font-inter-medium",
												onKeyPress: (event) => {
													if (/[^0-9 ]/.test(event.key)) {
														return event.preventDefault();
													}
												},
											}}
										/>

									</div>
									<div className="w-[57%]">
										<SelectMenu
											name="radiusType"
											optionLabel="type"
											className="w-full"
											options={radiusTypeList}
											onChange={(option) => setFieldValue("radiusType", option)}
											value={values.radiusType}
										/>
										{touched.radiusType && errors.radiusType ? (
											<div className="text-red-600 text-sm">{errors.radiusType}</div>
											) : (
												<div className="invisible h-5"></div>
										)}
									</div>
								</div>
								<MapArea
									pincodeArray={pincodeArray}
									setPincodeArray={setPincodeArray}
									isEdit={data?.isEdit}
									city={data?.cityData}
								/>
								<div className="mt-4 space-y-4">
									<SelectMenu
										isMulti={true}
										name="employees"
										options={supervisorList}
										onChange={(option) => setFieldValue("employees", option)}
										value={values.employees}
										placeholder="Select a Supervisor"
										label="Assign a Supervisor"
										customeClassName= "font-inter-medium"
										inputType = "radio"
									/>
									<div>
										<SelectMenu
											isMulti={true}
											name="timeSlot"
											options={timeSlotList}
											onChange={(option) => setFieldValue("timeSlot", option)}
											value={values.timeSlot}
											placeholder="Select a slot"
											label="Assign a Time Slot"
											isLoading={timeSlotStatus !== "fulfilled"}
											customeClassName= "font-inter-medium"
											multiSelectClass= "multi-time-slot"
										/>
										{touched["timeSlot"] && errors["timeSlot"] ? (
											<div className="text-red-600 text-sm">{errors["timeSlot"]}</div>
										) : (
											<div className="invisible h-5"></div>
										)}
									</div>
									<SelectMenu
										name="paymentType"
										options={paymentMode}
										onChange={(option) => setFieldValue("paymentType", option)}
										value={values.paymentType}
										placeholder="Select a Payment Method"
										label="Payment Method"
										customeClassName= "font-inter-medium"
										showCheckboxOnRight={false}
									/>
								</div>
							</div>
							<div className="flex justify-between">
								<Button
									className="w-[47.5%] text-black bg-[#F1F1F1] cursor-pointer bottom-btn-ht"
									onClick={closeModal}
								>
									Cancel
								</Button>
								<Button
									className="w-[47.5%] text-white cursor-pointer bottom-btn-ht"
									type="submit"
									isSubmitting={status==="pending" || addAreaStatus === "pending"}
								>
									Submit
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}
