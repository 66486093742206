import React, { useState, useEffect, useRef } from 'react'
import TableComponent from '../../../shared/components/Table.component';
import { Information } from 'iconsax-react';
import LeadsTabs from './Leads_Tab';
import { useLazyGetRiderLeadQuery } from '../../../utils/Services/Apis/riderManagementApi';
import { useParams } from 'react-router-dom';
import moment from 'moment'
import TableInfiniteScroll from '../../../shared/components/TableInfiniteScroll';
import useToaster from '../../../utils/hooks/useToaster'

function LeadsTable({ selectedIndex }) {
    const [leadsData, setLeadsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const [getLeads, { data: getLeadsList, status: leadsListStatus }] = useLazyGetRiderLeadQuery();
    const [selectIndex, setSelectIndex] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('allLeads');
    const { riderId } = useParams();
    const { setToast } = useToaster();
    const isLoadingRef = useRef(false);

    useEffect(() => {
        // if (riderId) getLeads(riderId);
        fetchInitialData();
    }, [riderId]);

    let categories = [
        {
            title: `All Leads`,
            status: "allLeads",

        },
        // {
        //     title: `Converted Leads`,
        //     status: "convertedLeads",
        // },
    ]

    const columns = [
        {
            header: "Vehicle",
            accessorKey: "vehicle",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className='pl-4 w-full'>
                        <p className='font-inter-medium text-base'>{original?.carBrand} {original?.carModelNumber}</p>
                    </span>
                )
            }
        },
        {
            header: "Service",
            accessorKey: "service",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className='pl-4 w-full overflow-x-auto final-price-scrollbar'>
                        <p className='font-inter-medium text-base whitespace-nowrap'>{original?.service}</p>
                    </span>
                )
            }
        },
        {
            header: "Date and Time",
            accessorKey: "dateTime",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className='pl-2 w-full'>
                        <p className='font-inter-medium text-base'>{moment(original?.created).format('DD-MM-YYYY hh:mm:ss A')}</p>
                    </span>
                )
            }
        },
        {
            header: "Lead Status",
            accessorKey: "leadStatus",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className='pl-2 w-full'>
                        <p className='font-inter-medium text-base'>{original?.leadStatus}</p>
                    </span>
                )
            }
        },
        // {
        //     header: "Info",
        //     accessorKey: "info",
        //     cell: ({ row, getValue }) => {
        //         const { original } = row;
        //         return (
        //             <p><Information
        //                 color="#0d6efd"
        //                 size="16"
        //                 className=" cursor-pointer info-circle-size"
        //             />
        //             </p>
        //         )
        //     }
        // },
    ]

    const fetchInitialData = async () => {
        isLoadingRef.current = true;
        const { data, error } = await getLeads({
            riderId: riderId,
            params: {
                page: 1,
                pageSize: 10,
            },
        });
        if (data) {
            setLeadsData(data.leads);
            setCurrentPage(2);
            setHasMoreRecords(data.leads.length > 0);
            isLoadingRef.current = false;
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
            isLoadingRef.current = false;
        }
    };
    const fetchMoreData = async () => {
        console.log('More data have been fetched')
        const { data, error } = await getLeads({
            riderId: riderId,
            params: {
                page: currentPage,
                pageSize: 10,
            },
        });
        if (data) {
            setLeadsData((prev) => [...prev, ...data.leads]);
            setCurrentPage((prev) => prev + 1);
            setHasMoreRecords(data.leads.length > 0);
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
        }
    };

    return (
        <div className=''>
            {selectedIndex == 6 && (
                <>
                    <div className='flex items-center justify-between'>
                        <div className=''>
                            <LeadsTabs
                                categories={categories}
                                setSelectedIndex={setSelectIndex}
                                selectedIndex={selectIndex}
                                className='w-fit'
                            />
                        </div>
                        <div className='flex items-center gap-x-8'>
                            <h1 className='text-[#000] font-inter-bold text-lg'>Converted Leads: NA</h1>
                            <h1 className='text-[#000] font-inter-bold text-lg'>Conversion Rate: NA</h1>
                        </div>
                    </div>
                    <div className="bg-white p-5 mb-3 mt-3 pb-2 h-[30vh] overflow-hidden brand-details-section bg-white-height">
                        <div className="w-full mb-10">
                            <TableInfiniteScroll
                                hasMore={hasMoreRecords}
                                data={leadsData}
                                height="12rem"
                                next={fetchMoreData}
                                scrollThreshold={0.8}
                                dataLength={leadsData?.length}
                                columns={columns}
                                customwidths={["w-[25%]", "w-[25%]", "w-[25%]", "w-[25%]"]}
                                customeClass='max-h-[19vh]'
                                customeHeight="12rem"
                            />
                        </div>
                    </div>
                </>
            )
            }
        </div>
    )
}

export default LeadsTable
