import React from 'react'
import Button from '../../../../shared/components/Button.component'
import TableComponent from '../../../../shared/components/Table.component'
import EditIcon from "../../../../assets/icons/Edit.png"
import DeleteIcon from '../../../../assets/icons/Delete.png'
import useSelectModal from '../../../../utils/hooks/useSelectModal'
import { useNavigate } from 'react-router-dom'


function SubMenuMaster() {

    const { openModal } = useSelectModal();
    const navigate = useNavigate();

    const getAllMenuMasterData = [
        {
            name: "Service mangement",
            subname:"Service One",
            order: "01",
        },
        {
            name: "Request mangement",
            subname:"Request One",
            order: "04",
        },
        {
            name: "Role mangement",
            subname:"Role One",
            order: "03",
        },
        {
            name: "Franchise mangement",
            subname:"Franchise One",
            order: "06",
        },
        {
            name: "Rider mangement",
            subname:"Rider One",
            order: "05",
        },
        {
            name: "Area mangement",
            subname:"Area One",
            order: "02",
        },
        {
            name: "Service mangement",
            subname:"Service One",
            order: "01",
        },
        {
            name: "Request mangement",
            subname:"Request One",
            order: "04",
        },
        {
            name: "Role mangement",
            subname:"Role One",
            order: "03",
        },
        {
            name: "Franchise mangement",
            subname:"Franchise One",
            order: "06",
        },
        {
            name: "Rider mangement",
            subname:"Rider One",
            order: "05",
        },
        {
            name: "Area mangement",
            subname:"Area One",
            order: "02",
        },
    ]

    const columns = [
        {
            header: "Submenu Master Title",
            cell: ({ row }) => {
                const { original } = row;
                return (
                    <span className="">
                        <span className="font-inter-medium text-base text-[#333]">{original?.subname}</span>
                    </span>
                );
            },
        },
        {
            header: "Sort Order",
            cell: ({ row }) => {
                const { original } = row;
                return (
                    <span className="">
                        <span className='font-inter-medium text-base text-[#333]'>{original?.order}</span>
                    </span>
                )
            }
        },
        {
            header: "Menu Master",
            cell: ({ row }) => {
                const { original } = row;
                return (
                    <span className="">
                        <span className="font-inter-medium text-base text-[#333]">{original?.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Actions",
            cell: ({ row }) => {
                const { original } = row;
                return (
                    <span className="flex items-center justify-center gap-3">
                        <img
                            src={EditIcon}
                            alt=""
                            onClick={() => openModal("EditSubMenuMasterModal")}
                            className="h-5 w-5 cursor-pointer"
                        />

                        <img
                            src={DeleteIcon}
                            alt=""
                            onClick={() => openModal("DeleteSubMenuMasterModal")}
                            className="h-5 w-5 cursor-pointer"
                        />
                    </span>
                );
            },
        },
    ]


    return (
        <div className='bg-[#F5F5F5] h-full'>
            <div className="space-y-3">
                <div className="px-8 pb-4 pt-8">
                    <div className="flex justify-between">
                        <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Submenu Master</h3>
                        <Button className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
                            onClick={() => navigate("/area-master/submenu-master/create-submenu-master")}
                        >
                            Create Submenu Master
                        </Button>
                    </div>
                </div>
                <div className="bg-white p-5 mx-8 pb-4 h-[75vh] overflow-hidden brand-details-section bg-white-height franchise-table-height relative">
                    <div className="w-full my-4">
                        <TableComponent
                            data={getAllMenuMasterData}
                            columns={columns}
                            customwidths={["w-[30%]", "w-[30%]", "w-[30%]", "w-[10%]"]}
                            customeClass="menu-master-table-ht pr-3 max-h-[56vh]"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubMenuMaster