import React from "react";
import {
	Calendar1,
	Car,
	Clock,
	GasStation,
	Keyboard,
	Lifebuoy,
	Location,
	User,
	Message,
	CallCalling,
	Calendar,
} from "iconsax-react";
import DatePickerComponent from "../../shared/components/DatePicker.component";
import Brake from "../.././assets/icons/service_request_management/brake.svg";
import CarPolish from "../.././assets/icons/service_request_management/car.svg";
import Tire from "../.././assets/icons/service_request_management/tire.svg";
import Edit from "../.././assets/icons/service_request_management/edit.svg";
import Button from "../../shared/components/Button.component";
import PaymentMode from "../../assets/icons/payment-mode.svg";
import KeyboardIcon from "../../assets/icons/small-keyboard.svg";
import SuccessTick from "../../assets/icons/success-tick.svg";
import SuccssWallet from "../../assets/icons/success-green-icon.png";
import PhoneCall from "../../assets/icons/phone-call.svg";
import moment from "moment";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useRescheduleOrCancelMutation } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";

function CardService({ type, orderDetails = null, status , refetch }) {
	const { setToast } = useToaster();
	const { openModal } = useSelectModal();

	if (!orderDetails) return;
	const handleAssignRider = () => {
		openModal("AssignRiderModal", {...orderDetails, hideCloseArrow: false , refetch});
	}
	
	const handleRescheduleRequest = () => {
		openModal("RescheduleModal", {...orderDetails, hideCloseArrow: false, refetch});
	}
	
	const handleCancelRequest = () => {
		openModal("CancelAppointmentModal", {
			status: 'canceled',
			orderId: orderDetails._id,
			refetch
		});
	}

	function formatNumber(num) {
		if (num < 10) {
		  return '0' + num;
		}
		return '' + num;
	}
	
	return (
		<>
			<div className="">
				{type === "success" && (
					<div className="flex items-center pb-4">
						<img src={SuccssWallet} color="white" />
						<h1 className="ml-4">Your Appointment has been successfully Booked </h1>
					</div>
				)}

				<div
					className={`w-full h-2/4 pb-5 mb-3 bg-white ${status === 'requested' ? "border-[#FF621F]" : status === 'confirmed' ? "border-[#00BE68]" : status === 'canceled' ? "border-[#CB0303]" : ""} border-l-8 shadow rounded-xl order-modal-${type}`}
				>
					<div className="bg-white rounded-t-lg drop-shadow-md px-5 py-4">
						<div className="flex items-center justify-between">
							<div className="flex items-center py-1.5 px-3 mt-2 bg-[#F1F1F1] rounded-lg w-fit space-x-2">
								<Car className="w-5 h-5 2xl:w-7 2xl:h-7"/>
								<h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
									{orderDetails?.service[0]?.serviceName}
								</h1>
							</div>
							<div className="flex justify-evenly items-center">
								<div className="flex items-center">
									<Clock size={25} className="mx-3 w-5 h-5 2xl:w-7 2xl:h-7" />
									<h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">{orderDetails?.timeslotDetails?.split(" - ")[0]}</h1>
								</div>
								<div className="flex items-center ml-6">
									<Calendar size={25} className="mx-4 w-5 h-5 2xl:w-7 2xl:h-7" />
									<h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">
										{moment(orderDetails?.serviceDate).format("DD MMMM YYYY")}
									</h1>
								</div>
								{/* <div className="flex items-center ml-6">
									<div className="h-11 w-11 p-3 bg-[#F1F1F1] rounded-full flex items-center justify-center cursor-pointer edit-icon-size">
										<img src={Edit} className="h-full w-full" />
									</div>
								</div> */}
							</div>
						</div>
						<div className="flex items-center justify-between pt-5 ">
							{type !== "success" && (
								<>
									<div className="">
										<Button className="bg-[#D9D9D9] rounded-lg text-base 2xl:text-xl font-inter-medium text-[#000000] cursor-pointer border border-[#A8A8A8]">
											One Time Service
										</Button>
									</div>
									<div className={`relative left-5 ${status === 'requested' ? "bg-[#FF621F]/20" : status === 'confirmed' ? "bg-[#00BE68]/20" : status === 'canceled' ? "bg-[#CB0303]/20" : ""} `}>
										<h1 className={`relative h-10 w-40 text-lg font-medium font-plus-jakarta text-center pt-1.5 ${status === 'requested' ? "text-[#FF621F]" : status === 'confirmed' ? "text-[#00BE68]" : status === 'canceled' ? "text-[#CB0303]" : ""} rounded-l-full`}>
											{
												status === 'requested' ? "Requested" : status === 'confirmed' ? "Confirmed" : status === 'canceled' ? "Cancelled" : ""
											}
											<div className="h-8 w-8 rounded-full absolute -left-5 top-1/2 bg-white -translate-y-1/2"></div>
										</h1>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="">
						<div className="flex justify-between ">
							<div className="w-[20%] 2xl:w-[30%] p-5 pt-6 h-[12vw] width-30">
								<img src={orderDetails?.vehicleModelImage} className="w-full h-full object-contain" />
							</div>

							<div className="w-[30%] pt-6 relative">
								<div className="w-[80%] overflow-x-auto final-price-scrollbar">
									<h1 className="2xl:text-[22px] text-lg font-inter-bold vehicle-name-text whitespace-nowrap">{orderDetails?.vehicleName}</h1>
								</div>
								<div className="flex space-x-8 h-[3vw] items-center">
									<div className="flex space-x-3 items-center">
										<GasStation size={22} color="#666666" className="icon-size-14px" />
										<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
											{orderDetails?.fuelType?.charAt(0).toUpperCase() +
												orderDetails?.fuelType?.slice(1)}
										</h1>
									</div>
									<div className="flex space-x-3 items-center">
										<Lifebuoy size={22} color="#666666" className="icon-size-14px" />
										<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
											{orderDetails?.transmissionType.charAt(0).toUpperCase() +
												orderDetails?.transmissionType.slice(1)}
										</h1>
									</div>
								</div>

								<div className="flex space-x-3 h-[3vw] items-center">
									<img src={KeyboardIcon} className="w-6 h-6 icon-size-14px" />
									<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
										{orderDetails?.vehicleNumber?.replace(/([A-Z]{2})(\d{2})([A-Z]{2})(\d{4})/, "$1 $2 $3 $4")}
									</h1>
								</div>

								<div className=" flex space-x-5 h-[3vw] items-center">
									<p className="text-black font-inter-medium text-sm 2xl:text-base ">Assigned To</p>
									<div className="w-[50%]">
										<div className="border items-center sm:pl-2 bg-[#FFFFFF] flex space-x-2 font-normal border-inherit rounded-md p-1.5 w-full justify-start pl-1">
											<User size={18} color="#666666" className="icon-size-14px mx-1" />
											<span className="text-[#474747] text-sm 2xl:text-base truncate">{orderDetails?.riderName ? orderDetails?.riderName : 'None'}</span>
										</div>
									</div>
								</div>

								<div className="absolute 2xl:right-7 lg:right-[0.75rem] top-3 w-px bg-gray-300 h-[14vw]"></div>
							</div>
							<div className="w-[50%] 2xl:w-[40%] pb-4 pt-6 width-40">
								<div className="flex h-[5vw]">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Address</p>
									</div>

									<div className="flex justify-center items-start">
										<Location size={20} color="#666666" className="icon-size-14px" />
										
                    					<p className="font-inter-regular text-[#474747] text-sm 2xl:text-base w-56 pl-2 ml-0 line-clamp-3 2xl:line-clamp-4">
											{orderDetails?.addressLineOne && <span>{orderDetails?.addressLineOne}</span>}
											{orderDetails?.addressLineTwo && <span>, {orderDetails?.addressLineTwo}</span>} 
											{orderDetails?.landMark && <span>, {orderDetails?.landMark}</span>} 
											{orderDetails?.areaName && <span>, {orderDetails?.areaName}</span>} 
											{orderDetails?.cityName && <span>, {orderDetails?.cityName}</span>} 
											{orderDetails?.pincode && <span>, {orderDetails?.pincode}</span>}  
										</p>
									</div>
								</div>

								<div className="flex h-[3vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Customer Name</p>
									</div>

									<div className="flex justify-center">
										<User size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] pr-2 pl-2 3xl:text-base text-sm truncate">{orderDetails?.customerName}</p>
										<img src={PhoneCall} className="w-5 h-5 icon-size-14px"/>
										{/* <div className="p-1 h-6 w-6 bg-black rounded-full ml-4">
											<CallCalling size={8} color="#FFF" />
										</div> */}
									</div>
								</div>

								<div className="flex h-[3vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Customer Email</p>
									</div>

									<div className="flex space-x-1 items-center w-[65%] pr-4">
										<Message size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate">{orderDetails?.customerEmail}</p>
									</div>
								</div>

								<div className="flex h-[3vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Order ID</p>
									</div>

									<div className="flex space-x-1 items-center w-[65%] pr-4">
										{/* <Message size={20} color="#666666" className="icon-size-14px" /> */}
										<p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate">
											{orderDetails?.orderUniqueId ? orderDetails?.orderUniqueId : '-'}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="px-5 mt-3">
						<hr className="border bg-gray-300 w-full" />
					</div>
					<div className="flex justify-between pt-5 px-16">
						<div className="flex items-center space-x-14">
							<div className="">
								<p className="text-black font-inter-medium text-base">Total Service Time</p>
							</div>
							<div className="flex items-center justify-center space-x-3 ml-3 border rounded-lg p-1 px-3">
								<Clock size={18} color="gray" className="icon-size-14px" />
								<p className="font-inter-medium text-base text-[#000000]">
									{orderDetails?.service[0]?.serviceTime[0].hours}:
									{formatNumber(orderDetails?.service[0]?.serviceTime[0].minutes)} Hours
								</p>
							</div>
						</div>
						<div className="flex items-center space-x-14">
							<div className="">
								<p className="text-black font-inter-medium text-base">Total Price</p>
							</div>
							<div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
								<p className="text-gray text-base">₹</p>
								<p className="text-[#000000] font-inter-medium text-base">
									{orderDetails.payableAmount}
								</p>
							</div>
						</div>
					</div>
					<div className=" px-5">
						<hr className="border bg-gray-300 w-full mt-5" />
					</div>
					<div className="flex items-center py-5 justify-between">
						<div className="flex items-center pl-16 space-x-6">
							<div className="flex mr-2">
									<img src={PaymentMode} color="white" className="h-9 w-9" />
								{/* <div className="bg-[#198754] rounded-full w-8 h-8 flex items-center justify-center">
								</div> */}
								<h1 className="font-semibold pl-2 flex items-center justify-center">
									Payment Mode :
								</h1>
							</div>
							<div className="">
								<p className="font-inter-medium text-gray-700 text-md border rounded-lg p-1 px-5">
									{orderDetails?.paymentType == "cash" ? "Cash" : "Online"}
								</p>
							</div>
						</div>
						{
							orderDetails?.canceledBy?.role === 'Rider' &&
							<Button className="bg-black font-md w-[300px] text-white border lg:text-base 2xl:text-lg border-black-200 rounded-xl py-2 p-2 mr-4 cursor-pointer h-[3.1rem] btn-radius" onClick={handleAssignRider}>
								Assign to Rider
							</Button>
						}
					</div>
					{type !== "success" && (
						<>
							<div className="px-5">
								<hr className="border bg-gray-300 w-full" />
							</div>
							<div className="">
								<div className="flex items-center justify-evenly pt-5 px-5">
									{
										status !== "canceled" &&
											<>
												{
													status === "requested" && 
													<Button className="bg-black w-[285px] text-white font-inter-regular lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] py-2 p-2 mr-4 cursor-pointer h-[3.1rem] btn-radius" onClick={handleAssignRider}>
														Assign to Rider
													</Button>
												}
												<Button className="bg-black w-[285px] text-white font-inter-regular lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] p-2 mr-4 cursor-pointer h-[3.1rem] btn-radius" onClick={handleRescheduleRequest}>
													Reschedule Request
												</Button>
												<Button className="bg-black w-[285px] text-white font-inter-regular lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] p-2 mr-5 cursor-pointer h-[3.1rem] btn-radius" onClick={handleCancelRequest}>
													Cancel Request
												</Button>												
											</>
									}
								</div>
								{
									status === "canceled" &&
									<div className="pl-16">
										<div className=" flex space-x-7 pt-3 pr-5 items-center">

											<p className="text-gray-900 font-semibold text-base">Cancelled by {orderDetails?.canceledBy?.role}</p>
											<div className="">
												<Button className="bg-[#FFFFFF] flex space-x-2 font-normal w-32 cursor-pointer">
													<User size={20} color="#666666" className="icon-size-14px"/>
													<span className="text-[#474747]">{orderDetails?.canceledBy?.name}</span>
												</Button>
											</div>
										</div>
										<div className="flex justify-between">
											<div className=" flex space-x-1 pr-5 items-center w-1/2">
												<p className="text-gray-900 font-semibold text-base">Reason :</p>
												<div className="">
													<p className="font-inter-medium text-[#474747]">{orderDetails?.reason}</p>
												</div>
											</div>
											<div className="flex items-center justify-center px-11 w-1/2">
												<p className="font-inter-medium text-[#474747] pt-6 "><span className="text-gray-900 font-semibold text-base pr-1">Note :</span>{orderDetails?.note}</p>

											</div>
										</div>
									</div>
								}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default CardService;
