import { Tab } from "@headlessui/react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Tabs({ categories, className, setSelectedIndex, selectedIndex, setCurrentPage = null, customeClassName }) {
	return (
		<Tab.Group
			as={"div"}
			className={className}
			selectedIndex={selectedIndex}
			onChange={(index) => {
				setSelectedIndex(index)
				if(setCurrentPage){
					setCurrentPage(1)
				}
			}}
		>
			<Tab.List className="flex items-center justify-between space-x-1 rounded-xl bg-[#EDEDED] p-2 shadow border border-[#A8A8A8]">
				{categories.map((category, index) => (
					<Tab
						key={index}
						className={({ selected }) =>
							classNames(
								"tab-titles w-[15rem] 2xl:rounded-lg rounded-md 2xl:py-[0.625rem] py-[0.45rem] whitespace-nowrap text-base 2xl:text-base lg:text-sm font-inter-medium leading-5",
								"focus:outline-none px-[10px]",
								selected
									? "bg-white  text-black"
									: "text-black hover:bg-white/[0.12]", customeClassName
							)
						}
					>
						{category.title}
					</Tab>
				))}
			</Tab.List>
		</Tab.Group>
	);
}
