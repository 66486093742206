import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const orderApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getCustomerByPhoneNumber: builder.mutation({
				query: (phone) => ({
					url: endpoints.order.getCustomerByPhoneNumber,
					method: "POST",
					body: {
						phoneNumber: phone,
					},
				}),
			}),
			getCarModelByBrandId: builder.query({
				query: (brandId) => ({
					url: endpoints.order.getCarModelByBrandId(brandId),
					method: "GET",
				}),
			}),
			addCustomer: builder.mutation({
				query: (body) => ({
					url: endpoints.order.addCustomer,
					method: "POST",
					body: body,
				}),
			}),
			getServicesForOrder: builder.mutation({
				query: (body) => ({
					url: endpoints.order.getServicesForOrder,
					method: "POST",
					body: body,
				}),
			}),
			addOrder: builder.mutation({
				query: (body) => ({
					url: endpoints.order.addOrder,
					method: "POST",
					body: body,
				}),
			}),
			getOrderDetails: builder.query({
				query: (orderId) => ({
					url: endpoints.order.getOrderDetails(orderId),
					method: "GET",
				}),
			}),
			getOrderCount: builder.query({
				query: () => ({
					url: endpoints.order.getOrderCount,
					method: "GET",
				}),
				providesTags: ["OrderCount"],
			}),
			getOrderByStatus: builder.query({
				query: ({status, params}) => ({
					url: endpoints.order.getOrderByStatus(status),
					params: params,
					method: "GET",
				}),
				// providesTags: ["GetOrders"]
			}),
			getRidersForOrder: builder.query({
				query: (orderId) => ({
					url: endpoints.order.getRidersForOrder(orderId),
					method: "GET",
				}),
			}),
			assignOrderToRider: builder.mutation({
				query: (body) => ({
					url: endpoints.order.assignOrderToRider,
					body: body,
					method: "PUT",
				}),
				invalidatesTags: ["GetOrders"],
			}),
			rescheduleOrCancel: builder.mutation({
				query: ({ data, orderId }) => ({
					url: endpoints.order.rescheduleOrCancel(orderId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["GetOrders", "OrderCount"],
			}),
			verifyVehicle: builder.mutation({
				query: (body) => ({
					url: endpoints.order.verifyVehicle,
					method: "POST",
					body: body,
				}),
			}),
			vehicleAddress: builder.mutation({
				query: (body) => ({
					url: endpoints.order.vehicleAddress,
					method: "POST",
					body: body,
				}),
			}),
			getSingleOrder: builder.query({
				query: (orderId) => ({
					url: endpoints.order.getSingleOrder(orderId),
					method: "GET"
				})
			})
		};
	},
});

export const {
	useGetCustomerByPhoneNumberMutation,
	useLazyGetCarModelByBrandIdQuery,
	useAddCustomerMutation,
	useGetServicesForOrderMutation,
	useAddOrderMutation,
	useLazyGetOrderDetailsQuery,
	useLazyGetOrderCountQuery,
	useLazyGetOrderByStatusQuery,
	useLazyGetRidersForOrderQuery,
	useAssignOrderToRiderMutation,
	useRescheduleOrCancelMutation,
	useVerifyVehicleMutation,
	useVehicleAddressMutation,
	useLazyGetSingleOrderQuery
} = orderApi;
export { orderApi };
