import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";

import TableComponent from "../../shared/components/Table.component";
import Button from "../../shared/components/Button.component";
import { useNavigate } from "react-router";
import useToaster from "../../utils/hooks/useToaster";
import { InfoCircle } from "iconsax-react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Toggle from "../../shared/components/toggle.component";

export default function RoleManagement() {
	const navigate = useNavigate();
    const { openModal } = useSelectModal();
    const roleManagementData = [
        {
            roleName: "Super Admin",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "City Manager",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "22",
            Actions: ""
        },
        {
            roleName: "Supervisor",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "33",
            Actions: ""
        },
        {
            roleName: "HR",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "Super Admin",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "City Manager",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "22",
            Actions: ""
        },
        {
            roleName: "Supervisor",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "33",
            Actions: ""
        },
        {
            roleName: "HR",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "Super Admin",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "City Manager",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "22",
            Actions: ""
        },
        {
            roleName: "Supervisor",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "33",
            Actions: ""
        },
        {
            roleName: "HR",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "Super Admin",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "City Manager",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "22",
            Actions: ""
        },
        {
            roleName: "Supervisor",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "33",
            Actions: ""
        },
        {
            roleName: "HR",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "Super Admin",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        },
        {
            roleName: "City Manager",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "22",
            Actions: ""
        },
        {
            roleName: "Supervisor",
            dataVisibility: "Self, Hierarchy, Cities, Area",
            usersAssigned: "33",
            Actions: ""
        },
        {
            roleName: "HR",
            dataVisibility: "Self, Cities, Area",
            usersAssigned: "100",
            Actions: ""
        }
    ]

    const columns = [
		{
			header: "Role Name",
			accessorKey: "name",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium">
						{original.roleName}
					</span>
				);
			},
		},
		{
			header: "Data Visibility",
			accessorKey: "phoneNumber",
            cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium">
						{original.dataVisibility}
					</span>
				);
			},
		},
		{
			header: "No. of users assigned",
			accessorKey: "email",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="truncate font-inter-medium">
						{original.usersAssigned}
					</span>
				);
			},
		},
		{
			header: "Action",
			cell: ({ row, getValue }) => {
				const { original } = row;
				return (
					<span className="flex gap-3">
						<InfoCircle
							color="#0d6efd"
							variant="Bold"
							// onClick={() => navigate(`/employee-management/${original?._id}`)}
							className={`h-5 w-5 cursor-pointer`}
						/>
                        <Toggle
                            // defaultChecked={original?.isActive}
                            // handleOnToggle={() => handleActionToggle(original)}
                        />
						<img
							src={EditIcon}
							alt="edit"
							// onClick={() => navigate(`/employee-management/edit/${original?._id}`)}
							className={`h-5 w-5 cursor-pointer`}
						/>
						<img
							src={DeleteIcon}
							alt="delete"
							onClick={() => handleDelete(original)}
							className="h-5 w-5 cursor-pointer"
						/>
					</span>
				);
			},
		},
	];

    const handleDelete = (original) => {
        openModal("DeleteRoleModal", {original})
    }

	return (
		<div className="bg-[#F5F5F5] h-full">
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Role Management</h3>
						<Button
							className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm"
							onClick={() => navigate("/role-management/add-new-role")}
						>
							Add New Role
						</Button>
					</div>
				</div>
				<div className="bg-white p-5 mx-8 my-3 pb-2 h-[75vh] overflow-hidden brand-details-section bg-white-height">

					<div className="w-full mb-10">
						<TableComponent
							data={roleManagementData}
							columns={columns}
							customeClass='pr-3 max-h-[65vh]'
							customwidths={["w-[30%]", "w-[25%]", "w-[25%]", "w-[20%]"]}
							// isLoading={employeeListStatus === "pending"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}