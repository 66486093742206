import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const configurationApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getConfiguration: builder.query({
				query: () => ({
					url: endpoints.configuration.getConfiguration,
					method: "GET",
				}),
				providesTags: ["config"],
			}),
			updateConfiguration: builder.mutation({
				query: (body) => ({
					url: endpoints.configuration.updateConfiguration,
					method: "POST",
					body: body,
				}),
				invalidatesTags: ["config"],
			}),
		};
	},
});

export const { useLazyGetConfigurationQuery, useUpdateConfigurationMutation } = configurationApi;
export { configurationApi };
