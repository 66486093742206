import React, { useEffect, useState } from "react";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import useToaster from "../../utils/hooks/useToaster";
import DeleteIcon from "../../assets/icons/modal-delete-icon.svg";
import SelectMenu from "../../shared/components/SelectMenu.component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRescheduleOrCancelMutation } from "../../utils/Store";
import moment from "moment";
import Calender from "../../shared/components/Calender";
import { Calendar } from "iconsax-react";
import { useReschedulingTimeSlotMutation } from "../../utils/Services/Apis/timeSlot";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const schema = Yup.object().shape({
	selectedSlot: Yup.string().required("Invalid details"),
});

function RescheduleModal({ data }) {
	const { closeModal, openModal } = useSelectModal();
	const { setToast } = useToaster();
	const [rescheduleOrder, { status }] = useRescheduleOrCancelMutation();
	const [allSlots, setAllSlots] = useState([]);
	const [reschedulingTimeSlot, { data: allTimeSlots, status: timeSlotStatus }] =
		useReschedulingTimeSlotMutation();

	const [selectedDate, setSelectedDate] = useState(new Date(data?.serviceDate));
	const [selectedSlot, setSetSelectedSlot] = useState(null);
	const now = moment().toDate();
	const maxDate = moment().add(6, "days").toDate();

	useEffect(() => {
		if (data && selectedDate) {
			getAvailableTimeSlots(selectedDate, data);
		}
	}, [selectedDate]);
	const getAvailableTimeSlots = async (sDate, appointmentData) => {
		const { data: availableSlot, error } = await reschedulingTimeSlot({
			coOrdinates: {
				latitude: appointmentData?.coOrdinates?.latitude,
				longitude: appointmentData?.coOrdinates?.longitude,
			},
			areaId: appointmentData?.areaId,
			serviceDate: moment(sDate).format("YYYY-MM-DD"),
			duration: "sixty",
			orderId: data?._id,
			serviceId: appointmentData?.service?.map((ser) => ser?._id)
		});
		if (availableSlot) {
			setAllSlots(availableSlot);
			setSetSelectedSlot(availableSlot.find((slotData) => slotData?.timeslot == data?.timeSlot));
		}
		if (error)
			setToast({
				type: "error",
				msg: error?.data?.message ? error?.data?.message : "Error while fetching slots",
			});
	};

	const handleReschedule = async () => {
		if (selectedSlot && Object.keys(selectedSlot).length > 0) {
			rescheduleOrder({
				data: {
					serviceDate: selectedDate,
					timeSlot: selectedSlot.timeslot,
				},
				orderId: data._id,
			}).unwrap()
				.then(() => {
					setToast({ type: "success", msg: "Order Rescheduled" });
					closeModal();
          data?.refetch && data?.refetch()
				})
				.catch((error) => {
					setToast({ type: "error", msg: error.data.message });
					closeModal();
				});
		}
	};

	// const getSlots = () => {
	// 	let slots = allSlots;
	// 	if (moment().isSame(selectedDate, "day")) {
	// 		slots = allSlots.filter((slot) => {
	// 			const currentTime = moment();
	// 			const endTimeOfSlot = moment(slot.value.split("-")[1], "h A"); // Get the end time of the slot

	// 			return endTimeOfSlot.isAfter(currentTime);
	// 		});
	// 	}
	// 	return slots;
	// };

	const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
		<div
			className={`flex gap-3 border rounded-md text-sm h-[2.3rem] p-2 bg-white pr-4 ${
				!value && "text-gray-500"
			}`}
			onClick={onClick}
			ref={ref}
		>
			<Calendar className="h-5 w-5" />
			{value || placeholder}
		</div>
	));

	return (
		<Formik
			validationSchema={schema}
			initialValues={{
				selectedSlot: "",
			}}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form action="#" method="POST">
					<div className="w-[calc(353px+9.444vw)] p-6 px-8 bg-[#FFFFFF] rounded-xl pb-8">
						<div className="flex font-inter-medium justify-center pt-4 text-2xl pb-3 text-center">
							Preferred Time slot
						</div>
						<div className="pb-5 text-center">
							<span className="text-base   font-inter-medium text-center">
								Select Preferred Slot
							</span>
						</div>
						<div className="flex gap-3 items-center">
							<div className="w-1/2 h-full">
								<div className="w-full prefered-slot-modal mt-1">
									<DatePicker
										minDate={now}
										maxDate={maxDate}
										selected={selectedDate}
										onChange={(date) => setSelectedDate(date)}
										className="w-full rounded-lg bg-[#f1f1f1] border-[#d9d9d9] mt-1"
										dateFormat="d MMMM, yyyy"
										customInput={<CustomInput />}
										showIcon
									/>
								</div>
							</div>
							<div className="w-1/2 h-full">
								<SelectMenu
									name="selectedSlot"
									placeholder="Select Slot"
									options={allSlots}
									isLoading={timeSlotStatus !== "fulfilled"}
									onChange={(option) => setSetSelectedSlot(option)}
									value={selectedSlot}
									className="w-full rounded-lg bg-[#f1f1f1] border-[#d9d9d9]"
									hideSelectedOptions={false}
								/>
							</div>
						</div>

						{errors?.selectedSlot && touched.selectedSlot ? (
							<div className="text-red-600 text-sm">{errors?.selectedSlot}</div>
						) : (
							<div className="invisible h-5"></div>
						)}
						<div className="flex justify-center pt-12 pb-1">
							<Button
								className="w-[70%] text-white cursor-pointer bottom-btn-ht"
								type="submit"
								onClick={handleReschedule}
								isSubmitting={status === "pending"}
							>
								Submit change request
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default RescheduleModal;
