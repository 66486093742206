import React from 'react'
import BackButton from '../../shared/components/BackButton.component'
import Button from '../../shared/components/Button.component'
import { Formik, Field, Form } from 'formik';
import RoleTableComponent from './Role_Table_Component';
import { original } from '@reduxjs/toolkit';
import { ArrowDown2 } from 'iconsax-react';

function AddNewRole() {

    const menuName = [
        {
            name: "Rider Management 1",
            hasSubRows: true,
            subRows: [
                {
                    rowName: "All Riders"
                },
                {
                    rowName: "All Riders 2"
                },
                {
                    rowName: "All Riders 3"
                }
            ]
        },
        {
            name: "Rider Management 2",
            hasSubRows: true,
            subRows: [
                {
                    rowName: "All Riders 4"
                },
                {
                    rowName: "All Riders 5"
                },
                {
                    rowName: "All Riders 6"
                }
            ]
        }
    ]

    const columns = [
        {
            header: "Menu Name",
            accessorKey: "name",
            cell: ({ row }) => {
                const { original } = row;
                return row.getCanExpand() ? (
                    <button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: 'pointer'},
                        }}
                        className='flex gap-2 items-center w-full relative before:content pl-4 before:w-[4px] before:h-[26px] before:bg-[#FB0C0C] before:rounded-sm before:absolute before:left-0'
                    >
                        {/* {row.getIsExpanded() ? '👇' : '👉'}{original?.name} */}
                        <ArrowDown2 size={16} color="#000" className={`${row.getIsExpanded() ? 'rotate-180' : ''}`}/>{original?.name}
                    </button>
                ) : (
                    '🔵'
                );
            },
        },
        {
            header: "All",
            accessorKey: "all",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium">
                        <Field
                            type="checkbox"
                            name="all"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            header: "Read",
            accessorKey: "read",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"

                        />
                    </span>
                );
            },
        },
        {
            header: "Delete",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            header: "Create",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            header: "Edit",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
    ];

    const extractedSubRows = menuName.map(subItem => subItem.subRows);

    const subTableColumns = [
        {
            header: () => null,
            accessorKey: "name",
            cell: ({ row }) => {
                // const subRows = row.original.subRows;
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium">
                        {/* {subRows?.map((subRowName) => subRowName.name)} */}
						{original?.rowName}
                    </span>
                );
            },
        },
        {
            header: () => null,
            accessorKey: "all",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium mx-auto">
                        <Field
                            type="checkbox"
                            name="all"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            header: () => null,
            accessorKey: "read",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="truncate font-inter-medium mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"

                        />
                    </span>
                );
            },
        },
        {
            header: () => null,
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            header: () => null,
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            header: () => null,
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 mx-auto">
                        <Field
                            type="checkbox"
                            name="read"
                            className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                        />
                    </span>
                );
            },
        },
    ];

    // const renderSubComponent = ({ row }) => {
    //     console.log(row);
    //     const { subRows } = row.original;
    //     console.log(subRows, 'sub rows')
    //     return (
    //         <>
    //             <table className='w-full'>
    //                 <tbody>
    //                     {subRows.map((subRow, rowIndex) => (
    //                         <tr key={rowIndex}>
    //                             {subColumns.map((subColumn, colIndex) => (
    //                                 <td className='px-2 py-4 text-center' key={colIndex} style={{ width: subColumn.width }}>{subColumn.cell({ row: subRow })}</td>
    //                             ))}
    //                         </tr>
    //                     ))}
    //                 </tbody>
    //             </table>
    //         </>
    //     );
    // };


    return (
        <div className=" bg-[#F5F5F5] h-full pt-8">
            <div className="space-y-3 px-8 pb-4">
                <div className="flex justify-between">
                    <div className="flex gap-3 items-center">
                        <BackButton />
                        <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Add New Role</h3>
                    </div>
                    <Button
                        className="text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6 2xl:text-base text-sm w-[12%]"
                    // onClick={() => navigate(`/add-new-car`, { state: { brandData } })}
                    >
                        Save
                    </Button>
                </div>

                <div className="pl-8 pt-9">
                    <Formik>
                        <div className='border shadow rounded-xl px-10 py-5 bg-[#FFFFFF] w-full h-[74vh]'>
                            <div className='flex justify-between'>
                                <div className='bg-[#F9FAFB] p-6 border-[#D9D9D9] border rounded-[20px] w-[42%]'>
                                    <p className='font-inter-bold text-base mb-1'>Role Name</p>
                                    <input
                                        type="text"
                                        className="w-full outline-focus h-[2.5rem] border-[#D9D9D9] rounded-xl py-1.5 pl-4 pr-3 text-base font-inter-regular bg-[#FFFFFF] placeholder-[#757575] focus:outline-none focus:border-[#E9E9E9] focus:ring-transparent"
                                        placeholder="Enter Role Name"
                                    // value={inputValue}
                                    // onChange={handleChange}
                                    />
                                </div>

                                <div className='bg-[#F9FAFB] p-6 border-[#D9D9D9] border rounded-[20px] w-[54%]'>
                                    <div className='flex items-center justify-between'>
                                        <p className='font-inter-bold text-base mb-1'>Menu Data Visibility</p>
                                        <div className='border border-[#D9D9D9] rounded-[20px] bg-[#F4F4F4] p-4 w-[6.5rem] text-center'>
                                            <p className='font-inter-medium text-sm'>Self</p>
                                            <Field
                                                type="checkbox"
                                                name="self"
                                                className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                                            // id={fieldName}
                                            // onChange={(e) => {
                                            //     setFieldValue(fieldName, e.target.checked);
                                            //     updateSelectedShifts(activeDay, {
                                            //         ...values,
                                            //         [fieldName]: e.target.checked,
                                            //     });
                                            // }}
                                            />
                                        </div>

                                        <div className='border border-[#D9D9D9] rounded-[20px] bg-[#F4F4F4] p-4 w-[6.5rem] text-center'>
                                            <p className='font-inter-medium text-sm'>Hierarchy</p>
                                            <Field
                                                type="checkbox"
                                                name="hierarchy"
                                                className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                                            // id={fieldName}
                                            // onChange={(e) => {
                                            //     setFieldValue(fieldName, e.target.checked);
                                            //     updateSelectedShifts(activeDay, {
                                            //         ...values,
                                            //         [fieldName]: e.target.checked,
                                            //     });
                                            // }}
                                            />
                                        </div>

                                        <div className='border border-[#D9D9D9] rounded-[20px] bg-[#F4F4F4] p-4 w-[6.5rem] text-center'>
                                            <p className='font-inter-medium text-sm'>Cities</p>
                                            <Field
                                                type="checkbox"
                                                name="hierarchy"
                                                className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                                            // id={fieldName}
                                            // onChange={(e) => {
                                            //     setFieldValue(fieldName, e.target.checked);
                                            //     updateSelectedShifts(activeDay, {
                                            //         ...values,
                                            //         [fieldName]: e.target.checked,
                                            //     });
                                            // }}
                                            />
                                        </div>

                                        <div className='border border-[#D9D9D9] rounded-[20px] bg-[#F4F4F4] p-4 w-[6.5rem] text-center'>
                                            <p className='font-inter-medium text-sm'>Area</p>
                                            <Field
                                                type="checkbox"
                                                name="hierarchy"
                                                className="form-checkbox h-4 w-4 checkbox-custom mx-auto"
                                            // id={fieldName}
                                            // onChange={(e) => {
                                            //     setFieldValue(fieldName, e.target.checked);
                                            //     updateSelectedShifts(activeDay, {
                                            //         ...values,
                                            //         [fieldName]: e.target.checked,
                                            //     });
                                            // }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='mt-8'>
                                <div className='bg-[#F9FAFB] p-6 border-[#D9D9D9] border rounded-[20px] w-[full]'>
                                    <div className='flex justify-between'>
                                        <div className='w-[49%]'>
                                            <RoleTableComponent
                                                data={menuName}
                                                columns={columns}
                                                customeClass='max-h-[38vh]'
                                                customwidths={["w-[50%]", "w-[10%]", "w-[10%]", "w-[10%]", "w-[10%]", "w-[10%]"]}
                                                getRowCanExpand={() => true}
                                                // renderSubComponent={renderSubComponent}
                                                subTableColumns={subTableColumns}
                                                subRowsData={extractedSubRows}
                                            />
                                        </div>

                                        <div className='w-[49%]'>
                                            <RoleTableComponent
                                                data={menuName}
                                                columns={columns}
                                                customeClass='max-h-[65vh]'
                                                customwidths={["w-[50%]", "w-[10%]", "w-[10%]", "w-[10%]", "w-[10%]", "w-[10%]"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddNewRole