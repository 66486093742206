import { useUploadFileMutation } from "../Services/Apis/fileUpload";
import useToaster from "./useToaster";

function formatFileSize(sizeInBytes) {
	if (sizeInBytes < 1024) {
		return sizeInBytes + " B";
	} else if (sizeInBytes < 1024 * 1024) {
		return Math.floor(sizeInBytes / 1024) + " KB";
	} else {
		return Math.floor(sizeInBytes / (1024 * 1024)) + " MB";
	}
}

export default function useGetImageUploadData() {
	const [uploadImage, { status: uploadImgStatus }] = useUploadFileMutation();
	const { setToast } = useToaster();
	const uploadImages = async (object) => {
		const promises = Object.keys(object).map((key) => {
			var formData = new FormData();
			formData.append("uploadFile", object[key]);

			return uploadImage(formData).then((response) => {
				// Process the response and return a specific value
				console.log(object[key]);
				return {
					url: response.data.urls[0],
					name: object[key].name,
					size: formatFileSize(object[key].size),
					type: object[key].type,
				};
			});
		});

		try {
			return await Promise.all(promises); // Return the result directly
		} catch (error) {
			console.error("Failed to upload one or more images", error);
			setToast({ type: "error", msg: "Failed to upload one or more files" });
		}
	};

	return { uploadImages, uploadImgStatus };
}
