import React, { useEffect } from "react";
import Tabs from "../../shared/components/Tabs.component";
import { useState } from "react";
import CardService from "./CardService";
import Button from "../../shared/components/Button.component";
import { useNavigate, useParams } from "react-router-dom";
import { CalendarTick, ArrowCircleDown2, CalendarRemove, TickCircle } from "iconsax-react";
import DatePickerComponent from "../../shared/components/DatePicker.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useLazyGetOrderByRiderAndStatusQuery, useLazyGetOrderByStatusQuery, useLazyGetOrderCountQuery, useLazyGetOrdersCountByRiderQuery } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";
import InfiniteScroll from "react-infinite-scroll-component";
import { Hidden } from "@mui/material";
import { useRef } from "react";
import { LuLoader2 } from "react-icons/lu";
import CardServiceSkeleton from "../../shared/components/CardServiceSkeleton";

function ServiceRequestMangement() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const { setToast } = useToaster();
    const [getOrderCounts, { data: orderCounts }] = useLazyGetOrderCountQuery();
    const [getOrderCountsByRider, { data: orderCountsByRider }] = useLazyGetOrdersCountByRiderQuery();
    const [getOrderByStatus] = useLazyGetOrderByStatusQuery();
    const [ordersData, setOrdersData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const isLoadingRef = useRef(false);
    const [getOrderByRiderAndStatus] = useLazyGetOrderByRiderAndStatusQuery();
    const {riderId} = useParams();

    useEffect(() => {
        if(riderId){
            getOrderCountsByRider({riderId});
        }else{
            getOrderCounts();
        }
        fetchInitialData();
    }, []);

    useEffect(() => {
        fetchInitialData();
    }, [selectedIndex]);

    const fetchInitialData = async () => {
        isLoadingRef.current = true
        let data, error;
        if(riderId){
            ({ data, error } = await getOrderByRiderAndStatus({
                status: categories[selectedIndex].status,
                riderId: riderId,
                params: { page: 1 },
            }));
        }else{
            ({ data, error } = await getOrderByStatus({
                status: categories[selectedIndex].status,
                params: { page: 1 },
            }));
        }
        if (data) {
            setOrdersData(data.orders);
            setCurrentPage(2);
            setHasMoreRecords(data.orders.length > 0);
            isLoadingRef.current = false
        }
        if(error) {
            setToast({ type: "error", msg: error.data.message });
            isLoadingRef.current = false
        }
    };

    const fetchMoreData = async () => {
        let data, error;
        if(riderId){
            ({ data, error } = await getOrderByRiderAndStatus({
                status: categories[selectedIndex].status,
                riderId: riderId,
                params: { page: currentPage },
            }));
        }else{
            ({ data, error } = await getOrderByStatus({
                status: categories[selectedIndex].status,
                params: { page: currentPage },
            }));
        }
        if (data) {
            setOrdersData(prev => [...prev, ...data.orders]);
            setCurrentPage(prev => prev + 1);
            setHasMoreRecords(data.orders.length > 0);
        }
        if (error) {
            setToast({ type: "error", msg: error.data.message });
        }
    };

    const renderCards = (status) => {
        if(ordersData?.length){
			return ordersData.map((order, index) => (
				<CardService status={status} orderDetails={order} key={order.id || index} refetch={fetchInitialData}/>
			));
		}else{
			return (
				<div className="h-96 w-full justify-center flex items-center">
					<p>No Data Found</p>
				</div>
			)
		}
    };

    let categories = [
        {
            title: `Requested (${(riderId ? orderCountsByRider?.requested : orderCounts?.requested) || "00"})`,
            status: "requested",
            component: renderCards("requested"),
        },
        {
            title: `Confirmed (${(riderId ? orderCountsByRider?.confirmed : orderCounts?.confirmed) || "00"})`,
            status: "confirmed",
            component: renderCards("confirmed"),
        },
        {
            title: `Cancelled (${(riderId ? orderCountsByRider?.canceled : orderCounts?.canceled) || "00"})`,
            status: "canceled",
            component: renderCards("canceled"),
        },
    ];

    return (
        <div className="relative bg-gray-100 h-full">
            <div className="w-[75%] 2xl:w-3/4 sm:px-0">
                <div className="px-8 pb-5 pt-8">
                    <div className="flex">
                        <h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">Appointment Management</h3>
                    </div>
                </div>
                <div className="flex justify-between pl-8 mb-6 pr-3">
                    <Tabs
                        categories={categories}
                        className="w-[30vw]"
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                    />
                    <Button
                        className="2xl:text-base text-sm text-white cursor-pointer reb-btn-shadow 2xl:px-8 px-6"
                        onClick={() => navigate("/add-new-service")}
                    >
                        Add New Appointment
                    </Button>
                </div>

                {isLoadingRef.current ? <div className="w-full justify-center flex items-center">
					<CardServiceSkeleton 
                        className=""
                        dataLength={ordersData.length}
                    />
				</div> : <div className="overflow-y-auto overflow-x-hidden pl-8">
                    <InfiniteScroll
                        hasMore={hasMoreRecords}
                        next={fetchMoreData}
                        height="71vh"
                        scrollThreshold={0.8}
                        dataLength={ordersData.length}
                    >
                        {categories[selectedIndex].component}
                    </InfiniteScroll>
                </div>}
            </div>

            <div className="w-[25%] 2xl:w-1/4 absolute inset-y-0 top-11 right-0 pl-3 h-full">
                <div className="absolute w-0.5 bg-gray-300 h-[750px] mt-6"></div>

                <div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
                    <div className="bg-[#FF621F] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
                        <CalendarRemove color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
                    </div>
                    <div className="pl-5">
                        <h1 className="text-base 2xl:text-lg font-inter-regular">Requested Appointment</h1>
                        <h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
                            {riderId ? orderCountsByRider?.requested : orderCounts?.requested}
                        </h1>
                    </div>
                </div>

                <div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
                    <div className="bg-[#00BE68] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
                        <CalendarTick color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
                    </div>
                    <div className="pl-5">
                        <h1 className="text-base 2xl:text-lg font-inter-regular">Confirmed Appointment</h1>
                        <h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
                            {riderId ? orderCountsByRider?.confirmed : orderCounts?.confirmed}
                        </h1>
                    </div>
                </div>

                <div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
                    <div className="bg-[#FB0C0C] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
                        <CalendarTick color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
                    </div>
                    <div className="pl-5">
                        <h1 className="text-base 2xl:text-lg font-inter-regular">Cancelled Appointment</h1>
                        <h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
                            {riderId ? orderCountsByRider?.canceled : orderCounts?.canceled}
                        </h1>
                    </div>
                </div>

                {/* <div className="w-72 h-28 bg-white m-6 rounded-2xl flex items-center justify-start pl-3">
                    <div className="bg-[#198754] h-14 w-14 rounded-full flex items-center justify-center">
                        <TickCircle size={30} color="#FFFFFF" />
                    </div>
                    <div className="pl-5">
                        <h1 className="text-lg font-medium">Completed Services</h1>
                        <h1 className="text-2xl font-bold">03</h1>
                    </div>
                </div>

                <div className="w-72 h-28 bg-white m-6 rounded-2xl flex items-center justify-start pl-3">
                    <div className="bg-[#315EFF] h-14 w-14 rounded-full flex items-center justify-center">
                        <CalendarTick size={30} color="#FFFFFF" />
                    </div>
                    <div className="pl-5">
                        <h1 className="text-lg font-medium">Rescheduled Services</h1>
                        <h1 className="text-2xl font-bold">2</h1>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default ServiceRequestMangement;