import React from 'react'
import Button from '../../shared/components/Button.component'
import NewPackage from "../../assets/icons/package.svg";
import EditSubscription from "../../assets/icons/Edit.png";
import useSelectModal from '../../utils/hooks/useSelectModal';
import Edit from '../../assets/icons/Edit.png';

function PackageModal({ data }) {
    const { closeModal } = useSelectModal();

    return (
        <div className='w-[832px] border rounded-xl 2xl:h-[42vw] h-[46vw] overflow-y-hidden'>
            <div className='p-5'>
                <div className='pb-5'>
                    <div className='flex items-center justify-between'>
                        <div className={`h-14 w-14 ${data ? "bg-[#FFC107]" : "bg-[#0D6EFD]"} rounded-full flex items-center justify-center`}>
                            {data ?
                                <img src={NewPackage} color='white' />
                                :
                                <img src={Edit} className='h-6' color='white' />
                            }
                        </div>
                    </div>
                    <div className='py-3'>
                        {data ?
                            <h1 className='text-xl font-bold'>Create New Package</h1>
                            :
                            <h1 className='text-xl font-bold'>Edit Package</h1>
                        }
                    </div>
                    <div className=''>
                        {data ?
                            <h1 className='text-sm font-medium text-[#475467]'>Create New Package by Adding the details below</h1>
                            :
                            <h1 className='text-sm font-medium text-[#475467]'>Edit Package details by changing the details below</h1>
                        }
                    </div>
                </div>

                <div className='h-[32.5vw] overflow-y-scroll'>
                    <div className=' '>
                        <p className='text-sm font-medium text-[#111827] py-2'>Enter Package Name</p>
                        <input type='text' placeholder='Enter Package Name' className='border-[#E5E5E5] rounded-xl w-full' />
                    </div>
                    <div className=''>
                        <div className='flex space-x-2 border rounded-xl p-2 my-3 bg-[#F7F7F7]'>
                            <div className='w-1/2 '>
                                <div className=''>
                                    <h1 className='text-sm font-medium text-[#111827] my-3'>Select Service to add in Package</h1>
                                    <div className=''>
                                        <select className='border-[#E5E5E5] rounded-xl w-full'>
                                            <option value="Option1" className='text-[#475467]'>Option1</option>
                                            <option value="Option2" className='text-[#475467]'>Option2</option>
                                            <option value="Option3" className='text-[#475467]'>Option3</option>
                                        </select>
                                    </div>
                                    <div className='flex pt-5 space-x-10 '>
                                        <h1 className='text-sm font-medium text-[#111827] my-3'>Total Selected Services (0)</h1>
                                        <h1 className='text-sm font-medium text-[#111827] my-3'>Total Service Cost - ₹ 0</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/2 flex space-x-4'>
                                <div className='w-full mt-11'>
                                    <Button className='w-full bg-[#000000] text-white cursor-pointer'>Add Service</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex space-x-4'>
                        <div className='w-1/2 ' >
                            <div className='space-x-2 my-3'>
                                <input type="checkbox" id="checkbox" className="checkbox" />
                                <label htmlFor="checkbox">Include GST in prices</label>
                            </div>
                            <div className='flex space-x-4 h-10 my-3'>
                                <h1 className='text-sm font-medium text-[#111827] my-3'>Add GST %</h1>
                                <div className='w-1/4 border rounded-xl flex justify-around items-center'>
                                    <h1 className='text-sm font-medium text-[#111827] my-3 py-2 pr-2.5 border-r-2 h-full'>%</h1>
                                    <h1 className='text-sm font-medium text-[#475467] my-3 '>12</h1>
                                </div>
                            </div>
                            <h1 className='text-sm font-medium text-[#111827] my-3'>Discount Type</h1>
                            <div className=''>
                                <select className='border-[#E5E5E5] rounded-xl w-full'>
                                    <option value="Flat Type Discount" className='text-[#475467]'>Flat Type Discount</option>
                                    <option value="Flat Type Discount" className='text-[#475467]'>Flat Type Discount</option>
                                </select>
                            </div>
                            <h1 className='text-sm font-medium text-[#111827] my-3'>Select City</h1>
                            <div className=''>
                                <select className='border-[#E5E5E5] rounded-xl w-full'>
                                    <option value="City1" className='text-[#475467]'>City1</option>
                                    <option value="City2" className='text-[#475467]'>City2</option>
                                    <option value="City3" className='text-[#475467]'>City3</option>
                                </select>
                            </div>
                            <h1 className='text-sm font-medium text-[#111827] my-3'>Payment Mode</h1>
                            <div className=''>
                                <select className='border-[#E5E5E5] rounded-xl w-full'>
                                    <option value="Select1" className='text-[#475467]'>Select1</option>
                                    <option value="Select2" className='text-[#475467]'>Select2</option>
                                </select>
                            </div>
                            <Button className='w-full mt-5 bg-[#F1F1F1] text-[#000000] cursor-pointer' onClick={closeModal}>Cancel</Button>
                        </div>

                        <div className='w-1/2 pt-1' >
                            <h1 className='text-sm font-medium text-[#111827] my-3'>Enter the Price of Package</h1>
                            <div className=''>
                                <div className='h-10 border rounded-xl flex flex-start space-x-10 pl-5 items-center'>
                                    <h1 className='text-sm font-medium text-[#111827] my-3 p-2 pr-10 border-r-2 h-full'>₹</h1>
                                    <h1 className='text-sm font-medium text-[#475467] my-3'>0</h1>
                                </div>
                            </div>
                            <h1 className='text-sm font-medium text-[#111827] my-3'>Discount Amount</h1>
                            <div className=''>
                                <div className='h-10 border rounded-xl flex flex-start space-x-10 pl-5 items-center'>
                                    <h1 className='text-sm font-medium text-[#111827] my-3 p-2 pr-10 border-r-2 h-full'>₹</h1>
                                    <h1 className='text-sm font-medium text-[#475467] my-3'>0</h1>
                                </div>
                            </div>
                            <h1 className='text-sm font-medium text-[#111827] my-3'>Select Area</h1>
                            <div className=''>
                                <select className='border-[#E5E5E5] rounded-xl w-full'>
                                    <option value="Area1" className='text-[#475467]'>Area1</option>
                                    <option value="Area2" className='text-[#475467]'>Area2</option>
                                    <option value="Area3" className='text-[#475467]'>Area3</option>
                                </select>
                            </div>
                            <div className='mt-9'>
                                <h1 className='text-sm font-medium text-[#111827] my-3'>Final Package Price</h1>
                                <div className='mt-3'>
                                    <h1 className='text-sm font-medium text-[#111827] my-3'>₹ 0</h1>
                                </div>
                            </div>
                            {data ?
                                <Button className='w-full mt-5 bg-[#FB0C0C] text-white cursor-pointer'>Create New Package</Button>
                                :
                                <Button className='w-full mt-5 bg-[#FB0C0C] cursor-pointer'>Save</Button>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PackageModal

