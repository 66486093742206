import React from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'
import TableComponent from '../../shared/components/Table.component';
import useSelectModal from '../../utils/hooks/useSelectModal';
import Button from '../../shared/components/Button.component';
import { useLazyGetSingleOrderQuery, useLazyGetDuesQuery } from '../../utils/Store';
import { useEffect } from 'react';
import moment from 'moment/moment'

function CustomerDueAmount() {
    const navigate = useNavigate();
    const [getOrders, { data: ordersData, status: ordersDataStatus }] = useLazyGetDuesQuery();
    const { openModal } = useSelectModal();
    const { customerId } = useParams();

    useEffect(() => {
        getOrders(customerId)
    }, []);

    const columns = [
        {
            header: 'Service Name',
            cell: ({ row, getValue }) => {
                // const {original} = row
                const { orderId, dueAmount, dueStatus, serviceName } = row.original;
                return (
                    <div>
                        <p
                            className='font-inter-regular text-base cursor-pointer'
                            onClick={() => {
                                openModal("CustomerServiceModal", {orderId, dueAmount, serviceName, dueStatus, customerId, customeClassForModal: 'overflow-hidden modal-down' })
                            }}>{row.original.serviceName}</p>
                    </div>
                )
            }
        },
        {
            header: 'Rider Name',
            cell: ({ row }) => {
                return (
                    <p>{row.original.riderName}</p>
                )
            }
        },
        {
            header: 'Date',
            cell: ({ row }) => {
                return (
                    <p>{`${moment(row?.original?.created).format('l')} ${moment(row?.original?.created).format('LT')}`}</p>
                )
            }
        },
        {
            header: 'Service Price',
            cell: ({ row }) => {
                return (
                    <p>₹ {row.original.servicePrice}</p>
                )
            }
        },
        {
            header: 'Due Amount',
            cell: ({ row }) => {
                return (
                    <p>₹ {row.original.dueAmount}</p>
                )
            }
        },
        {
            header: 'Settled Amount',
            cell: ({ row }) => {
                return (
                    <p>₹ {row.original.settlementAmount}</p>
                )
            }
        },
        {
            header: '',
            accessorKey: 'Action',
            cell: ({ row }) => {
                if(row.original.dueStatus === "cleared") return <div className="text-[16] font-medium text-[#31BA2E]">Cleared</div>
                if(row.original.dueStatus === "no due") return <div className="text-[16] font-medium">-</div>
                return (
                    <Button
                        className="2xl:text-base text-sm text-white cursor-pointer 2xl:px-4 px-4 py-2 w-[8vw] font-inter-regular rounded-xl"
                        customeHeight="h-10"
                        // type="submit"
                        onClick={() => {
                            openModal("clearDueAmountModal",{dueAmount:row.original.dueAmount, customerId:customerId, orderId:row.original?.orderId})
                        }}
                    >
                        Clear Due
                    </Button>
                )
            }
        },
    ]
    return (
        <div className="h-full pl-8 pr-24 pb-4 pt-8 w-full bg-[#F5F5F5]">
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="flex items-center space-x-4">
                        <FiArrowLeft
                            className="cursor-pointer back-arrow"
                            size={22}
                            onClick={() => navigate(-1)}
                        />
                        <h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                            Due Amount
                        </h1>
                    </div>
                </div>

                <div className='bg-white border rounded-xl px-5 pt-3 my-3 h-[78.4vh] overflow-hidden brand-details-section relative'>
                    <TableComponent
                        data={ordersData}
                        columns={columns}
                        customwidths={["w-[15%]", "w-[10%]", "w-[17.5%]", "w-[15%]", "w-[15%]", "w-[15%]", "w-[12.5%]"]}
                        isLoading={ordersDataStatus === 'pending'}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomerDueAmount