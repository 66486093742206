import { useUploadFileMutation } from "../Services/Apis/fileUpload";

export default function useUploadImage() {
	const [uploadImage, {status: uploadImgStatus}] = useUploadFileMutation();

	const uploadImages = async (object) => {
		const promises = Object.keys(object).map((key) => {
			var formData = new FormData();
			formData.append("uploadFile", object[key]);

			return uploadImage(formData).then((response) => {
				// Process the response and return a specific value
				return response.data.urls[0];
			});
		});

		try {
			return await Promise.all(promises); // Return the result directly
		} catch (error) {
			console.error("Failed to upload one or more images", error);
		}
	};

	return { uploadImages, uploadImgStatus };
}
