import React from "react";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import Button from "../../../shared/components/Button.component";
import { useNavigate } from "react-router-dom";
import useToaster from "../../../utils/hooks/useToaster";
import CheckIcon from "../../../assets/icons/check.svg";


function AddShiftModal({ data }) {
	const { closeModal } = useSelectModal();

	const handleAddShifts = () => {
		data.refetchRidersShift();
		data.setShiftsFormVisible();
		closeModal();
	};

	return (
		<div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
			<div className="flex items-center justify-center">
				<div className="bg-[#C3FFD1] h-[4.5rem] w-[4.5rem] rounded-full flex justify-center items-center ">
					<img src={CheckIcon} />
				</div>
			</div>
			<div className="flex font-inter-medium justify-center py-4 text-lg text-center">
				{
					data.isEditMode
						? <>The shift for “{data?.riderName}’’ has been successfully updated and will be effective starting tomorrow</>
						: <>New Shift for “{data?.riderName}’’ has been<br />Created !</>
				}
			</div>
			<div className="flex justify-between ">
				<Button
					className="w-[47.5%] bg-[#F1F1F1] text-[#000]cursor-pointer border border-solid border-[#D0D0D0] font-inter-medium text-base"
					onClick={closeModal}
				>
					Cancel
				</Button>
				<Button
					className="w-[47.5%] text-white cursor-pointer"
					type="submit"
					onClick={handleAddShifts}
				    // isSubmitting={status==="pending"}
				>
					Okay
				</Button>
			</div>
		</div>
	);
}

export default AddShiftModal;