import React, { useState, useEffect, useRef } from 'react'
import DatePicker from "react-datepicker"
import moment from "moment"
import { Calendar, EmptyWallet, InfoCircle, MoneyRecive } from 'iconsax-react'
import { useLazyGetCashCollectionCountQuery, useLazyGetTotalCashReceivedQuery, useLazyGetTotalCashDepositedQuery, useLazyGetAvailableAmountQuery, useLazyGetAllCashCollectionQuery } from '../../../utils/Store'
import { useParams } from 'react-router-dom'
import TableInfiniteScroll from '../../../shared/components/TableInfiniteScroll'
import useToaster from '../../../utils/hooks/useToaster'

function CashCollection({ selectedIndex }) {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [cashCollectionData, setCashCollectionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState({ name: "Today", value: "today" });
    const [getCashCollection, { data: cashCollectionList }] = useLazyGetCashCollectionCountQuery();
    const [getTotalCashReceived, { data: cashReceivedList }] = useLazyGetTotalCashReceivedQuery();
    const [getTotalCashDeposited, { data: cashDepositedList }] = useLazyGetTotalCashDepositedQuery();
    const [getAvailableAmount, { data: availableAmountList }] = useLazyGetAvailableAmountQuery();
    const [getAllCashCollection, { data: allCashCollectionList }] = useLazyGetAllCashCollectionQuery();
    const { riderId } = useParams();
    const { setToast } = useToaster();
    const isLoadingRef = useRef(false);

    const totalCollectedCash = cashCollectionData.reduce((sum, item) => sum + item.amount, 0);

    useEffect(() => {
        handleSetDate();
    }, [selectedOptions]);

    useEffect(() => {
        handleCashCollectionData();
        handleCashReceivedData();
        handleCashDepositedData();
        handleAvailableAmountData();
    }, [startDate, endDate]);

    useEffect(() => {
        fetchInitialData()
    }, [startDate, endDate]);

    const handleCashCollectionData = async () => {
        let data;
        if (riderId) {
            ({ data } = await getCashCollection({
                riderId: riderId,
                params: { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') },
            }));
        }
    }

    const handleCashReceivedData = async () => {
        let data;
        if (riderId) {
            ({ data } = await getTotalCashReceived({
                riderId: riderId,
                params: { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') },
            }));
        }
    }

    const handleCashDepositedData = async () => {
        let data;
        if (riderId) {
            ({ data } = await getTotalCashDeposited({
                riderId: riderId,
                params: { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') },
            }));
        }
    }

    const handleAvailableAmountData = async () => {
        let data;
        if (riderId) {
            ({ data } = await getAvailableAmount({
                riderId: riderId,
                params: { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') },
            }));
        }
    }

    const fetchInitialData = async () => {
        isLoadingRef.current = true;
        const { data, error } = await getAllCashCollection({
            riderId: riderId,
            params: {
                page: 1,
                pageSize: 10,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
            },
        });
        if (data) {
            setCashCollectionData(data.orders);
            setCurrentPage(2);
            setHasMoreRecords(data.orders.length > 0);
            isLoadingRef.current = false;
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
            isLoadingRef.current = false;
        }
    };

    const fetchMoreData = async () => {
        const { data, error } = await getAllCashCollection({
            riderId: riderId,
            params: {
                page: currentPage,
                pageSize: 10,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
            },
        });
        if (data) {
            setCashCollectionData((prev) => [...prev, ...data.orders]);
            setCurrentPage((prev) => prev + 1);
            setHasMoreRecords(data.orders.length > 0);
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
        }
    };

    const columns = [
        {
            header: "Services",
            cell: ({ row }) => {
                const { original } = row;
                return (
                    <span className='pl-4 w-full'>
                        <span className="flex gap-3 overflow-x-auto final-price-scrollbar">
                            {original?.servicename?.map((service, index) => (
                                <span key={index} className="font-inter-medium whitespace-nowrap">
                                    {service?.serviceName}
                                </span>
                            ))}
                        </span>
                    </span>
                );
            },
        },
        {
            header: "Add-On",
            cell: ({ row }) => {
                const { original } = row;
                return (
                    <span className="pl-4 flex gap-3">
                        <span className="">
                            {original?.addOnServices?.map((addOn, index) => (
                                <span className='font-inter-medium whitespace-nowrap' key={index}>{addOn?.serviceName}</span>
                            ))}
                        </span>
                        {original.addOnServices && original.addOnServices.length > 1 && (
                            <span className='flex gap-4 items-center'>
                                <span>
                                    {original.addOnServices.length}
                                </span>
                                <div className="relative group z-10">
                                    <InfoCircle
                                        color="#0d6efd"
                                        onClick={() => handleInfo(original)}
                                        size="16"
                                        className="cursor-pointer info-circle-size"
                                    />
                                    <div className={`absolute hidden ${original.addOnServices?.length && "group-hover:block"
                                        }  left-4 top-0`}>
                                    </div>
                                </div>
                            </span>
                        )}
                    </span>
                );
            },
        },
        {
            header: "Date",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 pl-4">
                        <span className='font-inter-medium'>{moment(original?.serviceDate).format('DD-MM-YYYY')}</span>
                    </span>
                );
            },
        },
        {
            header: "Cash Collection",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 pl-4">
                        <span className='font-inter-medium'>{original?.amount}</span>
                    </span>
                );
            },
        },
    ]

    const handleSetDate = () => {
        switch (selectedOptions.value) {
            case 'today':
                setStartDate(moment().toDate())
                break;
            case 'custom':
                setStartDate(moment().toDate())
                setEndDate(moment().toDate())
                break;
            default:
                break;
        }
    }

    const handleInfo = () => { };

    const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
        <div
            className={`flex gap-2 items-center border-[#000]/10 border rounded-[10px] text-sm h-[3.1rem] 2xl:w-[10rem] w-[10rem] py-2 pl-4 bg-white ${!value && "text-[#000]"}`}
            onClick={onClick}
            ref={ref}
        >
            <Calendar className="h-5 w-5" />
            {value || placeholder}
        </div>
    ));

    return (
        <div className=''>
            {selectedIndex == 2 && (
                <div className="">
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-x-4'>
                            <div className='w-9 h-9 rounded-full bg-[#000] flex items-center justify-center'>
                                <EmptyWallet
                                    color="#FFF"
                                    className='w-5 h-5'
                                />
                            </div>
                            <div className="">
                                <h1 className="2xl:text-lg text-base font-inter-medium">Cash Collection</h1>
                            </div>
                            <div className=''>
                                <h1 className="2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center px-4 py-1.5 bg-[#FFF]">
                                    {cashCollectionList?.completedServicesCount || 0} Services
                                </h1>
                            </div>
                            <div className=''>
                                <h1 className="2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center px-4 py-1.5 bg-[#FFF]">
                                    ₹ {cashCollectionList?.totalCollectedCash || 0}
                                </h1>
                            </div>
                        </div>
                        <div className="flex items-center 2xl:space-x-4 space-x-4">
                            <div className="">
                                <h1 className="text-base text-[#333333] font-normal">Date Range :</h1>
                            </div>
                            <div className="calendar-icon-size all-rider-date-input">
                                <DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    maxDate={moment().toDate()}
                                    customInput={<CustomInput />}
                                />
                            </div>
                            <div className="">
                                <h1 className="font-plus-jakarta text-sm text-center px-1">To</h1>
                            </div>
                            <div className="calendar-icon-size all-rider-date-input">
                                <DatePicker className="rounded-lg border-[#000]/10 2xl:w-[10rem] w-[10rem]"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    minDate={moment(startDate).toDate()}
                                    maxDate={moment().toDate()}
                                    customInput={<CustomInput />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex items-center gap-x-4 mb-2 mt-2'>
                            <div className='2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center px-4 py-2.5 bg-[#FFF] gap-x-2'>
                                <div className='w-7 h-7 rounded-full bg-[#000] flex items-center justify-center'>
                                    <EmptyWallet
                                        color="#FFF"
                                        className='w-4 h-4'
                                    />
                                </div>
                                <div className=''>
                                    <h1 className="2xl:text-base font-inter-medium">
                                        Total Cash Received
                                    </h1>
                                </div>
                                <div className=''>
                                    <h1 className="2xl:text-base font-inter-medium">
                                        ₹ {cashReceivedList?.totalCollection || 0}
                                    </h1>
                                </div>
                            </div>
                            <div className='2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center px-4 py-2.5 bg-[#FFF] gap-x-2'>
                                <div className='w-7 h-7 rounded-full bg-[#000] flex items-center justify-center'>
                                    <MoneyRecive
                                        color="#FFF"
                                        className='w-4 h-4'
                                    />
                                </div>
                                <div className=''>
                                    <h1 className="2xl:text-base font-inter-medium">
                                        Total Cash Deposited
                                    </h1>
                                </div>
                                <div className=''>
                                    <h1 className="2xl:text-base font-inter-medium">
                                        ₹ {cashDepositedList?.totalDeposited || 0}
                                    </h1>
                                </div>
                            </div>
                            <div className='2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center px-4 py-2.5 bg-[#FFF] gap-x-2'>
                                <div className='w-7 h-7 rounded-full bg-[#000] flex items-center justify-center'>
                                    <EmptyWallet
                                        color="#FFF"
                                        className='w-4 h-4'
                                    />
                                </div>
                                <div className=''>
                                    <h1 className="2xl:text-base font-inter-medium">
                                        Available Balance
                                    </h1>
                                </div>
                                <div className=''>
                                    <h1 className="2xl:text-base font-inter-medium">
                                        ₹ {availableAmountList?.AvailableAmount || 0}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='bg-white border rounded-xl px-8 my-3 py-3 h-[30vh] overflow-hidden brand-details-section'>
                <TableInfiniteScroll
                    hasMore={hasMoreRecords}
                    next={fetchMoreData}
                    height="9rem"
                    scrollThreshold={0.8}
                    dataLength={cashCollectionData?.length}
                    data={cashCollectionData}
                    columns={columns}
                    customwidths={["w-[30%]", "w-[30%]", "w-[25%]", "w-[15%]"]}
                    customeClass='max-h-[14vh]'
                    customeHeight="9rem"
                />
                <div className='flex items-center gap-x-4 pl-4 justify-between'>
                    <div className='flex items-center'>
                        <div className='w-9 h-9 rounded-full bg-[#000] flex items-center justify-center'>
                            <EmptyWallet
                                color="#FFF"
                                className='w-5 h-5'
                            />
                        </div>
                        <div className="ml-3">
                            <h1 className="2xl:text-lg text-base font-inter-medium">Total Cash</h1>
                        </div>
                    </div>
                    <div className='mr-[7.5rem]'>
                        <h1 className="2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center px-4 py-1.5 bg-[#FFF]">
                            ₹ {cashReceivedList?.totalCollection || 0}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashCollection
