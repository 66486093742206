import { method, uniqueId } from "lodash";
import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const riderManagementApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getRidersCountByStatus: builder.query({
				query: () => ({
					url: endpoints.riderManagement.getRidersCountByStatus,
					method: "GET",
				}),
			}),
			getTotalRiders: builder.query({
				query: () => ({
					url: endpoints.riderManagement.getTotalRiders,
					method: "GET",
				}),
			}),
			getRidersByStatus: builder.query({
				query: ({ riderStatus, params }) => ({
					url: endpoints.riderManagement.getRidersByStatus(riderStatus),
					params: params,
					method: "GET",
				}),
			}),
			getRiderNotifications: builder.query({
				query: () => ({
					url: endpoints.riderManagement.getRiderNotifications,
					method: "GET",
				}),
			}),
			getRidersDistance: builder.query({
				query: () => ({
					url: endpoints.riderManagement.getRidersDistance,
					method: "GET",
				}),
			}),
			getOrderByRiderAndStatus: builder.query({
				query: ({ riderId, status, params }) => ({
					url: endpoints.riderManagement.getOrderByRiderAndStatus(riderId, status),
					params: params,
					method: "GET",
				}),
			}),
			getOrdersCountByRider: builder.query({
				query: ({ riderId }) => {
					return {
						url: endpoints.riderManagement.getOrdersCountByRider(riderId),
						method: "GET",
					};
				},
			}),
			getRiderDetails: builder.query({
				query: (riderId) => {
					return {
						url: endpoints.riderManagement.getRiderDetails(riderId),
						method: "GET",
					};
				},
			}),
			getAllRiders: builder.mutation({
				query: ({ body, params }) => {
					return {
						url: endpoints.riderManagement.getAllRiders,
						body: body,
						params: params,
						method: "POST",
					};
				},
			}),
			getPDF: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getPDF(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getRidersCompletedOrders: builder.mutation({
				query: ({ riderId, status, body }) => {
					return {
						url: endpoints.riderManagement.getRidersCompletedOrders(riderId, status),
						method: "POST",
						body: body,
					};
				},
			}),
			getOrderOfRiderByStatus: builder.query({
				query: ({ riderId, status, params }) => {
					return {
						url: endpoints.riderManagement.getOrderOfRiderByStatus(riderId, status),
						params: params,
						method: "GET",
						// body: body,
					};
				},
			}),
			getRiderEmployeeDetails: builder.query({
				query: (employeeId) => {
					return {
						url: endpoints.riderManagement.getRiderEmployeeDetails(employeeId),
						method: "GET",
					};
				},
			}),
			getCashCollectionCount: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getCashCollectionCount(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getTotalCashReceived: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getTotalCashReceived(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getTotalCashDeposited: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getTotalCashDeposited(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getAvailableAmount: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getAvailableAmount(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getSalaryAllowanceOfRider: builder.query({
				query: (riderId) => {
					return {
						url: endpoints.riderManagement.getSalaryAllowanceOfRider(riderId),
						method: "GET",
					};
				},
			}),
			getRiderLead: builder.query({
				query: ({riderId, params}) => {
					return {
						url: endpoints.riderManagement.getRiderLead(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getRiderMapStatus: builder.mutation({
				query: (body) => {
					return {
						url: endpoints.riderManagement.getRiderMapStatus,
						method: "POST",
						body: body,
					};
				},
			}),
			getTotalProfit: builder.query({
				query: (riderId) => {
					return {
						url: endpoints.riderManagement.getTotalProfit(riderId),
						method: "GET",
					};
				},
			}),
			getTotalServices: builder.query({
				query: (riderId) => {
					return {
						url: endpoints.riderManagement.getTotalServices(riderId),
						method: "GET",
					};
				},
			}),
			getAllCashCollection: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getAllCashCollection(riderId),
						params: params,
						method: "GET",
					};
				},
			}),
			getRidersOrderCount: builder.query({
				query: ({ riderId }) => {
					return {
						url: endpoints.riderManagement.getRidersOrderCount(riderId),
						method: "GET"
					}
				}
			}),
			getOrderCountByRiderOfMap: builder.query({
                query: (riderId) => {
                    return {
                        url: endpoints.riderManagement.getOrderCountByRiderOfMap(riderId),
                        method: "GET",
                    };
                },
            }),
			getTotalTimeLogged: builder.query({
				query: ({ riderId, params }) => {
					return {
						url: endpoints.riderManagement.getTotalTimeLogged(riderId),
						params: params,
						method: "GET",
					};
				},
			}),

			// Shift Timings API
			getShiftsByRidersCity: builder.query({
				query: (riderId) => {
					return {
						url: endpoints.riderManagement.getShiftsByRidersCity(riderId),
						method: "GET",
					};
				}
			}),
			addShiftsForRiders: builder.mutation({
				query: ({riderId, body}) => {
					return {
						url: endpoints.riderManagement.addShiftsForRiders(riderId),
						body: body,
						method: "POST",
					};
				}
			}),
			getRidersShifts: builder.query({
				query: (riderId) => {
					return {
						url: endpoints.riderManagement.getRidersShifts(riderId),
						method: "GET",
					};
				}
			}),
			deleteRidersShift: builder.mutation({
				query: (uniqueId) => {
					return {
						url: endpoints.riderManagement.deleteRidersShift(uniqueId),
						method: "DELETE"
					}
				}
			}),
			updateShiftsForRiders: builder.mutation({
				query: ({uniqueId, body}) => {
					return {
						url: endpoints.riderManagement.updateShiftsForRiders(uniqueId),
						body: body,
						method: "PUT"
					}
				}
			})
		};
	},
});

export const {
	useLazyGetRidersCountByStatusQuery,
	useLazyGetTotalRidersQuery,
	useLazyGetRidersByStatusQuery,
	useLazyGetRiderNotificationsQuery,
	useLazyGetRidersDistanceQuery,
	useLazyGetOrderByRiderAndStatusQuery,
	useLazyGetOrdersCountByRiderQuery,
	useLazyGetRiderDetailsQuery,
	useGetAllRidersMutation,
	useGetRidersCompletedOrdersMutation,
	useLazyGetRiderEmployeeDetailsQuery,
	useLazyGetPDFQuery,
	useLazyGetCashCollectionCountQuery,
	useLazyGetSalaryAllowanceOfRiderQuery,
	useLazyGetRiderLeadQuery,
	useLazyGetTotalCashReceivedQuery,
	useLazyGetTotalCashDepositedQuery,
	useLazyGetAvailableAmountQuery,
	useGetRiderMapStatusMutation,
	useLazyGetTotalProfitQuery,
	useLazyGetTotalServicesQuery,
	useLazyGetAllCashCollectionQuery,
	useLazyGetOrderOfRiderByStatusQuery,
	useLazyGetRidersOrderCountQuery,
	useLazyGetOrderCountByRiderOfMapQuery,
	useLazyGetTotalTimeLoggedQuery,
	useLazyGetShiftsByRidersCityQuery,
	useAddShiftsForRidersMutation,
	useLazyGetRidersShiftsQuery,
	useDeleteRidersShiftMutation,
	useUpdateShiftsForRidersMutation,
} = riderManagementApi;

export { riderManagementApi };
