import React, { useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import Button from "../../shared/components/Button.component";
import SelectMenu from "../../shared/components/SelectMenu.component";
import Checkbox from "../../shared/components/Checkbox";
import OtpInput from "react18-input-otp";
import { Verify } from "iconsax-react";
import {
	useUpdateAppointmentAfterOtpVerificationMutation,
	useVerifyOtpMutation,
} from "../../utils/Services/Apis/onDemand";
import useToaster from "../../utils/hooks/useToaster";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useEffect } from "react";

import * as Yup from "yup";

const schema = Yup.object()
	.shape({
		dueAmount: Yup.number().min(1, 'Due amount should be greater than zero').notRequired(),
		totalAmount: Yup.number().nullable().notRequired(),
	})
	.test("due amount should not be greater than total price", function (value) {
		if (value.dueAmount > value.totalAmount) {
			return this.createError({
				path: "dueAmount",
				message: "Due amount should not be greater than total amount!",
			});
		}
		return true; // Validation passed
	});

function PaymentModal({ data }) {
	const [otp, setOtp] = useState("");
	const [verifyOtp, { data: otpData, status: otpStatus }] = useVerifyOtpMutation();
	const [updateAppointment, { status: updateAppointmentStatus }] =
		useUpdateAppointmentAfterOtpVerificationMutation();
	const { setToast } = useToaster();
	const { closeModal } = useSelectModal();
	const handleSubmit = async (values) => {
		let body = values.dueAmount ? {dues: values.dueAmount} : {}
		const { data: d, error: err } = await updateAppointment({
			orderId: data?._id,
			body: body
		});

		if (d) {
			setToast({ type: "success", msg: d.message });
			closeModal();
			data.handleGetAppointments(data.fetchStatus)
		}
		if (err) {
			setToast({ type: "error", msg: err.data.message });
			closeModal();
		}
	}
	
	return (
		<Formik initialValues={{}}
		  validationSchema={schema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form action="#" method="POST">
					<PaymentComponent
						otp={otp}
						setOtp={setOtp}
						data={data}
						verifyOtp={verifyOtp}
						otpData={otpData}
						otpStatus={otpStatus}
						updateAppointment={updateAppointment}
						updateAppointmentStatus={updateAppointmentStatus}
					/>
				</Form>
			)}
		</Formik>
	);
}

export default PaymentModal;

const PaymentComponent = ({
	setOtp,
	otp,
	data,
	verifyOtp,
	otpData,
	otpStatus,
	updateAppointment,
	updateAppointmentStatus,
}) => {
	const { values, setFieldValue, errors, touched } = useFormikContext();
	const { setToast } = useToaster();
	
	useEffect(()=>{
		if(!values.isDueAmount) setFieldValue("dueAmount","")
	},[values.isDueAmount])

	useEffect(()=>{
    if(data) setFieldValue("totalAmount", data?.totalAmount)
	},[data])

	return (
		<div className="2xl:w-[35vw] w-[40vw] p-6 px-16 bg-[#FFFFFF] rounded-xl pb-8">
			<div className="flex items-center pt-6 justify-between">
				<div className="">
					<h1 className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Total Amount</h1>
				</div>
				<div className="pr-10">
					<h1 className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">₹ {data?.totalAmount}</h1>
				</div>
			</div>
			<div className="flex items-center gap-2 py-5">
				<div>
					<Checkbox name="isDueAmount" />
				</div>
				<div>
					<p className="text-[#000000] font-inter-regular 2xl:text-base text-sm">Add Due Amount</p>
				</div>
			</div>
			{values.isDueAmount && (
				<>
					<div className="">
						<h1 className="text-[#000] font-inter-medium 2xl:text-2xl text-lg">Add Due Amount</h1>
					</div>
					<div className="pt-3 pb-4">
						<p className="text-[#717171] 2xl:text-base text-sm font-inter-regular">
							Enter due amount to be paid in cash
						</p>
					</div>
					<div>
						<Field
							name="dueAmount"
							type="text"
							className="w-[28.25vw] outline-focus h-[2.5rem] border-[#D9D9D9] 2xl:rounded-[0.625rem] rounded-lg py-1.5 px-4 text-base font-inter-regular bg-[#D9D9D9] bg-opacity-20 placeholder-black"
							onKeyPress={(event) => {
								if (/[^0-9]/.test(event.key)) {
									return event.preventDefault();
								}
							}}
						/>
						{touched.dueAmount && errors.dueAmount ? (
						<div className="text-red-600 text-sm">{errors.dueAmount}</div>
					) : (
						<div className="invisible h-5"></div>
					)}
					</div>
				</>
			)}

			{/* {otpData?.message !== "OTP Verified successfully" && ( */}
				<>
					<div className="pt-6">
						<h1 className="text-[#000] font-inter-medium text-lg 2xl:text-2xl">Enter OTP</h1>
					</div>
					<div className="pt-3 pb-4 w-[18vw]">
						<p className="text-[#000] 2xl:text-base text-sm font-inter-regular">
							Please enter OTP sent to the Customer to approve Cash Payment
						</p>
					</div>
					<div className="otpinput-parent-div m-auto w-[65%]">
						<OtpInput
							value={otp}
							inputStyle="otpinput otpinputTab"
							isInputNum={true}
							focusStyle="focus:ring-[#D05929]"
							onChange={(enteredOtp) => setOtp(enteredOtp)}
							numInputs={4}
							separator={<span></span>}
						/>
					</div>
					<div className="flex justify-center pt-6">
						<Button
							className={`w-full text-white bottom-btn-ht text-base font-inter-bold ${
								(otp?.length !== 4 || otpData?.message === "OTP Verified successfully") ? "bg-red-300 cursor-not-allowed" : "cursor-pointer"
							}`}
							disabled={otp?.length !== 4 || otpData?.message === "OTP Verified successfully"}
							isSubmitting={otpStatus === "pending"} 
							onClick={async () => {

								const { data: d, error: err } = await verifyOtp({
									orderId: data?._id,
									otp,
								});

								if (d) setToast({ type: "success", msg: d.message });
								if (err) setToast({ type: "error", msg: err.data.message });
							}}
						>
							Confirm
						</Button>
					</div>
				</>
			{/* )} */}
			<div className="flex items-center pt-6 justify-between">
				<div className="">
					<h1 className="text-[#000] font-inter-medium text-2xl">Total Payable</h1>
				</div>
				<div className="pr-10">
					<h1 className="text-[#000] font-inter-medium text-2xl">
						₹ {data?.totalAmount - (values.dueAmount || 0)}
					</h1>
				</div>
			</div>
			<div className="gap-3 items-center">
				<div className="flex justify-center pt-6 pb-1">
					<Button
						className={`w-full text-white cursor-pointer bottom-btn-ht text-base font-inter-bold ${
							otpData?.message !== "OTP Verified successfully"
								? "bg-red-300 cursor-not-allowed"
								: "cursor-pointer"
						}`}
						disabled={otpData?.message !== "OTP Verified successfully"}
						isSubmitting={updateAppointmentStatus === "pending"}
						type="submit"
					>
						Submit
					</Button>
				</div>
			</div>
		</div>
	);
};
