import { Form, Formik } from "formik";
import React from "react";

import FormField from "../../shared/components/FormField.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import Button from "../../shared/components/Button.component";
import useToaster from "../../utils/hooks/useToaster";
import ImageLoadFormField from "../../shared/components/Image_Load_Form_Field.component";
import { useUploadFileMutation } from "../../utils/Services/Apis/fileUpload";
import {
	useAddCarBrandMutation,
	useUpdateCarBrandMutation,
} from "../../utils/Services/Apis/carApi";
import { Car, EmojiHappy } from "iconsax-react";
import { useNavigate } from "react-router";
import { BrandSchema } from "../../utils/Services/ValidationSchema";
import editIcon from "../../assets/icons/car-management/editBlueIcon.svg";
import carIcon from "../../assets/icons/car-management/carYellowIcon.svg";
import useUploadImage from "../../utils/hooks/useUploadImage";

export default function AddBrandModal({ data }) {
	const isEdit = data;
	const { closeModal } = useSelectModal();
	const { uploadImages, uploadImgStatus } = useUploadImage();
	const [addCarBrand, {status:status1} ] = useAddCarBrandMutation();
	const [updateBrand, {status:status2} ] = useUpdateCarBrandMutation();
	const navigate = useNavigate();
	const { setToast } = useToaster();
	// const { setToast } = useToaster();

	const formFieldValues = [
		{
			label: isEdit ? "Brand Name" : "Enter Car Brand",
			name: "brand",
			type: "text",
			placeholder: "Lamborghini",
			onKeyPress: (event) => {
				if (/[0-9]/.test(event.key)) {
					return event.preventDefault();
				}
			},
		},
	];
	
	const renderFormFields = (errors) =>
		formFieldValues.map((field) => {
			return <FormField field={field} key={field.name} error={errors[field.name]} />;
		});

	return (
		<div className="w-[calc(115px+16.367vw)] p-5 relative">
			<div className="flex justify-start items-center">
				<div className="h-12 w-12 rounded-full flex justify-center items-center modal-logo-size">
					<img src={isEdit ? editIcon : carIcon} alt="" className="h-full w-full" />
				</div>
			</div>
			<div className="text-lg pt-8 font-inter-bold">{isEdit ? "Edit" : "Add New"} Brand</div>

			<div className="tracking-tight text-sm  pb-5 font-inter-regular text-[#475467]">
				{isEdit
					? "Edit Brand by entering the details below"
					: "Add a new Brand by entering the details below"}
			</div>
			<Formik
				initialValues={{
					brand: isEdit ? data?.name : "",
					photo: isEdit ? data?.logo : null,
				}}
				validationSchema={BrandSchema}
				onSubmit={async (values) => {
					if (isEdit) {
						if (!(values.photo instanceof FileList)) {
							const { data: d, error } = await updateBrand({
								brandId: data._id,
								data: {
									name: values.brand,
									logo: values.photo,
								},
							});
							if (d) {
								setToast({ type: "success", msg: "Brand updated successfully" });
								// navigate(`/car-management`);
								closeModal();
							}
							if (error) setToast({ type: "error", msg: error.data.message });
						} else {
							let imageUrlArray = await uploadImages(values.photo);
							if (!imageUrlArray) return;
							const { data: d, error } = await updateBrand({
								brandId: data._id,
								data: {
									name: values.brand,
									logo: imageUrlArray[0],
								},
							});
							if (d) {
								setToast({ type: "success", msg: "Brand updated successfully" });
								closeModal();
							}
							if (error) setToast({ type: "error", msg: error.data.message });
						}
						return;
					}

					let imageUrlArray = await uploadImages(values.photo);
					if (imageUrlArray) {
						let { error } = await addCarBrand({
							name: values.brand,
							logo: imageUrlArray[0],
						});
						if (!error) {
							setToast({ type: "success", msg: "Brand added successfully" });
							closeModal();
						} else {
							setToast({ type: "error", msg: error.data.message });
						}
					}
				}}
			>
				{({ isSubmitting, errors, touched }) => (
					<Form className="space-y-6" action="#" method="POST">
						{renderFormFields(errors)}
						<div>
							<div className="mb-1 text-sm font-inter-regular">Brand Logo</div>
							<ImageLoadFormField
								bottomText={"png format of 24x24 px"}
								imageUrl={data?.logo}
								instructions={
									"Note: Please upload a brand logo with white background and a ratio of 1:1"
								}
								isEdit={isEdit && true}
								changeImgButtonName="Change logo"
								// acceptableWidth={24}
								// acceptableHeight={24}
								imgFormat="image/png"
							/>
							{/* <div className="text-[#6B7280] font-normal text-sm mt-2">
								Note: Please upload a brand logo with white background and a ratio of 1:1
							</div> */}
							<div className="text-red-600 text-sm">
								{errors.photo && touched.photo ? <div className=" text-sm">{errors.photo}</div> : null}
							</div>
						</div>
						{/* <div>
							<Button className="w-full bg-[#1A1A1A]">Upload</Button>
						</div> */}
						<div className="flex justify-between">
							<Button
								className="w-[47.5%] bg-[#F1F1F1] text-black cursor-pointer bottom-btn-ht"
								onClick={closeModal}
							>
								Cancel
							</Button>
							<Button className="w-[47.5%] text-white cursor-pointer bottom-btn-ht" type="submit"
							  isSubmitting={ status1 === "pending" || status2 === "pending" || uploadImgStatus === 'pending'}
							>
								{isEdit ? "Save" : "Submit"}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
