import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import FormField from "../../shared/components/FormField.component";
import Button from "../../shared/components/Button.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import addAddressIcon from "../../assets/icons/add-address.svg";
import locationIcon from '../../assets/icons/locationIcon.svg'
import * as Yup from "yup";
import SelectMenu from "../../shared/components/SelectMenu.component";
import MapSearchLocation from "../service-request-mangement/MapSearchLocation";
import { useAddAddressMutation, useLazyGetAreasQuery, useLazyGetCitesQuery, useUpdateVehicleAddressMutation } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";

const validationSchema = Yup.object().shape({
	line1: Yup.string().required("Address Line 1 is required"),
		line2: Yup.string(),
		landMark: Yup.string(),
		area: Yup.object().required("Area is required"),
		city: Yup.object().required("City is required"),
		pincode: Yup.string().required("Pincode is required").min(6, "Please enter 6 digits Pincode"),
		coOrdinates: Yup.object().required("Select location on map"),
		addressType: Yup.string().required("Select Address type"),
});

export default function AddEditAddress({ data }) {
	const [getCities, { data: cityList }] = useLazyGetCitesQuery();
	const [getAreasByCity, { data: areaList }] = useLazyGetAreasQuery();
	const [addAddress, {status: addStatus}] = useAddAddressMutation();
	const [updateAddress, {status: updateStatus}] =useUpdateVehicleAddressMutation()
  const { setToast } = useToaster();
	const { closeModal } = useSelectModal();
	useEffect(() => {
		getCities()
	}, [])

	function isPointInsideCircle(pointLat, pointLon, circleCenterLat, circleCenterLon, circleRadius) {
		const distance = haversineDistance(pointLat, pointLon, circleCenterLat, circleCenterLon);
		return distance <= circleRadius;
	}

	function haversineDistance(lat1, lon1, lat2, lon2) {
		const R = 6371e3;  // Earth's radius in meters
		const dLat = toRadians(lat2 - lat1);
		const dLon = toRadians(lon2 - lon1);
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c;  // Distance in meters
	}

	function toRadians(degrees) {
		return degrees * (Math.PI / 180);
	}

	const getPostalCode = async (lat, lng) => {
		const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Replace with your API key
		const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

		try {
			const response = await fetch(url);
			const data = await response.json();

			if (data.status === 'OK') {
				// Extracting the postal code from the response
				const addressComponents = data.results[0].address_components;
				const postalCodeObj = addressComponents.find(component => component.types.includes('postal_code'));
				return postalCodeObj ? postalCodeObj.long_name : 'Postal code not found';
			} else {
				throw new Error('Geocoding failed');
			}
		} catch (error) {
			console.error('Error fetching postal code:', error);
			return null;
		}
	};

	const handleFormSubmit = async (values) => {
		let isInTheRadius = false;
		if (values.area?.pincodes.length) {
			const postalCode = await getPostalCode(values.coOrdinates.latitude, values.coOrdinates.longitude)
			const isSamePincode = values.area?.pincodes.find((pin) => pin == postalCode)
			if (isSamePincode) isInTheRadius = true
		} else {
			const radius = values.area?.radiusType?.unit === "Kilometer" ? values.area.radius * 1000 : values.area.radius
			isInTheRadius = isPointInsideCircle(values.coOrdinates.latitude, values.coOrdinates.longitude, values.area.coOrdinates.latitude, values.area.coOrdinates.longitude, radius)
		}

		if(!isInTheRadius) return setToast({ type: "error", msg: "Address is not in serviceable radius" });

		const addAddressDetails = {
			addressLineOne: values.line1,
			addressLineTwo: values.line2,
			landMark: values.landMark,
			areaId: values.area?._id,
			cityId: values.city?._id,
			pincode: values.pincode,
			addressType: values.addressType,
			defaultAddress: values.defaultAddress,
			coOrdinates: {...values.coOrdinates, searchedPlace:values.searchedPlace}
		}

		if(data?.vehicleAddress){
			const { data: updateAddressData, error } = await updateAddress({
			addressId: data?.vehicleAddress?._id,
			body: addAddressDetails,
			});

			if(updateAddressData) {
			data.getAddress()
			setToast({ type: "success", msg: "Address updated successfully" });
			closeModal()
			}
			if (error) {
				setToast({ type: "error", msg: error?.data?.message });
				closeModal()
			}
			return
		} 

		const { data: addressData, error } = await addAddress({
			vehicleId: data?.vehicleId,
			body: addAddressDetails,
		});
		
		if(addressData) {
			data.getAddress()
			setToast({ type: "success", msg: "Address added successfully" });
			closeModal()
		}
		if (error) {
			setToast({ type: "error", msg: error?.data?.message });
			closeModal()
		}

	}

	return (
		<div className="w-[30rem] relative py-6 overflow-y-hidden modal-height px-5">
			<div className="pl-5">
				<div className="flex justify-start items-center">
					<div className="h-12 w-12 rounded-full flex justify-center items-center modal-logo-size">
						<img src={data?.vehicleAddress ? locationIcon : addAddressIcon} alt="" className=" " />
					</div>
				</div>
				<div className="text-lg pt-4 font-inter-bold">{data?.vehicleAddress ? "Edit Address" : "Add New Address"}</div>
				<div className="tracking-tight text-sm  pb-5 text-[#475467] font-inter-regular">
					{data?.vehicleAddress ? "Edit an address by changing the details below" : "Create new address by adding the details below"}
				</div>
			</div>
			<div className="pb-4 overflow-y-hidden modal-height ">
				<Formik
					initialValues={{
						line1: data?.vehicleAddress ? data?.vehicleAddress?.addressLineOne : "",
						line2: data?.vehicleAddress ? data?.vehicleAddress?.addressLineTwo : "",
						landMark: data?.vehicleAddress ? data?.vehicleAddress?.landMark : "",
						area: "",
						city: "",
						pincode: data?.vehicleAddress ? data?.vehicleAddress?.areaCode : "",
						addressType: data?.vehicleAddress ? data?.vehicleAddress?.addressType : "home",
						defaultAddress: data?.vehicleAddress ? data?.vehicleAddress?.default : false,
						coOrdinates: "",
						searchedPlace:""
					}}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
				>
					{({ isSubmitting, values, setFieldValue, errors, touched }) => (
						<Form
							className="space-y-1 overflow-y-auto px-5 modal-form-height h-[30.5vw]"
							action="#"
							method="POST"
						>
							<AddressForm vehicleAddress={data?.vehicleAddress} updateStatus={updateStatus} addStatus={addStatus} setFieldValue={setFieldValue} touched={touched} errors={errors} values={values} getAreasByCity={getAreasByCity} areaList={areaList} cityList={cityList} />
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}


const AddressForm = ({ setFieldValue, touched, errors, values, getAreasByCity, cityList, areaList, addStatus, updateStatus, vehicleAddress }) => {
	const { closeModal } = useSelectModal();
	useEffect(() => {
		if (values?.city?._id) {
			getAreasByCity(values.city?._id);
		}
	}, [values?.city]);

	useEffect(()=>{
		if(vehicleAddress && cityList) setFieldValue("city", cityList?.find((city) => city._id === vehicleAddress.cityId))
		if(vehicleAddress?.addressType !== "home" && vehicleAddress?.addressType !== "office"){
				 setFieldValue("customType", vehicleAddress?.addressType);
			}
	},[vehicleAddress, cityList])

	useEffect(()=>{
		if(vehicleAddress && areaList) setFieldValue("area", areaList?.find((area) => area._id === vehicleAddress.areaId
))
	},[vehicleAddress, areaList])

	return (
		<>
			<div className="pt-2">
				<label className="text-sm mb-1">Address</label>
				<MapSearchLocation
					setFieldValue={setFieldValue}
					selectedAddress={vehicleAddress}
					height={"187px"}
				/>
				{errors.coOrdinates && touched.coOrdinates ? (
					<div className="error text-red-600 text-sm">{errors?.coOrdinates}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>
			<FormField
				field={{
					label: "Address 1",
					name: "line1",
					type: "text",
					placeholder: "Enter Address Line 1",
				}}
			/>
			<FormField
				field={{
					label: "Address 2",
					name: "line2",
					type: "text",
					placeholder: "Enter Address Line 2",
				}}
			/>
			<FormField
				field={{
					label: "Landmark",
					name: "landMark",
					type: "text",
					placeholder: "Enter Landmark",
				}}
			/>

			<div>
				<SelectMenu
					label="City"
					name="city"
					options={cityList}
					onChange={(option) => setFieldValue("city", option)}
					value={values.city}
					placeholder="City"
					getOptionValue={(option) => option._id}
				/>
				{errors.city && touched.city ? (
					<div className="error text-red-600 text-sm">{errors?.city}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>

			<div>
				<SelectMenu
					label="Area"
					name="area"
					options={areaList}
					onChange={(option) => setFieldValue("area", option)}
					value={values.area}
					placeholder="Area"
					getOptionValue={(option) => option._id}
				/>
				{errors.area && touched.area ? (
					<div className="error text-red-600 text-sm">{errors?.area}</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>

			<FormField
				field={{
					label: "Pin Code",
					name: "pincode",
					type: "text",
					placeholder: "Enter Pin Code",
					onKeyPress: (event) => {
						if (/[^0-9 ]/.test(event.key) || event.target.value.length > 5) {
							return event.preventDefault();
						}
					},
				}}
			/>
			<div>
				<div className="block text-sm font-medium leading-6 text-gray-900 mb-1">Address Type</div>
				<div className="flex items-center mt-2 mb-3">
					<div className="mr-8">
						<div className="flex items-center">
							<Field
								type="radio"
								name="addressType"
								className="mr-2 mt-1.5 appearance-none items-center p-1 border-black border-2 checked:bg-black focus:text-black focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-black"
								value="home"
							/>
							<span className="text-gray-700  text-sm">Home</span>
						</div>
					</div>
					<div className="">
						<div className="flex items-center">
							<Field
								type="radio"
								name="addressType"
								className="mr-2 mt-1.5 appearance-none items-center p-1 border-black border-2 checked:bg-black focus:text-black focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-black"
								value="office"
							/>
							<span className="text-gray-700  text-sm">Office</span>
						</div>
					</div>
				</div>
				<div className="w-full rounded-lg mt-2">
					<div className="flex items-center w-full gap-1">
						<Field
							// disabled={true}
							type="radio"
							name="addressType"
							className="mr-2 mt-1.5 appearance-none items-center p-1 border-black border-2 checked:bg-black focus:text-black focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-black"
							value={values.customType}
						/>
						<div className="w-full -mt-1">
							<FormField
								nonFormikError
								field={{
									name: "customType",
									type: "text",
									placeholder: "Other",
								}}
							/>
						</div>
					</div>
				</div>
				{errors.addressType && touched.addressType ? (
					<div className="error text-red-600 text-sm whitespace-nowrap">
						{errors?.addressType}
					</div>
				) : (
					<div className="invisible h-5"></div>
				)}
			</div>
			<div className="pb-5 default-address-checkbox">
				<Field
					// disabled={shouldDefaultDisabled.current}
					type="checkbox"
					name="defaultAddress"
					className="rm-font-overpass mr-2 border-black border-2 p-1 bg-inherit checked:bg-black focus:ring-transparent focus:ring-offset-0 focus:checked:bg-black"
				/>
				<span className=" text-sm">Save address as default?</span>
			</div>
			<div className="flex justify-between pt-2">
				<Button
					className="w-[47.5%] text-black bg-[#F1F1F1] cursor-pointer bottom-btn-ht"
					onClick={closeModal}
				>
					Cancel
				</Button>
				<Button
					className="w-[47.5%] text-white cursor-pointer bottom-btn-ht"
					type="submit"
				  isSubmitting={addStatus === "pending" || updateStatus === "pending" }
				>
					{vehicleAddress ? "Save" :"Add"}
				</Button>
			</div>
		</>
	)
}