import { createSlice } from "@reduxjs/toolkit";
const TOKEN_KEY = "jwt";
let initialState = null;

if (localStorage.getItem(TOKEN_KEY)) initialState = JSON.parse(localStorage.getItem(TOKEN_KEY));
export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		createUser: (state, action) => {
			localStorage.setItem(TOKEN_KEY, JSON.stringify(action.payload));
			return (state = action.payload);
		},
		destroyUser: (state, action) => {
			localStorage.removeItem(TOKEN_KEY);
			return (state = null);
		},
	},
});

export const { createUser, destroyUser } = authSlice.actions;
export default authSlice.reducer;
