import React, { useEffect } from 'react'
import { useLazyGetFranchiseDetailQuery } from '../../../utils/Store'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import pdfIcon from '../../../assets/icons/pdg-icon.png'


function FranchiseInfo({ selectedIndex, onFranchiseNameChange }) {
    const [getFranchiseDetails, { data: franchiseDetailsData }] = useLazyGetFranchiseDetailQuery();
    const { franchiseId: id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            const data = await getFranchiseDetails(id);
            if (data && data.length > 0) {
                onFranchiseNameChange(data[0]?.name);
            }
        };

        fetchData();
    }, [id, onFranchiseNameChange]);

    useEffect(() => {
        if (franchiseDetailsData && franchiseDetailsData.length > 0) {
            const franchiseName = franchiseDetailsData[0]?.name;
            onFranchiseNameChange(franchiseName);
        }
    }, [franchiseDetailsData, onFranchiseNameChange]);
    // if (!franchiseDetailsData || franchiseDetailsData.length === 0) return null;
    if (!franchiseDetailsData?.length) return

    return (
        <div>
            <div className="px-3 pb-12 rounded-xl w-full bg-white">
                {/* Primary Information */}
                {selectedIndex == 0 && (
                    <div className="">
                        <div className="px-10 pt-6 pb-4">
                            <h1 className="2xl:text-lg text-base font-inter-bold">Primary Information</h1>
                        </div>
                        <hr className="mb-4 border border-gray-100" />
                        <div className="flex px-10">
                            <div className="w-full">
                                {franchiseDetailsData[0]?.franchiseLevel === "child" ? (
                                    <>
                                        <div className="flex mb-8">
                                            <div className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                                Master Franchise :
                                            </div>
                                            <div className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                                {franchiseDetailsData[0]?.parent?.name}
                                            </div>
                                        </div>
                                        <div className="flex mb-8">
                                            <div className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                                Franchise Name :
                                            </div>
                                            <div className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                                {franchiseDetailsData[0]?.name}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex mb-8">
                                        <div className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                            Franchise Name :
                                        </div>
                                        <div className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                            {franchiseDetailsData[0]?.name}
                                        </div>
                                    </div>
                                )}
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Address :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.address}
                                    </h1>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Mobile Number :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.phoneNumber}
                                    </h1>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Email ID :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5 truncate">
                                        {franchiseDetailsData[0]?.email}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Secondary Information */}
                {selectedIndex == 1 && (
                    <div className="">
                        <div className="px-10 pt-6 pb-4">
                            <h1 className="2xl:text-lg text-base font-inter-bold">Secondary Information</h1>
                        </div>
                        <hr className="mb-4 border border-gray-100" />
                        <div className="flex px-10">
                            <div className="w-full">
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        GST :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.gst}
                                    </h1>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        City :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.city?.join(', ')}
                                    </h1>
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                {/* Franchise Information */}
                {selectedIndex == 2 && (
                    <div className="">
                        <div className="px-10 pt-6 pb-4">
                            <h1 className="2xl:text-lg text-base font-inter-bold">Franchise Information</h1>
                        </div>
                        <hr className="mb-4 border border-gray-100" />
                        <div className="flex px-10">
                            <div className="w-full">
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Serviceable Area :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5 truncate">
                                        {franchiseDetailsData[0]?.area?.join(', ')}
                                    </h1>
                                </div>
                                <div className="flex mb-8">
                                    <div className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Manager :
                                    </div>
                                    <div className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.manager}
                                    </div>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Start Date :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {moment(franchiseDetailsData[0]?.startDate).format('DD-MM-YYYY')}
                                    </h1>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        End Date :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {moment(franchiseDetailsData[0]?.endDate).format('DD-MM-YYYY')}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Documents */}
                {selectedIndex == 3 && (
                    <div className="">
                        <div className="px-10 pt-6 pb-4">
                            <h1 className="2xl:text-lg text-base font-inter-bold">Documents</h1>
                        </div>
                        <hr className="mb-4 border border-gray-100" />
                        <div className='max-h-[56vh] min-h-[24vh] overflow-y-auto pr-3'>
                            <div className="pl-10">
                                <div className="w-full">
                                    <div className="flex mb-4">
                                        <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                            Emergency Contact Number :
                                        </h1>
                                        <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                            {franchiseDetailsData[0]?.emergencyPhoneNumber || 'NA'}
                                        </h1>
                                    </div>
                                </div>
                                <div className="flex mb-4">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        KYC :
                                    </h1>
                                </div>
                                <div className="w-full flex flex-wrap gap-5 pr-10 pb-4">
                                    {franchiseDetailsData[0]?.kycDocuments?.map((imageDetails, i) => (
                                        <div key={i} className="flex gap-3">
                                            <div className="flex border rounded-lg justify-center flex-1 h-[10vw] w-[15vw] overflow-x-auto final-price-scrollbar">
                                                {(imageDetails.type === "image/png" || imageDetails.type === 'image/jpg') && <div className="flex p-2 h-[8vw] my-auto">
                                                    <img src={imageDetails.url} alt="" className="w-full h-full" />
                                                </div>}

                                                {imageDetails.type === 'application/pdf' && <div className="flex flex-col p-2 h-[8vw] w-[inherit] my-auto relative">
                                                    <a href={imageDetails.url} target='_blank'>
                                                        <img src={imageDetails.url} className="w-full h-full opacity-0" />
                                                        <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-[6vw] h-[6vw]'>
                                                            <img src={pdfIcon} alt="PDF Icon" className='w-full h-full' />
                                                            <p className='2xl:text-base font-normal text-[#474747] truncate text-center'>{imageDetails.name}</p>
                                                        </div>
                                                    </a>
                                                </div>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="px-10">
                                <div className="flex mb-4">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        MOU :
                                    </h1>
                                </div>
                                <div className="w-full flex flex-wrap gap-5 pr-10">
                                    {franchiseDetailsData[0]?.mouDocuments?.map((pdfDetails, i) => (
                                        <div key={i} className="flex gap-3">
                                            {pdfDetails.url && <div className="flex border rounded-lg justify-center flex-1 h-[10vw] w-[15vw] overflow-x-auto final-price-scrollbar">
                                                <div className="flex flex-col p-2 h-[8vw] w-[inherit] my-auto relative">
                                                    <a href={pdfDetails.url} target='_blank'>
                                                        <img src={pdfDetails.url} className="w-full h-full opacity-0" />
                                                        <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-[6vw] h-[6vw]'>
                                                            <img src={pdfIcon} alt="PDF Icon" className='w-full h-full' />
                                                            <p className='2xl:text-base font-normal text-[#474747] truncate text-center'>{pdfDetails.name}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Payment Information */}
                {selectedIndex == 4 && (
                    <div className="">
                        <div className="px-10 pt-6 pb-4">
                            <h1 className="2xl:text-lg text-base font-inter-bold">Payment Information</h1>
                        </div>
                        <hr className="mb-4 border border-gray-100" />
                        <div className="flex px-10">
                            <div className="w-full">
                                <div className="flex mb-8">
                                    <div className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Credit Limit :
                                    </div>
                                    <div className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.creditLimit}
                                    </div>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Payment Due Date :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.paymentDueDate} / Every Month
                                    </h1>
                                </div>
                                <div className="flex mb-8">
                                    <h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
                                        Grace Period :
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
                                        {franchiseDetailsData[0]?.gracePeriod}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Commission Information */}
                {selectedIndex == 5 && (
                    <div className="">
                        <div className="px-10 pt-6 pb-4">
                            <h1 className="text-lg font-inter-bold">Commission Information</h1>
                        </div>
                        <hr className="mb-4 border border-gray-100" />
                        <div className='max-h-[61vh] min-h-[24vh] overflow-y-auto pr-3'>
                            <div className="flex px-10 gap-4 flex-wrap">
                                {franchiseDetailsData[0]?.commissionModelName === "revenue" &&
                                    <>
                                        <div className="w-[32%] bg-[#F5F5F5] rounded-[20px] p-4">
                                            <div className="flex mb-8 ">
                                                <div className="text-base font-normal text-[#474747] w-[60%]">
                                                    Commission Model :
                                                </div>
                                                <div className="text-base font-inter-medium w-[40%]">
                                                    {franchiseDetailsData[0]?.commissionModelName}
                                                </div>
                                            </div>
                                        </div>
                                        {franchiseDetailsData[0]?.commission.filter(record => record.name === "revenue").map((record, index) => (
                                            <div key={index} className='w-[32%] bg-[#F5F5F5] rounded-[20px] p-4'>
                                                <div className='flex mb-8'>
                                                    <div className=''>
                                                        <h1 className="text-base font-inter-bold text-[#000]">
                                                            Record #{index + 1}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            Start Date :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {moment(record.startDate).format('DD-MM-YYYY')}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            End Date :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {moment(record.endDate).format('DD-MM-YYYY')}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            Amount :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {record.Amount && `₹ ${record.Amount}`}
                                                            {!record?.includeGst && (
                                                                <span className="pl-2 text-base font-inter-medium">+ {record?.gstPercentage}% GST</span>
                                                            )}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            Percentage :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {record.percentage && `${record.percentage}%`}
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )}
                                    </>
                                }
                                {franchiseDetailsData[0]?.commissionModelName === "perUser" &&
                                    <>
                                        <div className="w-[32%] bg-[#F5F5F5] rounded-[20px] p-4">
                                            <div className="flex mb-8">
                                                <div className="text-base font-normal text-[#474747] w-[60%]">
                                                    Commission Model :
                                                </div>
                                                <div className="text-base font-inter-medium w-[40%]">
                                                    {franchiseDetailsData[0]?.commissionModelName}
                                                </div>
                                            </div>
                                            <div className="flex mb-8">
                                                <div className='w-[60%]'>
                                                    <h1 className="text-base font-normal text-[#474747]">
                                                        Start Date :
                                                    </h1>
                                                </div>
                                                <div className='w-[40%]'>
                                                    <h1 className="text-base font-inter-medium">
                                                        {moment(franchiseDetailsData[0]?.startDate).format('DD-MM-YYYY')}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="flex mb-8">
                                                <div className='w-[60%]'>
                                                    <h1 className="text-base font-normal text-[#474747]">
                                                        End Date :
                                                    </h1>
                                                </div>
                                                <div className='w-[40%]'>
                                                    <h1 className="text-base font-inter-medium">
                                                        {moment(franchiseDetailsData[0]?.endDate).format('DD-MM-YYYY')}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="flex mb-8">
                                                <div className='w-[60%]'>
                                                    <h1 className="text-base font-normal text-[#474747]">
                                                        No. of Service Provider :
                                                    </h1>
                                                </div>
                                                <div className='w-[40%]'>
                                                    <h1 className="text-base font-inter-medium">
                                                        {franchiseDetailsData[0]?.commission?.[franchiseDetailsData[0]?.commission?.length - 1]?.ServiceProvider }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        {franchiseDetailsData[0]?.commission.filter(record => record.name === "perUser").map((record, index) => (
                                            <div key={index} className='w-[32%] bg-[#F5F5F5] rounded-[20px] p-4'>
                                                <div className='flex mb-8'>
                                                    <div className=''>
                                                        <h1 className="text-base font-inter-bold text-[#000]">
                                                            Record #{index + 1}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            From :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {record?.rangeFrom}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            To :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {record?.rangeTo}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="flex mb-8">
                                                    <div className='w-[60%]'>
                                                        <h1 className="text-base font-normal text-[#474747]">
                                                            Amount :
                                                        </h1>
                                                    </div>
                                                    <div className='w-[40%]'>
                                                        <h1 className="text-base font-inter-medium">
                                                            {/* {record?.includeGst ? `₹ ${record?.Amount}` : `₹ ${record?.afterGstAmount}`} */}
                                                            {record?.Amount}
                                                            {!record?.includeGst && (
                                                                <span className="pl-2 text-base font-inter-medium">+ {record?.gstPercentage}% GST</span>
                                                            )}
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FranchiseInfo
