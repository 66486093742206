import React, { useEffect } from 'react'
import TableComponent from '../../../shared/components/Table.component'
import { useLazyGetSalaryAllowanceOfRiderQuery } from '../../../utils/Services/Apis/riderManagementApi'
import { useParams } from 'react-router-dom';

function RiderSalaryAllowance({ selectedIndex }) {

    const [getSalaryAllowance, { data: salaryAllowanceList }] = useLazyGetSalaryAllowanceOfRiderQuery();
    const { riderId } = useParams();

    useEffect(() => {
        if (riderId)
            getSalaryAllowance(riderId);
    }, [riderId]);

    const columns = [
        {
            header: 'Order ID',
            // cell: ({ row }) => <div className="text-[#333] text-base font-medium">{row.original.serviceName}</div>,
        },
        {
            header: 'Type',
            // cell: ({ row }) => <div className="text-[#333] text-base font-medium">{row.original.serviceName}</div>,
        },
        {
            header: 'Reason',
            // cell: ({ row }) => <div className="text-[#333] text-base font-medium">{row.original.serviceName}</div>,
        },
        {
            header: 'Date',
            // cell: ({ row }) => <div className="text-[#333] text-base font-medium">{row.original.serviceName}</div>,
        },
        {
            header: 'Amount',
            // cell: ({ row }) => <div className="text-[#333] text-base font-medium">{row.original.serviceName}</div>,
        },
    ]

    return (
        <div className=''>
            <div className="px-3 h-[20vh] rounded-xl w-full bg-white brand-details-section">
                {selectedIndex == 5 && salaryAllowanceList && salaryAllowanceList.length > 0 && (
                    <div className="">
                        <div className="px-10 py-4">
                            <h1 className="2xl:text-lg text-base font-inter-bold">Salary details</h1>
                        </div>
                        <hr className="mb-3.5 border border-gray-100" />
                        <div className='flex px-10'>
                            <div className='w-[35%]'>
                                <div className="flex items-center mb-6 mt-2.5">
                                    <h1 className="2xl:text-base font-normal text-[#000] col-span-1">
                                        Fixed Salary:
                                    </h1>
                                    <h1 className="2xl:text-base font-inter-medium col-span-2 pl-10">
                                        ₹ {salaryAllowanceList[0]?.fixedSalary || 'NA'}
                                    </h1>
                                </div>
                                <div className="flex mb-6">
                                    <h1 className="2xl:text-base text-base font-normal text-[#000] col-span-1">
                                        Incentives:
                                    </h1>
                                    <h1 className="2xl:text-base text-base font-inter-medium col-span-2 pl-14">
                                        ₹ {salaryAllowanceList[0]?.incentive || 'NA'}
                                    </h1>
                                </div>
                            </div>
                            <div className='w-[40%]'>
                                <div className={`flex mb-5 items-center space-x-20 ${salaryAllowanceList[0]?.salaryAllowance && salaryAllowanceList[0]?.salaryAllowance.length === 0 ? 'mt-3' : 'mt-0'}`}>
                                    <h1 className="2xl:text-base font-normal text-[#000]">
                                        Allowances:
                                    </h1>
                                    <div className='flex gap-x-3 w-[100%] overflow-x-auto final-price-scrollbar'>
                                        {/* {salaryAllowanceList[0]?.salaryAllowance?.map((allowance, index) => (
                                            <h1
                                                key={index}
                                                className="2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center p-1.5"
                                            >
                                                {`${allowance?.type} ₹ ${allowance?.amount}` || 'NA'}
                                            </h1>
                                        ))} */}
                                        {salaryAllowanceList[0]?.salaryAllowance?.length > 0 ? (
                                            salaryAllowanceList[0]?.salaryAllowance?.map((allowance, index) => (
                                                <h1
                                                    key={index}
                                                    className="2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center p-1.5"
                                                >
                                                    {`${allowance?.type} ₹ ${allowance?.amount}`}
                                                </h1>
                                            ))
                                        ) : (
                                            <h1 className="2xl:text-base font-inter-medium border-[#E5E5E5] border border-solid whitespace-nowrap rounded-[10px] flex items-center justify-center p-1.5">
                                                NA
                                            </h1>
                                        )}
                                    </div>
                                </div>
                                <div className="flex mb-6 items-center">
                                    <h1 className="2xl:text-base text-base font-normal text-[#000] col-span-1">
                                        Total Allowances:
                                    </h1>
                                    <h1 className="2xl:text-base text-base font-inter-medium col-span-2 pl-10">
                                        ₹ {salaryAllowanceList[0]?.totalAllowance || 'NA'}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='bg-white border rounded-xl px-8 my-3 h-[35vh] overflow-hidden brand-details-section hidden'>
                <div className="px-4 pt-6">
                    <h1 className="2xl:text-lg text-base font-inter-bold">Penalties and Incentives</h1>
                </div>
                <TableComponent
                    // data={ordersData}
                    columns={columns}
                    customwidths={["w-[20%]", "w-[20%]", "w-[20%]", "w-[20%]", "w-[20%]"]}
                    customeClass='max-h-[37vh] pr-[1rem]'
                // isLoading={ordersDataStatus === 'pending'}
                />
            </div>
        </div>
    )
}

export default RiderSalaryAllowance
