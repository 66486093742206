import Button from "../../shared/components/Button.component";
import { FiArrowLeft } from "react-icons/fi";
import DeleteIcon from "../../assets/icons/delete.svg";
import EditIcon from "../../assets/icons/edit.svg";
import { TickCircle, Car, InfoCircle } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDeleteAreaMutation, useLazyGetAreaDetailsQuery } from "../../utils/Store";
import React, { useEffect } from "react";
import useToaster from "../../utils/hooks/useToaster";
import dateFormatter from "../../shared/components/dateFormatter.component";
import MapDisplayAreas from "./Map_Display_Areas";
import BackButton from "../../shared/components/BackButton.component";
import useSelectModal from "../../utils/hooks/useSelectModal";
import convertToCardinalFormat from "../../utils/helper/convertToCardinalFormat";

function AreaDetailsManagement() {
	const { state } = useLocation();
	const { areaId } = useParams();
	const [getAreaDetails, { data: areaDetails }] = useLazyGetAreaDetailsQuery();

	useEffect(() => {
		if (areaId) getAreaDetails(areaId);
	}, [areaId]);

	const { openModal } = useSelectModal();

	const handleDeleteArea = async () => {
		openModal("DeleteAreaModal", { ...areaDetails, hideCloseArrow: true, isNavigateBack: true });
	};

	if (!areaDetails) return null;
	const { lat, lng } = convertToCardinalFormat(
		areaDetails.coOrdinates?.latitude,
		areaDetails.coOrdinates?.longitude
	);

	return (
		<div className="px-8 pb-4 pt-8 bg-[#F5F5F5] h-full">
			<h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
				Area and City Management
			</h1>
			<div className="flex justify-between">
				<div className="flex gap-3 items-center">
					<BackButton />
					<h3 className="2xl:text-[20px] text-[18px] font-futura-medium tracking-tight text-gray-900 subheading-at-zoom">
						Area Management
					</h3>
				</div>
				<div className="flex items-center space-x-4">
					<Button
						className="text-white bg-black cursor-pointer"
						onClick={() =>
							openModal("AddNewArea", { cityData: state, isEdit: true, areaData: areaDetails , fromAreaDetails: true})
						}
					>
						Edit
						<img src={EditIcon} alt="" className="pl-2.5 delete-edit-icon-ht" />
					</Button>
					<Button className="text-white cursor-pointer" onClick={() => handleDeleteArea()}>
						Delete
						<img src={DeleteIcon} alt="" className="pl-2.5 delete-edit-icon-ht" />
					</Button>
				</div>
			</div>
			<hr className="mt-4 mb-4 border border-[#9F9F9F2E]" />
			<div className="flex gap-5">
				<div className="flex-col flex gap-5">
					<div className=" bg-white border rounded-xl w-[50rem] min-h-[18.5rem] px-5 py-3">
						<h1 className="text-xl font-inter-bold subheading-at-zoom">
							Area Name: {areaDetails?.name}
						</h1>
						<hr className="mt-2 mb-5 border border-gray-100" />
						<div className="grid grid-cols-2 gap-y-10 ">
							<div className="flex gap-5">
								<div className="text-base font-inter-medium text-[#000]">Area Center:</div>
								<div className="text-base font-inter-regular text-[#474747] font-normal">
									<div>{lat}</div>
									<div>{lng}</div>
								</div>
							</div>
							<div className="flex gap-5">
								<div className="text-base font-inter-medium text-[#000]">Area Supervisor:</div>
								<div className="text-base font-inter-regular font-normal text-[#474747] whitespace-nowrap overflow-y-auto">
									<span>
										{areaDetails.employees
											.filter((employee) => employee.roles.includes("supervisor"))
											.map((employee) => employee.name)
											?.join(", ")}
									</span>
								</div>
							</div>
							<div className="flex gap-5 pr-6">
								<div className="text-base font-inter-medium text-[#000] whitespace-nowrap">
									Time Slot:
								</div>
								<div className="text-base font-normal flex gap-1 overflow-x-auto text-[#474747] time-slot-scrollbar">
									{areaDetails.timeSlot.map((slotTime, index, array) => (
										<span key={index} className="whitespace-nowrap">
											{slotTime?.name}{" "}
											{array.length - 1 > index && ","}
										</span>
									))}
								</div>
							</div>
							<div>
								<div className="flex gap-5">
									<div className="text-base font-inter-medium text-[#000]">Payment Mode :</div>
									<div className="text-base font-normal text-[#474747] capitalize">
										{areaDetails.paymentType}
									</div>
								</div>
							</div>
						</div>
						{
							areaDetails.radius && 
							<div className="flex gap-5 mt-10">
								<div className="text-base font-inter-medium text-[#000]">Address:</div>
								<div className="text-base font-inter-regular text-[#474747] font-normal">
									<div>{areaDetails?.coOrdinates?.searchedAreaName}</div>
								</div>
							</div>
						}
					</div>
					<div className="bg-white border rounded-xl w-[50rem] min-h-[14.5rem] px-5 py-3">
						<h1 className="text-xl font-inter-bold subheading-at-zoom">Pincodes/Radius</h1>
						<hr className="mt-2 mb-5 border border-gray-100" />
						<div className="grid grid-col-1">
							<div className="flex items-center gap-5 w-full">
								<div className="text-base font-inter-medium text-[#000] w-[20%]">
									{areaDetails.name} Pincodes:
								</div>
								<div className="text-base font-normal w-[75%]">
									{areaDetails.pincodes.length ? (
										<div className="flex gap-1 flex-wrap">
											{areaDetails.pincodes.map((pincode, index, array) => (
												<span className="py-1 px-2 border rounded-lg" key={pincode}>
													{pincode}
												</span>
											))}
										</div>
									) : (
										<div className="text-base font-inter-bold text-[#898C81]">nil</div>
									)}
								</div>
							</div>
							<div className="flex gap-5 mt-8">
								<div className="text-base font-inter-medium text-[#000]">Area Radius:</div>
								{areaDetails.radius ? (
									<div className="text-base font-inter-normal text-[#000]">
										{areaDetails.radius ? areaDetails.radius + " " + areaDetails.radiusType?.type : "nil"}
									</div>
								) : (
									<div className="text-base font-inter-bold text-[#898C81]">nil</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="w-full">
					<div className="p-3 bg-white border rounded-xl w-full h-[34.25rem]">
						<h1 className="text-xl font-inter-bold subheading-at-zoom">Map View</h1>
						<hr className="mt-2 mb-5 border border-gray-100" />
						<>
							{areaDetails && (
								<div className="area-mgmt-map-zoom">
									<MapDisplayAreas
										data={{
											pincodeArray: areaDetails.pincodes,
											areaData: areaDetails,
											height: "calc(305px + 7.951vw)",
											width: "100%",
											zoom: "13",
										}}
									/>
								</div>
							)}
						</>
					</div>
				</div>
			</div>
		</div>
	);
}
export default React.memo(AreaDetailsManagement);
