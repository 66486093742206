function decimalToDMS(decimal) {
	const degrees = Math.floor(decimal);
	const minutesDecimal = (decimal - degrees) * 60;
	const minutes = Math.floor(minutesDecimal);
	const seconds = ((minutesDecimal - minutes) * 60).toFixed(3);
	return `${degrees}° ${minutes}' ${seconds}"`;
}

export default function convertToCardinalFormat(lat, lng) {
	const latDirection = lat >= 0 ? "N" : "S";
	const lngDirection = lng >= 0 ? "E" : "W";

	const latDMS = decimalToDMS(Math.abs(lat));
	const lngDMS = decimalToDMS(Math.abs(lng));

	return { lat: `${latDirection}: ${latDMS}`, lng: `${lngDirection}: ${lngDMS}` };

	return `${latDirection}: ${latDMS}, ${lngDirection}: ${lngDMS}`;
}
