import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import FormField from "../../../shared/components/FormField.component";
import Button from "../../../shared/components/Button.component";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import BlueCarIcon from '../../../assets/icons/BlueCar.svg'
import * as Yup from "yup";
import SelectMenu from "../../../shared/components/SelectMenu.component";
import { useUpdateVehicleMutation, useVerifyVehicleMutation } from "../../../utils/Store";
import { useLazyGetBrandsQuery, useLazyGetCarModelsQuery } from "../../../utils/Services/Apis/carApi";
import useToaster from "../../../utils/hooks/useToaster";

const validationSchema = Yup.object().shape({
    carBrand: Yup.object().required("Brand Name is required"),
    carModel: Yup.object().required("Car Model is required"),
    vehicleNumber: Yup.string().required("Vehicle Number is required"),
});

export default function EditVehicleModal({ data }) {
    const [updateVehicle, {status}] = useUpdateVehicleMutation()
    const [verifyVehicle, { status: vehicleVerificationStatus }] = useVerifyVehicleMutation()
    const [getCarBrands, { data: brandsData }] = useLazyGetBrandsQuery();
	const [getCarModels, { data: carModels }] = useLazyGetCarModelsQuery();
    const { setToast } = useToaster();
    const { closeModal } = useSelectModal();

    const updateVehicleDetails = async (values) => {
        const { data: updatedVehicleData, error } = await updateVehicle({
            vehicleId:data?.vehicleId || data?._id,
            body: {
                "carBrandId": values.carBrand?._id,
                "carModelId": values.carModel?.variants.length && values.carModel?.variants[0]._id,
                "vehicleNumber": values.vehicleNumber
            },
        });

        if(updatedVehicleData) {
            // data.getAddress()
            setToast({ type: "success", msg: "Car updated successfully" });
            closeModal()
            data.fetchVehicleDetails()
        }
        if (error) {
            setToast({ type: "error", msg: error?.data?.message });
            closeModal()
        }
    };

    const handleFormSubmit = async (values) => {

        if(values.vehicleNumber !== data?.vehicleNumber) {
            const { data, err } = await verifyVehicle({
				vehicleNumber: values.vehicleNumber,
			})
            if(data?.verified) {
                updateVehicleDetails(values)
            }
            if(!data?.verified)	return setToast({ type: "error", msg: "Please enter valid vehicle number" });
            if(err) return setToast({ type: "error", msg: err.data.message });
            
        } else {
            updateVehicleDetails(values)
        }
        
    }
    useEffect(()=>{
        getCarBrands()
    },[])

    useEffect(()=>{
        if(!brandsData?.length) return
        const carBrand = brandsData?.find((brand)=> brand.name === data.vehicleBrand)
        if(!carBrand) return
        getCarModels(carBrand._id)
    },[brandsData])

    return (
        <div className="w-[28rem] relative py-6 overflow-y-hidden modal-height px-5">
            <div className="">
                <div className="flex justify-start items-center px-5">
                    <div className="h-12 w-12 rounded-full flex justify-center items-center modal-logo-size">
                        <img src={BlueCarIcon} alt="" className=" " />
                    </div>
                </div>
                <div className="px-5">
                    <div className="text-lg pt-4 font-inter-bold">Edit Vehicle</div>
                    <div className="tracking-tight text-sm  pb-5 text-[#475467] font-inter-regular">
                        Edit Vehicle Details by Changing the details below
                    </div>
                </div>
                <div className="pb-4 overflow-y-hidden modal-height ">
                    <Formik
                        initialValues={{
                            "carBrand": "",
                            "carModel": "",
                            "vehicleNumber": data?.vehicleNumber
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
                            <Form
                                className="space-y-1 overflow-y-auto px-5 modal-form-height"
                                action="#"
                                method="POST"
                            >
                                <VehicleForm status={status} getCarModels={getCarModels} brandsData={brandsData} carModels={carModels?.result} setFieldValue={setFieldValue} touched={touched} errors={errors} values={values} carData={data}/>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}


const VehicleForm = ({status, setFieldValue, touched, errors, values, brandsData, carModels, carData, getCarModels }) => {
    const { closeModal } = useSelectModal();

    useEffect(()=>{
        if(!brandsData?.length) return
        const carBrand = brandsData?.find((brand)=> brand.name === carData.vehicleBrand)
        if(!carBrand) return
        setFieldValue("carBrand", carBrand)
        getCarModels(carBrand._id)
    },[brandsData])

    useEffect(()=>{
        if(!values.carBrand?._id) return
        setFieldValue("carModel", "")
        getCarModels(values.carBrand._id)
    },[values.carBrand])

    useEffect(()=>{
        if(!carModels?.length) return
        const carModel = carModels?.find((modal)=> modal.name === (carData.vehicleModel || carData.carModelName))
        if(!carModel) return
        setFieldValue("carModel", carModel)
    },[carModels])

    return (
        <>
            <div className="">
                <div className="">
                    <SelectMenu
                        label="Brand Name"
                        name="carBrand"
                        options={brandsData}
                        onChange={(option) => setFieldValue("carBrand", option)}
                        value={values.carBrand}
                        placeholder="Select Brand Name"
                        getOptionValue={(option) => option._id}
                    />
                    {errors.carBrand && touched.carBrand ? (
                        <div className="error text-red-600 text-sm">{errors?.carBrand}</div>
                    ) : (
                        <div className="invisible h-5"></div>
                    )}
                </div>

                <div className="">
                    <SelectMenu
                        label="Car Model"
                        name="carModel"
                        options={carModels}
                        onChange={(option) => setFieldValue("carModel", option)}
                        value={values.carModel}
                        placeholder="Select Car Model"
                        getOptionValue={(option) => option._id}
                    />
                    {errors.carModel && touched.carModel ? (
                        <div className="error text-red-600 text-sm">{errors?.carModel}</div>
                    ) : (
                        <div className="invisible h-5"></div>
                    )}
                </div>
                <FormField
                    field={{
                        label: "Vehicle Number",
                        name: "vehicleNumber",
                        type: "text",
                        placeholder: "Enter Vehicle Number",
                    }}
                />
                
            </div>
            <div className="flex justify-between pt-2">
                <Button
                    className="w-[47.5%] text-black font-inter-bold bg-[#F1F1F1] cursor-pointer bottom-btn-ht border-[#D0D0D0]"
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <Button
                    className="w-[47.5%] text-white font-inter-bold cursor-pointer bottom-btn-ht"
                    type="submit"
                    isSubmitting={status === "pending"}
                >
                    Save
                </Button>
            </div>
        </>
    )
}