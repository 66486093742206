
import React, { useEffect, useState } from 'react'
import TableComponent from '../../shared/components/Table.component';
import useSelectModal from '../../utils/hooks/useSelectModal';
import Toggle from '../../shared/components/toggle.component';
import DeleteIcon from '../../assets/icons/Delete.png'
import Button from '../../shared/components/Button.component';
import moment from 'moment';
import EditIcon from "../../assets/icons/edit-icon.svg"
import useToaster from '../../utils/hooks/useToaster';
import { useParams } from "react-router-dom";
import SelectMenu from '../../shared/components/SelectMenu.component';
import { useLazyGetCitesQuery } from '../../utils/Store';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useAddTimeSlotCreationMutation, useLazyGetShiftsForTimeslotsQuery, useLazyGetTimeSlotCreationPaginationQuery, useUpdateTimeSlotCreationMutation } from '../../utils/Services/Apis/shiftMasterApi';
import TimePicker from 'rc-time-picker';
const schema = Yup.object().shape({
    selectedCities: Yup.array().min(1, "Select City"),
    selectedShift: Yup.object().required("Select Shift"),
});

const TimeslotCreation = () => {
    const [getAllShifts, { data: shiftList, status: shiftListStatus }] = useLazyGetShiftsForTimeslotsQuery();
    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const { setToast } = useToaster();
    const { openModal } = useSelectModal();
    const [getCites, { data: cities }] = useLazyGetCitesQuery();
    const [selectedTime1, setSelectedTime1] = useState(moment('12:00 AM', 'h:mm A'));
    const [selectedTime2, setSelectedTime2] = useState(moment(selectedTime1).add(15, 'minutes'));
    const [errorTimeDifference, setErrorTimeDifference] = useState(false);
    useEffect(() => {
        if (selectedTime2.isSameOrBefore(moment(selectedTime1).add(1, "hour"))) {
            setSelectedTime2(moment(selectedTime1).add(1, "hour"));
        }
    }, [selectedTime1]);
    useEffect(() => {
        calculateTimeDifference(selectedTime2, selectedTime1);
    }, [selectedTime2]);
    const [getTimeSlotPagination, { data: timeSlotPaginationList, status: timeSlotPaginationStatus }] = useLazyGetTimeSlotCreationPaginationQuery();
    const { slotId } = useParams();
    const [updateTimeSlot] = useUpdateTimeSlotCreationMutation();
    const [addTimeSlot, { status: addTimeSlotStatus }] = useAddTimeSlotCreationMutation();
    useEffect(() => {
        getAllTimeSlots();
    }, [pageNo]);
    useEffect(() => {
        getCites();
        getAllShifts();
    }, []);
    const getAllTimeSlots = async () => {
        getTimeSlotPagination({ pageNo, pageSize });
    };

    const columns = [
        {
            header: "City",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center w-full">
                        <span className='font-inter-medium'>{original.cities?.map(city => city.name).join(", ")}</span>
                    </span>
                );
            },
        },
        {
            header: "Shift Category",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center w-full">
                        <span className='font-inter-medium'>{original.shiftcategories?.map(shift => shift.name)}</span>
                    </span>
                );
            },
        },
        {
            header: "List of Time Slots",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3 items-center w-full">
                        <span className='font-inter-medium'>{original.name}</span>
                    </span>
                );
            },
        },
        {
            header: "Action",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-3">
                        <Toggle
                            defaultChecked={original.isActive}
                            handleOnToggle={() => handleActionToggle(original)}
                        />
                        <img
                            src={EditIcon}
                            alt="edit"
                            className={`h-5 w-5 cursor-pointer`}
                            onClick={() => openEditModal(original)}
                        />
                        <img
                            src={DeleteIcon}
                            alt=""
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => openDeleteModal(original)}
                        />
                    </span>
                );
            },
        },
    ]
    function calculateTimeDifference(time1, time2) {
        const date1 = moment(time1);
        const date2 = moment(time2);
        const differenceMs = date2 - date1;
        const differenceHours = Math.abs(differenceMs / 36e5); // 1 hour = 36e5 milliseconds
        if (differenceHours > 12) {
            setErrorTimeDifference("Slot should be less than 12 Hours")
        } else if (differenceHours < 1) {
            setErrorTimeDifference("Slot should be greater than 1 Hour")
        } else {
            setErrorTimeDifference("")
        }
    }
    const openEditModal = (timeSlotData) => {
        const status = "time-slot";
        openModal("EditMasterCategoryModal", { status, data: timeSlotData, refetchData: getAllTimeSlots });
    };

    const openDeleteModal = (timeSlotData) => {
        const status = "time-slot";
        openModal("DeleteMasterCategoryModal", { status, data: timeSlotData, refetchData: getAllTimeSlots })
    }

    const handleActionToggle = (timeSlotData) => {
        updateTimeSlot({
            data: {
                isActive: !timeSlotData?.isActive,
                "shiftCategoryId": timeSlotData?.shiftcategories[0]?._id, 
                "cityIds": timeSlotData?.cities?.map(city => city?._id)
            },
            id: timeSlotData?._id,
        })
            .then((data) => {
                !timeSlotData.isActive
                    ? setToast({ type: "success", msg: "Time Slot is Enabled" })
                    : setToast({ type: "success", msg: "Time Slot Disabled" });
                    getAllTimeSlots()
            })
            .catch((err) => {
                setToast({ type: "error", msg: err.data.message });
            });
    }
    const handlePageChange = (type) => {
        let tempCurrentPage = pageNo;
        if (type === 'prev') {
            tempCurrentPage -= 1;
        } else {
            tempCurrentPage += 1;
        }
        setPageNo(tempCurrentPage);
    }
    const handleAddTimeSlot = async (values, resetForm) => {
        if (errorTimeDifference) return 
        const { data: timeSlotData, error } = await addTimeSlot({ from: moment(selectedTime1).format("LT").toLocaleLowerCase(), to: moment(selectedTime2).format("LT").toLocaleLowerCase(), cityIds: values.selectedCities?.map((city) => city._id), shiftCategoryId: values.selectedShift?._id })
        if (timeSlotData) {
            setToast({ type: "success", msg: "Time Slot is Added successfully" })
            getAllTimeSlots()
            setSelectedTime1(moment('12:00 AM', 'h:mm A'))
            setSelectedTime2(moment('1:00 AM', 'h:mm A'))
            resetForm()
        }
        if (error) setToast({ type: "error", msg: error.data.message });
    }

    return (
        <Formik
            initialValues={{
                selectedCities: [],
                selectedShift: ""
            }}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
                handleAddTimeSlot(values, resetForm);
            }}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form className="" action="#" method="POST">
                    <div className="bg-[#F5F5F5] h-full">
                        <div className="space-y-3">
                            <div className='w-[100%] flex items-center justify-start pl-8 pb-3 gap-x-8'>
                                <div className='w-[17%]'>
                                    <h1 className='font-inter-medium text-[#111827] text-sm'>Select City</h1>
                                    <div className="relative">
                                        <SelectMenu
                                            placeholder='Select Cities'
                                            name="selectedCities"
                                            options={cities}
                                            isMulti={true}
                                            onChange={(option) => setFieldValue("selectedCities", option)}
                                            value={values.selectedCities}
                                            classNameForAllRiders='h-[2.9rem] custome-ht-all-riders'
                                        />
                                        {errors?.selectedCities && touched?.selectedCities ? (
                                            <div className="text-red-600 text-sm">{errors?.selectedCities}</div>
                                            ) : (
                                                <div className="invisible h-5"></div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='w-[20%]'>
                                    <h1 className='font-inter-medium text-[#111827] text-sm'>Select Shift Category</h1>
                                    <div className="relative">
                                        <SelectMenu
                                            name="selectedShift"
                                            showCheckbox={false}
                                            options={shiftList}
                                            value={values.selectedShift}
                                            menuPlacement={"bottom"}
                                            onChange={(option) => setFieldValue("selectedShift", option)}
                                            classNameForAllRiders='h-[2.9rem] custome-ht-all-riders'
                                            placeholder='Select Shift'
                                        />
                                    
                                        {errors?.selectedShift && touched?.selectedShift ? (
                                            <div className="text-red-600 text-sm">{errors?.selectedShift}</div>
                                            ) : (
                                                <div className="invisible h-5"></div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='w-[15%]'>
                                    <h1 className='font-inter-medium text-[#111827] text-sm pb-1'>From</h1>
                                    <div className="relative">
                                        <Timepicker
                                            selectedTime={selectedTime1}
                                            setSelectedTime={setSelectedTime1}
                                            onChange={setSelectedTime2}
                                        />
                                        <div className="invisible h-5"></div>
                                    </div>
                                </div>
                                <div className='w-[15%]'>
                                    <h1 className='font-inter-medium text-[#111827] text-sm pb-1'>To</h1>
                                    <div className="relative">
                                        <Timepicker
                                            selectedTime={selectedTime2}
                                            setSelectedTime={setSelectedTime2}
                                            disabledTime={selectedTime1}
                                            disabled={!selectedTime2}
                                            onChange={setSelectedTime2}
                                            placeholder="Select To Time"
                                        />
                                        <div className={`${!errorTimeDifference && "invisible"} text-sm h-5 text-red-600`}>{errorTimeDifference}</div>
                                    </div>
                                </div>
                                <div className='pl-6 pt-6 w-[15%]'>
                                    <Button
                                        type='submit'
                                        className="w-[100%] h-[2.9rem] text-[#FEFEFE] text-base cursor-pointer font-inter-regular rounded-[10px]"
                                        isSubmitting={addTimeSlotStatus === "pending"}
                                    >
                                        Add Time Slot
                                    </Button>
                                    <div className="invisible h-5"></div>
                                </div>
                            </div>
                            <div className="bg-white p-5 mx-8 my-3 pb-2 h-[60vh] overflow-hidden brand-details-section bg-white-height relative">
                                <div className='w-full'>
                                    <TableComponent
                                        data={timeSlotPaginationList?.timeslots}
                                        columns={columns}
                                        customwidths={["w-[35%]", "w-[25%]", "w-[30%]", "w-[10%]"]}
                                        isActionColumnTable={true}
                                        customeClass='max-h-[43vh] table-height-sm pr-3'
                                        isLoading={timeSlotPaginationStatus === "pending"}
                                    />
                                </div>
                                {timeSlotPaginationList?.totalPages > 1 &&
                                    <div className='flex items-center gap-x-3 2xl:pt-2 absolute bottom-4'>
                                        <div className=''>
                                            <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == 1 ? 'cursor-not-allowed' : 'cursor-pointer '}`} disabled={pageNo == 1} onClick={() => handlePageChange('prev')}>Previous</Button>
                                        </div>
                                        <div className=''>
                                            <Button className={`w-[100%] h-[2.5rem] text-[#1A1A1A] bg-[#000/50] text-base cursor-pointer font-inter-medium border-[#000/50] rounded-[10px] ${pageNo == timeSlotPaginationList?.totalPages ? 'cursor-not-allowed' : 'cursor-pointer '}`} disabled={pageNo == timeSlotPaginationList?.totalPages} onClick={() => handlePageChange('next')}>Next</Button>
                                        </div>
                                        <div className=''>
                                            <p className='text-[#727272] text-base font-inter-regular'>Page <span className='text-[#FF0000] text-base font-inter-regular'>{pageNo}</span> of {timeSlotPaginationList?.totalPages}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>

    )
}
export default TimeslotCreation
const Timepicker = ({ selectedTime, setSelectedTime, disabledTime, placeholder, onChange }) => {

    const format = "h:mm A";
    const handleTimeChange = (value) => {
        setSelectedTime(value);
    };
    return (
        <div>
            <TimePicker
                showSecond={false}
                value={selectedTime}
                onChange={handleTimeChange}
                className='text-lg font-medium'
                allowEmpty={false}
                format={format}
                use12Hours
                placeholder={placeholder}
                disabledHours={() => {
                    if (!disabledTime) return [];
                    const disabledHours = [];
                    const minToTime = disabledTime.clone().add(1, 'hours');
                    let maxToTime = disabledTime.clone().add(12, 'hours');
                    if (maxToTime.hours() <= 12) {
                        maxToTime = maxToTime.hours(23).minutes(0);
                    }
                    for (let i = 0; i < minToTime.hours(); i++) {
                        disabledHours.push(i);
                    }
                    for (let i = maxToTime.hours() + 1; i < 24; i++) {
                        disabledHours.push(i);
                    }
                    return disabledHours;
                }}
                disabledMinutes={(hour) => {
                    if (!disabledTime) return [];
                    const disabledMinutes = [];
                    const minToTime = disabledTime.clone().add(1, 'hour');
                    const maxToTime = disabledTime.clone().add(12, 'hours');
                    if (hour === minToTime.hour()) {
                        for (let i = 0; i < minToTime.minutes(); i++) {
                            disabledMinutes.push(i);
                        }
                    }
                    if (hour === maxToTime.hour()) {
                        for (let i = maxToTime.minutes() + 1; i < 60; i++) {
                            disabledMinutes.push(i);
                        }
                    }
                    return disabledMinutes;
                }}
            />
        </div>
    );
}
