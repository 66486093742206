import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the CSS for styling

export default function PreviewDocImage({ data }) {
	const images = data.map((image) => ({ original: image }));

	return (
		<div className="py-40 px-40 ">
			<div className="w-[500px] rounded-2xl overflow-hidden">
				<ImageGallery items={images} showPlayButton={false} showIndex={true} />
			</div>
		</div>
	);
}
