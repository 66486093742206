import * as Yup from "yup";

export const CitySchema = Yup.object().shape({
	name: Yup.string().required("City name is required"),
	// cityManagers: Yup.array().min(1, "Add At least one manager"),
	// fromCity: Yup.array().min(1, "Add At least one city"),
});

export const AreaSchema = Yup.object()
	.shape({
		name: Yup.string().required("Area name is required"),
		timeSlot: Yup.array().min(1, "Select time Slot"),
		fromCity: Yup.array().min(1, "Add At least one city"),
		search: Yup.string(),
		pincodeArrayLength: Yup.number(),
		radius: Yup.number().when("search", {
			is: (search) => !!search,
			then: () =>
				Yup.number()
					.typeError("Radius should be number")
					.min(1, "price min 1")
					.positive("Must be a positive number.")
					.integer("Must be an integer.")
					.required("Radius is required."),
			otherwise: () => Yup.string().notRequired(),
		}),
		radiusType: Yup.object().when("search", {
			is: (search) => !!search,
			then: () =>
				Yup.object().required("Radius Type is required."),
			otherwise: () => Yup.object().notRequired(),
		})
	})
	.test("area-or-pin", "Add Area and radius or pincode", function (value) {
		if (!value.search && !value.pincodeArrayLength) {
			return this.createError({
				path: "search",
				message: "Add Area and radius or pincode",
			});
		}
		return true; // Validation passed
	});

// export const ServiceSchema = Yup.object().shape({
// 	serviceName: Yup.string().required("Service name is required"),

// 	isChecked: Yup.boolean(),
// 	price: Yup.number().when("name", {
// 		is: (isChecked) => isChecked,
// 		then: () =>
// 			Yup.number("price should be number")
// 				.min(1, "price min 1")
// 				.positive("Must be a positive number.")
// 				.integer("Hours must be an integer.")
// 				.required("Price is required."),
// 		otherwise: Yup.string().notRequired(),
// 	}),
// 	discountPrice: Yup.number().when("name", {
// 		is: (isChecked) => isChecked,
// 		then: () =>
// 			Yup.number("discountPrice should be number")
// 				.positive("Must be a positive number.")
// 				.integer("Hours must be an integer.")
// 				.required("discount price is required."),
// 		otherwise: Yup.string().notRequired(),
// 	}),
// });

export const BrandSchema = Yup.object().shape({
	brand: Yup.string()
		.min(2, "Brand name should have at least 2 characters")
		.max(20, "Brand name should not exceed 20 characters")
		.required("Brand name is required")
		.matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),
	photo: Yup.string().required("Brand photo is required"),
});

export const CarSchema = Yup.object().shape({
	carName: Yup.string().required("Car model name is required"),
	variants: Yup.array().min(1, "Add At least one variant"),
	carType: Yup.object().required("Car type is required"),

	photo: Yup.mixed().required("Car photo is required"),
});

export const AddVariantSchema = Yup.object().shape({
	variantName: Yup.string().required("Variant name is required"),
	fuelType: Yup.object().required("Fuel type is required"),
	transmissionType: Yup.object().required("Transmission type is required"),
});

///////////////////////////////////////////////////service validation//////////////////////////////////////////////////////////////
const carTypeValidation = Yup.object()
	.shape({
		isChecked: Yup.boolean(),
		price: Yup.number().nullable().notRequired(),
		discountPrice: Yup.number().notRequired(),
	})
	.test("price-validation", "Price validation failed", function (item) {
		const { isChecked, price } = item;
		if (isChecked) {
			if (typeof price !== "number" || price <= 0 || !Number.isInteger(price)) {
				return this.createError({ path: `${this.path}.price`, message: "Invalid price" });
			}
		}
		return true; // return true if validation passes
	})
	.test("discountPrice-validation", "Discount price validation failed", function (item) {
		const { isChecked, discountPrice, discountType, price } = item;
		if(discountType?.value == 'flat') {
			if(discountPrice > price || discountPrice < 0) 
				return this.createError({
					path: `${this.path}.discountPrice`,
					message: "Invalid discount price",
				});
		} else {
			if (
				discountPrice < 0 || discountPrice > 100
			) {
				return this.createError({
					path: `${this.path}.discountPrice`,
					message: "Invalid discount price",
				});
			}
		}
		return true; // return true if validation passes
	});

const serviceTimeSchema = Yup.object()
	.shape({
		hours: Yup.number().min(0, "Invalid hours"),
		minutes: Yup.number().min(0, "Invalid minutes").max(59, "Invalid minutes"),
	})
	.test("non-zero", "Hours or Minutes must be non-zero", function (value) {
		if (value.hours === 0 && value.minutes === 0) {
			return this.createError({
				path: `${this.path}.hours`,
				message: "Hours or Minutes must be non-zero",
			});
		}
		return true; // Validation passed
	});

export const ServiceSchema = Yup.object()
	.shape({
		carType: Yup.array()
			.of(carTypeValidation)
			.test("has-selected-item", "At least one car type must be selected", (array) => {
				return array.some((item) => item.isChecked);
			}),
		serviceName: Yup.string().required("Service name is required"),
		cityId: Yup.object().required("Please select city"),
		areaId: Yup.array().required("Please select area").min(1, "Please add one Area"),
		serviceType: Yup.object().required("Please select service type"),
		includeGST: Yup.boolean(),
		assets: Yup.array().min(1, "Please add one asset"),
		gst: Yup.number().when("includeGST", {
			is: (includeGST) => {
				return !includeGST;
			},
			then: () =>
				Yup.number("GST should be number")
					.min(0, "GST should be min 0")
					// .positive("Must be a positive number")
					// .integer("Must be an integer")
					.required("GST is required"),
			otherwise: () => Yup.string().notRequired(),
		}),
		photo: Yup.string().required("photo is required"),
		serviceTime: Yup.array().of(serviceTimeSchema).min(1, "Please add service time"),
	})
	.required();
///////////////////////////////////////////////////service validation//////////////////////////////////////////////////////////////
