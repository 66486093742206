import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpoints, BASE_URL } from "../../config/endpoint.config";
import { createUser, destroyUser } from "../Slices/authSlice";

const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = getState().auth?.token;
		if (token) {
			headers.set("authorization", `Bearer ${token}`);
		}
		return headers;
	},
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result?.error?.data?.message === "jwt expired" || result?.error?.data?.message === "invalid signature") {
		// send refresh token to get new access token
		const refreshResult = await baseQuery(
			{
				url: endpoints.auth.refreshToken,
				body: {
					refreshToken: api.getState().auth?.refreshToken,
					token: api.getState().auth?.token,
				},
				method: "POST",
			},
			api,
			extraOptions
		);
		if (refreshResult?.data) {
			// const user = api.getState().auth.user;
			// store the new token
			// login(refreshResult.data);
			api.dispatch(createUser(refreshResult?.data));
			// retry the original query with new access token
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(destroyUser());
		}
	}
	return result;
};
export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({}),
});
