import React from 'react'
import { BiTrash } from 'react-icons/bi'
import useSelectModal from '../../utils/hooks/useSelectModal';
import Button from '../../shared/components/Button.component';
import { useNavigate } from 'react-router-dom';
import { useDeleteBrandsMutation } from '../../utils/Services/Apis/carApi';
import useToaster from '../../utils/hooks/useToaster';
import DeleteIcon from "../../assets/icons/modal-delete-icon.svg";
import { useDeleteCityMutation } from '../../utils/Store';

function DeleteCityModal({ data }) {
    const { closeModal, openModal } = useSelectModal();
    const [deleteCity, {status}] = useDeleteCityMutation();
    const { setToast } = useToaster();
    
    const handleDelete = async () => {
        deleteCity(data?._id).then(({data}) => {
            setToast({ type: "success", msg: "City Deleted successfully" });
		}).catch((error) => {
            setToast({ type: "error", msg: error.data.message });
		})
        closeModal();
	};
    return (
        <div className="w-[29rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center justify-center">
                <div className=" bg-[#E5E5E5] h-2/12 w-2/12 rounded-full flex justify-center items-center ">
                    <img src={DeleteIcon} />
                </div>
            </div>
            <div className="flex font-inter-medium justify-center pt-4 text-lg text-center">Are you sure you want to delete this City ({data?.name}) ?</div>
            <div className='pb-6 pt-0.5 text-center'>
                <span className='text-[#87898E] text-center text-sm'>This action can’t be undone</span>
            </div>
            <div className="flex justify-between ">
                <Button className="w-[47.5%] bg-[#F1F1F1] text-[#23262F] cursor-pointer" onClick={closeModal}>Cancel</Button>
                <Button className="w-[47.5%] text-white cursor-pointer" type="submit" onClick={handleDelete} isSubmitting={status === "pending"}>Delete</Button>
            </div>
        </div>
    )
}

export default DeleteCityModal
