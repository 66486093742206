import React, { useEffect } from "react";
import Tabs from "../../shared/components/Tabs.component";
import { useState } from "react";
import CardsService from "./CardsService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CalendarTick, TickCircle, LocationTick, Timer1, SearchNormal1 } from "iconsax-react";
import { useGetRidersCompletedOrdersMutation, useLazyGetOrderByStatusQuery, useLazyGetOrderCountQuery } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";
import PaymentMode from "../../assets/icons/PaymentMode.svg";
import {
	useGetOrdersMutation,
	useLazyGetWidgetCountQuery,
	useWidgetsCountByRiderMutation,
} from "../../utils/Services/Apis/onDemand";
import moment from "moment";
import CalenderNonFormik from "../../shared/components/CalenderNonFormik";
import Loader from "../../shared/components/Loader";

function OnDemandMangement() {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const { setToast } = useToaster();
	const [getWidgetCounts, { data: widgetCountsWithoutRider }] = useLazyGetWidgetCountQuery();
	const [getWidgetCountsByRider, { data: widgetCountsWithRider }] = useWidgetsCountByRiderMutation();
	const [widgetCounts, setWidgetsCount]= useState()
	const [getOrderByStatus, { data: ordersData, status: orderStatus }] = useGetOrdersMutation();
	const [getRidersCompletedOrders, {data: completedOrdersData}] = useGetRidersCompletedOrdersMutation();
	const now = moment().toDate();
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [orderList, setOrderList] = useState([]);
	const [searchedOrder, setSearchedOrder] = useState("");
	const {riderId} = useParams();
	const {state} = useLocation();
	
	useEffect(() => {
		if(riderId){
			setSelectedIndex(3);
		}
	}, [])

	useEffect(() => {
		handleGetAppointments(categories[selectedIndex].status);
	}, [selectedIndex, selectedDate, searchedOrder]);

	const handleGetAppointments = async (status) => {
		if (status === "completed") {
			if (searchedOrder) {
				if(riderId){
						let { data, error } = await getRidersCompletedOrders({
						riderId,
						status,
						body: { startDate: state?.startDate, endDate:state?.endDate, search: searchedOrder },
					});
					if (!data) {
						setToast({ type: "error", msg: error.data.message });
					} else {
						setOrderList(data);
					}
					return;
				}
				let { data, error } = await getOrderByStatus({
					status,
					body: { fromDate: selectedDate, search: searchedOrder },
				});
				if (!data) {
					setToast({ type: "error", msg: error.data.message });
				} else {
					setOrderList(data);
				}
				return;
			}

			if(riderId){
				let { data, error } = await getRidersCompletedOrders({
					riderId,
					status,
					body: { startDate: state?.startDate, endDate:state?.endDate },
				});
				if (!data) {
					setToast({ type: "error", msg: error.data.message });
				} else {
					setOrderList(data);
				}
				return;
			}

			let { data, error } = await getOrderByStatus({
				status,
				body: { fromDate: selectedDate },
			});

			if (!data) {
				setToast({ type: "error", msg: error.data.message });
			} else {
				setOrderList(data);
			}
			return;
		}

		if (searchedOrder) {

			if(riderId){
				let { data, error } = await getRidersCompletedOrders({
					riderId,
					status,
					body: { startDate: state?.startDate, endDate:state?.endDate, search: searchedOrder },
				});
				if (!data) {
					setToast({ type: "error", msg: error.data.message });
				} else {
					setOrderList(data);
				}
				return;
			}

			let { data, error } = await getOrderByStatus({
				status,
				body: { search: searchedOrder },
			});
			if (!data) {
				setToast({ type: "error", msg: error.data.message });
			} else {
				setOrderList(data);
			}
			return;
		}

    if(riderId){
			let { data, error } = await getRidersCompletedOrders({
				riderId,
				status,
				body: { startDate: state?.startDate, endDate:state?.endDate },
			});
			if (!data) {
				setToast({ type: "error", msg: error.data.message });
			} else {
				setOrderList(data);
			}
			return;
		}

		let { data, error } = await getOrderByStatus({
			status,
		});
		if (!data) {
			setToast({ type: "error", msg: error.data.message });
		} else {
			setOrderList(data);
		}
	};

	const Navigation = () => {
		if(riderId) {
			return completedOrdersData?.map((order, index) => {
			return <CardsService status="navigation" orderDetails={order} key={index} handleGetAppointments={handleGetAppointments}/>;
		});
		}
		return orderList?.map((order, index) => {
			return <CardsService status="navigation" orderDetails={order} key={index} handleGetAppointments={handleGetAppointments}/>;
		});
	};

	const WorkInProgress = () => {
		if(riderId) {
			return completedOrdersData?.map((order, index) => {
			return <CardsService status="ongoing" orderDetails={order} key={index} handleGetAppointments={handleGetAppointments}/>;
		});
		}
		return orderList?.map((order, index) => {
			return <CardsService status="ongoing" orderDetails={order} key={index} handleGetAppointments={handleGetAppointments}/>;
		});
	};

	const Payment = () => {
		if(riderId) {
			return completedOrdersData?.map((order, index) => {
			return <CardsService status="payment" orderDetails={order} key={index} handleGetAppointments={handleGetAppointments}/>;
		});
		}
		return orderList?.map((order, index) => {
			return <CardsService status="payment" orderDetails={order} key={index} handleGetAppointments={handleGetAppointments}/>;
		});
	};

	const Completed = () => {
		if(riderId) {
			return completedOrdersData?.map((order, index) => {
			return <CardsService status="completed" orderDetails={order} key={index} />;
		});
	}
		return orderList?.map((order, index) => {
			return <CardsService status="completed" orderDetails={order} key={index} />;
		});
	};

	const handleRequestCount = (count) => {
		let countStr = "00";
		if (count == 0) return countStr;
		if (count > 0 && count < 10) {
			countStr = count > 0 && count < 10 ? "0" + count.toString() : count.toString();
		} else {
			countStr = count;
		}
		return countStr;
	};

	let categories = [
		{
			title: `Navigation (${handleRequestCount(
				widgetCounts?.navigation ? widgetCounts?.navigation : "00"
			)})`,
			status: "navigation",
			component: <Navigation />,
		},
		{
			title: `Work In Progress (${handleRequestCount(
				widgetCounts?.ongoing ? widgetCounts?.ongoing : "00"
			)})`,
			status: "ongoing",
			component: <WorkInProgress />,
		},
		{
			title: `Payment (${handleRequestCount(
				widgetCounts?.payment ? widgetCounts?.payment : "00"
			)})`,
			status: "payment",
			component: <Payment />,
		},
		{
			title: `Completed (${handleRequestCount(
				widgetCounts?.completed ? widgetCounts?.completed : "00"
			)})`,
			status: "completed",
			component: <Completed />,
		},
	];

	useEffect(() => {
		if(riderId){
			getWidgetCountsByRider(riderId)
		} else getWidgetCounts();
	}, [selectedIndex]);

	useEffect(()=>{
		if(riderId) {
			setWidgetsCount(widgetCountsWithRider)
		} else setWidgetsCount(widgetCountsWithoutRider)
	},[widgetCountsWithoutRider, widgetCountsWithRider])

	const decreaseDate = () => {
		const newDate = moment(selectedDate).subtract(1, "days").toDate();
		setSelectedDate(newDate);
	};

	const increaseDate = () => {
		const newDate = moment(selectedDate).add(1, "days").toDate();
		setSelectedDate(newDate);
	};

	const isToday =
		"" + selectedDate.getDate() + selectedDate.getMonth() + selectedDate.getFullYear() ===
		"" + now.getDate() + now.getMonth() + now.getFullYear();

	return (
		<div className="relative bg-gray-100 h-full">
			<div className="w-[75%] 2xl:w-3/4 sm:px-0">
				<div className="px-8 pb-5 pt-8">
					<div className="flex">
						<h3 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
							On Demand Job Management
						</h3>
					</div>
				</div>
				<div className="relative h-[3.2rem] pl-8 mb-4 pr-3 search-customer-height">
					<input
						type="text"
						className="w-[32rem] outline-focus h-full border-[#E9E9E9] rounded-xl py-1.5 pl-12 pr-3 text-base font-regular bg-[#FFF] placeholder-black focus:outline-none focus:border-[#E9E9E9] focus:ring-transparent"
						placeholder="Search Customer Name / Mobile Number / Vehicle Number"
						value={searchedOrder}
						onChange={(e) => setSearchedOrder(e.target.value)}
						onKeyPress= {(event) => {
														if (/[^a-zA-Z0-9 ]/.test(event.key)) {
															return event.preventDefault();
												}
						}}
					/>
					<div className="absolute left-12 top-[50%] translate-y-[-50%] mr-3">
						<SearchNormal1 color="gray" size={20} className="search-icon " />
					</div>
				</div>

				<div className="flex justify-between pl-8 mb-4 pr-3">
					<Tabs
						categories={categories}
						className="2xl:w-[38vw] lg:w-[41vw] tab-width-zoom"
						setSelectedIndex={setSelectedIndex}
						selectedIndex={selectedIndex}
					/>

					{selectedIndex === 3 && !riderId && (
						<div className="flex items-center">
							<div className="flex px-2 py-0.5 items-center border rounded-md border-[#D9D9D9] bg-[rgba(217,217,217,0.20)]">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									onClick={decreaseDate}
									className="mr-2 cursor-pointer w-[1.5rem] h-[1.5rem]"
								>
									<path d="M14.375 2.5L5.625 10L14.375 17.5V2.5Z" fill="black" />
								</svg>
								<CalenderNonFormik
									maxDate={now}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									customeClassName="text-sm"
								/>
								{!isToday && (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										onClick={increaseDate}
										className="cursor-pointer w-[1.5rem] h-[1.5rem]"
									>
										<path d="M5.625 17.5L14.375 10L5.625 2.5V17.5Z" fill="black" />
									</svg>
								)}
							</div>
						</div>
					)}
				</div>

				<div className="max-h-[65vh] overflow-y-auto overflow-x-hidden pr-3 pl-8">
					{(!ordersData?.length && !completedOrdersData?.length) ? (
						<Loader isLoading={orderStatus === "pending"} noDataText="No data found" />
					) : (
						categories[selectedIndex].component
					)}
				</div>
			</div>

			<div className="w-[25%] 2xl:w-1/4 absolute inset-y-0 top-11 right-0 pl-3 h-full">
				<div className="absolute w-0.5 bg-gray-300 h-[750px] mt-6"></div>

				<div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
					<div className="bg-[#FFC24C] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
						<CalendarTick color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
					</div>
					<div className="pl-5">
						<h1 className="text-base 2xl:text-lg font-inter-regular">Total Ongoing Appointments</h1>
						<h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
							{handleRequestCount(widgetCounts?.total)}
						</h1>
					</div>
				</div>

				<div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
					<div className="bg-[#FF621F] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
						<LocationTick color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
					</div>
					<div className="pl-5">
						<h1 className="text-base 2xl:text-lg font-inter-regular">Navigation</h1>
						<h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
							{handleRequestCount(widgetCounts?.navigation)}
						</h1>
					</div>
				</div>

				<div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
					<div className="bg-[#00BE68] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
						<Timer1 color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
					</div>
					<div className="pl-5">
						<h1 className="text-base 2xl:text-lg font-inter-regular">Work In Progress</h1>
						<h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
							{handleRequestCount(widgetCounts?.ongoing)}
						</h1>
					</div>
				</div>

				<div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
					<div className="bg-[#FB0C0C] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
						<img src={PaymentMode} color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
					</div>
					<div className="pl-5">
						<h1 className="text-base 2xl:text-lg font-inter-regular">Payment</h1>
						<h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
							{handleRequestCount(widgetCounts?.payment)}
						</h1>
					</div>
				</div>

				<div className="w-76 h-28 bg-white m-3 2xl:m-6 rounded-2xl flex items-center justify-start pl-3">
					<div className="bg-[#315EFF] h-10 w-10 2xl:h-14 2xl:w-14 rounded-full flex items-center justify-center">
						<TickCircle color="#FFFFFF" className="w-5 h-5 2xl:w-8 2xl:h-8" />
					</div>
					<div className="pl-5">
						<h1 className="text-base 2xl:text-lg font-inter-regular">Completed</h1>
						<h1 className="sm:text-2xl text-3xl 2xl:text-2xl font-semibold font-plus-jakarta">
							{handleRequestCount(widgetCounts?.completed)}
						</h1>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OnDemandMangement;
