import React from "react";
import { RiLoader4Fill } from "react-icons/ri";
export default function Loader({ isLoading, noDataText }) {
	return (
		<div className="h-96 w-full justify-center flex items-center">
			{isLoading ? (
				<RiLoader4Fill className="h-12 w-12 text-red-500 animate-spin" />
			) : (
				<div>{noDataText}</div>
			)}
		</div>
	);
}
