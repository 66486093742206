import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const authApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			signin: builder.mutation({
				query: (body) => ({
					url: endpoints.auth.signin,
					body: body,
					method: "POST",
				}),
			}),
			signup: builder.mutation({
				query: (body) => {
					return {
						url: endpoints.auth.signup,
						body: body,
						method: "POST",
					};
				},
			}),
			forgotPassword: builder.mutation({
				query: (body) => {
					return {
						url: endpoints.auth.forgotPassword,
						body: body,
						method: "POST",
					};
				},
			}),
			resetPassword: builder.mutation({
				query: ({tokenId, body}) => {
					return {
						url: endpoints.auth.resetPassword(tokenId),
						body: body,
						method: "POST",
					};
				},
			}),
		};
	},
});

export const {
	useSigninMutation,
	useSignupMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
} = authApi;
export { authApi };
