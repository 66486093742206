import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Toggle = ({ defaultChecked, handleOnToggle}) => {
	
	return (
		<Switch
			checked={defaultChecked}
			onChange={handleOnToggle}
			className={classNames(
				defaultChecked ? "bg-[#59D956] "  : "bg-[transparent] border-[gray]",
				"relative inline-flex h-5 w-8 flex-shrink-0 cursor-pointer rounded-full  border-2 transition-color duration-200 ease-in-out focus:outline-none"
			)}
		>
			<span className="sr-only">Use setting</span>
			<span
				aria-hidden="true"
				className={classNames(
					defaultChecked ? "translate-x-3.5 translate-y-0.5 " : "translate-x-0.5 translate-y-0.5 bg-[gray]",
					"pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
				)}
			/>
		</Switch>
	);
};

export default Toggle;
