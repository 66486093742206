import React, { useState, useEffect } from 'react'
import useSelectModal from '../../../utils/hooks/useSelectModal'
import editIcon from '../../../assets/icons/car-management/editBlueIcon.svg'
import Button from '../../../shared/components/Button.component'
import SelectMenu from '../../../shared/components/SelectMenu.component'
import Timepicker from '../../../shared/components/TimePicker.component'
import moment from 'moment'
import { Field, Form, Formik } from 'formik'
import { useUpdateRadiusTypeMutation } from '../../../utils/Services/Apis/areaMasterApi'
import useToaster from '../../../utils/hooks/useToaster'
import * as Yup from "yup";

const schema = Yup.object()
	.shape({
		radiusType: Yup.string().max(10, 'Radius Type should not exceed 10 characters').required("Radius Type is required"),
		radiusUnit: Yup.object().required("Radius Unit is required"),
	})

const EditMasterAreaModal = ({ data }) => {
    const { status, ...otherProps } = data;
    const [selectedTime1, setSelectedTime1] = useState(moment(otherProps.data.startTime, 'h:mm A'));
    const [selectedTime2, setSelectedTime2] = useState(moment(otherProps.data.endTime, 'h:mm A'));


    const { closeModal } = useSelectModal();
    const [ updateRadiusType, {status: updateRadiusStatus} ] = useUpdateRadiusTypeMutation();
    const { setToast } = useToaster();

    // useEffect(() => {
    //     setSelectedTime2(moment(selectedTime1).add(15, 'minutes'));
    // }, [selectedTime1]);

    const radiusUnit = [
        { name: "Kilometer", value: "Kilometer" },
        { name: "Meter", value: "Meter" },
    ];

    const handleSubmit = async (values) => {
       if(status === "radius-type"){
         const {data:d, error} = await updateRadiusType({
            radiusTypeId: data?.data?._id, data: {"type": values.radiusType, "unit": values.radiusUnit.value}
        })
        if(d){ setToast({ type: "success", msg: "Radius Type updated successfully" }) 
            closeModal()
        }
        if(error) setToast({ type: "error", msg: error?.data?.message });
       }
    }

    return (

        <Formik
			validationSchema={schema}
			initialValues={{
				radiusType: data?.data?.type,
                radiusUnit: radiusUnit.find((unit)=> unit.value === data?.data?.unit) 
			}}
            onSubmit={handleSubmit}
		>
			{({ values, setFieldValue, touched, errors }) => (
				<Form action="#" method="POST">
                    <div className="w-[32rem] min-h-[24rem] p-6 px-8 bg-[#FFFFFF] rounded-xl">
            <div className="flex items-center justify-between">
                <div className="h-2/12  rounded-full flex justify-center items-center ">
                    <img src={editIcon} />
                </div>
            </div>

            <div className="pb-6">
                <div className="flex font-inter-bold justify-start pt-4 pb-1 text-[#000000] text-lg">
                    Edit {status === "time-slot" ? "Assigned Time Slot" : "Radius Type"}
                </div>
                <div className="text-[#87898E]">Edit an existing {status === "time-slot" ? "assigned time slot" : "Radius type"} by changing it's details</div>
            </div>
            {status === "time-slot" && (
                <>
                    <div className='flex items-center justify-center pb-4'>
                        <div className='w-[100%] pr-3'>
                            <h1 className='font-inter-regular text-[#111827] text-sm'>From</h1>
                            <div className="">
                                <Timepicker
                                    selectedTime={selectedTime1}
                                    setSelectedTime={setSelectedTime1} />
                            </div>
                        </div>

                        <div className='w-[100%] pl-3'>
                            <h1 className='font-inter-regular text-[#111827] text-sm'>To</h1>
                            <div className="">
                                <Timepicker
                                    selectedTime={selectedTime2}
                                    setSelectedTime={setSelectedTime2}
                                    disabledTime={selectedTime1}
                                    disabled={!selectedTime2}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {status === "radius-type" && (
                <>
                    <div className='mb-4'>
                        <label className='font-inter-medium text-[#111827] text-sm'>Radius Type</label>
                        <Field
                            name="radiusType"
                            type="text"
                            className="block w-full rounded-md border border-gray-300 px-3 2xl:py-2 py-1 placeholder-gray-400 shadow-sm focus:border-gray-300 focus:outline-none text-sm h-[3rem] focus:ring-transparent "
                            placeholder="Kilometer"
                        />
                        {touched.radiusType && errors.radiusType ? (
                            <div className="text-red-600 text-sm">{errors.radiusType}</div>
                        ) : (
                            <div className="invisible h-5"></div>
					)}
                    </div>
                    <div className="mb-4">
                        <SelectMenu
                        label="Radius Unit"
                        name="radiusUnit"
                        options={radiusUnit}
                        placeholder="Select Radius Unit"
                        multiSelectClass="h-[3rem] radius-unit-ht w-full"
                        onChange={(option) => setFieldValue("radiusUnit", option)}
						value={values.radiusUnit}
                        />
                        {touched.radiusUnit && errors.radiusUnit ? (
                            <div className="text-red-600 text-sm">{errors.radiusUnit}</div>
                        ) : (
                            <div className="invisible h-5"></div>
					    )}
                    </div>
                </>
            )}
            <div className="flex justify-between pt-5 pb-4">
                <Button className="w-[47.5%] h-[3.5rem] bg-[#F1F1F1] text-[#23262F] cursor-pointer rounded-[10px] font-inter-bold text-base border-[#D0D0D0]" onClick={closeModal}>
                    Cancel
                </Button>
                <Button className="w-[47.5%] h-[3.5rem] bg-[#000000] text-white cursor-pointer rounded-[10px] font-inter-bold text-base border" type="submit" isSubmitting = { updateRadiusStatus === "pending" }>
                    Save Changes
                </Button>
            </div>
        </div>
        
			</Form>
			)}
        </Formik>
       
    )
}

export default EditMasterAreaModal
