import { Field } from "formik";
import React from "react";

export default function RadioButton({ name, value }) {
	return (
		<Field
			type="radio"
			name={name}
			className="appearance-none items-center border-gray-600 border-2 checked:bg-gray-600 focus:text-gray-600 focus:ring-transparent md:mt-0 sm:mt-0 cursor-pointer hover:text-gray-600"
			value={value}
		/>
	);
}
