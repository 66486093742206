import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const shiftMasterApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAllShifts: builder.query({
				query: () => ({
					url: endpoints.shiftCategory.getAllShifts,
					method: "GET",
				}),
				providesTags: ["ShiftCategory"],
			}),
			addShift: builder.mutation({
				query: (body) => {
					return {
						url: endpoints.shiftCategory.addShift,
						method: "POST",
						body: body,
					};
				},
				invalidateTags: ["ShiftCategory"],
			}),
			updateShift: builder.mutation({
				query: ({ id, data }) => ({
					url: endpoints.shiftCategory.updateShift(id),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["ShiftCategory"]
			}),
			updateTimeSlotCreation: builder.mutation({
				query: ({ id, data }) => ({
					url: endpoints.shiftCategory.updateTimeSlotCreation(id),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["TimeSlotCreation"]
			}),
			deleteTimeSlotCreation: builder.mutation({
				query: (id) => ({
					url: endpoints.shiftCategory.deleteTimeSlotCreation(id),
					method: 'DELETE'
				})
			}),
			deleteShiftCategory: builder.mutation({
				query: (shiftId) => ({
					url: endpoints.shiftCategory.deleteShiftCategory(shiftId),
					method: 'DELETE'
				})
			}),
			getTimeSlotCreationPagination: builder.query({
				query: ({pageNo, pageSize}) => {
					return {
						url: endpoints.shiftCategory.getTimeSlotCreationPagination(pageNo, pageSize),
						method: "GET",
					};
				},
				providesTags: ["Time Slot"],
			}),
			addTimeSlotCreation: builder.mutation({
				query: (body) => ({
					url: endpoints.shiftCategory.addTimeSlotCreation,
					body: body,
					method: "POST",
				}),
				// invalidatesTags: ["timeslot"]
			}),
			getShiftsForTimeslots: builder.query({
				query: () => {
					return {
						url: endpoints.shiftCategory.getShiftsForTimeslots,
						method: "GET"
					}
				}
			})
		};
	},
});

export const {
	useLazyGetAllShiftsQuery,
	useAddShiftMutation,
	useUpdateShiftMutation,
	useUpdateTimeSlotCreationMutation,
	useDeleteTimeSlotCreationMutation,
	useDeleteShiftCategoryMutation,
	useLazyGetTimeSlotCreationPaginationQuery,
	useAddTimeSlotCreationMutation,
	useLazyGetShiftsForTimeslotsQuery,
} = shiftMasterApi;
export { shiftMasterApi };