import React from "react";
import {
	Calendar1,
	Car,
	Clock,
	GasStation,
	Keyboard,
	Lifebuoy,
	Location,
	User,
	Message,
	CallCalling,
	Calendar,
} from "iconsax-react";
import DatePickerComponent from "../../shared/components/DatePicker.component";
import Brake from "../.././assets/icons/service_request_management/brake.svg";
import CarPolish from "../.././assets/icons/service_request_management/car.svg";
import Tire from "../.././assets/icons/service_request_management/tire.svg";
import Edit from "../.././assets/icons/service_request_management/edit.svg";
import Button from "../../shared/components/Button.component";
import PaymentMode from "../../assets/icons/payment-mode.svg";
import KeyboardIcon from "../../assets/icons/small-keyboard.svg";
import SuccessTick from "../../assets/icons/success-tick.svg";
import SuccssWallet from "../../assets/icons/success-green-icon.png";
import PhoneCall from "../../assets/icons/phone-call.svg";
import moment from "moment";
import useSelectModal from "../../utils/hooks/useSelectModal";
import { useRescheduleOrCancelMutation } from "../../utils/Store";
import useToaster from "../../utils/hooks/useToaster";
import ProgressBar from "@ramonak/react-progress-bar";
import ImageThumbnail from "../../shared/components/ImageThumbnail";
import { useGetTotalAmountMutation, useUpdateAppointmentMutation } from "../../utils/Services/Apis/onDemand";
import AddOnServices from "./AddOnServices";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { data, formatTime, secondsToTime, timeDifference, timeToSeconds } from "../../utils/helper/progressBarCalculations";
import { useRef } from "react";

function CardsService({ type, status, orderDetails = null, handleGetAppointments }) { 
	// const orderDetails = data
	const [allServicesTotalTime, setAllServicesTotalTime] = useState("")
	const [currentService, setCurrentService] = useState("")
	const [extraTimeInSeconds, setExtraTimeInSeconds] = useState(0)
	const [timeCompletedByServiceInSeconds, setTimeCompletedByServiceInSeconds] = useState(0)
	const [earliestService, setEarliestService] = useState(null)
	const serviceRef = useRef(null)
  	const [getTotalAmount, {data : amountData}]= useGetTotalAmountMutation()


	useEffect(()=>{
		if(!orderDetails) return

		const current = orderDetails.service?.find((service)=> service.startTime && !service.endTime && (service.addOnServiceVerification !== "false"))
		setCurrentService(current)
		if(serviceRef.current !== current) serviceRef.current = current
		const totalTimeInSeconds = orderDetails?.service?.reduce((accumulator , service)=> {
			if(service.addOnServiceVerification === "false") return accumulator
			const hrsToMins =  (+service.serviceTime[0].hours * 3600)
			const mins =(+service.serviceTime[0].minutes * 60)
			return accumulator + hrsToMins + mins
		},0)

		setAllServicesTotalTime(totalTimeInSeconds)
	},[orderDetails])

	useEffect(()=>{
		let extraTimeInSecondsCompletedServices = orderDetails?.service?.reduce((accumulator , service)=> {
			if((service.addOnServiceVerification === "false") || !service.startTime || !service.endTime) return accumulator
			const timeDiff =   timeDifference(service?.startTime, service?.endTime)
			const serviceDurationInSeconds = timeToSeconds(service?.serviceTime[0])
			const extraTimeInSeconds = timeToSeconds(timeDiff) - serviceDurationInSeconds
			return accumulator + extraTimeInSeconds
		},0)

		if(currentService){
			const timeDiff = timeDifference(currentService?.startTime)
			const serviceDurationInSeconds = timeToSeconds(currentService?.serviceTime[0])
			const extraTimeInSeconds = timeToSeconds(timeDiff) - serviceDurationInSeconds
			if(extraTimeInSeconds > 0) extraTimeInSecondsCompletedServices += extraTimeInSeconds
		}
		setExtraTimeInSeconds(extraTimeInSecondsCompletedServices)

	},[orderDetails, currentService])

	useEffect(()=>{
		let timeInSecondsCompletedServices = orderDetails?.service?.reduce((accumulator , service)=> {
			if((service.addOnServiceVerification === "false") || !service.startTime || !service.endTime) return accumulator
			const timeDiff = timeDifference(service?.startTime, service?.endTime)
			const extraTimeInSeconds =  timeToSeconds(timeDiff)
			return accumulator + extraTimeInSeconds
		},0)

		if(currentService){
			const timeDiff = timeDifference(currentService?.startTime)
			const extraTimeInSeconds =  timeToSeconds(timeDiff)
			if(extraTimeInSeconds > 0) timeInSecondsCompletedServices +=  extraTimeInSeconds
		}
		setTimeCompletedByServiceInSeconds(timeInSecondsCompletedServices)

	},[orderDetails, currentService])

	const completedLength = Math.round(allServicesTotalTime / (allServicesTotalTime + extraTimeInSeconds) * 100)


	const extraTime = secondsToTime(extraTimeInSeconds);

	const { setToast } = useToaster();
	const { openModal , closeModal} = useSelectModal();
	const [updateAppointment, { status: updateAppointmentStatus }] = useUpdateAppointmentMutation();

	let addonServices = []

	if (!orderDetails) return;


	function formatNumber(num) {
		if (num < 10) {
			return "0" + num;
		}
		return "" + num;
	}

	if (status === "ongoing" || status === "payment" || status === "completed") {
		addonServices = orderDetails?.service?.filter((service) => service.addOnServiceVerification)
	}
	
	if (status === "completed") {
		addonServices = orderDetails?.service?.filter((service) => service.addOnServiceVerification)
	}

	const convertBeforeServiceImageUrls = (services) => {
		services = services.filter(service => 
			(service.addOnService && service.addOnServiceVerification) || (!service.addOnService && !service.addOnServiceVerification)
		)
		let serviceData = services.reduce((acc, service) => {
			const imgs = service?.beforeServiceImageUrls?.map(imgUrl => ({
				serviceName: service.serviceName,
				imgUrl
			}));
			return acc.concat(imgs);
		}, []);
		return serviceData
	};
	
	const convertAfterServiceImageUrls = (services) => {
		services = services.filter(service => 
			(service.addOnService && service.addOnServiceVerification) || (!service.addOnService && !service.addOnServiceVerification)
		)
		let serviceData = services.reduce((acc, service) => {
			const imgs = service?.afterServiceImageUrls?.map(imgUrl => ({
				serviceName: service.serviceName,
				imgUrl
			}));
			return acc.concat(imgs);
		}, []);
		return serviceData
	}

	const endService = async () => {

    const {data:amountData, error:amountError} = await getTotalAmount({
			orderId: orderDetails._id,
			vehicleType: orderDetails.vehicleType
		})

		if(amountError) return setToast({ type: "error", msg: amountError.data.message });
    
		if(amountData) {
       const { data: d, error } = await updateAppointment({
				orderId: orderDetails?._id,
				status: "complete",
			});
			if (amountData?.totalAmount === 0){ 
					handleGetAppointments(status)
					return openModal("EndServiceSuccessModal", {...orderDetails})
				} 
			if (d) {
				setToast({ type: "success", msg: d.message });
				openModal("PaymentModal", {...orderDetails, customeClassForModal: 'margin-top-modal' , handleGetAppointments:handleGetAppointments , fetchStatus:status , totalAmount : amountData.totalAmount})
			}
			if (error) setToast({ type: "error", msg: error.data.message });
		} 
	}

	const time = secondsToTime(allServicesTotalTime)
	const timeCompletedByServices = secondsToTime(timeCompletedByServiceInSeconds)

	const completion = Math.round((timeCompletedByServiceInSeconds / allServicesTotalTime) * 100);

	// const handleTotalPayable = (orderData) => {
	// 	let totalPayableAmount = orderData?.payableAmount;
	// 	if(orderData?.service.length >= 2) {
	// 		for (let index = 1; index < orderData?.service.length; index++) {
	// 			if(orderData?.service[index]?.addOnService && orderData?.service[index]?.addOnServiceVerification)
	// 				totalPayableAmount += orderData?.service[index]?.addOnServiceAmount ? orderData?.service[index]?.addOnServiceAmount : 0
	// 		}
	// 	}
	// 	return totalPayableAmount;
	// }


	return (
		<>
			<div className="">
				{type === "success" && (
					<div className="flex items-center pb-4">
						<img src={SuccssWallet} color="white" />
						<h1 className="ml-4">Your Appointment has been successfully Booked </h1>
					</div>
				)}

				<div
					className={`w-full h-2/4 pb-5 mb-3 bg-white ${(status === "navigation" || status === "ongoing")
						? "border-[#FF621F]"
						: status === "completed"
							? "border-[#315EFF]"
							: status === "payment"
								? "border-[#FB0C0C]"
								: ""
						} border-l-8 shadow rounded-xl order-modal-${type}`}
				>
					<div className="bg-white rounded-t-lg drop-shadow-md px-5 py-4">
						<div className="flex items-center justify-between">
							<div className="flex items-center py-1.5 px-3 mt-2 bg-[#F1F1F1] rounded-lg w-fit space-x-2">
								<Car className="w-5 h-5 2xl:w-7 2xl:h-7" />
								<h1 className="font-inter-medium text-base 2xl:text-lg pl-1">
									{orderDetails?.service[0]?.serviceName}
								</h1>
							</div>
							<div className="flex justify-evenly items-center mr-4">
								<div className="flex items-center">
									<Clock size={20} className="mx-3 w-5 h-5 2xl:w-7 2xl:h-7" />
									<h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">{orderDetails?.timeslotDetails?.split(" - ")[0]}</h1>
								</div>
								<div className="flex items-center ml-6">
									<Calendar size={20} className="mx-4 w-5 h-5 2xl:w-7 2xl:h-7" />
									<h1 className="text-base 2xl:text-xl font-inter-medium text-[#000]">
										{moment(orderDetails?.serviceDate).format("DD MMMM YYYY")}
									</h1>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-between pt-5 pr-4">
							{type !== "success" && (
								<>
									<div className="w-[25%]">
										<Button className="bg-[#D9D9D9] rounded-lg text-base 3xl:text-xl font-inter-medium text-[#000000] cursor-pointer border border-[#A8A8A840]">
											One Time Service
										</Button>
									</div>
									{(status === "payment" || status === "completed") && (
										<div className="w-[75%] flex items-center">
											<div className="w-full">
												<div className="flex justify-between mb-2">
													<p className="font-inter-bold text-sm 2xl:text-base text-[#000]">
														{formatTime(timeCompletedByServices.hours, timeCompletedByServices.minutes, timeCompletedByServices.seconds)} <span className="text-[#000] font-inter-bold text-sm 2xl:text-base">(Completed)</span>
													</p>
													<p className="font-inter-bold text-sm 2xl:text-base mr-1.5">
														{ formatTime(time.hours, time.minutes, time.seconds)}
													</p>
												</div>
												<ProgressBar
													completed={100}
													labelAlignment="center"
													className="progress-bar-height"
													labelClassName="progress-bar-label"
													bgColor="#24BB61"
													width="100%"
												/>
											</div>
										</div>
									)}
									{(status === "ongoing") &&
										<>
											<div className="w-[80%]">
											  <div className="flex justify-between mb-2 ">
														<p className="font-inter-bold text-sm 2xl:text-base text-[#000]">
															{formatTime(timeCompletedByServices.hours, timeCompletedByServices.minutes, timeCompletedByServices.seconds)}  {currentService && <span className="text-[#000] font-inter-bold text-sm 2xl:text-base"> ({`${currentService?.serviceName}`})</span>}
														</p>
														<p className="font-inter-bold text-sm 2xl:text-base flex gap-1">
															{formatTime(time.hours, time.minutes, time.seconds)} <span className={`text-[#CB0303] font-inter-bold ml-auto text-sm 2xl:text-base`}>{extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}</span>
														</p>
													</div>
												<div className="h-2 flex items-center">
													<div style={{width : completedLength + "%"}}>
														<ProgressBar
															completed={completion > 100 ? 100 : completion}
															labelAlignment="center"
															className="progress-bar-height"
															labelClassName="progress-bar-label"
															bgColor="#24BB61"
															width="100%"
														/>
													</div>
													{completedLength !== 100 && <span
														style={{
															border: "1px solid #FB0C0C",
															height: "20px",
															// marginTop: "2rem"
														}}
														// className="progress-margin-top"
													></span>}
													{completedLength !== 100 && <div style={{width : 100-completedLength + "%"}}>
														
														<ProgressBar
															completed={100}
															labelAlignment="center"
															className="progress-bar-height"
															labelClassName="progress-bar-label"
															bgColor="#FB0C0C"
															width="100%"
															isLabelVisible={false}
														/>
													</div>}
												</div>
											</div>
										</>

									}

									{
										(status === "completed") && orderDetails?.leadStatus &&
										<div
											className="relative left-5 bg-[#315EFF]/20"
										>
											<h1
												className="relative h-10 w-44 text-lg font-medium font-plus-jakarta text-center pt-1.5 pl-3 text-[#315EFF] rounded-l-full"
											>
												Lead Generated
												<div className="h-8 w-8 rounded-full absolute -left-5 top-1/2 bg-white -translate-y-1/2"></div>
											</h1>
										</div>
									}


								</>
							)}
						</div>
					</div>
					<div className="">
						<div className="flex justify-between ">
							<div className="w-[20%] 2xl:w-[30%] p-5 pt-6 h-[12vw] width-30">
								<img
									src={orderDetails?.vehicleModelImage}
									className="w-full h-full object-contain"
								/>
							</div>

							<div className="w-[30%] pt-6 relative">
								<div className="w-[80%] overflow-x-auto final-price-scrollbar">
									<h1 className="2xl:text-[22px] text-lg font-inter-bold vehicle-name-text whitespace-nowrap">
										{orderDetails?.vehicleName}
									</h1>
								</div>
								<div className="flex space-x-8 h-[3vw] items-center">
									<div className="flex space-x-3 items-center">
										<GasStation size={22} color="#666666" className="icon-size-14px" />
										<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
											{orderDetails?.fuelType?.charAt(0).toUpperCase() +
												orderDetails?.fuelType?.slice(1)}
										</h1>
									</div>
									<div className="flex space-x-3 items-center">
										<Lifebuoy size={22} color="#666666" className="icon-size-14px" />
										<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
											{orderDetails?.transmissionType.charAt(0).toUpperCase() +
												orderDetails?.transmissionType.slice(1)}
										</h1>
									</div>
								</div>

								<div className="flex space-x-3 h-[3vw] items-center">
									<img src={KeyboardIcon} className="w-6 h-6 icon-size-14px" />
									<h1 className="font-inter-medium text-[#474747] text-sm 2xl:text-base">
										{orderDetails?.vehicleNumber.replace(
											/([A-Z]{2})(\d{2})([A-Z]{2})(\d{4})/,
											"$1 $2 $3 $4"
										)}
									</h1>
								</div>

								<div className=" flex space-x-5 h-[3vw] items-center">
									<p className="text-black font-inter-medium text-sm 2xl:text-base ">Assigned To</p>
									<div className="w-[50%]">
										<div className="border items-center sm:pl-2 bg-[#FFFFFF] flex space-x-2 font-normal border-inherit rounded-md p-1.5 w-full justify-start pl-1">
											<User size={18} color="#666666" className="icon-size-14px mx-1" />
											<span className="text-[#474747] text-sm 2xl:text-base truncate">
												{orderDetails?.riderName ? orderDetails?.riderName : "None"}
											</span>
										</div>
									</div>
								</div>

								<div className="absolute 2xl:right-7 lg:right-[0.75rem] top-3 w-px bg-gray-300 h-[14vw]"></div>
							</div>
							<div className="w-[50%] 2xl:w-[40%] pb-4 pt-6 width-40">
								<div className="flex h-[5vw]">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Address</p>
									</div>

									<div className="flex justify-center items-start">
										<Location size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] text-sm 2xl:text-base w-56 pl-2 ml-0 -mt-1 line-clamp-3 2xl:line-clamp-4">
											{orderDetails?.addressLineOne && <span>{orderDetails?.addressLineOne}</span>}
											{orderDetails?.addressLineTwo && <span>, {orderDetails?.addressLineTwo}</span>} 
											{orderDetails?.landMark && <span>, {orderDetails?.landMark}</span>} 
											{orderDetails?.areaName && <span>, {orderDetails?.areaName}</span>} 
											{orderDetails?.cityName && <span>, {orderDetails?.cityName}</span>} 
											{orderDetails?.pincode && <span>, {orderDetails?.pincode}</span>}  
										</p>
									</div>
								</div>

								<div className="flex h-[3vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">
											Customer Name
										</p>
									</div>

									<div className="flex justify-center items-center">
										<User size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] pr-2 pl-2 3xl:text-base text-sm truncate">
											{orderDetails?.customerName}
										</p>
										<img src={PhoneCall} className="w-5 h-5 icon-size-14px" />
										<p className="ml-2 font-inter-regular text-[#474747] 3xl:text-base text-sm">{orderDetails?.customerPhoneNumber?.substring(0,3)} {orderDetails?.customerPhoneNumber?.substring(3)}</p>
									</div>
								</div>

								<div className="flex h-[3vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">
											Customer Email
										</p>
									</div>

									<div className="flex space-x-1 items-center w-[65%] pr-4">
										<Message size={20} color="#666666" className="icon-size-14px" />
										<p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate">
											{orderDetails?.customerEmail}
										</p>
									</div>
								</div>

								<div className="flex h-[3vw] items-center">
									<div className="w-[35%]">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">
											Order ID
										</p>
									</div>

									<div className="flex space-x-1 items-center w-[65%] pr-4">
										{/* <Message size={20} color="#666666" className="icon-size-14px" /> */}
										<p className="font-inter-regular text-[#474747] pl-1 text-sm 2xl:text-base truncate">
											{orderDetails?.orderUniqueId ? orderDetails?.orderUniqueId : '-'}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{(status === "payment" || status === "completed") && addonServices.length > 0 && (
						<>
							<div className="px-5 mt-3">
								<hr className="border bg-gray-300 w-full" />
							</div>
							<div className="pt-5 2xl:px-16 lg:px-9">
								<div className="">
									<p className="text-black font-inter-medium text-sm 2xl:text-base">Add On Services</p>
								</div>
								<div className="items-center overflow-x-auto final-price-scrollbar">

									<div className="flex space-x-3 w-max">
										{addonServices.map((addOnService, i) => {
											const serviceDurationInSeconds = timeToSeconds(addOnService?.serviceTime[0]);
											const timeDiff = addOnService?.startTime ? timeDifference(addOnService?.startTime, addOnService?.endTime) : { hours: 0, minutes: 0, seconds: 0 };
											const extraTimeInSeconds = addOnService?.startTime ? timeToSeconds(timeDiff) - serviceDurationInSeconds : 0;
											const extraTime = secondsToTime(extraTimeInSeconds);
											return (
												<div className="flex items-center justify-center space-x-2 border rounded-lg py-1 px-3 my-2" key={i}>
													<img src={addOnService?.imageUrl} className="h-5 w-5" />
													<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">{addOnService?.serviceName}</p>
													<Clock size={18} color="gray" className="icon-size-14px" />
													<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
														{addOnService?.serviceTime[0]?.hours}:
														{formatNumber(addOnService?.serviceTime[0]?.minutes)} Hours
														<span className="text-[#CB0303] font-inter-bold text-sm 2xl:text-base">
															{extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}
														</span>
													</p>
													<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
														<span className="text-sm 2xl:text-base">₹</span>
														{addOnService?.addOnServiceAmount}
													</p>
												</div>
											)
										})}
										{/* <div className="flex items-center justify-center space-x-2 border rounded-lg py-1 px-3 my-2">
												<img src={Brake} className="h-5 w-5" />
												<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">Brake Polish</p>
												<Clock size={18} color="gray" className="icon-size-14px" />
												<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
													{orderDetails?.service[0]?.serviceTime[0].hours}:
													{formatNumber(orderDetails?.service[0]?.serviceTime[0].minutes)} Hours
												</p>
												<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
													<span className="text-sm 2xl:text-base">₹</span>
													{orderDetails.payableAmount}
												</p>
											</div>
											<div className="flex items-center justify-center space-x-2 border rounded-lg py-1 px-3 my-2">
												<img src={CarPolish} className="h-5 w-5" />
												<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">Car Polish</p>
												<Clock size={18} color="gray" className="icon-size-14px" />
												<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
													{orderDetails?.service[0]?.serviceTime[0].hours}:
													{formatNumber(orderDetails?.service[0]?.serviceTime[0].minutes)} Hours
												</p>
												<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
													<span className="text-sm 2xl:text-base">₹</span>
													{orderDetails.payableAmount}
												</p>
											</div> */}
									</div>

								</div>
							</div>
						</>
					)}
					{status === "ongoing" && !!addonServices?.length && (
						<>
							<div className="px-5 mt-3">
								<hr className="border bg-gray-300 w-full" />
							</div>
							<div className="pt-5 pl-16 pr-8">
								<div className="items-center">
									<div className="">
										<p className="text-black font-inter-medium text-sm 2xl:text-base">Add On Services</p>
									</div>
									<div className="">
										{addonServices
											.map((service)=> <AddOnServices service={service} />)
										}
										{/* <div className="flex">
											<div className="w-[42%]">
												<div className="border rounded-lg py-1 px-3 my-2 overflow-x-auto max-w-fit final-price-scrollbar">
													<div className="flex items-center justify-center space-x-2 w-max">
														<img src={Brake} className="h-5 w-5" />
														<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">Brake Polish</p>
														<Clock size={18} color="gray" className="icon-size-14px" />
														<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
															{orderDetails?.service[0]?.serviceTime[0].hours}:
															{formatNumber(orderDetails?.service[0]?.serviceTime[0].minutes)} Hours
														</p>
														<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
															<span className="text-sm 2xl:text-base">₹</span>
															{orderDetails.payableAmount}
														</p>
													</div>
												</div>
											</div>
											<div className="w-[58%] flex items-center pl-10">
												<div className="w-[100%]">
													<div className="flex justify-between">
														<p className="text-sm 2xl:text-base font-inter-bold text-[#000]">
															00:25:00{" "}
															<span className="text-[#000] font-inter-bold text-sm 2xl:text-base"> (In-progress)</span>
														</p>
														<p className="text-sm 2xl:text-base font-inter-bold">00:20:00</p>
													</div>
													<ProgressBar
														completed={50}
														labelAlignment="center"
														className="progress-bar-height"
														labelClassName="progress-bar-label"
														bgColor="#24BB61"
														width="100%"
													/>
												</div>
											</div>
										</div> */}

										{/* <div className="flex">
											<div className="w-[42%]">
												<div className="border rounded-lg py-1 px-3 my-2 overflow-x-auto final-price-scrollbar max-w-fit">
													<div className="flex items-center justify-center space-x-2 w-max">
														<img src={Brake} className="h-5 w-5" />
														<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">Brake Polish</p>
														<Clock size={18} color="gray" className="icon-size-14px" />
														<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
															{orderDetails?.service[0]?.serviceTime[0].hours}:
															{formatNumber(orderDetails?.service[0]?.serviceTime[0].minutes)} Hours
														</p>
														<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
															<span className="text-sm 2xl:text-base">₹</span>
															{orderDetails.payableAmount}
														</p>
													</div>
												</div>
											</div>
											<div className="w-[58%] flex items-center pl-10">
												<div className="w-[100%]">
													<div className="flex justify-between">
														<p className="text-sm 2xl:text-base font-inter-bold text-[#000]">
															00:25:00{" "}
															<span className="text-[#000] font-inter-bold text-sm 2xl:text-base"> (In-progress)</span>
														</p>
														<p className="text-sm 2xl:text-base font-inter-bold">00:20:00</p>
													</div>
													<ProgressBar
														completed={0}
														labelAlignment="center"
														className="progress-bar-height"
														labelClassName="progress-bar-label"
														bgColor="#24BB61"
														width="100%"
													/>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>
						</>
					)}
					<div className="px-5 mt-3">
						<hr className="border bg-gray-300 w-full" />
					</div>
					<div className="flex justify-between pt-5 2xl:pl-16 lg:pl-9 pr-9">
						<div className="flex items-center space-x-14">
							<div className="">
								<p className="text-black font-inter-medium text-sm 2xl:text-base">Total Service Time</p>
							</div>
							<div className="flex items-center justify-center space-x-3 ml-2 border rounded-lg p-1 px-3">
								<Clock size={18} color="gray" className="icon-size-14px" />
								<p className="font-inter-medium text-sm 2xl:text-base text-[#000000]">
									{formatTime(time.hours, time.minutes, time.seconds)} Hours
									<span className="text-[#CB0303] font-inter-bold text-sm 2xl:text-base" >{extraTimeInSeconds > 0 ? " + " + formatTime(extraTime.hours, extraTime.minutes, extraTime.seconds) : ""}</span>
								</p>
							</div>
						</div>
						<div className="flex items-center space-x-14">
							<div className="">
								<p className="text-black font-inter-medium text-sm 2xl:text-base">Total Price</p>
							</div>
							<div className="flex space-x-2 ml-3 border rounded-lg p-1 px-3">
								<p className="text-gray text-sm 2xl:text-base">₹</p>
								<p className="text-[#000000] font-inter-medium text-sm 2xl:text-base">
									{orderDetails?.payableAmount}
								</p>
							</div>
						</div>
					</div>
					<div className=" px-5">
						<hr className="border bg-gray-300 w-full mt-5" />
					</div>
					<div className="flex items-center py-5 justify-between">
						<div className="flex items-center 2xl:pl-16 lg:pl-9 2xl:space-x-6 space-x-2">
							<div className="flex mr-2">
								<img src={PaymentMode} color="white" className="h-9 w-9" />

								<h1 className="font-inter-medium pl-2 flex items-center justify-center text-base 2xl:text-base lg:text-sm">
									Payment Mode :
								</h1>
							</div>
							<div className="">
								<p className="font-inter-medium text-gray-700 text-md border lg:text-sm 2xl:text-base rounded-lg p-1 px-5">
									{orderDetails?.paymentType == "cash" ? "Cash" : "Online"}
								</p>
							</div>
						</div>

						{status !== "completed" && (
							<div className="flex items-center justify-evenly px-5">
								<Button
									className="bg-black lg:w-[25rem] 2xl:w-[32.5rem] text-white font-inter-regular text-base lg:text-base 2xl:text-lg border border-black-200 rounded-[10px] p-2 cursor-pointer h-[3.1rem] btn-radius btn-width"
									onClick={() => {
										status !== "payment" ? openModal("UpdateAppointmentModal", {...orderDetails , handleGetAppointments:handleGetAppointments , fetchStatus:status}) : endService();
									}}
									isSubmitting={updateAppointmentStatus === "pending"}
								>
									{status === "payment" ? "End Service" : "Update Appointment"}
								</Button>
							</div>
						)}
						{status === "completed" && (
							<>
								<div className="right-4 top-3 w-px bg-gray-300 h-[2vw]"></div>
								<div className="flex items-center mr-[2.5rem] w-[60%] justify-between">
									<div className="flex items-center justify-center 2xl:mr-9 mr-5 space-x-2">
										<div className="flex mr-3">
											<h1 className="font-semibold 2xl:text-base lg:text-sm">Before Service Photos :</h1>
										</div>
										<div>
											<ImageThumbnail imgUrls={convertBeforeServiceImageUrls(orderDetails?.service)}/>
										</div>
									</div>
									<div className="flex items-center justify-center space-x-2">
										<div className="flex mr-3">
											<h1 className="font-semibold 2xl:text-base lg:text-sm">After Service Photos :</h1>
										</div>
										<div>
											<ImageThumbnail imgUrls={convertAfterServiceImageUrls(orderDetails?.service)} />
										</div>
									</div>
								</div>
							</>
						)}
					</div>
					{/* {type !== "success" && (
						<>
							<hr className="border bg-gray-300 w-[1100px] mx-5" />
							<div className="">

								{
									status === "payment" &&
									<div className="">
										<div className=" flex space-x-7 pt-3 px-5 items-center">

											<p className="text-gray-900 font-semibold text-base">payment by {orderDetails?.canceledBy?.role}</p>
											<div className="">
												<Button className="bg-[#FFFFFF] flex space-x-2 font-normal w-32 cursor-pointer">
													<User size={20} color="#666666" />
													<span className="text-[#474747]">{orderDetails?.canceledBy?.name}</span>
												</Button>
											</div>
										</div>
										<div className="flex justify-between">
											<div className=" flex space-x-1 px-5 items-center w-1/2">
												<p className="text-gray-900 font-semibold text-base">Reason :</p>
												<div className="">
													<p className="font-inter-medium text-[#474747]">Vehicle is not available at place</p>
												</div>
											</div>
											<div className="flex items-center justify-center px-11 w-1/2">
												<p className="font-inter-medium text-[#474747] pt-6 "><span className="text-gray-900 font-semibold text-base pr-1">Note:</span>I Booked Service appointment but due to some personal reason the car is not available at this time</p>

											</div>
										</div>
									</div>
								}
							</div>
						</>
					)} */}
				</div>
			</div>
		</>
	);
}

export default CardsService;
