import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "iconsax-react";

const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
	<div
		className={`flex gap-3 text-base font-inter-medium items-center h-[2.3rem] p-2 cursor-pointer ${
			!value && "text-gray-500"
		} `}
		onClick={onClick}
		ref={ref}
	>
		<Calendar className="h-5 w-5" />
		{value || placeholder}
	</div>
));

export default function CalenderNonFormik({ now, maxDate, selectedDate, setSelectedDate, customeClassName }) {
	return (
		<DatePicker
			minDate={now}
			maxDate={maxDate}
			selected={selectedDate}
			onChange={(date) => setSelectedDate(date)}
			className={`w-full rounded-lg bg-[#f1f1f1] border-[#d9d9d9] mt-1 ${customeClassName}`}
			dateFormat="d MMMM, yyyy"
			customInput={<CustomInput />}
			showIcon
		/>
	);
}
