import React from 'react'
import TableComponent from '../../../shared/components/Table.component'
import { FiArrowLeft } from 'react-icons/fi';
import useSelectModal from '../../../utils/hooks/useSelectModal';

function RevenueModal() {
    const { closeModal } = useSelectModal();
    const revenueData = [
        {
            dateRange: "1st Jan 2024 - 10th Jan 2024",
            amount: "5000 (0-5000)",
            percentage: "5%"
        },
        {
            dateRange: "11th Jan 2024 - 20th Jan 2024",
            amount: "10000 (5000-10000)",
            percentage: "10%"
        },
        {
            dateRange: "21st Jan 2024 - 30th Jan 2024",
            amount: "20000 (10000-20000)",
            percentage: "5%"
        }
    ]
    const column = [
        {
            header: "Date Range",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-x-2 items-center w-full">
                        <span className="truncate font-inter-medium">{original?.dateRange}</span>
                    </span>
                );
            },
        },
        {
            header: "Amount",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-x-2 items-center w-full">
                        <span className="truncate font-inter-medium">{original?.amount}</span>
                    </span>
                )
            }
        },
        {
            header: "Percentage",
            cell: ({ row, getValue }) => {
                const { original } = row;
                return (
                    <span className="flex gap-x-2 items-center w-full">
                        <span className="truncate font-inter-medium">{original?.percentage}</span>
                    </span>
                )
            }
        }
    ];
    return (
        <div className='w-[45vw] px-10 pb-4 pt-20 relative'>
            <div className='absolute top-[1.5rem] left-[1rem]'>
                <div className="flex items-center space-x-4">
                    <FiArrowLeft
                        size={22}
                        onClick={() => {
                            closeModal()
                        }}
                        className="cursor-pointer back-arrow"
                    />
                    <h1 className="2xl:text-[22px] text-[20px] font-futura-bold heading-at-zoom">Revenue Model</h1>
                </div>
            </div>
            <TableComponent
                data={revenueData}
                columns={column}
                customwidths={["w-[45%]", "w-[30%]", "w-[25%]"]}
            // isLoading={franchiseDataStatus === "pending"}
            // customeClass="franchise-management-table-ht"
            />
        </div>
    )
}

export default RevenueModal