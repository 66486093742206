import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import VehiclesCard from './vehicle-module/Vehicles_Card'
import { FiArrowLeft } from 'react-icons/fi'
import { useLazyGetCustomerVehiclesQuery } from '../../utils/Store';
import { useParams } from 'react-router';

function CustomerVehicleCount() {

    const navigate = useNavigate();
    const { customerId } = useParams();
    const isVehicleCountCard = true;

    const [ getCustomerVehicles, {data: customerVehicleList, status: customerVehicleStatus} ] = useLazyGetCustomerVehiclesQuery();

    useEffect(() => {
        if(customerId) {
            getCustomerVehicles({customerId});
        }
    }, []);

    return (
        <div className="h-full pl-8 pr-24 pb-4 pt-8 w-full bg-[#F5F5F5]">
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="flex items-center space-x-4">
                        <FiArrowLeft
                            className="cursor-pointer back-arrow"
                            size={22}
                            onClick={() => navigate(-1)}
                        />
                        <h1 className="2xl:text-[22px] text-[20px] font-inter-bold heading-at-zoom">
                            Vehicles ({customerVehicleList?.length})
                        </h1>
                    </div>
                </div>

                <div className='mt-5 overflow-y-auto h-[80vh] pr-4'>
                    {
                        customerVehicleList && customerVehicleList.length > 0 &&
                        customerVehicleList?.map((vehicleData) => {

                            const defaultAddress = vehicleData?.addresses.find((address) => address.defaultAddress);
                            const address = {
                                addressLineOne: defaultAddress?.addressLineOne || '',
                                landMark: defaultAddress?.landMark || '',
                                areaName: defaultAddress?.areaName || '',
                                cityName: defaultAddress?.cityName || '',
                                pincode: defaultAddress?.pincode || '',
                            };

                            return (
                                <VehiclesCard
                                    isVehicleCountCard = {isVehicleCountCard}
                                    vehicleData = {{...vehicleData, vehicleBrand: vehicleData?.brandName, vehicleModelImage: vehicleData?.imageUrl, vehicleType: vehicleData?.carType, address: vehicleData?.addresses,}}
                                />
                            )
                        }
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomerVehicleCount