import React, { useState, useEffect, useRef } from "react";
import { GoogleMap } from "@react-google-maps/api";

function DisplayCityMap({ data }) {
	const {city} = data;
	const containerStyle = {
		width: "100%",
		height: "302px",
		borderRadius: "15px",
		overflow: "hidden",
	};

	const [center, setPlaceCenter] = useState({
		lat: city?.latitude,
		lng: city?.longitude,
	});

	const featureStyleOptions = {
		strokeColor: "#810FCB",
		strokeOpacity: 1.0,
		strokeWeight: 3.0,
		fillOpacity: 0.5,
	};

	const placesServiceRef = useRef(null);
	const [map, setMap] = useState(null);

	useEffect(() => {
		if (map) {
			placesServiceRef.current = new window.google.maps.places.PlacesService(map);
			let featureLayer = map.getFeatureLayer("LOCALITY");
				featureLayer.style = (options) => {
					if (options?.feature?.placeId == city?.place_id) {
						return featureStyleOptions;
					}
				};
		}
	}, [map]);


	const onLoad = (map) => {
		setMap(map);
	};

	const onUnmount = () => {
		setMap(null);
	};

	return (
		<div className=" w-[500px]">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={10}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{ disableDefaultUI: true, mapId: process.env.REACT_APP_MAP_ID }}
				version="beta"
			></GoogleMap>
		</div>
	);
}

export default React.memo(DisplayCityMap);
