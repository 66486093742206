import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
export default function Button({
	type = "button",
	isSubmitting = false,
	children,
	right_icon,
	left_icon,
	className,
	customeHeight,
	...rest
}) {
	return (
		<button
			type={type}
			disabled={isSubmitting}
			className={`flex items-center justify-center rounded-md border ${customeHeight} ${
				/bg-[^\s]+/.test(className) ? "" : "bg-[#FB0C0C]" 
			} ${/h-[^\s]+/.test(className) ? "" : "h-12"} ${/border-[^\s]+/.test(className) ? "" : "border-transparent"} ${/2xl:px-[^\s]+/.test(className) ? "" : "2xl:px-4"} ${/px-[^\s]+/.test(className) ? "" : "px-2"} font-medium  ${className}`}
			{...rest}
		>
			{left_icon && <img src={left_icon} className="mr-3.5" alt="" />}
			{isSubmitting ? <BiLoaderAlt className="animate-spin text-xl"></BiLoaderAlt> : children}
			{right_icon && <img src={right_icon} className="ml-3.5" alt="" />}
		</button>
	);
}
