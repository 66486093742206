import React, { useEffect, useRef, useState } from "react";
import Button from "../../../shared/components/Button.component";
import useSelectModal from "../../../utils/hooks/useSelectModal";
import Edit from "../../../assets/icons/edit-icon-blue-bg.svg";
import { useGetAreaListMutation, useLazyGetAllMasterFranchiseQuery, useLazyGetAreasQuery, useLazyGetCitesQuery, useUpdateFranchiseMutation, } from "../../../utils/Store";
import { Formik, Form } from "formik";
import useToaster from "../../../utils/hooks/useToaster";
import FormField from "../../../shared/components/FormField.component";
import SelectMenu from "../../../shared/components/SelectMenu.component";
import { ServiceSchema } from "../../../utils/Services/ValidationSchema";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import Calender from "../../../shared/components/Calender";
import FileSelectButton from "../../../shared/components/FileSelectButton";
import useGetImageUploadData from "../../../utils/hooks/useGetImageUploadData";
import { RxCross2 } from "react-icons/rx";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
    name: Yup.string().required("Franchise Name is required"),
    address: Yup.string().required("Address is required"),
    phoneNumber: Yup.string()
        .required("Mobile Number is required")
        .matches(/^\d{10}$/, "Please enter 10 digits Mobile Number"),
    gst: Yup.string()
        .required("GST Number is required")
        .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Please enter 15 digits GST Number"),
    cityId: Yup.array().required("City is required"),
    areaId: Yup.array().required("Area is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    // franchise: Yup.object().required("Master Franchise is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    mouDocumentsUrls: Yup.array().min(1, "Document is required"),
});

function EditFranchiseModal({ data }) {
    const { closeModal } = useSelectModal();
    const { _id } = useParams();
    const [reinitialize, setReInitialize] = useState(!!_id);
    const [getAllCities, { data: cities }] = useLazyGetCitesQuery();
    const [cityId, setCityId] = useState();
    const { setToast } = useToaster();
    const navigate = useNavigate();
    const [updateFranchise, { status: updateFranchiseStatus }] = useUpdateFranchiseMutation();
    const [allSelectedCityAreas, setAllSelectedCityAreas] = useState([]);
    const [isFirstRender, setFirstRender] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [getAllMasterFranchise, { data: masterFranchiseList }] = useLazyGetAllMasterFranchiseQuery();
    const parentFranchise = masterFranchiseList?.find((items) => items._id === data.parentId)

    useEffect(() => {
        handleFetchCityList();
        getAllMasterFranchise();
    }, []);

    const handleFetchCityList = async () => {
        const { data, error } = await getAllCities();
        if (error) {
            setToast({ type: "error", msg: error.data.message });
        }
    };

    const handleFormSubmit = async (formData) => {
        let apiData = {
            name: formData.name,
            email: formData.email,
            cityId: formData?.cityId?.map(cityId => cityId._id),
            areaId: formData?.areaId?.map(areaId => areaId._id),
            address: formData.address,
            phoneNumber: "+91" + formData?.phoneNumber,
            gst: formData.gst,
            mouDocumentToAdd: formData.mouDocumentsUrls?.length && formData.mouDocumentsUrls
        }
        const { data: d, error } = await updateFranchise({
            id: data._id,
            data: apiData
        })
        if (d) {
            setToast({ type: "success", msg: "Franchise updated successfully" })
            closeModal();
            data.fetchFranchise();
        }
        if (error) setToast({ type: "error", msg: error?.data?.message });

        return;
    }

    const FranchiseForm = ({ values, setFieldValue, errors, touched }) => {
        const [getAreas, { data: areaDetails }] = useGetAreaListMutation();
        const { uploadImages, uploadImgStatus } = useGetImageUploadData();

        const handleGetCityAreas = async () => {
            if (!values.cityId?.length) return
            let { data } = await getAreas({
                cityId: values.cityId.map((city, index) => city._id)
            });
        }

        const handleUpload = async () => {
            if (!selectedFiles) return

            const uploadedData = await uploadImages(selectedFiles)
            if (uploadedData) {
                setSelectedFiles([])
                if (values.mouDocumentsUrls?.length) setFieldValue("mouDocumentsUrls", [...values.mouDocumentsUrls, ...uploadedData]);
                else setFieldValue("mouDocumentsUrls", uploadedData);
            }
        }



        useEffect(() => {
            if (areaDetails?.length && isFirstRender) {
                const areaList = data?.getAreaObject?.map(area => areaDetails.find(areas => areas._id === area.areaId))
                setFieldValue("areaId", areaList)
                setFirstRender(false)
            }
        }, [areaDetails])

        useEffect(() => {
            if (areaDetails?.length && values.areaId?.length) {
                const areas = areaDetails?.filter(area => values.areaId.find((area1) => area._id === area1?._id))
                setFieldValue("areaId", areas)
            }
        }, [areaDetails]);

        useEffect(() => {
            handleGetCityAreas()
        }, [values.cityId])

        useEffect(() => {
            if (values?.cityId?._id) setCityId(values?.cityId?._id);
        }, [values?.cityId?._id]);

        const CustomDatePicker = ({ field, form, ...props }) => {
            const currentFieldValue = field.value;

            return (
                <div className="w-full">
                    <DatePicker
                        {...props}
                        selected={currentFieldValue}
                        onChange={(date) => form.setFieldValue(field.name, date)}
                        minDate={new Date()}
                    />
                </div>
            );
        };

        return (
            <div className="overflow-y-auto max-h-[60vh] pt-4 px-5 modal-ht-inner">
                <div className="flex space-x-4">
                    <div className="w-full space-y-2">
                        <div className="">
                            <FormField
                                field={{
                                    label: "Franchise Name",
                                    name: "name",
                                    type: "text",
                                    placeholder: "Enter Franchise Name",
                                }}
                            />
                        </div>
                        <div className="">
                            <FormField
                                field={{
                                    label: "Address",
                                    name: "address",
                                    type: "text",
                                    placeholder: "Enter Address",
                                }}
                            />
                        </div>
                        <div className="">
                            <FormField
                                field={{
                                    label: "Mobile Number",
                                    name: "phoneNumber",
                                    type: "text",
                                    placeholder: "Enter Mobile Number",
                                }}
                            />
                        </div>
                        <div className="">
                            <FormField
                                field={{
                                    label: "GST",
                                    name: "gst",
                                    type: "text",
                                    placeholder: "Enter GST Number",
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <SelectMenu
                                isMulti={true}
                                name="cityId"
                                options={cities}
                                onChange={(option) => {
                                    setFieldValue("cityId", option);
                                }}
                                value={values.cityId}
                                placeholder="Select City"
                                label="City"
                                getOptionValue={(option) => option._id}
                                showCheckboxOnRight={false}
                            />
                            {touched?.cityId && errors?.cityId ? (
                                <div className="error text-red-600 text-sm">{errors?.cityId}</div>
                            ) : (
                                <div className="invisible h-5"></div>
                            )}
                        </div>
                        <div className="w-full pt-4">
                            <SelectMenu
                                isMulti={true}
                                label="Area"
                                name="areaId"
                                options={areaDetails}
                                onChange={(option) => setFieldValue("areaId", option)}
                                value={values.areaId}
                                placeholder="Select Area"
                                showCheckboxOnRight={false}
                                hideSelectedOptions={false}
                            />
                            {touched?.areaId && errors?.areaId ? (
                                <div className="error text-red-600 text-sm">{errors?.areaId}</div>
                            ) : (
                                <div className="invisible h-5"></div>
                            )}
                        </div>
                        <div className="pt-4">
                            <FormField
                                field={{
                                    label: "Enter Email",
                                    name: "email",
                                    type: "text",
                                    placeholder: "Enter Customer Email",
                                }}
                            />
                        </div>
                        {data.franchiseLevel === "child" && <div className="w-full">
                            <SelectMenu
                                name="masterFranchise"
                                placeholder="Select Master Franchise"
                                label="Master Franchise"
                                value={parentFranchise}
                                disabled
                            />
                        </div>}
                        <div className=" w-full flex space-x-12 py-4">
                            <div className="w-1/2">
                                <label
                                    htmlFor="startDate"
                                    className="block text-sm font-medium leading-6 text-[#111827]"
                                >
                                    Start Date
                                </label>
                                <Calender name="startDate" disabled={true} />
                            </div>
                            <div className="w-1/2">
                                <label
                                    htmlFor="endDate"
                                    className="block text-sm font-medium leading-6 text-[#111827]"
                                >
                                    End Date
                                </label>
                                <Calender name="endDate" disabled={true} />
                            </div>
                        </div>

                        <div className="border rounded-xl bg-[#F5F5F5] px-2 mb-4 pt-3">
                            <div className="font-medium text-sm mb-2">Upload MOU PDF</div>
                            <div className="w-full flex space-x-4 ">
                                <div className="w-1/2">
                                    <FileSelectButton selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} accept={"application/pdf"} />
                                </div>
                                <div className="w-1/2">
                                    <Button
                                        className="bg-black text-white cursor-pointer h-[2.4rem] w-full"
                                        onClick={handleUpload}
                                        isSubmitting={uploadImgStatus === "pending"}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            </div>
                            {errors.mouDocumentsUrls && touched.mouDocumentsUrls ? (
                                <div className="error text-red-600 text-sm">{errors?.mouDocumentsUrls}</div>
                            ) : (
                                <div className="invisible h-5"></div>
                            )}
                            <div className="flex gap-2 overflow-x-auto final-price-scrollbar mb-4">
                                {data?.mouDocuments?.map((doc, index) => (
                                    <div
                                        className="flex justify-between bg-white rounded-md border items-center px-3 py-1.5 relative"
                                        key={index}
                                    >
                                        <div className="flex-1 flex items-center gap-3">
                                            <div className="font-inter-regular text-sm text-gray-500 ">
                                                {doc?.name}
                                            </div>
                                            <RxCross2
                                                alt=""
                                                className="h-4 w-4 text-gray-400 cursor-default"
                                                // onClick={() => {
                                                //     const updatedArray = values.mouDocumentsUrls?.filter(
                                                //         (e) => e.url !== doc.url
                                                //     );
                                                //     setFieldValue("mouDocumentsUrls", updatedArray);
                                                // }}
                                            />

                                        </div>
                                    </div>
                                ))}
                                {values.mouDocumentsUrls?.map((doc, index) => (
                                    <div
                                        className="flex justify-between bg-white rounded-md border items-center px-3 py-1.5 relative"
                                        key={index}
                                    >
                                        <div className="flex-1 flex items-center gap-3">
                                            <div className="font-inter-regular text-sm text-gray-500 ">
                                                {doc?.name}
                                            </div>
                                            <RxCross2
                                                alt=""
                                                className="h-4 w-4 text-gray-400 cursor-pointer"
                                                onClick={() => {
                                                    const updatedArray = values.mouDocumentsUrls?.filter(
                                                        (e) => e.url !== doc.url
                                                    );
                                                    setFieldValue("mouDocumentsUrls", updatedArray);
                                                }}
                                            />

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex space-x-4 my-3 pt-4">
                    <Button
                        className="bg-[#F5F5F5] rounded-lg text-[#111827] cursor-pointer bottom-btn-ht w-full"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="bg-[#FB0C0C] rounded-lg text-white cursor-pointer bottom-btn-ht w-full"
                        type="submit"
                        isSubmitting={updateFranchiseStatus === "pending"}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="w-[52rem] rounded-xl modal-height-service h-[80vh] overflow-hidden">
            <div>
                <div className="pl-5 pb-4">
                    <div className="flex items-center justify-between pt-5">
                        <div className="h-12 w-12 bg-[#0D6EFD] rounded-full flex justify-center items-center modal-logo-size">
                            <img src={Edit} className="h-12 w-12" alt="" />
                        </div>
                    </div>
                    <div className="mb-1 mt-6">
                        <h1 className="text-xl font-inter-bold">Edit Franchise</h1>
                    </div>
                    <div className="">
                        <h1 className="text-base font-normal text-[#475467]">
                            Edit franchise details by changing the details below
                        </h1>
                    </div>
                </div>
                <div className="pr-3 pb-5">
                    <Formik
                        // enableReinitialize={reinitialize}
                        initialValues={{
                            name: data?.name,
                            address: data?.address,
                            phoneNumber: data?.phoneNumber?.slice(3),
                            gst: data?.gst,
                            cityId: data?.getCityObject || [],
                            areaId: [],
                            email: data?.email,
                            masterFranchise: parentFranchise?.name,
                            startDate: data?.startDate ? new Date(data.startDate) : null,
                            endDate: data?.endDate ? new Date(data.endDate) : null,
                            // mouDocumentsUrls: data?.mouDocuments,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleFormSubmit(values);
                        }}
                    >
                        {({ isSubmitting, values, errors, touched, setFieldValue }) => {
                            return (
                                <Form className="flex gap-5">
                                    <div className="gap-5 w-full">
                                        {<FranchiseForm values={values} errors={errors} setFieldValue={setFieldValue} touched={touched} />}
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default EditFranchiseModal
