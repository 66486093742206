import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const areaApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			getAreas: builder.query({
				query: (cityId) => {
					return {
						url: endpoints.area.getAreas(cityId),
						method: "GET",
					};
				},
				providesTags: ["Area"],
			}),
			getAreasPagination: builder.query({
				query: ({cityId, pageNo, pageSize}) => {
					return {
						url: endpoints.area.getAreaPagination(cityId, pageNo, pageSize),
						method: "GET",
					};
				},
				providesTags: ["Area"],
			}),
			addArea: builder.mutation({
				query: (body) => ({
					url: endpoints.area.addArea,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["Area"],
			}),
			getSupervisorsFromCity: builder.query({
				query: (cityId) => {
					return {
						url: endpoints.area.getCitySupervisor(cityId),
						method: "GET",
					};
				},
			}),
			deleteArea: builder.mutation({
				query: (areaId) => ({
					url: endpoints.area.deleteArea(areaId),
					method: "DELETE",
				}),
				invalidatesTags: ["Area"],
			}),
			updateArea: builder.mutation({
				query: ({ areaId, data }) => ({
					url: endpoints.area.upateArea(areaId),
					body: data,
					method: "PUT",
				}),
				invalidatesTags: ["Area", "Area Details"],
			}),
			getAreaDetails: builder.query({
				query: (areaId) => {
					return {
						url: endpoints.area.getAreaDetails(areaId),
						method: "GET",
					};
				},
				providesTags: ["Area Details"],
			}),
			getActiveRadiusType: builder.query({
				query: () => {
					return {
						url: endpoints.area.getActiveRadiusType,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const {
	useAddAreaMutation,
	useGetAreasQuery,
	useLazyGetAreasQuery,
	useLazyGetSupervisorsFromCityQuery,
	useDeleteAreaMutation,
	useUpdateAreaMutation,
	useLazyGetAreaDetailsQuery,
	useLazyGetAreasPaginationQuery,
	useLazyGetActiveRadiusTypeQuery
} = areaApi;
export { areaApi };
