import React from "react";
import dateFormatter from "../../shared/components/dateFormatter.component";
import moment from "moment";
const conversionData = {
	balancestatement: "Balance Statement",
	cancelcheque: "Cancel Cheque",
}

function EmpolyeeInfo({ employeeData, selectedIndex, franchiseData }) {
	const franChise = franchiseData?.franchise?.find((franchise)=> franchise._id === employeeData.franchiseId)
	return (
		<div className="">
			<div className="px-3 pb-12 rounded-xl w-full bg-white">
				{/* Employee Details */}
				{selectedIndex == 0 && (
					<div className="">
						<div className="px-10 pt-6 pb-4">
							<h1 className="2xl:text-lg text-base font-inter-bold">Employee Details</h1>
						</div>
						<hr className="mb-8 border border-gray-100" />
						<div className="flex px-10">
							<div className="w-[45%]">
								<div className="flex mb-8">
									<div className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Employee Name:
									</div>
									<div className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
										{employeeData.name}
									</div>
								</div>
								<div className="flex mb-8">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Mobile Number:
									</h1>
									<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
										{employeeData.phoneNumber}
									</h1>
								</div>
								<div className="flex mb-8">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Email ID:
									</h1>
									<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5 truncate">
										{employeeData.email}
									</h1>
								</div>
								<div className="flex mb-8">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										City:
									</h1>
									<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
										{employeeData.cityName}
									</h1>
								</div>
								{employeeData && employeeData?.roles?.includes("rider") && (
									<>
										<div className="flex mb-8">
											<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
												Latitude:
											</h1>
											<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
											{employeeData?.coOrdinates?.latitude ? employeeData.coOrdinates.latitude : '-'}
											</h1>
										</div>
										<div className="flex mb-8">
											<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
											Updated Time:
											</h1>
											<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
												{moment(employeeData?.coOrdinatesUpdatedTime).local().format('YYYY-MM-DD h:mm:ss')}
											</h1>
										</div>
									</>
								)}
							</div>
							<div className="w-[45%]">
								<div className="flex mb-8">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Role:
									</h1>
									<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
										{employeeData.roles[0]}
									</h1>
								</div>
								<div className="flex mb-8">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Assigned<br /> {employeeData?.roles[0] === 'rider' ? 'Supervisor' : 'City Manager'}:
									</h1>
									<h1 className="2xl:text-base font-inter-medium col-span-2  2xl:w-4/5 w-3/5 pl-5">
										{employeeData?.assigneeName}
									</h1>
								</div>
								<div className="flex mb-2 invisible ">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Joining Date:
									</h1>

									<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
										{dateFormatter(employeeData.joiningDate, "D MMMM YYYY")}
									</h1>
								</div>
								<div className="flex mb-8">
									<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
										Joining Date:
									</h1>

									<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
										{dateFormatter(employeeData.joiningDate, "D MMMM YYYY")}
									</h1>
								</div>
								<>
									{employeeData && employeeData?.roles?.includes("rider") && (
										<>
											<div className="flex mb-8">
												<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
													Longitude:
												</h1>
												<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
												{employeeData?.coOrdinates?.longitude ? employeeData.coOrdinates.longitude : '-'}
												</h1>
											</div>
										</>
									)}
									{franChise && <div className="flex mb-8">
										<h1 className="2xl:text-base font-normal text-[#474747] col-span-1 2xl:w-1/5 w-2/5">
											Franchise:
										</h1>

										<h1 className="2xl:text-base font-inter-medium col-span-2 2xl:w-4/5 w-3/5 pl-5">
											{franChise?.name}
										</h1>
								  </div>}
								</>
							</div>
						</div>
					</div>
				)}

				{/* KYC Details */}
				{selectedIndex == 1 && (
					<div className="">
						<div className="px-10 pt-6 pb-4">
							<h1 className="2xl:text-lg text-base font-inter-bold">KYC Details</h1>
						</div>
						<hr className="mb-8 border border-gray-100" />
						<div className="list-decimal list-inside px-10 flex gap-5">
							{employeeData?.kycDocuments?.map((doc, i) => {
								return (
									<div key={i} className="w-[33%]">
										<div className="text-[#474747] text-base capitalize">
											{doc.name} Number : <span className="font-bold text-[#000] text-lg">{doc.documentNumber}</span>
										</div>
									</div>
								);
							})}
						</div>
						<div className="w-full flex flex-wrap mt-10 gap-5 px-10">
							{employeeData?.kycDocuments?.map((doc, i) => {
								return (
									<div key={i} className="flex w-[33%] gap-1">
										<div className="flex border rounded-lg justify-center flex-1 h-[15vw] overflow-x-auto final-price-scrollbar">
											<div className="flex gap-2 pl-8 py-8 pr-4 mr-2 h-[10vw] my-auto">
												{doc?.imageUrls?.map((image, i) => {
													return <img src={image} alt="" key={i} className="w-full h-full"></img>;
												})}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}

				{/* Employee Salary Details */}
				{selectedIndex == 2 && (
					<div className="">
						<div className="px-10 pt-6 pb-4">
							<h1 className="2xl:text-lg text-base font-inter-bold">Employee Salary details</h1>
						</div>
						<hr className="mb-8 border border-gray-100" />
						<div className="px-10">
							<div className="flex mb-8">
								<h1 className="2xl:text-base text-base font-normal text-[#474747] col-span-1 w-[12.5%]">
									Fixed Salary:
								</h1>
								{employeeData.fixedSalary && (
									<h1 className="2xl:text-base text-base font-inter-medium col-span-3 w-1/5">
										₹ {employeeData.fixedSalary}
									</h1>
								)}
							</div>
							<div className="flex mb-8">
								<h1 className="2xl:text-base text-base font-normal text-[#474747] col-span-1 w-[12.5%]">
									Incentives:
								</h1>
								{employeeData.incentive && (
									<h1 className="2xl:text-base text-base font-inter-medium col-span-3 w-1/5">
										₹ {employeeData.incentive}
									</h1>
								)}
							</div>
							<div className="flex mb-8">
								<h1 className="2xl:text-base text-base font-normal text-[#474747] col-span-1 w-[12.5%]">
									Other Allowances:
								</h1>
								{employeeData.salaryAllowance?.length && (
									<h1 className="text-lg font-inter-medium col-span-3 w-1/5">
										{employeeData.salaryAllowance?.map((e, i, a) => (
											<span>
												{e.type} ₹ {e.amount}
												{i < a.length - 1 && ","}{" "}
											</span>
										))}
									</h1>
								)}
							</div>
						</div>
					</div>
				)}

				{/* Employee Bank Details */}
				{selectedIndex == 3 && (
					<div className="">
						<div className="">
							<div className="px-10 pt-6 pb-4">
								<h1 className="2xl:text-lg text-base font-inter-bold">
									Employee Bank Account Details
								</h1>
							</div>
							<hr className="mb-8 border border-gray-100" />

							<div className="flex px-10">
								<div className="2xl:w-[40%] w-[45%]">
									<div className="flex mb-8">
										<div className="w-[40%]">
											<h1 className="2xl:text-base text-base font-normal text-[#474747] col-span-1">
												Account Number:
											</h1>
										</div>
										<div className="w-[60%]">
											<h1 className="2xl:text-base text-base font-inter-medium col-span-1">
												{employeeData.accountNumber}
											</h1>
										</div>
									</div>
									<div className="flex mb-8">
										<div className="w-[40%]">
											<h1 className="2xl:text-base text-base font-normal text-[#474747] col-span-1">
												IFSC Code:
											</h1>
										</div>
										<div className="w-[60%]">
											<h1 className="2xl:text-base text-base font-inter-medium col-span-1">
												{employeeData.ifscCode}
											</h1>
										</div>
									</div>
									<div className="flex mb-8">
										<div className="w-[40%]">
											<h1 className="2xl:text-base text-base font-normal text-[#474747] col-span-1">
												Document Uploaded:
											</h1>
										</div>
										<div className="w-[60%]">
											<h1 className="2xl:text-base text-base font-inter-medium col-span-1">
												{conversionData[employeeData?.bankDocuments[0]?.documentType]}
											</h1>
										</div>
									</div>
								</div>
								<div className="col-span-1 w-[40%]">
									<div className="border border-gray-100 rounded-lg p-3 h-[12.5vw]">
										<img
											className="w-full h-full object-cover"
											src={employeeData.bankDocuments[0]?.imageUrl}
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* Service details */}
				{/* {selectedIndex == 1 && (
					<div className="">
						<div className="mt-5">
							<div className="">
								<h1 className="2xl:text-lg text-base font-inter-bold">Service details</h1>
							</div>
							<hr className="mt-2 mb-4 border border-gray-100" />

							<div className="flex "></div>
						</div>
					</div>
				)} */}
			</div>
		</div>
	);
}

export default EmpolyeeInfo;
