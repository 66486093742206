import { endpoints } from "../../config/endpoint.config";
import { apiSlice } from "./apiInterceptor";

const employeeApi = apiSlice.injectEndpoints({
	endpoints(builder) {
		return {
			profile: builder.query({
				query: () => {
					return {
						url: endpoints.employee.profile,
						method: "GET",
					};
				},
				providesTags: ["Employee"],
			}),
			addEmployee: builder.mutation({
				query: (body) => ({
					url: endpoints.employee.addEmployee,
					body: body,
					method: "POST",
				}),
				invalidatesTags: ["Employee"],
			}),
			getEmployeeDetails: builder.query({
				query: (employeeId) => {
					return {
						url: endpoints.employee.getEmployeeDetails(employeeId),
						method: "GET",
					};
				},
				providesTags: ["Employee"],
			}),
			deleteEmployee: builder.mutation({
				query: (employeeId) => ({
					url: endpoints.employee.deleteEmployee(employeeId),
					method: "DELETE",
				}),
				invalidatesTags: ["Employee"],
			}),
			updateEmployee: builder.mutation({
				query: ({employeeId, body}) => ({
					url: endpoints.employee.updateEmployee(employeeId),
					body: body,
					method: "PUT",
				}),
				invalidatesTags: ["Employee"],
			}),
			getAllEmployees: builder.query({
				query: () => {
					return {
						url: endpoints.employee.getAllEmployees,
						method: "GET",
						// params: {
						// 	cityId: "64ec92a79a41cccc96fcdbc5",
						// },
					};
				},
				providesTags: ["Employee"],
			}),
		};
	},
});

export const {
	useProfileQuery,
	useLazyProfileQuery,
	useAddEmployeeMutation,
	useDeleteEmployeeMutation,
	useGetEmployeeDetailsQuery,
	useLazyGetEmployeeDetailsQuery,
	useUpdateEmployeeMutation,
	useGetAllEmployeesQuery,
	useLazyGetAllEmployeesQuery,
} = employeeApi;
export { employeeApi };
