import React, { useEffect, useState } from "react";

import { FiArrowLeft } from "react-icons/fi";
import Button from "../../shared/components/Button.component";
import DeleteIcon from "../../assets/icons/white-trash.svg";
import EditIcon from "../../assets/icons/white-pen.svg";

import { useNavigate, useParams } from "react-router-dom";
import {  useGetAllFranchiseMutation, useLazyGetEmployeeDetailsQuery } from "../../utils/Store";
import Tabs from "../../shared/components/Tabs.component";
import EmpolyeeInfo from "./Empolyee_Info";
import useSelectModal from "../../utils/hooks/useSelectModal";

export default function EmployeeDetailsPage() {
	const { employeeId } = useParams();
	const [getEmployeeDetails, { data: employeeData }] = useLazyGetEmployeeDetailsQuery();
	const navigate = useNavigate();
	const [selectedIndex, setSelectedIndex] = useState(0);
	const { openModal } = useSelectModal();
	const [getAllFranchise, { data: franchiseData }] = useGetAllFranchiseMutation();

	let categories = [
		{
			title: `Employee Details`,
			status: "employeeDetails",
			component: <EmpolyeeInfo employeeData={employeeData} selectedIndex={selectedIndex} franchiseData={franchiseData} />,
		},

		{
			title: `KYC Details`,
			status: "kYCDetails",
			component: <EmpolyeeInfo employeeData={employeeData} selectedIndex={selectedIndex} />,
		},
		{
			title: `Employee Salary Details`,
			status: "employeeSalaryDetails",
			component: <EmpolyeeInfo employeeData={employeeData} selectedIndex={selectedIndex} />,
		},
		{
			title: `Employee Bank Details`,
			status: "employeeBankDetails",
			component: <EmpolyeeInfo employeeData={employeeData} selectedIndex={selectedIndex} />,
		},
	];

	useEffect(()=>{
		if(!employeeData) return
		getAllFranchise({ body: {cityId: [employeeData?.cityId], areaId: employeeData?.areas?.map((area) => area._id),  franchiseLevel:"all",  status:"all"}, params: { page: 1, pageSize: 1000 } });
	},[employeeData])

	useEffect(() => {
		if (employeeId) getEmployeeDetails(employeeId);
	}, [employeeId]);


	const handleDeleteEmployee = async () => {
		openModal("DeleteEmployeeModal", {
			...employeeData,
			hideCloseArrow: true,
			isNavigateBack: true,
		});
	};

	if (!employeeData) return null;

	return (
		<div className="bg-[#F5F5F5] h-full">
			
			<div className="space-y-3">
				<div className="px-8 pb-4 pt-8">
					<div className="flex justify-between">
						<div className="flex items-center space-x-4">
							<FiArrowLeft
								size={22}
								onClick={() => navigate("/employee-management")}
								className="cursor-pointer back-arrow"
							/>
							<h1 className="2xl:text-[22px] text-[20px] font-futura-bold heading-at-zoom">{employeeData.name}</h1>
						</div>
						<div className="flex items-center space-x-4">
							<Button
								className="text-white bg-[#000] cursor-pointer rounded-[10px] btn-radius"
								onClick={() => navigate(`/employee-management/edit/${employeeData._id}`)}
							>
								Edit
								<img src={EditIcon} color="white" className="pl-2.5 delete-edit-icon-ht" />
							</Button>
							<Button className="text-white cursor-pointer rounded-[10px] reb-btn-shadow" onClick={() => handleDeleteEmployee()}>
								Delete
								<img src={DeleteIcon} color="white" className="pl-2.5 delete-edit-icon-ht" />
							</Button>
						</div>
					</div>

					<Tabs
						categories={categories}
						className="w-full py-6"
						setSelectedIndex={setSelectedIndex}
						selectedIndex={selectedIndex}
					/>
					<div className="mt-3 overflow-y-auto overflow-x-hidden brand-details-section">
						{categories[selectedIndex].component}
					</div>
				</div>
			</div>
		</div>
	);
}
