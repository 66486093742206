import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { libraries } from "../../utils/Store/constants";
import {data as areaMasterHelper} from '../../utils/helper/areaMaster';

function MapDisplayAreas({ data }) {
	const { pincodeArray = [], areaData, width = "300px", height = "200px", zoom = "12" } = data;

	const [center, setCenter] = useState();
	const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(()=>{
		setCenter({ lat: +areaData?.coOrdinates?.latitude, lng: +areaData?.coOrdinates?.longitude })
	},[areaData])

	const containerStyle = {
		width,
		height,
		borderRadius: "15px",
		overflow: "hidden",
	};

	const mapRef = useRef(null);
	const boundaryStyles = {};

	useEffect(() => {
		if (!isMapLoaded || !pincodeArray || !pincodeArray.length) return;
		const geocoder = new window.google.maps.Geocoder();

		pincodeArray.forEach((pincode) => {
			geocoder.geocode({ address: pincode, region: "IN" }, (results, status) => {
				if (status === "OK" && results.length > 0) {
					const place = results[0].geometry.location;
					const placeId = results[0].place_id;
					const boundaryStyle = {
						strokeColor: "#810FCB",
						strokeOpacity: 1.0,
						strokeWeight: 3.0,
						fillOpacity: 0.5,
					};

					boundaryStyles[placeId] = boundaryStyle;

					if (Object.keys(boundaryStyles).length === pincodeArray.length) {
						let featureLayer = mapRef.current.getFeatureLayer("POSTAL_CODE");
						featureLayer.style = (options) => {
							if (options?.feature?.placeId) {
								return boundaryStyles[options.feature.placeId];
							}
						};
					}
				} else {
					console.error(`No results found for the pin code: ${pincode}`);
				}
			});
		});
	}, [pincodeArray, isMapLoaded]);

	useEffect(() => {
		if (!isMapLoaded || !areaData?.coOrdinates?.searchedAreaName || !areaData?.radius) return;

		const circles = [];

				const circle = new window.google.maps.Circle({
					center: { lat: +areaData?.coOrdinates?.latitude, lng: +areaData?.coOrdinates?.longitude },
					radius: areaData?.radius * areaMasterHelper[areaData?.radiusType?.unit],
					strokeColor: "#810FCB",
					strokeOpacity: 1.0,
					strokeWeight: 3.0,
				});
				circle.setMap(mapRef.current);
				circles.push(circle);

		return () => {
			circles.forEach((circle) => {
				circle.setMap(null);
			});
		};
	}, [areaData, isMapLoaded]);

	const onLoad = (map) => {
		mapRef.current = map;
		const idleListener = map.addListener("idle", () => {
			setIsMapLoaded(true);
		});
		return () => {
			window.google.maps.event.removeListener(idleListener);
		};
	};

	const onUnmount = () => {
		mapRef.current = null;
	};

	return (
		<div className="rounded-full">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={+zoom}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{ disableDefaultUI: true, mapId: process.env.REACT_APP_MAP_ID }}
				version="beta"
			>
				{isMapLoaded && center && <Marker position={center} />}
			</GoogleMap>
		</div>
	);
}

export default React.memo(MapDisplayAreas);
