
import 'rc-time-picker/assets/index.css';
import React, { useState } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

const Timepicker = ({ selectedTime, setSelectedTime, disabledTime, placeholder, onChange }) => {


    const format = "h:mm A";

    const handleTimeChange = (value) => {
        setSelectedTime(value);
    };

    return (
        <div>
            <TimePicker
                showSecond={false}
                value={selectedTime}
                onChange={handleTimeChange}
                className='text-lg font-medium'
                allowEmpty={false}
                format={format}
                use12Hours
                placeholder={placeholder}
                disabledHours={() => {
                    if (!disabledTime) return [];
                    const disabledHours = [];
                    const minToTime = disabledTime.clone().add(15, 'minutes');
                    let maxToTime = disabledTime.clone().add(4, 'hours');
                    if (maxToTime.hours() <=3) {
                        maxToTime = maxToTime.hours(23).minutes(0);
                    }
                    for (let i = 0; i < minToTime.hours(); i++) {
                        disabledHours.push(i);
                    }
                    for (let i = maxToTime.hours() + 1; i < 24; i++) {
                        disabledHours.push(i);
                    }
                    return disabledHours;
                }}

                disabledMinutes={(hour) => {
                    if (!disabledTime) return [];
                    const disabledMinutes = [];
                    const minToTime = disabledTime.clone().add(15, 'minutes');
                    const maxToTime = disabledTime.clone().add(4, 'hours');
                    if (hour === minToTime.hour()) {
                        for (let i = 0; i < minToTime.minutes(); i++) {
                            disabledMinutes.push(i);
                        }
                    }
                    if (hour === maxToTime.hour()) {
                        for (let i = maxToTime.minutes() + 1; i < 60; i++) {
                            disabledMinutes.push(i);
                        }
                    }
                    return disabledMinutes;
                }}
            />

        </div>
    );
}

export default Timepicker;
